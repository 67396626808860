import lectureService from "actions/services/lecture.service";
// import Accordion from "react-bootstrap/Accordion";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaTimesCircle } from "react-icons/fa";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

function Results({
  submissions,
  challengeSubmissionResponse,
  fetchPrevSubmission,
}) {
  function encode(str) {
    return btoa(unescape(encodeURIComponent(str || "")));
  }

  function decode(bytes) {
    var escaped = escape(atob(bytes || ""));
    try {
      return decodeURIComponent(escaped);
    } catch {
      return unescape(escaped);
    }
  }

  if (challengeSubmissionResponse?.isLoading)
    return (
      <div className="h-full w-full z-10">
        <p>Loading...</p>
      </div>
    );
  if (challengeSubmissionResponse?.isError)
    return <div className="text-dark">Can't find submissions. Try later</div>;

  return (
    <Accordion allowMultipleExpanded className="m-1">
      {submissions?.length > 0 ? (
        submissions?.map((item, index) => (
          <AccordionItem eventKey={index} key={index}>
            <AccordionItemHeading>
              <AccordionItemButton
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  className="flex"
                  onClick={() => {
                    fetchPrevSubmission(index);
                  }}
                >
                  {item?.passed ? (
                    <BsCheckCircleFill
                      color="green"
                      size={24}
                      style={{ marginRight: "10px" }}
                    />
                  ) : (
                    <FaTimesCircle
                      color="red"
                      size={24}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  Submitted on{" "}
                  {moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="m-2">
              <div className="border-2 bg-slate rounded-sm">
                {item?.testcasesdata?.map((testcase, index) => (
                  // className="bg-grey p-2 m-1 rounded"
                  <div className="p-2">
                    <p className="flex">
                      <span>Test {index + 1}</span>
                      <span>
                        {testcase?.passed === true ? (
                          <BsCheckCircleFill
                            color="green"
                            size={24}
                            style={{ marginLeft: "10px" }}
                          />
                        ) : (
                          <FaTimesCircle
                            color="red"
                            size={24}
                            style={{ marginLeft: "10px" }}
                          />
                        )}
                      </span>
                    </p>
                    {testcase && <p className="text-xs">{testcase?.title}</p>}
                  </div>
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))
      ) : (
        <div className="text-dark">No submissions yet</div>
      )}
    </Accordion>
  );
}

export default Results;
