import { TrashIcon } from "@heroicons/react/20/solid";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { s } from "@heroicons/react/20/solid";
import { useState } from "react";
import useEditorStore from "zustand-store/editorStore";
function Explorer({
  currentIndex,
  handleOpenFile,
  files,
  handleDeleteFile,
  handleAddFile,
  onCtrlS,
}) {
  const [hoverIndex, setHoverIndex] = useState(null);
  const sourceFiles = useEditorStore((state) => state.sourceFiles);
  const setIsCurrentFileOriginal = useEditorStore(
    (state) => state.setIsCurrentFileOriginal
  );
  const setCurrentOriginalFile = useEditorStore(
    (state) => state.setCurrentOriginalFile
  );
  const openFile = useEditorStore((state) => state.openFile);
  return (
    <div>
      <div className="text-white p-2 text-sm font-semibold opacity-50 flex items-center justify-between">
        <small className="opacity-50">EXPLORER </small>
        <div className="flex items-center">
          <PlusCircleIcon
            onClick={handleAddFile}
            className="h-4 w-4 mx-2 cursor hover:bg-sky-700"
          />
          {/* <button className="hover:bg-sky-700" onClick={onCtrlS}>
            <small>Save</small>
          </button> */}
        </div>
      </div>
      <div className="">
        {files &&
          files?.map((file, index) => (
            <button
              key={index}
              className={`hover:bg-sky-700 w-full text-white text-left py-1 opacity-80 flex items-center justify-between flex-wrap ${
                file?.filename === openFile?.filename && "bg-gray-600"
              }`}
              onClick={() => {
                setCurrentOriginalFile(null);
                setIsCurrentFileOriginal(false);
                setTimeout(() => {
                  handleOpenFile(file, index, false);
                }, 400);
              }}
              onMouseOver={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <small className="mx-2">{file.filename}</small>
              {hoverIndex === index && (
                <TrashIcon
                  onClick={(e) => handleDeleteFile(e, file)}
                  className="h-4 w-4 mx-2"
                />
              )}
            </button>
          ))}
      </div>

      {sourceFiles?.length > 0 && (
        <>
          <div className="text-white p-2 text-sm font-semibold opacity-50 flex items-center justify-between mt-10">
            <small className="opacity-50">ORIGINAL CODE</small>
          </div>
          <div className="">
            {sourceFiles &&
              sourceFiles?.map((file, index) => {
                return (
                  <button
                    key={index}
                    className={`hover:bg-sky-700 w-full text-white text-left py-1 opacity-80 flex items-center justify-between italic`}
                    onClick={() => {
                      setCurrentOriginalFile(file);
                      setTimeout(() => {
                        setIsCurrentFileOriginal(true);
                      }, 500);
                    }}
                    // onMouseOver={() => setHoverIndex(index)}
                    // onMouseLeave={() => setHoverIndex(null)}
                  >
                    <small className="mx-2">{file.filename}</small>
                    {/* {hoverIndex === index && (
                      <TrashIcon
                        onClick={() => handleDeleteFile(index)}
                        className="h-4 w-4 mx-2"
                      />
                    )} */}
                  </button>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}

export default Explorer;
