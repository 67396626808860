import authService from "actions/services/auth.service";
import React from "react";
import { createContext, useState } from "react";

interface IUser {
  user:
    | {
        isadmin: boolean | null;
        isbootcamp: boolean | null;
        isbootcampenrolled: boolean | null;
        pendingassignments: boolean | null;
        username: string | null;
        email: string | null;
        isContentDrawerOpen: boolean;
        isQRPlaying: boolean;
        success: boolean;
      }
    | null
    | undefined;
  setUser: React.Dispatch<
    React.SetStateAction<
      | {
          isadmin: boolean | null;
          isbootcamp: boolean | null;
          isbootcampenrolled: boolean | null;
          pendingassignments: boolean | null;
          username: string | null;
          email: string | null;
          isContentDrawerOpen: boolean;
          isQRPlaying: boolean;
          success: boolean;
        }
      | null
      | undefined
    >
  >;
  userProfile:
    | {
        username: string;
        location: string;
        linkedIn: string;
        github: string;
        website: string;
      }
    | null
    | undefined;
  setUserProfile: React.Dispatch<
    React.SetStateAction<
      | {
          username: string;
          location: string;
          linkedIn: string;
          github: string;
          website: string;
        }
      | null
      | undefined
    >
  >;
  userNotSignedUp:
    | {
        xp: number;
        streaks: number;
        completedlectures: string[];
        courseid: string;
        lastwatchedid: string;
        digitalLiteracy: string;
        havePC: string;
        isComfortable: string;
        reelOnboardingComplete: string;
      }
    | null
    | undefined;
  setUserNotSignedUp: React.Dispatch<
    React.SetStateAction<
      | {
          xp: number;
          streaks: number;
          completedlectures: string[];
          courseid: string;
          lastwatchedid: string;
          digitalLiteracy: string;
          havePC: string;
          isComfortable: string;
          reelOnboardingComplete: string;
        }
      | null
      | undefined
    >
  >;
  userProgress:
    | {
        streaks: number | null;
        teamStreaks: number | null;
        completedlecturecount: number | null;
      }
    | null
    | undefined;
  setUserProgress: React.Dispatch<
    React.SetStateAction<
      | {
          streaks: number | null;
          teamStreaks: number | null;
          completedlecturecount: number | null;
        }
      | null
      | undefined
    >
  >;
  isProfileCompleted: boolean | null;
  isDemographicsCompleted: boolean | null;
  setIsProfileCompleted: React.Dispatch<React.SetStateAction<boolean | null>>;
  setIsDemographicsCompleted: React.Dispatch<
    React.SetStateAction<boolean | null>
  >;
}

const defaultValue: IUser = {
  user: {
    isadmin: null,
    isbootcamp: null,
    isbootcampenrolled: null,
    pendingassignments: null,
    username: null,
    email: null,
    isContentDrawerOpen: false,
    isQRPlaying: true,
    success: false,
  },
  isProfileCompleted: null,
  isDemographicsCompleted: null,
  setUser: () => {},
  userProfile: null,
  setUserProfile: () => {},
  userNotSignedUp: {
    xp: 0,
    streaks: 0,
    completedlectures: [],
    courseid: "",
    lastwatchedid: "",
    digitalLiteracy: "",
    havePC: "",
    isComfortable: "",
    reelOnboardingComplete: "",
  },
  setUserNotSignedUp: () => {},
  userProgress: {
    teamStreaks: null,
    streaks: null,
    completedlecturecount: null,
  },
  setUserProgress: () => {},
  setIsProfileCompleted: () => {},
  setIsDemographicsCompleted: () => {},
};

export const UserContext = createContext(defaultValue);

export const UserProvider: React.FC = ({ children }: any) => {
  const [user, setUser] = useState<IUser["user"]>(authService.getCurrentUser());
  const [userProgress, setUserProgress] = useState<IUser["userProgress"]>({
    teamStreaks: 0,
    streaks: 0,
    completedlecturecount: 0,
  });
  const [userProfile, setUserProfile] = useState<IUser["userProfile"]>(
    authService.getUserProfile()
  );
  const [userNotSignedUp, setUserNotSignedUp] = useState<
    IUser["userNotSignedUp"]
  >(authService.getUserNotSignedUpData(defaultValue.userNotSignedUp));
  const [isProfileCompleted, setIsProfileCompleted] = useState(
    defaultValue.isProfileCompleted
  );
  const [isDemographicsCompleted, setIsDemographicsCompleted] = useState(
    defaultValue.isDemographicsCompleted
  );
  console.log("\n\n\n\nuser from context\n\n", user);
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userProfile,
        setUserProfile,
        userNotSignedUp,
        setUserNotSignedUp,
        userProgress,
        setUserProgress,
        isProfileCompleted,
        setIsProfileCompleted,
        isDemographicsCompleted,
        setIsDemographicsCompleted,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
