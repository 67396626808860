import { OverViewContainer } from "pages/Competitions/Competitions.styled";
import MarkdownToReact from "pages/Competitions/MarkdownToReact";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ZaioCourses from "./ZaioCourses";

function Resources({ courses, otherresources }) {
  console.log("SPEC", otherresources);

  return (
    otherresources && (
      <OverViewContainer>
        {/* <div className="p-3 w-100 text-center">
          <h1>Coming soon.</h1>

          <p>Stay tuned to see resources to guide you in the competition.</p>
        </div> */}
        <Tab.Container defaultActiveKey={"zaio-courses"} className="h-100">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="zaio-courses">Zaio Courses</Nav.Link>
            </Nav.Item>
            {otherresources &&
              otherresources.map((item, index) => (
                <Nav.Item key={index}>
                  <Nav.Link eventKey={item._id}>{item.name}</Nav.Link>
                </Nav.Item>
              ))}
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="zaio-courses">
              <ZaioCourses courses={courses} />
            </Tab.Pane>
            {otherresources &&
              otherresources.map((item, index) => (
                <Tab.Pane key={index} eventKey={item._id}>
                  <MarkdownToReact url={item.info} />
                </Tab.Pane>
              ))}
          </Tab.Content>
        </Tab.Container>
      </OverViewContainer>
    )
  );
}

export default Resources;
