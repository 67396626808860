import React from "react";

function CompleteChallenge({ onNextClick, dataNext }) {
  return (
    <div>
      <span className="btn success-btn" onClick={() => onNextClick(dataNext)}>
        Next Lesson
      </span>
    </div>
  );
}

export default CompleteChallenge;
