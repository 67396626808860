import bootcamp from "actions/services/bootcamp.service";
import { Loading } from "components/Loader/Loader.styled";
import { UserContext } from "context/UserProvider";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import { useHistory, useLocation } from "react-router-dom";
import { getQueryParameters } from "Utils/AppUtils";
import { LuMoveLeft } from "react-icons/lu";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdAccessTime, MdDone, MdError } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";

export const DeferredStudentGoals = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { search } = useLocation();
  const { bootcampid: bootcampid_ } = getQueryParameters(search);
  const [bootcampid, setBootcampId] = useState(bootcampid_);
  const [loading, setLoading] = useState(false);
  const [goals, setGoals] = useState(null);
  const [otherDetails, setOtherDetails] = useState(null);
  const [deferredStudentBootcamps, setDeferredStudentBootcamps] = useState([]);

  const fetchGoals = async () => {
    const userid = user?.data?.userid || user?.userid;
    if (userid && bootcampid) {
      setLoading(true);
      try {
        const goals = await bootcamp.getDeferredStudentGoals({
          bootcampid,
          userid,
        });
        if (goals?.status === 200) {
          setGoals(goals?.deferredGoals);
          setOtherDetails({
            tutorDetails: goals?.tutorDetails,
          });
        }
      } catch (err) {
        console.log("Error in getting deferred student goals", err);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchAllDeferredBootcamps = async () => {
    try {
      const data = await bootcamp.getDeferredStudentBootcamps();
      if (data?.status === 200) {
        setDeferredStudentBootcamps(data?.allDeferredBootcamps);
      }
    } catch (err) {
      console.log("Error in getting deferred student bootcamps", err);
    }
  };

  useEffect(() => {
    fetchAllDeferredBootcamps();
  }, []);

  useEffect(() => {
    if (goals?.length === 0 || !goals) fetchGoals();
  }, [user, bootcampid]);

  if (loading) {
    return (
      <div>
        <Loading color={"#8437f9"}>
          <div>
            <div />
            <div />
            <div />
          </div>
        </Loading>
      </div>
    );
  }

  return (
    <div
      className="mx-5 my-2 rounded bg-white pt-2 px-4 h-min-100"
      style={{
        minHeight: "100vh",
      }}
    >
      <div className="d-flex align-items-center">
        <button
          onClick={() => {
            history.goBack();
          }}
        >
          <LuMoveLeft size={32} />
        </button>
        <p className="text-2xl m-0 ms-2 my-4">My Goals</p>

        {deferredStudentBootcamps?.length > 0 && (
          <div className="ml-auto">
            {/* Dropdown to select bootcamp ID */}
            <select
              className="form-select my-3"
              value={bootcampid}
              onChange={(e) => {
                console.log(e.target.value);
                setBootcampId(e?.target?.value);
                setGoals([]);
              }}
            >
              <option value="">Select Bootcamp</option>
              {deferredStudentBootcamps.map((bt) => (
                <option key={bt._id} value={bt.bootcampid?._id}>
                  {bt?.bootcampid?.bootcampName}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {goals?.length === 0 && (
        <p className="text-2xl m-0 my-4">
          {bootcampid ? "No goals found" : "Please select a Bootcamp first"}
        </p>
      )}

      {goals?.length > 0 &&
        goals?.map((goal) => (
          <div className="border rounded p-2 mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <p className="m-0 text-md">
                  <span className="font-bold">Goal starting on:</span>{" "}
                  {moment(goal.goalStartDate).format("MMMM Do YYYY, h:mm:ss A")}{" "}
                </p>
                <p className="m-0 text-md">
                  <span className="font-bold">Goal ending on:</span>{" "}
                  {moment(goal.goalEndDate).format("MMMM Do YYYY, h:mm:ss A")}{" "}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p className="m-0 text-md">
                <span className="font-bold">Goal set by:</span>{" "}
                {otherDetails?.tutorDetails?.company_username}
                </p>
                <p className="m-0 text-md">
                <span className="font-bold">Complete goal by:</span>{" "}
                  {moment(goal.goalDeadline).format("MMMM Do YYYY, h:mm:ss A")}{" "}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <button
                className="btn btn-outline-secondary m-0"
                onClick={() => {
                  history.push(
                    `/bootcamp-mode?learningpath=${goal?.learningpath}`
                  );
                }}
              >
                Take me to the Calendar
              </button>

              <div className="d-flex justify-content-end ms-2 border rounded align-items-center py-1 px-3">
                {/* Deadline Missed */}
                {goal?.deadlineMissed && (
                  <div className="d-flex align-items-center me-3 text-danger">
                    <MdError size={20} />
                    <p className="ms-1 m-0 fw-bold">Deadline Missed</p>
                  </div>
                )}

                {/* Completed */}
                {(goal?.isCompleted || goal?.status === "completed") && (
                  <div className="d-flex align-items-center me-3 text-success">
                    <MdDone size={20} />
                    <p className="ms-1 m-0 fw-bold">Completed</p>
                  </div>
                )}

                {/* In Progress */}
                {goal?.status === "in-progress" && (
                  <div className="d-flex align-items-center me-3 text-primary">
                    <MdAccessTime size={20} />
                    <p className="ms-1 m-0 fw-bold">In Progress</p>
                  </div>
                )}

                {/* Not Started */}
                {!goal?.deadlineMissed && goal?.status === "default" && (
                  <div className="d-flex align-items-center text-secondary">
                    <AiOutlineClockCircle size={20} />
                    <p className="ms-1 m-0 fw-bold">Not Started</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
