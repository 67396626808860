import { useContext, useEffect, useState } from 'react'
import Split from 'react-split'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import useVideoStore from 'zustand-store/videoPlayerStore'
import MarkdownToHTML from 'components/HTMLToReact/MarkdownToHTML'
import Results from './Results'
import DownloadBtn from 'components/Buttons/DownloadBtn'
import ZipFileInput from 'components/Inputs/ZipFileInput'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { Puff } from 'react-loader-spinner'
import lectureService from 'actions/services/lecture.service'
import useAssignmentStore from 'zustand-store/assignmentStore'
import SubmitCodeForm from 'components/Compiler/SubmitCodeForm'
import ReelModal from 'components/QuizReelComponents/ReelModal/ReelModal'
import { SfxContext } from 'context/SfxContext'
import CompleteChallenge from 'components/Compiler/CompleteChallenge'

function Backend({
  onCtrlS,
  preLoadedProjectFiles,
  onProjectFilesUpdate = undefined,
  lectureId,
  onNextClick = undefined,
  dataNext = null,
  assignment = false,
  refreshCourseData
}) {
  const { winSfx, hoverSfx } = useContext(SfxContext)
  const queryClient = useQueryClient()

  const [horizontalSizes, setHorizontalSizes] = useState([45, 55])
  const [consoleTab, setConsoleTab] = useState('input')
  const [questionTab, setQuestionTab] = useState('description')
  const [uploadFile, setUploadFile] = useState(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [result, setResult] = useState(null)
  const [modal, setModal] = useState({
    show: false,
    title: 'Challenge Completed! 🎉',
    description:
      'Congratulations! You have completed this challenge. You can now move on to the next lesson.',
    CustomComponent: null,
    props: null,
  })
  const [showAssignmentSubmission, setShowAssignmentSubmission] =
    useState(false)

  const challenge = useVideoStore((state) => state.challenge)
  const assignmentData = useAssignmentStore((state) => state.assignmentData)
  const currentQuestionId = useAssignmentStore(
    (state) => state.currentQuestionId
  )

  const handleFileSelect = (file) => {
    setUploadFile(file)
  }

  const handleFileDelete = () => {
    setUploadFile(null)
    setUploadPercent(0)
  }

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    })
  }

  const handleSubmitAssignmentBackend = async () => {
    setModal({
      ...modal,
      show: true,
      title: 'You are about to submit your code! ',
      description:
        'This cannot be reveresed, and your results will be returned as soon as its marked by a tutor/instructor.',
      CustomComponent: SubmitCodeForm,
      props: {
        assignmentSubmitCodeMutation: assignmentSubmitBackendMutation,
        setShowAssignmentSubmission,
        showAssignmentSubmission,
      },
    })
  }

  const handleSubmitSolution = () => {
    setResult(null)
    if (assignment) {
      handleSubmitAssignmentBackend()
    } else {
      setQuestionTab('submissions')
      challengeSubmitBackendMutation.mutate()
    }
  }

  const handleTestSolution = () => {
    setResult(null)
    if (assignment) {
      assignmentTestBackendMutation.mutate()
    } else {
      challengeTestBackendMutation.mutate()
    }
  }

  const challengeTestBackendMutation = useMutation(
    () =>
      lectureService.testChallengeBackendQuestion(
        lectureId,
        challenge._id,
        uploadFile
      ),
    {
      onSuccess: (data) => {
        console.log(data)
        setUploadFile(null)
        setResult(data)
      },
      onError: (error) => {
        console.log(error)
        setResult(error)
      },
    }
  )

  const challengeSubmitBackendMutation = useMutation(
    () =>
      lectureService.submitChallengeBackendQuestion(
        lectureId,
        challenge._id,
        uploadFile
      ),
    {
      onSuccess: (data) => {
        console.log(data)
        setUploadFile(null)
        setResult(data?.data)
        if (data?.data?.success || data?.success) {
          winSfx()
          setModal({
            ...modal,
            show: true,
            title: 'Challenge Completed! 🎉',
            description:
              'Congratulations! You have completed this challenge. You can now move on to the next lesson.',
            CustomComponent: CompleteChallenge,
            props: {
              onNextClick,
              dataNext,
            },
          })
        } else {
          setResult(data)
        }
        queryClient.invalidateQueries('challengeSubmissions')
        return queryClient.invalidateQueries('challengeSubmissions')
      },
      onError: (error) => {
        console.log(error)
        setResult(error)
      },
    }
  )

  const assignmentTestBackendMutation = useMutation(
    () =>
      lectureService.testAssignmentBackendQuestion(
        assignmentData?.assignmentid?._id,
        currentQuestionId,
        uploadFile
      ),
    {
      onSuccess: (data) => {
        console.log(data)
        setUploadFile(null)
        setResult(data)
      },
      onError: (error) => {
        console.log(error)
        setResult(error)
      },
    }
  )

  const assignmentSubmitBackendMutation = useMutation(
    () =>
      lectureService.submitAssignmentBackendQuestion(
        assignmentData?.assignmentid?._id,
        currentQuestionId,
        uploadFile
      ),
    {
      onSuccess: (data) => {
        console.log(data)
        setUploadFile(null)
        setResult(data)
        handleOnHide()
        setShowAssignmentSubmission(false)
        refreshCourseData()
      },
      onError: (error) => {
        console.log(error)
        setResult(error)
      },
    }
  )

  useEffect(() => {
    return () => {
      setUploadFile(null)
      setUploadPercent(0)
      setResult(null)
    }
  }, [])

  return (
    <div
      className='w-screen h-screen'
      style={{ overflow: 'hidden', height: '100vh', width: '100vw' }}
    >
      {modal.show && (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          props={modal.props}
          CustomComponent={modal.CustomComponent ?? false}
          // videoUrl={"https://youtu.be/Uv9URjIBtUQ"}
        />
      )}

      <Split
        className='split'
        sizes={horizontalSizes}
        onDragEnd={(s) => {
          console.log('onDragEnd', s)
          setHorizontalSizes(s)
        }}
      >
        <div className='h-screen bg-white p-2 overflow-y-scroll '>
          <p className='text-md font-bold uppercase'>
            {challenge?.challengename}
          </p>
          <Tab.Container
            defaultActiveKey={questionTab}
            activeKey={questionTab}
            className='h-100'
          >
            <Nav variant='pills'>
              <Nav.Item onClick={() => setQuestionTab('description')}>
                <Nav.Link eventKey={'description'}>Description</Nav.Link>
              </Nav.Item>
              {!assignment && (
                <Nav.Item onClick={() => setQuestionTab('submissions')}>
                  <Nav.Link eventKey={'submissions'}>Submissions</Nav.Link>
                </Nav.Item>
              )}
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey={'description'}>
                <div className='m-2'>
                  <MarkdownToHTML html={challenge?.questionfile} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={'submissions'}>
                <Results
                  lectureId={lectureId}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <div className='h-screen bg-black-400 text-white px-12'>
          {/* Download Section */}
          <div className='my-4'>
            <DownloadBtn
              assetLink={challenge?.starterfileurl}
              fileName='starter-code.zip'
            >
              Download Starter Code
            </DownloadBtn>
          </div>

          {/* Upload Section */}
          <div className='my-4'>
            <h2 className='my-2'>Upload Solution</h2>
            <div className='border-4 border-gray-500 rounded h-[20rem] grid place-content-center'>
              {uploadFile ? (
                <div className='flex flex-col items-center gap-4'>
                  <span className='text-2xl text-ellipsis max-w-xs whitespace-nowrap overflow-hidden'>
                    {uploadFile.name}
                  </span>
                  <button
                    onClick={handleFileDelete}
                    className='text-lg bg-red-700 hover:bg-red-600 px-3 py-1 rounded text-white'
                  >
                    Remove
                  </button>
                </div>
              ) : (
                <ZipFileInput variant='lg' onFileSelect={handleFileSelect} />
              )}
            </div>
            <div className='flex gap-2'>
              {assignmentTestBackendMutation.isLoading ||
              challengeTestBackendMutation.isLoading ? (
                <button className='w-full rounded justify-center flex my-2 py-2 bg-blue-700 opacity-50'>
                  <Puff
                    height='25'
                    width='25'
                    radius={1}
                    color='white'
                    ariaLabel='puff-loading'
                    wrapperStyle={{}}
                    wrapperClass=''
                    visible={true}
                  />
                </button>
              ) : (
                <button
                  disabled={!uploadFile}
                  onClick={handleTestSolution}
                  className='text-lg bg-blue-700 rounded my-2 py-2 w-full hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50'
                >
                  Test Solution
                </button>
              )}
              {assignmentSubmitBackendMutation.isLoading ||
              challengeSubmitBackendMutation.isLoading ? (
                <button className='w-full rounded justify-center flex my-2 py-2 bg-green-700 opacity-50'>
                  <Puff
                    height='25'
                    width='25'
                    radius={1}
                    color='white'
                    ariaLabel='puff-loading'
                    wrapperStyle={{}}
                    wrapperClass=''
                    visible={true}
                  />
                </button>
              ) : (
                <button
                  onClick={handleSubmitSolution}
                  disabled={
                    !uploadFile || assignmentTestBackendMutation.isLoading
                  }
                  className='text-lg bg-green-700 rounded my-2 py-2 w-full hover:bg-green-600 disabled:cursor-not-allowed disabled:opacity-50'
                >
                  Submit Solution
                </button>
              )}
            </div>
          </div>

          {/* Console Section */}
          <div className='my-4'>
            <h2 className='my-2'>Status</h2>
            <div className='border-4 border-gray-500 rounded py-4 px-2 max-h-40 overflow-scroll'>
              <div>
                {result ? (
                  <span className={!result.success ? 'text-red-600' : ''}>
                    {result?.message}
                  </span>
                ) : assignmentTestBackendMutation.isLoading ? (
                  <span>Checking...</span>
                ) : (
                  // now check for error in react query mutation
                  <></>
                )}
              </div>
              {/* 
                result.data is an array where each element `ele` is an object with the following structure
                ele.expected_output contains the expected output and ele.data contains the resulting output
                Show this result to the use with ✅ or ❌ the condition if ele.error is false or true.
               */}
              {result && result.data && Array.isArray(result.data) && (
                <div className='mt-4'>
                  {result.data.map((ele, index) => (
                    <div key={index}>
                      <div className='flex gap-2'>
                        <span className='text-lg'>Test Case {index + 1}</span>
                        <span
                          className={`text-lg ${
                            ele.error ? 'text-red-500' : 'text-green-500'
                          }`}
                        >
                          {ele.error ? '❌' : '✅'}
                        </span>
                      </div>
                      <div className='flex flex-col'>
                        <span className='text-lg min-w-max'>Your Output</span>
                        <span className='text-lg font-mono'>
                          {JSON.stringify(ele.data)}
                        </span>
                      </div>
                      <div className='flex flex-col'>
                        <span className='text-lg min-w-max'>
                          Expected Output
                        </span>
                        <span className='text-lg font-mono'>
                          {JSON.stringify(ele.expected_output)}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Split>
    </div>
  )
}

export default Backend
