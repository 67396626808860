import { LoadingContext } from "context/LoadingProvider";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import Realistic from "components/Confetti/Confetti";
import { useHistory, useParams } from "react-router-dom";
import Modal from "react-modal";
import "./Certificate.css";
import courseService from "actions/services/course.service";
import authService from "actions/services/auth.service";
import RSSUsage from "components/SocialShare/SocialShare";
import { OverlayTrigger, Popover } from "react-bootstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    background: "#05192d",
    padding: "2rem",
    borderRadius: "1rem",
    color: "#fff",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },
};

const courses = [
  "626055a21dd7340022782fbb",
  "602a18ae05614a00218fa391",
  "602bd41ec38d5f0021a60332",
  "602bfce33d47dd0021379ab9",
  "61dfd765dd6338d4afd53b73",
  "61e56bcceb846b939f8db978",
  "620505cd3749c16ced1b7d4a",
  "605485e6fdbaf00021b428f2",
  "60c1ff1e53e3bd002132394e",
  "60db25a003b0e8174a324f6f",
  "60587618c12b83ff0e7ff355",
  "605875fac12b83ff0e7ff354",
  "626916b4e14a640022ff8699",
  "60df089fa307d500210223b8",
  "60e4c9a0d1a7b60021bf5d9b",
  "610a376ae2667400219f09ab",
  "610a3a7ce2667400219f09ac",
  "610a403be2667400219f09ad",
  "610a4242e2667400219f09ae",
  "610a4402e2667400219f09af",
  "610a4696e2667400219f09b0",
  "610a4e33e2667400219f09b1",
  "626055a21dd7340022782fbb",
  "602a18ae05614a00218fa391",
  "602bd41ec38d5f0021a60332",
  "602bfce33d47dd0021379ab9",
  "605485e6fdbaf00021b428f2",
  "60c1ff1e53e3bd002132394e",
  "60db25a003b0e8174a324f6f",
  "60587618c12b83ff0e7ff355",
  "605875fac12b83ff0e7ff354",
  "60e4c9a0d1a7b60021bf5d9b",
  "610a4e33e2667400219f09b1",
  "626055a21dd7340022782fbb",
  "602a18ae05614a00218fa391",
  "602bd41ec38d5f0021a60332",
  "602bfce33d47dd0021379ab9",
  "61dfd765dd6338d4afd53b73",
  "605485e6fdbaf00021b428f2",
  "61e56bcceb846b939f8db978",
  "60c1ff1e53e3bd002132394e",
  "60db25a003b0e8174a324f6f",
  "60587618c12b83ff0e7ff355",
  "605875fac12b83ff0e7ff354",
  "626916b4e14a640022ff8699",
  "60df089fa307d500210223b8",
  "610a376ae2667400219f09ab",
  "610a3a7ce2667400219f09ac",
  "610a403be2667400219f09ad",
  "610a4242e2667400219f09ae",
  "610a4402e2667400219f09af",
  "610a4696e2667400219f09b0",
];

const Certificate = () => {
  const user = authService.getUserProfile();
  const history = useHistory();
  const { courseid } = useParams();
  const { setLoading, isloading } = useContext(LoadingContext);
  const [certificateLink, setCertificateLink] = useState(null);
  const [open, setOpen] = useState(false);
  const [grades, setGrades] = useState(null);
  const [finalScore, setFinalScore] = useState(null);
  const [nextCourse, setNextCourse] = useState(null);
  useEffect(() => {
    setLoading(true);
    console.log("user", user);
    if (!user.data.username) {
      setOpen(true);
      setLoading(false);
    } else {
      const getGrades = async () => {
        const course = await courseService.getCourse(courseid);
        console.log(course);
        if (course.success) {
          console.log(user.data.username, course.data.coursename);
          const certificate = await courseService.generateCertificate(
            courseid
          );
          // const certificate = await courseService.generateCertificateLink(
          //   user.data.username,
          //   course.data.coursename
          // );
          if (certificate.data.success) {
            setCertificateLink(certificate.data.result);
            const grades = await courseService.getGrades(courseid);
            setGrades(grades.data);
            let gradeArr = [], challengeTypeCount = 0, isCourseMCQ = 0;
            if(grades.data.coursemcqmarks > 0) {
              gradeArr.push(grades.data.coursemcqmarks || 0);
              challengeTypeCount++;
              isCourseMCQ = 1;
            }
            if(grades.data.challenges.passedchallenges > 0) {
              gradeArr.push((grades.data.challenges.passedchallenges/grades.data.challenges.totalchallenges) || 0);
              challengeTypeCount++;
            }
            if(grades.data.backendchallenges.passedbackendchallenges > 0) {
              gradeArr.push((grades.data.backendchallenges.passedbackendchallenges/grades.data.backendchallenges.totalbackendchallenges) || 0);
              challengeTypeCount++;
            }
            if(grades.data.frontendchallenges.passedfrontendchallenges > 0) {
              gradeArr.push((grades.data.frontendchallenges.passedfrontendchallenges/grades.data.frontendchallenges.totalfrontendchallenges) || 0);
              challengeTypeCount++;
            }

            switch(challengeTypeCount){
              case 1:
                setFinalScore(
                  gradeArr[0]
                );
                break;
              case 2:
                if(isCourseMCQ){
                  setFinalScore(
                    gradeArr[0] * 0.6 + gradeArr[1] * 0.4
                  );
                } else {
                  setFinalScore(
                    gradeArr[0] * 0.5 + gradeArr[1] * 0.5
                  );
                }
                break;
              case 3:
                setFinalScore(
                  (gradeArr[0] * 0.5)  + (gradeArr[1] + gradeArr[2]) * 0.25
                );
                break;
              case 4:
                setFinalScore(
                  (gradeArr[0] + gradeArr[1] + gradeArr[2] + gradeArr[3]) * 0.25
                );
                break;
              default: setFinalScore(0);
            }

            // if (
            //   grades.data.coursemcqmarks &&
            //   grades.data.challenges.passedchallenges &&
            //   grades.data.backendchallenges.passedbackendchallenges
            // ) {
            //   setFinalScore(
            //     grades.data.coursemcqmarks * 0.5 +
            //       (grades.data.challenges.passedchallenges /
            //         grades.data.challenges.totalchallenges) *
            //         0.25 +
            //       (grades.data.backendchallenges.passedbackendchallenges /
            //         grades.data.challenges.totalbackendchallenges) *
            //         0.25
            //   );
            // } else if (
            //   grades.data.coursemcqmarks &&
            //   grades.data.challenges.passedchallenges &&
            //   !grades.data.backendchallenges.passedbackendchallenges
            // ) {
            //   setFinalScore(
            //     grades.data.coursemcqmarks * 0.6 +
            //       (grades.data.challenges.passedchallenges /
            //         grades.data.challenges.totalchallenges) *
            //         0.4
            //   );
            // } else if (
            //   grades.data.coursemcqmarks &&
            //   !grades.data.challenges.passedchallenges &&
            //   grades.data.backendchallenges.passedbackendchallenges
            // ) {
            //   setFinalScore(
            //     grades.data.coursemcqmarks * 0.6 +
            //       (grades.data.backendchallenges.passedbackendchallenges /
            //         grades.data.challenges.totalbackendchallenges) *
            //         0.4
            //   );
            // } else if (
            //   !grades.data.coursemcqmarks &&
            //   grades.data.challenges.passedchallenges &&
            //   grades.data.backendchallenges.passedbackendchallenges
            // ) {
            //   setFinalScore(
            //     (grades.data.challenges.passedchallenges /
            //       grades.data.challenges.totalchallenges) *
            //       0.5 +
            //       (grades.data.backendchallenges.passedbackendchallenges /
            //         grades.data.challenges.totalbackendchallenges) *
            //         0.5
            //   );
            // } else if (
            //   grades.data.coursemcqmarks &&
            //   !grades.data.challenges.passedchallenges &&
            //   !grades.data.backendchallenges.passedbackendchallenges
            // ) {
            //   setFinalScore(grades.data.coursemcqmarks);
            // } else if (
            //   !grades.data.coursemcqmarks &&
            //   grades.data.challenges.passedchallenges &&
            //   !grades.data.backendchallenges.passedbackendchallenges
            // ) {
            //   setFinalScore(
            //     grades.data.challenges.passedchallenges /
            //       grades.data.challenges.totalchallenges
            //   );
            // } else if (
            //   !grades.data.coursemcqmarks &&
            //   !grades.data.challenges.passedchallenges &&
            //   grades.data.backendchallenges.passedbackendchallenges
            // ) {
            //   setFinalScore(
            //     grades.data.backendchallenges.passedbackendchallenges /
            //       grades.data.challenges.totalbackendchallenges
            //   );
            // }
            if (grades.success) {
              const next = await courseService.getNextCourse(courseid);
              console.log(next);
              setNextCourse(next.data._id);
              setLoading(false);
            }
          }
        }
      };
      getGrades();
    }
  }, []);

  const profile = () => {
    history.push("/profile");
    setOpen(false);
  };

  const nextPage = () => {
    if (courses.indexOf(nextCourse) === -1) {
      history.push(`/recommend/${nextCourse}`);
      setLoading(true);
    } else {
      history.push(`/next-course/${nextCourse}`);
      setLoading(true);
    }
  };

  const popover = (
    <Popover>
      <RSSUsage url={certificateLink} />
    </Popover>
  );

  if (isloading) return <div></div>;

  return (
    <div className="main">
      <Modal
        // defaultStyles={{ overlay: { background: "#05192d" } }}
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="No username"
      >
        <h3>Enter your username first</h3>
        <button className="next-course-button" onClick={profile}>
          Go to Profile
        </button>
      </Modal>
      <Realistic />
      <div className="certificate_container">
        <div className="certificate">
          <img
            src={certificateLink}
            alt="certificate"
            className="certificate_image"
          />
        </div>
        <div className="course_result">
          <h3>Achievements</h3>
          {grades?.coursemcqmarks && (
            <div className="remark">
              <GiCheckMark className="check" />
              <h5>{(grades?.coursemcqmarks * 100).toFixed(2)}% MCQ average</h5>
            </div>
          )}
          {grades?.challenges.totalchallenges ? (
            <div className="remark">
              <GiCheckMark className="check" />
              <h5>
                {grades.challenges.passedchallenges}/
                {grades.challenges.totalchallenges} challenges
              </h5>
            </div>
          ) : null}
          {grades?.backendchallenges.totalbackendchallenges ? (
            <div className="remark">
              <GiCheckMark className="check" />
              <h5>
                {grades.backendchallenges.passedbackendchallenges}/
                {grades.backendchallenges.totalbackendchallenges} backend
                challenges
              </h5>
            </div>
          ) : null}
          {grades?.frontendchallenges.totalfrontendchallenges ? (
            <div className="remark">
              <GiCheckMark className="check" />
              <h5>
                {grades.frontendchallenges.passedfrontendchallenges}/
                {grades.frontendchallenges.totalfrontendchallenges} frontend
                challenges
              </h5>
            </div>
          ) : null}
          {grades?.assignments.totalassignments ? (
            <div className="remark">
              <GiCheckMark className="check" />
              <h5>
                {grades.assignments.completedassignments}/
                {grades.assignments.totalassignments} assignments
              </h5>
            </div>
          ) : null}
          <div className="remark">
            <h5>Course mark</h5>
            <h3>{(finalScore * 100).toFixed(2)}%</h3>
          </div>
        </div>
      </div>
      <div className="share">
        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
          <button className="share-button">Share Certificate</button>
        </OverlayTrigger>
        <button className="next-course-button" onClick={nextPage}>
          Continue to Next course
        </button>
      </div>
    </div>
  );
};

export default Certificate;
