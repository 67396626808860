import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { DiHtml5, DiCss3, DiReact } from "react-icons/di";

import classes from "./Accordion.module.css";
// import "./Accordion.css";

import { ProgressBar, Row, Col } from "react-bootstrap";

import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { AiFillPlayCircle } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import img1 from "../img/img1.png";
import img2 from "../img/img2.png";
import img3 from "../img/img3.png";
import Lock from "../img/Lock.png";

import learningpathService from "actions/services/learningpath.service";
import Stats from "components/QuizReelComponents/Header/Stats";
import { LoadingContext } from "context/LoadingProvider";
import Course from "./Course";
import { courseCompletionEventTracking } from "Utils/WebEngage";

function Accordion(props) {
  const elementRef = useRef(null);
  const [learningPath, setLearningPath] = useState({});
  const history = useHistory();
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");
  const [cardHeight, setCardHeight] = useState(270);
  const [completedCoursePercentage, setCompletedCoursePercentage] = useState(0);

  const { fromTeamPath } = props;
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setCardHeight(elementRef?.current?.scrollHeight);

    // setCardHeight(elementRef.current.clientHeight)
  }, []);

  useEffect(() => {
    const learningPathHandler = async () => {
      // setLoading(true);
      let res = null;

      if (fromTeamPath) {
        console.log("FROM TEAM PAYTH", props);
        setLearningPath({
          learningpathcourses: props.courseData,
        });
        await calculateCompletedPercentage(props.courseData);
      } else {
        try {
          res = await learningpathService.getLearningPath(props.learningId);
          console.log("FROM ACCORDION", res.data);
          setLearningPath({
            ...res.data,
          });
          calculateCompletedPercentage(res.data.learningpathcourses);
          courseCompletionEventTracking({
            ...res.data,
            completedCoursePercentage,
          });
        } catch (error) {
          console.log(error);
          history.push("/somethingwentwrong");
        }
      }
      // setLoading(false);
    };

    learningPathHandler();
    // calculateCompletedPercentage();
  }, [completedCoursePercentage]);

  useEffect(() => {}, [height]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
    // learningPathHandler(props.learningId);
  }

  const calculateCompletedPercentage = (lpCourses) => {
    let ccp = 0;

    if (fromTeamPath) {
      lpCourses.map((course) => (ccp += course.completedPercentage));
      // console.log("completedCoursePercentage before rounding", ccp);

      ccp = ccp / lpCourses.length;
    } else {
      Object.values(lpCourses)
        .filter((item) => item)
        .map((course) => (ccp += course.completedPercentage));

      ccp = ccp / Object.values(lpCourses).filter((item) => item).length;
    }

    ccp = Math.round(ccp * 100) / 100;

    setCompletedCoursePercentage(ccp);
  };

  return (
    <div className={classes.accordion__section}>
      <div
        className={`${classes.accordion} ${classes.box} ${
          props.timepath && classes.box2
        } ${active ? "" : ""}`}
        onClick={toggleAccordion}
      >
        {/* <button
          onClick={() => {
            history.push(`bootcamp-mode?learningpath=${learningPath?._id}`);
          }}
        >
          bootcamp
        </button> */}
        <div>
          <p className={classes.contentTitle}>
            {props.learner}
            {props.learner && "'s"} {props.title}
          </p>
          <div className={classes.iconsandTitle}>
            <span className={classes.contentHeading}>{props.heading}</span>
            <div className={classes.wrapper}>
              <a href="#" className={`${classes.icon} ${classes.html}`}>
                <DiHtml5 />
              </a>
              <a href="#" className={`${classes.icon} ${classes.css}`}>
                <i className="fab fa-instagram"></i>
                <DiCss3 />
              </a>
              <a href="#" className={`${classes.icon} ${classes.react}`}>
                <i className="fab fa-google-plus-g"></i>
                <DiReact />
              </a>
            </div>
          </div>
          <div
            className={`${classes.progressBar} ${
              classes.mobileViewProgressBar
            } ${props.timepath && classes.progressBar2} ${
              props.timepath && classes.mobileViewProgressBar2
            } `}
          >
            <label className={classes.lable} htmlFor="file">
              Progress{" "}
              <span className={classes.percentageIcon}>
                {completedCoursePercentage}%
              </span>
            </label>
            <ProgressBar
              now={completedCoursePercentage}
              className={classes.courseProgressBar}
              style={{ width: "100 %" }}
            />
          </div>
        </div>

        {/* {props.showXPstreaks && (
          <div>
            <Stats />
          </div>
        )} */}

        <div className={classes.p}>
          {/* <Stats /> */}
          <div
            className={`${classes.progressBar} ${
              props.timepath && classes.progressBar2
            }`}
          >
            <label className={classes.lable} htmlFor="file">
              Progress{" "}
              <span className={classes.percentageIcon}>
                {completedCoursePercentage}%
              </span>
            </label>

            <ProgressBar
              now={completedCoursePercentage}
              className={classes.courseProgressBar}
              style={{ width: "100 %" }}
            />
          </div>

          <span style={{ marginLeft: "20px", color: "#909090" }}>
            {active ? <BsChevronUp /> : <BsChevronDown />}
          </span>
        </div>
      </div>

      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className={classes.accordion__content}
      >
        {/* <div className={classes.accordion__innerBody}>

        </div> */}
        {!fromTeamPath
          ? learningPath.learningpathcourses &&
            Object.values(learningPath.learningpathcourses)
              .filter((lec) => lec?.showondesktop)
              ?.map((course, index) => {
                if (course)
                  return (
                    <Course
                      elementRef={elementRef}
                      fromTeamPath={fromTeamPath}
                      course={{
                        ...course,
                        learningPathName: learningPath?.learningpathname,
                      }}
                      cardHeight={cardHeight}
                    />
                  );
              })
          : learningPath.learningpathcourses &&
            learningPath.learningpathcourses
              .filter((lec) => lec?.showondesktop)
              ?.map(
                (course, index) =>
                  course?.courseDetails && (
                    <Course
                      elementRef={elementRef}
                      fromTeamPath={fromTeamPath}
                      course={{
                        ...course.courseDetails,
                        completedPercentage: course?.completedPercentage,
                      }}
                      cardHeight={cardHeight}
                    />
                  )
              )}

        {!fromTeamPath &&
          learningPath?.lockedcourses &&
          learningPath?.lockedcourses.map((lockedCourse, index) => {
            return (
              <Course
                elementRef={elementRef}
                fromTeamPath={fromTeamPath}
                course={{
                  ...lockedCourse?.course,
                  price: lockedCourse?.price,
                  completedPercentage: lockedCourse?.completedPercentage,
                }}
                cardHeight={cardHeight}
                lockedCourse={!lockedCourse?.unlocked}
              />
            );
          })}
      </div>
    </div>
  );
}

export default Accordion;
