import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import classes from './MyProfile.module.css';

import { ProgressBar, Modal, Button } from 'react-bootstrap';
import { LoadingContext } from 'context/LoadingProvider';

import learningpathService from 'actions/services/learningpath.service';
import courseService from "actions/services/course.service";
import CourseList from "./../../pages/Course/CourseList";

import Loader from "../../components/Loader/Loader";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const EnrolledCourse = (props) => {
  const [learningPath, setLearningPath] = useState([]);
  const [viewCount, setViewCount] = useState(3);
  const [counter, setCounter] = useState(0);
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const [course, setCourse] = useState(null);
  const [showCourseList, setShowCourseList] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const getAllCourse = async () => {
    let res = null;
    let tempLearningPath = [];
    let learningIds = [];
    //setLoading(true);

    let count = 0;

    await new Promise((resolve) => {
      // console.log("props.learningPathList", props.learningPathList,props.email)
      Object.values(props.learningPathList).forEach(async (item) => {
        console.log("item", item)
        if (props.email) {
          res = await learningpathService.getLearningPathUserProfile(
            item?.learningpathid?._id,
            props.email
          );
          console.log("rest", res)
        }
         else {
          res = await learningpathService.getLearningPath(item?.learningpathid?._id);
          console.log("res2", res)
        }


        console.log("res", res)
        Object.values(res.data?.learningpathcourses).forEach((learning) => {
          if (!learningIds.includes(learning._id)) {
            learningIds.push(learning._id);
            tempLearningPath.push(learning);
            setLearningPath(tempLearningPath);
            props.setTotalCourse(tempLearningPath);
          }
        });

        count += 1;
        if (count === Object.keys(props.learningPathList).length) {
          //setLoading(false);
          resolve();
        }
      });
    }).then(() => {
      // console.log('all done *****************************************88');
      //setLoading(false);
    });
  };

  useEffect(() => {
    const learningPathHandler = async () => {
      try {
        getAllCourse();
      } catch (error) {
        console.log(error);
        history.push('/somethingwentwrong');
      }
    };

    learningPathHandler();
  }, [props.learningPathList]);

  let timer1;
  if (counter < 11) {
    timer1 = setTimeout(() => {
      setCounter(counter + 1);
    }, 1000);
  }

  useEffect(() => {
    if (counter > 11) {
      clearTimeout(timer1);
    }
  }, [counter]);

  const handleSetView = () => {
    if (viewCount === 3) {
      setViewCount(learningPath.length);
    } else {
      setViewCount(3);
    }
  };

  const handleCheck = async (id) => {
    setShowCourseList(true);
    setIsLoading(true);
    const _response = await courseService.getCourse(id);
    const completedLectures = _response.data.completedLectures.map(
      (item) => item._id
      );
    setCourse({
      ..._response.data,
      completedLectures,
    });
    setIsLoading(false);
  }

  return (
    <>
      {learningPath &&
        learningPath.slice(0, viewCount).map((course, index) => (
          <div className={classes.box}>
            <div>
              <p className={classes.contentTitle}>
                <span className={classes.proIcon}>PRO</span> Skill Path
              </p>
              <div className={classes.iconsandTitle}>
                <span className={classes.contentHeading}>{course.coursename}</span>
              </div>
              <div className={`${classes.progressBar} ${classes.mobileViewProgressBar}`}>
                <label className={classes.lable} htmlFor="file">
                  {/* Progress */}
                  <span className={classes.percentageIcon}>{course.completedPercentage}%</span>
                </label>
                <ProgressBar
                  now={course.completedPercentage}
                  className={classes.courseProgressBar}
                  style={{ width: '100 %' }}
                />
                <button className="btn bg-teal-100" onClick={() => handleCheck(course._id)}>View More</button>
              </div>
            </div>
            <div className={classes.p}>
              <div className={classes.progressBar}>
                <label className={classes.lable} htmlFor="file">
                  {/* Progress */}
                  <span className={classes.percentageIcon} style={{ color: "white" }}>{course.completedPercentage}%</span>
                </label>
                <ProgressBar
                  now={course.completedPercentage}
                  className={classes.courseProgressBar}
                  style={{ width: '100 %' }}
                />
                {/* <button className="px-4 text-white" onClick={() => handleCheck(course._id)}>View More</button> */}
              </div>
            </div>
          </div>
        ))}
      <p
        className={`${classes.viewAllText} ${classes.underlineP}`}
        onClick={handleSetView}
        style={{ visibility: `${learningPath.length <= 3 ? 'hidden' : ''}` }}
      >
        {viewCount > 3 ? 'collapse' : 'View all'}
      </p>
      <Modal
        centered
        show={showCourseList}
        onHide={() => setShowCourseList(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>All Resources</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading == false ?
            <Accordion allowMultipleExpanded>
              <CourseList
                course={course}
                checkbox={true}
              // updateUnitList={updateUnitList}
              // updateLectureList={updateLectureList}
              // edit={props.edit}
              // setUpdate={setUpdate}
              // setnewlecture={setNewLecture}
              // setNewUnit={setNewUnit}
              // courseid={courseid}
              // indexListChange={indexListChange}
              // setLoading={setLoading}
              />
            </Accordion>
            : <p style={{ margin: "auto" }}>Loading...</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn bg-[red]' onClick={() => setShowCourseList(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnrolledCourse;
