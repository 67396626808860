import React, { useState, useEffect } from "react";

export default function ScoreIndicator({
  score,
  maxScore,
  showPercentage = true,
  width = 160,
  height = 160,
}) {
  const [displayScore, setDisplayScore] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayScore(score);
    }, 500);
    return () => clearTimeout(timer);
  }, [score]);

  const percentage = maxScore === 0 ? 0 : (displayScore / maxScore) * 100;
  const radius = Math.min(width, height) / 2 - 10; // 10 is the stroke width
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  const displayValue = showPercentage
    ? `${Math.round(percentage)}%`
    : displayScore.toString();

  const getColor = (percent) => {
    if (percent > 66) return "#10B981"; // green
    if (percent >= 40) return "#FBBF24"; // yellow
    return "#EF4444"; // red
  };

  const color = getColor(percentage);

  return (
    <div
      className="relative"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <svg className="w-full h-full" viewBox={`0 0 ${width} ${height}`}>
        {/* Background circle */}
        <circle
          cx={width / 2}
          cy={height / 2}
          r={radius}
          fill="none"
          stroke="#e6e6e6"
          strokeWidth="5"
        />
        {/* Progress circle */}
        <circle
          cx={width / 2}
          cy={height / 2}
          r={radius}
          fill="none"
          stroke={color}
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          transform={`rotate(-90 ${width / 2} ${height / 2})`}
          className="transition-all duration-1000 ease-in-out"
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span
          className="text-lg font-bold"
          style={{ color: color }}
          aria-live="polite"
        >
          {displayValue}
        </span>
      </div>
      {/* Glowing effect */}
      <div
        className="absolute inset-0 rounded-full blur opacity-50"
        style={{
          background: `radial-gradient(circle, ${color}66 0%, ${color}00 70%)`,
        }}
      />
    </div>
  );
}
