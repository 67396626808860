import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import learningpathService from "actions/services/learningpath.service";
import { useContext } from "react";

import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "context/UserProvider";
import LearningPath from "components/LearningPath/LearningPath";
import { Courses } from "./../../Constants"
import { IoIosArrowBack } from "react-icons/io";


function CoursePreferenceQuiz(props) {
  let redUrl;
  if (process.env.REACT_APP_MODE === "sandbox") {
    redUrl = `${process.env.REACT_APP_BACKEND_URL}/payment`;
  } else {
    redUrl = "https://www.zaio.io/payment";
  }

  const [find, setFind] = useState(false);
  const [step, setStep] = useState(0);

  const [lp, setLp] = useState([]);


  const { user, setUser } = useContext(UserContext);

  useEffect(() => {

    const funct = async () => {
      const res = await learningpathService.getLearningPaths();
      console.log("=================================");
      console.log(res);
      setLp(res.data);
    };
    funct();
    // if ( props.location.state && props.location.state.prevPath == "/getstarted") {
    //   return notify
    // }
  }, []);

  const onFindCourse = (val) => {
    setStep(val+50);
    if(step === 100) setFind(val)
  };

  const history = useHistory();
  
  const [data, setData] = useState({
    installmentAmount: "",
    paymentBrand: "visa",
    couponcode: "",
    cardNumber: "4111111111111111",
    cardHolder: "Raj rones",
    expiryMonth: "11",
    expiryYear: "2035",
    cvv: "123",
    coupondiscountadded: null,
    name: "",
    courseid: "",
    valid:false,
    paid:"",
    total:"",
    intrest:""
  });
  const [state, setState] = useState({
    success: !!user,
    completed: !!user ? (user.hasOwnProperty("data")? (user.data.hasOwnProperty("completed")? user.data.completed : false) :!!user.completed): false,
    username: !!user ? (user.hasOwnProperty("data")? (user.data.hasOwnProperty("username")? true : false) :!!user.username): false,
    redirect: "/login",
    src: "zaio.io",
  });

  const Step1 = () => (
      <>
        <div className="row mt-5 align-items-center  no-gutters">
            <IoIosArrowBack color="#fff"/>
            <Link className="ml-1 text-white" to="dashboard">Back</Link>
        </div>
    <div className="mt-3 mb-4">
        <h2 className="text-white mediumText">What is your goal?</h2>
    </div>
    <div className="row pb-5">
        <div className="col-sm-12 col-md-6">
            <div className="bg-white p-3 rounded align-items-center ">
                <input type="radio" name="question" />
                <span className="ml-2 text-16 text-black-50">Secure your carrer</span>
            </div>
        </div>
        <div className="col-sm-12 col-md-6">
            <div className="bg-white p-3 rounded align-items-center ">
                <input type="radio" name="question" />
                <span className="ml-2 text-16 text-black-50">For fun (hobby)</span>
            </div>
        </div>
        <div className="col-sm-12 col-md-6">
            <div className="bg-white p-3 rounded align-items-center ">
                <input type="radio" name="question" />
                <span className="ml-2 text-16 text-black-50">Upgrade my career</span>
            </div>
        </div>
        <div className="col-sm-12 col-md-6">
            <div className="bg-white p-3 rounded align-items-center ">
                <input type="radio" name="question" />
                <span className="ml-2 text-16 text-black-50">I’m not sure</span>
            </div>
        </div>
        <div className="col-sm-12 col-md-6">
            <div className="bg-white p-3 rounded align-items-center ">
                <input type="radio" name="question" />
                <span className="ml-2 text-16 text-black-50">Freelancing</span>
            </div>
        </div>
    </div>

    <div className="mt-5 d-flex align-items-center">
        <div className="progress w-100">
            <div role="progressbar" className={`progress-bar bg-warning w-${step}`} aria-valuenow={`${step}`} aria-valuemin="0" aria-valuemax="100">{step}%</div>
        </div>
        <button className="btn btn-warning ml-2" onClick={()=> onFindCourse(step) }>
            Next
        </button>
    </div>
    </>
  );

  const Step2 = () => (
    <>
      <div className="row mt-5 align-items-center  no-gutters">
            <IoIosArrowBack color="#fff"/>
            <Link className="ml-1 text-white" to="dashboard">Back</Link>
        </div>
  <div className="mt-3 mb-4">
      <h2 className="text-white mediumText">What here interests you the most?</h2>
  </div>
  <div className="row pb-5">
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">Design Web Apps</span>
          </div>
      </div>
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">End to end system (e.g. building an Uber)</span>
          </div>
      </div>
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">Building games</span>
          </div>
      </div>
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">I’m not sure</span>
          </div>
      </div>
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">Backend systems</span>
          </div>
      </div>
  </div>

  <div className="mt-5 d-flex align-items-center">
      <div className="progress w-100">
          <div role="progressbar" className={`progress-bar bg-warning w-${step}`} aria-valuenow={`${step}`} aria-valuemin="0" aria-valuemax="100">{step}%</div>
      </div>
      <button className="btn btn-warning ml-2" onClick={()=> onFindCourse(step) }>
          Next
      </button>
  </div>
  </>
);


const Step3 = () => (
    <>
      <div className="row mt-5 align-items-center  no-gutters">
            <IoIosArrowBack color="#fff"/>
            <Link className="ml-1 text-white" to="dashboard">Back</Link>
        </div>
  <div className="mt-3 mb-4">
      <h2 className="text-white mediumText">Which of these statements apply to you??</h2>
  </div>
  <div className="row pb-5">
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">I love maths and im good at it</span>
          </div>
      </div>
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">Improve efficiency of a website excites me</span>
          </div>
      </div>
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">I like design, fashion or art</span>
          </div>
      </div>
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">I’m not sure</span>
          </div>
      </div>
      <div className="col-sm-12 col-md-6">
          <div className="bg-white p-3 rounded align-items-center ">
              <input type="radio" name="question" />
              <span className="ml-2 text-16 text-black-50">I enjoy the look and feel of websites</span>
          </div>
      </div>
  </div>

  <div className="mt-5 d-flex align-items-center">
      <div className="progress w-100">
          <div role="progressbar" className={`progress-bar bg-warning w-${step}`} aria-valuenow={`${step}`} aria-valuemin="0" aria-valuemax="100">{step}%</div>
      </div>
      <button className="btn btn-warning ml-2" onClick={()=> onFindCourse(step) }>
          Next
      </button>
  </div>
  </>
);
  
  if (!state.success) {
      history.push(`/login`);
      return null;
  }else if(state.success && state.username && lp.length === 0 ) {
    
   return (
    <div className="container pt-5 pb-5">
        {!find ? <>
            {step === 0 ? <Step1 />: 
                step === 50 ? <Step2 />: <Step3 />}
         </>:
        <>
        <div className="row mt-5 align-items-center  no-gutters">
            <IoIosArrowBack color="#fff"/>
            <Link className="ml-1 text-white" to="dashboard" onClick={()=> onFindCourse(false)}>Back</Link>
        </div>
        <div className="row mt-3 mb-4 justify-content-between no-gutters">
            <h2 className="text-white mediumText">Based on your responses, we recommend:</h2>
            <Link className="text-white text-underline">Wanna see more recommendations?</Link>
        </div>
        <div className="row pb-5">

        {
            [{"key":"IC", "values":"Introduction to Coding"}, {"key":"FS", "values":"Full Stack Web Developer"}, {"key":"BE", "values":"Backend Engineering"}, {"key":"FE", "values":"Frontend Engineering"}, ].slice(0,2).map((val, key) => {
            const ptCourses = Courses.filter((obj) => obj.lp === val.key);
            return  <LearningPath key={key} lp={val.key} fullLp={val.values} courses={ptCourses} pop={val.key==="FS"} />
            })
        }
            
        </div></>
        }
    </div>
    );
  } else if (state.success && !state.username ) {
    history.push(`/completesignin`);
    console.log(state);
    return null;
  }
}

export default CoursePreferenceQuiz;
