import React, { useState, useEffect, useMemo } from 'react'
import adminService from "actions/services/admin.service";
import getDemo from "actions/services/admin.service"
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import UserTable from './UserTable'

function SupportSession() {
    const [timeLength, setTimeLength] = useState({ startTimeLength: 0, endTimeLenght: 0 })
    const [paidUserData, setPaidUserData] = useState();
    const [paidUserName, setPaidUserName] = useState();
    const [selected, setSelected] = useState();
    const [emailSelected, setEmailSelected] = useState();
    const [nameSelectedTo, setNameSelectedto] = useState();
    const [allData, setAllData] = useState([]);
    const [emailId, setEmailId] = useState('');
    const [totalMinutes, setTotalMinutes] = useState(0);


    const [formData, setFormData] = useState({
        tutorName: '',
        startTime: '',
        endTime: '',
        issueResolved: ''
    });
    const [topUpAmoount, setTopUpAmount] = useState(0);
    const [resMsg, setResMsg] = useState('');

    // const getUserPaid = async () => {
    //     const resp = await adminService.getPaidUsers();
    //     const getUSer = await getDemo.getUsers();
    //     // setPaidUserName(getUSer.data.data.map((item) => item.name))
    //     setPaidUserName(getUSer.data.data.map((item) => `${item.name} ( ${item.email} )`))
    //     setPaidUserData(getUSer.data.data.map((item) => item.email))
    //     if (nameSelectedTo) {
    //         const fill = getUSer?.data?.data?.find((item) => item?.name === nameSelectedTo?.toString());
    //         setEmailId(fill?.email);
    //     }
    // }

    const getUserPaid = async () => {
        // const resp = await adminService.getPaidUsers();
        const getUSer = await getDemo.userTutorAccount();
        setAllData(getUSer);
        setPaidUserName(getUSer.data.map((item) => `${item?.userid?.username} ( ${item?.userid?.email} )`))
        setPaidUserData(getUSer.data.map((item) => item?.userid?.email))

    }

    const loadTotalMinutes = async () => {
        const resp = await adminService.getTotalMinutes();
        const getMinutes = resp.data.map((item) => item.cost / 3.5)
            .reduce((acc, comp) => acc + comp, 0);
        setTotalMinutes(getMinutes);
    }

    useEffect(() => {
        getUserPaid();
        loadTotalMinutes();
    }, [])

    useEffect(() => {
        if (nameSelectedTo) {
            const fill = allData.data?.find((item) => item?.userid?.username === nameSelectedTo?.toString());
            setEmailId(fill?.userid?.email);
        }
    }, [nameSelectedTo])


    const { tutorName, startTime, endTime, issueResolved } = formData;

    const handleChange = (e) => {
        let { name, value } = e.target;
        if ((name !== "startTime" && name !== "endTime") || (value.length <= 5 && !value.endsWith("::"))) {
            if (value.length === 2 && ((name === "startTime" && value.length > timeLength.startTimeLength) || (name === "endTime" && value.length > timeLength.endTimeLength))) {
                value += ":";
            }
            setFormData({
                ...formData,
                [name]: value
            })
            if (name === 'startTime') {
                setTimeLength({ ...timeLength, startTimeLength: value.length })
            } else if (name === 'endTime') {
                setTimeLength({ ...timeLength, endTimeLength: value.length })
            }
        }
    }

    const nameSelected = (value) => {
        // setNameSelectedto(value);
        if (value[0])
            setNameSelectedto([value[0].slice(0, value[0].indexOf("(") - 1)])
        else setNameSelectedto()
    }

    // const emailChange = (value) => {
    //     setSelected(value);
    // }

    const topUpEmailChange = (value) => {
        setEmailSelected(value);
    }

    useEffect(() => {
        setFormData({
            tutorName: '',
            startTime: '',
            endTime: '',
            issueResolved: '',
        })
    }, [resMsg])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const body = {
            "tutor_name": tutorName,
            "start_time": startTime,
            "end_time": endTime,
            "issues_resolved": issueResolved,
            "email": emailId,
        }
        const res = await getDemo.spportSession(body);
        if (res) {
            setResMsg(res.message);
        }
    }

    const handleTopUpSubmit = async (e) => {
        e.preventDefault();
        const body = {
            "amount": topUpAmoount,
            "email": emailId,
        }
        const res = await getDemo.topupUserAccount(body);
        if (res) {
            alert(res.message);
            setTopUpAmount(0);
            setEmailSelected('');
        }
    }

    console.log("value", nameSelectedTo);

    return (
        <div className='container mt-5'>
            <div className='row'>
                <div className='col-md-6'>
                    <h3 style={{ color: "white" }}>Support Session and user details</h3>
                    <p style={{ color: "white" }}>Stats</p>
                    <p style={{ color: "white" }}>Total Minutes used on the platform: {totalMinutes} mins</p>
                    <form onSubmit={handleSubmit}>
                        <h2 style={{ color: "red" }}>{resMsg}</h2>
                        <div className="form-group">
                            <input type="text" value={tutorName} onChange={handleChange} name="tutorName" className="form-control" aria-describedby="emailHelp" placeholder="Enter Tutor Name" />
                        </div>
                        <div className="form-group">
                            <input type="text" value={startTime} onChange={handleChange} name="startTime" className="form-control" placeholder="Enter Start Time" />
                        </div>
                        <div className="form-group">
                            <input type="text" value={endTime} onChange={handleChange} name="endTime" className="form-control" aria-describedby="emailHelp" placeholder="Enter End Time" />
                        </div>
                        <div className="form-group">
                            <input type="text" value={issueResolved} onChange={handleChange} name="issueResolved" className="form-control" placeholder="Enter Issue Resolved" />
                        </div>
                        <div className="form-group">
                            <Typeahead
                                id="basic-example"
                                onChange={nameSelected}
                                options={paidUserName ? paidUserName : ["Loading..."]}
                                placeholder="Choose a name..."
                                selected={nameSelectedTo}
                            />
                        </div>
                        <div className="form-group">
                            <input type="text" value={emailId} name="emailId" className="form-control" placeholder="Email Id" disabled={true} />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
                <div className='col-md-6'>
                    <h3 style={{ color: "white" }}>Topup User Account</h3>
                    <form onSubmit={handleTopUpSubmit}>
                        <h2>{resMsg}</h2>
                        <div className="form-group">
                            <input type="number" value={topUpAmoount} onChange={(e) => setTopUpAmount(e.target.value)} className="form-control" aria-describedby="emailHelp" placeholder="Enter Topup Amount" />
                        </div>
                        {/* <div className="form-group">
                            <Typeahead
                                id="basic-example"
                                onChange={topUpEmailChange}
                                options={paidUserData}
                                placeholder="Choose a email..."
                                selected={emailSelected}
                            />
                        </div> */}
                        <div className="form-group">
                            <Typeahead
                                id="basic-example"
                                onChange={nameSelected}
                                options={paidUserName}
                                placeholder="Choose a name..."
                                selected={nameSelectedTo}
                            />
                        </div>
                        <div className="form-group">
                            <input type="text" value={emailId} name="emailId" className="form-control" placeholder="Email Id" disabled={true} />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
            <h2 style={{ color: 'white' }} className="mt-5">User Table</h2>
            <UserTable allData={allData} />
        </div>
    )
}

export default SupportSession