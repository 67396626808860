import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_BACKEND_URL;

class CompetitionsService {
  getCompetitions(teamId) {
    let reqUrl = API_URL + "/competition/" + teamId;
    if (!teamId) {
      reqUrl = API_URL + "/competition/";
    }
    console.log("reqUrl", reqUrl);
    return axios
      .get(reqUrl, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  getCompetitionDetails(competitionId, teamId) {
    return axios
      .get(`${API_URL}/competition/${competitionId}/${teamId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  getLeaderboard(competitionId) {
    return axios
      .get(`${API_URL}/competition/getleaderboard/${competitionId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  getHints(teamid, competitionid) {
    return axios
      .post(
        `${API_URL}/competition/hints`,
        {
          teamid,
          competitionid,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  submitSolution(teamid, competitionid, github, link, demo) {
    return axios
      .post(
        `${API_URL}/competition/makesubmission`,
        {
          teamid,
          competitionid,
          github,
          link,
          demo,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }
}

export default new CompetitionsService();
