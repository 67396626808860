import React from 'react'
import Form from 'react-bootstrap/Form'

import unitService from 'actions/services/unit.service'
class NewUnit extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      courseid: this.props.courseid,
      unitid: this.props.unitid,
      removeunitid: this.props.removeunitid,
      unitname:"",
      unitno:0,
    }
  }

  async componentDidMount(){
    
    if(this.props.unitid){
      const res = await unitService.getUnit(this.props.unitid);
      console.log("bree", res);
      if(res.success)
      {
        this.setState({...res.data} );
      }
      else{
        alert('please enroll in the course to make a change to unit')
      }
    }else if(this.props.removeunitid){
      const res = await unitService.getUnit(this.props.removeunitid);
      if(res.success)
      {
        this.setState({...res.data} );
      }
      else{
        alert('please enroll in the course to make a change to unit')
      }
    }
  }

  setValue = (key,value,callback) =>{
    this.setState({
      [key]:value
    },callback)
  }
  onSubmit = (e) =>{
    e.preventDefault();

    const funct = async () =>{
      let res = null 
      try {
        this.props.setLoading(true);
        if(this.props.unitid){
          res = await await unitService.putUnit(this.props.unitid, this.state);
        }else if(this.props.removeunitid){
          res = await unitService.deleteUnit(this.props.removeunitid);
         }
        else{
          res = await unitService.postUnit(this.state);
        }
        
        console.log({res})
        if(res.success){
          this.props.setUpdate(true);
          this.props.setNewUnit(false);
        }
        else{
          alert(res.message.message)
          this.props.setUpdate(false);
          this.props.setLoading(false);
        }
      }
      catch(error){
        alert(error)
      } 
    }
    
    funct();
  }
  render(){
    return(
      <Form className="w-75 ml-0 ml-md-5 p-2 bg-white" style={{height:'fit-content'}} onSubmit={e=>this.onSubmit(e)}>
          { !this.props.removeunitid? <>
        <Form.Group>
          <Form.Label>Unit Name</Form.Label>
          <Form.Control required type="text" placeholder="Unit Name" value={this.state.unitname} onChange={e=>this.setValue('unitname',e.target.value)}/>
        </Form.Group>
        <Form.Group >
          <Form.Label>Unit Number</Form.Label>
          <Form.Control required type="text" placeholder="Unit Number" value={this.state.unitno} onChange={e=>this.setValue('unitno',e.target.value)}/>
        </Form.Group> </> :
          <h2 > Do you  want to delete the <span className='text-danger'>
          {this.state.unitname} </span>unit ?</h2>
          }
        <button disabled={this.state.loading} type="submit" className='btn btn-sm btn-main mt-3 ' onClick={e=>this.onSubmit(e)}>
          Submit
        </button>
      </Form>
    )
    }
}

export default NewUnit
