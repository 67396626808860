import styled, { css, keyframes } from "styled-components";

export const ComponentContainer = styled.div`
  padding: 28px 10px;
  background-color: #0d1e3a;
  height: 100%;
  max-width: 1000px;
  margin: 0;
  color: white;

  .nav-pills .nav-link {
    color: black;
    font-weight: 500;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: white;
    color: var(--purple);
    border-radius: 0;
    border-bottom: 2px solid var(--purple);
  }

  /* background: white; */
  .main-nav {
    background: white;
    color: black;
    /* padding: 5px; */
    line-height: 2;
    font-size: 14px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .nav-left {
      display: flex;
      flex-wrap: wrap;
    }

    .nav-item {
      font-size: 14px;
    }

    .nav-right {
      button {
        background: var(--purple);
        border: none;
        color: white;
        padding: 5px 10px;
        margin-right: 10px;
        border-radius: 3px;
      }
    }
  }

  .tab-content {
    width: 100%;
    margin-top: 2rem;
    background: white;
    color: black;

    border-radius: 3px;

    .tab-content-header {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      font-size: 14px;
      font-weight: 600;
      padding: 1rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eaeaea;
      background: #fbfbfb;
    }
  }

  @media only screen and (max-width: 900px) {
    max-width: 100%;
    padding: 28px 0;

    .nav-right {
      margin: 0.2rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const PosterContainer = styled.div`
  height: 250px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #e5e5e5;
  padding: 0;
  position: relative;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    z-index: 1;
  }

  .poster-info {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
    padding: 1rem;

    .poster-header {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
    }

    .poster-body {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-info {
      }
      .right-info {
        text-align: center;
        p {
          font-size: 1.5rem;
        }
      }
    }

    .poster-footer {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;

      img {
        width: 2rem;
        height: 2rem;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 5px;
      }
      span {
        padding: 0 5px;
        display: inline-block;
      }
      .dot {
        position: relative;
        font-size: 2rem;
        bottom: 10px;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .poster-info {
      .poster-body {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const OverViewContainer = styled.div`
  display: flex;
  .nav {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eaeaea;
    width: 25%;
    line-height: 2;
    font-size: 14px;
  }
  .tab-content {
    width: 75%;
    margin: 0;
    padding: 0;
    padding: 1rem;
    overflow: hidden;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: white;
    color: var(--purple);
    border-radius: 0;
    border: none;
    border-right: 2px solid var(--purple);
  }

  .tab-pane {
    width: 100%;
  }

  @media only screen and (max-width: 1110px) {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    .nav {
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: start;
      width: 100%;
    }
    .tab-content {
      width: 100%;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      border: none;
      border-bottom: 2px solid var(--purple);
    }
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    .nav {
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: start;
      width: 100%;
    }
    .tab-content {
      width: 100%;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      border: none;
      border-bottom: 2px solid var(--purple);
    }
  }
`;

export const MarkdownContainer = styled.div`
  overflow: auto;
  all: none;
  max-width: 100%;
  margin-right: 10px;
  /* white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word; */
  overflow: hidden;

  /* all: initial;
  * {
    all: unset;
  } */

  text-decoration: unset;

  a {
    color: blue;
  }

  a:hover {
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    word-wrap: break-word;
    text-align: left;
    max-width: 100%;
    margin-bottom: 3px;
  }
  img {
    max-width: 100%;
  }
  ul {
    margin-bottom: 1rem;
    list-style: disc inside;
    li {
      list-style-type: circle;
    }
  }
  blockquote {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
  }

  pre {
    background-color: #f6f8fa;
  }

  @media only screen and (max-width: 1082px) {
    /* width: 100vh; */
    /* width: 88vw; */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      width: 100%;
      
    }
  }

  @media only screen and (max-width: 600px) {
    /* width: 100vh; */
    width: 88vw;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      width: 100%;
    }
  }
`;

export const CompetitionsListContainer = styled.div`
  width: 100%;
  margin: ${(props) => (props?.fromDashboard ? "0" : "1.5rem 1rem 1rem 0")};

  .cards-list {
    display: flex;
    flex-wrap: wrap;

    .why-competitions {
      background: white;
      margin: 1rem 1rem 1rem 0;
      min-width: 500px;
      max-width: 600px;
      height: 320px;
      margin-left: 1rem;
    }
  }

  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    margin: 1rem;


    .cards-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;

      .why-competitions {
        max-width: 200px;
        min-width: 250px;
      }
    }
  }
`;

export const CompetitionCardContainer = styled.div`
  margin: 1rem 1rem 1rem 0;

  width: 235px;
  min-width: 235px;
  background: white;
  color: black;
  height: 320px;
  /* border-radius: 16px; */
  cursor: pointer;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

  .card-header {
    flex-grow: 2;
    width: 100%;
    height: 40%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* border-top-left-radius: 16px;
      border-top-right-radius: 16px; */
    }

    .card-live {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      margin: 5px;
    }

    .card-poster-info {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      padding: 1rem;

      img {
        width: 2rem;
        height: 2rem;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 5px;
      }
      span {
        padding: 0 5px;
        display: inline-block;
        color: white;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.65);
      z-index: 1;
      /* border-top-left-radius: 16px;
      border-top-right-radius: 16px; */
    }
  }

  .card-info {
    flex-grow: 2;
    padding: 0.7rem;
    span {
      font-size: 12px;
      color: #a0a0a0;
      line-height: 0.5;
    }
    .card-info-skills {
      span {
        margin-right: 3px;
        /* display: inline-block; */
      }
    }
  }

  .card-footer {
    flex-grow: 1;
    padding: 0.7rem;
    border-top: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CompetitionSubmissionFormContainer = styled.div`
  background: white;
  color: black;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
`;

export const TableContainer = styled.div`
  margin: 10px;
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-family: var(--bs-body-font-family);

  tr {
    font-size: 14px;
    font-weight: 500;
  }

  td {
    /* cursor: pointer; */

    img {
      border-radius: 50%;
    }
  }

  tr.expandable > td {
    box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
    padding: 0;
  }

  tr.expandable > td > .inner {
    margin: 15px;
    overflow: hidden;
  }
`;
