import { useEffect, useState, useContext } from 'react';

import classes from './Settings.module.css';

import { Row, Col, Button } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';

import tutorService from 'actions/services/tutor-service';
import authService from 'actions/services/auth.service';

import DashboardNavbar from '../navbar/Navbar.jsx';
import SettingsTable from './SettingsTable.jsx';
import { LoadingContext } from 'context/LoadingProvider';
import { UserContext } from 'context/UserProvider';

import { FiChevronLeft } from 'react-icons/fi';

import cvvIcon from 'assets/img/setting/cvvIcon.svg';
import editIcon from 'assets/img/setting/editIcon.svg';
import cardNoIcon from 'assets/img/setting/cardNoIcon.svg';
import mailIcon from 'assets/img/setting/mailIcon.svg';
import passwordIcon from 'assets/img/setting/passwordIcon.svg';

const Settings = () => {
  const { user, setUser } = useContext(UserContext);
  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [email, setEmail] = useState(user.email);
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordData, setPasswordData] = useState({ newPassword: '', confirmPassword: '' });
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    const funct = async () => {
      try {
        let res3 = await tutorService.getUserAccountDetails();

        setMinutesRemaining(res3.data?.totalminutes);
        //console.log('res3======== Tutor deets', res3);
      } catch (error) {
        console.log(error);
      }
    };

    funct();
  }, []);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    authService
      .updateUserEmail(email)
      .then((response) => {
        if (response.success) {
          setUser({ ...response, ...response.data });
        }
      })
      .catch((reject) => alert('somethingwentwrong'))
      .then(() => {
        alert('Email updated successfully');
        setLoading(false)});
  };

  const handlePasswordVerified = (e) => {
    e.preventDefault();
    console.log(currentPassword);

    setLoading(true);
    authService
      .passwordVerified(currentPassword)
      .then((response) => {
        if (response.success) {
          setIsPasswordVerified(true);
        }
      })
      .catch((reject) => alert('somethingwentwrong'))
      .then(() => {
        setCurrentPassword('');
        setLoading(false);
      });
  };

  const handlePasswordData = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordData.newPassword === passwordData.confirmPassword) {
      setLoading(true);
      authService
        .updatePassword(passwordData.newPassword)
        .then((response) => {
          if (response.success) {
            console.log('Password changed successfully');
            setPasswordData({ newPassword: '', confirmPassword: '' });
          }
        })
        .catch((reject) => alert('somethingwentwrong'))
        .then(() => {
          setIsPasswordVerified(false);
          setLoading(false);
          alert('Password changed successfully');
        });
    } else {
      console.log('newPassword and confirm Password are not same');
    }
  };

  return (
    <div className={classes.container}>
      <DashboardNavbar minutesRemaining={minutesRemaining} />
      <div className={classes.title}>
        <h1>Accout settings</h1>
        <p>
          <FiChevronLeft style={{ fontSize: '27px', margin: '0 6px 3px 0' }} />
          Go back to profile
        </p>
      </div>
      <div>
        <form onSubmit={handleEmailSubmit}>
          {/* <Row style={{ justifyContent: 'space-between' }}> */}
          <Row>
            <Col md={6} style={{ marginTop: 16 }}>
              <p className={classes.subHeading}>
                <img src={mailIcon} alt="" style={{ marginRight: 10 }} />
                Change e-mail
              </p>
              <div className={`input-group ${classes.inputGroup}`}>
                <input
                  type="email"
                  className={`form-control ${classes.input}`}
                  id="validationTooltipUsername"
                  placeholder={email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  aria-describedby="validationTooltipUsernamePrepend"
                />
                <img src={editIcon} alt="" className={classes.searchIcon} />
              </div>
            </Col>
            <Col md={3}>
              <Button
                type="submit"
                variant="warning"
                className={`${classes.submitBtn} ${classes.subHeading}`}
                style={{ color: '#2C3131' }}
              >
                Update Email
              </Button>
            </Col>
          </Row>
        </form>
        {isPasswordVerified ? (
          <form onSubmit={handlePasswordSubmit}>
            <Row style={{ marginTop: 40 }}>
              <Col md={6} style={{ marginTop: 16 }}>
                <p className={classes.subHeading}>
                  <img src={passwordIcon} alt="" style={{ marginRight: 10 }} />
                  New Password
                </p>
                <div className={`input-group ${classes.inputGroup}`}>
                  <input
                    type="password"
                    name="newPassword"
                    className={`form-control ${classes.input}`}
                    id="validationTooltipUsername"
                    placeholder="****************"
                    value={passwordData.newPassword}
                    aria-describedby="validationTooltipUsernamePrepend"
                    onChange={handlePasswordData}
                  />
                  <img src={editIcon} alt="" className={classes.searchIcon} />
                </div>
              </Col>
              <Col md={6} style={{ marginTop: 16 }}>
                <p className={classes.subHeading}>
                  <img src={passwordIcon} alt="" style={{ marginRight: 10 }} />
                  Confirm Password
                </p>
                <div className={`input-group ${classes.inputGroup}`}>
                  <input
                    type="password"
                    name="confirmPassword"
                    className={`form-control ${classes.input}`}
                    id="validationTooltipUsername"
                    placeholder="****************"
                    value={passwordData.confirmPassword}
                    aria-describedby="validationTooltipUsernamePrepend"
                    onChange={handlePasswordData}
                  />
                  <img src={editIcon} alt="" className={classes.searchIcon} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 3, offset: 9 }}>
                <Button
                  variant="warning"
                  className={`${classes.submitBtn} ${classes.subHeading}`}
                  style={{ color: '#2C3131' }}
                  type="submit"
                >
                  Update Password
                </Button>
              </Col>
            </Row>
          </form>
        ) : (
          <form onSubmit={handlePasswordVerified}>
            {/* <Row style={{ justifyContent: 'space-between', marginTop: 40 }}> */}
            <Row>
              <Col md={6} style={{ marginTop: 16 }}>
                <p className={classes.subHeading}>
                  <img src={passwordIcon} alt="" style={{ marginRight: 10 }} />
                  Current Password
                </p>
                <div className={`input-group ${classes.inputGroup}`}>
                  <input
                    type="password"
                    className={`form-control ${classes.input}`}
                    id="validationTooltipUsername"
                    placeholder="****************"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    aria-describedby="validationTooltipUsernamePrepend"
                  />
                  <img src={editIcon} alt="" className={classes.searchIcon} />
                </div>
              </Col>
              <Col md={3}>
                <Button
                  variant="warning"
                  className={`${classes.submitBtn} ${classes.subHeading}`}
                  style={{ color: '#2C3131' }}
                  type="submit"
                >
                  Verify Password for update
                </Button>
              </Col>
            </Row>
          </form>
        )}

        {/* <form>
          <Row style={{ marginTop: 50 }}>
            <Col md={6} style={{ marginTop: 16 }}>
              <p className={classes.subHeading}>Name on card</p>
              <div className={`input-group ${classes.inputGroup}`}>
                <input
                  type="text"
                  className={`form-control ${classes.input}`}
                  id="validationTooltipUsername"
                  placeholder="Jack Black"
                  aria-describedby="validationTooltipUsernamePrepend"
                />
                <img src={editIcon} alt="" className={classes.searchIcon} />
              </div>
            </Col>
            <Col md={3} style={{ marginTop: 16 }}>
              <p className={classes.subHeading}>Expiry date</p>
              <div className={`input-group ${classes.inputGroup}`}>
                <input
                  type="text"
                  className={`form-control ${classes.input}`}
                  id="validationTooltipUsername"
                  placeholder="MM/YY"
                  aria-describedby="validationTooltipUsernamePrepend"
                />
                <img src={editIcon} alt="" className={classes.searchIcon} />
              </div>
            </Col>
            <Col md={3} style={{ marginTop: 16 }}>
              <p className={classes.subHeading}>
                CVV <img src={cvvIcon} alt="" style={{ marginLeft: 10 }} />
              </p>
              <div className={`input-group ${classes.inputGroup}`}>
                <input
                  type="password"
                  className={`form-control ${classes.input}`}
                  id="validationTooltipUsername"
                  placeholder="***"
                  aria-describedby="validationTooltipUsernamePrepend"
                />
                <img src={editIcon} alt="" className={classes.searchIcon} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} style={{ marginTop: 16 }}>
              <p className={classes.subHeading}>Card number</p>
              <div className={`input-group ${classes.inputGroup}`}>
                <input
                  type="password"
                  className={`form-control ${classes.input}`}
                  id="validationTooltipUsername"
                  placeholder="**** **** **** ****"
                  aria-describedby="validationTooltipUsernamePrepend"
                />
                <img src={cardNoIcon} alt="" className={classes.searchIcon} />
              </div>
            </Col>
            <Col md={3}>
              <Button
                variant="warning"
                className={`${classes.editBtn} ${classes.subHeading}`}
                style={{ color: '#F9C80E' }}
              >
                Edit card
              </Button>
            </Col>
            <Col md={3}>
              <Button
                variant="warning"
                className={`${classes.submitBtn} ${classes.subHeading}`}
                style={{ color: '#2C3131' }}
              >
                Save change
              </Button>
            </Col>
          </Row>
        </form>
         */}
      </div>
      {/* <SettingsTable /> */}
    </div>
  );
};

export default Settings;
