import React, { useState } from "react";
import TBLService from "actions/services/tbl.service";

function InviteFriends(props) {
  const [state, setState] = useState({
    email1: "",
    email2: "",
    inviteSent1: "",
    inviteSent2: "",
  });

  const onChangeState = (e, prop) => {
    setState({ ...state, [prop]: e.target.value });
  };

  const handleCreateTeam = () => {
    if (!state.email1) {
      alert("Please invite atleast one friend.");
    }

    if (state.email1)
      TBLService.sendInvite(state.email1).then((res) => {
        alert(res.message);
        window.location.reload();
      });
    if (state.email2)
      TBLService.sendInvite(state.email2).then((res) => {
        alert(res.message);
        window.location.reload();
      });
    console.log("state", state);
  };

  return (
    <>
      <input
        type="email"
        className="form-control form-control-sm m-2"
        name="password"
        placeholder="friend@gmail.com"
        value={state.email1}
        onChange={(e) => onChangeState(e, "email1")}
      />
      <input
        type="email"
        className="form-control form-control-sm m-2"
        name="password"
        placeholder="optional - friend2@gmail.com"
        value={state.email2}
        onChange={(e) => onChangeState(e, "email2")}
      />


      <small className="mt-2 mb-2 badge bg-warning text-center">
        <i>Once a member accepts your invite, the team will be created.</i>
      </small>
      <br />
      <button
        className="btn btn-block btn-main"
        onClick={() => {
          props.handleOnHide();
          // alert("Your friends have received emails.");
          handleCreateTeam();
          // history.push("/getstarted");
        }}
      >
        Create team - Its Free
      </button>
    </>
  );
}

export default InviteFriends;
