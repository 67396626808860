import { UserContext } from 'context/UserProvider';
import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { useHistory } from 'react-router-dom'
export default function ShouldBeLoggedIn({ children }) {
    const { user, setUser } = useContext(UserContext)
    const history = useHistory();
    const [success, setSuccess] = useState(false)
    useEffect(() => {
        if (history.location.pathname === '/code') {

            const queries = new URLSearchParams(history.location.search);
            // if (!queries.get('courseid') || !queries.get('lectureid') || !queries.get('courseid')) {
            //     history.push("/allcourses")
            // }
        }
        else if (history.location.pathname === '/dashboard') {
            if (user && user.isadmin) history.replace("/admin")
        }
        else if (history.location.pathname === '/admin') {
            if (user && !user.isadmin) history.replace("/updated-dashboard")
        }
        setSuccess(true)

    }, [history])
    if (success) {
        return (
            <div>
                {children}
            </div>
        )
    }
    return null;
}
