import moment from "moment";

const userSystemAttributes = {
  WE_EMAIL: "email",
  WE_PHONE: "phone",
  WE_GENDER: "gender",
  WE_FIRST_NAME: "first_name",
  WE_LAST_NAME: "last_name",
};

const userCustomAttributes = {
  SOLO_STREAK: "Solo Streak",
  Z_COINS: "Z-coins",
  TEAM_STREAK: "Team Streak",
  CURRENT_PLAN: "Current Plan",
  SUBSCRIPTION_STATUS: "Subscription Status",
  SA_CITIZENSHIP: "SA Citizenship",
  HIGHEST_SCHOOL_GRADE: "Highest School Grade",
  DEGREE_NAME: "Degree Name",
  HOME_LANGUAGE: "Home Language",
  SECOND_LANGUAGE: "Second Language",
  RACE: "Race",
  DISABILITY_STATUS: "Disability status",
  CURRENT_SALARY_RANGE: "Current Salary Range",
};

const dailyCommitment = {
  "15 minutes/day": "Casual",
  "30 minutes/day": "Regular",
  "45 minutes/day": "Serious",
  "60 minutes/day": "Intense",
}

export const setSytemUserAttributes = (userDetails = {}) => {
  const username = userDetails?.username?.trim() || "";
  console.log({ userDetails }, "-----", username?.split(" "));
  window.webengage.user.setAttribute(
    userSystemAttributes.WE_EMAIL,
    userDetails?.email || ""
  );
  window.webengage.user.setAttribute(
    userSystemAttributes.WE_FIRST_NAME,
    username?.split(" ")[0] || ""
  );
  window.webengage.user.setAttribute(
    userSystemAttributes.WE_LAST_NAME,
    username?.split(" ")[1] || ""
  );
  window.webengage.user.setAttribute(
    userSystemAttributes.WE_GENDER,
    userDetails?.gender || ""
  );
  window.webengage.user.setAttribute(
    userSystemAttributes.WE_PHONE,
    userDetails?.phoneNo || ""
  );

  /* CUSTOM ATTRIBUTES */
  window.webengage.user.setAttribute(
    userCustomAttributes.SA_CITIZENSHIP,
    userDetails?.citizen?.includes("yes") ? true : false
  );

  window.webengage.user.setAttribute(
    userCustomAttributes.DISABILITY_STATUS,
    userDetails?.disability?.includes("yes") ? true : false
  );

  window.webengage.user.setAttribute(
    userCustomAttributes.HOME_LANGUAGE,
    userDetails?.homeLanguage || ""
  );
  window.webengage.user.setAttribute(
    userCustomAttributes.SECOND_LANGUAGE,
    userDetails?.secondLanguage || ""
  );
  window.webengage.user.setAttribute(
    userCustomAttributes.HIGHEST_SCHOOL_GRADE,
    userDetails?.school || ""
  );
  window.webengage.user.setAttribute(
    userCustomAttributes.DEGREE_NAME,
    userDetails?.courseName || ""
  );

  window.webengage.user.setAttribute(
    userCustomAttributes.RACE,
    userDetails?.race || ""
  );
  window.webengage.user.setAttribute(
    userCustomAttributes.CURRENT_SALARY_RANGE,
    userDetails?.salary || ""
  );
};

export const setWalletScoreAttribute = (data) => {
  window.webengage.user.setAttribute(
    userCustomAttributes.Z_COINS,
    Number(data) || 0
  );
};

export const setTeamStreakAttribute = (data) => {
  window.webengage.user.setAttribute(
    userCustomAttributes.TEAM_STREAK,
    Number(data) || 0
  );
};

export const setSoloStreakAttribute = (data) => {
  window.webengage.user.setAttribute(
    userCustomAttributes.SOLO_STREAK,
    Number(data) || 0
  );
};

export const courseEnrollEventTracking = (email, ls_data = {}) => {
  const havePC = ls_data?.havePC.includes("don't");
  window.webengage.user.setAttribute(
    userSystemAttributes.WE_EMAIL,
    email || ""
  )

  window.webengage.track('Enroll for Course',{
    "Course Name": (havePC ? ls_data?.selectingCourseOption : ls_data?.selectingMobileCourseOption) || '',
    "Reason for learning": ls_data?.goal || '',
    "PC Access": havePC,
    "Profiecieny in PC": ls_data?.isComfortable || '',
    "Daily Commitment": dailyCommitment[ls_data?.setDailyGoal] || ''
  })
}

export const videoEventsTracking = (key,data) => {
  console.log({data},key);
  window.webengage.track(key, {
    "Video Title": data?.titleMedia || '',
    "Course Name": data?.courseTitle || '',
    "Duration": Math.round(data?.duration/60) || 0
  })
}

export const assignmentEventTracking = (progress = {}) => {
  let studentsFinalGrade =  progress?.finalgrade;
  let isStarted = false;
  let codingQuestionsProgress = progress?.codingQuestionsProgress || [];
  let backendQuestionsProgress = progress?.backendQuestionsProgress || [];
  let frontendQuestionsProgress = progress?.frontendQuestionsProgress || [];
  let eventKey = 'Assignments Viewed';

  if(codingQuestionsProgress){
    for(let i = 0; i < codingQuestionsProgress.length; i++){
      if(codingQuestionsProgress[i]?.status.includes('marked')){
        isStarted = true;
        break;
      }
    }
  }
  if(backendQuestionsProgress){
    for(let i = 0; i < backendQuestionsProgress.length; i++){
      if(backendQuestionsProgress[i]?.status.includes('marked')){
        isStarted = true;
        break;
      }
    }
  }
  if(frontendQuestionsProgress){
    for(let i = 0; i < frontendQuestionsProgress.length; i++){
      if(frontendQuestionsProgress[i]?.status.includes('marked')){
        isStarted = true;
        break;
      }
    }
  }
  if(studentsFinalGrade !== 0){
    eventKey = 'Assignments Completed'
  } else if(isStarted){
    eventKey = 'Assignments Started'
  } else {
    eventKey = 'Assignments Viewed'
  }

  window.webengage.track(eventKey, {
    "Title" : progress?.assignmentid?.assignmentname || '',
    "Status": progress?.status || '',
    "Open Date": progress?.createdAt || '',
    "Close Date": progress?.deadline || '',
    "Final Grade": studentsFinalGrade
  })

}

export const paymentPlanEventTracking = (data) => {
  window.webengage.track('Payment Plan Selected', {
    "Plan Name": data?.plan || '',
    "Discount": data?.discount || 0,
    "Price": data?.price || 0,
    "Coupon Code": data?.couponcode || ''
  })
}


export const paymentEventTracking = (key, data) => {
  console.log({key, data});
  window.webengage.track(key, {
    "Amount" : data?.data?.amount || 0,
    "Method" : data?.data?.channel || '',
    "Order Id" : data?.data?.reference || '',
    "Status" : data?.data?.status || ''
  })
}

export const codeSwitchEventTracking = () => {
  window.webengage.track('Code Switch Clicked');
}

export const walletViewedEventTracking = (data) => {
  window.webengage.track('Wallet Viewed',{
    "Current Z-coins": Number(data)
  })
}

export const inviteFriendEventTracking = () => {
  window.webengage.track('Invite Friend',{
    'Channel' : 'email' || ''
  });
}

export const resumeCourseEventTracking = (data) => {
   window.webengage.track('Resume Course',{
    'Course Name': data?.resumecourse?.coursename || ''
   })
}

export const courseCompletionEventTracking = (data) => {
  if(data?.completedCoursePercentage === 100){
    console.log({data})
    window.webengage.track("Course Completion", {
      "Percentage Completed": data?.completedCoursePercentage || 0,
      "Course Name": data?.learningpathname || "",
      "Price": data?.learningpathprice || 0,
      "Discount": data?.learningpathdiscount || 0,
      "Course Time Duration": data?.learningpathduration || 0,
      "Total Modules": data?.learningpathcourses.length || 0,
    });
  }
}

export const moduleEventTracking = (key, data) => {
  let duration = moment(Date.now()).format("DD/MM/YYYY");
  if(key.includes('Module Selected')){
    window.webengage.track(key, {
      "Module Name": data?.coursename || "",
      "Course Name": data?.learningPathName || "",
      "Percentage Completed": data?.completedPercentage || 0,
    });
  } else{
    if(data?.completedPercentage === 0 || data?.completedPercentage === 100){
      window.webengage.track(key, {
        "Module Name": data?.coursename || "",
        "Course Name": data?.learningPathName || "",
        "Percentage Completed": data?.completedPercentage || 0,
        "Time Duration": duration
      });
    }
  }
}

export const chapterCompletedEventTracking = (data) => {
  console.log({data});
  let chapters = [];
  data?.courseunit?.map((unit,index) => {
    const completedLecture = unit?.lecture?.filter((lec,index)=>data?.completedLectures?.includes(lec._id));
    if(completedLecture.length === unit.lecture.length) {
      chapters.push(unit);
    };
  })
  chapters.map(ch => {
    window.webengage.track("Chapter Completed", {
      "Module Name": data?.coursename || "",
      "chapter Name": ch.unitname || "",
    });
  })
}

export const signupEventTracking = (data) => {
  window.webengage.track("Signup completed", {
    "username": data?.username || "",
    "email": data?.email || ""
  })
}

export const phoneEventTracking = (data) => {
  window.webengage.track("Phone completed", {
    "phone": data?.phonenumber || "",
  })
}