import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { MarkdownContainer } from "./Competitions.styled";
// import { readFile } from "browserify-fs";

const DEMO_URL =
  "https://zaiocontent.s3.eu-west-2.amazonaws.com/competitions/description.md";
function MarkdownToReact({ url = DEMO_URL }) {
  const [content, setContent] = useState("");

  const fetchContent = async () => {
    await fetch(url)
      .then((res) => res.text())
      .then((text) => {
        // console.log("TEXT", text);
        setContent(text);
      });
  };
  useEffect(() => {
    fetchContent();
  }, []);

  if (!content) return null;
  return (
    <MarkdownContainer>
      <ReactMarkdown children={content} />
    </MarkdownContainer>
  );
}

export default MarkdownToReact;
