import Accordion from "component/Accordion/Accordion";
import { useQuery } from "react-query";

import learningpathService from "actions/services/learningpath.service";
import classes from "../Dashboardnew.module.css";
import { useContext } from "react";
import { UserContext } from "context/UserProvider";

import ZPLoading from "pages/Watch/components/ZPLoading";
import Error from "components/Error/Error";

function LearningPaths() {
  const { user } = useContext(UserContext);
  const {
    isLoading,
    isError,
    error,
    data: learningPaths,
  } = useQuery("learningPaths", () => learningpathService.getLearningPaths(), {
    enabled: !!(user && user?.success),
    onSuccess: (res) => {
      res.data.map((path) =>
        learningpathService.autoEnroll(path?.learningpathid?._id)
      );
    },
  });

  if (isLoading) return <ZPLoading />;
  if (isError) return <Error message={error} />;
  if (learningPaths?.success === false) {
    return (
      <div className="cl-container d-flex justify-content-center align-items-center text-white text-center">
        <h1>
          <mark>Start Learning</mark>
        </h1>{" "}
        choose a course from below.
      </div>
    );
  }
  const learningPathList = learningPaths?.data;
  return (
    <div className={classes.blocks}>
      <h1 className={classes.myLearningPaths}>My learning paths</h1>
      <div className="">
        {learningPathList &&
          Object.values(learningPathList)
            .filter(
              (learning) =>
                !learning?.learningpathid?.learningpathname.includes(
                  "Introduction to Coding"
                )
            )
            .map((learning) => {
              // console.log("LP", learning);
              return (
                <Accordion
                  xl={3}
                  lg={4}
                  md={6}
                  key={learning?.learningpathid?._id}
                  learningId={learning?.learningpathid?._id}
                  title="LEARNING PATH"
                  heading={learning?.learningpathid?.learningpathname}
                  //content="Second Accordion content"
                />
              );
            })}
      </div>
    </div>
  );
}

export default LearningPaths;
