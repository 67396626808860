import Ide from "components/IDE/Ide";
import AdminSandboxList from "../AdminPortal/AdminIde/AdminSandboxList";
import AdminIdeNav from "../AdminPortal/AdminIde/AdminIdeNav";
import SaveProgress from "../AdminPortal/AdminIde/SaveProgress";

import "../AdminPortal/AdminIde/admin_ide.scss";
import { useEffect, useMemo, useState } from "react";

import { useLocation, useParams } from "react-router-dom";

import { useMutation, useQuery } from "react-query";
import lectureService from "actions/services/lecture.service";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import AssignmentNav from "./AssignmentNav";
import Compiler from "components/Compiler/Compiler";
import MCQ from "components/MCQ";
import useAssignmentStore from "zustand-store/assignmentStore";
import CustomModal from "components/Modals/CustomModal";
import QuestionsList from "./QuestionsList";
import AssCompiler from "./AssCompiler";
import useVideoStore from "zustand-store/videoPlayerStore";
import useQuizStore from "zustand-store/quizStore";
import Loader from "components/Loader/Loader";
import AssignmentOverview from "./AssignmentOverview";
import useEditorStore from "zustand-store/editorStore";
import Backend from "components/Backend/Backend";
import Frontend from "components/FrontendChallenge/Frontend";
import { assignmentEventTracking } from "Utils/WebEngage";

function Assignment() {
  function encode(str) {
    return btoa(unescape(encodeURIComponent(str || "")));
  }

  function decode(bytes) {
    var escaped = escape(atob(bytes || ""));
    try {
      return decodeURIComponent(escaped);
    } catch {
      return unescape(escaped);
    }
  }

  const { qId, qType, assignmentid } = useParams();
  // const location = useLocation();
  // const queries = new URLSearchParams(location.search);
  // console.log("QUERIES", queries, location);

  const [showSandboxList, setShowSandboxList] = useState(false);
  const [showVideosList, setShowVideosList] = useState(true);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [projectFiles, setProjectFiles] = useState(null);
  const [preLoadedFiles, setPreLoadedFiles] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editingCompleted, setEditingCompleted] = useState(true);

  const [playgroundid, setPlaygroundId] = useState(false);
  const [show, setShow] = useState(false);

  const setAssignmentMode = useAssignmentStore(
    (state) => state.setAssignmentMode
  );
  const setAssignmentData = useAssignmentStore(
    (state) => state.setAssignmentData
  );
  const setChallenge = useVideoStore((state) => state.setChallenge);
  const setTitle = useAssignmentStore((state) => state.setTitle);
  const setCurrentQuestionId = useAssignmentStore(
    (state) => state.setCurrentQuestionId
  );
  const currentQuestionId = useAssignmentStore(
    (state) => state.currentQuestionId
  );
  const type = useAssignmentStore((state) => state.type);
  const setQuizData = useQuizStore((state) => state.setQuizData);
  const setCurrentQuestion = useAssignmentStore(
    (state) => state.setCurrentQuestion
  );
  const setFiles = useEditorStore((state) => state.setFiles);
  const setEditorMode = useEditorStore((state) => state.setEditorMode);
  const setOpenFile = useEditorStore((state) => state.setOpenFile);

  const {
    // isLoading,
    // isError,
    // error,
    data: assignment,
  } = useQuery(
    "assignment",
    () => lectureService.getAssignmentById(assignmentid),
    {
      enabled: !!assignmentid,
      onSuccess: (response) => {
        console.log("Assignment", response?.data);
        // setCurrentVideo(data);
        setAssignmentData(response?.data);
      },
    }
  );

  const {
    // isLoading,
    // isError,
    // error,
    data: assignmentProgress,
  } = useQuery(
    "assignmentProgress",
    () => lectureService.getAssProgressByQId(assignmentid),
    {
      enabled: !!assignmentid,
      onSuccess: (response) => {
        console.log("Assignment Progress", response);
        assignmentEventTracking(response?.data);
        // setCurrentVideo(data);
        // setAssignmentData(response?.data);
      },
    }
  );

  const getAssignmentQuestion = useMutation(
    () => lectureService.getAssQuestionById(qId, qType),
    {
      onSuccess: (response) => {
        console.log("Question Data", response?.data);
        setCurrentQuestion(response?.data);
        if (response?.data?.assignmentcodingquestionid?.type === "coding") {
          setEditorMode("assignment");
          setChallenge(response?.data?.assignmentcodingquestionid);
          const filesFetched = response?.data?.files?.map((file, index) => ({
            filename: file?.filename,
            sourcecode: decode(file?.sourcecode),
          }));
          setFiles(filesFetched);
          setOpenFile(filesFetched[0]);

          setTitle(response?.data?.assignmentcodingquestionid?.questionname);
        }
        if (response?.data?.assignmentbackendquestionid?.type === "backend") {
          setEditorMode("assignment");
          setChallenge(response?.data?.assignmentbackendquestionid);
          setTitle(response?.data?.assignmentbackendquestionid?.questionname);
        }
        if (response?.data?.assignmentfrontendquestionid?.type === "frontend") {
          setEditorMode("assignment");
          setChallenge(response?.data?.assignmentfrontendquestionid);
          setTitle(response?.data?.assignmentfrontendquestionid?.questionname);
        }
        if (response?.data?.assignmentmcqid?.type === "mcq") {
          // setChallenge(response?.data?.assignmentcodingquestionid);
          setQuizData(response?.data?.assignmentmcqid);
          setTitle(response?.data?.assignmentmcqid?.questionname);
        }

        // setCurrentVideo(data);
      },
    }
  );

  useEffect(() => {
    console.log("YOOOOO", qId, qType);
    if (qId) {
      console.log("QID FOUND", qId);
      setAssignmentMode(qType);
      setCurrentQuestionId(qId);
      getAssignmentQuestion.mutate();
    }
  }, [qId]);
  // setQId

  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const loadModal = () => {
    setShow(true);
    console.log("LOAD MODAL", show);

    setModal({
      ...modal,
      show: true,
      title: editingCompleted ? "Create" : "Edit",
      description: "",
      CustomComponent: null,
      props: {},
    });
  };

  const onCtrlS = () => {
    // loadModal();
  };

  function handleSaveShortcut(e) {
    if (
      e.keyCode === 83 &&
      (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
    ) {
      e.preventDefault();
      console.log("Ctrl S..");
      onCtrlS();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleSaveShortcut, false);
    return () => {
      document.removeEventListener("keydown", handleSaveShortcut);
    };
  }, []);

  const loadMainComponent = () => {
    console.log(type, 'h3h3h3');
    if (type === "coding") {
      return (
        <div className="video-player-compiler">
          <AssCompiler qId={qId} />
        </div>
      );
    } else if (type === "mcq") {
      return (
        <div className="video-player-compiler">
          <MCQ assignment={true} />
        </div>
      );
    } else if (type === "backend") {
      return (
        <div className="video-player-compiler">
            <Backend assignment={true} />
        </div>
      )
    } else if (type === "frontend") {
      return (
        <div className="video-player-compiler">
            <Frontend assignment={true} />
        </div>
      )
    } else {
      return (
        <div className="video-player-compiler">
          <AssignmentOverview
            setShowQuestions={setShowSandboxList}
            mdfile={assignment?.data?.assignmentid?.assignmentpreview}
          />
        </div>
      );
    }
  };

  return (
    <div>
      {modal.show && (
        <CustomModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          props={modal.props}
          CustomComponent={modal.CustomComponent ?? false}
          // videoUrl={"https://youtu.be/Uv9URjIBtUQ"}
        />
      )}
      <AssignmentNav
        setShowSandboxList={setShowSandboxList}
        showSandboxList={showSandboxList}
        setShowVideosList={setShowVideosList}
        showVideosList={showVideosList}
        currentCourse={currentCourse}
        editMode={editMode}
        setEditMode={setEditMode}
        currentVideo={currentVideo}
      />

      {getAssignmentQuestion.isLoading ? (
        <Loader loadDirect={true} />
      ) : (
        <>
          {showSandboxList && (
            <div className="admin-sandbox-sidebar">
              <QuestionsList
                mcqs={assignmentProgress?.data?.mcqProgress}
                codingQs={assignmentProgress?.data?.codingQuestionsProgress}
                backendQs={assignmentProgress?.data?.backendQuestionsProgress}
                frontendQs={assignmentProgress?.data?.frontendQuestionsProgress}
                assignmentid={assignmentid}
              />
            </div>
          )}
          <div className="admin-ide-container">
            {loadMainComponent()}

            {/* <Ide
          onCtrlS={onCtrlS}
          preLoadedProjectFiles={preLoadedFiles}
          onProjectFilesUpdate={onProjectFilesUpdate}
        /> */}
          </div>
        </>
      )}
      {/* {showVideosList && (
        <AdminVideosList
          setCurrentCourse={setCurrentCourse}
          courseid={courseid}
          lectureid={lectureid}
          unitid={unitid}
          setCurrentVideo={setCurrentVideo}
          currentVideo={currentVideo}
        />
      )} */}
    </div>
  );
}

export default Assignment;
