import moment from "moment";
import { useEffect, useState } from "react";

const TutorSupportProfile = ({tutorDetails}) => {
  return (
    <div className="w-full h-full bg-blue-900 text-white px-4 rounded-md">
      <div className="flex w-full h-full justify-center items-center">
        {/* <div className='rounded-full bg-gray-100'>anc</div> */}
        <div class="flex -space-x-1 px-2 mr-2">
          <img
            class="inline-block size-24 rounded-full"
            src={tutorDetails[0]?.image || `https://avatar.iran.liara.run/username?username=${[tutorDetails[0]?.company_username.split(' ')[0]+tutorDetails[0]?.company_username.split(' ')[1]]}`}
            alt=""
          />
        </div>
        <div className="flex-col">
          <div className="text-2xl flex-wrap my-2">Your allocated Tutor: {tutorDetails[0]?.company_username}</div>
          <a className="bg-green-400 px-3 py-1 rounded-md text-dark" href={tutorDetails[0]?.calendlyLink} target="_blank">Book Now</a>
        </div>
      </div>
    </div>
  );
};

export default TutorSupportProfile;
