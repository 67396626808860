import React from "react";
import { createContext, useState } from "react";

interface ITasks {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  tasks: any;
  setTasks: React.Dispatch<React.SetStateAction<any>>;
}

const defaultValue: ITasks = {
  showModal: false,
  setShowModal: () => {},
  tasks: [],
  setTasks: () => {},
};

export const TasksContext = createContext(defaultValue);

export const TasksProvider: React.FC = ({ children }: any) => {
  const [showModal, setShowModal] = useState(false);
  const [tasks, setTasks] = useState([]);
  return (
    <TasksContext.Provider value={{ showModal, setShowModal, tasks, setTasks }}>
      {children}
    </TasksContext.Provider>
  );
};
