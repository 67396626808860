import React, { useState, useEffect } from "react";

import { MdMovieFilter, MdGroups } from "react-icons/md";
import { FiChevronRight } from "react-icons/fi";
import {
  BsArrowLeftCircle,
  BsArrowRightCircle,
  BsArrowLeftShort,
  BsArrowRightShort,
} from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";

import { NavLink, useHistory } from "react-router-dom";

import classes from "./Sidebar.module.css";

import ZCoinIcon from "assets/svg/zcoin.svg";
import logo from "assets/img/zaio-logos/newLogo.svg";
import smalllogo from "assets/img/zaio-logos/smallLogo.svg";
import setting from "assets/img/dashboard/setting.svg";
import settingActive from "assets/img/dashboard/settingActive.svg";
import team from "assets/img/dashboard/teampng.png";
import teamActive from "assets/img/dashboard/team-active.png";

import wallet from "assets/img/dashboard/wallet-icon-inactive.png";
import walletActive from "assets/img/dashboard/wallet-icon-active.png";

import star from "assets/img/dashboard/star.svg";
import starActive from "assets/img/dashboard/star-active.svg";
import tutor from "assets/img/dashboard/tutor.svg";
import tutorActive from "assets/img/dashboard/tutor-active.svg";
import dashboardActive from "assets/img/dashboard/dashboard-active.svg";
import dashboard from "assets/img/dashboard/dashboard.svg";
import statistics from "assets/img/dashboard/statistics.svg";
import profile from "assets/img/dashboard/profile.svg";
import profileInActive from "assets/img/dashboard/profileInActive.svg";
import jobs from "assets/img/dashboard/brief-case.png";
import jobsActive from "assets/img/dashboard/briefcase_white.png";

import useWindowDimensions from "../../Utils/WindowDimension";

import Tooltip from "react-power-tooltip";
import WalletBalance from "pages/Wallet/WalletBalance";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [linkActive, setLinkActive] = useState("Dashboard");
  const [mouseOver, setMouseOver] = useState("none");
  const toggle = () => setIsOpen(!isOpen);
  const { height, width } = useWindowDimensions();
  const history = useHistory();

  const menuItem = [
    {
      path: "/updated-dashboard",
      name: "Dashboard",
      iconActive: dashboardActive,
      icon: dashboard,
      image: true,
    },
    // {
    //   path: "/profile",
    //   name: "My profile",
    //   iconActive: profile,
    //   icon: profileInActive,
    //   image: true,
    // },
    {
      path: "/assignments",
      name: "Assignments",
      iconActive: starActive,
      icon: star,
      image: true,
    },
    {
      path: "/tutor-support",
      name: "Tutor support",
      iconActive: tutorActive,
      icon: tutor,
      image: true,
    },
    // {
    //   path: "/hall-of-fame",
    //   name: "Hall of fame",
    //   iconActive: starActive,
    //   icon: star,
    //   image: true,
    // },
    // {
    //   path: "/notifications",
    //   name: "Notifications",
    //   iconActive: starActive,
    //   icon: star,
    //   image: true
    // },
    // {
    //   path: "/notes",
    //   name: "Notes",
    //   iconActive: starActive,
    //   icon: star,
    //   image: false,
    // },
    // {
    //   path: "/team",
    //   name: "Team Learning",
    //   iconActive: teamActive,
    //   icon: team,
    //   image: true,
    // },
    // {
    //   path: "/comment",
    //   name: "Statistics",
    //   iconActive: statistics,
    //   icon: statistics,
    // },
    // {
    //   path: "/jobs",
    //   name: "Jobs",
    //   iconActive: jobsActive,
    //   icon: jobs,
    //   image: true,
    // },
    {
      path: "/wallet",
      name: "Wallet",
      iconActive: statistics,
      icon: statistics,
      image: true,
    },
  ];

  // console.log(height, width, 'height, widthheight, width')

  useEffect(() => {
    if (width <= 600) {
      setIsOpen(false);
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  const handleMouseOver = (item) => setMouseOver(item.name);
  const handleMouseOut = () => setMouseOver("none");
  // console.log(isOpen, 'isOpen')

  return (
    <div className={classes.dashboardContainer}>
      <div
        style={{ width: isOpen ? "240px" : "85px" }}
        className={classes.dashboardSidebar}
      >
        <div className={classes.top_section}>
          <div
            to="/ssac"
            className={classes.navbarLogo}
            style={{ display: isOpen ? "block" : "none" }}
          >
            <img className={classes.zaioLogo} src={logo} alt=""></img>
            <BsArrowLeftShort
              onClick={toggle}
              className={classes.collapsIconLeft}
            />
          </div>
          <div
            style={{
              marginLeft: isOpen ? "50px" : "0px",
              display: isOpen ? "none" : "block",
            }}
            className={classes.bars}
          >
            <div style={{ display: isOpen ? "none" : "block", width: 30 }}>
              <img src={smalllogo} alt="" style={{ width: 27 }}></img>
            </div>
            {width > 414 && (
              <BsArrowRightShort
                onClick={toggle}
                className={classes.collapsIconRight}
              />
            )}
          </div>
        </div>

        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className={`${classes.link} ${
              linkActive === item.name ? classes.active : ""
            }`}
            isActive={(match, location) => {
              if (!match) {
                return false;
              }
              setLinkActive(item.name);
              // only consider an event active if its event id is an odd number
              const eventID = parseInt(match.params.eventID);
              return !isNaN(eventID) && eventID % 2 === 1;
            }}
            onMouseOver={() => handleMouseOver(item)}
            onMouseOut={handleMouseOut}
          >
            <Tooltip
              show={mouseOver === item.name}
              // zIndex={99999999}
              color="black"
            >
              <div
                className="d-flex justify-content-start align-items-center"
                onClick={() => history.push(item.path)}
              >
                {item.name}
              </div>
            </Tooltip>
            {/* <div className={classes.icon}>{item.icon}</div> */}
            <div className={classes.icon}>
              {mouseOver === item.name ? (
                item.image ? (
                  <img
                    id={item.name.toLowerCase()}
                    src={item.iconActive}
                    alt=""
                    // className={"wallet"}
                  />
                ) : (
                  <item.active />
                )
              ) : item.image ? (
                <img
                  id={item.name.toLowerCase()}
                  src={linkActive === item.name ? item.iconActive : item.icon}
                  alt=""
                  // className={"wallet"}
                />
              ) : (
                <item.icon />
              )}
            </div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className={classes.link_text}
            >
              {item.name}
              <FiChevronRight
                className={`${classes.arrowActive} ${classes.arrow}`}
              />
            </div>
          </NavLink>
        ))}
        <a
          href="https://discord.gg/G5kaneFSDa"
          target="_blank"
          className={` ${classes.teligramBtn}`}
        >
          <div
            style={{
              color: "#fff",
            }}
            className={classes.bookConsultation}
          >
            <p style={{ margin: 0 }}>
              <FaDiscord
                style={{
                  fontSize: 20,
                  transform: "translateY(-1px)",
                  marginRight: 8,
                }}
              />
              {isOpen && !isMobile && (
                <span className={classes.link_text}>Discord Community</span>
              )}
            </p>
          </div>
        </a>
      </div>
      <main
        style={{ paddingLeft: isOpen ? "240px" : isMobile ? "0px" : "85px" }}
      >
        {children}
      </main>
    </div>
  );
};

export default Sidebar;
