import { Table } from 'react-bootstrap';

import classes from './TutorSupport.module.css';

import { MdStarBorder } from 'react-icons/md';

import moment from "moment";
import { useEffect, useState } from 'react';

const AboutTable = ({ tableData, tableLenght, isLoading,user }) => {

  return (
    <div className={classes.tableContainer}>
      {tableLenght ? (
        <Table responsive borderless style={{ color: '#fff' }}>
          <thead className={classes.tableHead}>
            <tr>
              <th className={classes.tableHeadData}>Date</th>
              <th className={classes.tableHeadData}>Call duration</th>
              <th className={classes.tableHeadData}>start time</th>
              <th className={classes.tableHeadData}>end time</th>
              <th className={classes.tableHeadData}>Issue Resolved</th>
              {/* <th className={classes.tableHeadData}>Rating (x/10) </th> */}
            </tr>
          </thead>
          <tbody className={classes.tabelBody}>
            {tableData.map((item) => (
              <tr className={classes.tableRow}>
                <td className={classes.tableBodyData}>{moment(item.createdAt).format("DD-MM-YYYY")}</td>
                {/* <td className={classes.tableBodyData}>
                <span className={classes.statusBtn}>Fullstack</span>
              </td> */}
                <td className={classes.tableBodyData}>{(new Date('2021-08-25 ' + item.end_time + ':00') -
                  new Date('2021-08-25 ' + item.start_time + ':00')) /
                  (1000 * 60)}{' '}
                  mins</td>
                <td className={classes.tableBodyData}>{item.start_time}</td>
                <td className={classes.tableBodyData}>{item.end_time}</td>
                <td className={classes.tableBodyData}>{item.issues_resolved}</td>
                {/* <td className={classes.tableBodyData} style={{ display: 'flex', alignItems: 'center' }}>
                <MdStarBorder style={{ fontSize: 16, marginRight: 3 }} />
                10
              </td> */}
              </tr>
            ))}
          </tbody>
        </Table>) : (
        <div className={classes.ringButton}>
          <h3 style={{ textAlign: "center", color: "white" }}>Connect with your tutor on Discord.</h3>

          <a
            href={"https://discord.gg/G5kaneFSDa"}
            target="_blank"
            className="text-white mt-2"
          >
            <button className={classes.ringActiveBtn} >Join Discord</button></a>
        </div>
      )}
    </div>
  );
};

export default AboutTable;
