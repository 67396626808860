import Ide from "components/IDE/Ide";
import AdminSandboxList from "./AdminSandboxList";
import AdminIdeNav from "./AdminIdeNav";

import "./admin_ide.scss";
import { useEffect, useState } from "react";
import AdminVideosList from "./AdminVideosList";
import { useParams } from "react-router-dom";

import SaveProgress from "./SaveProgress";
import { useQuery } from "react-query";
import lectureService from "actions/services/lecture.service";

import Modal from "react-bootstrap/Modal";
import Editor from "@monaco-editor/react";
import { defaultFileStructure, ideConstants, playgroundTypes } from "Constants";
import { formatJSON } from "components/IDE/ideUtils";

import { default as useStateRef } from "react-usestateref";

function AdminIde() {
  const { courseid, lectureid, unitid } = useParams();
  const [showSandboxList, setShowSandboxList] = useState(false);
  const [showVideosList, setShowVideosList] = useState(true);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedSandbox, setSelectedSandbox] = useState(null);
  const [playgroundid, setPlaygroundId] = useState(false);
  const [show, setShow] = useState(false);

  const [fromAddNewBtn, setFromAddNewBtn] = useState(false);

  const [projectFiles, setProjectFiles] = useState({
    type: playgroundTypes.SINGLE_FILES,
    files: defaultFileStructure,
  });

  //current selected file
  const [currentFile, setCurrentFile, currentFileRef] =
    useStateRef("index.html");

  //Handling Multiple Files
  const [showMulFileJSONEditor, setShowMulFileJSONEditor] = useState(false);

  const handleOnChange = (value, event) => {
    localStorage.setItem(ideConstants.JSON_FOLDER_STUCTURE, value);
  };

  const { data } = useQuery(
    "ideCurrentVideo",
    () => lectureService.getPublicLectureById(lectureid),
    {
      onSuccess: (data) => {
        setCurrentVideo(data);
      },
    }
  );

  const loadModal = () => {
    setShow(true);
  };

  const onCtrlS = () => {
    loadModal();
  };

  const onProjectFilesUpdate = (files) => {
    setProjectFiles(files);
  };

  useEffect(() => {
    //reset everything if edit mode turned off
    if (!editMode) {
      setProjectFiles({
        type: playgroundTypes.SINGLE_FILES,
        files: defaultFileStructure,
      });
      setSelectedSandbox(null);
      setPlaygroundId(null);
    }
  }, [editMode]);

  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <SaveProgress
          currentVide={currentVideo}
          projectFiles={projectFiles}
          setProjectFiles={setProjectFiles}
          lectureid={lectureid}
          setEditMode={setEditMode}
          setSelectedSandbox={setSelectedSandbox}
          selectedSandbox={selectedSandbox}
          editMode={editMode}
          playgroundid={playgroundid}
          setShow={setShow}
          fromAddNewButton={fromAddNewBtn}
          setFromAddNewBtn={setFromAddNewBtn}
        />
      </Modal>

      <Modal
        show={showMulFileJSONEditor}
        onHide={() => setShowMulFileJSONEditor(false)}
        style={{
          width: "100%",
          height: "100%",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={() => {
            setShowMulFileJSONEditor(false);
            localStorage.removeItem(ideConstants.JSON_FOLDER_STUCTURE);
          }}
        >
          Close
        </button>

        <button
          onClick={() => {
            const value = localStorage.getItem(
              ideConstants.JSON_FOLDER_STUCTURE
            );

            let parsedV = [];

            try {
              parsedV = JSON.parse(value);
            } catch (err) {}

            setProjectFiles((prev) => ({
              ...prev,
              files: parsedV,
            }));

            setShowMulFileJSONEditor(false);
            localStorage.removeItem(ideConstants.JSON_FOLDER_STUCTURE);
          }}
        >
          Save
        </button>
        <Editor
          className="monaco-editor bg-light w-100 h-100"
          height="100vh"
          width="100%"
          theme="vs-dark"
          language="json"
          value={formatJSON(projectFiles?.files)}
          onChange={handleOnChange}
          options={{
            automaticLayout: true,
            fontLigatures: true,
            fontSize: 16,
            minimap: {
              enabled: false,
            },
            renderLineHighlight: "line",
            renderLineHighlightOnlyWhenFocus: false,
            cursorSmoothCaretAnimation: true,
            cursorBlinking: "smooth",
          }}
        />
      </Modal>

      <AdminIdeNav
        setShowSandboxList={setShowSandboxList}
        showSandboxList={showSandboxList}
        setShowVideosList={setShowVideosList}
        showVideosList={showVideosList}
        currentCourse={currentCourse}
        editMode={editMode}
        setEditMode={setEditMode}
        setSelectedSandbox={setSelectedSandbox}
        currentVideo={currentVideo}
      />
      {showSandboxList && (
        <AdminSandboxList
          lectureid={lectureid}
          setEditMode={setEditMode}
          setSelectedSandbox={setSelectedSandbox}
          setPlaygroundId={setPlaygroundId}
          playgroundid={playgroundid}
          loadModal={loadModal}
          setShowSandboxList={setShowSandboxList}
          setFromAddNewBtn={setFromAddNewBtn}
          setProjectFiles={setProjectFiles}
        />
      )}
      <div className="admin-ide-container">
        <Ide
          onCtrlS={onCtrlS}
          onProjectFilesUpdate={onProjectFilesUpdate}
          setShowMulFileJSONEditor={setShowMulFileJSONEditor}
          projectFiles={projectFiles}
          setProjectFiles={setProjectFiles}
          currentFile={currentFile}
          setCurrentFile={setCurrentFile}
          currentFileRef={currentFileRef}
        />
      </div>
      {showVideosList && (
        <AdminVideosList
          setCurrentCourse={setCurrentCourse}
          courseid={courseid}
          lectureid={lectureid}
          unitid={unitid}
          setCurrentVideo={setCurrentVideo}
          currentVideo={currentVideo}
        />
      )}
    </div>
  );
}

export default AdminIde;
