import axios from "axios";
import moment from "moment";
import { AiOutlineConsoleSql } from "react-icons/ai";
import authHeader from "./auth-header";
import courseService from "./course.service";
import { courseEnrollEventTracking } from "Utils/WebEngage";

const API_URL = process.env.REACT_APP_BACKEND_URL;

class AuthService {
  payStackPayment(reference) {
    return axios
      .get("https://api.paystack.co/charge/" + reference, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK}`,
        },
      })
      .then((response) => {
        console.log("PAYMENT SUCCESS", response);
        return response;
        // console.log('login',JSON.parse(localStorage.getItem('user')))
      })
      .catch((rej) => {
        console.log("PAYMENT FAILED", rej);
        return rej.response;
      });
  }
  getUserNotSignedUpData(defaultObj) {
    const userData = JSON.parse(localStorage.getItem("notSignedUpUser"));
    let updatedUserData;
    updatedUserData = userData;
    if (defaultObj && !userData) {
      updatedUserData = {
        ...defaultObj,
      };
      // console.log("updatedUserData", updatedUserData);
    }

    localStorage.setItem("notSignedUpUser", JSON.stringify(updatedUserData));
    return updatedUserData;
  }
  settingDataWithoutSigningUp(objToAdd) {
    const userData = JSON.parse(localStorage.getItem("notSignedUpUser"));
    let updatedUserData;
    if (userData) {
      updatedUserData = {
        ...userData,
        ...objToAdd,
      };
    } else {
      updatedUserData = {
        ...objToAdd,
      };
    }
    localStorage.setItem("notSignedUpUser", JSON.stringify(updatedUserData));
  }
  getOnboardingData(prop) {
    const userData = JSON.parse(localStorage.getItem("notSignedUpUser"));
    if (userData) {
      return userData[prop];
    }
    return null;
  }
  login(email, password) {
    // alert("SENDING OBJ: "+ JSON.stringify({ email, password }) + " ");
    return axios
      .post(API_URL + "/login", {
        email,
        password,
      })
      .then((response) => {
        console.log(response, "LOGIN RESPONSE");
        if (response.data.success) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.removeItem("notSignedUpUser");
          // this.setSessionData();
        }
        // console.log('login',JSON.parse(localStorage.getItem('user')))
        return response.data;
      })
      .catch((rej) => {
        console.log(rej, "LOGIN ERROR");

        return rej.response;
      });
  }

  getPaymentDetails() {
    return axios
      .get(API_URL + "/user-payments", {
        headers: authHeader(),
      })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch((rej) => rej);
  }

  updatePaymentDetails(paymentMode) {
    return axios
      .post(
        API_URL + "/user-payments",
        {
          paymentMode,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch((rej) => rej);
  }

  async mondayReferenceGetBoardValue(email) {
    let query = `query { items_page_by_column_values (limit: 1, board_id: 1414037448, columns: [{column_id: \"text6\", column_values: [\"${email}\"]}]) { items { id name }}}`;
    const payload = { query };
    return axios
      .post("https://api.monday.com/v2", JSON.stringify(payload), {
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_MONDAY_API_KEY,
          "API-Version": "2023-04",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err));
  }

  async mondayReferencePost(email, name, phonenumber = "-") {
    // console.log({email,name,phonenumber});
    // return;
    const currentDate = new Date();
    const currentLocalDate = moment(currentDate).format("YYYY-MM-DD");
    const payload = {
      query: `
      mutation($name: String!, $columnValues: JSON!) {
        create_item(
          board_id: 1414037448,
          item_name: $name,
          column_values: $columnValues
        ) {
          id
        }
      }
      `,
      variables: {
        name,
        columnValues: JSON.stringify({
          text6: email,
          text: phonenumber,
          date4: { date: currentLocalDate },
        }),
      },
    };
    // variables:{
    //   name, columnValues: JSON.stringify({email: {email: email, text: email}, phone: phonenumber})
    // }
    // console.log(JSON.stringify(payload));
    // return;
    return axios
      .post("https://api.monday.com/v2", JSON.stringify(payload), {
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_MONDAY_API_KEY,
          "API-Version": "2023-04",
        },
      })
      .then((res) => {
        // console.log({res});
        return res;
      })
      .catch((err) => console.log(err));
  }

  loginSnapplify(usercode) {
    return axios
      .post(API_URL + "/sso", {
        usercode,
      })
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        // console.log('login',JSON.parse(localStorage.getItem('user')))
        return response.data;
      })
      .catch((rej) => {
        // console.log(rej.response);
        return rej.response;
      });
  }

  googleLogin(tokenId) {
    const ls_user = this.getUserNotSignedUpData(null);

    const notSignedUpObj = {
      onboardingquestions: [
        {
          digitalLiteracy: ls_user.digitalLiteracy,
          havePC: ls_user.havePC,
          isComfortable: ls_user.isComfortable,
          reelOnboardingComplete: ls_user.reelOnboardingComplete,
        },
      ],
      completedlectures: ls_user.completedlectures.map((item) => {
        return { _id: item };
      }),
      // lastlecture: ls_user.lastwatchedid,
      // continuelearning: {
      //   lectureid: ls_user.lastwatchedid,
      //   courseid: ls_user.courseid,
      // },
      // courseid: ls_user.courseid,
    };

    console.log("NOT SIGNEDUP OBJECTS...", {
      // username,
      // email,
      // password,
      //phonenumber,
      // src,
      // coursein,
      ...notSignedUpObj,
    });

    return axios
      .post(API_URL + "/googleLogin", {
        tokenId,
        ...notSignedUpObj,
      })
      .then((response) => {
        if (response.data.success) {
          if (!response.data.alreadyRegister) {
            localStorage.setItem("googleUser", JSON.stringify(response.data));
            // response.data.data.username = "";
          }
          localStorage.setItem("user", JSON.stringify(response.data));
          // this.setSessionData();
          courseEnrollEventTracking(response?.data?.data?.email || "", ls_user);
        }
        // console.log('login',JSON.parse(localStorage.getItem('user')))
        return response.data;
      })
      .catch((rej) => {
        // console.log(rej.response);
        return rej.response;
      });
  }

  updateUserData() {
    return axios
      .get(API_URL + "/login", {
        headers: authHeader(),
      })
      .then((response) => {
        // alert("response.data", JSON.stringify(response));
        console.log("\n\n\n\nresponse.data", response.data);

        if (response.data.token) {
          console.log("CONFIRM");
          localStorage.setItem("user", JSON.stringify(response.data));
          this.setSessionData();
        }
        return response.data;
      })
      .catch((rej) => {
        // alert("ERROR IN UPDATE USER DATA", JSON.stringify(rej));
        // console.log(rej.response);
        return rej.response;
      });
  }

  webViewGetLogin(token) {
    console.log("WEBVIEW GET LOGIN", token);
    return axios
      .get(API_URL + "/login", {
        headers: { "auth-token": token },
      })
      .then((response) => {
        if (response.data.token) {
          console.log("CONFIRM");
          localStorage.setItem("user", JSON.stringify(response.data));
          this.setSessionData();
        }
        return response.data;
      })
      .catch((rej) => {
        console.log(rej.response);
        return rej.response;
      });
  }

  setSessionData() {
    const session = JSON.parse(localStorage.getItem("session"));
    if (session) {
      const then = new Date(session.lastLogin);
      const now = new Date();

      if (!this.IsWithin24Hours(then, now)) {
        localStorage.setItem(
          "session",
          JSON.stringify({
            ...session,
            lastLogin: new Date(),
            videoCounter: 0,
          })
        );
      }
    }
  }

  logout() {
    axios
      .post(
        API_URL + "/setLastActive",
        {},
        {
          headers: authHeader("logout"),
        }
      )
      .then((response) => {
        console.log("logout", response);
      })
      .catch((rej) => rej.response);

    localStorage.removeItem("user");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("notSignedUpUser");
    // localStorage.setItem("lastActive", JSON.stringify(Date.now()));
    localStorage.removeItem("googleUser");
  }

  updateUser(goal, currentstatus, heardfrom) {
    return axios
      .post(
        API_URL + "/updateuserquestions",
        {
          goal,
          currentstatus,
          heardfrom,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log("updatedd");
        // console.log('login',JSON.parse(localStorage.getItem('user')))
      })
      .catch((rej) => rej.response);
  }

  updateUserEmail(email) {
    return axios
      .post(
        API_URL + "/updateuseremail",
        {
          email,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  passwordVerified(password) {
    return axios
      .post(
        API_URL + "/passwordVerified",
        {
          password,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  updatePassword(password) {
    return axios
      .post(
        API_URL + "/updatePassword",
        {
          password,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  postOnboardingQuestions() {
    //will post onboarding questions from local storage
    console.log("posting this to db...");
    const ls_user = this.getUserNotSignedUpData(null);

    const notSignedUpObj = {
      onboardingquestions: [
        {
          digitalLiteracy: ls_user.digitalLiteracy,
          havePC: ls_user.havePC,
          isComfortable: ls_user.isComfortable,
          reelOnboardingComplete: ls_user.reelOnboardingComplete,
        },
      ],
      completedlectures: ls_user.completedlectures.map((item) => {
        return { _id: item };
      }),
      lastlecture: ls_user.lastwatchedid,
      continuelearning: {
        lectureid: ls_user.lastwatchedid,
        courseid: ls_user.courseid,
      },
      courseid: ls_user.courseid,
    };

    return axios
      .post(
        API_URL + "/addonboardingquestions",
        {
          // coursein,
          // onboardingquestions,
          ...notSignedUpObj,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        this.updateUserData();
        console.log("Successfully psoted onboarding questions");
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  register(
    // username,
    email,
    //phonenumber,
    password,
    username,
    src = "zaio.io",
    referralcode
    // coursein,
  ) {
    // console.log('register')

    const ls_user = this.getUserNotSignedUpData(null);

    const notSignedUpObj = {
      onboardingquestions: [
        {
          digitalLiteracy: ls_user.digitalLiteracy,
          havePC: ls_user.havePC,
          isComfortable: ls_user.isComfortable,
          reelOnboardingComplete: ls_user.reelOnboardingComplete,
        },
      ],
      completedlectures: ls_user.completedlectures.map((item) => {
        return { _id: item };
      }),
      lastlecture: ls_user.lastwatchedid,
      continuelearning: {
        lectureid: ls_user.lastwatchedid,
        courseid: ls_user.courseid,
      },
      courseid: ls_user.courseid,
    };

    console.log("NOT SIGNEDUP OBJECTS...", {
      // username,
      // email,
      // password,
      //phonenumber,
      // src,
      // coursein,
      ...notSignedUpObj,
    });

    return axios
      .post(API_URL + "/register", {
        // username,
        email,
        password,
        //phonenumber,
        username,
        src,
        referralcode: !!referralcode ? referralcode : " ",
        // coursein,
        ...notSignedUpObj,
      })
      .then((response) => {
        console.log(response);
        if (response.data.token) {
          courseEnrollEventTracking(email, ls_user);
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.removeItem("notSignedUpUser");
        }

        this.updateUserData();
        // console.log('login',JSON.parse(localStorage.getItem('user')))
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  updatetUserdata(phonenumber) {
    return axios
      .put(API_URL + "/register", {
        phonenumber,
      }, 
      {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  completeRegister(
    username,
    email,
    phonenumber,
    heardfrom
    // password,
    // src,
    // coursein,
  ) {
    // console.log('register')
    return axios
      .post(API_URL + "/completeregistration", {
        username,
        email,
        phonenumber,
        heardfrom,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        // console.log('login',JSON.parse(localStorage.getItem('user')))
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  forgotpassword(email) {
    // console.log('register')
    return axios
      .post(API_URL + "/forgotpasswordgen", {
        email,
      })
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  setNewPassword(uid, password) {
    // console.log('register')
    return axios
      .post(API_URL + "/forgotpassword?uid=" + uid, {
        password,
      })
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  editUserProfile(data) {
    return axios
      .post(API_URL + "/userProfile", data, {
        headers: {
          ...authHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("userProfile", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  findUserProfile() {
    return axios
      .get(API_URL + "/userProfile", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success) {
          // response.data.lastActive = JSON.parse(localStorage.getItem("lastActive"));
          console.log("authService", response.data);
          localStorage.setItem("userProfile", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  findZaioProfile(email) {
    return axios
      .get(API_URL + "/zaio-profile/" + email)
      .then((response) => {
        console.log("zaioProfile", response);
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  getUserProfile() {
    return JSON.parse(localStorage.getItem("userProfile"));
  }

  updateCounterLocalStorage() {
    const session = JSON.parse(localStorage.getItem("session"));
    const then = new Date(session.lastLogin);
    const now = new Date();

    if (this.IsWithin24Hours(then, now)) {
      localStorage.setItem(
        "session",
        JSON.stringify({
          ...session,
          videoCounter: session.videoCounter + 1,
        })
      );
    } else {
      localStorage.setItem(
        "session",
        JSON.stringify({
          ...session,
          lastLogin: new Date(),
          videoCounter: 0,
        })
      );
    }
  }

  IsWithin24Hours(then, now) {
    const msBetweenDates = Math.abs(then.getTime() - now.getTime());
    // 👇️ convert ms to hours                  min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

    console.log(hoursBetweenDates);

    if (hoursBetweenDates < 24) {
      return true;
    } else {
      return false;
    }
  }

  getUserEarningDetails() {
    console.log(API_URL, authHeader());
    return axios
      .get(API_URL + "/coupon/earningdetails", {
        headers: authHeader(),
      })
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((rej) => rej.response);
  }

  getAllUserCourseDetails() {
    return axios
      .get(API_URL + "/getAllUserCourseDetails")
      .then((response) => {
        return response.data;
      })
      .catch((rej) => {
        console.log("getAllUserCourseDetails", rej.response);
        return rej.response;
      });
  }

  setCompletedPercentage() {
    return axios
      .post(
        API_URL + "/setCompletedPercentage",
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((rej) => {
        console.log("getAllUserCourseDetails", rej.response);
        return rej.response;
      });
  }

  //coupons for all users
  addCoupon(email, coupon) {
    return axios
      .post(API_URL + "/addCoupon", { email, coupon })
      .then((response) => {
        return response.data;
      })
      .catch((rej) => {
        console.log("getAllUserCourseDetails", rej.response);
        return rej.response;
      });
  }

  getStreakData() {
    return axios
      .get(API_URL + "/streak/getstreak", {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((rej) => {
        console.log("getstreak", rej.response);
        return rej.response;
      });
  }

  getTeamStreakData(teamid) {
    // console.log("TEAMID", teamid);
    return axios
      .post(
        API_URL + "/streak/getteamstreak",
        { teamid },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        // console.log("getteamstreak works", response.data);
        return response.data;
      })
      .catch((rej) => {
        console.log("getteamstreak", rej.response);
        return rej.response;
      });
  }
}

export default new AuthService();
