import { useState, useEffect, useContext } from "react";

import DashboardNavbar from "component/navbar/Navbar";
// import DashboardNavbar from '';
import { UserContext } from "context/UserProvider";
import classes from "component/myProfile/MyProfile.module.css";
import TutorService from "actions/services/tutor-service";
import TeamScore from "pages/TeamLearning/TeamScore";
import NotesList from "./NotesList";

const Notes = () => {
  const { user, setUser } = useContext(UserContext);

  const [minutesRemaining, setMinutesRemaining] = useState(0);

  useEffect(() => {
    const funct = async () => {
      try {
        let res3 = await TutorService.getUserAccountDetails();

        setMinutesRemaining(res3.data?.totalminutes);
        //console.log('res3======== Tutor deets', res3);
      } catch (error) {
        console.log(error);
      }
    };

    funct();
  }, []);

  return (
    <div className={`${classes.container}`}>
      <DashboardNavbar minutesRemaining={minutesRemaining} />
      <div className={classes.title}>
        <h1>Notes</h1>
      </div>
      <div className={classes.notesContainer}>
        <NotesList />
      </div>
    </div>
  );
};

export default Notes;
