import competitionsService from "actions/services/competitions.service";
import { OverViewContainer } from "pages/Competitions/Competitions.styled";
import MarkdownToReact from "pages/Competitions/MarkdownToReact";
import ZPLoading from "pages/Watch/components/ZPLoading";
import Hint from "./Hint";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useQuery } from "react-query";

function Hints({ teamid, competitionid, loadModal }) {
  const { isLoading, error, data } = useQuery(
    "competitionHints",
    () => competitionsService.getHints(teamid, competitionid),
    {
      enabled: !!competitionid,
    }
  );

  if (isLoading) return <ZPLoading />;
  if (error) return <Error message={error} />;

  // return (
  //   <OverViewContainer>
  //     <div className="p-3 w-100 text-center">
  //       <h1>Will be released on 15th</h1>

  //       <p>Please join the competition to access the hints.</p>
  //       {/* <h1>Join competition to access hints</h1>

  //       <p>Please join the competition to access the hints.</p> */}
  //       {/* <button className="btn btn-main" onClick={loadModal}>
  //         Join Competition
  //       </button> */}
  //     </div>
  //   </OverViewContainer>
  // );

  if (data?.success === false)
    return (
      <OverViewContainer>
        <div className="p-3 w-100 text-center">
          <h1>Join competition to access hints</h1>

          <p>Please join the competition to access the hints.</p>
          <button className="btn btn-main" onClick={loadModal}>
            Join Competition
          </button>
        </div>
      </OverViewContainer>
    );

  const hints = data?.hints;
  return (
    <OverViewContainer>
      <Tab.Container
        defaultActiveKey={(hints && hints[0]?._id) || ""}
        className="h-100"
      >
        <Nav variant="pills">
          {hints &&
            hints.map((item) => (
              <Nav.Item>
                <Nav.Link eventKey={item._id}>{item.title}</Nav.Link>
              </Nav.Item>
            ))}
        </Nav>

        <Tab.Content>
          {hints &&
            hints.map((item) => (
              <Tab.Pane eventKey={item._id}>
                <Hint
                  item={item}
                  teamid={teamid}
                  competitionid={competitionid}
                />
              </Tab.Pane>
            ))}
        </Tab.Content>
      </Tab.Container>
    </OverViewContainer>
  );
}

export default Hints;
