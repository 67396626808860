import React, { useEffect, useRef, useState } from "react";
import "./ide.scss";
// import "./main";
import Editor, { useMonaco } from "@monaco-editor/react";
import { emmetHTML, emmetCSS, expandAbbreviation } from "emmet-monaco-es";
import { compileAndRun } from "./console";
import ConsoleOutput from "./ConsoleOutput";

import "luna-object-viewer/luna-object-viewer.css";
import "luna-data-grid/luna-data-grid.css";
import "luna-dom-viewer/luna-dom-viewer.css";
import "luna-console/luna-console.css";
import IframeCode from "./PreviewTerminal";

import { DiHtml5, DiCss3, DiJsBadge } from "react-icons/di";
import {
  BsLayoutSplit,
  BsLayoutThreeColumns,
  BsArrowsFullscreen,
} from "react-icons/bs";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// import console from "./LunaConsole";

// const container = document.getElementById("luna-container");
// var console = new LunaConsole(container);
// console.log("luna");

function IdeFrontend({
  onCtrlS,
  preLoadedProjectFiles,
  onProjectFilesUpdate = undefined,
}) {
  const monacoRef = useRef(null);
  const editorRef = useRef(null);

  const [isEditorReady, setIsEditorReady] = useState(false);
  const [showConsole, setShowConsole] = useState(false);
  //maximized, minimize, closed, default
  const [previewMode, setPreviewMode] = useState("default");

  const [projectFiles, setProjectFiles] = useState({
    "index.html": {
      name: "index.html",
      language: "html",
      value: "<h1>Try type HTML here to see magic</h1>",
    },
    "style.css": {
      name: "style.css",
      language: "css",
      value: "/* css code is here  */",
    },
    "script.js": {
      name: "script.js",
      language: "javascript",
      value: "// JS code ",
    },
  });

  const [currentFile, setCurrentFile] = useState("index.html");
  const [language, setLanguage] = useState("html");

  const [output, setOutput] = useState([]);
  const [codeOutput, setCodeOutput] = useState();
  const [loggedErrors, setLoggedErrors] = useState([]);

  const setLanguageBasedOnFile = (file) => {
    if(file.split(".")[1] === "js") {
      setLanguage("javascript");
    } else {
      setLanguage(file.split(".")[1]);
    }
  }
  const handleSelectProjectFile = (file) => {
    setCurrentFile(file);  
    setLanguageBasedOnFile(file);
  };

  const overrideSave = () => {
    document.addEventListener(
      "keydown",
      function (e) {
        if (
          e.keyCode === 83 &&
          (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
        ) {
          e.preventDefault();
          // console.log("Ctrl S..");
          onCtrlS();
        }
      },
      false
    );
  };
  const handleEditorValidation = (markers) => {
    markers.forEach((marker) => console.log("onValidate:", marker.message));
  };
  // const fetchFromLS = () => {
  //   const files = JSON.parse(localStorage.getItem("projectFiles"));
  //   if (files) {
  //     setProjectFiles({
  //       ...files,
  //     });
  //   }
  // };
  const saveToLS = () => {
    localStorage.setItem("projectFiles", JSON.stringify(projectFiles));
  };
  const handleEditorWillMount = (monaco) => {
    monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
  };
  const handleEditorDidMount = (editor, monaco) => {
    // emmetHTML(monaco);
    // emmetCSS(monaco);
    emmetHTML(monaco);
    emmetCSS(monaco);

    setIsEditorReady(true);
    monacoRef.current = monaco;
    editorRef.current = editor; // save editor instance
  };
  const handleOnChange = (value, event) => {
    // console.log(currentFile, value, event);
    setProjectFiles((projectFiles) => {
      const updatedFiles = {
        ...projectFiles,
        [currentFile]: {
          ...projectFiles[currentFile],
          value,
        },
      };
      if (onProjectFilesUpdate) {
        onProjectFilesUpdate(updatedFiles);
      }

      return updatedFiles;
    });

    // saveToLS();

    // setConsoleLogs(compileAndRun(projectFiles["script.js"].value));
  };

  const handleToggleConsole = () => {
    if (showConsole) {
      setShowConsole(false);
    } else {
      setShowConsole(true);
    }
  };

  useEffect(() => {
    console.log({preLoadedProjectFiles});
    if (preLoadedProjectFiles) {
      setLanguage("html");
      setCurrentFile("index.html")
      console.log("preloaded", preLoadedProjectFiles);
      const prevFiles = {
        "index.html": {
          ...preLoadedProjectFiles?.['index.html'],
        },
        "style.css": {
          ...preLoadedProjectFiles?.['style.css'],
        },
        "script.js": {
          ...preLoadedProjectFiles?.['script.js'],
        },
      };
      console.log("preloaded", prevFiles);
      setProjectFiles(prevFiles);
    } else {
        onProjectFilesUpdate(projectFiles)
      // fetchFromLS();
    }
    console.log({projectFiles:projectFiles[currentFile]?.value});
    overrideSave();
  }, []);

  const loadIcon = (projectFile) => {
    if (projectFile.includes("html")) {
      return <DiHtml5 color="#e85e28" className="file-icon" />;
    }
    if (projectFile.includes("css")) {
      return <DiCss3 color="#254ede" className="file-icon" />;
    }
    if (projectFile.includes("js")) {
      return <DiJsBadge color="#f0da3c" className="file-icon" />;
    }
  };
  return (
    <div className="ide-container">
      <div
        className={`code-editor-container code-editor-container-${previewMode}`}
      >
        <div className={`ide-sidebar ide-sidebar-${previewMode}`}>
          <p className="text-white">EXPLORER</p>
          {Object.keys(projectFiles).map((projectFile, index) => (
            <button
              key={index}
              onClick={() => handleSelectProjectFile(projectFile)}
              className={`project-file-btn ${
                projectFile === currentFile && "selected"
              }`}
            >
              {loadIcon(projectFile)}
              <code>{projectFile}</code>
            </button>
          ))}
        </div>
        <div className={`code-editor code-editor-${previewMode}`}>
          <div className="code-editor__header">
            <div></div>
          </div>

          <div className="monaco-editor-container">
            <div className="monaco-editor-header">
              <code>{currentFile}</code>
            </div>
            {/* {console.log("projectFiles", projectFiles)}
            {console.log("currentFile", currentFile)}
            {console.log("projectFiles[currentFile]", projectFiles[currentFile])} */}

            <Editor
              className="monaco-editor"
              height="100%"
              width="100%"
              theme="vs-dark"
              path={projectFiles[currentFile]?.name}
              language={language}
              value={projectFiles[currentFile]?.value}
              onValidate={handleEditorValidation}
              beforeMount={handleEditorWillMount}
              onMount={handleEditorDidMount}
              onChange={handleOnChange}
              options={{
                automaticLayout: true,
                fontLigatures: true,
                fontSize: 16,
                minimap: {
                  enabled: false,
                },
                renderLineHighlight: "line",
                renderLineHighlightOnlyWhenFocus: false,
                cursorSmoothCaretAnimation: true,
                cursorBlinking: "smooth",
              }}
            />
          </div>
        </div>
      </div>
      {/* <button onClick={() => setShowConsole(true)}>show Console</button> */}
      {/* ${previewMode} */}
      <div className={`browser-window browser-window-${previewMode}`}>
        <div className="preview-container-header">
          <div className="monaco-editor-header">
            <code className="text-primary">{"Preview"}</code>
          </div>
          <div className="tools">
            {/* <div className="" onClick={() => setPreviewMode("closed")}> */}

            <BsLayoutSplit
              className="preview-container-header-round"
              onClick={() => setPreviewMode("split")}
            />

            <BsLayoutThreeColumns
              className="preview-container-header-round"
              onClick={() => setPreviewMode("default")}
            />
            <BsArrowsFullscreen
              className="preview-container-header-round"
              onClick={() => setPreviewMode("maximized")}
            />
          </div>
        </div>
        <iframe
          srcDoc={IframeCode(
            projectFiles["index.html"]?.value,
            projectFiles["style.css"]?.value,
            projectFiles["script.js"]?.value
          )}
        />
      </div>
    </div>
  );
}

export default IdeFrontend;
