import axios from "axios";
import authHeader from "./auth-header";
const STREAK_API_URL = process.env.REACT_APP_STREAK_SERVER_URL;

class StreakService {
  getStreakData() {
    return axios
      .get(STREAK_API_URL + "/streak/getstreak", {
        headers: authHeader(),
      })
      .then((response) => {
        // console.log("getstreak works", response.data);
        return response.data;
      })
      .catch((rej) => {
        console.log("getstreak", rej.response);
        return rej.response;
      });
  }

  getTeamStreakData(teamid) {
    console.log("TEAMID", teamid);
    return axios
      .post(
        STREAK_API_URL + "/streak/getteamstreak",
        { teamid },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        // console.log("getteamstreak works", response.data);
        return response.data;
      })
      .catch((rej) => {
        console.log("getteamstreak", rej.response);
        return rej.response;
      });
  }
}

export default new StreakService();
