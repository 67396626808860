import { ShareSocial } from "react-share-social";

export default function RSSUsage({ url }) {
  // We can use inline-style
  const style = {
    root: {
      background: "transparent",
      borderRadius: 3,
      border: 0,
    //   boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      color: "white",
      width: "100%",
      padding: 0,
      margin: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    copyContainer: {
      display: "none",
      border: "1px solid blue",
      background: "rgb(0,0,0,0.7)",
    },
    title: {
      color: "aquamarine",
      fontStyle: "italic",
    },
  };

  return (
    <ShareSocial
      //   title={"Sharing happiness"}
      url={url}
      socialTypes={["facebook", "twitter", "whatsapp", "linkedin", "telegram"]}
      style={style}
    />
  );
}

// import {
//   FacebookIcon,
//   EmailShareButton,
//   FacebookShareButton,
//   HatenaShareButton,
//   InstapaperShareButton,
//   LineShareButton,
//   LinkedinShareButton,
//   LivejournalShareButton,
//   MailruShareButton,
//   OKShareButton,
//   PinterestShareButton,
//   PocketShareButton,
//   RedditShareButton,
//   TelegramShareButton,
//   TumblrShareButton,
//   TwitterShareButton,
//   ViberShareButton,
//   VKShareButton,
//   WhatsappShareButton,
//   WorkplaceShareButton,
// } from "react-share";

// function SocialShare({ shareUrl }) {
//   return (
//     <div>
//       <FacebookShareButton
//         url={shareUrl}
//         quote={shareUrl}
//         className="Demo__some-network__share-button"
//       >
//         <FacebookIcon size={32} round />
//       </FacebookShareButton>
//     </div>
//   );
// }

// export default SocialShare;
