import AdminSandboxList from "./AdminSandboxList";
import AdminIdeNav from "./AdminIdeNav";

import "./admin_ide.scss";
import { useEffect, useState } from "react";
import AdminVideosList from "./AdminVideosList";
import { useParams } from "react-router-dom";

import CustomModal from "components/Modals/CustomModal";
import SaveProgress from "./SaveProgress";
import { useQuery } from "react-query";
import lectureService from "actions/services/lecture.service";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import IdeFrontend from "components/IDE/IdeFrontend";
import Ide from "components/IDE/Ide";

function AdminIdeFrontend({frontendFilesCode, setFrontendFilesCode,setIdeOpen}) {
  const { courseid, lectureid, unitid } = useParams();
  const [showSandboxList, setShowSandboxList] = useState(false);
  const [showVideosList, setShowVideosList] = useState(true);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [projectFiles, setProjectFiles] = useState(null);
  const [preLoadedFiles, setPreLoadedFiles] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editingCompleted, setEditingCompleted] = useState(true);
  const [selectedSandbox, setSelectedSandbox] = useState(null);
  const [playgroundid, setPlaygroundId] = useState(false);
  const [show, setShow] = useState(false);

  const { isLoading, isError, error, data } = useQuery(
    "ideCurrentVideo",
    () => lectureService.getPublicLectureById(lectureid),
    {
      onSuccess: (data) => {
        setCurrentVideo(data);
      },
    }
  );

  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const loadModal = () => {
    setShow(true);
    // console.log("LOAD MODAL", currentVideo, show);
    // console.log(projectFiles, "projectFiles");
    setModal({
      ...modal,
      show: true,
      title: editingCompleted ? "Create" : "Edit",
      description: "",
      CustomComponent: SaveProgress,
      props: {
        currentVideo,
        // projectFiles:  JSON.parse(localStorage.getItem("projectFiles")),
        projectFiles: {
          "index.html": {
            name: "index.html",
            language: "html",
            value: "<h1>Try type HTML here to see magic</h1>",
          },
          "style.css": {
            name: "style.css",
            language: "css",
            value: "/* css code is here  */",
          },
          "script.js": {
            name: "script.js",
            language: "javascript",
            value: "// JS code ",
          },
        },
        lectureid,
        setEditMode,
        setSelectedSandbox,
        selectedSandbox,
        editMode,
        playgroundid,
      },
    });
  };

  const onCtrlS = () => {
    loadModal();
  };

  const onProjectFilesUpdate = (files) => {
    // console.log("PROJECT Files", files);
    setFrontendFilesCode(files)
    setProjectFiles(files);
  };

  // console.log({ projectFiles });

  // useEffect(() => {
  //   console.log("EDIT MODE UDPATED", editMode);
  //   if (!editMode) setEditingCompleted(true);
  //   else setEditingCompleted(false);
  // }, [editMode]);

  return (
    <>
      <div className="admin-ide-container mt-2">
        <div className="d-flex justify-items-center items-center">
          <button className="rounded-full bg-orange-400 p-1 text-white" onClick={()=>setIdeOpen(false)}>
            Save Changes
          </button>
        </div>
        <IdeFrontend
          onCtrlS={onCtrlS}
          preLoadedProjectFiles={frontendFilesCode}
          onProjectFilesUpdate={onProjectFilesUpdate}
        />
        {/* <Ide
          onCtrlS={onCtrlS}
          preLoadedProjectFiles={preLoadedFiles}
          onProjectFilesUpdate={onProjectFilesUpdate}
        /> */}
      </div>
    </>
  );
}

export default AdminIdeFrontend;
