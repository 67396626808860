import { OverViewContainer } from "pages/Competitions/Competitions.styled";
import MarkdownToReact from "pages/Competitions/MarkdownToReact";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

function Spec({ specs }) {
  return (
    <OverViewContainer>
      <Tab.Container
        defaultActiveKey={specs[0].tabname.toLowerCase()}
        className="h-100"
      >
        <Nav variant="pills">
          {specs.map((item, index) => (
            <Nav.Item key={index}>
              <Nav.Link eventKey={item.tabname.toLowerCase()}>
                {item.tabname}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        <Tab.Content>
          {specs.map((item, index) => (
            <Tab.Pane key={index} eventKey={item.tabname.toLowerCase()}>
              <MarkdownToReact url={item.mdlink} />
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </OverViewContainer>
  );
}

export default Spec;
