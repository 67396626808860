import {
  BsLayoutSidebarInset,
  BsReverseLayoutSidebarInsetReverse,
} from "react-icons/bs";
import { useQueryClient } from "react-query";
import useAssignmentStore from "zustand-store/assignmentStore";
function AdminAssignmentNav({
  hideSandboxList = false,
  showSandboxList,
  setShowSandboxList,
  setShowVideosList,
  showVideosList,
  currentCourse,
  editMode,
  setEditMode,
  currentVideo,
  questionType,
  setQuestionType,
}) {
  // const queryClient = useQueryClient();
  // const currentVideo = queryClient.getQueryData("ideCurrentVideo");
  const adminQuestionMode = useAssignmentStore(
    (state) => state.adminQuestionMode
  );
  const setAdminQuestionMode = useAssignmentStore(
    (state) => state.setAdminQuestionMode
  );
  const adminSelectedAssignment = useAssignmentStore(
    (state) => state.adminSelectedAssignment
  );
  const adminSelectedAssignmentQuestion = useAssignmentStore(
    (state) => state.adminSelectedAssignmentQuestion
  );
  return (
    <div
      className={`admin-ide-nav ${editMode ? "admin-ide-nav-edit-mode" : ""}`}
    >
      {" "}
      {!hideSandboxList && (
        <span
          className={`admin-ide-btn ${
            showSandboxList ? "admin-ide-btn-active" : ""
          }`}
          onClick={() => setShowSandboxList(!showSandboxList)}
        >
          <BsLayoutSidebarInset className="cursor" />
          <span className="font-small">Questions</span>
        </span>
      )}
      <div className="admin-ide-center">
        <h6>
          {adminSelectedAssignment?.assignmentname || "Select Assignment on right"} -{" "}
          {adminSelectedAssignmentQuestion?.questionname || "Select question on left"}
          {/* <select
            id="questionType"
            value={questionType}
            onChange={(e) => setQuestionType(e.target.value)}
          >
            <option value="">Select a question type</option>
            <option value="mcq">MCQ</option>
            <option value="coding">Coding Challenge</option>
          </select> */}
        </h6>
        <span
          className="badge bg-warning text-black"
          onClick={() => setEditMode(false)}
          style={{ marginLeft: "10px" }}
        >
          {adminQuestionMode}
        </span>
      </div>
      <div className="flex items-center">
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-assignment-mcq");
          }}
        >
          + MCQ
        </span>
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-assignment-coding");
          }}
        >
          + Coding
        </span>
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-assignment-backend");
          }}
        >
          + Backend
        </span>
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-assignment-frontend");
          }}
        >
          + FE
        </span>
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-assignment-datascience");
          }}
        >
          + DS
        </span>
        <span
          className={`admin-ide-btn ${
            showVideosList ? "admin-ide-btn-active" : ""
          }`}
          onClick={() => setShowVideosList(!showVideosList)}
        >
          <BsReverseLayoutSidebarInsetReverse className="cursor" />
          <span className="font-small">Assignments</span>
        </span>
      </div>
    </div>
  );
}

export default AdminAssignmentNav;
