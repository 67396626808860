import { CompetitionsListContainer } from "../Competitions.styled";
import CompetitionCard from "./CompetitionCard";
import { useQuery, useQueryClient } from "react-query";
import competitionsService from "actions/services/competitions.service";
import ZPLoading from "pages/Watch/components/ZPLoading";
import Error from "components/Error/Error";
import ReactPlayer from "react-player";

function CompetitionsList({
  displayTitle = true,
  fromDashboard = false,
  handleInviteMembers,
}) {
  const queryClient = useQueryClient();
  const team = queryClient.getQueryData("getTeam");
  console.log("team", team?.teamData?._id);
  const {
    isLoading,
    error,
    data: competitions,
  } = useQuery(
    "getCompetitions",
    () => competitionsService.getCompetitions(team?.teamData?._id),
    {
      // enabled: !!team?.teamData?._id,
    }
  );

  const displayItems = () => {
    if (fromDashboard && competitions?.Data) {
      const competition = competitions?.Data[0];
      return (
        <CompetitionCard
          _id={competition?._id}
          competitionname={competition?.competitionname}
          competitionthumbnail={competition?.competitionthumbnail}
          prize={competition?.prize}
          duration={Math.floor(competition?.duration / 24)}
          company={competition?.company}
          tags={competition?.tags}
        />
      );
    }

    return (
      competitions?.Data &&
      competitions?.Data.map((competition, index) => {
        return (
          <CompetitionCard
            key={index}
            _id={competition?._id}
            competitionname={competition?.competitionname}
            competitionthumbnail={competition?.competitionthumbnail}
            prize={competition?.prize}
            duration={Math.floor(competition?.duration / 24)}
            company={competition?.company}
            tags={competition?.tags}
          />
        );
      })
    );
  };

  if (isLoading) return <ZPLoading />;
  if (error) return <Error />;
  if (competitions?.success === false)
    return <Error message={competitions?.message} />;
  return (
    <CompetitionsListContainer fromDashboard={fromDashboard}>
      {displayTitle && <h3>Live competitions</h3>}
      {!team?.teamData?._id && (
        <p className="text-underline cursor" onClick={handleInviteMembers}>
          <em>Create team</em>
        </p>
      )}

      <div className="cards-list">
        {displayItems()}

        {/* <CompetitionCard />
        <CompetitionCard />
        <CompetitionCard /> */}
        <div className="why-competitions">
          <ReactPlayer
            width="100%"
            height="100%"
            controls
            url="https://youtu.be/hgTI5VUgsjw"
          />
        </div>
      </div>
    </CompetitionsListContainer>
  );
}

export default CompetitionsList;
