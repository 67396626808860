import { useEffect, useState } from "react";
import { Circle } from "rc-progress";
import "./Redirect.css";

import JobEligibility from "pages/Jobs/JobsPage/JobEligibility";
function MockInterviewRequirements(props) {
  const [isChecklistDone, setIsChecklistDone] = useState(false);
  const { job, forModal } = props;
  return (
    <div className="redirect-container">
      <div
        className="mt-3 mb-3"
        onClick={() =>
          window
            .open(
              "https://docs.google.com/document/d/1eR-UWzJYnZHAyStmiNEGKhNaXUStCDI4UeVDp3NNH5k/edit?usp=sharing",
              "_blank"
            )
            .focus()
        }
      >
        <span className={`eligible-icon yellow-tag`}>
          View the checklist before applying for this opportunity.
        </span>
      </div>

      {job.eligible ? (
        <>
          <label
            htmlFor="blueinmail"
            className="signup-finetext mt-1 mb-2 w-100"
          >
            {" "}
            <input
              type="checkbox"
              id="blueinmail"
              name="blueinmail"
              value="true"
              className="mb-3 "
              checked={isChecklistDone}
              onClick={() => setIsChecklistDone(!isChecklistDone)}
            />{" "}
            I have viewed the checklist, and have prepared for the mock
            interview.
          </label>
          <button
            className="btn btn-block btn-main"
            disabled={!isChecklistDone}
          >
            Book Mock interview
          </button>
        </>
      ) : (
        <JobEligibility job={job} forModal={forModal} />
      )}
    </div>
  );
}

export default MockInterviewRequirements;
