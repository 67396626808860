import bootcamp from "actions/services/bootcamp.service";
import AllDays from "./AllDays";
import DayCardList from "./DayCardList";
import { parse } from "date-fns";

const Calendar = ({
  tasks,
  date,
  learningpath,
  email,
  bootcampData,
  handleDataUpdate,
  setLoading,
  goals,
}) => {
  const events = tasks;

  // An array of days containing events for populating the calendar
  const days = Array.from({ length: date.daysInMonth }, (_, i) => {
    const currentDay = i + 1;

    //Creates dateObject using month spelled out in a string, currentDay and year
    const dateObject = parse(
      `${date.month}, ${currentDay}, ${date.year}`,
      "MMMM, d, yyyy",
      new Date()
    );
    let eventsEachDay = events?.filter(
      (event) =>
        new Date(event?.date)?.toLocaleDateString() ===
        new Date(dateObject)?.toLocaleDateString()
    );
    const status = eventsEachDay[0]?.currentPerformance;
    eventsEachDay = eventsEachDay?.length > 0 ? eventsEachDay[0]?.tasks : [];
    return {
      date: dateObject,
      events: eventsEachDay,
      status,
    };
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e?.target);
    const newDate = formData.get("newDate");

    if (!newDate || new Date(newDate).getTime() <= new Date().getTime()) {
      alert("Please enter a valid date.");
      return false;
    }
    setLoading(true);
    bootcamp
      .reviseBootcampTasks({ newDate, learningpath, email })
      .then((res) => {
        handleDataUpdate(res);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="flex flex-grow relative h-full w-full overflow-auto text-gray-700 bg-white">
        <div className="flex flex-col flex-grow">
          <AllDays />
          <DayCardList
            goals={goals}
            data={days}
            firstDayOfMonth={date.firstDay}
          />
        </div>
        {bootcampData?.status === "blocked" && (
          <div className="absolute backdrop-blur-md flex  justify-center items-center flex-col  left-0 top-0 bottom-0 right-0">
            <div className="">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col justify-center items-center"
              >
                <p>
                  You are behind by {bootcampData?.totalPendingHours} Hours.
                  Please revise you tasks.
                </p>
                <input name="newDate" type="date" className="my-2" />
                <button
                  type="submit"
                  className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Revise
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Calendar;
