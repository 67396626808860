function ZipFileInput({ variant, onFileSelect }) {
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    onFileSelect(file)
  }

  return (
    <div className="inline-block">
      <label
        htmlFor='zipFileInput'
        // className='text-3xl text-gray-700 border border-gray-500 rounded-lg px-4 py-8 cursor-pointer'
        className={`flex justify-center items-center  text-white rounded cursor-pointer ${variant==="lg" ? "text-4xl py-2 pb-3 px-4 hover:bg-green-600 bg-green-700" : "bg-green-600 px-2 text-sm"}`}
      >
        +
      </label>
      <input
        className='w-[0.1px] h-[0.1px] opacity-0 overflow-hidden absolute -z-10'
        type='file'
        id='zipFileInput'
        accept='.zip,.rar,.7zip'
        onChange={handleFileChange}
      />
    </div>
  )
}

export default ZipFileInput
