import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Video from "../Video/Video";
// import db from "./firebase";
import "./VideoEntry.css";
import Footer from "../BottomNav/Footer";
import Header from "../Header/Header";
import { UserContext } from "context/UserProvider";
import courseService from "actions/services/course.service";
// import lectureService from "actions/services/lecture.service";
import { Constants } from "Constants";
import { Mixpanel } from "Utils/Mixpanel";
import ContentDrawer from "../ContentDrawer/ContentDrawer";

function VideoEntry() {
  const location = useLocation();
  const history = useHistory();
  const queries = new URLSearchParams(location.search);
  const [courseId, setCourseId] = useState(queries.get("courseid"));
  const [unitId, setUnitId] = useState(queries.get("unitid"));
  const [lectureId, setLectureId] = useState(queries.get("lectureid"));
  const [onboarding, setOnboarding] = useState(queries.get("onboarding"));

  const { user, setUser, setUserProgress } = useContext(UserContext);

  const [videos, setVideos] = useState([]);
  const [courseData, setCourseData] = useState({});
  const [coursesEnrolledIn, setCoursesEnrolledIn] = useState("");
  const [lectureDetails, setLectureDetails] = useState([]);

  const [contentDrawer, setContentDrawer] = useState({
    active: false,
  });

  const fetchCourseData = async () => {
    let cl,
      courseInfo,
      ln = null;
    try {
      // cl = await courseService.getCourses();
      courseInfo = await courseService.getCourse(courseId);
      // ln = await lectureService.getLecture(lectureId);
      // console.log("courseInfo", courseInfo.data);
      // console.log("courseList", cl);
      // console.log("lecturename", ln);
      setCourseData(courseInfo.data);
      // setCoursesEnrolledIn(cl)
      // setLectureDetails(ln)
      setVideos(courseInfo.data.courseunit[0].lecture);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/quiz-reel",
      "Page name": "Mobile Reel Page",
    });
    if (onboarding) {
      Mixpanel.track("Onboarding Started");
    }
    fetchCourseData();
    fetchCountLecturesWatched();
  }, []);

  const fetchCountLecturesWatched = async () => {
    if (user && user.success) {
      await courseService.getCourseCount(courseId).then((response) => {
        // console.log("COURSE PROGRESS: ", response.data);
        setUserProgress((prevProgress) => ({
          ...prevProgress,
          completedlecturecount: response.data
            ? response.data.completedlecturecount
            : 0,
        }));
      });
    }
  };

  const handleOpenContentDrawer = () => {
    // clickSfx();
    setContentDrawer({ ...contentDrawer, active: true });
    setUser({ ...user, isContentDrawerOpen: true });
  };

  const handleCloseContentDrawer = () => {
    // clickSfx();
    setContentDrawer({ ...contentDrawer, active: false });
    setUser({ ...user, isContentDrawerOpen: false });
  };

  const scrollToVideo = (lectureid) => {
    if (!lectureId) return;

    var el = document.getElementById(lectureid + "-video-click");
    el.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      history.push(
        `/quiz-reel?courseid=${courseId}&lectureid=${lectureid}&unitid=${unitId}`
      );
    }, 2500);
  };

  const onboardingVideos = [
    {
      lecturename: "Welcome to Zaio!",
      lecturedescription:
        "Zaio teaches you how to code in a fun, and easy to understand manner. From absolute beginner, to earning an income. Its an interactive experience. This is how we do it. Answer the questions to help us understand you better",
    },
  ];

  return (
    // BEM
    <>
      <button
        className="btn-dash btn-main m-2"
        onClick={() =>
          user && user?.success
            ? history.push("/updated-dashboard")
            : history.push("/getstarted")
        }
      >
        Back to dashboard
      </button>
      {/* <DashboardNavbar minutesRemaining={300} /> */}
      <div className="qr-app">
        {/* {user && !user.isQRPlaying ? <Header playlistLenggth={videos.length} /> : null} */}
        <Header playlistLength={videos.length} />
        <div className="back-logo"></div>
        <div
          className="qr-app__videos"
          style={{
            overflow: user && user.isContentDrawerOpen ? "hidden" : "scroll",
          }}
        >
          {onboarding === "true" ? (
            <Video
              url={Constants.onboardingVideo}
              title={Constants.onboardingVideoTitle}
              description={Constants.onboardingVideoDescription}
              quizOptions={null}
              quizPushes={null}
              displayQuizTimestampString={"00:12"} //what brings you here
              tryAgainQPStringTime={null}
              jumpToTryAgainStringTime={null}
              jumptoContinueStringTime={null}
              hasQuiz={true}
              index={0}
              playlistLength={1}
              videos={onboardingVideos}
              courseData={[]}
              getAJobTimeStampString={"00:19"}
              getAJobTriggerString={"00:34"}
              sideHustleTimeStampString={"00:43"}
              sideHustleTriggerString={"01:01"}
              codingAsASkillTimeStampString={"01:09"}
              codingAsASkillTriggerString={"01:22"}
              howComfortableTimeStampString={"01:29"}
              howComfortableTriggerString={"01:41"}
              courseOptionsTimeStampString={"02:22"}
              courseOptionsTriggerString={"02:53"}
              mobileCoursesTimeStampString={"01:46"}
              mobileCoursesTriggerString={"02:15"}
              dlCoursesTimeStampString={"02:59"}
              dlCoursesTriggerString={"03:09"}
              onboarding
            />
          ) : (
            videos
              // .sort((a, b) => a.lectureno - b.lectureno)
              .map(
                (
                  {
                    lectureurl,
                    lecturename,
                    lecturedescription,
                    quizpushes,
                    lectureno,
                    _id,
                  },
                  index
                ) => {
                  // console.log(_id);
                  return (
                    <Video
                      _id={_id}
                      key={index}
                      url={lectureurl}
                      // url={"https://www.loom.com/embed/ec936ba1feb0424796a88fb6a5a39c2b"}

                      // url={"https://zaiocontent.s3.eu-west-2.amazonaws.com/Learn+to+Code+_+Zaio+-+2+November+2022.mp4"}
                      // url={"https://zaiocontent.s3.eu-west-2.amazonaws.com/WhatsApp+-+2+November+2022.mp4"}
                      // url={"https://zaiocontent.s3.eu-west-2.amazonaws.com/Code+-+VideoEntry.js+%E2%80%94+zaio-frontend+-+2+November+2022.mp4"}
                      // url={"https://zaiocontent.s3.eu-west-2.amazonaws.com/S3+Management+Console+-+2+November+2022.mp4"}
                      // url={"https://zaiocontent.s3.eu-west-2.amazonaws.com/must+del.mov"}

                      lectIdFromUrl={lectureId}
                      title={lecturename}
                      description={lecturedescription}
                      quizOptions={quizpushes[0].quizoptions}
                      quizPushes={quizpushes}
                      displayQuizTimestampString={quizpushes[0].pushtime}
                      tryAgainQPStringTime={quizpushes[0].tryagainpush}
                      jumpToTryAgainStringTime={quizpushes[0].tryagain}
                      jumptoContinueStringTime={quizpushes[0].continue}
                      hasQuiz={true}
                      index={lectureno}
                      playlistLength={videos.length}
                      videos={videos}
                      courseData={courseData}
                      scrollToVideo={scrollToVideo}
                      courseId={courseId}
                      unitId={unitId}
                      handleOpenContentDrawer={handleOpenContentDrawer}
                    />
                  );
                }
              )
          )}
        </div>
        {contentDrawer.active && (
          <ContentDrawer
            contentDrawer={contentDrawer}
            handleCloseContentDrawer={handleCloseContentDrawer}
            videos={videos}
            courseData={courseData}
            scrollToVideo={scrollToVideo}
          />
        )}
        {/* {user && !user.isQRPlaying ? <Footer /> : null} */}
        <Footer />
      </div>
    </>
  );
}

export default VideoEntry;
