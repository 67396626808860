import React from "react";
import "./SignupProcess.css";
import AuthService from "actions/services/auth.service";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "context/UserProvider";
import { LoadingContext } from "context/LoadingProvider";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Mixpanel } from "Utils/Mixpanel";

const API_URL = process.env.REACT_APP_BACKEND_URL;

function ValidateEmail(inputText) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

const CompleteSignin = () => {
  const [page, setPage] = useState(1);
  const [init, setInit] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);

  const googleUser = JSON.parse(localStorage.getItem("googleUser"));

  const history = useHistory();
  const queries = new URLSearchParams(history.location.search);

  useEffect(() => {
    if (user && googleUser) {
      if (
        user &&
        user.success &&
        user.data.username &&
        (googleUser.sso !== "googlelogin" || googleUser.alreadyRegister)
      ) {
        // history.push('/dashboard');
        history.push("/updated-dashboard");
      }
      setState({
        ...state,
        username: googleUser.data.username,
        email: googleUser.data.email,
      });
    } else if (user)
      if (user && user.success && user.data.username) {
        // history.push('/dashboard');
        history.push("/updated-dashboard");
      }
  }, []);

  const [state, setState] = useState({
    username: "",
    name: !!user
      ? user.hasOwnProperty("data")
        ? user.data.hasOwnProperty("username")
          ? true
          : false
        : !!user.username
      : false,
    email:
      user.hasOwnProperty("data") && user.data.hasOwnProperty("email")
        ? user.data.email
        : user.hasOwnProperty("email")
        ? user.email
        : "",
    password:
      user.hasOwnProperty("data") && user.data.hasOwnProperty("password")
        ? user.data.password
        : "",
    success: !!user,
    completed: !!user
      ? user.hasOwnProperty("data")
        ? user.data.hasOwnProperty("completed")
          ? user.data.completed
          : false
        : !!user.completed
      : false,
    message: "",
    redirect: queries.get("redirect") || "/dashboard",
    src: "zaio.io",
    coursein: "na",
    heardfrom: "select",
    phonenumber:
      user.hasOwnProperty("data") && user.data.hasOwnProperty("phonenumber")
        ? user.data.phonenumber
        : "",
    goal: "",
    currentStatus: "",
    commitment: "",
    dob: new Date(1997, 8, 28),
  });

  const onChangeState = (e, prop) => {
    setState({ ...state, [prop]: e.target.value });
  };

  const onSelectPhoneNumber = (phone) => {
    setState({ ...state, phonenumber: phone });
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setState({
      ...state,
      message: "",
      successful: false,
    });

    Mixpanel.people.set({
      $distinct_id: state.email,
      "Heard from": state.heardFrom,
      $name: state.username,
      "Phone number": state.phonenumber,
    });

    console.log("state", state);

    setLoading(true);

    AuthService.completeRegister(
      state.username,
      state.email,
      state.phonenumber,
      state.heardfrom,
      queries.get("course")
    )
      .then((response) => {
        if (response.success) {
          setState({
            ...state,
            success: true,
            message: response.message,
          });
          setUser({ ...response, ...response.data });
          if (response.data.username && response.data.completed) {
            // history.push('/dashboard');
            history.push("/updated-dashboard");
            return;
          }
        } else {
          const resMessage = response.message;
          setState({
            ...state,
            successful: false,
            message: resMessage,
          });
        }
      })
      .catch((reject) => alert("somethingwentwrong"))
      .then(() => setLoading(false));
  };

  const LoadPage = () => (
    <Page1
      setPage={setPage}
      setInit={setInit}
      handleRegister={handleRegister}
      onChangeState={onChangeState}
      state={state}
      onSelectPhoneNumber={onSelectPhoneNumber}
    />
  );

  return (
    <React.Fragment>
      {/* Facebook Pixel Code  */}
      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '578796853193199');
          fbq('track', 'PageView');
          `}
      </script>
      <noscript>
        <img
          height={1}
          width={1}
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=578796853193199&ev=PageView&noscript=1"
        />
      </noscript>
      {/* End Facebook Pixel Code */}

      <div>{LoadPage()}</div>
    </React.Fragment>
  );
};

const Page1 = ({
  setPage,
  setInit,
  state,
  message,
  onChangeState,
  onSelectPhoneNumber,
  handleRegister,
}) => {
  const [error, setError] = useState("");

  const onClickOption = (e) => {
    e.preventDefault();
    //

    if (state.username == "" || state.phonenumber == "") {
      setError("Please enter all fields above correctly to continue");
    } else {
      handleRegister(e);
    }
  };

  return (
    <div className="page-container">
      <div className="m-3">
        <form
          id="1"
          className="signup-form col-12 col-md-6 col-lg-4 border-4 rounded border-primary bg-white"
          onSubmit={onClickOption}
        >
          <h3 className="d-flex justify-content-center mediumBlackText mt-4">
            Enter your personal details
          </h3>
          <div className="form-group mt-4">
            <label
              className="apercuProText font_16 pb-2 pt-2"
              htmlFor="username"
            >
              Full Name
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="username"
              placeholder="Jack Black"
              value={state.username}
              onChange={(e) => onChangeState(e, "username")}
              required
            />
          </div>

          <div className="form-group">
            <label className="apercuProText font_16 pb-2 pt-2" htmlFor="email">
              Mobile Number
            </label>
            <PhoneInput
              country={"za"}
              preferredCountries={[
                "za",
                "ng",
                "eg",
                "bw",
                "ke",
                "gb",
                "gh",
                "mu",
                "mw",
              ]}
              placeholder="+27 763 793 540"
              value={state.phonenumber}
              onChange={(phone) => onSelectPhoneNumber(phone)}
              inputStyle={{ width: "100%", height: "48px" }}
              inputProps={{
                name: "phonenumber",
                required: true,
              }}
            />
          </div>

          <div className="form-group">
            <label className="apercuProText font_16 pb-2 pt-2">
              Where did you find us?
            </label>
            <select
              className="form-control form-control-lg"
              value={state.heardfrom}
              onChange={(e) => onChangeState(e, "heardfrom")}
            >
              <option defaultValue="select" value="select">
                Click to select
              </option>
              <option value="facebook">Facebook</option>
              <option value="outside-a-school-flyers">
                Outside a school or flyers
              </option>
              <option value="instagram">Instagram</option>
              <option value="tiktok">TikTok</option>
              <option value="wordofmouth">Word of mouth</option>
              <option value="uber">Uber/Bolt/Didi/Taxi</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="form-group mt-4">
            {error && <p className="error">{error}</p>}
            {state.message && <p className="error">{state.message}</p>}
            <button
              className="btn btn-main w-100 btn-lg"
              type="submit"
              onClick={onClickOption}
            >
              Get Started
            </button>
          </div>

          <div className=" mt-1 d-flex no-gutters justify-content-center normalText font_14">
            <p>
              <Link className="text link-main text-underline" to={`/logout`}>
                Back to signup
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompleteSignin;
