import { useState } from "react";
import ReelModal from "components/QuizReelComponents/ReelModal/ReelModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import RSSUsage from "components/SocialShare/SocialShare";
import InviteEarnBody from "./InviteEarnBody";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCopy } from "react-icons/fa";
import "./InviteLink.css";

function InviteContainer({ inviteLink }) {
  const [modal, setModal] = useState({
    show: false,
    title: "HELO HELO",
    description:
      "Try sign up, it will take a min, and you will be get a personalized experience - ITS FREE.",
    CustomComponent: null,
  });

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      title: "",
      description:
        "Try sign up, it will take a min, and you will be get a personalized experience - ITS FREE.",
      CustomComponent: null,
      props: {},
    });
  };

  const handleInviteMembers = () => {
    setModal({
      ...modal,
      show: true,
      title: "Invite & Earn",
      description: "",
      CustomComponent: InviteEarnBody,
    });
  };
  const notify = () => toast(<p>Copied to clipboard</p>);
  return (
    <div className="invite-link-container mt-3">
      <ToastContainer />
      {modal.show && (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          CustomComponent={modal.CustomComponent ?? false}
          props={modal.props ?? false}
        />
      )}
      <h3>Invite a friend</h3>
      <div className="w-100">
        <RSSUsage url={inviteLink?.data?.invitelink} />
        <CopyToClipboard text={inviteLink?.data?.invitelink}>
          <span className="invite-span" onClick={notify}>
            <code>
              <small>{inviteLink?.data?.invitelink}</small>
            </code>
            <br />
            <FaCopy className="copy-link" />
          </span>
        </CopyToClipboard>
        {/* <p className="text-center">OR</p> */}

        <span
          className="btn btn-main btn-block w-100"
          onClick={handleInviteMembers}
        >
          Or Invite by email
        </span>
      </div>
    </div>
  );
}

export default InviteContainer;
