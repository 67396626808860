import React from "react";

function Sponsor({ company }) {
  return (
    <div>
      <h1>Sponsor</h1>
      <img src={company?.fulllogo} />
      <p>
        <b>{company?.name}</b> is sponsoring this competition. Learn more about{" "}
        <b>{company?.name}</b> on their website{" "}
        <a href={company?.website}>{company?.website}</a>
      </p>
      <p>
        This project idea has been provided by {company?.name} and the prizes
        will be provided by them.
      </p>
      <p>
        Give it your best, it will look good on your profile and will open up
        recruitment opportunities for you as this will appear in your profile,
        and could get hired by Liorra.
      </p>
      <p>Goodluck!</p>
    </div>
  );
}

export default Sponsor;
