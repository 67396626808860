import lectureService from "actions/services/lecture.service";
import React, { useEffect, useState } from "react";
import { Form, Button, Card, ListGroup } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAssignmentStore from "zustand-store/assignmentStore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function MCQForm({ courseId, unitId, lectureId, assignmentid = null }) {
  // console.log("lectureId", lectureId);
  const [questions, setQuestions] = useState([]);
  const [questionText, setQuestionText] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctOptionIndex, setCorrectOptionIndex] = useState(-1);
  const [afterLecture, setAfterLecture] = useState(lectureId);
  const [answerExplanation, setAnswerExplanation] = useState("");
  const [lectureName, setLectureName] = useState("");
  const [duration, setDuration] = useState("");
  const [marks, setMarks] = useState(null);
  const [editIndex, setEditIndex] = useState(-1);
  const [assignmentDropdown, setAssignmentDropdown] = useState("0");
  const [questionName, setQuestionName] = useState("");
  const [isAdminAssignmentMCQ, setIsAdminAssignmentMCQ] = useState(
    !!assignmentid
  );
  const [editQuestion, setEditQuestion] = useState(null);

  const [currentFocus, setCurrentFocus] = useState(false);

  const adminCourseQuestion = useAssignmentStore(
    (state) => state.adminCourseQuestion
  );
  const adminQuestionMode = useAssignmentStore(
    (state) => state.adminQuestionMode
  );
  const adminSelectedAssignmentQuestion = useAssignmentStore(
    (state) => state.adminSelectedAssignmentQuestion
  );
  const setAdminAssignmentList = useAssignmentStore(
    (state) => state.setAdminAssignmentList
  );
  const queryClient = useQueryClient();

  const createAssignmentQMutation = useMutation(
    () =>
      lectureService.createAssignmentMCQSeriesAsAdmin(
        assignmentid,
        questions,
        marks,
        questionName
      ),
    {
      onSuccess: (data) => {
        console.log("created assignment mcq series...", data);
        alert(
          "Created ASSIGNMENT MCQ series...response: " + JSON.stringify(data)
        );
        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const deleteAssignmentQMutation = useMutation(
    (tcs) =>
      lectureService.deleteAssignmentMCQSeries(
        adminSelectedAssignmentQuestion?._id,
        assignmentid
      ),
    {
      onSuccess: (data) => {
        console.log("deleted assignment challenge...", data);
        alert("DELETED ASSIGNMENT QUESTION...response" + JSON.stringify(data));
        return queryClient.invalidateQueries(["assignmentList"]);
      },
    }
  );
  const addAssignmentMCQMutation = useMutation(
    (question) =>
      lectureService.addAssignmentMCQ(
        adminSelectedAssignmentQuestion?._id,
        assignmentid,
        editQuestion?._id,
        question
      ),
    {
      onSuccess: (data) => {
        console.log("created assignment mcq series...", data);
        alert(
          "ADDED ASSIGNMENT MCQ series...response: " + JSON.stringify(data)
        );
        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const updateAssignmentMCQMutation = useMutation(
    (question) =>
      lectureService.updateAssignmentMCQ(
        adminSelectedAssignmentQuestion?._id,
        assignmentid,
        editQuestion?._id,
        question,
        marks
      ),
    {
      onSuccess: (data) => {
        console.log("created assignment mcq series...", data);
        alert(
          "UPDATED ASSIGNMENT MCQ series...response: " + JSON.stringify(data)
        );
        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const deleteAssignmentMCQMutation = useMutation(
    (questionid) =>
      lectureService.deleteAssignmentMCQ(
        adminSelectedAssignmentQuestion?._id,
        assignmentid,
        questionid
      ),
    {
      onSuccess: (data) => {
        console.log("created assignment mcq series...", data);
        alert("DELETED ASSIGNMENT MCQ...response: " + JSON.stringify(data));
        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const submitMCQMutation = useMutation(
    () =>
      lectureService.adminUploadMCQ(
        courseId,
        unitId,
        lectureName,
        duration,
        questions,
        afterLecture,
        assignmentDropdown
      ),
    {
      onSuccess: (data) => {
        console.log("submitted MCQ...", data);
        alert("Created Course MCQ series...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const addCourseMCQMutation = useMutation(
    (question) =>
      lectureService.createCourseMCQ(
        adminCourseQuestion?.coursemcq?._id,
        question
      ),
    {
      onSuccess: (data) => {
        console.log("created assignment mcq series...", data);
        alert("ADDED Course MCQ QUESTION...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const updateCourseMCQMutation = useMutation(
    (question) =>
      lectureService.updateCourseMCQ(
        adminCourseQuestion?.coursemcq?._id,
        editQuestion?._id,
        question
      ),
    {
      onSuccess: (data) => {
        console.log("created assignment mcq series...", data);
        alert(
          "UPDATED Course MCQ QUESTION...response: " + JSON.stringify(data)
        );
        queryClient.invalidateQueries("videoCourseList");
        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const deleteCourseMCQMutation = useMutation(
    (questionid) =>
      lectureService.deleteCourseMCQ(
        adminCourseQuestion?.coursemcq?._id,
        questionid
      ),
    {
      onSuccess: (data) => {
        console.log("deleted assignment mcq series...", data);
        alert("DELETE Course MCQ QUESTION...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const updateLectureInfoMutation = useMutation(
    () =>
      lectureService.putLecture({
        lecturename: lectureName,
        duration: duration,
        afterlecture: afterLecture,
        _id: adminCourseQuestion?._id,
        releaseassignment: assignmentDropdown,
      }),
    {
      onSuccess: (data) => {
        console.log("update lecture information...", data);
        alert("UPDATE LECTURE INFO...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  );

  const {
    isLoading: isAssignmentListLoading,
    isError,
    error,
    data: assignmentListResponse,
  } = useQuery(
    "assignmentList",
    () => lectureService.getAdminAssignmentList(),
    {
      onSuccess: (response) => {
        setAdminAssignmentList(response?.data);
      },
    }
  );

  useEffect(() => {
    if (adminSelectedAssignmentQuestion) {
      console.log("ASSIGNMENT QUESTION...", adminSelectedAssignmentQuestion);
      const questions = adminSelectedAssignmentQuestion?.questions;
      if (questions) {
        // storeInLS(questions);
        setQuestions(questions);
        setLectureName(adminSelectedAssignmentQuestion?.title);
        setMarks(adminSelectedAssignmentQuestion?.marks);
        setQuestionName(adminSelectedAssignmentQuestion?.questionname);
      }
    } else if (adminCourseQuestion) {
      // if (adminQuestionMode === "create-course-coursemcq") {
      //   resetForm();
      // }
      console.log("COURSE QUESTION...", adminCourseQuestion);
      const questions = adminCourseQuestion?.coursemcq?.questions;
      if (questions) {
        // storeInLS(questions);
        setQuestions(questions);
        setLectureName(adminCourseQuestion?.lecturename);
        setDuration(adminCourseQuestion?.duration);
        setAssignmentDropdown(adminCourseQuestion?.releaseassignment || "0");
      }
    } else {
      resetForm();
    }
  }, [adminCourseQuestion, adminSelectedAssignmentQuestion, lectureId]);

  const storeInLS = (Qs) => {
    localStorage.setItem("lectureName", lectureName);
    localStorage.setItem("duration", duration);
    localStorage.setItem("questions", JSON.stringify(Qs));
  };

  const handleAddQuestion = (e) => {
    e.preventDefault();

    const newQuestion = {
      lecture: lectureId,
      type: "coursemcq",
      questionText,
      correctAnswer: [
        {
          id: correctOptionIndex,
          answer: options[correctOptionIndex],
          explanation: answerExplanation,
        },
      ],
      answerOptions: options.map((option, index) => ({
        id: index,
        answer: option,
      })),
    };
    console.log("NEW QUESTION", newQuestion);
    // storeInLS([...questions, newQuestion]);
    setQuestions([...questions, newQuestion]);
    resetForm();

    if (adminQuestionMode === "edit-course-coursemcq")
      addCourseMCQMutation.mutate(newQuestion);
    if (adminQuestionMode === "edit-assignment-mcq")
      addAssignmentMCQMutation.mutate(newQuestion);

    // if (isAdminCourseMCQ) addCourseMCQMutation.mutate(newQuestion);
  };

  const resetForm = () => {
    setQuestionText("");
    setOptions(["", "", "", ""]);
    setCorrectOptionIndex(-1);
    setEditIndex(-1);
    setAnswerExplanation("");
    setEditQuestion(null);
  };

  const handleUpdateQuestion = (e) => {
    e.preventDefault();
    const editedQuestion = {
      lecture: lectureId,
      type: "coursemcq",
      questionText,
      correctAnswer: [
        {
          id: correctOptionIndex,
          answer: options[correctOptionIndex],
          explanation: answerExplanation,
        },
      ],
      answerOptions: options.map((option, index) => ({
        id: index,
        answer: option,
      })),
    };
    const updatedQuestions = questions.map((question, index) =>
      editIndex === index ? editedQuestion : question
    );
    // storeInLS(updatedQuestions);
    setQuestions(updatedQuestions);
    resetForm();
    if (adminQuestionMode === "edit-course-coursemcq")
      updateCourseMCQMutation.mutate(editedQuestion);
    if (adminQuestionMode === "edit-assignment-mcq")
      updateAssignmentMCQMutation.mutate(editedQuestion);
  };

  const handleEditQuestion = (index, q) => {
    setEditQuestion(q);
    console.log("EDITING", index, editIndex);
    setEditIndex(index);

    const question = questions[index];
    console.log(
      "EDITING",
      question,
      question.answerOptions.map((option) => `<div>${option.answer}</div>`)
    );
    setQuestionText(question.questionText);
    setOptions(question.answerOptions.map((option) => `${option.answer}`));
    setCorrectOptionIndex(question.correctAnswer[0].id);
    setAnswerExplanation(question.correctAnswer[0].explanation);
  };

  const handleDeleteQuestion = (index, q) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    // storeInLS(newQuestions);
    setQuestions(newQuestions);

    alert("About to delete from DB, refresh page to stop operation");
    console.log(q._id);
    // if (isAdminCourseMCQ) deleteCourseMCQMutation.mutate(q._id);

    if (adminQuestionMode === "edit-course-coursemcq")
      deleteCourseMCQMutation.mutate(q._id);
    if (adminQuestionMode === "edit-assignment-mcq")
      deleteAssignmentMCQMutation.mutate(q._id);
  };
  const handlePostMCQ = (e) => {
    e.preventDefault();

    // const objToSend = {
    //   courseid: courseId,
    //   courseunitid: unitId,
    //   lecturename: lectureName,
    //   duration: duration,
    //   questions: questions,
    //   afterlecture: afterLecture,
    // };
    // console.log("POST MCQ", objToSend);
    if (adminQuestionMode === "create-assignment-mcq") {
      console.log("creating assignment series");
      createAssignmentQMutation.mutate();
    }
    // else if (adminQuestionMode === "edit-assignment-mcq") {
    //   handleUpdateQuestion(e);
    // }
    else {
      console.log("creating mcq between videos");
      submitMCQMutation.mutate();
    }
  };

  const handleEditorChange = (content, delta, source, editor, index) => {
    // const updatedOptions = options.map((option, i) =>
    //   index === i ? val : option
    // );

    console.log("EDITOR CHANGE", content, delta, source, editor, index);
    console.log("EDITOR CHANGE CONTENTS", editor.getContents());
    console.log("EDITOR CHANGE getHTML", editor.getHTML());
    // setOptions([
    //   ...options.slice(0, index),
    //   editor.getHTML(),
    //   ...options.slice(index + 1),
    // ]);
    // setOptions(updatedOptions);
    return;
  };

  const displayFormDataWithoutMCQs = () => {
    if (adminQuestionMode.includes("coursemcq")) {
      return (
        <>
          <Form.Group controlId="lectureName">
            <Form.Label className="text-white">Quiz Name</Form.Label>
            <Form.Control
              type="text"
              value={lectureName}
              onChange={(event) => setLectureName(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="duration">
            <Form.Label className="text-white">Duration</Form.Label>
            <Form.Control
              type="number"
              value={duration}
              onChange={(event) => setDuration(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="afterLecture">
            <Form.Label className="text-white">After Lecture</Form.Label>
            <Form.Control
              type="text"
              value={afterLecture}
              onChange={(event) => setAfterLecture(event.target.value)}
            />
          </Form.Group>
        </>
      );
    }

    if (adminQuestionMode.includes("assignment")) {
      return (
        <Form.Group controlId="duration">
          <Form.Label className="text-white">Marks</Form.Label>
          <Form.Control
            type="number"
            value={marks}
            onChange={(event) => setMarks(event.target.value)}
          />
        </Form.Group>
      );
    }
  };

  const displayUniversalFormBtn = () => {
    if (adminQuestionMode.includes("coursemcq")) return setCourseMCQBtns();
    if (adminQuestionMode.includes("assignment")) return setAssignmentBtns();
  };

  const setCourseMCQBtns = () => {
    if (adminQuestionMode === "edit-course-coursemcq") {
      return (
        <>
          {submitMCQMutation?.isLoading ? (
            <p className="text-white text-center w-100">Loading...</p>
          ) : (
            <button
              className="btn-warning p-3 w-100 m-2"
              onClick={() => updateLectureInfoMutation.mutate()}
            >
              Update MCQ Data to DB
            </button>
          )}
        </>
      );
    }
    if (adminQuestionMode === "create-course-coursemcq") {
      return (
        <>
          {submitMCQMutation?.isLoading ? (
            <p className="text-white text-center w-100">Loading...</p>
          ) : (
            <button
              className="btn-warning p-3 w-100 m-2"
              onClick={handlePostMCQ}
            >
              Submit MCQ to DB
            </button>
          )}
        </>
      );
    }
  };
  const setAssignmentBtns = () => {
    if (adminQuestionMode === "create-assignment-mcq")
      return (
        <>
          {createAssignmentQMutation?.isLoading ? (
            <p className="text-white text-center w-100">Loading...</p>
          ) : (
            <button
              className="btn-warning p-3 w-100 m-2"
              onClick={handlePostMCQ}
            >
              Create MCQ Series
            </button>
          )}
        </>
      );

    if (adminQuestionMode.includes("edit-course")) {
      return (
        <>
          {createAssignmentQMutation?.isLoading ? (
            <p className="text-white text-center w-100">Loading...</p>
          ) : (
            <button
              className="btn-warning p-3 w-100 m-2"
              onClick={handlePostMCQ}
            >
              Update Marks
            </button>
          )}
        </>
      );
    }
  };

  return (
    <div>
      {displayUniversalFormBtn()}
      <div className="d-flex w-100">
        <div className="w-50 m-3">
          {/* {adminQuestionMode === "edit-assignment-mcq" && (
          <button
            className="bg-red-400 p-2 px-3 m-2 rounded"
            onClick={() => {
              alert("Refresh page to stop.");
              deleteAssignmentQMutation.mutate();
            }}
          >
            Delete Assignment Question
          </button>
        )} */}
          {!adminQuestionMode.includes("assignment") && (
            <Form.Group controlId="questionType">
              <Form.Label className="text-white">
                Select Assignment to release
              </Form.Label>
              {isAssignmentListLoading ? (
                <p>Loading...</p>
              ) : (
                <Form.Select
                  id=""
                  value={assignmentDropdown}
                  onChange={(event) =>
                    setAssignmentDropdown(event.target.value)
                  }
                >
                  <option value="0">No assignment to release</option>
                  {assignmentListResponse?.data?.map((ass, index) => (
                    <option value={ass?._id}>
                      {ass?.assignmentname} ({ass?._id})
                    </option>
                  ))}
                </Form.Select>
              )}
            </Form.Group>
          )}

          {adminQuestionMode.includes("assignment") && (
            <Form.Group controlId="questionName">
              <Form.Label className="text-white">MCQ Series Name</Form.Label>
              <Form.Control
                type="url"
                value={questionName}
                onChange={(event) => setQuestionName(event.target.value)}
              />
            </Form.Group>
          )}

          {displayFormDataWithoutMCQs()}

          <Form>
            <Form.Group controlId="questionText">
              <Form.Label className="text-white">Question Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={questionText}
                onChange={(event) => setQuestionText(event.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="options">
              <Form.Label className="text-white">Options</Form.Label>
              <div className="">
                {options.map((option, index) => (
                  <div
                    className="mt-5 bg-slate-200 bg-white"
                    style={{ zIndex: 2 }}
                  >
                    <Form.Check
                      className="mx-2"
                      type="radio"
                      label="Correct"
                      checked={correctOptionIndex === index}
                      onChange={() => setCorrectOptionIndex(index)}
                      style={{ zIndex: 999999 }}
                    />
                    <div className="mb-2 bg-white h-80" style={{ zIndex: 1 }}>
                      <ReactQuill
                        key={index}
                        id={index}
                        theme="snow"
                        className="w-full h-full bg-white"
                        style={{ background: "white" }}
                        value={option}
                        // onChange={(content, delta, source, editor) => {
                        //   handleEditorChange(
                        //     content,
                        //     delta,
                        //     source,
                        //     editor,
                        //     index
                        //   );
                        // }}
                        onFocus={(range, source, editor) => {
                          setCurrentFocus(index);
                        }}
                        onBlur={(previousRange, source, editor) => {
                          console.log(
                            "\n\nBLUR ----" + index,
                            previousRange,
                            source,
                            editor.getHTML()
                          );
                          if (currentFocus === index) {
                            setOptions(
                              options.map((option, i) =>
                                index === i ? editor.getHTML() : option
                              )
                            );
                          }
                        }}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, false] }],
                            ["bold", "italic", "underline"],
                            ["image", "code-block"],
                          ],
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* <ListGroup>
                {options.map((option, index) => (
                  <ListGroup.Item key={index}>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      placeholder={`Option ${index + 1}`}
                      value={option}
                      onChange={(event) =>
                        setOptions([
                          ...options.slice(0, index),
                          event.target.value,
                          ...options.slice(index + 1),
                        ])
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="Correct"
                      checked={correctOptionIndex === index}
                      onChange={() => setCorrectOptionIndex(index)}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup> */}
            </Form.Group>
            <Form.Group controlId="answerExplanation">
              <Form.Label className="text-white">Answer Explanation</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={answerExplanation}
                onChange={(event) => setAnswerExplanation(event.target.value)}
              />
            </Form.Group>
            {editIndex >= 0 ? (
              <button
                className="btn success-btn p-3 w-100 m-2"
                onClick={handleUpdateQuestion}
              >
                Update Question
              </button>
            ) : (
              <button
                className="btn-primary p-3 w-100 m-2"
                onClick={handleAddQuestion}
              >
                Add Question
              </button>
            )}
            {editIndex >= 0 && (
              <button
                className="btn-primary  p-3 w-100 m-2"
                onClick={resetForm}
              >
                Reset Question
              </button>
            )}
          </Form>
        </div>
        <div className="w-50 m-3">
          {questions &&
            questions.length > 0 &&
            questions.map((question, index) => (
              <Card key={index} className="mt-3">
                <Card.Body>
                  <Card.Title className="d-flex align-items-center justify-content-between">
                    Question {index + 1}
                    <div className="d-flex">
                      <button
                        className="btn-primary btn-sm m-2"
                        onClick={() => handleEditQuestion(index, question)}
                      >
                        Edit Question
                      </button>
                      <button
                        className="btn-danger btn-sm m-2"
                        onClick={() => handleDeleteQuestion(index, question)}
                      >
                        Delete Question
                      </button>
                    </div>
                  </Card.Title>
                  {/* <Card.Subtitle className="mb-2 text-muted">
              Duration: {question.duration} minutes
            </Card.Subtitle> */}
                  <Card.Text>{question?.questionText}</Card.Text>
                  <ListGroup>
                    {questions &&
                      questions?.length > 0 &&
                      question?.answerOptions.map((option, index) => (
                        <ListGroup.Item
                          key={index}
                          style={{
                            background:
                              question?.correctAnswer[0].id === index
                                ? "#00d395"
                                : "",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: option?.answer,
                            }}
                          >
                            {/* {question?.correctAnswer[0].id === index && (
                              <span className="ml-2 text-success">Correct</span>
                            )} */}
                          </div>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                  <Card.Text className="mt-2">
                    Correct Answer:{" "}
                    {question &&
                      question?.correctAnswer.length > 0 &&
                      question?.correctAnswer[0].explanation}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MCQForm;
