import lectureService from "actions/services/lecture.service";
import { formatProjectFiles } from "components/IDE/ideUtils";
import Loader from "components/Loader/Loader";
import { useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";

function AdminSandboxList({
  lectureid,
  setEditMode,
  setPlaygroundId,
  loadModal,
  setShowSandboxList,
  setSelectedSandbox,
  setFromAddNewBtn,
  setProjectFiles,
}) {
  //queries
  const {
    isLoading,
    isError,
    error,
    data: sandboxResponse,
  } = useQuery("sandboxes", () => lectureService.getCodePushes(lectureid), {
    onSuccess: (data) => {},
    onError: (err) => {},
  });

  const queryClient = useQueryClient();

  const refershSandboxListPushMutation = useMutation(
    () => Promise.resolve("resolved"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sandboxes");
      },
    }
  );

  const deleteCodePushMutation = useMutation(
    (playgroundid) => lectureService.deletePlayground(lectureid, playgroundid),
    {
      onSuccess: (data) => {
        if (data?.success) {
          setEditMode(false);
          setSelectedSandbox(null);
          queryClient.invalidateQueries("sandboxes");
        } else {
          alert("Error occured: " + data?.message);
        }
      },
    }
  );

  useEffect(() => {
    refershSandboxListPushMutation.mutate();
  }, [lectureid]);

  const handleEditSandbox = (e, sandbox) => {
    e.stopPropagation();
    setEditMode(true);
    setSelectedSandbox(sandbox);
    setPlaygroundId(sandbox?.playgroundid?._id);
    setShowSandboxList(false);
    setProjectFiles(formatProjectFiles(sandbox));
  };

  const handleDeleteSandbox = (e, sandbox) => {
    e.stopPropagation();
    alert("DELETE SANDBOX");
    deleteCodePushMutation.mutate(sandbox?.playgroundid?._id);
  };

  if (isError)
    return (
      <div className="admin-sandbox-sidebar admin-videos-list">
        <Error message={JSON.stringify(error)} />
      </div>
    );

  const sandboxes = sandboxResponse?.data?.playgrounds || null;
  return (
    <div className="admin-sandbox-sidebar">
      <div className="d-flex align-items-center justify-content-center mb-2">
        <button
          onClick={() => {
            setFromAddNewBtn(true);
            loadModal();
          }}
          className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm"
        >
          + Add new
        </button>
      </div>

      {isLoading && (
        <div className="admin-sandbox-sidebar admin-videos-list">
          <Loader />
        </div>
      )}

      {sandboxes?.length > 0 ? (
        <ListGroup style={{ borderRadius: 0 }}>
          {sandboxes?.map((sandbox, index) => (
            <ListGroup.Item
              key={index}
              action
              onClick={() => handleUpdatingProjectFiles(sandbox)}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <small>
                    {sandbox?.pause && "⏯️"} {sandbox?.timestamp} seconds
                  </small>
                </div>
                <div className="d-flex">
                  <span
                    className="admin-ide-btn m-2"
                    onClick={(e) => handleEditSandbox(e, sandbox)}
                  >
                    <BsFillPencilFill />
                  </span>
                  <span
                    className="p-2 admin-ide-btn"
                    onClick={(e) => handleDeleteSandbox(e, sandbox)}
                  >
                    <BsFillTrashFill />
                  </span>
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <div>
          <p>No sandboxes found</p>{" "}
        </div>
      )}
    </div>
  );
}

export default AdminSandboxList;
