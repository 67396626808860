import { useState } from "react";
import classes from "./MyProfile.module.css";
import certificatesImg from "assets/img/myProfile/certificates.svg";

const Transcript = ({ learningPathList }) => {
  const [learningPathViewCount, setLearningPathViewCount] = useState(3);
  const handleSetView = () => {
    console.log("handlesetview==");
    if (learningPathViewCount === 3) {
      setLearningPathViewCount(Object.values(learningPathList).length);
    } else {
      setLearningPathViewCount(3);
    }
  };
  return (
    <div>
      {learningPathList.length === 0 && (
        <div className={classes.box}>
          <div style={{ display: "flex" }}>
            <img
              src={certificatesImg}
              alt="certificates"
              style={{ marginRight: 20 }}
            />

            <div style={{ display: "flex", alignItems: "center" }}>
              <span className={classes.contentHeading}>
                You don't have any LearningPath yet
              </span>
            </div>
          </div>
        </div>
      )}
      {learningPathList &&
        Object.values(learningPathList)
          .slice(0, learningPathViewCount)
          .map((learningpath) => {
            console.log({ learningpath });
            return (
              <div key={learningpath._id} className={classes.box}>
                <div style={{ display: "flex" }}>
                  <img
                    src={certificatesImg}
                    alt="certificates"
                    style={{ marginRight: 20 }}
                  />

                  <div>
                    <p className={classes.contentTitle}>Transcript</p>
                    <div className={classes.iconsandTitle}>
                      <span className={classes.contentHeading}>
                        {learningpath.learningpathid?.learningpathname}
                      </span>
                    </div>
                    <div
                      className={`${classes.progressBar} ${classes.mobileViewProgressBar}`}
                    >
                      <a
                        href={`https://zaio-companies.netlify.app/student/learningpath/${learningpath.learningpathid?._id}?user_id=${learningpath?.userid}`}
                        target="_blank"
                        style={{ marginBottom: 0 }}
                      >
                        <span
                          className={classes.underlineP}
                          style={{ color: "#fff" }}
                        >
                          View Transcript
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={classes.p}>
                  <div
                    className={classes.progressBar}
                    style={{ minWidth: "unset" }}
                  >
                    <a
                      href={`https://zaio-companies.netlify.app/student/learningpath/${learningpath.learningpathid?._id}?user_id=${learningpath?.userid}`}
                      target="_blank"
                      style={{ marginBottom: 0 }}
                    >
                      <span
                        className={classes.underlineP}
                        style={{ color: "#fff" }}
                      >
                        View Transcript
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      <p
        className={`${classes.viewAllText} ${classes.underlineP}`}
        onClick={handleSetView}
        style={{
          visibility: `${Object.values(learningPathList).length <= 3 ? "hidden" : ""} `,
        }}
      >
        {learningPathViewCount > 3 ? "collapse" : "View all"}
      </p>
    </div>
  );
};

export default Transcript;
