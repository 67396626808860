import React from "react";

function AcceptInvite({ handleOnHide, acceptInvite, invite }) {
  console.log("acceptInvite, invite", invite);
  return (
    <button
      className=" mt-4 btn btn-block btn-main"
      onClick={() => {
        handleOnHide();
        acceptInvite(invite._id).then((res) => alert(res.message));
        console.log("INVITEID", invite)
        window.location.reload();
        // alert("Your friends have received emails.");
        // handleCreateTeam();
        // history.push("/getstarted");
      }}
    >
      Accept Invite from {invite.from}
    </button>
  );
}

export default AcceptInvite;
