import { Circle } from "rc-progress";

import "./TeamScore.css";
import Members from "./Members";
import { useEffect, useState } from "react";
import streakService from "actions/services/streak.service";
import TeamStreakScore from "./TeamStreakScore";

function TeamScore({
  hasTeam,
  handleInviteMembers,
  sentInvites,
  receivedInvites,
  handleAcceptInvite,
  team,
  teamStreak,
}) {
  // const [teamStreak, setTeamStreak] = useState(null);
  useEffect(() => {
    async function fetchStreakData() {
      console.log("DOING A FETCH FOR", "streakData");
      // const response = await streakService.getTeamStreakData(team._id);
      // console.log("RESPONSE", response);
      // const streakData = await response.data.streakNumber;
      // console.log("IN FETCH STREAK DATA", response, response.data);
      // setTeamStreak(streakData);
      // setTeamStreak(4);
      // then(async (response) => {
      //   console.log("TEAM", teamStreak, response.data, response.data.streakNumber);
      //   await setTeamStreak(response.data.streakNumber);
      // });
    }

    fetchStreakData();
  }, [teamStreak, team]);

  return (
    <div className="teamscore-card">
      <p>
        {hasTeam ? (
          <strong>Team: {team.name}</strong>
        ) : (
          <strong>No team members</strong>
        )}
      </p>
      <TeamStreakScore />

      <div>
        {!hasTeam && (
          <div style={{ textAlign: "center" }}>
            {/* <p>
              Having a gym buddy increases your chances of success. Invite a
              "code" buddy.
            </p> */}
          </div>
        )}
        {receivedInvites.map((invite, index) => {
          return (
            <Members
              key={index}
              pending
              receivedInvite={invite}
              handleAcceptInvite={() => handleAcceptInvite(invite)}
            />
          );
        })}
        {sentInvites.map((invite, index) => {
          return <Members key={index} pending sentInvite={invite} />;
        })}

        {hasTeam &&
          team.members.map((member, index) => (
            <Members key={index} member={member} />
          ))}
        {hasTeam && team.members.length >= 3 ? (
          <p style={{ textAlign: "center", marginTop: "1rem" }}>
            You are all set. Have a tutor support session to make the most of
            it.
          </p>
        ) : (
          <button
            className=" mt-4 btn btn-block btn-main"
            onClick={handleInviteMembers}
          >
            Invite co-learners (friends) - IT's FREE"
          </button>
        )}
      </div>
    </div>
  );
}

export default TeamScore;

// <div className="teamscore-container">
//         {/* <div className="circle teamscore-circle">
//           <Circle
//             strokeWidth={4}
//             trailWidth={4}
//             percent={50}
//             strokeColor="#8556f8"
//           />
//           <div className="score">55XP</div>
//         </div> */}
//         <div className="circle teamscore-circle">
//           <Circle
//             strokeWidth={4}
//             trailWidth={4}
//             percent={100}
//             strokeColor="#8556f8"
//           />

//           {
//             <div className="score">
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   alignContent: "center",
//                 }}
//               >
//                 <span role="img" aria-label="emoji">
//                   {teamStreak} 🔥
//                   {/* {team._id ? teamStreak : 0} 🔥 */}
//                 </span>
//                 <span style={{ fontSize: "8px" }}>Team</span>
//               </div>
//             </div>
//           }
//         </div>
//       </div>
