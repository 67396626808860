import axios from "axios";
import authHeader from "./auth-header";

// const API_URL = 'https://zaio-dev.herokuapp.com'
const API_URL = process.env.REACT_APP_BACKEND_URL;
//const API_URL = 'http://localhost:4000'

const demographic = {
    postDemographic(data) {
        return axios
            .post(API_URL + "/demograaphic", data,{
                headers: authHeader(),
            })
            .then((res) => {
                //   console.log(res.data);
                return res.data;
            })
            .catch((rej) => {
                // console.log(rej.data);
                return rej;
            });
    },

    getDemographic(email) {
        return axios
            .get(API_URL + "/demograaphic?email=" + email,{headers:authHeader()})
            .then((res) => {
                  console.log(res.data);
                return res.data;
            })
            .catch((rej) => {
                // console.log(rej.data);
                return rej;
            });
    },

};

export default demographic;
