import React from "react";
import { Mixpanel } from "Utils/Mixpanel";
import "./QuizOptions.css";

function QuizOptions({
  quizOptions,
  handleQuizOptionSelect,
  tryAgainQPStringTime,
  jumptoContinueStringTime,
  jumpToTryAgainStringTime,
}) {
  // console.log("quizOptions", quizOptions);
  return (
    <div className="quizContainer">
      {quizOptions.map((quizOption, index) => (
        <button
          key={index}
          className="quizOption"
          // className={`quizOption ${
          //   quizOptions.isCorrectAnswer ? "yellow" : "purple"
          // }`}
          onClick={() => {
            Mixpanel.track("Onboarding Option Selected", {
              option: quizOption.text,
            });

            handleQuizOptionSelect(
              quizOption
              // quizOption.text,
              // quizOption.nexttimestamp,
              // quizOption.correctanswer,
              // tryAgainQPStringTime,
              // jumpToTryAgainStringTime,
              // jumptoContinueStringTime
            );
          }}
        >
          {quizOption.text}
        </button>
      ))}
    </div>
  );
}

export default QuizOptions;
