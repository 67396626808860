import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import classes from "./Navbar.module.css";
import "./Navbar.css";

import {
  Button,
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Offcanvas,
} from "react-bootstrap";

import notification from "assets/img/dashboard/notification.svg";
import GroupAngular from "assets/img/dashboard/GroupAngular.svg";
import setting from "assets/img/dashboard/setting.svg";
import logoutCircle from "assets/img/dashboard/logoutCircle.svg";
import logoutArrow from "assets/img/dashboard/logoutArrow.svg";
import minutIcon from "assets/img/dashboard/minutIcon.svg";
import { UserContext } from "context/UserProvider";
import { LoadingContext } from "context/LoadingProvider";

import { BsPerson } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import Stats from "components/QuizReelComponents/Header/Stats";
import WalletBalance from "pages/Wallet/WalletBalance";
import TeamStreak from "components/TeamStreak/TeamStreak";
import SoloStreak from "components/SoloStreak/SoloStreak";
import TeamStreakScore from "pages/TeamLearning/TeamStreakScore";
import SoloStreakScore from "component/myProfile/SoloStreakScore";
import bootcamp from "actions/services/bootcamp.service";
import CustomModal from "components/Modals/CustomModal";
import { AllEnrolledBootcamps } from "component/TasksCalendar/AllEnrolledBootcamps";
import demographic from "../../actions/services/demographic.service";
import AuthService from "actions/services/auth.service";
import Banner from "components/Banner";
import { setSytemUserAttributes } from "Utils/WebEngage";

const DashboardNavbar = ({ minutesRemaining }) => {
  const {
    user,
    isProfileCompleted,
    setIsProfileCompleted,
    userProfile,
    setUserProfile,
  } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);
  const [pathName, setPathName] = useState("");
  // const [subPath, setSubPath] = useState();
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
    title: "Enrolled Bootcamps",
  });

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const loadModal = async () => {
    setModal({
      ...modal,
      show: true,
      CustomComponent: AllEnrolledBootcamps,
      props: {
        user,
      },
    });
    // setModal({
    //   ...modal,
    //   show: true,
    //   CustomComponent: BootcampModal,
    //   props: {
    //     user,
    //   },
    // });
  };

  useEffect(() => {
    // console.log(window.location.pathname.includes("job-details"), "TESTIN");
    if (window.location.pathname === "/app/updated-dashboard") {
      setPathName("Dashboard");
    }
    if (window.location.pathname === "/app/tutor-support") {
      setPathName("Tutor Support");
    }
    if (window.location.pathname === "/app/hall-of-fame") {
      setPathName("Hall Of Fame");
    }
    if (window.location.pathname === "/app/profile") {
      setPathName("Profile");
    }
    if (window.location.pathname === "/app/settings") {
      setPathName("Settings");
    }
    if (window.location.pathname === "/app/notes") {
      setPathName("Notes");
    }
    if (window.location.pathname === "/app/team") {
      setPathName("Team Learning");
    }
    if (window.location.pathname === "/app/jobs") {
      setPathName("Jobs");
    }
    if (window.location.pathname.includes("/app/jobs/job-details")) {
      setPathName("Job Details");
    }
  }, []);

  useEffect(() => {
    AuthService.findUserProfile()
      .then((response) => {
        if (response.success) {
          console.log(response.data, "kislay-data");
          setUserProfile({ ...response, ...response.data });
        }
        const fetchSavedDemographic = async () => {
          const res = await demographic.getDemographic();
          const temp = res.data[0];
          const data = {
            citizen: temp?.sa_citizen,
            gender: temp?.gender,
            phoneNo: temp?.phone_number,
            school: temp?.highest_school_grade,
            universityName: temp?.university_name,
            courseName: temp?.university_degree,
            homeLanguage: temp?.home_language,
            secondLanguage: temp?.second_language,
            race: temp?.race,
            salary: temp?.monthly_salary,
            disability: temp?.disability,
          };
          checkProfileCompletion(data, response.data);
        };
        fetchSavedDemographic();
      })
      .catch((reject) => alert("somethingwentwrong"));
  }, []);

  const checkProfileCompletion = (demographicData, usrProfile) => {
    console.log({ demographicData, usrProfile });
    setSytemUserAttributes({ ...demographicData, ...usrProfile });
    for (let key in demographicData) {
      if (!demographicData[key]) {
        console.log("key---", key, demographicData);
        setIsProfileCompleted(false);
        return;
      }
    }
    for (let key in usrProfile) {
      if (key !== "image" && key !== "lastActive" && !usrProfile[key]) {
        console.log("key---", key, usrProfile);
        setIsProfileCompleted(false);
        return;
      }
    }
    setIsProfileCompleted(true);
  };

  const expand = "lg";

  return (
    <Navbar
      key={expand}
      bg="transparent"
      expand={expand}
      className={`mb-3 ${classes.navbar}`}
    >
      {isProfileCompleted === false && <Banner />}
      {modal.show && (
        <CustomModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          props={modal.props}
          CustomComponent={modal.CustomComponent}
        />
      )}
      <Container fluid>
        <Navbar.Brand href="#home" className={classes.navbarBrand}>
          <WalletBalance simple toolTipPlacement="bottom" />
          {/* <TeamStreakScore simple toolTipPlacement="bottom" />  */}
          <SoloStreakScore simple toolTipPlacement="bottom" />
          {/* <Stats
            streaksOnly
            teamStreaks
            customStyles={{ margin: "0", padding: "0" }}
          />
          <Stats streaksOnly customStyles={{ margin: "0", padding: "0" }} /> */}
          {/* <span style={{ color: "#909090" }}>Pages</span> /{" "} */}
          {/* <span>{pathName}</span> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="top"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title
              id={`offcanvasNavbarLabel-expand-${expand}`}
            ></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav
              className={`justify-content-end flex-grow-1 pe-3 ${classes.navItemContainer}`}
            >
              {/* <Nav.Link onClick={() => history.push("/tutor-support")}>
                <img alt="minut" src={minutIcon} style={{ marginRight: 10 }} />
                <span className={classes.headingTwoDark}>
                  {minutesRemaining} tutor minutes left
                </span>
              </Nav.Link> */}
              <div className={classes.navItemSubContainer}>
                {/* <Nav.Link href="#link" className={classes.notificationLink}>
                  <img src={notification} />
                </Nav.Link> */}
                {/* 
                <Nav.Link>
                  <button
                    className="bg-green-400 px-3 py-1 rounded-md text-dark"
                    onClick={loadModal}
                  >
                    My Schedule
                  </button>
                </Nav.Link> */}

                {user?.data?.isbootcampenrolled || user?.isbootcampenrolled ? (
                  // <Nav.Link onClick={() => history.push("/bootcamp-mode")}>
                  <Nav.Link>
                    <button
                      className="bg-green-400 px-3 py-1 rounded-md text-dark"
                      onClick={loadModal}
                    >
                      My Schedule
                    </button>
                  </Nav.Link>
                ) : null}

                {/* <Nav.Link onClick={() => history.push("/choose-path")}> */}
                {user && !user.ispro ? (
                  <Nav.Link
                    onClick={() => history.push("/onboarding/payments")}
                  >
                    <button className={classes.upgradeBtn}>Upgrade</button>
                  </Nav.Link>
                ) : null}
                {user && !user.ispro ? (
                  <a
                    // href={`https://wa.me/+27632770232/?text=Hi consultant at Zaio, my name is ${user.username}, and my email is ${user.email}. I wanted to connect with you to get some genuine and expert advise to guide me with decision making. Thanks.`}
                    href={`https://calendly.com/d/cpdj-3h5-2tn/zaio-fullstack-web-development-consultation`}
                    target="_blank"
                    // style={{
                    //   marginTop: "10px",
                    //   padding: "2px",
                    //   color: "yellow"
                    // }}
                    className={classes.bookConsultation}
                  >
                    Book consultation
                  </a>
                ) : null}

                {/* <a
                  href="https://t.me/zaiodevcommunity"
                  target="_blank"
                  style={{
                    backgroundColor: '#0088cc',
                    color: '#fff',
                  }}
                  className={classes.bookConsultation}
                >
                  <p style={{ padding: '0px 5px', margin: 0 }}>
                    <FaTelegramPlane style={{ fontSize: 17, transform: 'translateY(-1px)' }} />{' '}
                    Community
                  </p>
                </a> */}
                {/* <div style={{ height: "50%"}}>
                  <Stats />
                </div> */}
                <Nav.Link
                  onMouseEnter={() => setShowDropdown(true)}
                  className={classes.groupIconBox}
                >
                  <img src={GroupAngular} className={classes.iconCenter} />
                </Nav.Link>
              </div>
              <div onMouseEnter={() => setShowDropdown(true)}>
                <NavDropdown
                  title={
                    <span style={{ color: "#909090" }}>{user.username}</span>
                  }
                  id="nav-dropdown"
                  className={classes.dropdown}
                  show={showDropdown}
                >
                  {showDropdown && (
                    <div onMouseLeave={() => setShowDropdown(false)}>
                      <NavDropdown.Item
                        onClick={() => history.push("/profile")}
                        className={classes.dropdownItem}
                      >
                        <BsPerson
                          style={{
                            fontSize: 24,
                            transform: "translateX(-1px)",
                          }}
                        />
                        <span style={{ marginLeft: 7 }}>My profile</span>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => history.push("/settings")}
                        className={classes.dropdownItem}
                      >
                        <img src={setting} />
                        <span style={{ marginLeft: 10 }}>Settings</span>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        href="/logout"
                        className={classes.dropdownItem}
                      >
                        <span style={{ position: "relative", width: 20 }}>
                          <img src={logoutCircle} alt="logout" />
                          <img
                            src={logoutArrow}
                            alt="logout"
                            className={classes.iconCenter}
                            style={{ top: "59%" }}
                          />
                        </span>
                        <span style={{ marginLeft: 10 }}>Log out</span>
                      </NavDropdown.Item>
                    </div>
                  )}
                </NavDropdown>
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default DashboardNavbar;
