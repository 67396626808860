import "./live.scss";
import { FaCircle } from "react-icons/fa";

function Live() {
  return (
    <div className="live-indicator-block">
      <span className="live-indicator">
        <FaCircle className="blink" />
        <span>Live</span>
      </span>
    </div>
  );
}

export default Live;
