import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACKEND_URL;

class TutorService {
  getUserAccountDetails() {
    return axios
      .get(API_URL + "/usertutoraccount/usertutoraccount", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log("then", res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }


  getUserSupportSession() {
    return axios
      .get(API_URL + "/supportsession/usersessions", {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then", res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  userSupportSessionForAdmin(id) {

    return axios
      .get(API_URL + `/supportsession/usersessions/${id}`)
      .then((res) => {
        // console.log("then", res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  async getTutorForUser(userid){
    try {
      const res = await axios.get(API_URL + `/supportsession/tutor-details/${userid}`)
      return res.data
    } catch (error) {
      console.log(error)
      return null
    }
  }

}
export default new TutorService();
