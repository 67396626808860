import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACKEND_URL;

class WalletService {
  getWalletBalance() {
    return axios
      .get(API_URL + "/wallet/userwallet", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return rej.response;
      });
  }
  getInviteLink() {
    return axios
      .get(API_URL + "/invite/getinvitelink", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return rej.response;
      });
  }
  inviteFriend(recipientemail) {
    return axios
      .post(
        API_URL + "/invite/sendinvite",
        { recipientemail },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log("then", res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return rej.response;
      });
  }
  purchaseCourse(courseid, price) {
    console.log("purchaseCourse", courseid, price);
    return axios
      .post(
        API_URL + "/course/purchasecourse",
        { courseid, price },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        // console.log("then", res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log("catch", rej.response);
        return rej.response;
      });
  }

  unlockHint(teamid, competitionid, hintid, price) {
    return axios
      .post(
        API_URL + "/competition/purchasehint",
        { teamid, competitionid, hintid, price },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  joinCompetition(teamid, competitionid) {
    return axios
      .post(
        API_URL + "/competition/enrolincompetition",
        { teamid, competitionid },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }
}

export default new WalletService();
