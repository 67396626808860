import authService from "actions/services/auth.service";
import { UserContext } from "context/UserProvider";
import { useContext } from "react";
import { Mixpanel } from "Utils/Mixpanel";

export const Logout = () => {
  const { setUser } = useContext(UserContext);
  authService.logout();  
  window.webengage?.user?.logout();
  Mixpanel.track("Logout")
  Mixpanel.reset()
  setUser(null);
  window.location.href = window.location.origin + "/";
};
