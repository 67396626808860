import styled, { css, keyframes } from "styled-components";

export const ZCoinContainer = styled.div`
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 5px;
  background: linear-gradient(#0d1e3a, var(--purple));
  background: var(--purple);
  margin: 0 0.5rem;
  cursor: pointer;

  .z-coin-amount {
    display: flex;
    align-items: center;
  }

  img {
    width: 35px !important;
    height: 35px !important;
  }

  span {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 400;
    margin-right: 5px;
    color: white;
  }
`;
