import React from 'react';

export default function Text({ children, variant, size }) {
  const textStyle = [
    'textDark',
    'mainHeading',
    'textWhite',
    'textGrey',
    'mainHeading_dark',
    'sub_text_dark',
    'text_red',
    'sub_purple',
    'eng_para',
    'eng_text',
    'sub_heading',
  ];
  const textWidth = ['default', 'textSmall', 'textMedium', 'textLarge', 'xxxl'];

  const checkTextStyle = textStyle.includes(variant) ? variant : textStyle[0];
  const checkTextWidth = textWidth.includes(size) ? size : textWidth[0];

  return (
    <>
      <div className={`${variant} ${size}`}>{children}</div>
    </>
  );
}
