import React, { useState, useEffect } from "react";
import { Link, useParams, Switch, Route, Redirect } from "react-router-dom";
import Options from "components/Wrappers/Options/Options";
import Styles from "./AdminPortal.module.css";
import WaitingList from "containers/WaitingList/WaitingList";
import PageWithOptions from "components/Wrappers/PageWithOptions/PageWithOptions";
import NewCourse from "containers/NewCourse/NewCourse";
import Results from "components/Results/Results";
import Course from "pages/Course/Course";
import Loader from "components/Loader/Loader";
import courseService from "actions/services/course.service";
import AuthService from "actions/services/auth.service";
import Users from "containers/Users";
import { useContext } from "react";
import { LoadingContext } from "context/LoadingProvider";
import SupportSession from "containers/SupportSession";
import Testimonials from "containers/Testimonials";

import { CSVLink, CSVDownload } from "react-csv";
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export const AdminPortal = () => {
  const { setLoading } = useContext(LoadingContext);

  const [auth, setAuth] = useState(true);
  const [data, setData] = useState();
  const [counter, setCounter] = useState(0);
  const [updatedData, setUpdatedData] = useState();
  const [updatedData2, setUpdatedData2] = useState();
  const section = useParams().section;

  // const newUsers = [
  //   'naselemhlambiso09@gmail.com',
  //   'thsothsoeland@gmail.com',
  //   'minyukunsuku0@gmail.com',
  //   'nkosiengel@gmail.com',
  //   'ckrtrader@gmail.com',
  //   'zinhlenomzamo76@gmail.com',
  //   'Thabomapoulo@yahoo.com',
  //   'loatemophuting@gmail.com',
  //   'tamsanqathwala62@gmail.com',
  //   'junaidalexander76@gmail.com',
  //   'zainabdavids460@gmail.com',
  //   'yeye.ngaba@gmail.com',
  // ];

  // const newUsers2 = [
  //   'ntokozomafa12@gmail.com',
  //   'mahlodisefoko@gmail.com',
  //   'krbkumalo@gmail.com',
  //   'ayandasihle26@gmail.com',
  //   'palesahlatshwayo98@gmail.com',
  //   'boitumelomarule1@gmail.com',
  //   'Nompumeleloxhorile@gmail.com',
  //   'magabaza01@gmail.com',
  //   'thatomakhubela081@gmail.com',
  //   'tebogosibiya64@gmail.com',
  //   'Mahlatsimoloto6@gmail.com',
  //   'siyasangataleni@gmail.com',
  //   'shlahla48@gmail.com',
  //   'justicemaleti@gmail.com',
  //   'boikanyomhlanga0@gmail.com',
  //   'neotaba00@gmail.com',
  //   'sikiweesandile@gmail.com',
  //   'jesuitnukeri1@gmail.com',
  //   'samungwenya99@gmail.com',
  //   'ndlovutamela02@gmail.com',
  //   'leshayclassen16@gmail.com',
  //   'odisebesho@gmail.com',
  //   'matilakutlwano654 @gmail.com',
  // ];

  const headers = [
    { label: "Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Phone number", key: "phonenumber" },
    { label: "createdAt", key: "createdAt" },
    { label: "Coupon code", key: "couponcode" },
    { label: "Current course", key: "currentCourse" },
    { label: "Current lecture", key: "currentLecture" },
    { label: "Course Completed", key: "CurrentCourseCompletedPercentage" },
    { label: "Journey Percentage", key: "allCourseCompletedPercentage" },
    { label: "Certificates", key: "certificates" },
  ];

  const allUserCourseDetailsHandler = () => {
    setLoading(true);
    AuthService.getAllUserCourseDetails()
      .then((response) => {
        setData(response.data);
      })
      .catch((reject) => alert("somethingwentwrong"))
      .then(() => setLoading(false));
  };

  // const addcoupon = () => {
  //   newUsers.forEach((email) => {
  //     AuthService.addCoupon(email, 'LOGICALIS');
  //   });
  //   newUsers2.forEach((email) => {
  //     AuthService.addCoupon(email, 'MBSA');
  //   });
  // };

  useEffect(() => {
    allUserCourseDetailsHandler();
    // addcoupon();
  }, []);

  useEffect(() => {
    if (data)
      data.map(async (item, index) => {
        let certificates = "";
        if (item.certificates.length)
          item.certificates.forEach((certificate, index) => {
            certificates += index + "." + certificate.coursename + ", ";
          });
        item.certificates = certificates;
        item.currentCourse = item.continuelearning.courseid.coursename;
        item.currentLecture = item.continuelearning.lectureid.lecturename;

        const options = { year: "numeric", month: "long", day: "numeric" };
        let createdAt = item.joined;
        if (item.joined)
          createdAt = new Date(createdAt).toLocaleDateString("en-US", options);
        item.createdAt = createdAt;

        delete item["continuelearning"];
        return item;
      });
    setUpdatedData(data);
  }, [data]);

  console.log("getAllUserCourseDetails", updatedData);

  if (auth) {
    return (
      <PageWithOptions>
        <div style={{ height: "100%" }}>
          <Options triggerName="Menu" lgSidebar>
            {/* <Link to="/admin/waitinglist" style={{ padding: '0px' }}>
              <p className={`${Styles.adminItems}`}>Registered in WaitingList</p>
            </Link> */}
            <Link to="/admin/users" style={{ padding: "0px" }}>
              <p className={`${Styles.adminItems}`}>All Users</p>
            </Link>
            <Link to="/admin/paid-users" style={{ padding: "0px" }}>
              <p className={`${Styles.adminItems}`}>Paid Users</p>
            </Link>
            {/* <Link to="/admin/businessdemo" style={{ padding: '0px' }}>
              <p className={`${Styles.adminItems}`}>Business Form Responses</p>
            </Link> */}
            <Link to="/admin/newcourse" style={{ padding: "0px" }}>
              <p className={`${Styles.adminItems}`}>New Course</p>
            </Link>
            <Link to="/admin/mycourses" style={{ padding: "0px" }}>
              <p className={`${Styles.adminItems}`}>My Course</p>
            </Link>
            <Link to="/admin/support/session" style={{ padding: "0px" }}>
              <p className={`${Styles.adminItems}`}>Session</p>
            </Link>
            <Link to="/admin/testimonials" style={{ padding: "0px" }}>
              <p className={`${Styles.adminItems}`}>Testimonials</p>
            </Link>
            {updatedData ? (
              <CSVLink
                data={updatedData ? updatedData : []}
                filename={"user-details.csv"}
                headers={headers}
                asyncOnClick={true}
                style={{ padding: "0px" }}
              >
                <p className={`${Styles.adminItems}`}>Download csv</p>
              </CSVLink>
            ) : (
              <Link to="#" style={{ padding: "0px" }}>
                <p className={`${Styles.adminItems}`}>CSV Loading...</p>
              </Link>
            )}
            {/* <Link to="#" style={{ padding: "0px" }}> */}
            <a
              className="m-0 p-0"
              onClick={() =>
                window.open(
                  `${FRONTEND_URL}/app/admin-mcq/636974f23d2395002291c404/6369776d3d2395002291c58c/636977843d2395002291c59e`,
                  "_blank"
                )
              }
            >
              <p className={`${Styles.adminItems}`}>Create Quiz btwn lecs</p>
            </a>
            <a
              className="m-0 p-0"
              onClick={() =>
                window.open(
                  `${FRONTEND_URL}/app/admin-code-challenge/636974f23d2395002291c404/6369776d3d2395002291c58c/636977843d2395002291c59e`,
                  "_blank"
                )
              }
            >
              <p className={`${Styles.adminItems}`}>
                Create Coding Challenge btwn lecs
              </p>
            </a>
            <a
              className="m-0 p-0"
              onClick={() =>
                window.open(
                  `${FRONTEND_URL}/app/admin-create-assignment`,
                  "_blank"
                )
              }
            >
              <p className={`${Styles.adminItems}`}>Create Assignment</p>
            </a>
            {/* </Link> */}
          </Options>
        </div>
        <Switch>
          <Route exact path="/admin/waitinglist">
            <WaitingList props_width="75" setLoading={setLoading} />
          </Route>
          <Route exact path="/admin/users">
            <Users />
          </Route>
          <Route exact path="/admin/paid-users">
            <Users paid />
          </Route>
          <Route exact path="/admin/newcourse">
            <NewCourse setLoading={setLoading} />
          </Route>
          <Route exact path="/admin/mycourses">
            <Results
              explore
              setLoading={setLoading}
              service={courseService.getCourseList}
              redirect="edit"
            />
          </Route>
          <Route exact path="/admin/course/:courseid">
            <div className="w-75">
              <Course setLoading={setLoading} edit />
            </div>
          </Route>
          <Route exact path="/admin/support/session">
            <div className="w-75">
              <SupportSession setLoading={setLoading} />
            </div>
          </Route>
          <Route exact path="/admin/testimonials">
            <div className="w-75">
              <Testimonials setLoading={setLoading} />
            </div>
          </Route>
          <Route exact path="/admin">
            <Redirect to="/admin/paid-users" />
          </Route>
          <Route>
            <h2 className="w-100 ml-0 ml-md-5 p-2">ERROR 404 : WRONG PATH</h2>
          </Route>
        </Switch>
      </PageWithOptions>
    );
  } else {
    return <h1>Enter Correct Id/Password</h1>;
  }
};
