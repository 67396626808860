import { useQuery } from "react-query";
import walletService from "actions/services/wallet.service";
import ZPLoading from "pages/Watch/components/ZPLoading";

import InviteContainer from "./InviteContainer";
import EarnContainer from "./EarnContainer";

import "../WalletBalance.css";
import "./InviteLink.css";

function InviteLink({
  toolTipText = "Your current balance",
  toolTipPlacement = "right",
  displayToolTip = true,
  getInviteLink = undefined,
  simple = false,
}) {
  //queries
  const {
    isLoading,
    isError,
    error,
    data: inviteLink,
  } = useQuery("inviteLink", () => walletService.getInviteLink(), {
    // onSuccess: (data) => {
    //   if (getWalletBalance) getWalletBalance(data?.data?.balance);
    // },
  });

  if (isLoading) return <ZPLoading />;
  if (isError) return <div className="text-white">{error}</div>;
  return (
    <>
      <EarnContainer />
      <InviteContainer inviteLink={inviteLink} />
    </>
  );
}

export default InviteLink;
