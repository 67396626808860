import { useState } from "react";
import ZCoinIcon from "assets/svg/zcoin.svg";
import "./WalletBalance.css";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { useQuery } from "react-query";
import walletService from "actions/services/wallet.service";
import ZPLoading from "pages/Watch/components/ZPLoading";
import ZCoin from "components/ZCoin/ZCoin";
import { setWalletScoreAttribute, walletViewedEventTracking } from "Utils/WebEngage";

function WalletBalance({
  toolTipText = "Your current balance",
  toolTipPlacement = "right",
  displayToolTip = true,
  getWalletBalance = undefined,
  simple = false,
  handleClick=undefined
}) {
  const history = useHistory();

  const target = useRef(null);
  const [showToolTip, setShowToolTip] = useState(false);

  //queries
  const {
    isLoading,
    isError,
    error,
    data: walletBalance,
  } = useQuery("walletBalance", () => walletService.getWalletBalance(), {
    onSuccess: (data) => {
      if (getWalletBalance) getWalletBalance(data?.data?.balance);
    },
  });

  const renderTooltip = (props) => <Tooltip {...props}>{toolTipText}</Tooltip>;
  const displayBalance = () => {
    if (walletBalance?.success === false) {
      return (
        <>
          <br />
          <small>
            <em className="text-center">{walletBalance?.message}</em>
          </small>
        </>
      );
    } else {
      setWalletScoreAttribute(walletBalance?.data?.balance || 0)
      walletViewedEventTracking(walletBalance?.data?.balance || 0);
      return <h2 className="text-center">{walletBalance?.data?.balance}</h2>;
    }
  };

  if (isLoading) return <ZPLoading />;
  if (isError) return <div className="text-white">{error}</div>;
  if (simple) {
    return (
      <ZCoin
        number={walletBalance?.data?.balance}
        toolTipPlacement={toolTipPlacement}
        handleClick={handleClick}
      />
    );
  }
  return (
    <>
      <div
        ref={target}
        className="wallet-ballance-container"
        // onClick={() => history.push("/wallet")}
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
      >
        <img src={ZCoinIcon} alt="LogoFooter" className="zcoinicon" />
        <div>
          <small>My Z-Coin balance</small>
          {displayBalance()}
        </div>
      </div>
      <Overlay
        target={target.current}
        show={displayToolTip && showToolTip}
        placement={toolTipPlacement}
      >
        {renderTooltip}
      </Overlay>
    </>
  );
}

export default WalletBalance;
