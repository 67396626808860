import { UserContext } from "context/UserProvider";
import { useContext } from "react";
import { Modal, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Mixpanel } from "Utils/Mixpanel";

import "./ReelModal.css";
function ReelModal({
  title,
  description,
  show,
  handleOnHide,
  CustomComponent,
  props,
}) {
  // console.log("PROPS", props);
  const history = useHistory();
  const { user } = useContext(UserContext);

  return (
    <Modal
      show={show}
      size="md"
      onHide={handleOnHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className="p-5"
        style={{ paddingTop: "0px", textAlign: "center" }}
      >
        <h2 className="mediumText mb-3 reel-modal-title">{title}</h2>
        <Form>
          <Form.Group className="">{description}</Form.Group>
        </Form>
        {CustomComponent ? (
          <CustomComponent handleOnHide={handleOnHide} {...props} />
        ) : (
          <>
            {!(user && user.success) && (
              <button
                className=" mt-4 btn btn-block btn-main btn-lg"
                onClick={(e) => {
                  Mixpanel.track("Button Click", {
                    "Button name": "Sign up",
                    "Button context": title,
                  });
                  handleOnHide();
                  history.push("/getstarted");
                }}
              >
                Signup
              </button>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ReelModal;
