import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';

const BackButton = ({ color = '', size = '' }) => {
    const history = useHistory();
    return (
        <div onClick={history.goBack} className='p-2 cursor-pointer'>
            <FaArrowLeft style={{color: `${color}`, size: `${size}`}} /> 
        </div>
    )
}

export default BackButton