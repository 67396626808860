import ChatBubble from "pages/DashBoard/ChatBubble";
import OnboardingFooter from "pages/Onboarding/OnboardingFooter";
import { useEffect } from "react";
import useVideoStore from "zustand-store/videoPlayerStore";

function QuizResult({ resultDecimal, assignment = false }) {
  return (
    <div>
      <ChatBubble
        title={"Great stuff! Quiz completed 🎉"}
        text={`You have completed the quiz with ${parseInt(
          resultDecimal * 100
        )}%. ${
          !assignment
            ? "You can now move on to the next lesson."
            : "Open the next question. Using questions tab on top left of the screen"
        }`}
        showLive={false}
        // hideImage={true}
        // backgroundColor={isSolutionCorrect ? "#D1F2EB" : "#FADBD8"}
      />
    </div>
  );
}

export default QuizResult;
