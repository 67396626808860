import { useState, useEffect, useContext, useCallback } from "react";
import classes from "./MyProfile.module.css";

import { useHistory } from "react-router-dom";

import {
  ProgressBar,
  Button,
  Card,
  CardImg,
  Modal,
  Row,
  Col,
} from "react-bootstrap";

import AuthService from "actions/services/auth.service";

import { LoadingContext } from "context/LoadingProvider";
import Questions from "./Questions";

import moment from "moment";
import toonavatar from "cartoon-avatar";
import lectureService from "actions/services/lecture.service";
import Avatar from "react-avatar";

import userImg from "assets/img/myProfile/user.svg";
import badge from "assets/img/myProfile/badge.svg";
import certificatesImg from "assets/img/myProfile/certificates.svg";
import github from "assets/img/myProfile/github.svg";
import linkedin from "assets/img/myProfile/linkedin.svg";
import navigate from "assets/img/myProfile/navigate.svg";
import net from "assets/img/myProfile/net.svg";
import SoloStreakScore from "./SoloStreakScore";

import { saveAs } from "file-saver";

import learningpathService from "actions/services/learningpath.service";
import tutorService from "actions/services/tutor-service";
import courseService from "actions/services/course.service";
import calculateCompletionDate from "pages/DashBoard/completionAlgo";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
// import DashboardNavbar from '../navbar/Navbar.jsx';
import ProfileEdit from "./ProfileEdit.jsx";
import { BsFillPencilFill } from "react-icons/bs";
// import EnrolledCourse from './EnrolledCourseUserProfile.jsx';
import EnrolledCourse from "./EnrolledCourse.jsx";
import { useParams } from "react-router";
import { Mixpanel } from "Utils/Mixpanel";
import {
  ComponentContainer,
  PosterContainer,
} from "../../pages/Competitions/Competitions.styled";
import WalletBalance from "../../pages/Wallet/WalletBalance";
import ActivityChart from "./ActivityChart";
import { CalendarPage } from "component/TasksCalendar/TasksCalendar";
import { UserContext } from "context/UserProvider";
import Banner from "components/Banner";
import Transcript from "./Transcript";

function ProfileCommon({
  avatar,
  userProfile,
  handleUpdateURL,
  certificates,
  setCertificateShow,
  certificateDataHandler,
  certificateShow,
  certificateData,
  handleSetView,
  viewCount,
  learningPathList,
  setTotalCourse,
  email,
  tab,
  totalAssignment,
  totalChallenge,
  totalCourse,
  profile,
  editable,
  profileData,
  setProfileData,
  publicProfile,
}) {
  const { user, isProfileCompleted, isDemographicsCompleted } =
    useContext(UserContext);
  // const [profileData, setProfileData] = useState({
  //   bio: "",
  //   location: "",
  //   linkedIn: "",
  //   github: "",
  //   website: "",
  // });
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  // const [isProfileComplete,setIsProfileComplete] = useState(true);
  const handleCloseModal = () => setShowModal(false);
  // useEffect(() => {

  // }, []);
  const displayButton = () => {
    return (
      <div className="bg-yellow-400 mr-2 text-white px-4 rounded-full italic">
        Learning in Progress...
      </div>
    );
  };

  return (
    <ComponentContainer style={{ margin: "auto" }}>
      {editable && isProfileCompleted === false && <Banner />}
      <PosterContainer>
        <div className={classes.poster_header_section}>
          <div className="d-flex">
            {/* <div className={classes.profile_tab}>HTML</div>
              <div className={classes.profile_tab}>CSS</div>
              <div className={classes.profile_tab}>java script</div> */}
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <WalletBalance simple toolTipPlacement="bottom" />
            <SoloStreakScore simple toolTipPlacement="bottom" />
          </div>
        </div>
        <div className="row p-3">
          <div className="col-md-2">
            <div className={classes.profile_pic}>
              <img
                src={userProfile?.image?.url || avatar}
                className={classes.img_avtar}
                alt="test"
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className={classes.profile_detail}>
              <h1 style={{ fontSize: "30px" }}>{userProfile?.username}</h1>
              <p>{userProfile?.bio}</p>
              <p>{userProfile?.location}</p>
              <div className="d-flex mt-3 items-center">
                <a href={userProfile?.linkedIn} target="_black">
                  <img
                    src={linkedin}
                    alt="navigate"
                    className={classes.social_icons}
                  />
                </a>
                <a href={userProfile?.github} target="_black">
                  <img
                    src={github}
                    alt="navigate"
                    className={classes.social_icons}
                  />
                </a>
                <a href={userProfile?.website} target="_black">
                  <img
                    src={net}
                    alt="navigate"
                    className={classes.social_icons}
                  />
                </a>
                <div className="ml-2 cursor-pointer">
                  {editable && (
                    <BsFillPencilFill
                      style={isProfileCompleted === false && { color: "red" }}
                      onClick={() => setShowModal(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            {editable && (
              <ProfileEdit
                show={showModal}
                image={image}
                setImage={setImage}
                handleClose={handleCloseModal}
                state={profileData}
                setState={setProfileData}
              />
            )}
          </div>
          <div className="col-md-2">
            {/* <div>
                <div className={classes.profile_tab_right}>
                  {totalChallenge ? totalChallenge : 0} Challenges
                </div>
                <div className={classes.profile_tab_right}>
                  {totalAssignment ? totalAssignment : 0} Assignments
                </div>
                <div className={classes.profile_tab_right}>
                  {totalCourse.length ? totalCourse.length : 0} Courses
                </div>
              </div> */}
          </div>
        </div>
      </PosterContainer>
      <Tab.Container defaultActiveKey={tab || "overview"}>
        <Nav variant="pills" className="main-nav">
          <div className="nav-left">
            <Nav.Item onClick={() => handleUpdateURL("Certificates")}>
              <Nav.Link eventKey="Certificates">Certificates</Nav.Link>
            </Nav.Item>
            {user && (
              <Nav.Item onClick={() => handleUpdateURL("Calender")}>
                <Nav.Link eventKey="Calender">Calendar</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item onClick={() => handleUpdateURL("Progress")}>
              <Nav.Link eventKey="Progress">Progress</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => handleUpdateURL("Demographics")}>
              <Nav.Link eventKey="Demographics">
                Demographics
                {editable && isDemographicsCompleted === false && (
                  <span style={{ color: "red", fontWeight: 800 }}> !</span>
                )}{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => handleUpdateURL("Transcript")}>
              <Nav.Link eventKey="Transcript">
                Transcripts
              </Nav.Link>
            </Nav.Item>
          </div>
          <div className="nav-right">{displayButton()}</div>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="Certificates">
            <div className="tab-content-header">
              <span>Certificates</span>
            </div>
            <div className="tab-content-body" style={{ padding: "20px" }}>
              {certificates.length === 0 && (
                <div className={classes.box}>
                  <div style={{ display: "flex" }}>
                    <img
                      src={certificatesImg}
                      alt="certificates"
                      style={{ marginRight: 20 }}
                    />

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className={classes.contentHeading}>
                        You don't have any certificate yet
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {certificates &&
                Object.values(certificates)
                  .slice(0, viewCount)
                  .map((certificate) => (
                    <div key={certificate._id} className={classes.box}>
                      <div style={{ display: "flex" }}>
                        <img
                          src={certificatesImg}
                          alt="certificates"
                          style={{ marginRight: 20 }}
                        />

                        <div>
                          <p className={classes.contentTitle}>Course</p>
                          <div className={classes.iconsandTitle}>
                            <span className={classes.contentHeading}>
                              {certificate.coursename}
                            </span>
                          </div>
                          <div
                            className={`${classes.progressBar} ${classes.mobileViewProgressBar}`}
                          >
                            <p style={{ marginBottom: 0 }}>
                              <span
                                className={classes.underlineP}
                                style={{ color: "#fff" }}
                                onClick={() => {
                                  setCertificateShow(true);
                                  certificateDataHandler(
                                    certificate.coursename,
                                    certificate.certificateurl
                                  );
                                }}
                              >
                                Certificate
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={classes.p}>
                        <div
                          className={classes.progressBar}
                          style={{ minWidth: "unset" }}
                        >
                          <p style={{ marginBottom: 0 }}>
                            <span
                              className={classes.underlineP}
                              style={{ color: "#fff" }}
                              onClick={() => {
                                setCertificateShow(true);
                                certificateDataHandler(
                                  certificate.coursename,
                                  certificate.certificateurl
                                );
                              }}
                            >
                              Certificate
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              <Modal
                centered
                show={certificateShow}
                onHide={() => setCertificateShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{certificateData.coursename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <img
                    src={certificateData.certificateurl}
                    alt="certificate"
                    style={{ width: "100%" }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    style={{ backgroundColor: "grey" }}
                    onClick={() => {
                      saveAs(certificateData.certificateurl, "image.jpg");
                      setCertificateShow(false);
                    }}
                  >
                    Download
                  </Button>
                </Modal.Footer>
              </Modal>
              <p
                className={`${classes.viewAllText} ${classes.underlineP}`}
                onClick={handleSetView}
                style={{
                  visibility: `${certificates.length <= 3 ? "hidden" : ""} `,
                }}
              >
                {viewCount > 3 ? "collapse" : "View all"}
              </p>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Calender">
            <div className="tab-content-header">
              <span>Calendar</span>
            </div>
            <div className="tab-content-body">
              {user && (
                <CalendarPage
                  onlyCalendar
                  email={email}
                  fromProfile={true}
                  publicProfile={publicProfile}
                />
              )}
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="Progress">
            <div className="tab-content-header">
              <span>Progress</span>
            </div>
            <div className="tab-content-body" style={{ padding: "20px" }}>
              {learningPathList && (
                <EnrolledCourse
                  learningPathList={learningPathList}
                  setTotalCourse={setTotalCourse}
                  email={email}
                />
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Demographics">
            {" "}
            <div className="tab-content-header">
              <span>Demographics</span>
            </div>
            <div className="tab-content-body">
              <div className="question-form border-4 rounded border-primary bg-white shdow mb-5">
                <Questions profile={profile} />
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Transcript">
            {" "}
            <div className="tab-content-header">
              <span>Transcripts</span>
            </div>
            <div className="tab-content-body" style={{ padding: "20px" }}>
                <Transcript
                  learningPathList={learningPathList}
                />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </ComponentContainer>
  );
}

export default ProfileCommon;
