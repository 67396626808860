import {
  ItemPlaybackRate,
  IconPlayBackRate,
} from "../VideoPlayerControls.styled";
import { FiCheck } from "react-icons/fi";
import { memo, useMemo } from "react";

const SpeedControl = ({
  playbackRateEnable,
  showPlaybackRate,
  playbackRate,
  setShowPlaybackRate,
  playbackRateOptions,
  onChangePlayBackRate,
}) => {
  console.log("SPEED CONTROL RENDER");
  return (
    !!playbackRateEnable && (
      <div
        className="item-control"
        onMouseLeave={() => setShowPlaybackRate(false)}
      >
        {showPlaybackRate === true && (
          <ItemPlaybackRate>
            <div>
              <div className="title">Speeds</div>
              {playbackRateOptions.map((item, index) => (
                <div
                  className="item"
                  onClick={() => onChangePlayBackRate(item)}
                  key={index}
                >
                  {(+item === +playbackRate ||
                    (item === "Normal" && +playbackRate === 1)) && <FiCheck />}
                  <div className="bold">
                    {item === "Normal" ? item : `${item}x`}
                  </div>
                </div>
              ))}
            </div>
            <div className="box-connector" />
          </ItemPlaybackRate>
        )}

        <IconPlayBackRate
          className="playbackRate"
          onMouseEnter={() => setShowPlaybackRate(true)}
        >
          <span>
            {playbackRate === "Normal" ? "1" : `${playbackRate}`}
            <small>x</small>
          </span>
        </IconPlayBackRate>
      </div>
    )
  );
};

export default memo(SpeedControl, (prevProps, nextProps) => {
  // console.log("SPEED CONTROL MEMO");
  return (
    prevProps.playbackRateEnable === nextProps.playbackRateEnable &&
    prevProps.showPlaybackRate === nextProps.showPlaybackRate &&
    prevProps.playbackRate === nextProps.playbackRate
  );
});
