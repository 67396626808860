import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import ZaioVideoPlayer from "./ZaioVideoPlayer";
import courseService from "actions/services/course.service";
import lectureService from "actions/services/lecture.service";
import { LoadingContext } from "context/LoadingProvider";
import unitService from "actions/services/unit.service";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ZPLoading from "./components/ZPLoading";
import { ControlsContainer } from "./components/VideoPlayerControls/VideoPlayerControls.styled";
import { UserContext } from "context/UserProvider";
import { Mixpanel } from "Utils/Mixpanel";
import useVideoStore from "zustand-store/videoPlayerStore";
import { BsArrowDownUp } from "react-icons/bs";

import "./watch.scss";
import useQuizStore from "zustand-store/quizStore";
import { trackLectureActivity } from "Utils/Activity";
import moment from "moment";
import Banner from "components/Banner";
import { formatProjectFiles } from "components/IDE/ideUtils";

const DEFAULT_CODESANDBOX_URL =
  "https://codesandbox.io/embed/coding-on-zaio-m2e9pw?fontsize=14&hidenavigation=1&theme=dark";

function Watch() {
  const { courseid, unitid, lectureid } = useParams();
  // console.log("courseid", courseid, "unitid", unitid, "lectureid", lectureid);
  const history = useHistory();
  const { setLoading } = useContext(LoadingContext);
  const { user, isProfileCompleted } = useContext(UserContext);

  const [unitList, setUnitList] = useState(null);
  const [lectureData, setLectureData] = useState(null);
  const [unitData, setUnitData] = useState(null);
  const [unitIndex, setUnitIndex] = useState(null);
  const [completedLectures, setCompletedLectures] = useState(null);
  const [nextLecture, setNextLecture] = useState(null);
  const [codePushUrl, setCodePushUrl] = useState(DEFAULT_CODESANDBOX_URL);

  const [codePushes, setCodePushes] = useState(null);
  const [codePlaygrounds, setCodePlaygrounds] = useState(null);
  const [projectFiles, setProjectFiles] = useState(null);

  const [isCodePushMessage, setIsCodePushMessage] = useState(false);
  const [isVideoComplete, setIsVideoComplete] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    display: false,
    message: "",
  });

  const setShowControlsContainer = useVideoStore(
    (state) => state.setShowControlsContainer
  );
  const showControlsContainer = useVideoStore(
    (state) => state.showControlsContainer
  );
  const videoMode = useVideoStore((state) => state.videoMode);
  const setVideoMode = useVideoStore((state) => state.setVideoMode);
  const setChallenge = useVideoStore((state) => state.setChallenge);
  const setQuizData = useQuizStore((state) => state.setQuizData);
  const setDSChallenge = useVideoStore((state) => state.setDSChallenge);

  const queryClient = useQueryClient();
  const {
    isLoading: isCourseLoading,
    isError: isCourseError,
    error: courseError,
    data: courseData,
    refetch,
  } = useQuery(
    "courseBeingWatched",
    () => courseService.getCourseById(courseid),
    {
      onSuccess: (data) => {
        //   console.log("ONSUCCESS About to setup data...");
        setUpVideoData(data);
        console.log("ONSUCCESS", data);
      },
    }
  );
  const videoCompleteMutation = useMutation(
    () => lectureService.postLastWatched(lectureid, courseid),
    {
      onSuccess: (res) => {
        console.log("ONSUCCCESS VIDEO COMPLETION", res);
        Mixpanel.track("Video completed", {
          type: "Netflix video",
        });

        let cp = false;
        if (res?.data?.streakupdate) {
          setAlertMessage({
            display: true,
            message: "You just won a Streak! 🔥",
            isCodePush: false,
          });
          cp = true;
          Mixpanel.track("Won Streak");
        }
        if (res?.data?.walletdata?.updated) {
          setAlertMessage({
            display: true,
            message: "You just earned 1 Z-Coin! 🤑",
            isCodePush: false,
          });

          cp = true;
        }

        if (cp) {
          setTimeout(() => {
            setIsCodePushMessage(false);
            setAlertMessage({
              display: false,
              message: "",
              isCodePush: false,
            });
          }, 4500);
        }

        return queryClient.invalidateQueries([
          "walletBalance",
          "soloStreaksData",
        ]);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [isVideoComplete]);

  useEffect(() => {
    if (
      courseData?.completedPercentage === 100 &&
      nextLecture?.courseCompleted
    ) {
      setNextLecture({
        heading: "You are done!",
        _id: "-1",
        lecturename: "Review your experience",
        duration: "Course completed",
        course: "",
        courseunit: "",
        courseCompleted: nextLecture?.courseCompleted,
      });
    }
  }, [courseData]);

  const refreshCourseData = () => {
    queryClient.invalidateQueries("courseBeingWatched");
  };

  const progressUpdateMutation = useMutation(
    () => lectureService.postStudentProgress(lectureid),
    {
      enabled: !!videoCompleteMutation?.data?.success,
      onSuccess: (res) => {
        // console.log("ONPROGRESS VIDEO UPDATE", res);
        return Promise.all([
          queryClient.invalidateQueries("continueLearning"),
          queryClient.invalidateQueries("courseBeingWatched"),
        ]);
      },
    }
  );
  // console.log("VIDEOCOMPLETE MUTATION", videoCompleteMutation);
  // console.log("VIDEOCOMPLETE PROGRESS", progressUpdateMutation);
  useEffect(() => {
    // console.log("")
    if (isCourseLoading) return;

    if (courseData._id === courseid) {
      // console.log("USEEFFECT - About to setup video data...", courseData);
      setUpVideoData(courseData);
    }
  }, [lectureid, unitid, courseid]);

  const setUpVideoData = (_courseData) => {
    console.log("_courseData", _courseData);
    const unit = configureUnitData(_courseData, unitid);
    setUnitData(unit.data);
    // console.log("UNIT", unit);
    const lecture = configureLectureData(unit.data, lectureid);
    setLectureData(lecture.data);
    if (lecture?.data?.type === "challenge") {
      switchToChallengeMode(lecture?.data);
    } else if (lecture?.data?.type === "backendchallenge") {
      switchToBackendChallengeMode(lecture?.data);
    } else if (lecture?.data?.type === "frontendchallenge") {
      switchToFrontendChallengeMode(lecture?.data);
    } else if (lecture?.data?.type === "coursemcq") {
      switchToMCQMode(lecture?.data);
    } else if (lecture?.data?.type === "datascience") {
      switchToDSChallengeMode(lecture?.data);
    } else {
      // trackLectureActivity("start", "video", item?._id);
    }
    console.log("LECTURE", lecture);
    const _nextLecture = configureNextLectureData(_courseData, unit, lecture);
    console.log("nextLecture", _nextLecture);

    Mixpanel.track("Web Page view", {
      "Page url": "/watch",
      "Page name": "Video Watch page",
      "Lecture name": lecture?.data?.coursename,
      "Unit name": unit?.data?.unitname,
      "Course name": courseData?.coursename,
    });

    if (
      courseData?.completedPercentage === 100 &&
      _nextLecture?.courseCompleted
    ) {
      setNextLecture({
        heading: "You are done!",
        _id: "-1",
        lecturename: "Review your experience",
        duration: "Course completed",
        course: "",
        courseunit: "",
        courseCompleted: _nextLecture?.courseCompleted,
      });
    } else if (_nextLecture?.courseCompleted) {
      setNextLecture({
        heading: "Unwatched Lectures",
        _id: "-1",
        lecturename: "Complete remaining lectures",
        duration: "Course incomplete",
        course: "",
        courseunit: "",
        courseCompleted: _nextLecture?.courseCompleted,
      });
    } else {
      setNextLecture({
        heading: "Next Lecture",
        _id: _nextLecture?._id,
        lecturename: _nextLecture?.lecturename,
        duration: `${_nextLecture?.duration} mins`,
        course: _nextLecture?.course,
        courseunit: _nextLecture?.courseunit,
        courseCompleted: _nextLecture?.courseCompleted,
      });
    }

    setCompletedLectures(_courseData?.completedLectures?.map((cl) => cl._id));

    setCodePlaygrounds(lecture.data?.playgrounds);
    setCodePushes(lecture.data?.checkpoint);
    setUnitList(_courseData?.courseunit);
  };

  const configureUnitData = (_courseData, unitid) => {
    let unitIndex = 0;
    const unit = _courseData?.courseunit.filter((unit, index) => {
      if (unit._id === unitid) {
        unitIndex = index;
        return unit;
      }
    })[0];
    if (!unit) {
      console.log("Unit not found, returning first unit", _courseData);
      // if (!!_courseData === false) {
      //   alert(
      //     "Something is not adding up here. Try access this from your dashboard? You will be redirected."
      //   );
      //   history.push("/updated-dashboard");
      //   return;
      // }
      return { data: _courseData.courseunit[0], index: -1 };
    }

    return { data: unit, index: unitIndex };
  };
  const configureLectureData = (unitData, lectureid) => {
    let indexToReturn = 0;
    const lecture = unitData.lecture.filter((lecture, index) => {
      if (lecture._id === lectureid) {
        indexToReturn = index;
        return lecture;
      }
    })[0];
    if (!lecture) {
      console.log("Lecture not found, loading first lecture of unit");
      return { data: unitData.lecture[0], index: -1 };
    }
    return { data: lecture, index: indexToReturn };
  };

  const configureNextLectureData = (_courseData, unit, lecture) => {
    // console.log(
    //   "NEXTLECTURE STUFF",
    //   _courseData,
    //   _courseData?.courseunit,
    //   unit,
    //   lecture
    // );
    if (
      unit.index + 1 === _courseData?.courseunit.length &&
      lecture.index + 1 === unit.data.lecture.length
    ) {
      return { courseCompleted: true };
    } else if (lecture.index + 1 === unit.data.lecture.length) {
      return {
        ..._courseData.courseunit[unit.index + 1].lecture[0],
        courseCompleted: false,
      };
    } else {
      return {
        ..._courseData.courseunit[unit.index].lecture[lecture.index + 1],
        courseCompleted: false,
      };
    }
  };

  const onVideoProgress = (e, pauseVideo, toggleMode, duration) => {
    const currentTime = e.target.currentTime;
    loadQuizPushes(currentTime, pauseVideo, toggleMode);
    !isVideoComplete && postVideoCompleted(currentTime, duration, pauseVideo);
  };
  const postVideoCompleted = (currentTime, duration, pauseVideo) => {
    // console.log("lectureid", lectureid);
    // console.log("courseid", courseid);
    if (currentTime >= duration - 15) {
      videoCompleteMutation.mutate();
      progressUpdateMutation.mutate();
      setIsVideoComplete(true);
      return;
    }
  };
  const loadQuizPushes = (currentTime, pauseVideo, toggleMode) => {
    codePlaygrounds.forEach((codePush, index) => {
      if (Math.floor(currentTime) === codePush?.timestamp) {
        // setCodePushUrl(formatCodePushUrl(codePush?.checkpointdataurl));
        setProjectFiles(formatProjectFiles(codePush));
        // setProjectFiles({
        //   html: {
        //     name: "index.html",
        //     langauge: "html",
        //     value:
        //       '<!DOCTYPE html>\n<html lang="en">\n<head>\n    <meta charset="UTF-8">\n    <meta http-equiv="X-UA-Compatible" content="IE=edge">\n    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n    <title>Document</title>\n</head>\n<body>\n    <h1>testing..</h1>\n</body>\n</html>',
        //   },
        //   css: {
        //     name: "style.css",
        //     langauge: "css",
        //     value: "/* css code is here  */",
        //   },
        //   js: {
        //     name: "index.js",
        //     langauge: "js",
        //     value: " // This is the JavaScript file",
        //   },
        // });
        setIsCodePushMessage(true);

        if (codePush?.pause) {
          pauseVideo();
          setAlertMessage({
            display: true,
            message: "🔥 Let's Practise - Let me redirect you...",
            isCodePush: true,
          });
          setTimeout(() => {
            toggleMode("code-switch");
          }, 2000);
        } else {
          setAlertMessage({
            display: true,
            message: "Hey, here is a code push for you!",
            isCodePush: true,
          });
        }

        setTimeout(() => {
          setIsCodePushMessage(false);
          setAlertMessage({
            display: false,
            message: "",
            isCodePush: false,
          });
        }, 4500);

        return;
      }
    });
    // codePushes.forEach((codePush, index) => {
    //   if (Math.floor(currentTime) === codePush?.checkpointtime) {
    //     setCodePushUrl(formatCodePushUrl(codePush?.checkpointdataurl));
    //     setIsCodePushMessage(true);

    //     if (codePush?.checkpointpause === "true") {
    //       pauseVideo();
    //       setAlertMessage({
    //         display: true,
    //         message: "Challenge time! Redirecting you...",
    //         isCodePush: true,
    //       });
    //       setTimeout(() => {
    //         toggleMode("code-switch");
    //       }, 2000);
    //     } else {
    //       setAlertMessage({
    //         display: true,
    //         message: "View code push from instructor",
    //         isCodePush: true,
    //       });
    //     }

    //     setTimeout(() => {
    //       setIsCodePushMessage(false);
    //       setAlertMessage({
    //         display: false,
    //         message: "",
    //         isCodePush: false,
    //       });
    //     }, 4500);

    //     return;
    //   }
    // });
  };

  const formatCodePushUrl = (url) => {
    return url.substring(0, 8) === "https://"
      ? url
      : "https://codesandbox.io/embed/" + url + "?fontsize=14&theme=dark";
    // if (this.props.lecture.editor === "paiza") {
    //   editorStyles = { ...editorStyles, top: "11px" };
    //   url =
    //     "https://paiza.io/projects/e/" +
    //     this.state.checkpointurl +
    //     "?theme=twilight";
    // }
  };
  const switchToChallengeMode = useCallback((lecture) => {
    // trackLectureActivity("start", "challenge", lectureid);
    setVideoMode("challenge");
    setChallenge(lecture?.challengeid);
    setShowControlsContainer(false);
  }, []);
  const switchToMCQMode = useCallback((lecture) => {
    // trackLectureActivity("start", "quiz", lectureid);
    setVideoMode("coursemcq");
    setQuizData(lecture?.coursemcq);
    setShowControlsContainer(false);
  }, []);
  const switchToDSChallengeMode = useCallback((lecture) => {
    setVideoMode("datascience");
    setDSChallenge({
      ...lecture?.datasciencechallengeid,
      ...lecture,
    });
    setShowControlsContainer(false);
  }, []);
  const switchToBackendChallengeMode = useCallback((lecture) => {
    // trackLectureActivity("start", "challenge", lectureid);
    setVideoMode("backendchallenge");
    setChallenge(lecture?.backendchallengeid);
    setShowControlsContainer(false);
  }, []);
  const switchToFrontendChallengeMode = useCallback((lecture) => {
    // trackLectureActivity("start", "challenge", lectureid);
    setVideoMode("frontendchallenge");
    setChallenge({
      ...lecture?.frontendchallengeid,
      frontendplaygroundid: lecture?.frontendplaygroundid,
    });
    setShowControlsContainer(false);
  }, []);

  const openLecture = useCallback(
    (item) => {
      setLoading(true);

      // setUpVideoData(courseData);
      if (item?.type === "challenge") {
        switchToChallengeMode(item);
      } else if (item?.type === "backendchallenge") {
        switchToBackendChallengeMode(item);
      } else if (item?.type === "frontendchallenge") {
        switchToFrontendChallengeMode(item);
      } else if (item?.type === "coursemcq") {
        switchToMCQMode(item);
      } else if (item?.type === "datascience") {
        switchToDSChallengeMode(item);
      } else {
        // trackLectureActivity("start", "video", item?._id);
        setShowControlsContainer(true);
        setVideoMode("lecture");
        // setShowControlsContainer(true);
      }

      // setIsVideoComplete(true);
      if (item.courseCompleted && courseData.completedPercentage === 100) {
        history.push(`/certificate/${courseid}`);
      } else if (item.courseCompleted) {
        history.push(`/course/${courseData.coursename}/${courseid}`);
      } else {
        // window.open(
        //   `/watch/${item.course}/${item.courseunit}/${item._id}`,
        //   "_self"
        // );
        history.push(`/watch/${item.course}/${item.courseunit}/${item._id}`);
      }
      // setIsVideoComplete(false);

      // setupPlayerData(item.course, item._id);
      // setLoading(false);

      setLoading(false);
      return;
    },
    [courseData]
  );

  const backAction = useCallback(() => {
    history.push(`/course/${courseData?.coursename}/${courseid}`);
    return true;
  }, [courseData, courseid]);

  const handleExitOnLoad = useCallback(
    (e) => {
      history.push(`/course/${courseData?.coursename}/${courseid}`);
      return true;
    },
    [courseData, courseid]
  );

  const memoizedAlertMessage = useMemo(() => alertMessage, [alertMessage]);
  const memoizedUnitList = useMemo(() => unitList, [unitList]);
  const memoizedCompletedLectures = useMemo(
    () => completedLectures,
    [completedLectures]
  );

  const handleVideoEnded = useCallback(() => {
    console.log("VIDEO ENDED", lectureid);
    // trackLectureActivity("end", "video", lectureid);
    setIsVideoComplete(false);
    if (nextLecture._id) {
      openLecture(nextLecture);
    }
  }, [nextLecture?._id]);

  const handleSupportButton = useCallback(() => {
    alert(
      "You will be redirected to discord for support! See you in just a moment."
    );
    window.open("https://discord.gg/G5kaneFSDa", "_blank");
  }, []);

  if (isCourseLoading || !unitData || !lectureData) {
    return <ZPLoading />;
  }
  if (isCourseError) {
    return <h1 className="text-white">Something went wrong: {courseError}</h1>;
  }

  const handleCanPlayVideo = () => {
    console.log("You already know what it is.");
  };

  const updateFrontendChallCompletion = () => {
    progressUpdateMutation.mutate();
  };
  return (
    <div>
      {/* {!isProfileCompleted && <Banner/>} */}

      {unitData && lectureData && (
        <ZaioVideoPlayer
          refreshCourseData={refreshCourseData}
          setProjectFiles={setProjectFiles}
          src={lectureData?.lectureurl}
          challenge={lectureData?.challengeid || null}
          currentLectureId={lectureData._id}
          currentCourseId={courseid}
          currentUnitId={unitData._id}
          alertMessage={memoizedAlertMessage}
          hasAlert={isCodePushMessage}
          codePushSrc={codePushUrl}
          projectFiles={projectFiles}
          courseTitle={courseData.coursename}
          unitTitle={unitData.unitname}
          title={lectureData.lecturename}
          subTitle={courseData?.coursename}
          titleMedia={lectureData?.lecturename}
          extraInfoMedia={unitData?.unitname}
          backButton
          backAction={backAction}
          dataNext={nextLecture}
          onNextClick={openLecture}
          unitList={memoizedUnitList}
          completedLectures={memoizedCompletedLectures}
          listRedirect={openLecture}
          autoPlay={true}
          onTimeUpdate={onVideoProgress}
          updateFrontendChallCompletion={updateFrontendChallCompletion}
          onCrossClick={handleExitOnLoad}
          onEnded={handleVideoEnded}
          handleSupportButton={handleSupportButton}
          // onCanPlay={handleCanPlayVideo}
        />
      )}

      {videoMode !== "lecture" && (
        <div
          className="floating-challenge-btn"
          onMouseEnter={() => setShowControlsContainer(true)}
          // onMouseLeave={() => setShowControlsContainer(false)}
        >
          {!showControlsContainer && (
            <p className="bg-yellow-400 rounded-full px-3 py-1 text-sm text-dark font-bold">
              View options
            </p>
          )}
          {/* <BsArrowDownUp /> */}
        </div>
      )}
    </div>
  );
}

export default Watch;
