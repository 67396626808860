import React, { useState, useEffect, useRef } from "react";
import { askQuestion } from "./OpenAPI";
import { FiSend } from "react-icons/fi";
import { ThreeDots } from "react-loader-spinner";

function OpenAIChat() {
  const [text, setText] = useState("");
  const [answer, setAnswer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const valueRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    askQuestion()
      .then((res) => {
        setAnswer([res]);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  const scrollToBottom = () => {
    valueRef.current.addEventListener("DOMNodeInserted", (event) => {
      const { currentTarget: target } = event;
      target.scroll({ top: target.scrollHeight, behavior: "smooth" });
    });
  };

  const onButtonSubmit = async (e) => {
    e.preventDefault();
    if (text.length > 0) {
      setAnswer((prev) => [...prev, { role: "user", content: text }]);
      setIsLoading(true);
      formRef.current.reset();
      scrollToBottom();
      try {
        const response = await askQuestion(text, answer);
        setAnswer((prev) => [...prev, response]);
        setIsError(false);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
      scrollToBottom();
    }
  };

  const submitOnEnter = async (e) => {
    if (!isLoading && e.keyCode === 13) {
      await onButtonSubmit(e);
    }
  };

  return (
    <div className="px-4 absolute w-100 bottom-20 h-[60%]">
      <div className="relative h-[80%]">
        <div
          className="relative h-full d-flex flex-column overflow-y-scroll mb-20"
          ref={valueRef}
        >
          {" "}
          {isError && (
            <div className="alert flex items-center h-screen" role="alert">
              Something went wrong. Try again later.
            </div>
          )}
          {!isError &&
            answer &&
            answer.map((a, i) => (
              <div
                key={i}
                className={`w-64 p-2 max-w-max  mt-2 text-sm border-2 border-transparent ${
                  a?.role === "user"
                    ? "bg-purple-500 text-white self-end text-left rounded-tl-2xl rounded-bl-2xl rounded-br-2xl"
                    : "bg-white self-start rounded-tr-2xl rounded-bl-2xl rounded-br-2xl"
                }`}
              >
                {a?.content ? (
                  a?.content?.includes("```") ? (
                    <>
                      <div className="whitespace-pre-line">
                        {a?.content?.split("```")[0]}
                      </div>
                      <div className="bg-zinc-200 text-red-600 whitespace-pre-line p-2 rounded-md my-2">
                        {a?.content?.split("```")[1]}
                      </div>
                      <div className="whitespace-pre-line">
                        {a?.content?.split("```")[2]}
                      </div>
                    </>
                  ) : (
                    a.content
                  )
                ) : (
                  ""
                )}
              </div>
            ))}
          {isLoading && (
            <div>
              <ThreeDots
                height="24"
                width="24"
                radius="4"
                color="rgb(147 51 234)"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={isLoading}
              />
            </div>
          )}
          <div></div>
        </div>
      </div>
      <div>
        {!isError && (
          <form className="mt-3" ref={formRef}>
            <div
              className="gap-2 mb-2 flex-1"
              style={{
                position: "absolute",
                display: "flex",
                width: "390px",
                height: "max-content",
                bottom: "-10px",
                padding: " 0 16px",
                justifyContent: "center",
                alignItems: "center",
                left: 0,
              }}
            >
              <textarea
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                rows={2}
                maxLength={400}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={submitOnEnter}
                disabled={isLoading}
                placeholder="Ask me:)"
              />
              <button
                className="rounded-full bg-[#c084fc] text-white p-2 cursor-pointer"
                disabled={isLoading}
                onClick={onButtonSubmit}
              >
                <FiSend />
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default OpenAIChat;
