import { MdMenu } from "react-icons/md";
import { useQueryClient } from "react-query";
import useAssignmentStore from "zustand-store/assignmentStore";
import moment from "moment";
import LogoNav from "assets/svg/Logonav.svg";
import { useHistory } from "react-router-dom";
function AssignmentNav({
  hideSandboxList = false,
  showSandboxList,
  setShowSandboxList,
  setShowVideosList,
  showVideosList,
  currentCourse,
  editMode,
  setEditMode,
  currentVideo,
}) {
  const type = useAssignmentStore((state) => state.type);
  const title = useAssignmentStore((state) => state.title);
  const assignmentData = useAssignmentStore((state) => state.assignmentData);

  const history = useHistory();
  const handleSubmitCode = () => {
    alert("handle submit all coding questions!");
  };
  // const queryClient = useQueryClient();
  // const currentVideo = queryClient.getQueryData("ideCurrentVideo");
  return (
    <div
      className={`admin-ide-nav ${editMode ? "admin-ide-nav-edit-mode" : ""}`}
    >
      {" "}
      {!hideSandboxList && (
        <div className="flex items-center">
          <span
            className={`admin-ide-btn mr-2 ${
              showSandboxList ? "admin-ide-btn-active" : ""
            }`}
            onClick={() => setShowSandboxList(!showSandboxList)}
          >
            <MdMenu className="cursor" />
            <span className="font-small">Q's</span>
          </span>
          {/* <Link to="/" className="navbar-logo"> */}
          <div
            className="cursor-pointer m-2 mt-0"
            onClick={() => history.push("/updated-dashboard")}
          >
            <img src={LogoNav} alt="React Logo" />
          </div>
          {/* </Link> */}
        </div>
      )}
      <div className="admin-ide-center d-flex">
        <h6 className="m-0 p-0">
          {type === "overview" ? "Assignment Overview" : title}
          <span
            className="badge success-btn cursor m-2"
            onClick={() => setShowVideosList(true)}
          >
            {type}
          </span>
        </h6>
      </div>
      <div>
        {assignmentData?.status === "returned" && (
          <span className="m-2">
            <em>
              Submitted on{" "}
              {moment(assignmentData?.submissionDate).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}{" "}
            </em>
            <badge className="badge success-btn">
              Score: {assignmentData?.finalgrade}
            </badge>
          </span>
        )}
        {assignmentData?.status === "inprogress" && (
          <span className="m-2">
            <em>
              Due on{" "}
              {moment(assignmentData?.deadline).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}{" "}
            </em>
          </span>
        )}
        {/* <span className="m-2">
          <em>Due on </em>
        </span> */}

        {/* {assignmentData?.status === "inprogress" && (
          <>
            <span
              className={`admin-ide-btn btn-purple m-2`}
              onClick={handleSubmitCode}
            >
              Submit Code
            </span>
          </>
        )} */}
      </div>
    </div>
  );
}

export default AssignmentNav;
