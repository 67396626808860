import ReactPlayer from "react-player/youtube";
import { VideoExplainerContainer } from "./TeamLearning.styled";

function VideoExplainer() {
  return (
    <VideoExplainerContainer>
      <ReactPlayer
        width="100%"
        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
      />
    </VideoExplainerContainer>
  );
}

export default VideoExplainer;
