import { useQuery, useMutation } from "react-query";
import classes from "./Dashboardnew.module.css";

import Avatar, { genConfig } from "react-nice-avatar";
import ZPLoading from "pages/Watch/components/ZPLoading";
import tblService from "actions/services/tbl.service";
import Members from "pages/TeamLearning/Members";
import { useHistory } from "react-router-dom";

const config1 = genConfig();
const config2 = genConfig();
const config3 = genConfig();
const config4 = genConfig();

// {
//     onSuccess: (response) => {
//       console.log("response", response);
//       setTeam({ ...response.teamData, members: response.members });
//       if (response.teamData === null) {
//         setHasTeam(false);
//       } else {
//         setHasTeam(true);
//       }
//     },
//   }
function TeamCard() {
  const history = useHistory()

  //queries
  const {
    isLoading: isTeamInvitesLoading,
    isError: isTeamInvitesError,
    error: teamInvitesError,
    data: teamInvites,
  } = useQuery("teamInvites", () => tblService.getInvites());

  const {
    isLoading: isTeamLoading,
    isError: isTeamError,
    error: teamError,
    data: team,
  } = useQuery("getTeam", () => tblService.getTeam());

  if (isTeamInvitesLoading || isTeamLoading) {
    return <ZPLoading />;
  }
  if (isTeamInvitesError || isTeamError) {
    console.log("teamInvitesError", teamInvitesError);
    console.log("teamError", teamError);
    return (
      <ol className="text-white">
        Errors
        <li>{teamInvitesError} </li>
        <li>{teamError}</li>
      </ol>
    );
  }
  if (team && teamInvites) {
    const hasTeam = !(team.teamData === null);
    // console.log("HASTEAM", hasTeam);

    return (
      <div className={classes.cards}>
        <div className={classes.cardBody} style={{ paddingTop: 17 }}>
          <>
            {!hasTeam &&
            teamInvites?.myReceivedInvites.length +
              teamInvites?.mySentInvites.length <
              1 ? (
              <div className={classes.paidCardTop}>
                {/* <span className={classes.paidCardHeading}>
                  Gym buddies succeed together. Get a "Code" buddy - It's FREE
                </span> */}
                <div
                  className={classes.wrapper}
                  style={{ position: "relative" }}
                >
                  <Avatar
                    style={{ width: "8rem", height: "8rem" }}
                    {...config1}
                    className={`${classes.icon} ${classes.tutor1}`}
                    style={{ position: "absolute", left: 0, top: 0 }}
                  />

                  <Avatar
                    style={{ width: "8rem", height: "8rem" }}
                    {...config2}
                    className={`${classes.icon} ${classes.tutor2}`}
                    style={{ position: "absolute", left: 35, top: 0 }}
                  />
                  <Avatar
                    style={{ width: "8rem", height: "8rem" }}
                    {...config3}
                    className={`${classes.icon} ${classes.tutor3}`}
                    style={{ position: "absolute", left: 70, top: 0 }}
                  />
                  <Avatar
                    style={{ width: "8rem", height: "8rem" }}
                    {...config4}
                    className={`${classes.icon} ${classes.tutor4}`}
                    style={{ position: "absolute", left: 105, top: 0 }}
                  />
                </div>
              </div>
            ) : null}
            <div className={classes.paidCardProgressBar}>
              {hasTeam ? (
                <p>Team: {team.teamData.name}</p>
              ) : (
                <p>Team not created. 3 members max per team.</p>
              )}

              {teamInvites?.myReceivedInvites.map((invite, index) => {
                return (
                  <Members pending receivedInvite={invite} fromDashboard />
                );
              })}
              {teamInvites?.mySentInvites.map((invite, index) => {
                return <Members pending sentInvite={invite} />;
              })}

              {hasTeam &&
                team.members.map((member) => (
                  <Members fromDashboard member={member} />
                ))}

              {/* <ProgressBar
              now={45}
              className={classes.courseProgressBar}
              style={{ width: "100%" }}
            /> */}
            </div>

            <div className={classes.cardText}>
              <span
                className={classes.left}
                onClick={() =>
                  alert(
                    "Team based learning - learning coding together is always more fun, and theres higher chances of success. Its easy to bring projects to teams that can already work well together than to assemble a team. So invite a friend, and create your own team, track each others progress, earn team streaks and even better, get real project experience opportunities if your team dynamic improves."
                  )
                }
              >
                <i>What is this?</i>
              </span>

              <span className={classes.right + " " + classes.proIcon}>
                <a
                  onClick={() => history.push("/team")}
                  // className="text-white"
                >
                  {hasTeam && team.members.length >= 3
                    ? "Visit workspace"
                    : "Invite co-learner"}
                </a>
              </span>
            </div>
          </>
        </div>
      </div>
    );
  }
}

export default TeamCard;
