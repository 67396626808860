import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_BACKEND_URL;

class TBLService {
  //coupons for all users
  sendInvite(recipientemail) {
    return axios
      .post(
        API_URL + "/teaminvite/sendinvite",
        { recipientemail },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        // console.log("response.data", response.data);
        return response.data;
      })
      .catch((rej) => {
        console.log("sendinvite", rej.response);
        return rej.response;
      });
  }

  getInvites() {
    return axios
      .get(API_URL + "/teaminvite/getinvites", {
        headers: authHeader(),
      })
      .then((response) => {
        // console.log("getinvites", response.data.data);
        return response.data.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  getTeam() {
    return axios
      .get(API_URL + "/team", {
        headers: authHeader(),
      })
      .then((response) => {
        // console.log("getTeam", response.data);
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  getTeamLearningPaths(teamid) {
    return axios
      .post(
        API_URL + "/team/getteamlearningpaths",
        { teamid },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        // console.log("response.data", response.data);
        return response.data;
      })
      .catch((rej) => {
        console.log("getteamlearningpaths", rej.response);
        return rej.response;
      });
  }

  acceptInvite(inviteid) {
    //     {"inviteid":"632f4d11f4156c69a23eda98",
    // "response":"accepted"}
    // console.log("INVITEID", inviteid)
    return axios
      .post(
        API_URL + "/teaminvite/respondtoinvite",
        { inviteid, response: "accepted" },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log("response.data invite", response.data);
        return response.data;
      })
      .catch((rej) => {
        console.log("sendinvite", rej.response);
        return rej.response;
      });
  }
}

export default new TBLService();
