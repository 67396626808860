import { useEffect, useState } from 'react'
import EditorDrawer from '../CodingChallenge/EditorDrawer'
import TextEditorDrawer from '../CodingChallenge/TextEditorDrawer'
import ZipFileInput from 'components/Inputs/ZipFileInput'
import useAssignmentStore from 'zustand-store/assignmentStore'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Card, Form, ListGroup } from 'react-bootstrap'
import useEditorStore from 'zustand-store/editorStore'
import lectureService from 'actions/services/lecture.service'

function BackendQForm({ courseId, unitId, lectureId, assignmentid = null }) {
  const [lectureName, setLectureName] = useState('')
  const [starterCode, setStarterCode] = useState(null)
  const [isTextEditorOpen, setTextEditorOpen] = useState(false)
  const [questionName, setQuestionName] = useState('')
  const [isAdminChallenge, setIsAdminChallenge] = useState(!!assignmentid)
  const [language, setLanguage] = useState('springBoot')
  const [marks, setMarks] = useState(0)
  const [testCases, setTestCases] = useState([])
  const [route, setRoute] = useState('/')
  const [payload, setPayload] = useState('{}')
  const [headers, setHeaders] = useState('{}')
  const [method, setMethod] = useState('get')
  const [expectedOutput, setExpectedOutput] = useState('{}')
  const [editIndex, setEditIndex] = useState(-1)
  const [duration, setDuration] = useState('')
  const [afterLecture, setAfterLecture] = useState(lectureId)
  const [assignmentDropdown, setAssignmentDropdown] = useState('0')
  const [timeout, setTimeout] = useState(30)

  const queryClient = useQueryClient()
  const adminCourseQuestion = useAssignmentStore(
    (state) => state.adminCourseQuestion
  )
  const adminQuestionMode = useAssignmentStore(
    (state) => state.adminQuestionMode
  )
  const adminSelectedAssignmentQuestion = useAssignmentStore(
    (state) => state.adminSelectedAssignmentQuestion
  )
  const questionFile = useEditorStore((state) => state.textEditorValue)
  const setQuestionFile = useEditorStore(
    (state) => state.setCurrentTextEditorValue
  )

  /* if edit mode then initialize variables */
  useEffect(() => {
    if (
      adminSelectedAssignmentQuestion &&
      adminQuestionMode === 'edit-assignment-backend'
    ) {
      setQuestionName(adminSelectedAssignmentQuestion?.questionname)
      setLanguage(adminSelectedAssignmentQuestion?.language)
      setMarks(adminSelectedAssignmentQuestion?.marks)
      setTestCases(adminSelectedAssignmentQuestion?.testcases)
      setTimeout(adminSelectedAssignmentQuestion?.timeout || 30)
    } else if (adminCourseQuestion && adminQuestionMode === "edit-course-backend") {
      console.log("ADMIN QUESTION...", adminCourseQuestion);
      setTestCases(adminCourseQuestion?.backendchallengeid?.testcases)
      setLectureName(adminCourseQuestion?.lecturename)
      setDuration(adminCourseQuestion?.duration)
      setLanguage(adminCourseQuestion?.backendchallengeid?.language)
      setTimeout(adminCourseQuestion?.backendchallengeid?.timeout || 30)
      console.log('NO HERE')
    }
     else {
      console.log('NO ADMIN QUESTION')
      resetForm()
    }

    return () => {
      resetFormComplete()
    }
  }, [adminQuestionMode, adminSelectedAssignmentQuestion, adminCourseQuestion])

  /* mutations start */

  const updateChallengeMutation = useMutation(
    (tcs) =>
      lectureService.adminEditCourseBackendChallenge(
        adminCourseQuestion?.backendchallengeid?._id,
        duration,
        tcs,
        questionFile,
        afterLecture,
        starterCode,
        timeout
      ),
    {
      onSuccess: (data) => {
        console.log("submitChallengeMutation...", data);
        alert("Updated Challenge...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  )

  const updateLectureInfoMutation = useMutation(
    () =>
      lectureService.putLecture({
        lecturename: lectureName,
        duration: duration,
        afterlecture: afterLecture,
        _id: adminCourseQuestion?._id,
        releaseassignment: assignmentDropdown,
      }),
    {
      onSuccess: (data) => {
        console.log("update lecture information...", data);
        alert("UPDATE LECTURE INFO...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  );

  const submitChallengeMutation = useMutation(
    () => 
      lectureService.adminUploadBackendChallenge(
        courseId,
        unitId,
        lectureName,
        duration,
        testCases,
        afterLecture,
        questionFile,
        starterCode,
        language,
        assignmentDropdown,
        timeout
      ),
    {
      onSuccess: (data) => {
        console.log("submitChallengeMutation...", data);
        alert("Created Challenge...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  )

  const updateAssignmentQMutation = useMutation(
    (tcs) =>
      lectureService.updateAssignmentBackendQ(
        adminSelectedAssignmentQuestion?._id,
        assignmentid,
        tcs,
        questionFile,
        marks,
        questionName,
        language,
        starterCode,
        timeout
      ),
    {
      onSuccess: (data) => {
        console.log('created assignment challenge...', data)
        alert('ADDED QUESTION ID...response' + JSON.stringify(data))
        return queryClient.invalidateQueries(['assignmentList'])
      },
    }
  )

  const deleteAssignmentQMutation = useMutation(
    (tcs) =>
      lectureService.deleteAssignmentBackendQ(
        adminSelectedAssignmentQuestion?._id,
        assignmentid
      ),
    {
      onSuccess: (data) => {
        console.log('deleted assignment challenge...', data)
        alert('DELETED ASSIGNMENT QUESTION...response' + JSON.stringify(data))
        return queryClient.invalidateQueries(['assignmentList'])
      },
    }
  )

  const createAssignmentQMutation = useMutation(
    () =>
      lectureService.createBackendChallengeAsAdmin(
        questionFile,
        assignmentid,
        testCases,
        marks,
        questionName,
        language,
        starterCode,
        timeout
      ),
    {
      onSuccess: (data) => {
        console.log('created assignment challenge...', data)
        alert('CREATED ASSIGNMENT QUESTION...response' + JSON.stringify(data))
        return queryClient.invalidateQueries(['assignmentList'])
      },
    }
  )
  /* mutations end */

  const {
    isLoading: isAssignmentListLoading,
    isError,
    error,
    data: assignmentListResponse,
  } = useQuery(
    'assignmentList',
    () => lectureService.getAdminAssignmentList(),
    {
      onSuccess: (response) => {
        // setAdminAssignmentList(response?.data);
      },
    }
  )

  const handleFileSelect = (file) => {
    setStarterCode(file)
  }

  const handlePostChallenge = async (e) => {
    e.preventDefault()
    if (adminQuestionMode === 'edit-course-challenge') {
      updateChallengeMutation.mutate()
      return
    }
    if (adminQuestionMode.includes('assignment')) {
      createAssignmentQMutation.mutate()
    } else {
      submitChallengeMutation.mutate()
    }
  }

  const handleUpdatChallengeToDB = (testcases) => {
    updateChallengeMutation.mutate(testcases);
    updateLectureInfoMutation.mutate();
  };

  const loadButton = () => {
    if (adminQuestionMode.includes('create')) {
      return (
        <>
          {submitChallengeMutation?.isLoading ? (
            <p className='text-white text-center w-100'>Loading...</p>
          ) : (
            <button
              className='btn-warning p-3 w-100 m-2'
              onClick={handlePostChallenge}
            >
              Submit Challenge to DB
            </button>
          )}
        </>
      )
    }

    if (adminQuestionMode === 'edit-course-backend') {
      return (
        <>
          {submitChallengeMutation?.isLoading ? (
            <p className='text-white text-center w-100'>Loading...</p>
          ) : (
            <button
              className='btn-warning p-3 w-100 m-2'
              onClick={() => handleUpdatChallengeToDB(testCases)}
            >
              Update Challenge to DB
            </button>
          )}
        </>
      )
    }

    if (adminQuestionMode === 'edit-assignment-backend') {
      return (
        <>
          {updateAssignmentQMutation?.isLoading ? (
            <p className='text-white text-center w-100'>Loading...</p>
          ) : (
            <button
              className='btn-warning p-3 w-100 m-2'
              onClick={() => updateAssignmentQMutation.mutate(testCases)}
            >
              Update Assignment Data
            </button>
          )}
        </>
      )
    }
  }

  function encode(str) {
    return btoa(unescape(encodeURIComponent(str || '')))
  }

  function decode(bytes) {
    var escaped = escape(atob(bytes || ''))
    try {
      return decodeURIComponent(escaped)
    } catch {
      return unescape(escaped)
    }
  }

  const handleAddTestCase = (e) => {
    e.preventDefault()

    try {
      JSON.parse(expectedOutput)
    } catch (e) {
      alert('Expected output is not a valid JSON')
      return
    }

    const newTestCase = {
      route: encode(route),
      method: encode(method),
      payload: encode(JSON.stringify(JSON.parse(payload))),
      headers: encode(JSON.stringify(JSON.parse(headers))),
      expected_output: encode(JSON.stringify(JSON.parse(expectedOutput))),
    }
    console.log('NEW TESTCASE', newTestCase)

    setTestCases([...testCases, newTestCase])
    resetForm()

    if (adminQuestionMode === 'edit-course-challenge') {
      updateChallengeMutation.mutate([...testCases, newTestCase])
    } else if (adminQuestionMode === 'edit-assignment-coding') {
      updateAssignmentQMutation.mutate([...testCases, newTestCase])
    }
  }

  const resetForm = () => {
    // setQuestionText("");
    setRoute('/')
    setMethod('get')
    setPayload('{}')
    setHeaders('{}')
    setExpectedOutput('{}')
    setEditIndex(-1)
  }

  const resetFormComplete = () => {
    resetForm()
    setTestCases([])
    setQuestionName('')
    setMarks('')
    setLanguage('springBoot')
    setStarterCode(null)
    setQuestionFile('')
  }

  const handleUpdatedTestCase = (e) => {
    e.preventDefault()
    const editedTestCase = {
      route: encode(route),
      method: encode(method),
      payload: encode(payload),
      headers: encode(headers),
      expected_output: encode(expectedOutput),
    }
    const updatedTestCases = testCases.map((testCase, index) =>
      editIndex === index ? editedTestCase : testCase
    )

    setTestCases(updatedTestCases)
    if (adminQuestionMode === 'edit-course-challenge') {
      updateChallengeMutation.mutate(updatedTestCases)
    } else if (adminQuestionMode === 'edit-assignment-coding') {
      updateAssignmentQMutation.mutate(updatedTestCases)
    }

    resetForm()
  }

  const handleEditTestCase = (index) => {
    setEditIndex(index)

    const testCase = testCases[index]
    console.log('EDITING', testCase, editIndex)
    setRoute(decode(testCase.route))
    setPayload(decode(testCase.payload))
    setHeaders(decode(testCase.headers))
    setMethod(decode(testCase.method))
    setExpectedOutput(decode(testCase.expected_output))
    // setLanguage("java");
  }

  const handleDeleteTestCase = (index) => {
    const newTestCases = testCases.filter((_, i) => i !== index)

    setTestCases(newTestCases)
    if (adminQuestionMode === 'edit-course-challenge') {
      updateChallengeMutation.mutate(newTestCases)
    } else if (adminQuestionMode === 'edit-assignment-coding') {
      updateAssignmentQMutation.mutate(newTestCases)
    }
  }

  return (
    <div>
      {loadButton()}
      {adminQuestionMode === 'edit-assignment-backend' && (
        <button
          className='w-100 bg-red-400 p-2 px-3 m-2 rounded'
          onClick={() => {
            alert('Refresh page to stop.')
            deleteAssignmentQMutation.mutate()
          }}
        >
          Delete Assignment Question
        </button>
      )}
      <div className='d-flex w-100'>
        <div className='w-50 m-3'>
          <h1 className='text-lg text-white m-2 px-2'>
            Starter code zip:{' '}
            <div
              className='cursor-pointer inline-block'
              // onClick={() => setIsDrawerOpen(true)}
            >
              {starterCode && (
                <span className='bg-purple-400 text-sm px-2 rounded-lg mx-1'>
                  {starterCode?.name}
                </span>
              )}
              <ZipFileInput onFileSelect={handleFileSelect} />
            </div>
          </h1>
          <h1 className='text-lg text-white m-2 px-2'>
            Question Details:{' '}
            <div
              className='cursor-pointer inline-block'
              onClick={() => setTextEditorOpen(true)}
            >
              <span className='bg-purple-400 text-sm px-2 rounded-lg mx-1'>
                Info
              </span>

              <button
                className='bg-green-600 text-white text-sm rounded px-2'
                onClick={() => setTextEditorOpen(true)}
              >
                +
              </button>
            </div>
          </h1>

          {!adminQuestionMode.includes('assignment') && (
            <Form.Group controlId='questionType'>
              <Form.Label className='text-white'>
                Select Assignment to release
              </Form.Label>

              {isAssignmentListLoading ? (
                <p>Loading...</p>
              ) : (
                <Form.Select
                  id=''
                  value={assignmentDropdown}
                  onChange={(event) =>
                    setAssignmentDropdown(event.target.value)
                  }
                >
                  <option value='0'>No assignment to release</option>
                  {assignmentListResponse?.data?.map((ass, index) => (
                    <option key={ass?.id} value={ass?._id}>
                      {ass?.assignmentname} ({ass?._id})
                    </option>
                  ))}
                </Form.Select>
              )}
            </Form.Group>
          )}

          {adminQuestionMode.includes('assignment') && (
            <Form.Group controlId='questionName'>
              <Form.Label className='text-white'>Challenge Name</Form.Label>
              <Form.Control
                type='url'
                value={questionName}
                onChange={(event) => setQuestionName(event.target.value)}
              />
            </Form.Group>
          )}

          {!isAdminChallenge && (
            <>
              <Form.Group controlId='lectureName'>
                <Form.Label className='text-white'>
                  Challenge Name{' '}
                  {adminQuestionMode === 'edit-course-challenge' &&
                    "(Challenge name won't update)"}
                </Form.Label>
                <Form.Control
                  type='text'
                  value={lectureName}
                  onChange={(event) => setLectureName(event.target.value)}
                  disabled={adminQuestionMode === 'edit-course-challenge'}
                />
              </Form.Group>
              <Form.Group controlId='duration'>
                <Form.Label className='text-white'>Duration</Form.Label>
                <Form.Control
                  type='number'
                  value={duration}
                  onChange={(event) => setDuration(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId='afterLecture'>
                <Form.Label className='text-white'>After Lecture</Form.Label>
                <Form.Control
                  type='text'
                  value={afterLecture}
                  onChange={(event) => setAfterLecture(event.target.value)}
                />
              </Form.Group>
            </>
          )}

          {isAdminChallenge && (
            <Form.Group controlId='duration'>
              <Form.Label className='text-white'>Marks</Form.Label>
              <Form.Control
                type='number'
                value={marks}
                onChange={(event) => setMarks(event.target.value)}
              />
            </Form.Group>
          )}
          <Form.Group controlId='questionType'>
            <Form.Label className='text-white'>Select Language</Form.Label>
            <Form.Select
              id=''
              value={language}
              onChange={(event) => setLanguage(event.target.value)}
            >
              <option value='springBoot'>Spring Boot (Java)</option>
            </Form.Select>
          </Form.Group>

          <Form.Group controlId='timeout'>
            <Form.Label className='text-white'>Timeout duration</Form.Label>
            <Form.Control
              type='number'
              value={timeout}
              onChange={(event) => setTimeout(event.target.value)}
            />
          </Form.Group>

          <Form>
            <Form.Group controlId='route'>
              <Form.Label className='text-white'>Route</Form.Label>
              <Form.Control
                type='text'
                value={route}
                onChange={(event) => setRoute(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='method'>
              <Form.Label className='text-white'>Select Method</Form.Label>
              <Form.Select
                id=''
                value={method}
                onChange={(event) => setMethod(event.target.value)}
              >
                <option value='get'>GET</option>
                <option value='post'>POST</option>
                <option value='put'>PUT</option>
                <option value='delete'>DELETE</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId='payload'>
              <Form.Label className='text-white'>Payload (JSON)</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={payload}
                onChange={(event) => setPayload(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='headers'>
              <Form.Label className='text-white'>Headers (JSON)</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={headers}
                onChange={(event) => setHeaders(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='expectedOutput'>
              <Form.Label className='text-white'>
                Expected Output (JSON)
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={expectedOutput}
                onChange={(event) => setExpectedOutput(event.target.value)}
              />
            </Form.Group>

            {editIndex >= 0 ? (
              <button
                className='btn success-btn p-3 w-100 mt-2'
                onClick={handleUpdatedTestCase}
              >
                Update Test Case
              </button>
            ) : (
              <button
                className='btn-primary p-3 w-100 mt-2'
                onClick={handleAddTestCase}
              >
                Add Test Case
              </button>
            )}
            {editIndex >= 0 && (
              <button
                className='btn-primary  p-3 w-100 mt-2'
                onClick={resetForm}
              >
                Reset Form
              </button>
            )}
          </Form>
        </div>
        <div className='w-50 m-3'>
          {testCases?.map((testCase, index) => (
            <Card key={index} className='mt-3'>
              <Card.Body>
                <Card.Title className='d-flex align-items-center justify-content-between'>
                  Test Case {index + 1}
                  <div className='d-flex'>
                    <button
                      className='btn-primary btn-sm m-2'
                      onClick={() => handleEditTestCase(index)}
                    >
                      Edit Test Case
                    </button>
                    <button
                      className='btn-danger btn-sm m-2'
                      onClick={() => handleDeleteTestCase(index)}
                    >
                      Delete Test Case
                    </button>
                  </div>
                </Card.Title>
                {/* <Card.Text>LanguageCode: {testCase.language_id}</Card.Text> */}
                <ListGroup>
                  <p className='m-0 p-0 mb-2'>Route:</p>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    value={decode(testCase.route)}
                    disabled
                  />
                </ListGroup>
                <ListGroup>
                  <p className='m-0 p-0 mb-2'>Method:</p>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    value={decode(testCase.method)}
                    disabled
                  />
                </ListGroup>
                <ListGroup>
                  <p className='m-0 p-0 mb-2'>Payload:</p>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    value={decode(testCase.payload)}
                    disabled
                  />
                </ListGroup>
                <ListGroup>
                  <p className='m-0 p-0 mb-2'>Headers:</p>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    value={decode(testCase.headers)}
                    disabled
                  />
                </ListGroup>
                <ListGroup>
                  <p className='m-0 p-0 mt-4 mb-2'>Expected Output:</p>

                  <Form.Control
                    as='textarea'
                    rows={3}
                    value={decode(testCase.expected_output)}
                    disabled
                  />
                </ListGroup>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>

      {/* <EditorDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} /> */}
      <TextEditorDrawer
        isOpen={isTextEditorOpen}
        setIsOpen={setTextEditorOpen}
      />
    </div>
  )
}

export default BackendQForm
