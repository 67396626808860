import walletService from "actions/services/wallet.service";
import UnlockWithZCoin from "components/UnlockWithZCoin/UnlockWithZCoin";

function LockedHint({
  title,
  description,
  price,
  teamid,
  competitionid,
  hintid,
  setIsPurchased,
}) {
  const redirectFn = (data) => {
    if (data?.success) {
      setIsPurchased(true);
    }
  };

  return (
    <div>
      <h1>{title}</h1>
      <p>{description}</p>
      <UnlockWithZCoin
        mutationFn={walletService.unlockHint}
        mutationParams={[teamid, competitionid, hintid, price]}
        price={price}
        btnText="Unlock hint with"
        redirectFn={redirectFn}
      />
    </div>
  );
}

export default LockedHint;
