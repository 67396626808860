import { useState } from "react";
import { Puff } from "react-loader-spinner";

function SubmitCodeForm({
  assignmentSubmitCodeMutation,
  showAssignmentSubmission,
  setShowAssignmentSubmission,
}) {
  const [show, setShow] = useState(false);
  if (assignmentSubmitCodeMutation?.isLoading === true) {
    setShow(true);
  }
  if (assignmentSubmitCodeMutation?.isError === true) {
    return <div>Error</div>;
  }
  if (assignmentSubmitCodeMutation?.isSuccess === true) {
    return <div>Code Submitted.</div>;
  }
  return (
    <>
      <button
        onClick={() => {
          setShow(true);
          assignmentSubmitCodeMutation.mutate();
        }}
        className="p-3 m-2 btn-block btn-warning"
      >
        {show ? (
          <Puff
            height="25"
            width="25"
            radius={1}
            color="white"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          "Submit Code"
        )}
      </button>
    </>
  );
}

export default SubmitCodeForm;
