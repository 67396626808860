import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingContext } from 'context/LoadingProvider';
import './Recommend.css'

import { RxTriangleLeft } from "react-icons/rx";
import { useHistory } from "react-router-dom";
import { UserContext } from "context/UserProvider";
// import Live from "components/Live/Live";

function ChatBubble({
  title = "🎉 Introducing competitions",
  text = "This holiday, grow your portfolio, earn prizes and get infront of companies by 2023",
  // showLive = true,
  onClick = undefined,
  chatPersonImage = "https://zaiocontent.s3.eu-west-2.amazonaws.com/ab.png",
}) {
  const { user } = useContext(UserContext);
  // const history = useHistory();
  return (
    <div className="dash-competition">
      <img src={chatPersonImage} />

      <div className="dash-message-parent">
        <RxTriangleLeft className="dash-comp-icon" />
        <div className="dash-message">
          {title && (
            <p className="m-0 p-0">
              {title}
              {/* {showLive && <Live />} */}
            </p>
          )}

          {text && <span>{text}</span>}
          {onClick && (
            <button className="btn btn-main m-2" onClick={onClick}>
              Try it out {user && !user.ispro ? " - It's Free" : ""}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const Recommend = () => {
  const history = useHistory();
  const { courseid } = useParams();
  const { setLoading, isloading } = useContext(LoadingContext);
  console.log(isloading)
  const nextPage = () => {
    history.push(`/next-course/${courseid}`);
    setLoading(true);
  };
  const upgradePage = () => {
    history.push(`/coursesummary?path=FS`);
    setLoading(true);
  }
  useEffect(() => {
    setLoading(false);
  }, [])

  if (isloading) return <div></div>;
  return (
    <div className="rec-container">
      <ChatBubble
        title="Hey, you did great with this course"
        text="I think you are a good fit to do fullstack and get a job in tech. Want to upgrade?"
      />
      <h4>Payment plans available from R1500 per month</h4>
      <button className="button" onClick={upgradePage}>
        Upgrade to Fullstack
      </button>
      <button className="" onClick={nextPage}>
        No thanks
      </button>
    </div>
  );
}

export default Recommend