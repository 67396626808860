import { memo } from "react";
import { FaArrowLeft, FaArrowRight, FaClone } from "react-icons/fa";
import { ItemListReproduction } from "../VideoPlayerControls.styled";
import useVideoStore from "zustand-store/videoPlayerStore";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const Playlist = ({
  showReproductionList,
  setShowReproductionList,
  sublistMode,
  goBackToMainList,
  listTitle,
  listReproduction,
  list,
  openSublist,
  listRedirect,
  unitList,
  currentUnitId,
  currentLectureId,
  completedLectures,
}) => {
  // console.log("PLAYLIST RE-RENDERED");
  const videoMode = useVideoStore((state) => state.videoMode);
  const setShowControlsContainer = useVideoStore(
    (state) => state.setShowControlsContainer
  );

  return (
    <div
      className="item-control"
      onMouseLeave={() => {
        videoMode === "challenge" && setShowControlsContainer(false);
        setShowReproductionList(false);
      }}
    >
      {showReproductionList && (
        <ItemListReproduction>
          <div>
            <div className="title">
              {sublistMode && <FaArrowLeft onClick={goBackToMainList} />}
              <span>{listTitle}</span>
            </div>
            <div
              ref={listReproduction}
              className="list-list-reproduction scroll-clean-player"
            >
              {list.map((item, index) => (
                <div
                  key={index}
                  className={`item-list-reproduction ${
                    (item._id === currentUnitId ||
                      item._id === currentLectureId) &&
                    "selected"
                  }`}
                  onClick={() => {
                    !sublistMode ? openSublist(item) : listRedirect(item);
                  }}
                >
                  <div className="item-list-row">
                    <div className="d-flex align-items-center">
                      {sublistMode ? (
                        completedLectures?.includes(item._id) ? (
                          <div className="me-1">
                            <IoMdCheckmarkCircleOutline color={"green"} />
                          </div>
                        ) : (
                          <div className="me-1">
                            <IoMdCheckmarkCircleOutline color={"gray"} />
                          </div>
                        )
                      ) : item?.lecture?.filter((lecture) =>
                          completedLectures?.includes(lecture._id)
                        ).length === item?.lecture?.length ? (
                        <div className="me-1">
                          <IoMdCheckmarkCircleOutline color={"green"} />
                        </div>
                      ) : (
                        <div className="me-1">
                          <IoMdCheckmarkCircleOutline color={"gray"} />
                        </div>
                      )}
                      <span style={{ marginRight: 15 }}>
                        {!sublistMode ? item.unitname : item.lecturename}
                        <br />
                        {!sublistMode ? (
                          <small style={{ opacity: "0.5" }}>
                            {
                              item.lecture.filter((lecture) =>
                                completedLectures.includes(lecture._id)
                              ).length
                            }
                            /{item?.lecture?.length || 0} | {item.unitduration}{" "}
                            mins
                          </small>
                        ) : (
                          <small>
                            <span
                              style={{ opacity: "0.5", marginRight: "10px" }}
                            >
                              {item.duration} mins
                            </span>
                            <span className="badge success-btn cursor">
                              {item?.type}
                            </span>
                            {/* | {item?.checkpoint.length} code */}
                            {/* pushes */}
                          </small>
                        )}
                      </span>
                    </div>
                    {!sublistMode && <FaArrowRight />}
                  </div>
                </div>
              ))}
              {/* <button onClick={handleOpenChallenge}>challenge</button> */}
            </div>
          </div>
          <div className="box-connector" />
        </ItemListReproduction>
      )}
      {unitList && unitList.length > 1 && (
        <FaClone
          onMouseEnter={() => {
            videoMode === "challenge" && setShowControlsContainer(true);
            setShowReproductionList(true);
          }}
        />
      )}
    </div>
  );
};

export default memo(Playlist, (prevProps, nextProps) => {
  if (
    prevProps.showReproductionList !== nextProps.showReproductionList ||
    prevProps.sublistMode !== nextProps.sublistMode ||
    prevProps.listTitle !== nextProps.listTitle ||
    prevProps.listReproduction !== nextProps.listReproduction ||
    prevProps.currentUnitId !== nextProps.currentUnitId ||
    prevProps.currentLectureId !== nextProps.currentLectureId ||
    prevProps.completedLectures.length !== nextProps.completedLectures.length
  ) {
    return false;
  }
  return true;
});
