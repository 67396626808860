import lectureService from "actions/services/lecture.service";
import {
  BsLayoutSidebarInset,
  BsReverseLayoutSidebarInsetReverse,
} from "react-icons/bs";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import useAssignmentStore from "zustand-store/assignmentStore";
function AdminIdeNav({
  hideSandboxList = false,
  showSandboxList,
  setShowSandboxList,
  setShowVideosList,
  showVideosList,
  currentCourse,
  editMode,
  setEditMode,
  currentVideo,
  courseid,
  lectureid,
  unitid,
  setSelectedSandbox,
}) {
  const queryClient = useQueryClient();
  const history = useHistory();
  // const currentVideo = queryClient.getQueryData("ideCurrentVideo");

  const adminCourseQuestion = useAssignmentStore(
    (state) => state.adminCourseQuestion
  );
  const adminQuestionMode = useAssignmentStore(
    (state) => state.adminQuestionMode
  );
  const setAdminQuestionMode = useAssignmentStore(
    (state) => state.setAdminQuestionMode
  );
  const deleteLectureMutation = useMutation(
    () => lectureService.deleteLecture(adminCourseQuestion?._id),
    {
      onSuccess: (data) => {
        console.log("submitChallengeMutation...", data);
        alert("DELETED LECTURE CHALLENGE...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  );

  const handleDeleteLecture = () => {
    alert("ABOUT TO DELETE, REFRESH PAGE TO STOP THIS");
    deleteLectureMutation.mutate();
  };
  return (
    <div
      className={`admin-ide-nav ${editMode ? "admin-ide-nav-edit-mode" : ""}`}
    >
      {" "}
      {!hideSandboxList && (
        <span
          className={`user-select-none admin-ide-btn ${
            showSandboxList ? "admin-ide-btn-active" : ""
          }`}
          onClick={() => setShowSandboxList(!showSandboxList)}
        >
          <BsLayoutSidebarInset className="cursor" />
          <span className="font-small">Sandboxes</span>
        </span>
      )}
      <div className="admin-ide-center">
        <h6>
          {currentCourse?.coursename} - {currentVideo?.lecturename}
        </h6>
        <div>
          <span
            className="badge bg-light text-black"
            onClick={() => {
              setSelectedSandbox(null);
              setEditMode(false);
            }}
          >
            Selecting lecture will create MCQ/Code challenge after the selected
            lecture
          </span>
          <span
            className="badge bg-warning text-black"
            onClick={() => {
              setSelectedSandbox(null);
              setEditMode(false);
            }}
            style={{ marginLeft: "10px" }}
          >
            {adminQuestionMode}
          </span>
        </div>
      </div>
      <div className="flex items-center">
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-course-coursemcq");
          }}
        >
          + MCQ
        </span>
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-course-challenge");
          }}
        >
          + Code
        </span>

        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-course-backend");
          }}
        >
          + Backend
        </span>
        
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-course-frontend");
          }}
        >
          + FE
        </span>
        
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-course-ds");
          }}
        >
          + DS
        </span>
        
        <span
          className="btn-primary cursor m-2 p-2 rounded"
          onClick={() => {
            setAdminQuestionMode("create-assignment");
            history.push(
              `/admin-assignment/${courseid}/${unitid}/${lectureid}`
            );
          }}
        >
          + Assignment
        </span>

        <span className="btn danger-btn m-2" onClick={handleDeleteLecture}>
          DELETE LECTURE/CHALLENGE
        </span>
        <span
          className={`admin-ide-btn ${
            showVideosList ? "admin-ide-btn-active" : ""
          }`}
          onClick={() => setShowVideosList(!showVideosList)}
        >
          <BsReverseLayoutSidebarInsetReverse className="cursor" />
          <span className="text-base">Videos</span>
        </span>
      </div>
    </div>
  );
}

export default AdminIdeNav;
