function Timelines({ startdate, enddate }) {
  return (
    <div>
      <p>Start Date: {startdate}</p>
      <p>End Date: {enddate}</p>

      <em>
        All deadlines are on the end date, the end time will be at the same time
        you start. The competition organizers reserve the right to update the
        contest timeline if they deem it necessary.
      </em>
    </div>
  );
}

export default Timelines;
