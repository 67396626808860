import { VideoPreLoading } from "./VideoPreLoad.styled";
import { FiX } from "react-icons/fi";
import { memo } from "react";

const VideoPreLoad = ({ videoReady, error, title, subTitle, onCrossClick }) => {
  // console.log("VIDEOPRELOAD Rendered");
  return (
    <VideoPreLoading
      backgroundColorHoverButtonError="#f78b28"
      colorHoverButtonError="#ddd"
      colorButtonError="#ddd"
      backgroundColorButtonError="#333"
      colorTitle="#fff"
      colorSubTitle="#fff"
      colorIcon="#fff"
      show={videoReady === false || (videoReady === true && error)}
      // show={true}
      showError={!!error}
    >
      {(title || subTitle) && (
        <div className="zpheader">
          <div>
            <h2 className="zph2">{subTitle}</h2>
            <h1 className="zph1">{title}</h1>
          </div>
          <FiX onClick={onCrossClick} />
        </div>
      )}

      {/* <div className="zp-outer-section">
          {error && (
            <div>
              <h1>{error}</h1>
              {qualities.length > 1 && (
                <div>
                  <p>Try accessing other quality</p>
                  <div className="links-error">
                    {qualities.map((item) => (
                      <div onClick={() => onChangeQuality(item.id)}>
                        {item.prefix && <span>HD</span>}
                        <span>{item.nome}</span>
                        {item.playing && <FiX />}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div> */}
    </VideoPreLoading>
  );
};

export default memo(VideoPreLoad);
