import { UserContext } from "context/UserProvider";
import { useContext, useEffect, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { useHistory } from "react-router-dom";

const MonthAndYear = ({
  month,
  year,
  handlePreviousMonth,
  handleNextMonth,
  tasks,
  onlyCalendar,
}) => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [headerConfig, setHeaderConfig] = useState({
    loading: true,
  });

  const calcTodaysTasks = () => {
    setHeaderConfig({
      loading: true,
    });

    const currDate = new Date().toDateString();
    // const currDate = new Date("2023-03-29T06:27:32.128Z").toDateString();

    const todaysTasks = tasks?.filter(
      (item) => new Date(item?.date).toDateString() === currDate
    );

    if (todaysTasks?.length > 0) {
      setHeaderConfig({
        isTasks: true,
        totalTasks: todaysTasks[0]?.tasks?.length,
        tasksCompleted: todaysTasks[0]?.tasks?.filter((task) =>
          ["late", "completed", "advanced"]?.includes(task.status)
        )?.length,
        loading: false,
      });
    } else {
      setHeaderConfig({
        isTasks: false,
        loading: false,
      });
    }
  };

  useEffect(() => {
    calcTodaysTasks();
  }, []);

  return (
    <div className="flex w-100 justify-between items-center py-2">
      {!headerConfig?.loading ? (
        headerConfig?.isTasks ? (
          <div className="ms-4">
            {!onlyCalendar && (
              <p className="text-xl font-semibold leading-none">
                Hello, {user.username}
              </p>
            )}
            <p className="text-md font-semibold leading-none mt-2">
              {headerConfig?.tasksCompleted} / {headerConfig?.totalTasks} tasks
              completed for the day
            </p>
          </div>
        ) : (
          <p className="text-md font-semibold leading-none mt-2 ms-4">
            You have no tasks for today.
          </p>
        )
      ) : (
        <p className="text-md font-semibold leading-none mt-2 ms-4">Loading</p>
      )}
      <div className="d-flex">
        <AiOutlineCalendar size={28} />
        <button onClick={handlePreviousMonth}>
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        <h2 className="text-2xl font-bold leading-none mx-2">
          {month}, {year}
        </h2>

        <button onClick={handleNextMonth}>
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
      <div className="me-4">
        <p className="text-md font-semibold leading-none">
          {user?.pendingassignments
            ? "New Assignment out!"
            : "Stay on track with your assignments"}
        </p>
        <p
          className="text-xl font-semibold leading-none mt-2 cursor-pointer"
          onClick={() => history.push("/assignments")}
        >
          View Assignments
        </p>
      </div>
    </div>
  );
};

export default MonthAndYear;
