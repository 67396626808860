import { UserContext } from "context/UserProvider";
import { useContext } from "react";
import { Modal, Form } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";

import "./Modals.css";
function CustomModal({
  title = false,
  description,
  show,
  handleOnHide,
  videoUrl,
  CustomComponent,
  props,
}) {
  // console.log("PROPS FROM CUSTOM MDOAL", props);
  const history = useHistory();
  const { user } = useContext(UserContext);


  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleOnHide}
      centered
      contentClassName={`modal-with-video-container ${
        !videoUrl ? "modal-with-video-center" : ""
      }`}
    >
      <div className="left-container p-3">
        {(title || description) && (
          <>
            {title && (
              <h2 className="mediumText mb-3 reel-modal-title">{title}</h2>
            )}
            {description && (
              <Form className="text-center">
                <Form.Group className="">{description}</Form.Group>
              </Form>
            )}
          </>
        )}

        {CustomComponent ? (
          <CustomComponent handleOnHide={handleOnHide} {...props} />
        ) : (
          <>
            {!(user && user.success) && (
              <button
                className=" mt-4 btn btn-block btn-main"
                onClick={() => {
                  handleOnHide();
                  history.push("/getstarted");
                }}
              >
                Signup with Google or Normal method
              </button>
            )}
          </>
        )}
      </div>

      {videoUrl && (
        <div className="right-container bg-dark">
          <ReactPlayer
            id={"continuLearning"}
            // ref={videoRef}
            // className="video__player"
            url={videoUrl}
            width={"400px"}
            height={"300px"}
            playsinline={true}
            loop={true}
            volume={0}
            muted={true}
          />
        </div>
      )}
      {/* <Modal.Body
        className="p-5"
        style={{ paddingTop: "0px", textAlign: "center" }}
      >
        <h2 className="mediumText mb-3 reel-modal-title">{title} New modal</h2>
        <Form>
          <Form.Group className="">{description}</Form.Group>
        </Form>
        
      </Modal.Body> */}
    </Modal>
  );
}

export default CustomModal;
