// refer to onboardingObject.js - same object with few updates
import Signup from "./Signup";
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
import setOnboardingObject from "components/QuizReelComponents/Video/OnboardingObject";
const videoBasedOnboardingData = {
  ...setOnboardingObject(),
};
const textBasedOnboardingData = {
  ...videoBasedOnboardingData,
  goal: {
    percentageCompleted: "0",
    // component: Signup,
    title: "Hey there 👋 Akhil here!",
    text: "Let me get to know you. Why do you want to learn coding?",
    options: [
      { ...videoBasedOnboardingData.goal[0], nextstep: "getAJob" },
      { ...videoBasedOnboardingData.goal[1], nextstep: "sideHustle" },
      { ...videoBasedOnboardingData.goal[2], nextstep: "AsASkill" },
    ],
  },
  getAJob: {
    disabledOptionClick: true,
    percentageCompleted: "20",
    title: "💪 Great choice!",
    text: "Here is what you can achieve",
    previousStep: "goal",
    viewOnlyNextStep: "havePC",
    options: [
      {
        text: "🔥 Code with confidence",
        textDescription: "200+ lessons from professional developers",
        nextstep: "havePC",
      },
      {
        text: "💼 Build a portfolio of projects",
        textDescription: "that you can carry to your first interview",
        nextstep: "havePC",
      },
      {
        text: "🧠 Develop a learning habit",
        textDescription: "and complete your journey with reminders and quizzes",
        nextstep: "havePC",
      },
    ],
  },
  sideHustle: {
    disabledOptionClick: true,
    title: "💪 Great choice!",
    percentageCompleted: "20",
    text: "Here is what you can achieve",
    previousStep: "goal",
    viewOnlyNextStep: "havePC",
    options: [
      {
        text: "🔥 Code with confidence",
        textDescription: "200+ lessons from professional developers",
        nextstep: "havePC",
      },
      {
        text: "💼 Build a portfolio of projects",
        textDescription: "that you can showcase to land your first client",
        nextstep: "havePC",
      },
      {
        text: "🧠 Develop a learning habit",
        textDescription: "and complete your journey with reminders and quizzes",
        nextstep: "havePC",
      },
    ],
  },
  AsASkill: {
    disabledOptionClick: true,
    title: "💪 Great choice!",
    percentageCompleted: "20",
    text: "Here is what you can achieve",
    previousStep: "goal",
    viewOnlyNextStep: "havePC",
    options: [
      {
        text: "🔥 Code with confidence",
        textDescription: "200+ lessons from professional developers",
        nextstep: "havePC",
      },
      {
        text: "💼 Build a portfolio of projects",
        textDescription: "to showcase your skills",
        nextstep: "havePC",
      },
      {
        text: "🧠 Develop a learning habit",
        textDescription: "and complete your journey with reminders and quizzes",
        nextstep: "havePC",
      },
    ],
  },
  havePC: {
    title: "👊 Let me help you get started!",
    percentageCompleted: "40",
    text: "Do you have a PC?",
    previousStep: "goal",
    options: [
      { ...videoBasedOnboardingData.havePC[0], nextstep: "isComfortable" },
      {
        ...videoBasedOnboardingData.havePC[1],
        nextstep: "selectingMobileCourseOption",
      },
    ],
  },
  isComfortable: {
    percentageCompleted: "60",
    title: "Thank you for that information.",
    text: "How comfortable are you using a PC?",
    previousStep: "havePC",
    options: [
      {
        ...videoBasedOnboardingData.isComfortable[0],
        nextstep: "digitalLiteracy",
      },
      {
        ...videoBasedOnboardingData.isComfortable[1],
        nextstep: "selectingCourseOption",
      },
    ],
  },
  selectingMobileCourseOption: {
    percentageCompleted: "60",
    title: "Did you know you can code from your phone?",
    text: "We made this possible 💪. Select a short tik-tok style course from below - Its FREE!",
    previousStep: "havePC",
    options: [
      {
        ...videoBasedOnboardingData.selectingMobileCourseOption[0],
        nextstep: "setDailyGoal",
        textDescription:
          "Learn about what coding is, the job prospects and whether its a good fit for you.",
      },
      {
        ...videoBasedOnboardingData.selectingMobileCourseOption[1],
        nextstep: "setDailyGoal",
        textDescription:
          "Start coding your own virtual card and share it with your friends and family.",
      },
    ],
  },
  digitalLiteracy: {
    percentageCompleted: "80",
    title: "We got your back.",
    text: "Get comfortable with a PC with this free course!",
    previousStep: "havePC",
    options: [
      {
        ...videoBasedOnboardingData.digitalLiteracy[0],
        nextstep: "setDailyGoal",
        textDescription:
          "Get upto speed with all the tools and tricks of using a computer to prepare you to code.",
      },
    ],
  },
  selectingCourseOption: {
    percentageCompleted: "80",
    title: "Thats great!",
    text: "Where do you want to start? (it's FREE)",
    previousStep: "havePC",
    options: [
      {
        ...videoBasedOnboardingData.selectingCourseOption[0],
        nextstep: "setDailyGoal",
        textDescription:
          "Learn about what coding is, the job prospects and whether its a good fit for you.",
      },
      {
        ...videoBasedOnboardingData.selectingCourseOption[1],
        nextstep: "setDailyGoal",
        textDescription:
          "Start coding your own virtual card and share it with your friends and family.",
      },
    ],
  },

  setDailyGoal: {
    percentageCompleted: "90",
    title: "I am so excited for you to start your coding journey.",
    text: "Let's set a daily goal so you can receive reminders.",
    previousStep: "havePC",
    options: [
      {
        text: "15 minutes/day",
        textDescription: "Casual",
        nextstep: "signup",
      },
      {
        text: "30 minutes/day",
        textDescription: "Regular",
        nextstep: "signup",
      },
      {
        text: "45 minutes/day",
        textDescription: "Serious",
        nextstep: "signup",
      },
      {
        text: "60 minutes/day",
        textDescription: "Intense",
        nextstep: "signup",
      },
    ],
  },
  signup: {
    lastStep: true,
    component: Signup,
    percentageCompleted: "95",
    title: "Awesome 🎊 Let's get you in!",
    text: "An AI powered, high success personalized experience is what you deserve ❤️🤗💯",
    previousStep: "setDailyGoal",
    options: [
      {
        text: "Getting you in",
        textDescription: "IN",
        // nextstep: "havePC",
      },
    ],
  },
  signup: {
    lastStep: true,
    component: Signup,
    percentageCompleted: "95",
    title: "Awesome 🎊 Let's save your account",
    text: "and get you the best learning experience ❤️🤗💯",
    previousStep: "setDailyGoal",
    options: [
      {
        text: "Getting you in",
        textDescription: "IN",
        // nextstep: "havePC",
      },
    ],
  },
};

export default textBasedOnboardingData;
