import styles from "./LearningPath.module.scss";
import { DiHtml5, DiCss3, DiReact } from "react-icons/di";
import { BsDot } from "react-icons/bs";
import classes from "./../../component/Accordion/Accordion.module.css";

export default function LearningPath({
  path,
  handleSelectLearningPath,
  clickDisabled,
  summaryPage,
  isPublic,
}) {
  console.log("path: ", path);
  return (
    <div
      onClick={() => !clickDisabled && handleSelectLearningPath(path)}
      className={
        summaryPage
          ? "col-sm-12 mt-2 addon_text"
          : "col-sm-12 col-md-12 col-lg-6 mt-2 addon_text"
      }
    >
      <div className="m-2 shdow">
        <div>
          <div className={path?.index == 2 ? styles.card : styles.card2}>
            <div className={`d-flex justify-content-between align-items-end`}>
              <div className={styles.lpTop}>
                <span className={styles.free}>
                  {/* <h5 className={styles.courseNbr}>{pop? "Popular  ":""}{nbrCourse} courses</h5>
                   */}
                  <h5 className={`${styles.courseNbr} apercuProText font_14`}>
                    {path?.index == 2 ? (
                      <>
                        <span>Popular</span>
                        <BsDot size={30} />
                      </>
                    ) : (
                      ""
                    )}
                    {path && path.learningpathcourses.length} courses
                  </h5>
                  <div className="row">
                    <div
                      className={`${classes.iconsandTitle} ${styles.iconsandTitle}`}
                    >
                      <div className={classes.wrapper}>
                        <a
                          href="#"
                          className={`${classes.icon} ${classes.html}`}
                        >
                          <DiHtml5 />
                        </a>
                        <a
                          href="#"
                          className={`${classes.icon} ${classes.css}`}
                        >
                          <i className="fab fa-instagram"></i>
                          <DiCss3 />
                        </a>
                        <a
                          href="#"
                          className={`${classes.icon} ${classes.react}`}
                        >
                          <i className="fab fa-google-plus-g"></i>
                          <DiReact />
                        </a>
                        <a
                          href="#"
                          className={`${classes.icon} ${classes.plusNbr}`}
                        >
                          <i className="fab fa-google-plus-g"></i>
                          <span className="normalText font_10">+5</span>
                        </a>
                      </div>
                    </div>

                    {/* {courses.slice(0, 3).map((val, key) => <img
                        key={key}
                        src={val.coursethumbnailurl}
                        alt="Course"
                        className={styles.elipse}
                      />
                    )} */}
                    {/* <div className={styles.elipse}>
                        <p className='text-black-50 text-weight-bold'>+{5}</p>
                    </div> */}
                  </div>
                </span>
                <h2 className={styles.heading}>{path.learningpathname}</h2>
              </div>
              <div className={styles.imgbox}>
                <p className="link-main h1 text-weight-bold">{path.code}</p>
              </div>
            </div>
            <div className={styles.border}>
              <p className="normalText font_14 mt-2 col-xs-12 col-sm-8 col-md-8">
                {/* Learn the basics of coding and web development. Use this plan to
                see if coding is for you. */}
                {path.text}
              </p>
            </div>

            <div className="pt-2">
              <div className="d-flex">
                <div className="col-xs-12 col-sm-7">
                  {path.index == 1 ? (
                    <button
                      className="btn btn-sm m-1"
                      style={{ color: "#17325b", backgroundColor: "#F4F4F8" }}
                    >
                      FREE
                    </button>
                  ) : null}
                  {path.index !== 1 ? (
                    <>
                      <button
                        className="btn btn-sm m-1"
                        style={{ backgroundColor: "#F4F4F8" }}
                      >
                        <span className="normalText font_14 normalDarkBlue">
                          Zaio AI
                        </span>
                      </button>
                      <button
                        className="btn btn-sm m-1"
                        style={{
                          backgroundColor: "#F4F4F8",
                        }}
                      >
                        <span className="normalText font_14 normalDarkBlue">
                          Personalized Quizzes
                        </span>
                      </button>
                    </>
                  ) : null}
                </div>
                <div className="d-flex ms-xs-1 ms-md-3 ms-lg-4 col-xs-12 col-sm-4">
                  <h4 className={`${styles.price}`}>
                    R
                    {Math.ceil(path.learningpathprice -
                      (path.learningpathprice * path.learningpathdiscount) /
                        100)}
                  </h4>
                  <span className="normalText font_14 w-50 ms-3 ">
                    {path.index == 1
                      ? " Try this course for free"
                      : " Starts from R1500 p/m"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
