import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ErrorCard } from "./Error.styled";
import { UserContext } from "context/UserProvider";

function Error({ message }) {
  const history = useHistory();
  const { user } = useContext(UserContext);
  return (
    <ErrorCard>
      <h3>Whoopsss!</h3>
      <p>
        Here is the error: <br /> <small>{message}</small>
      </p>
      {user && user?.success ? (
        <button
          className="btn btn-main btn-block"
          onClick={() => history.push("/updated-dashboard")}
        >
          Go to dashboard
        </button>
      ) : (
        <button
          className="btn btn-main btn-block"
          onClick={() => history.push("/logout")}
        >
          Login again
        </button>
      )}
    </ErrorCard>
  );
}

export default Error;
