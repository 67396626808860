import ZCoin from "components/ZCoin/ZCoin";
import "./InviteLink.css";

function EarnContainer() {
  return (
    <div className="invite-link-container mt-3">
      <h3>Earn</h3>
      <div>
        <p className="d-flex m-3 align-items-center">
          <ZCoin
            number={50}
            toolTipText="Earn 50 Z-Coin as soon as your friend signs up."
          />
          <span>upon friend signup.</span>
        </p>

        <p className="d-flex text-center m-3 align-items-center">
          <ZCoin
            number={1}
            toolTipText="Earn 2 Z-Coins in total, 1 for your friend watching, and 1 for you."
            toolTipPlacement="bottom"
          />
          <span>per video watched.</span>
        </p>
      </div>
    </div>
  );
}

export default EarnContainer;
