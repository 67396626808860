import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Payment.css";
import authService from "actions/services/auth.service";
import learningpathService from "actions/services/learningpath.service";
import ReelModal from "components/QuizReelComponents/ReelModal/ReelModal";
import Redirect from "components/QuizReelComponents/ReelModal/Redirect";
import { IoMdReturnLeft } from "react-icons/io";
import { paymentEventTracking } from "Utils/WebEngage";
import { PaymentDetailsContext } from "context/PaymentDetailsProvider";

let successRedirect = "/app/onboarding/questions";

function Payments() {
  const location = useLocation();
  const queries = new URLSearchParams(location.search);
  const { details, setDetails } = useContext(PaymentDetailsContext);
  const [countdown, setCountdown] = useState(10);
  const [learningpath, setLearningPath] = useState(queries.get("learningpath"));
  const [reference, setReference] = useState(queries.get("reference"));
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    title: "HELO HELO",
    description: "",
    CustomComponent: null,
    props: null,
  });

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      title: "",
      description: "",
      CustomComponent: null,
      props: null,
    });
  };

  useEffect(() => {
    const makePayment = async () => {
      setLoading(true);

      try {
        const queryParamArray = location.search.split("=");
        const lpid = queryParamArray[1].slice(
          0,
          queryParamArray[1].indexOf("&")
        );
        const paymentModeId = queries.get('paymentModeId').split('?')[0];


        const ref = queryParamArray[queryParamArray.length - 1];
        console.log("PAYMENTS DATA", ref, lpid);
        const data = await authService
          .payStackPayment(ref)
          .then(async (response) => {
            if (response.data.status) {
              paymentEventTracking('Payment Successful', response.data)
              setLoading(false);
              setLoading(true);
              await authService.updatePaymentDetails(paymentModeId);
              await learningpathService.enrollLearningPath(lpid).then((res) => {
                setModal({
                  ...modal,
                  show: true,
                  title: "Welcome to Zaio!",
                  description: "You will be redirected in a moment...",
                  CustomComponent: Redirect,
                  props: {
                    // redirectUrl: "/app/updated-dashboard",
                    redirectUrl: successRedirect,
                    timer: 5,
                  },
                });

                setLoading(false);
                return res;
              });
            } else {
              console.log("ERROR");
              paymentEventTracking('Payment Failure', response.data)
              setModal({
                ...modal,
                show: true,
                title:
                  "Something went wrong with the payment. Perhaps try again?",
                description: "You will be redirected in a moment...",
                CustomComponent: Redirect,
                props: {
                  // redirectUrl: "/app/choose-path",
                  redirectUrl: "/app/onboarding/payments",
                  timer: 5,
                },
              });
            }
          });
      } catch (e) {
        console.log("e", e);
        paymentEventTracking('Payment Failure', {})
        setModal({
          ...modal,
          show: true,
          title: "Something went wrong with the payment. Perhaps try again?",
          description: "You will be redirected in a moment...",
          CustomComponent: Redirect,
          props: {
            // redirectUrl: "/app/choose-path",
            redirectUrl: "/app/onboarding/payments",
            timer: 5,
          },
        });
      }
    };

    makePayment();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (countdown > 0) setCountdown(countdown - 1);
    }, 1000);
  }, [countdown]);
  return (
    <div className="payment-container">
      {modal.show ? (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          CustomComponent={modal.CustomComponent ?? false}
          props={modal.props ?? false}
        />
      ) : null}

      <h1>🔥🚀</h1>
      <h1>A step away from getting empowered</h1>
      <p className="mt-2">
        Hold tight while we wrap things up with payments...
      </p>

      {countdown === 0 ? (
        <button
          className="m-2 btn btn-block btn-main p-2"
          onClick={() => window.location.reload()}
        >
          Click here
        </button>
      ) : (
        <p>
          <i>If you are not redirected, click here in {countdown} seconds</i>
        </p>
      )}

      {/* {loading && countdown > 0 ? (
        <p>loading...</p>
      ) : (
        <p><i>Refresh the page if you see this.</i></p>
      )} */}
    </div>
  );
}

export default Payments;
