import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import adminService from "actions/services/admin.service";

const TwoColumnDisplayItem = ({ users, paid, setmodalIsOpen, setCid }) => {
  console.log(users);
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const onNoteClick = (user) => {
    setmodalIsOpen(true);
    setCid(user._id);
  };
  const handleFilterActive = () => {};
  const handleDelete = () => {
    adminService.deleteUser(selectedUser.email)
      .then(res => {
        console.log(res)
        handleClose()
        // window.location.reload();
      })
      .catch(err => console.log(err))
    
  };
  const handleShow = (user) => {
    setShow(true);
    setSelectedUser(user)
  } 
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are about to delete <strong>{selectedUser.name}, {selectedUser.email}</strong>. Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <table className="table table-striped">
        <thead>
          {paid ? (
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Number</th>
              <th scope="col">Email</th>
              <th scope="col">Learning path</th>
              <th scope="col" onClick={handleFilterActive}>
                Active
              </th>
              <th scope="col">Videos watched</th>
              <th scope="col">Latest activity</th>
              <th scope="col">Certificates</th>
              <th scope="col">Actions</th>
            </tr>
          ) : (
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone number</th>
              {/* <th scope="col">Note</th> */}
            </tr>
          )}
        </thead>
        <tbody>
          {paid
            ? users.map((user) => (
                <tr>
                  <td>{user.name}</td>
                  <td>{user.number}</td>
                  <td>{user.email}</td>
                  <td>{user.learningpathname}</td>
                  <td>{user.active ? "Yes" : "No"}</td>
                  <td>{user.numberofvideoswatched}</td>
                  <td>{user.latestactivitydate}</td>
                  <td>
                    {user.latestcertificate === "None" ? (
                      "None"
                    ) : (
                      <a
                        className="badge badge-pill badge-primary"
                        href={user.latestcertificate?.certificateurl}
                        target="_blank"
                      >
                        {user.latestcertificate.coursename}
                      </a>
                    )}
                  </td>
                  <td onClick={() => handleShow(user)}>❌</td>
                </tr>
              ))
            : users.map((user) => (
                <tr>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.phonenumber}</td>
                  {/* <td onClick={() => onNoteClick(user)}>{user.note}</td> */}
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
};

export default TwoColumnDisplayItem;
