import { loadIcon } from "components/IDE/ideUtils";

const FileExplorerH = ({ data, handleSelectProjectFile, currentFile }) => {
  const sortFolders = (folders) => {
    return folders.sort((a, b) =>
      a.name.localeCompare(b.name, "en", { sensitivity: "base" })
    );
  };

  const renderItems = (items, currentFile, handleSelectProjectFile) => {
    const rootFolder = {
      name: "/",
      children: [],
    };
    const rootFiles = [];

    // Build the folder structure
    items?.forEach((item) => {
      const { path, name, key } = item;
      const pathItems = path.split("/").filter((item) => item !== "");

      if (pathItems.length === 0) {
        rootFiles.push({ name, key });
      } else {
        let currentFolder = rootFolder;
        pathItems?.forEach((folderName) => {
          if (!currentFolder.children) {
            currentFolder.children = [];
          }

          let folder = currentFolder.children.find(
            (child) => child.name === folderName
          );

          if (!folder) {
            folder = {
              name: folderName,
              children: [],
            };
            currentFolder.children.push(folder);
            sortFolders(currentFolder.children);
          }

          currentFolder = folder;
        });

        currentFolder.children.push({name, key });
        sortFolders(currentFolder.children);
      }
    });

    // Recursively render the folder structure
    const renderFolder = (folder) => {
      return (
        <div key={folder.name}>
          <div className="text-danger ms-2 my-1">
            {folder.name === "/" ? "" : folder.name + "/"}
          </div>
          {folder.children &&
            folder.children.map((child) => {
              if (child.children) {
                return renderFolder(child);
              } else {
                return (
                  <div
                    onClick={() => {
                      handleSelectProjectFile(child);
                    }}
                    className={`project-file-btn ${
                      child?.key === currentFile && "selected"
                    }`}
                    key={child.name}
                  >
                    {loadIcon(child.name)}
                    <code>{child.name}</code>
                  </div>
                );
              }
            })}
        </div>
      );
    };

    return (
      <>
        {rootFiles.length > 0 && (
          <div>
            {rootFiles.map((file) => (
              <div
                onClick={() => {
                  handleSelectProjectFile(file);
                }}
                key={file.name}
                className={`project-file-btn ${
                  file?.key === currentFile && "selected"
                }`}
              >
                {loadIcon(file.name)}
                <code>{file.name}</code>
              </div>
            ))}
          </div>
        )}
        {renderFolder(rootFolder)}
      </>
    );
  };

  return <div>{renderItems(data, currentFile, handleSelectProjectFile)}</div>;
};

const FileExplorer = ({
  projectFiles,
  handleSelectProjectFile,
  currentFile,
}) => {
  const data = projectFiles
    ? Object.keys(projectFiles)?.map((key) => ({ key, ...projectFiles[key] }))
    : [];
  return (
    <div className="text-white">
      <FileExplorerH
        data={data}
        handleSelectProjectFile={handleSelectProjectFile}
        currentFile={currentFile}
      />
    </div>
  );
};

export default FileExplorer;
