import Course from "component/Accordion/Course";

function ZaioCourses({ courses }) {
  return (
    <>
      <h1>Zaio Courses</h1>
      <p>
        These are courses that will help you brush up your skills whenever you
        are stuck, and these will appear in your profile too that will help with
        job referrals. <br />
        <mark>Try do these if you can.</mark>
      </p>
      <div className="d-flex flex-wrap">
        {courses && courses.map((course, index) => <Course key={index} course={course} />)}
      </div>
    </>
  );
}

export default ZaioCourses;
