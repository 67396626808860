import React, { useContext, useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import TextLoop from "react-text-loop";
import { LoadingContext } from "context/LoadingProvider";
import "./NextCourse.css";
import courseService from "actions/services/course.service";
import { useHistory, useParams } from "react-router-dom";

const NextCourse = () => {
  const history = useHistory();
  const { courseid } = useParams();
  const [visible, setVisible] = useState(false);
  const { setLoading, isloading } = useContext(LoadingContext);
  const [course, setCourse] = useState(null);
  useEffect(() => {
    const getCourse = async () => {
      const course = await courseService.getCourse(courseid);
      console.log(course);
      setCourse(course.data);
      if (course.success) {
        setLoading(false);
      }
    };
    getCourse();
  }, []);
  setInterval(() => {
    setVisible(true);
  }, 5000);

  const watch = () => {
    setLoading(true);
    history.push(
      `/watch/${courseid}/${course.courseunit[0]._id}/${course.courseunit[0].lecture[0]._id}`
    );
  };

  if (isloading) return <div></div>;
  return (
    <div className="next-course-container">
      <BarLoader color="#f9c80e" width="40vw" />
      <TextLoop noWrap={false} className="text" interval={7000}>
        <span style={{ textAlign: "center" }}>
          Preparing your next course {course?.coursename}
        </span>
        <span style={{ textAlign: "center" }}>
          In this course you will be doing {course?.coursedescription}
        </span>
        <span style={{ textAlign: "center" }}>
          You will be building {course?.whythiscourse}
        </span>
      </TextLoop>
      <button
        className="button"
        onClick={watch}
        style={{ visibility: !visible && "hidden" }}
      >
        Start now
      </button>
    </div>
  );
};

export default NextCourse;
