import { useEffect, useState } from "react";
import { Circle } from "rc-progress";
import "./Redirect.css";
import setOnboardingObject from "../Video/OnboardingObject";

function Redirect({ redirectUrl, courseInfo, text, timer = 10 }) {
  const [countdown, setCountdown] = useState(timer);
  const [digitalLiteracyText, setDigitalLiteracyText] = useState(
    setOnboardingObject().digitalLiteracy[0].text
  );
  // const [,] = useState(
  //   setOnboardingObject().digitalLiteracy[0].text
  // );

  useEffect(() => {
    // console.log("digitalLiteracyText", courseInfo, digitalLiteracyText, text);
    setTimeout(() => {
      if (countdown > 0) setCountdown(countdown - 1);
    }, 1000);
    if (countdown === 0) {
      window.location.href = redirectUrl;
    }
  }, [countdown]);
  return (
    <div className="redirect-container">
      {courseInfo && (
        <div>
          <div className="redirect-container__course-info">
            <h4>Expected level Outcomes</h4>
            <ul className="redirect-container__list">
              {courseInfo.ELOs.map((elo) => (
                <li>{elo}</li>
              ))}
            </ul>
          </div>
          <div className="redirect-container__course-info">
            <h4>For you if</h4>
            <ul className="redirect-container__list">
              {courseInfo.forYouIf.map((fyi) => (
                <li>{fyi}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="redirect-container__circle">
        {/* <p>You will be redirected to the relevant course shortly</p> */}
        <div className="circle">
          <Circle
            strokeWidth={4}
            trailWidth={4}
            percent={100 - parseInt((countdown / 15) * 100)}
            strokeColor="#8556f8"
          />

          <div className="score" style={{ color: "black" }}>
            {countdown}
          </div>
        </div>
        {digitalLiteracyText === text ? (
          <button
            className="m-2 btn btn-block btn-main"
            onClick={() =>
              (window.location.href =
                process.env.REACT_APP_FRONTEND_URL + "/app/getstarted")
            }
          >
            Signup to access this course
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default Redirect;
