import courseService from "actions/services/course.service";
import Error from "components/Error/Error";
import Loader from "components/Loader/Loader";
import { ListGroup } from "react-bootstrap";
import { BsArrowUpRightSquareFill } from "react-icons/bs";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import useAssignmentStore from "zustand-store/assignmentStore";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

function AdminVideosList({
  courseid,
  lectureid,
  unitid,
  setCurrentVideo,
  currentVideo,
  setCurrentCourse,
  setQuestionType,
}) {
  //queries
  const { isLoading, isError, error, data } = useQuery(
    "videoCourseList",
    () => courseService.getCourse(courseid),
    {
      onSuccess: (data) => {
        setCurrentCourse(data?.data);
      },
    }
  );
  const history = useHistory();

  const setAdminCourseQuestion = useAssignmentStore(
    (state) => state.setAdminCourseQuestion
  );
  const setAdminQuestionMode = useAssignmentStore(
    (state) => state.setAdminQuestionMode
  );

  const handleOpenWatch = (event) => {
    event.stopPropagation();
    window.open(
      `${FRONTEND_URL}/app/watch/${currentVideo?.course}/${currentVideo?.courseunit}/${currentVideo?._id}`,
      "_blank"
    );
  };

  if (isLoading)
    return (
      <div className="admin-sandbox-sidebar admin-videos-list">
        <Loader loadDirect />
      </div>
    );

  if (isError)
    return (
      <div className="admin-sandbox-sidebar admin-videos-list">
        <Error message={JSON.stringify(error)} />
      </div>
    );

  const courseunit = data?.data?.courseunit || null;
  return (
    <div className="admin-sandbox-sidebar admin-videos-list">
      <Accordion preExpanded={[unitid]} allowMultipleExpanded>
        {courseunit &&
          courseunit.map((unit, unitIndex) => (
            <AccordionItem key={unitIndex} uuid={unit?._id}>
              <AccordionItemHeading>
                <AccordionItemButton>{unit?.unitname}</AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel className="m-0">
                {unit?.lecture.map((lec, lecIndex) => (
                  <ListGroup
                    key={lecIndex}
                    style={{ borderRadius: 0, margin: 0 }}
                    onClick={() => {
                      setCurrentVideo(lec);
                      if (lec?.type === "coursemcq") {
                        setAdminQuestionMode("edit-course-coursemcq");
                      } else if (lec?.type === "challenge") {
                        setAdminQuestionMode("edit-course-challenge");
                      } else if (lec?.type === "backendchallenge") {
                        setAdminQuestionMode("edit-course-backend");
                      } else if (lec?.type === "frontendchallenge") {
                        setAdminQuestionMode("edit-course-frontend");
                      } else if (lec?.type === "datascience") {
                        setAdminQuestionMode("edit-course-ds");
                      } else {
                        setAdminQuestionMode("lecture");
                      }
                      setAdminCourseQuestion(lec);
                      const redirectRoute =
                        history?.location?.pathname?.split("/")?.[1] ===
                        "admin-cvcs"
                          ? "admin-cvcs"
                          : "admin-ide";
                      history.push(
                        `/${redirectRoute}/${lec?.course}/${lec?.courseunit}/${lec?._id}`
                      );

                      // selectVideo(lec, unitIndex, lecIndex);
                    }}
                    defaultActiveKey={lectureid}
                  >
                    <ListGroup.Item
                      action
                      eventKey={lec?._id}
                      active={lec?._id === lectureid}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <small>
                            {lec?.lecturename}{" "}
                            <span className="badge success-btn cursor">
                              {lec?.type}
                            </span>
                          </small>
                        </div>
                        <div>
                          <span
                            className="p-2 admin-ide-btn m-2"
                            onClick={handleOpenWatch}
                          >
                            <BsArrowUpRightSquareFill />
                          </span>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </div>
  );
}

export default AdminVideosList;
