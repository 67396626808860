import React from "react";

function JobEligibility({ job, forModal }) {
  return (
    <div>
      <p className="mb-3">
        Try {" "}
        <span
          className={`eligible-icon yellow-tag`}
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_FRONTEND_URL}/app/tutor-support`,
              "_blank"
            )
          }
        >
          Book a Tutor
        </span>{" "}
        for advise or complete the following checkpoint courses to become eligible for this
        job:
      </p>
      <div className="d-flex justify-content-center">
        {job.simulationcoursesneeded.map((course) => (
          <div
            className={`eligible-icon ${
              forModal ? "purple-tag" : "tech-tag"
            } m-2`}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_FRONTEND_URL}/app/course/${course.coursename}/${course._id}`,
                "_blank"
              )
            }
          >
            {course.coursename}
          </div>
        ))}
      </div>
    </div>
  );
}

export default JobEligibility;
