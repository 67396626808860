import { isSameDay, format } from "date-fns";
import Event from "./Event";
import startOfDay from "date-fns/startOfDay";
import { useContext } from "react";
import { TasksContext } from "context/TasksProvider";
import { normalizeDate, StylesConfig } from "Utils/Bootcamp";

const CUT_TASKS_LEN = 4;

const DayCard = ({ date, events, status, goals }) => {
  const { setShowModal, setTasks } = useContext(TasksContext);
  //Extracts month in long format from date object
  const month = format(date, "MMMM");

  //Extracts day from date object
  const day = date.getDate();

  //Checks if current day matches date
  const sameDayCheck = isSameDay(startOfDay(date), new Date());

  // Sort events by startAt property
  let sortedEvents = [...events].sort(
    (a, b) => new Date(a.startAt) - new Date(b.startAt)
  );
  const classes = StylesConfig[status]?.styles || "bg-white";
  const color = StylesConfig[status]?.color || "";

  // Find if the date belongs to a goal
  const goalForDate = goals?.find(
    (goal) =>
      normalizeDate(goal.goalStartDate) <= date &&
      normalizeDate(goal.goalEndDate) >= date
  );
  const isGoalStart = goals?.some(
    (goal) => normalizeDate(goal.goalStartDate) === normalizeDate(date)
  );
  const isGoalEnd = goals?.some(
    (goal) => normalizeDate(goal.goalEndDate) === normalizeDate(date)
  );

  const borderStyleString = "3px dashed #000000";

  // Define border styles dynamically based on position within a goal
  let borderStyles = {};
  if (goalForDate) {
    if (isGoalStart) {
      borderStyles = {
        borderTop: borderStyleString,
        borderLeft: borderStyleString,
        borderBottom: borderStyleString,
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
      };
    } else if (isGoalEnd) {
      borderStyles = {
        borderTop: borderStyleString,
        borderBottom: borderStyleString,
        borderRight: borderStyleString,
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
      };
    } else {
      borderStyles = {
        borderTop: borderStyleString,
        borderBottom: borderStyleString,
      };
    }
  }

  return (
    <div
      className={`relative flex flex-col group w-100 ${
        sameDayCheck ? "border-[2px] border-purple-300" : ""
      } ${classes}`}
      style={borderStyles}
    >
      <div className="flex justify-content-between">
        <div
          className={`d-flex block px-2 py-1 text-sm font-semibold ${
            sameDayCheck && "bg-teal-light"
          }`}
        >
          {day} {month} {isGoalStart && <p className="m-0 ml-1 text-cprimary">(Goal: {goalForDate?.goalName})</p>}
        </div>
        {status === "advanced" && <div className="me-2">🎉</div>}
        
      </div>

      <div className="flex flex-col px-1 py-1">
        {sortedEvents.slice(0, CUT_TASKS_LEN).map((event, i) => (
          <>
            <Event color={color} event={event} key={i} />
            {i === CUT_TASKS_LEN - 1 &&
              sortedEvents?.length > CUT_TASKS_LEN && (
                <button
                  onClick={() => {
                    setShowModal(true);
                    setTasks(sortedEvents);
                  }}
                  className="font-semibold text-sm text-left mt-1 text-indigo-700"
                >
                  + {sortedEvents?.length - CUT_TASKS_LEN} Tasks
                </button>
              )}
          </>
        ))}
      </div>
    </div>
  );
};

export default DayCard;
