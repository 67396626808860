function isBrowsingFromPhone() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "Safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Edge";
  } else if (userAgent.match(/android/i)) {
    browserName = "Android";
  } else if (userAgent.match(/iphone/i)) {
    browserName = "iPhone";
  } else {
    browserName = "Unknown";
  }

  if (navigator.userAgent.match(/Android/i)) {
    // console.log("You are browsing on ANDROID with: " + browserName + "");
    return true;
  } else if (navigator.userAgent.match(/iPhone/i)) {
    // console.log("You are browsing on IOS with: " + browserName + "");
    return true;
  }
  {
    // console.log("You are browsing on WEB with: " + browserName + "");
    return false;
  }
}

export default isBrowsingFromPhone;
