import React from "react";
import "./SignupProcess.css";
import AuthService from "actions/services/auth.service";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "context/UserProvider";
import { LoadingContext } from "context/LoadingProvider";
import axios from "axios";
import GoogleLogin from "react-google-login";
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import gmail from "../../../assets/img/socials/google.svg";
import linkedin from "../../../assets/img/socials/linkedin.svg";
import googleLoginIcon from "assets/img/dashboard/googleLoginIcon.svg";

import { Mixpanel } from "Utils/Mixpanel";
import ChatBubble from "pages/DashBoard/ChatBubble";

const API_URL = process.env.REACT_APP_BACKEND_URL;

function ValidateEmail(inputText) {
  var mailformat = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export const SignupProcess = () => {
  const [page, setPage] = useState(1);
  const [init, setInit] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);

  const history = useHistory();
  const queries = new URLSearchParams(history.location.search);
  const [inviteCode, setInviteCode] = useState(queries.get("code"));

  const [state, setState] = useState({
    username: "",
    email: "",
    password: "",
    success: !!user,
    message: "",
    redirect: queries.get("redirect") || "/dashboard",
    src: "zaio.io",
    coursein: "na",
    heardfrom: "select",
    phonenumber: "",
    goal: "",
    currentStatus: "",
    commitment: "",
    dob: new Date(1997, 8, 28),
    terms: false,
  });

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/get-started",
      "Page name": "Sign up Page",
    });
  }, []);

  const onChangeState = (e, prop) => {
    if (prop === "terms") {
      setState({ ...state, terms: e.target.checked });
      console.log(e.target.checked);
    } else {
      setState({ ...state, [prop]: e.target.value });
    }
  };

  const onSelectPhoneNumber = (phone) => {
    console.log(phone);
    setState({ ...state, phonenumber: phone });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setState({
      ...state,
      message: "",
      successful: false,
    });
    setLoading(true);

    AuthService.register(state.email, state.password, state.src, inviteCode)
      .then((response) => {
        // alert(response.message);
        if (response.success) {
          setState({
            ...state,
            success: true,
            message: response.message,
            redirect: "dashboard",
          });
          Mixpanel.alias(state.email); // set alias for user
          Mixpanel.people.set({
            $email: state.email,
            $distinct_id: state.email,
            $status: "free",
            $expirement: "retention",
          });
          Mixpanel.track("Successful sign up", {
            method: "normal",
            distinct_id: state.email,
          });
          setUser({ ...response, ...response.data, enrolled: false });
          if (!response.data.username) {
            history.push("/completesignin");
          }
        } else {
          const resMessage = response.message;
          console.log(state);
          // Mixpanel.track("Unsuccessful sign up");
          setState({
            ...state,
            successful: false,
            message: resMessage,
          });
        }
      })
      .catch((reject) => console.log("somethingwentwrong", reject))
      .then(() => setLoading(false));
  };

  const LoadPage = () => {
    if (page === 1)
      return (
        <Page1
          setPage={setPage}
          setInit={setInit}
          handleRegister={handleRegister}
          onChangeState={onChangeState}
          state={state}
          setState={setState}
          user={user}
          setUser={setUser}
          onSelectPhoneNumber={onSelectPhoneNumber}
        />
      );
    else if (page === 7) {
      return (
        <Page7
          setPage={setPage}
          setInit={setInit}
          handleRegister={handleRegister}
          onChangeState={onChangeState}
          state={state}
          onSelectPhoneNumber={onSelectPhoneNumber}
        />
      );
    }
    // else if (page === 2) {
    //   return <Page2 setPage={setPage} onSelectGoal={onSelectGoal} />;
    // } else if (page === 3)
    //   return <Page3 setPage={setPage} onCurrentStatus={onCurrentStatus} />;
    // else if (page === 6)
    //   return (
    //     <Page6
    //       setPage={setPage}
    //       state={state}
    //       onSelectDate={onSelectDate}
    //       onChangeState={onChangeState}
    //       handleRegister={handleRegister}
    //       message={state.message}
    //       setState={setState}
    //       handleUpdate={handleUpdate}
    //     />
    //   );
    setPage(1);
  };

  return (
    <React.Fragment>
      {/* Facebook Pixel Code  */}
      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '578796853193199');
          fbq('track', 'PageView');
          `}
      </script>
      <noscript>
        <img
          height={1}
          width={1}
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=578796853193199&ev=PageView&noscript=1"
        />
      </noscript>
      {/* End Facebook Pixel Code */}

      <div>{LoadPage()}</div>
    </React.Fragment>
  );
};

const Page1 = ({
  setPage,
  setInit,
  state,
  setState,
  user,
  setUser,
  message,
  onChangeState,
  onSelectPhoneNumber,
  handleRegister,
  onChangeTerms,
}) => {
  const [error, setError] = useState("");
  const { setLoading } = useContext(LoadingContext);
  // const [state, setState] = useState({
  //   success: !!user,
  //   message: '',
  //   redirect: queries.get('redirect') || '/dashboard',
  //   src: 'zaio.io',
  // });
  const history = useHistory();
  const onClickOption = (e) => {
    e.preventDefault();
    setError("");
    //state.username == "" ||
    if (!ValidateEmail(state.email) || state.password === "") {
      setError("Please enter all fields above correctly to continue");
    } else if (state.terms === false) {
      setError("Please agree to terms of use to continue");
    } else {
      console.log("e");
      console.log(e);
      handleRegister(e);
    }
  };

  const responseSuccessGoogle = (response) => {
    setLoading(true);
    AuthService.googleLogin(response.tokenId)
      .then((response) => {
        //mixpanel

        if (response.success) {
          console.log("GOOGLE LOGIN", response);
          Mixpanel.alias(response?.data?.email);
          // mixpanel.identify(response?.data?.email);
          Mixpanel.people.set({
            $email: response?.data?.email,
            $distinct_id: response?.data?.email,
            $status: "free",
            $expirement: "retention",
          });
          Mixpanel.track("Successful sign up", { method: "google" });

          if (!response.alreadyRegister) {
            history.push("/completesignin");
          }
          if (response.data.enrolled) {
            setUser({ ...user, ...response.data });
            history.push("/updated-dashboard");
          }
          if (response.data.isadmin) {
            history.push("/admin/paid-users");
          } else if (response.data.username) {
            history.push("/dashboard");
          }
          setState({
            ...state,
            success: true,
            message: response.message,
          });
          setUser({ ...response, ...response.data });
        } else {
          const resMessage = response.message;
          setState({
            ...state,
            successful: false,
            message: resMessage,
          });
        }
      })
      .catch((reject) => alert("somethingwentwrong"))
      .then(() => setLoading(false));
  };

  const responseErrorGoogle = (response) => {
    console.log(response);
  };

  return (
    <div className="page-container">
      <div className="m-3 page-form-container">
        {/* <ChatBubble
          title={"currentQuestion.title"}
          text={"currentQuestion.text"}
          showLive={false}
        /> */}
        <form
          id="1"
          className="signup-form col-12 col-md-6 col-lg-4 border-4 p-4 rounded border-primary bg-white"
          onSubmit={onClickOption}
        >
          <h3 className="d-flex justify-content-center mediumBlackText mt-4">
            Get Started quick quick
          </h3>
          <p className="d-flex justify-content-center text-center">
            Build industry experience &amp; get hired. <br />
            No coding experience required.
          </p>
          <GoogleLogin
            clientId="26179719531-0i4v58bc6afmg2b2pjvlg1bd443e55u0.apps.googleusercontent.com"
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="w-100 btn-lg googleLoginButton"
              >
                <span className="googleLoginIcon">
                  <img src={googleLoginIcon} alt="" />
                </span>
                Sign Up with Google
              </button>
            )}
            buttonText="Login"
            onSuccess={responseSuccessGoogle}
            onFailure={responseErrorGoogle}
            cookiePolicy={"single_host_origin"}
            // className="w-100 btn-lg googleLoginButton"
          />
          <div className="login-heading-container">
            <hr className="dashed" />
            <p className="p">Sign up with Email</p>
            <hr className="dashed" />
          </div>
          {/* <div className="social-signin pt-4">
            <div className="gmail row no-gutters p-2">
              <div className="p-2 bg-white">
                <img src={gmail} width={16} alt="gmail"></img>
              </div>
              <div className="d-flex link-social" >
                <p className="text-white m-1">Sign in with Google</p>
              </div>
            </div>

            <div className="linkdln row no-gutters p-2 mt-3">
              <div className="p-2 bg-white">
                <img src={linkedin} width={16} alt=""></img>
              </div>
              <div className="d-flex link-social" >
                <p className="text-white m-1">Sign in with LinkedIn</p>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <p className="separation"> <p className="m-2">or sign up with email</p> </p>
          </div> */}

          <div className="form-group mt-3">
            <label className="apercuProText font_16 pb-2 pt-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              className="form-control form-control-lg"
              name="email"
              placeholder="email@address.com"
              value={state.email}
              onChange={(e) => onChangeState(e, "email")}
              required
            />
          </div>
          <div className="form-group mt-3 pb-2">
            <label
              className="apercuProText font_16 pb-2 pt-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              className="form-control form-control-lg"
              name="password"
              placeholder="**"
              value={state.password}
              onChange={(e) => onChangeState(e, "password")}
              required
            />
          </div>
          <div className="d-flex no-gutters align-items-center">
            <input
              type="checkbox"
              id="zaio-terms"
              onChange={(e) => onChangeState(e, "terms")}
            />
            <label
              htmlFor="zaio-terms"
              className="ms-2 normalTextColorGrey normalText font_12"
            >
              Agree to Terms of Use and Privacy Policy{" "}
            </label>
          </div>
          <div className="form-group mt-4">
            {error && <p className="error">{error}</p>}
            {state.message && <p className="error">{state.message}</p>}
            <button
              className="btn btn-main w-100 btn-lg"
              type="submit"
              onClick={onClickOption}
            >
              Continue
            </button>
          </div>

          <div className="mt-1 d-flex no-gutters justify-content-center normalText font_14">
            <p className="">
              Already a user?{" "}
              <Link
                className="text link-main text-underline font-weight-bold"
                to={`/login`}
              >
                Log In
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

const Page7 = ({
  setPage,
  setInit,
  state,
  message,
  onChangeState,
  onSelectPhoneNumber,
  handleRegister,
}) => {
  const [error, setError] = useState("");
  const onClickOption = (e) => {
    e.preventDefault();
    //
    if (state.username == "" || state.phonenumber == "") {
      setError("Please enter all fields above correctly to continue");
    } else {
      console.log(state);
    }
  };

  return (
    <div className="page-container">
      <div className="">
        <form
          id="1"
          className="signup-form col-12 col-md-6 col-lg-4 border-4 rounded border-primary"
          onSubmit={onClickOption}
        >
          <div className="sup-page3-heading mt-2">
            Enter your personal details
          </div>
          <div className="form-group mt-4">
            <label htmlFor="username">Full Name</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="username"
              value={state.username}
              onChange={(e) => onChangeState(e, "username")}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Mobile Number</label>
            <PhoneInput
              country={"za"}
              preferredCountries={[
                "za",
                "ng",
                "eg",
                "bw",
                "ke",
                "gb",
                "gh",
                "mu",
                "mw",
              ]}
              placeholder="+27 763 793 540"
              value={state.phonenumber}
              onChange={(phone) => onSelectPhoneNumber(phone)}
              inputStyle={{ width: "100%" }}
              inputProps={{
                name: "phonenumber",
                required: true,
              }}
            />
          </div>

          <div className="form-group mt-4">
            {error && <p className="error">{error}</p>}
            {state.message && <p className="error">{state.message}</p>}
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              onClick={onClickOption}
            >
              Get Started
            </button>
          </div>

          <div className=" mt-1 row no-gutters justify-content-around">
            <p>
              <Link
                className="text text-success font-weight-bold"
                to={`/getstarted`}
              >
                Back to signup
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

// const Page2 = ({ setPage, onSelectGoal }) => {
//   const onClickOption = (e) => {
//     onSelectGoal(e);
//     setPage(3);
//   };
//   return (
//     <div className="page-container">
//       <div className="sup-page3-heading">What is your end goal?</div>

//       <div className="sup-page3-options" onClick={onClickOption}>
//         Become a freelancer - work at my pace and be my own boss. Start with
//         R15-20k/month
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         Get a full-time job as a developer - a secure contract starting
//         R20-30k/month
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         Build my own app ideas
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         Any of the above
//       </div>
//     </div>
//   );
// };

// const Page3 = ({ setPage, onCurrentStatus }) => {
//   const onClickOption = (e) => {
//     onCurrentStatus(e);
//     setPage(6);
//   };
//   return (
//     <div className="page-container">
//       <div className="sup-page3-heading">What is your current status?</div>

//       <div className="sup-page3-options" onClick={onClickOption}>
//         Already employed, in IT field, but looking to upgrade my skills
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         Already employed, but not in IT field, and looking to upgrade my skills
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         Student looking to secure my future
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         Self employeed looking to join IT field
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         Unemployed, and looking to secure a job in IT
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         Other
//       </div>
//     </div>
//   );
// };
// const Page4 = ({ setPage, onSelectCommitment }) => {
//   const onClickOption = (e) => {
//     onSelectCommitment(e);
//     setPage(6);
//   };
//   return (
//     <div className="page-container">
//       <div className="sup-page3-heading">
//         It's self paced, but how much time can you commit a day?
//       </div>

//       <div className="sup-page3-options" onClick={onClickOption}>
//         1 hour a day (7 hours/week)
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         2 hours a day (Recommended - 14 hours/week)
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         4 hours a day (21 hours/week)
//       </div>

//       <div className="sup-page3-meta mt-5 pt-5">
//         Dont worry. You can change this in your Profile later.
//       </div>
//     </div>
//   );
// };
// const Page5 = ({ setPage }) => {
//   const onClickOption = () => {
//     setPage(6);
//   };
//   return (
//     <div className="page-container">
//       <div className="sup-page3-heading">How old are you?</div>

//       <div className="sup-page3-options" onClick={onClickOption}>
//         13 - 18
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         19 - 24
//       </div>

//       <div className="sup-page3-options" onClick={onClickOption}>
//         25 - 34
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         35 - 44
//       </div>
//       <div className="sup-page3-options" onClick={onClickOption}>
//         Older than 45
//       </div>
//     </div>
//   );
// };

// const Page6 = ({ setPage, state, onSelectDate, onChangeState, handleRegister, message, setState, handleUpdate }) => {

//   const onClickOption = (e) => {
//     console.log(state);
//     handleUpdate(e)
//   };
//   return (
//     <div className="page-container">
//       <div className="email-form">
//         <div className="sup-page3-heading">Okay, wrapping up now.</div>
//         <form id="2" className="form" onSubmit={onClickOption}>

//           <div className="form-group">
//             <label>Where did you find us?</label>
//             <select
//               className="form-control"
//               value={state.heardfrom}
//               onChange={(e) => onChangeState(e, "heardfrom")}
//             >
//               <option selected value="select">
//                 Click to select
//               </option>
//               <option value="facebook">Facebook</option>
//               <option value="instagram">Instagram</option>
//               <option value="tiktok">TikTok</option>
//               <option value="wordofmouth">Word of mouth</option>
//               <option value="uber">Uber/Bolt/Didi/Taxi</option>
//               <option value="other">Other</option>
//             </select>
//           </div>

//           <p className="signup-finetext mt-1">
//             By signing up for Zaio, you agree to Zaio's Terms of Service &
//             Privacy Policy.
//           </p>

//           <label htmlFor="blueinmail" className="signup-finetext mt-1 mb-2">
//             {" "}
//             <input
//               type="checkbox"
//               id="blueinmail"
//               name="blueinmail"
//               value="true"
//             />{" "}
//             I agree to receive promotional and marketing material
//           </label>
//           <div className="form-group">

//             <button
//               className="btn btn-primary btn-block"
//               type="submit"
//               onClick={onClickOption}
//             >
//               Start Coding
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };
