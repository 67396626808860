import React from 'react'
import "./Note.css"
function Note() {
  return (
    <div className='note'>
        <p>Title</p>
        <p>Note</p>
    </div>
  )
}

export default Note