import axios from "axios";
import authHeader from "./auth-header";

// const API_URL = 'https://zaio-dev.herokuapp.com'
const API_URL = process.env.REACT_APP_BACKEND_URL;
// const API_URL = 'http://localhost:5000'

const testimonial = {
    postTestimonial(data) {
        return axios
            .post(API_URL + "/testimonial", data)
            .then((res) => {
                //   console.log(res.data);
                return res.data;
            })
            .catch((rej) => {
                // console.log(rej.data);
                return rej;
            });
    },

    getTestimonial() {
        return axios
            .get(API_URL + "/testimonial")
            .then((res) => {
                //   console.log(res.data);
                return res.data;
            })
            .catch((rej) => {
                // console.log(rej.data);
                return rej;
            });
    },

};

export default testimonial;
