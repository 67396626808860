import { LoadingContext } from "context/LoadingProvider";
import { UserContext } from "context/UserProvider";
import { useContext, useState } from "react";
import GoogleLogin from "react-google-login";
import { Mixpanel } from "Utils/Mixpanel";
import googleLoginIcon from "assets/img/dashboard/googleLoginIcon.svg";
import AuthService from "actions/services/auth.service";
import OnboardingFooter from "./OnboardingFooter";
import { SfxContext } from "context/SfxContext";
import { useHistory } from "react-router-dom";

function Signup({ setShowSetup, showSetup, handleCompleteLastStep }) {
  console.log("SIGNUP", setShowSetup, showSetup);
  const { setLoading } = useContext(LoadingContext);
  const { user, setUser } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");

  const [state, setState] = useState({
    success: !!user,
    message: "",
  });

  const { clickSfx, hoverSfx } = useContext(SfxContext);
  const history = useHistory();

  function ValidateEmail(inputText) {
    var mailformat = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  const responseSuccessGoogle = (response) => {
    setLoading(true);
    AuthService.googleLogin(response.tokenId)
      .then((response) => {
        //mixpanel
        if (response.success) {
          setShowSetup(true);
          console.log("GOOGLE LOGIN", response);
          // alert("google login", response?.data.toString());
          Mixpanel.alias(response?.data?.email);
          Mixpanel.people.set({
            $email: response?.data?.email,
            $distinct_id: response?.data?.email,
            $name: username,
            $status: "free",
            $expirement: "retention",
          });
          Mixpanel.track("Text onboarding Successful sign up", {
            method: "google",
          });

          // setShowSetup(true);
          setState({
            ...state,
            success: true,
            message: response.message,
          });
          setUser({ ...response, ...response.data });
          handleCompleteLastStep();
        } else {
          const resMessage = response.message;
          setState({
            ...state,
            successful: false,
            message: resMessage,
          });
        }
      })
      .catch((reject) => alert("somethingwentwrong"))
      .then(() => setLoading(false));
  };
  const responseErrorGoogle = (response) => {
    console.log(response);
  };

  const onClickOption = (e) => {
    e.preventDefault();
    hoverSfx();
    setError("");
    //state.username == "" ||
    if (!ValidateEmail(email) || password === "") {
      setError("Please enter all fields above correctly to continue");
    } else if (state.terms === false) {
      setError("Please agree to terms of use to continue");
    } else {
      console.log("e");
      console.log(e);
      handleRegister(e);
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setState({
      ...state,
      message: "",
      successful: false,
    });
    setLoading(true);

    AuthService.register(email, password, username, "zaio.io", "")
      .then((response) => {
        // alert(email, password, username, "zaio.io", "");
        if (response.success) {
          setState({
            ...state,
            success: true,
            message: response?.message,
          });
          Mixpanel.alias(email); // set alias for user
          Mixpanel.people.set({
            $email: email,
            $distinct_id: email,
            $name: username,
            $status: "free",
            $expirement: "retention",
          });
          Mixpanel.track("Text onboarding Successful sign up", {
            method: "normal",
            distinct_id: email,
          });
          setUser({ ...response, ...response?.data, enrolled: false });
          handleCompleteLastStep();
          //   if (!response?.data?.username) {
          //     history.push("/completesignin");
          //   }

          setShowSetup(true);
        } else {
          const resMessage = response?.message;
          console.log(state);
          // Mixpanel.track("Unsuccessful sign up");
          setState({
            ...state,
            successful: false,
            message: resMessage,
          });
        }
      })
      .catch((reject) => console.log("somethingwentwrong", reject))
      .then(() => setLoading(false));
  };

  return (
    <div className="onb-question p-3">
      <GoogleLogin
        clientId="26179719531-0i4v58bc6afmg2b2pjvlg1bd443e55u0.apps.googleusercontent.com"
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="w-100 btn-lg googleLoginButton"
          >
            <span className="googleLoginIcon">
              <img src={googleLoginIcon} alt="" />
            </span>
            Complete with Google
          </button>
        )}
        buttonText="Login"
        onSuccess={responseSuccessGoogle}
        onFailure={responseErrorGoogle}
        cookiePolicy={"single_host_origin"}
      />
      <div className="login-heading-container">
        <hr className="dashed text-white" />
        {/* <p className="p text-white">Or Email 👇</p> */}
        <p className="p text-white">Email 👇</p>
        <hr className="dashed text-white" />
      </div>

      <div className="">
        <label className="" htmlFor="name">
          Name
        </label>
        <input
          type="text"
          className="form-control form-control-lg"
          name="name"
          placeholder="Name"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>
      <div className="">
        <label className="" htmlFor="email">
          Email
        </label>
        <input
          type="email"
          className="form-control form-control-lg"
          name="email"
          placeholder="email@address.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="">
        <label className="" htmlFor="password">
          Password
        </label>
        <input
          type="password"
          className="form-control form-control-lg"
          name="password"
          placeholder="*****"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>

      <div className="mt-3">
        {error && <p className="error">{error}</p>}
        {state?.message && <p className="error">{state?.message}</p>}
      </div>
      <OnboardingFooter
        handleContinue={onClickOption}
        customButtonName="Complete ✅"
        customButtonClass="onb-footer-complete-btn"
        customClassName="onb-footer-sticky"
      />
    </div>
  );
}

export default Signup;
