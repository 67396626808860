import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import demographic from "../../actions/services/demographic.service";
import { UserContext } from "context/UserProvider";
import { useParams } from 'react-router';


const RadioCommon = ({
  values,
  //   handleChange,
  name,
  register,
  watch,
  getValues,
  setValue,
}) => {
  return (
    <>
      <label className="text-xl p-2">{values.label}</label>
      {values.options.map((item) => (
        <div
        // onChange={(e) => handleChange(e, "citizen")}
        >
          <input
            type="radio"
            value={item}
            name={name}
            className="mr-3"
            required
            {...register(`${name}`)}
          />
          {item}
          {item === "others" && watch(`${name}`) === "others" && (
            <input
              type={Number}
              style={{ padding: "5px", border: "1px solid", marginLeft: "10px" }}
              {...register(`${name}InputText`)}
            />
          )}
        </div>
      ))}
    </>
  );
};

const Questions = ({ profile }) => {
  const { register, getValues, setValue, handleSubmit, watch, reset } = useForm();
  const { userProfile, isProfileCompleted ,setIsProfileCompleted, isDemographicsCompleted, setIsDemographicsCompleted } = useContext(UserContext)

  const userData = {
    citizen: {
      label: "Are you South African citizen?",
      options: ["yes", "no"],
    },
    gender: {
      label: "Gender",
      options: ["male", "female", "others"],
    },
    phoneNo: "",
    school: {
      label: "What is the highest school grade you have achieved?",
      options: ["Matric", "Tertiary Diploma", "Bachelors Degree", "Postgraduate Degree"],
    },
    universityName: "",
    courseName: {
      label: "If you went to a university give us the name of the degree you have achieved (e.g Bsc Computer Science) if your option does not appear below, please choose other option",
      options: ["I did not go to university", "BSc Computer Science", "BScEng Computer Engineering", "others"],
    },
    homeLanguage: {
      label: "Home Language",
      options: ["Afrikaans", "English", "Ndebele", "Sepedi", "Sesotho", "Swati", "Tsonga", "Tswana", "Venda", "Xhosa", "Zulu", "others"],
    },
    secondLanguage: {
      label: "Second Language",
      options: ["Afrikaans", "English", "Ndebele", "Sepedi", "Sesotho", "Swati", "Tsonga", "Tswana", "Venda", "Xhosa", "Zulu", "none", "others"],
    },
    race: {
      label: "Race",
      options: ["Black", "Coloured", "Indian", "Asian", "White", "Prefer not to say", "others"],
    },
    salary: {
      label: "Currently, my personal monthly salary (before tax) is:",
      options: ["Unemployed - Not earning a salary or wage", "Less than R 2000", "R2 000 - R4 000", "R4 000 - R6 000", "More than R6 000", "others"],
    },
    disability: {
      label: "Disability",
      options: ["yes", "no"],
    },
  };

  //   const [formDatas, setFormDatas] = useState();

  const {
    citizen,
    gender,
    school,
    courseName,
    homeLanguage,
    secondLanguage,
    race,
    salary,
    disability,
  } = userData;

  const formDataRefine = (formData) => {
    const keys = Object.keys(formData);
    keys.forEach((key) => {
      if (formData[key] === "others")
        formData[key] = getValues(`${key}InputText`);
    });
    return formData;
  };

  const onSubmit = async (e) => {

    const {
      citizen,
      gender,
      school,
      courseName,
      homeLanguage,
      secondLanguage,
      race,
      phoneNo,
      salary,
      disability,
      universityName,
    } = formDataRefine(getValues());
    const _data = {
      sa_citizen: citizen,
      gender: gender,
      phone_number: phoneNo,
      highest_school_grade: school,
      university_name: universityName,
      university_degree: courseName,
      home_language: homeLanguage,
      second_language: secondLanguage,
      race: race,
      monthly_salary: salary,
      disability: disability,
    };
    const data = await demographic.postDemographic(_data);
    if (data.success) {
      alert("Thanks for your response!")
    } else {
      alert("Your Data is already exists in our data base.")
    }

  };

  let { email } = useParams();

  useEffect(() => {
    try {
      const fetchSavedDemographic = async () => {
        try {
          const response = await demographic.getDemographic(email);
          const temp = response.data[0];
          const data = {
            citizen: temp?.sa_citizen,
            gender: temp?.gender,
            phoneNo: temp?.phone_number,
            school: temp?.highest_school_grade,
            universityName: temp?.university_name,
            courseName: temp?.university_degree,
            homeLanguage: temp?.home_language,
            secondLanguage: temp?.second_language,
            race: temp?.race,
            salary: temp?.monthly_salary,
            disability: temp?.disability,
          };
          
          reset({ ...data });
          checkProfileCompletion(data);
        } catch (error) {
        }
      };
      fetchSavedDemographic();
    } catch (error) {
    }
  }, [isProfileCompleted]);

  const checkProfileCompletion = (demographicData) => {
    for(let key in demographicData){
      if(!demographicData[key]){
        setIsProfileCompleted(false);
        setIsDemographicsCompleted(false)
        return;
      }
    }
    setIsDemographicsCompleted(true)
    const { data } = userProfile;
    for(let key in data){
      if(key!=='image' && key!=='lastActive' && !data[key]){
        setIsProfileCompleted(false);
        return;
      }
    }
    setIsProfileCompleted(true)
  }

  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "20px" }}>
      <RadioCommon
        watch={watch}
        name="citizen"
        values={citizen}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <RadioCommon
        watch={watch}
        name="gender"
        values={gender}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <label className="text-xl">Cellphone number</label>
      <br />
      <input
        type="text"
        name="phoneNo"
        style={{ padding: "5px", border: "1px solid" }}
        required
        // onChange={(e) => handleChange(e, "phoneNo")}
        {...register(`phoneNo`)}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="school"
        values={school}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <label className="text-xl">University Name:</label>
      <br />
      <input
        name="universityName"
        style={{ padding: "5px", border: "1px solid" }}
        required
        type="text"
        // onChange={(e) => handleChange(e, "universityName")}
        {...register(`universityName`)}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="courseName"
        values={courseName}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="homeLanguage"
        values={homeLanguage}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="secondLanguage"
        values={secondLanguage}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="race"
        values={race}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="salary"
        values={salary}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="disability"
        values={disability}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <div className="text-center">
        {profile && !isDemographicsCompleted ? <button className="btn btn-primary" onClick={handleSubmit}>
          Submit
        </button> : null
        }

      </div>
    </form>
    </div>
  );
};

export default Questions;
