export const getQueryParameters = (query) => {
  const obj = {};
  const queryArr = query?.replace("?", "")?.split("&");

  queryArr?.forEach((item) => {
    const splitted = item?.split("=");
    obj[splitted[0]] = splitted[1];
  });

  return obj;
};
