import React, { useContext, useEffect, useRef, useState } from "react";
import "./ideFrontendUser.scss";
// import "./main";
import Editor, { useMonaco } from "@monaco-editor/react";
import { emmetHTML, emmetCSS, expandAbbreviation } from "emmet-monaco-es";
import { compileAndRun } from "./console";
import ConsoleOutput from "./ConsoleOutput";

import "luna-object-viewer/luna-object-viewer.css";
import "luna-data-grid/luna-data-grid.css";
import "luna-dom-viewer/luna-dom-viewer.css";
import "luna-console/luna-console.css";
import IframeCode from "./PreviewTerminal";

import { DiHtml5, DiCss3, DiJsBadge } from "react-icons/di";
import {
  BsLayoutSplit,
  BsLayoutThreeColumns,
  BsArrowsFullscreen,
} from "react-icons/bs";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Split from "react-split";
import { Nav, Tab } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { FaShare } from "react-icons/fa";
import useEditorStore from "zustand-store/editorStore";
import LogoNav from "assets/svg/Logonav.svg";
import { BsFillCircleFill } from "react-icons/bs";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { UserContext } from "context/UserProvider";

// import console from "./LunaConsole";

// const container = document.getElementById("luna-container");
// var console = new LunaConsole(container);
// console.log("luna");

function IdeFrontendUser({
  // onCtrlS,
  lectureId,
  preLoadedProjectFiles,
  handleSubmitSolution,
  handleTestSolution,
  challengeTestFrontendMutation,
  challengeSubmitFrontendMutation,
  assignment,
  assignmentTestFrontendMutation,
  assignmentSubmitFrontendMutation,
  onProjectFilesUpdate = undefined,
  feedback,
  setFeedback,
  saveFrontendCodeFeedbackMutation,
  saveAssignmentFrontendCodeFeedbackMutation,
  setSyncSubmissions,
}) {
  const monacoRef = useRef(null);
  const editorRef = useRef(null);

  const [isEditorReady, setIsEditorReady] = useState(false);
  const [showConsole, setShowConsole] = useState(false);
  //maximized, minimize, closed, default
  const [previewMode, setPreviewMode] = useState("default");
  const { user } = useContext(UserContext);

  const [frontendCodeFiles, setFrontendCodeFiles] = useState({
    "index.html": {
      name: "index.html",
      language: "html",
      value: "<h1>Try type HTML here to see magic</h1>",
    },
    "style.css": {
      name: "style.css",
      language: "css",
      value: "/* css code is here  */",
    },
    "script.js": {
      name: "script.js",
      language: "javascript",
      value: "// JS code ",
    },
  });

  const [currentFile, setCurrentFile] = useState("index.html");
  const [language, setLanguage] = useState("html");

  const [output, setOutput] = useState([]);
  const [codeOutput, setCodeOutput] = useState();
  const [loggedErrors, setLoggedErrors] = useState([]);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userid = searchParams.get("userid");
  const isTutor = Boolean(searchParams.get("tutor"));

  const setFiles = useEditorStore((state) => state.setFiles);
  const setOpenFile = useEditorStore((state) => state.setOpenFile);
  const files = useEditorStore((state) => state.files);

  const setLanguageBasedOnFile = (file) => {
    if (file.split(".")[1] === "js") {
      setLanguage("javascript");
    } else {
      setLanguage(file.split(".")[1]);
    }
  };
  const handleSelectProjectFile = (file) => {
    setCurrentFile(file);
    setLanguageBasedOnFile(file);
  };

  const overrideSave = () => {
    document.addEventListener(
      "keydown",
      function (e) {
        if (
          e.keyCode === 83 &&
          (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
        ) {
          e.preventDefault();
          // console.log("Ctrl S..");
          //   onCtrlS();
        }
      },
      false
    );
  };
  const handleEditorValidation = (markers) => {
    markers.forEach((marker) => console.log("onValidate:", marker.message));
  };
  //   const fetchFromLS = () => {
  //     const files = JSON.parse(localStorage.getItem("projectFiles"));
  //     if (files) {
  //       setProjectFiles({
  //         ...files,
  //       });
  //     }
  //   };
  const saveToLS = () => {
    localStorage.setItem("projectFiles", JSON.stringify(frontendCodeFiles));
  };
  const handleEditorWillMount = (monaco) => {
    monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
  };
  const handleEditorDidMount = (editor, monaco) => {
    // emmetHTML(monaco);
    // emmetCSS(monaco);
    emmetHTML(monaco);
    emmetCSS(monaco);

    setIsEditorReady(true);
    monacoRef.current = monaco;
    editorRef.current = editor; // save editor instance
  };
  const handleOnChange = (value, event) => {
    // console.log(currentFile, value, event);
    if (Boolean(userid)) return;
    setFrontendCodeFiles((projectFiles) => {
      const updatedFiles = {
        ...projectFiles,
        [currentFile]: {
          ...projectFiles[currentFile],
          value,
        },
      };
      if (onProjectFilesUpdate) {
        onProjectFilesUpdate(updatedFiles);
      }

      return updatedFiles;
    });

    // saveToLS();

    // setConsoleLogs(compileAndRun(projectFiles["script.js"].value));
  };

  const handleToggleConsole = () => {
    if (showConsole) {
      setShowConsole(false);
    } else {
      setShowConsole(true);
    }
  };

  useEffect(() => {
    if (preLoadedProjectFiles) {
      setLanguage("html");
      setCurrentFile("index.html");
      setFrontendCodeFiles(preLoadedProjectFiles);
    }
  }, [lectureId]);

  useEffect(() => {
    if (preLoadedProjectFiles) {
      setFrontendCodeFiles(preLoadedProjectFiles);
    }
  }, [lectureId, preLoadedProjectFiles]);

  const loadIcon = (projectFile) => {
    if (projectFile.includes("html")) {
      return <DiHtml5 color="#e85e28" className="file-icon" />;
    }
    if (projectFile.includes("css")) {
      return <DiCss3 color="#254ede" className="file-icon" />;
    }
    if (projectFile.includes("js")) {
      return <DiJsBadge color="#f0da3c" className="file-icon" />;
    }
  };
  const [horizontalSizes, setHorizontalSizes] = useState([70, 30]);
  const [consoleTab, setConsoleTab] = useState("results");
  const [isSubmission, setIsSubmission] = useState(false);
  const [tab, setTab] = useState("feedbackTab");
  const loadRunningTestCases = () => {
    const currentMutation = !isSubmission
      ? challengeTestFrontendMutation
      : challengeSubmitFrontendMutation;
    if (currentMutation?.data?.success === true) {
      const submissionData = currentMutation?.data;

      console.log("SUBMISSION DATA", submissionData);
      // if (submissionData?.data?.lectureid) {
      const title = submissionData?.message + " ✅\n";

      let result = "";
      submissionData?.data?.tests?.forEach((testcase, index) => {
        if (testcase?.ok) result += "🟢 " + testcase.title + "\n";
        else result += "🔴 " + testcase.title + "\n";
      });
      if (result.length)
        result += (submissionData?.data?.percentage || 0) + "% passed...";
      return title + "\n" + result;
      // }

      return submissionData?.message + " ✅";
    }

    if (currentMutation?.isLoading) {
      return `Running test cases...this may take a while`;
    }

    return "";
  };

  const loadSubmissionResult = () => {
    const currentMutation = !isSubmission
      ? assignmentTestFrontendMutation
      : assignmentSubmitFrontendMutation;
    console.log("SUBMISSION MUTATION", currentMutation);
    if (currentMutation?.data?.success === true) {
      const submissionData = currentMutation?.data;

      console.log("SUBMISSION DATA", submissionData);
      // if (submissionData?.lectureid) {
      const title = submissionData?.message + " ✅\n";

      let result = "";
      submissionData?.data?.tests?.forEach((testcase, index) => {
        if (testcase?.ok) result += "🟢 " + testcase?.title + "\n";
        else result += "🔴 " + testcase?.title + "\n";
      });
      if (result.length)
        result += (submissionData?.data?.percentage || 0) + "% passed...";
      return title + "\n" + result;
      // }

      return assignmentTestFrontendMutation?.data?.message + " ✅";
    } else if (currentMutation?.data?.success === false) {
      return (
        currentMutation?.data?.message ||
        "Some error occurred... try again later"
      );
    }

    if (assignmentTestFrontendMutation?.isLoading)
      return `Running test cases...this may take a while`;

    return "";
  };

  const handleFeedbackBtn = () => {
    setFeedback({
      ...feedback,
      pending: false,
      show: !feedback?.show,
    });
  };

  const displayFeedbackMessage = () => {
    // const message =
    //   "There is an error in your code. In Java, the main method is case sensitive, it should start with a lowercase 'm'. Additionally, you are missing a semi-colon at the end of your System.out.println() statement. Here is the corrected code:\n" +
    //   "\n" +
    //   "```\n" +
    //   "public class Main {\n" +
    //   "    public static void main(String[] args)  {\n" +
    //   '        System.out.println("Hi");\n' +
    //   "    }\n" +
    //   "}\n" +
    //   "``` \n" +
    //   "\n" +
    //   'This code will output "Hi" to the console when run. Keep up the good work!';

    if (feedback?.message) {
      const message = feedback?.message;
      return (
        <div>
          <p
            className="font-light italic text-sm"
            dangerouslySetInnerHTML={{ __html: message.split("```")[0] }}
          ></p>
          <pre>
            <code className="text-black">{message.split("```")[1]}</code>
          </pre>
          <p
            className="font-light italic text-sm"
            dangerouslySetInnerHTML={{ __html: message.split("```")[2] }}
          ></p>
        </div>
      );
    }
  };

  const saveCode = () => {
    setFeedback({
      ...feedback,
      isLoading: true,
    });
    // console.log("saving file...");
    // if (files.length === 0) {
    //   return;
    // }
    // const file = files[currentIndex];
    // console.log("FILE", file)
    if (assignment) {
      // alert("ASAVING.. assignment");
      saveAssignmentFrontendCodeFeedbackMutation.mutate();
    } else {
      console.log("ASAVING.. challenge", {
        // filename: file?.filename,
        // sourcecode: file?.sourcecode,
      });

      saveFrontendCodeFeedbackMutation.mutate();
    }

    // const editedCode = {
    //   ...file,
    //   sourcecode: file?.sourcecode,
    //   editing: false,
    // };
    // setOpenFiles(
    //   openFiles.map((file, index) =>
    //     index === currentIndex ? editedCode : file
    //   )
    // );
  };

  const showChat = () => {
    if (feedback?.isLoading)
      return (
        <Puff
          height="25"
          width="25"
          radius={1}
          color="white"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      );

    if (feedback?.show) return <XMarkIcon className="w-75 h-75" />;

    return <img src={LogoNav} />;
  };

  return (
    <div className="ide-container">
      <Split
        className="split"
        sizes={horizontalSizes}
        minSize={100}
        expandToMin={false}
        gutterSize={10}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
        onDragEnd={(s) => {
          setHorizontalSizes(s);
        }}
      >
        <Split
          className="split-col h-screen"
          direction="vertical"
          sizes={[80, 20]}
        >
          <div
            className={`code-editor-container code-editor-container-${previewMode}`}
          >
            <div
              className={`ide-sidebar ide-sidebar-${previewMode} d-flex flex-column`}
            >
              <p className="text-white">EXPLORER</p>
              {Object.keys(frontendCodeFiles).map((projectFile, index) => (
                <button
                  key={index}
                  onClick={() => handleSelectProjectFile(projectFile)}
                  className={`project-file-btn ${
                    projectFile === currentFile && "selected"
                  }`}
                >
                  {loadIcon(projectFile)}
                  <code>{projectFile}</code>
                </button>
              ))}
              <div className="mt-auto mx-auto">
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      Copy URL to share submissions with tutor on Discord.
                    </Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    onClick={() => {
                      const url = `${location.href}?userid=${user?.userid}&tutor=true`;
                      navigator.clipboard.writeText(url);
                    }}
                    className="bg-success shadow-none"
                    variant="success"
                  >
                    <FaShare color="white" />
                  </Button>
                </OverlayTrigger>
                ,
              </div>
            </div>
            <div className={`code-editor code-editor-${previewMode}`}>
              <div className="code-editor__header">
                <div></div>
              </div>

              <div className="w-full flex justify-center items-center bg-[#1e1e1e]">
                <p
                  className="relative bottom-0 left-0 py-1 bg-purple-400 z-10 rounded-full italic text-white font-light m-2 px-3 cursor-pointer text-sm hover:bg-sky-700"
                  onClick={() =>
                    window.open(
                      "https://www.loom.com/share/9a5877177dcb4da993950ac6e036c297",
                      "_blank"
                    )
                  }
                >
                  How to use this?
                </p>
                <button
                  className="relative top-0 right-0 py-1 bg-green-600 hover:bg-green-400 hover:text-dark z-10 rounded-full text-white font-light m-2 px-3 hover:bg-sky-700"
                  onClick={!feedback?.isLoading && saveCode}
                >
                  {feedback?.isLoading ? (
                    <Puff
                      height="25"
                      width="25"
                      radius={1}
                      color="white"
                      ariaLabel="puff-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    "Feedback Assistant"
                  )}
                </button>
                <div className="bottom-0 right-0 m-2 cursor-pointer h-full flex flex-col-reverse z-10 absolute ">
                  <div className="flex w-full justify-end mb-2 relative bottom-[64px]">
                    <div
                      className="text-white bg-purple-400 rounded-full h-14 w-14 px-2 flex items-center mt-1 mr-2 justify-center hover:bg-sky-700"
                      onClick={!feedback?.isLoading && handleFeedbackBtn}
                    >
                      {showChat()}
                    </div>
                    {feedback?.pending && (
                      <span className="relative">
                        <BsFillCircleFill className="ml-1 w-4 h-4 text-red-600 absolute top-0 right-0 mr-3 rounded-full" />
                      </span>
                    )}
                  </div>
                  {feedback?.show && (
                    <>
                      <div className="h-3/4 w-96 bg-gray-100 border-2 bg-slate rounded-md mr-2 mt-2 overflow-x-hidden">
                        <div className="d-flex justify-around cursor-pointer bg-white text-white z-10 relative">
                          <div
                            onClick={() => setTab("feedbackTab")}
                            className={`
                    w-full d-flex justify-center p-3  ${
                      tab === "feedbackTab"
                        ? "bg-purple-400 font-medium"
                        : "text-purple-400"
                    }
                  `}
                          >
                            Feedback
                          </div>
                        </div>
                        {tab === "feedbackTab" && (
                          <>
                            <div className="flex flex-col justify-between items-center p-2 bg-white w-full">
                              <h1 className="text-lg m-0 font-bold">
                                Feedback
                              </h1>
                            </div>
                            <div className="bg-white p-2 rounded-md bg-slate m-2">
                              <p className="text-sm opacity-70 font-bold">
                                FEEDBACK FROM ZAIO ASSISTANT
                              </p>
                              {displayFeedbackMessage()}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="monaco-editor-container">
                <div className="monaco-editor-header">
                  <code>{currentFile}</code>
                </div>
                <Editor
                  className="monaco-editor"
                  height="100%"
                  width="100%"
                  theme="vs-dark"
                  path={frontendCodeFiles[currentFile]?.name}
                  language={language}
                  value={frontendCodeFiles[currentFile]?.value}
                  onValidate={handleEditorValidation}
                  beforeMount={handleEditorWillMount}
                  onMount={handleEditorDidMount}
                  onChange={handleOnChange}
                  options={{
                    readOnly: !isTutor && Boolean(userid),
                    automaticLayout: true,
                    fontLigatures: true,
                    fontSize: 16,
                    minimap: {
                      enabled: false,
                    },
                    renderLineHighlight: "line",
                    renderLineHighlightOnlyWhenFocus: false,
                    cursorSmoothCaretAnimation: true,
                    cursorBlinking: "smooth",
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "#1e1e1e", zIndex: 1 }}>
            <Tab.Container
              defaultActiveKey={consoleTab}
              activeKey={consoleTab}
              className="h-full w-full"
            >
              <Nav variant="pills w-full">
                <div className="w-100 flex justify-between items-center">
                  <div className="flex">
                    <Nav.Item onClick={() => setConsoleTab("results")}>
                      <Nav.Link eventKey={"results"}>Results</Nav.Link>
                    </Nav.Item>
                  </div>
                  <div className="nav-right z-10">
                    {!challengeSubmitFrontendMutation?.isLoading &&
                      !assignmentSubmitFrontendMutation?.isLoading && (
                        <button
                          onClick={() => {
                            setIsSubmission(false);
                            handleTestSolution();
                          }}
                          disabled={
                            challengeTestFrontendMutation?.isLoading ||
                            (!isTutor && Boolean(userid))
                          }
                        >
                          {challengeTestFrontendMutation?.isLoading ||
                          assignmentTestFrontendMutation?.isLoading ? (
                            <Puff
                              height="25"
                              width="25"
                              radius={1}
                              color="white"
                              ariaLabel="puff-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          ) : (
                            "Test"
                          )}
                        </button>
                      )}
                    {!isTutor &&
                      !challengeTestFrontendMutation?.isLoading &&
                      !assignmentTestFrontendMutation?.isLoading && (
                        <button
                          onClick={() => {
                            setIsSubmission(true);
                            handleSubmitSolution();
                          }}
                          disabled={
                            challengeSubmitFrontendMutation?.isLoading ||
                            Boolean(userid)
                          }
                        >
                          {challengeSubmitFrontendMutation?.isLoading ||
                          assignmentSubmitFrontendMutation?.isLoading ? (
                            <Puff
                              height="25"
                              width="25"
                              radius={1}
                              color="white"
                              ariaLabel="puff-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}
                  </div>
                </div>
              </Nav>

              <Tab.Content style={{ zIndex: 9999 }}>
                <Tab.Pane eventKey={"results"}>
                  <Editor
                    className="monaco-editor"
                    height="30vh"
                    width="100%"
                    theme="vs-dark"
                    path={"results"}
                    language={"plaintext"}
                    value={
                      assignment
                        ? loadSubmissionResult()
                        : loadRunningTestCases()
                    }
                    options={{
                      automaticLayout: true,
                      fontSize: 16,
                      scrollBeyondLastLine: false,
                      readOnly: true,
                      language: "plaintext",
                      minimap: {
                        enabled: false,
                      },
                    }}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Split>
        {/* <button onClick={() => setShowConsole(true)}>show Console</button> */}
        {/* ${previewMode} */}
        <div className={`browser-window browser-window-${previewMode}`}>
          <div className="preview-container-header">
            <div className="monaco-editor-header">
              <code className="text-primary">{"Preview"}</code>
            </div>
            <div className="tools">
              {/* <div className="" onClick={() => setPreviewMode("closed")}> */}

              <BsLayoutSplit
                className="preview-container-header-round"
                onClick={() => setPreviewMode("split")}
              />

              <BsLayoutThreeColumns
                className="preview-container-header-round"
                onClick={() => setPreviewMode("default")}
              />
              <BsArrowsFullscreen
                className="preview-container-header-round"
                onClick={() => setPreviewMode("maximized")}
              />
            </div>
          </div>
          <iframe
            title="1"
            srcDoc={IframeCode(
              frontendCodeFiles["index.html"]?.value,
              frontendCodeFiles["style.css"]?.value,
              frontendCodeFiles["script.js"]?.value
            )}
          />
        </div>
      </Split>
    </div>
  );
}

export default IdeFrontendUser;
