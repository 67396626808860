import { useState } from "react";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import useAssignmentStore from "zustand-store/assignmentStore";

function AdminQuestionsList({
  setQuestionType = null,
  mcqs = null,
  codingQs = null,
  backendQs = null,
  frontendQs = null,
  datascienceQs = null,
}) {
  const [selectedId, setSelectedId] = useState(null);
  const [adminAssignmentMode, setAdminAssignmentMode] = useState(
    !!setQuestionType
  );
  const setAssignmentMode = useAssignmentStore(
    (state) => state.setAssignmentMode
  );
  const setCurrentQuestionId = useAssignmentStore(
    (state) => state.setCurrentQuestionId
  );
  const setAdminSelectedAssignmentQuestion = useAssignmentStore(
    (state) => state.setAdminSelectedAssignmentQuestion
  );
  const setAdminQuestionMode = useAssignmentStore(
    (state) => state.setAdminQuestionMode
  );
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleListItemClick = (type, id, q) => {
    setAdminQuestionMode("edit-assignment-" + type);
    setSelectedId(id);
    setCurrentQuestionId(id);
    setAssignmentMode(type);
    // queryClient.invalidateQueries("codingQInfo");

    console.log("ASS QUESTION", q);
    if (adminAssignmentMode) {
      setQuestionType(type);
      setAdminSelectedAssignmentQuestion(q);
    } else {
      history.push(`/assignment/${type}/${id}`);
    }
  };

  return (
    <ListGroup as="ol" numbered defaultActiveKey={-1}>
      <h5 className="text-center m-2">MCQs (auto-graded)</h5>
      {mcqs &&
        mcqs.map((mcq, index) => (
          <ListGroup.Item
            key={mcq?._id}
            as="li"
            className="d-flex justify-content-between align-items-start"
            action
            active={mcq?._id === selectedId}
            onClick={(e) => handleListItemClick(mcq?.type, mcq?._id, mcq)}
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">{mcq?.title || mcq?.questionname}</div>
              {/* 20 mins */}
            </div>
            <Badge bg="primary" pill>
              {mcq?.marks}
            </Badge>
          </ListGroup.Item>
        ))}
      <h5 className="text-center m-2">Coding (manually-graded)</h5>
      {codingQs &&
        codingQs.map((mcq, index = mcqs?.length - 1) => (
          <ListGroup.Item
            key={mcq?._id}
            as="li"
            className="d-flex justify-content-between align-items-start"
            action
            active={mcq?._id === selectedId}
            onClick={(e) => handleListItemClick(mcq?.type, mcq?._id, mcq)}
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">{mcq?.title || mcq?.questionname}</div>
              {/* 20 mins */}
            </div>
            <Badge bg="primary" pill>
              {mcq?.marks}
            </Badge>
          </ListGroup.Item>
        ))}
      <h5 className="text-center m-2">Backend (manually-graded)</h5>
      {backendQs &&
        backendQs.map((question, index = backendQs?.length - 1) => (
          <ListGroup.Item
            key={question?._id}
            as="li"
            className="d-flex justify-content-between align-items-start"
            action
            active={question?._id === selectedId}
            onClick={(e) => handleListItemClick(question?.type, question?._id, question)}
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">{question?.title || question?.questionname}</div>
              {/* 20 mins */}
            </div>
            <Badge bg="primary" pill>
              {question?.marks}
            </Badge>
          </ListGroup.Item>
        ))}
      <h5 className="text-center m-2">Frontend (automated-graded)</h5>
      {frontendQs &&
        frontendQs.map((question, index = frontendQs?.length - 1) => (
          <ListGroup.Item
            key={question?._id}
            as="li"
            className="d-flex justify-content-between align-items-start"
            action
            active={question?._id === selectedId}
            onClick={(e) => handleListItemClick(question?.type, question?._id, question)}
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">{question?.title || question?.questionname}</div>
              {/* 20 mins */}
            </div>
            <Badge bg="primary" pill>
              {question?.marks}
            </Badge>
          </ListGroup.Item>
        ))}
      <h5 className="text-center m-2">DataScience (automated-graded)</h5>
      {datascienceQs &&
        datascienceQs.map((question, index = datascienceQs?.length - 1) => (
          <ListGroup.Item
            key={question?._id}
            as="li"
            className="d-flex justify-content-between align-items-start"
            action
            active={question?._id === selectedId}
            onClick={(e) => handleListItemClick(question?.type, question?._id, question)}
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">{question?.title || question?.questionname}</div>
              {/* 20 mins */}
            </div>
            <Badge bg="primary" pill>
              {question?.marks}
            </Badge>
          </ListGroup.Item>
        ))}
    </ListGroup>
  );
}

export default AdminQuestionsList;
