import React from "react";
import "./Company.css";

function Company({ handleApplyBtn, job }) {
  return (
    <div className="company-container" onClick={handleApplyBtn}>
      <img
        className="company-image-company"
        src={job.company.companylogo}
      />
      <h3 className="mt-3">{job.company.companyname}</h3>
      <div className="m-3">
        <span className={`eligible-icon yellow-tag`}>
          {job.company.website}
        </span>
      </div>
      <div>
        <span className={`eligible-icon info-tag mb-2`}>🌏 {job.location}</span>
        <div className="p-2"></div>
        <span className={`eligible-icon info-tag mt-2`}>💰 {job.salary}</span>
      </div>

      <button className=" mt-4 btn btn-block btn-main w-75 p-2">
        Apply Now
      </button>
    </div>
  );
}

export default Company;
