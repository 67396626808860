import React from "react";
import "../Note/Note.css";
import { MdPlayCircleFilled, MdClear } from "react-icons/md";
import Sidebar from "containers/Code/Sidebar";
import "./ContentDrawer.css";
import { useHistory } from "react-router-dom";

function ContentDrawer({
  handleCloseContentDrawer,
  contentDrawer,
  videos,
  courseId,
  lectureId,
  unitId,
  courseData,
  scrollToVideo,
  handlePlay,
}) {
  const history = useHistory();

  const handleListItemClick = (id) => {
    handleCloseContentDrawer();
    scrollToVideo(id);
    // handlePlay();
  };

  return (
    <div className="note__container">
      <div className="note__header">
        {courseData.coursename} X/{videos.length} | {courseData.courseduration}{" "}
        mins
        <MdClear
          style={{ cursor: "pointer" }}
          onClick={handleCloseContentDrawer}
        />
      </div>
      <div className="contentdrawer-content">
        <p>{courseData.coursedescription}</p>
        {videos &&
          videos.map((video, index) => (
            // onClick={() => history.push(
            //   `/quiz-reel?courseid=${courseid}&lectureid=${lectureId}&unitid=${unitId}`
            // )}
            <div
              className="contentdrawer-content-item"
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => handleListItemClick(video?._id)}
            >
              <div className="contentdrawer-content-item-title">
                {/* {courseData.completedLectures} */}
                <MdPlayCircleFilled
                  className="qr-icons content-icon font-color-purple"
                  style={{ color: "#8437f9" }}
                />
                <div style={{ marginLeft: "1rem" }}>{index + 1}.</div>
                <div style={{ marginLeft: "0.5rem" }}>{video.lecturename}</div>
              </div>
              <span className="">{video.duration} min</span>
            </div>
          ))}

        {/* <Sidebar
          courseid={"626055a21dd7340022782fbb"}
          showSidebar={true}
          lectureId={"626120601dd7340022788dec"}
          unitId={"62611f3e1dd73400227887b2"}
          contentDrawer={contentDrawer}
        /> */}
      </div>
    </div>
  );
}

export default ContentDrawer;
