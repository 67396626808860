import { useState, useEffect, useContext, useCallback } from "react";
import classes from "./MyProfile.module.css";

import { useHistory } from "react-router-dom";

import {
  ProgressBar,
  Button,
  Card,
  CardImg,
  Modal,
  Row,
  Col,
} from "react-bootstrap";

import AuthService from "actions/services/auth.service";
import { UserContext } from "context/UserProvider";
import { LoadingContext } from "context/LoadingProvider";
import Questions from "./Questions";

import moment from "moment";
import toonavatar from "cartoon-avatar";
import lectureService from "actions/services/lecture.service";
import Avatar from "react-avatar";

import userImg from "assets/img/myProfile/user.svg";
import badge from "assets/img/myProfile/badge.svg";
import certificatesImg from "assets/img/myProfile/certificates.svg";
import github from "assets/img/myProfile/github.svg";
import linkedin from "assets/img/myProfile/linkedin.svg";
import navigate from "assets/img/myProfile/navigate.svg";
import net from "assets/img/myProfile/net.svg";
import SoloStreakScore from "./SoloStreakScore";

import { saveAs } from "file-saver";

import learningpathService from "actions/services/learningpath.service";
import tutorService from "actions/services/tutor-service";
import courseService from "actions/services/course.service";
import calculateCompletionDate from "pages/DashBoard/completionAlgo";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
// import DashboardNavbar from '../navbar/Navbar.jsx';
import ProfileEdit from "./ProfileEdit.jsx";
// import EnrolledCourse from './EnrolledCourseUserProfile.jsx';
import EnrolledCourse from "./EnrolledCourse.jsx";
import { useParams } from "react-router";
import { Mixpanel } from "Utils/Mixpanel";
import {
  ComponentContainer,
  PosterContainer,
} from "../../pages/Competitions/Competitions.styled";
import WalletBalance from "../../pages/Wallet/WalletBalance";
import ActivityChart from "./ActivityChart";
import { CalendarPage } from "component/TasksCalendar/TasksCalendar";
import ProfileCommon from "./ProfileCommon";

const ZaioProfile = (props) => {
  const [learningPathList, setLearningPathList] = useState({});
  const [certificates, setCertificate] = useState({});
  const [certificateData, setcertificateData] = useState({
    certificateurl: "",
    coursename: "",
  });
  const [tab, setTab] = useState("Certificates");
  const [viewCount, setViewCount] = useState(3);
  const history = useHistory();

  const [certificateShow, setCertificateShow] = useState(false);
  const [show, setShow] = useState(false);
  // const { userProfile, setUserProfile } = useContext(UserContext);
  const [userProfile, setUserProfile] = useState();
  const [avatar, setAvatar] = useState("");
  const [totalCourse, setTotalCourse] = useState([]);
  const [totalChallenge, setTotalChallenge] = useState();
  const [totalAssignment, setTotalAssignment] = useState();

  // const { setLoading } = useContext(LoadingContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //   const calculateCompletion = (inputValue, continueData) => {
  //     console.log(' CALCULATE COMPLETION ALGO', inputValue, continueData);
  //     setValues(inputValue);
  //     let daysRemaining = calculateCompletionDate(inputValue, continueData);
  //     console.log(inputValue, daysRemaining);
  //     setDaysLeft(daysRemaining);
  //     setPercentage(continueData.courseEnrolledPercentageleftInDecimal * 100);
  //   };

  let { email } = useParams();
  console.log(email, "email");

  // const loadData = async () => {
  //   const data = await AuthService.findZaioProfile(email);
  //   console.log(data, 'data');
  //   setUserProfile(data[0]);
  //   let res = await learningpathService.getLearningPathsUserProfile(email);
  //   // console.log(res,'rrrrr----------')
  // };

  // useEffect(() => {
  //   loadData();
  // }, []);

  console.log(userProfile, "userProfile");

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/zaio-profile/email",
      "Page name": "Public Zaio Profile",
    });
    // setLoading(true);
    AuthService.findZaioProfile(email)
      .then((response) => {
        // console.log(response.data);
        if (response.success) {
          setUserProfile(response.data);
        }
      })
      .catch((reject) => alert("somethingwentwrong"))
      .then(() => console.log("done"));
    // .then(() => setLoading(false));

    console.log(userProfile, "userProfileuserProfile");

    const loadAssignmentNumber = async () => {
      try {
        const _dataAssignment = await lectureService.getTotalAssignment();
        if (_dataAssignment.status === 200) {
          setTotalAssignment(_dataAssignment.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const loadChallengeNumber = async () => {
      try {
        const _dataChallenge = await lectureService.getTotalChallenge();
        if (_dataChallenge.status === 200) {
          setTotalChallenge(_dataChallenge.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const funct = async () => {
      // loadAssignmentNumber();
      // loadChallengeNumber();
      const avatarUrl = toonavatar.generate_avatar({ gender: "male" });
      console.log(avatarUrl, "tst");
      setAvatar(avatarUrl);
      try {
        let res = await learningpathService.getLearningPathsUserProfile(email);
        console.log("res======== Learning path deets", res);
        if (res.data)
          setLearningPathList({
            ...res.data,
          });

        res.data.map((path) =>
          learningpathService.autoEnrollUserProfile(
            path.learningpathid._id,
            email
          )
        );
        // try {
        //   let res2 = await learningpathService.getContinueLearning();
        //   setContinueLearning({
        //     ...res2.data,
        //   });
        //   //console.log('res2======== Continue learning deets', res2);
        //   try {
        //     let res3 = await tutorService.getUserAccountDetails();

        //     setMinutesRemaining(res3.data.totalminutes);
        //     //console.log('res3======== Tutor deets', res3);
        //   } catch (error) {
        //     console.log(error);
        //   }
        //   calculateCompletion(values, res2.data);
        // } catch (error) {
        //   console.log(error);
        // }
      } catch (error) {
        console.log(error);
        history.push("/somethingwentwrong");
      }
    };

    funct();
  }, []);

  useEffect(() => {
    const courseHandler = async () => {
      let res = null;
      try {
        console.log("courseHandler");
        res = await courseService.getCertificatesUserProfile(email);
        console.log("res=========Certificates", res);
        setCertificate(res.data?.certificates);
      } catch (error) {
        console.log(error);
        history.push("/somethingwentwrong");
      }
    };

    courseHandler();
  }, []);

  const handleSetView = () => {
    if (viewCount === 3) {
      setViewCount(certificates.length);
    } else {
      setViewCount(3);
    }
  };

  const certificateDataHandler = (coursename, certificateurl) => {
    // const [certificateData, setcertificateData] = useState({ certificateurl: '', coursename: '' });
    setcertificateData({
      certificateurl: certificateurl,
      coursename: coursename,
    });
  };

  console.log(certificateData);

  console.log({ userProfile, learningPathList, certificates });

  const handleUpdateURL = useCallback((event) => {
    console.log(event, "ksevent---");
    setTab(event);
    // history.push(`/competitions/${competitionId}?tab=${event}`);
  }, []);

  return (
    <div className="ml-5">
      <ProfileCommon
        publicProfile={true}
        avatar={avatar}
        userProfile={userProfile}
        handleUpdateURL={handleUpdateURL}
        certificates={certificates}
        setCertificateShow={setCertificateShow}
        certificateDataHandler={certificateDataHandler}
        certificateShow={certificateShow}
        certificateData={certificateData}
        handleSetView={handleSetView}
        viewCount={viewCount}
        learningPathList={learningPathList}
        setTotalCourse={setTotalCourse}
        email={email}
        tab={tab}
        totalAssignment={totalAssignment}
        totalChallenge={totalChallenge}
        totalCourse={totalCourse}
        profile={false}
        editable={false}
      />
    </div>
  );
};

export default ZaioProfile;
