import { useState } from "react";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import useAssignmentStore from "zustand-store/assignmentStore";
import lectureService from "actions/services/lecture.service";

function QuestionsList({
  setQuestionType = null,
  mcqs = null,
  codingQs = null,
  backendQs = null,
  frontendQs = null,  
  assignmentid,
}) {
  const [selectedId, setSelectedId] = useState(null);
  const [adminAssignmentMode, setAdminAssignmentMode] = useState(
    !!setQuestionType
  );
  const setAssignmentMode = useAssignmentStore(
    (state) => state.setAssignmentMode
  );
  const setCurrentQuestionId = useAssignmentStore(
    (state) => state.setCurrentQuestionId
  );
  const setAdminSelectedAssignmentQuestion = useAssignmentStore(
    (state) => state.setAdminSelectedAssignmentQuestion
  );
  const setAdminQuestionMode = useAssignmentStore(
    (state) => state.setAdminQuestionMode
  );
  const setTitle = useAssignmentStore((state) => state.setTitle);
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleListItemClick = (type, id, q) => {
    setAdminQuestionMode("edit-assignment-" + type);
    setSelectedId(id);
    setCurrentQuestionId(id);
    setAssignmentMode(type);
    // queryClient.invalidateQueries("codingQInfo");

    console.log("ASS QUESTION", q);
    if (adminAssignmentMode) {
      setQuestionType(type);
      setAdminSelectedAssignmentQuestion(q);
    } else {
      history.push(`/assignment/${assignmentid}/${type}/${id}`);
    }
  };

  const refreshAssignmentProgressMutation = useMutation(
    () => lectureService.refreshAssignmentProgress(assignmentid),
    {
      onSuccess: (data) => {
        console.log(data);
        alert("Questions updated!");
        queryClient.invalidateQueries("assignmentProgress");
      }
    }
  )

  const handleRefresh = () => {
    refreshAssignmentProgressMutation.mutate()
  }
  // useEffect(() => {
  //   console.log("mcqs", mcqs);
  //   console.log("codingQs", codingQs);
  //   console.log(backendQs, 'h2h2h2')
  //   console.log(frontendQs, 'h2h2h2')
  // }, [backendQs, mcqs, codingQs]);

  return (
    <ListGroup as="ol" defaultActiveKey={-1}>
      <div className="flex justify-between items-center">
        <h1 className="text-sm font-bold m-2 opacity-70 mt-24">OVERVIEW</h1>
        <button 
          disabled={refreshAssignmentProgressMutation?.isLoading}
          onClick={handleRefresh} 
          className="m-2 h-min bg-green-400 rounded px-2 py-1 text-xs hidden sm:block disabled:opacity-60"
        >
          {refreshAssignmentProgressMutation?.isLoading ? 'Loading' : 'Refresh'}
        </button>
      </div>
      <ListGroup.Item
        // key={mcq?._id}
        as="li"
        className="d-flex justify-content-between align-items-start cursor-pointer hover:bg-gray-100"
        action
        // active={mcq?._id === selectedId}
        onClick={(e) => {
          setAssignmentMode("overview");
          history.push(`/assignment/${assignmentid}`);
        }}
      >
        <div className="ms-2 me-auto">
          <div className="font-normal">Information about assignment</div>
          {/* 20 mins */}
        </div>
        {/* <Badge bg="primary" pill>
          {mcq?.marks}
        </Badge> */}
      </ListGroup.Item>

      <h1 className="text-sm font-bold m-2 opacity-70">AUTOGRADED MCQs</h1>
      {mcqs &&
        mcqs.map((item, index) => {
          const mcq = item?.assignmentmcqid;
          console.log("MCQ", mcq);

          return (
            <ListGroup.Item
              key={mcq?._id}
              as="li"
              className={`d-flex justify-content-between align-items-start cursor-pointer hover:bg-gray-100 bg-purple-400`}
              action
              active={mcq?._id === selectedId}
              onClick={(e) => handleListItemClick(mcq?.type, mcq?._id, mcq)}
              style={{
                backgroundColor: item?.status === "completed" ? "#00d395" : null,
                color: item?.status === "completed" ? "white" : null,
              }}
            >
              <div className="ms-2 me-auto">
                <div className="font-normal">
                  {mcq?.title || mcq?.questionname}
                </div>
                {/* 20 mins */}
              </div>
              {item?.status === "inprogress" && (
                <Badge bg="primary" pill>
                  {mcq?.marks}
                </Badge>
              )}
              {item?.status === "completed" && (
                <span className="bg-purple-400 px-2 rounded-full text-sm">
                  {item?.mark}/{mcq?.marks}
                </span>
              )}
            </ListGroup.Item>
          );
        })}

      <h1 className="text-sm font-bold m-2 opacity-70 mt-24">
        CODING QUESTIONS
      </h1>
      {codingQs &&
        codingQs.map((item, index) => {
          const mcq = item?.assignmentcodingquestionid;
          return (
            <ListGroup.Item
              key={mcq?._id}
              as="li"
              className={`d-flex justify-content-between align-items-start cursor-pointer`}
              action
              active={mcq?._id === selectedId}
              onClick={(e) => handleListItemClick(mcq?.type, mcq?._id, mcq)}
              style={{
                backgroundColor: item?.status === "marked" ? "#00d395" : null,
                color: item?.status === "marked" ? "white" : null,
              }}
            >
              <div className="ms-2 me-auto">
                <div className="font-normal">
                  {mcq?.title || mcq?.questionname}
                </div>
                {/* 20 mins */}
              </div>
              {item?.status === "inprogress" && (
                <Badge bg="primary" pill>
                  {mcq?.marks}
                </Badge>
              )}
              {item?.status === "marked" && (
                <span className="bg-purple-400 px-2 rounded-full text-sm">
                  {item?.mark}/{mcq?.marks}
                </span>
              )}
            </ListGroup.Item>
          );
        })}

      <h1 className="text-sm font-bold m-2 opacity-70 mt-24">
        BACKEND QUESTIONS
      </h1>
      {backendQs &&
        backendQs.map((item, index) => {
          const question = item?.assignmentbackendquestionid;
          console.log(question);
          return (
            <ListGroup.Item
              key={question?._id}
              as="li"
              className={`d-flex justify-content-between align-items-start cursor-pointer`}
              action
              active={question?._id === selectedId}
              onClick={(e) => handleListItemClick(question?.type, question?._id, question)}
              style={{
                backgroundColor: question?.status === "marked" ? "#00d395" : null,
                color: question?.status === "marked" ? "white" : null,
              }}
            >
              <div className="ms-2 me-auto">
                <div className="font-normal">
                  {question?.title || question?.questionname}
                </div>
                {/* 20 mins */}
              </div>

              {item?.status === "inprogress" && (
                <Badge bg="primary" pill>
                  {question?.marks}
                </Badge>
              )}

              {item?.status === "marked" && (
                <span className="bg-purple-400 px-2 rounded-full text-sm">
                  {item?.mark}/{question?.marks}
                </span>
              )}
            </ListGroup.Item>
          );
        })}
      <h1 className="text-sm font-bold m-2 opacity-70 mt-24">
        FRONTEND QUESTIONS
      </h1>
      {frontendQs &&
        frontendQs.map((item, index) => {
          const question = item?.assignmentfrontendquestionid;
          console.log(question);
          return (
            <ListGroup.Item
              key={question?._id}
              as="li"
              className={`d-flex justify-content-between align-items-start cursor-pointer`}
              action
              active={question?._id === selectedId}
              onClick={(e) => handleListItemClick(question?.type, question?._id, question)}
              style={{
                backgroundColor: question?.status === "marked" ? "#00d395" : null,
                color: question?.status === "marked" ? "white" : null,
              }}
            >
              <div className="ms-2 me-auto">
                <div className="font-normal">
                  {question?.title || question?.questionname}
                </div>
                {/* 20 mins */}
              </div>

              {item?.status === "inprogress" && (
                <Badge bg="primary" pill>
                  {question?.marks}
                </Badge>
              )}

              {item?.status === "marked" && (
                <span className="bg-purple-400 px-2 rounded-full text-sm">
                  {item?.mark}/{question?.marks}
                </span>
              )}
            </ListGroup.Item>
          );
        })}
    </ListGroup>
  );
}

export default QuestionsList;
