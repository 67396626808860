import React from "react";
import { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaVideo, FaCode, FaSpellCheck } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import "./Course.css";
// import styles from "./styles.module.css";
// import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet";

// import Button from 'react-bootstrap/Button'
import Card from "react-bootstrap/Card";
import lectureService from "actions/services/lecture.service";
import unitService from "actions/services/unit.service";
import unchecked from "assets/img/misc/2.png";
import checked from "assets/img/misc/1.png";

import mixpanel from "mixpanel-browser";
import { ProgressBar } from "react-bootstrap";
import { BiWindowAlt } from "react-icons/bi";

import styles from "./coursecontent.module.scss";

import isBrowsingFromPhone from "Utils/DetectDeviceBrowser";

mixpanel.init("4e2be35d7debc0ad57db236937d08d8d");
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

function CourseList({
  course,
  updateUnitList,
  edit,
  setUpdate,
  setNewLecture,
  courseid,
  indexListChange,
  setLoading,
  borderRadius,
  lectureId,
  checkbox
}) {
  const history = useHistory();
  const queries = new URLSearchParams(history.location.search);

  const [click, setClick] = useState(false);

  const handleClick = (
    ismobilecourse,
    isenrolled,
    courseid,
    lectureId,
    unitId
  ) => {
    setClick(true);
    console.log(
      "TESTING",
      isBrowsingFromPhone()
      // `/quiz-reel?courseid=${courseid}&lectureid=${lectureId}&unitid=${unitId}`
    );

    // if (isenrolled) {
    if (course?.ismobilecourse) {
      console.log("IS MOBILE COURSE");
      history.push(
        `/quiz-reel?courseid=${courseid}&lectureid=${lectureId}&unitid=${unitId}`
      );
    } else {
      console.log("IS NOT COURSE");
      if (!isBrowsingFromPhone()) {
        console.log("FROM DESKTOP");

        history.push(`/watch/${course._id}/${unitId}/${lectureId}`);
        // window.location.href =
        //   process.env.REACT_APP_FRONTEND_URL +
        //   `/app/watch/${course._id}/${unitId}/${lectureId}`;
      } else {
        console.log("FROM PHONE");
        alert(
          "Please log into your desktop to access this course. You can explore mobile courses on your dashboard."
        );
        history.push(`/updated-dashboard`);
      }
    }
    // }
  };

  const lectureIcons = (type) => {
     if(type?.includes('mcq')){
      return <FaSpellCheck />
     } else if(["challenge", "datascience"]?.includes(type)) {
      return <FaCode />
     } else {
      return <FaVideo />
     }
  }

  useEffect(() => {
    // if (click) window.location.reload(true);
  }, [click]);
  console.log("COURSE LIST:", isBrowsingFromPhone());

  console.log(course,'csharp')

  return (
    <>
      <div>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {course &&
            course.courseunit.map((unit, unitIndex) => {
              return (
                <AccordionItem key={unitIndex} uuid={unit?._id}>
                  <div className="accordion-item">
                    <AccordionItemHeading id={"flush-headingOne" + unit._id}>
                      <AccordionItemButton
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <b style={{ fontSize: "18px" }}>{unit.unitname}</b>

                        <small
                          className={styles.grey_text}
                          style={{ fontSize: "12px" }}
                        >
                          {
                            unit.lecture.filter((lecture) =>
                              course.completedLectures.includes(lecture._id)
                            ).length
                          }
                          /{unit.lecture.length} | {unit.unitduration} mins
                        </small>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="">
                      <div className="accordion-body m-0 p-0">
                        {unit.lecture.map((lecture) => {
                          return (
                            <>
                              <div
                                className={`d-flex align-items-center justify-content-between ${styles.video_border}`}
                              >
                                <div
                                  className="d-flex justify-content-between flex-wrap w-100 m-0 p-0"
                                  onClick={() => {
                                    handleClick(
                                      course.ismobilecourse,
                                      course.isenrolled,
                                      courseid,
                                      lecture._id,
                                      unit._id
                                    );
                                  }}
                                >
                                  <div className="d-flex items-center">
                                    <div className="form-check mr-1">
                                      <input
                                        className={`form-check-input`}
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        checked={course.completedLectures.includes(
                                          lecture._id
                                        )}
                                      />
                                    </div>
                                    <div className="mr-2">
                                      {lectureIcons(lecture?.type)}
                                    </div>
                                    <div
                                      className={`${styles.sub_heading} ${lectureId === lecture._id
                                          ? styles.highlight
                                          : ""
                                        }`}
                                      data-bs-toggle="tooltip"
                                      title={lecture.lecturename}
                                    >
                                      {lecture.lecturename}
                                    </div>
                                  </div>
                                  <div className={`${styles.grey_text}`}>
                                    <span>
                                      {lecture.duration}{" "}
                                      {lecture.duration === 1 ? "min" : "mins"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </AccordionItemPanel>
                  </div>
                </AccordionItem>
              );
            })}
        </div>
        {/* <ContentAccordion /> */}
      </div>
    </>
  );
}

export default CourseList;

// <DragDropContext DragDropContext onDragEnd={updateUnitList}>
//   <Droppable droppableId="units">
//     {(provided) => {
//       return (
//         <ul {...provided.droppableProps} ref={provided.innerRef}>
//           {course &&
//             course.courseunit.map((unit, unitIndex) => (
//               <Draggable
//                 key={unit._id}
//                 draggableId={unit._id}
//                 index={unitIndex}
//                 isDragDisabled={!edit}
//               >
//                 {(provided) => (
//                   <li
//                     ref={provided.innerRef}
//                     style={{ cursor: "pointer" }}
//                     {...provided.draggableProps}
//                     {...provided.dragHandleProps}
//                   >
//                     <Card
//                       key={unit._id}
//                       style={{ borderRadius: borderRadius && 0 }}
//                     >
//                       <Accordion.Toggle
//                         as={Card.Header}
//                         variant="link"
//                         eventKey={unit._id}
//                         style={{ cursor: "pointer" }}
//                       >
//                         <p className="m-0">
//                           <b>{unit.unitname} </b> -{" "}
//                           {/* <b>{unit.lecture.length} lessons </b> */}
//                           <b>
//                             {unit.lecture.filter((lecture) =>
//                               course.completedLectures.includes(lecture._id)
//                             ).length +
//                               "/" +
//                               unit.lecture.length +
//                               " completed"}
//                           </b>
//                         </p>
//                       </Accordion.Toggle>
//                       <Accordion.Collapse eventKey={unit._id}>
//                         <Card.Body style={{ padding: "20px 0" }}>
//                           {edit ? (
//                             <div className="d-flex justify-content-between m-3">
//                               <div
//                                 className="btn btn-primary btn-sm"
//                                 onClick={(e) =>
//                                   setNewLecture({
//                                     courseunitid: unit._id,
//                                     show: true,
//                                   })
//                                 }
//                               >
//                                 Add Lecture
//                               </div>
//                               <div
//                                 className="btn btn-danger btn-sm"
//                                 onClick={async (e) => {
//                                   await unitService.deleteUnit(unit._id);
//                                   setUpdate(true);
//                                 }}
//                               >
//                                 Delete Unit
//                               </div>
//                             </div>
//                           ) : null}
//                           {unit.lecture.map((lecture, lectureIndex) => (
//                             <>
//                               <div
//                                 key={lecture._id}
//                                 className={`d-flex justify-content-between ${
//                                   lectureId === lecture._id
//                                     ? "activeDiv"
//                                     : ""
//                                 }`}
//                                 style={{
//                                   padding: "14px 20px",
//                                   backgroundColor: `${
//                                     lectureId === lecture._id && "#e4e4e4"
//                                   }`,
//                                 }}
//                               >
//                                 <div
//                                   onClick={() =>
//                                     handleClick(
//                                       course.isenrolled,
//                                       courseid,
//                                       lecture._id,
//                                       unit._id
//                                     )
//                                   }
//                                   // to={
//                                   //     course.isenrolled
//                                   //         ? `/code?courseid=${courseid}&lectureid=${lecture._id}&unitid=${unit._id}`
//                                   //         : "#"
//                                   // }
//                                   className="w-100 d-flex"
//                                   style={{ cursor: "pointer" }}
//                                 >
//                                   {course.completedLectures.includes(
//                                     lecture._id
//                                   ) ? (
//                                     <div>
//                                       <img src={checked} width="60%" />
//                                     </div>
//                                   ) : (
//                                     <div>
//                                       <img src={unchecked} width="60%" />
//                                     </div>
//                                   )}
//                                   {lecture.lecturename}
//                                 </div>
//                                 {edit ? (
//                                   <>
//                                     <div
//                                       className="btn btn-primary btn-sm"
//                                       onClick={(e) =>
//                                         setNewLecture({
//                                           lectureid: lecture._id,
//                                           courseunitid: unit._id,
//                                           show: true,
//                                         })
//                                       }
//                                     >
//                                       Edit
//                                     </div>
//                                     <div
//                                       className={`btn btn-danger m-1 ${
//                                         lectureIndex <= 0
//                                           ? "disabled"
//                                           : null
//                                       }`}
//                                       onClick={() =>
//                                         indexListChange(
//                                           unitIndex,
//                                           lectureIndex,
//                                           "INC"
//                                         )
//                                       }
//                                     >
//                                       &uarr;
//                                     </div>
//                                     <div
//                                       className={`btn btn-danger m-1 ${
//                                         lectureIndex >=
//                                         unit.lecture.length - 1
//                                           ? "disabled"
//                                           : null
//                                       }`}
//                                       onClick={() =>
//                                         indexListChange(
//                                           unitIndex,
//                                           lectureIndex,
//                                           "DEC"
//                                         )
//                                       }
//                                     >
//                                       &darr;
//                                     </div>
//                                     <div
//                                       className="btn btn-danger btn-sm"
//                                       onClick={(e) => {
//                                         setLoading(true);
//                                         lectureService.deleteLecture(
//                                           lecture._id
//                                         );
//                                         setUpdate(true);
//                                       }}
//                                     >
//                                       Delete
//                                     </div>
//                                   </>
//                                 ) : null}
//                               </div>
//                               <hr style={{ margin: "2px 20px" }} />
//                             </>
//                           ))}
//                         </Card.Body>
//                       </Accordion.Collapse>
//                     </Card>
//                   </li>
//                 )}
//               </Draggable>
//             ))}
//         </ul>
//       );
//     }}
//   </Droppable>
// </DragDropContext>
