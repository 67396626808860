import React from "react";
import Note from "pages/Notes/Note";
import "./NotesList.css"
function NotesList() {
  return (
    <div className="notes-list">
      <Note />
      <Note />
      <Note />
      <Note />
    </div>
  );
}

export default NotesList;
