import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import demographic from "../../actions/services/demographic.service";
import { UserContext } from "context/UserProvider";
import { useParams } from 'react-router';
import authService from "actions/services/auth.service";
import ChatBubble from "pages/DashBoard/ChatBubble";
import { LoadingContext } from "context/LoadingProvider";



const RadioCommon = ({
  values,
  //   handleChange,
  name,
  register,
  watch,
  getValues,
  setValue,
}) => {
  return (
    <>
      <label className="text-xl p-2">{values.label}</label>
      {values.options.map((item) => (
        <div
        // onChange={(e) => handleChange(e, "citizen")}
        >
          <input
            type="radio"
            value={item}
            name={name}
            className="mr-3"
            required
            {...register(`${name}`)}
          />
          {item}
          {item === "others" && watch(`${name}`) === "others" && (
            <input
              type={Number}
              style={{ padding: "5px", border: "1px solid", marginLeft: "10px" }}
              {...register(`${name}InputText`)}
            />
          )}
        </div>
      ))}
    </>
  );
};

const OnboardingInfo = () => {
  const { register, getValues, setValue, handleSubmit, watch, reset } = useForm();
  const { user, setUser, userProfile } = useContext(UserContext)
  const { isloading, setLoading } = useContext(LoadingContext)
  console.log({userProfile});
  const userData = {
    citizen: {
      label: "Are you South African citizen?",
      options: ["yes", "no"],
    },
    gender: {
      label: "Gender",
      options: ["male", "female", "others"],
    },
    phoneNo: "",
    school: {
      label: "What is the highest school grade you have achieved?",
      options: ["Matric", "Tertiary Diploma", "Bachelors Degree", "Postgraduate Degree"],
    },
    universityName: "",
    courseName: {
      label: "If you went to a university give us the name of the degree you have achieved (e.g Bsc Computer Science) if your option does not appear below, please choose other option",
      options: ["I did not go to university", "BSc Computer Science", "BScEng Computer Engineering", "others"],
    },
    homeLanguage: {
      label: "Home Language",
      options: ["Afrikaans", "English", "Ndebele", "Sepedi", "Sesotho", "Swati", "Tsonga", "Tswana", "Venda", "Xhosa", "Zulu", "others"],
    },
    secondLanguage: {
      label: "Second Language",
      options: ["Afrikaans", "English", "Ndebele", "Sepedi", "Sesotho", "Swati", "Tsonga", "Tswana", "Venda", "Xhosa", "Zulu", "none", "others"],
    },
    race: {
      label: "Race",
      options: ["Black", "Coloured", "Indian", "Asian", "White", "Prefer not to say", "others"],
    },
    salary: {
      label: "Currently, my personal monthly salary (before tax) is:",
      options: ["Unemployed - Not earning a salary or wage", "Less than R 2000", "R2 000 - R4 000", "R4 000 - R6 000", "More than R6 000", "others"],
    },
    disability: {
      label: "Disability",
      options: ["yes", "no"],
    },
  };

  //   const [formDatas, setFormDatas] = useState();

  const {
    citizen,
    gender,
    school,
    courseName,
    homeLanguage,
    secondLanguage,
    race,
    salary,
    disability,
  } = userData;

  const formDataRefine = (formData) => {
    const keys = Object.keys(formData);
    keys.forEach((key) => {
      if (formData[key] === "others")
        formData[key] = getValues(`${key}InputText`);
    });
    return formData;
  };

  const onSubmit = async (e) => {

    const {
      citizen,
      gender,
      school,
      courseName,
      homeLanguage,
      secondLanguage,
      race,
      phoneNo,
      salary,
      disability,
      universityName,
    } = formDataRefine(getValues());
    const _data = {
      sa_citizen: citizen,
      gender: gender,
      phone_number: phoneNo,
      highest_school_grade: school,
      university_name: universityName,
      university_degree: courseName,
      home_language: homeLanguage,
      second_language: secondLanguage,
      race: race,
      monthly_salary: salary,
      disability: disability,
    };
    setLoading(true);
    const data = await demographic.postDemographic(_data);
    if (data.success) {
      authService.updateUserData().then(res=>{
        setUser({...user, ...res.data});
        alert("Thanks for your response!")
      }).finally(()=>{
        setLoading(false);
        window.location.replace('/app/updated-dashboard');
      })
    } else {
      alert("Your Data is already exists in our data base.")
    }

  };

  let { email } = useParams();


  return (
    <div className="flex flex-col justify-center items-center m-2 p-4">
    <ChatBubble
            title={"Onboarding Questions"}
            text= "Lets collect some information about you before you can start learning.."
            showLive={false}
     />
    <div className="bg-white m-12">
    <form onSubmit={handleSubmit(onSubmit)} className="p-8 w-full" >
      <RadioCommon
        watch={watch}
        name="citizen"
        values={citizen}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <RadioCommon
        watch={watch}
        name="gender"
        values={gender}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <label className="text-xl">Cellphone number</label>
      <br />
      <input
        type="text"
        name="phoneNo"
        style={{ padding: "5px", border: "1px solid" }}
        required
        // onChange={(e) => handleChange(e, "phoneNo")}
        {...register(`phoneNo`)}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="school"
        values={school}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <label className="text-xl">University Name:</label>
      <br />
      <input
        name="universityName"
        style={{ padding: "5px", border: "1px solid" }}
        required
        type="text"
        // onChange={(e) => handleChange(e, "universityName")}
        {...register(`universityName`)}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="courseName"
        values={courseName}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="homeLanguage"
        values={homeLanguage}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="secondLanguage"
        values={secondLanguage}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="race"
        values={race}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="salary"
        values={salary}
        // handleChange={handleChange}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <br />
      <br />
      <RadioCommon
        watch={watch}
        name="disability"
        values={disability}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />
      <div className="text-center">
        <button className="btn btn-primary" onClick={handleSubmit} disabled={isloading}>
          {!isloading ? "Start Course" : "Please Wait..."}
        </button> 
      </div>
    </form>
    </div> 
    </div>
    );
};

export default OnboardingInfo;
