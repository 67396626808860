import { useEffect, useState } from "react";

// import Img from 'assets/img/misc/happy_people.jpg'
import { useHistory, Link } from "react-router-dom";

import learningpathService from "actions/services/learningpath.service";
import { useContext } from "react";
import { LoadingContext } from "context/LoadingProvider";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "context/UserProvider";
import LearningPath from "components/LearningPath/LearningPath";
import { PaymentDetailsContext } from "context/PaymentDetailsProvider";
import paymentService from "actions/services/payment.service";
import mixpanel from "mixpanel-browser";
import ReactPixel from "react-facebook-pixel";
import { Modal, Form } from "react-bootstrap";
import { Mixpanel } from "Utils/Mixpanel";

mixpanel.init("4e2be35d7debc0ad57db236937d08d8d", { debug: true });

ReactPixel.init("854803775130939");

function Dashboard({ fromUserDashboard, isPublic }) {
  const { details, setDetails } = useContext(PaymentDetailsContext);
  const [allLPs, setAllLPs] = useState([]);
  const [redirectModal, setRedirectModal] = useState({
    show: false,
    paymentProcessed: "pending", //success, pending, failed
    title: "Payment being processed...",
    paragraph:
      "If your payment is successful, you will automatically be redirected, please click below to redirect to your dashboard.",
  });
  const { isloading, setLoading } = useContext(LoadingContext);
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  const queries = new URLSearchParams(history.location.search);

  const setLearningPaths = async () => {
    setLoading(true);
    const res = await learningpathService.getLearningPathList();
    console.log("=================================");
    console.log(res);
    setAllLPs(res.data);
    setLoading(false);
  };
  useEffect(() => {
    console.log("DASHBOARD", user, fromUserDashboard);

    async function checkForPayment() {
      if (queries.get("id")) {
        setRedirectModal({
          show: true,
          paymentProcessed: "pending",
          title: "Payment being processed...",
          paragraph:
            "If your payment is successful, you will automatically be redirected, please click below to redirect to your dashboard.",
        });
        console.log("checking Payment");

        setLoading(true);
        try {
          const res2 = await paymentService.checkPaymentStatus();
          const DETAILS = JSON.parse(localStorage.getItem("DETAILS"));
          console.log("done checking", res2, DETAILS);
          if (res2.success) {
            console.log("success", res2.success);
            ReactPixel.track("Purchase", {
              currency: "ZAR",
              value: DETAILS.amountToPay,
              content_name: DETAILS.path,
              content_ids: DETAILS.id,
            });
            mixpanel.track("Payment successful", {
              "Course name": DETAILS.path,
              "Payment method": "card",
              "Payment plan": DETAILS.plan,
              Revenue: DETAILS.amountToPay,
              "Discount code": DETAILS.couponcode,
              "Discount percentage": DETAILS.discount,
            });
            mixpanel.track("Course enrolled", {
              "Course name": DETAILS.path,
              "Payment method": "card",
              "Payment plan": DETAILS.plan,
              Revenue: DETAILS.amountToPay,
              "Discount code": DETAILS.couponcode,
              "Discount percentage": DETAILS.discount,
            });
            setRedirectModal({
              show: true,
              paymentProcessed: "success",
              title:
                "Payment successful. Preparing and redirecting to your dashboard.",
              paragraph:
                "You are now being automatically be redirected, but if it doesn't auto-redirect, please click below to redirect to your dashboard.",
            });
            console.log("redirecting....");

            window.location.href =
              window.location.origin + "/updated-dashboard";
            setLoading(false);
          } else {
            mixpanel.track("Payment failed");
            setRedirectModal({
              show: true,
              paymentProcessed: "failed",
              title: "Payment Failed",
              paragraph:
                "Please select your desired course and try again. Ensure your card details were entered correctly. Click below to proceed.",
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setRedirectModal({
            show: true,
            paymentProcessed: "failed",
            title: "Payment Failed",
            paragraph:
              "Please select your desired course and try again. Ensure your card details were entered correctly. Click below to proceed.",
          });
          setLoading(false);
        }
      }
    }
    // IF USER MAKES A PAYMENT
    checkForPayment();

    if (fromUserDashboard || isPublic) {
      Mixpanel.track("Web Page view", {
        "Page url": "/pricing",
        "Page name": "Pricing page",
      });
      setLearningPaths();
    } else {
      //from signup/auth flow
      // if (user.enrolled || user.data.enrolled) {
      //   console.log("to updateddashboard in from dashboard");
      //   history.push("/updated-dashboard");
      //   return;
      // }
      // else if (
      //   user.success &&
      //   (!user.data.username || !user.username) &&
      //   !user.data.completed
      // ) {
      //   console.log("to completesign in from dashboard");
      //   history.push("/completesignin");
      //   return;
      // }
      // else {
      setLearningPaths();
      // }
    }
  }, []);

  const handleSelectLearningPath = async (path) => {
    console.log(path);
    ReactPixel.track("AddToCart", {
      currency: "ZAR",
      value: path.learningpathprice,
      content_name: path.learningpathname,
      content_ids: path.id,
    });

    if (isPublic) {
      window.location.href = window.location.origin + "/app/getstarted";
    }
    setDetails({
      case: "learning-path",
      price: path.learningpathprice,
      id: path._id,
      name: path.learningpathname,
      discount: path.learningpathdiscount,
      couponcode: "",
      code: path.code,
      path,
      country: path?.country,
      plan: "upfront",
    });
    mixpanel.track("Course selected", {
      "Course name": path.learningpathname,
    });

    fromUserDashboard
      ? history.replace(`/coursesummary?path=${path.code}`)
      : history.push(`/coursesummary`);
  };

  useEffect(() => {
    if (allLPs?.length > 0) {
      const fullStackLP = allLPs?.filter(
        (path) => path?.learningpathname === "Full Stack Web Developer"
      )[0];
      if (fullStackLP) {
        handleSelectLearningPath(fullStackLP);
      }
    }
  }, [allLPs]);

  return (
    <div className="container pt-5 pb-5">
      <Modal
        show={redirectModal.show}
        size="md"
        // onHide={() => setRedirectModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-5" style={{ paddingTop: "0px" }}>
          <h2 className="mediumText text-primary mb-3">
            {redirectModal.title}
          </h2>
          <Form>
            <Form.Group className="">{redirectModal.paragraph}</Form.Group>
          </Form>
          {redirectModal.paymentProcessed === "success" && (
            <button
              className=" mt-4 btn btn-block btn-main"
              onClick={() => history.push("/updated-dashboard")}
            >
              Click here if not auto-redirected
            </button>
          )}
          {redirectModal.paymentProcessed === "failed" && (
            <button
              className=" mt-4 btn btn-block btn-main"
              onClick={() =>
                setRedirectModal({ ...redirectModal, show: false })
              }
            >
              Select course & Try again
            </button>
          )}
        </Modal.Body>
      </Modal>

      {!isloading && (
        <div className="row mt-5 justify-content-between align-items-center no-gutters">
          <h1 className="text-white heading text-start">
            {isPublic
              ? "Pricing | Upfront placement offer available"
              : "Choose your course"}
          </h1>
          {isPublic && (
            <p className="text-white">
              Hold an internship placement offer upon enrolling in fullstack,
              frontend or backend. Learn with intent.{" "}
            </p>
          )}

          {/* <Link
          className="smalltext text-white text-underline"
          to="coursepreferencequiz"
        >
          Not sure which course to select?
        </Link> */}
        </div>
      )}
      <div className="row mt-5">
        {allLPs
          .filter((path) =>
            fromUserDashboard && !isPublic
              ? path.index !== undefined && !path.code.includes("IC")
              : path.index !== undefined
          )
          .sort((a, b) => a.index - b.index)
          .map((path, index) => (
            <LearningPath
              key={index.toString()}
              path={path}
              handleSelectLearningPath={handleSelectLearningPath}
              fromUserDashboard={fromUserDashboard}
              isPublic={isPublic}
            />
          ))}
      </div>
    </div>
  );
}

export default Dashboard;
