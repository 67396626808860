import { useContext, useEffect } from "react";
import "./VideoSidebar.css";
// import useVideoPlayer from "./hooks/useVideoPlayer";
import { FiPenTool } from "react-icons/fi";
import { MdVolumeOff, MdLibraryBooks, MdOutlineVolumeUp } from "react-icons/md";
import { UserContext } from "context/UserProvider";

function VideoSidebar({
  muted,
  handleMuteUnmute,
  handleTakeNote,
  handleOpenContentDrawer,
  videos,
  completedLecturesCount,
  modal,
  setModal,
  played,
  onboarding,
}) {
  const { userNotSignedUp, user, userProgress } = useContext(UserContext);

  // console.log("userNotSignedUp", userNotSignedUp);
  // const { handleSkipToTime } = useVideoPlayer(videoRef);
  return (
    <div className="qr-videoSidebar">
      <div className="qr-videoSidebar__button">
        {!onboarding && (
          <>
            <MdLibraryBooks
              className="icons"
              onClick={handleOpenContentDrawer}
            />

            <div>
              <small>
                <strong>
                  {user && user.success
                    ? userProgress && userProgress.completedlecturecount
                    : (userNotSignedUp &&
                        userNotSignedUp.completedlectures.length) ||
                      0}
                  {}/{videos.length}
                </strong>
              </small>
            </div>
          </>
        )}
      </div>

      <div
        className="qr-videoSidebar__button"
        // onClick={handleTakeNote}
        onClick={() => {
          // handleTakeNote();
          user && user.success
            ? setModal({
                ...modal,
                show: true,
                title: "Notes is coming soon!",
                description:
                  "Soon you will be able to take and track your learnings all along your journey.",
                CustomComponent: null,
              })
            : handleTakeNote();
        }}
      >
        <FiPenTool className="icons" />
        <div>
          <small>
            <strong>Note</strong>
          </small>
        </div>
      </div>

      <div className="qr-videoSidebar__button" onClick={handleMuteUnmute}>
        {muted ? (
          <MdVolumeOff className="icons" />
        ) : (
          <MdOutlineVolumeUp className="icons" />
        )}
        <div>
          <small>
            <strong>{muted ? "Unmute" : "Mute"}</strong>
          </small>
        </div>
        {/* <p>{Math.round((played)*100)}%</p> */}
      </div>

      {/* {quizOptions.quizOptions.map((quizOption, index) => (
        <div
          className={`videoSidebar__button ${
            quizOptions.isCorrectAnswer ? "yellow" : "purple"
          }`}
          onClick={() => handleSkipToTime(index, quizOptions.optionRedirects)}
        >
          {quizOption}
        </div>
      ))} */}
    </div>
  );
}

export default VideoSidebar;
