import { TableContainer } from "pages/Competitions/Competitions.styled";
import { useEffect, useState, useRef } from "react";
import TableRow from "./TableRow";

function Table({ leaderboard }) {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    fetch("https://randomuser.me/api/1.1/?results=15")
      .then((response) => response.json())
      .then((data) => setUsers(data.results));
  }, []);

  const isLoading = users === null;
  return (
    <TableContainer>
      <div className="overflow-auto">
        <table className="table table-hover table-divider">
          <thead>
            <tr>
              <th className="table-shrink">
                <p className="m-0 p-0">#</p>
              </th>
              {/* <th className="table-shrink">
                <p className="m-0 p-0">Avatar</p>
              </th> */}
              <th>Team Name</th>
              <th>Completed in</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={6} className="text-center">
                  <em className="text-muted">Loading...</em>
                </td>
              </tr>
            ) : (
              leaderboard.map((team, index) => (
                <TableRow
                  key={index}
                  index={index + 1}
                  team={team?.teamid}
                  mark={team?.submission?.mark}
                  startdate={team?.startdate}
                  enddate={team?.enddate}
                  timetaken={team?.timetaken}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    </TableContainer>
  );
}

export default Table;
