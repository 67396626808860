import { UserContext } from "context/UserProvider";
import { memo, useContext } from "react";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeDown,
  FaVolumeOff,
  FaVolumeMute,
} from "react-icons/fa";

import { VolumeControlsContainer } from "./VideoPlayerControls.styled";
import useVideoStore from "zustand-store/videoPlayerStore";

const BottomLeftControls = ({
  playing,
  play,
  muted,
  primaryColor,
  volume,
  showControlVolume,
  setShowControlVolume,
  setVolumeAction,
  setMutedAction,
  titleMedia,
  extraInfoMedia,
}) => {
  const { user } = useContext(UserContext);
  const videoMode = useVideoStore((state) => state.videoMode);

  // console.log("BOTTOM LEFT CONTROLS RENDERED", user.username);
  return (
    <div className="start" onClick={(e) => e.stopPropagation()}>
      {videoMode === "lecture" && (
        <>
          <div className="item-control">
            {!playing && <FaPlay onClick={play} />}
            {playing && <FaPause onClick={play} />}
          </div>
          {muted === false && (
            <VolumeControlsContainer
              className="item-control"
              primaryColor={primaryColor}
              percentVolume={volume}
            >
              {showControlVolume === true && (
                <div
                  className="volumn-controll"
                  onMouseLeave={() => setShowControlVolume(false)}
                  onMouseEnter={() => setShowControlVolume(true)}
                >
                  <div className="box-connector" />
                  <div className="box">
                    <input
                      className="zp-volume-input"
                      type="range"
                      min={0}
                      max={100}
                      value={volume}
                      onChange={(e) => setVolumeAction(+e.target.value)}
                      title=""
                    />
                  </div>
                </div>
              )}

              {volume >= 60 && (
                <FaVolumeUp
                  onMouseEnter={() => setShowControlVolume(true)}
                  onClick={() => setMutedAction(true)}
                />
              )}

              {volume < 60 && volume >= 10 && (
                <FaVolumeDown
                  onMouseEnter={() => setShowControlVolume(true)}
                  onClick={() => setMutedAction(true)}
                />
              )}

              {volume < 10 && volume > 0 && (
                <FaVolumeOff
                  onMouseEnter={() => setShowControlVolume(true)}
                  onClick={() => setMutedAction(true)}
                />
              )}

              {volume <= 0 && (
                <FaVolumeMute
                  onMouseEnter={() => setShowControlVolume(true)}
                  onClick={() => setVolumeAction(0)}
                />
              )}
            </VolumeControlsContainer>
          )}

          {muted === true && (
            <div className="item-control">
              <FaVolumeMute onClick={() => setMutedAction(false)} />
            </div>
          )}
        </>
      )}

      {/* <div className="item-control">
  <FaUndoAlt onClick={() => previousSeconds(5)} />
</div>

<div className="item-control">
  <FaRedoAlt onClick={() => nextSeconds(5)} />
</div> */}

      <div className="item-control info-video">
        <span className="info-first">{titleMedia}</span>
        <span className="info-secund">{extraInfoMedia}</span>
      </div>

      <div className="item-control info-video">
        {/* <span className="info-first">{titleMedia}</span> */}
        <span className="info-secund">Keep it up {user?.username}!</span>
      </div>
    </div>
  );
};

export default memo(BottomLeftControls);
