import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_BACKEND_URL;

class JobsService {
  getJobsByUser() {
    return axios
      .get(API_URL + "/job/getjobsforuser", {
        headers: authHeader(),
      })
      .then((response) => {
        // console.log("getTeam", response.data);
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }

  getJobById(jobId) {
    return axios
      .get(API_URL + "/job/getjobforuser/" + jobId, {
        headers: authHeader(),
      })
      .then((response) => {
        // console.log("getTeam", response.data);
        return response.data;
      })
      .catch((rej) => {
        return rej.response;
      });
  }
}

export default new JobsService();
