import { useState, useRef } from "react";

function datesDifference(enddate, startdate) {
  console.log("enddate", typeof enddate);
  const b = new Date(enddate);
  const a = new Date(startdate);
  const diffTime = Math.abs(b - a);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

function capitalize(str) {
  return str
    .split(" ")
    .map((s) => {
      return s.charAt(0).toUpperCase() + s.substr(1);
    })
    .join(" ");
}

function TableRow({ team, index, mark, startdate, enddate, timetaken }) {
  const [expanded, setExpanded] = useState(false);
  const expanderBody = useRef(null);

  const toggleExpander = (e) => {
    if (e.target.type === "checkbox") return;

    // if (!expanded) {
    //   setExpanded(true);
    // } else {
    //   setExpanded(false);
    // }
  };

  return [
    <tr key="main" onClick={toggleExpander}>
      <td className="text-nowrap">{index}</td>
      {/* <td>
        <img
          className="preserve-width border-circle"
          src={user.picture.thumbnail}
          width={48}
          alt="avatar"
        />
      </td> */}
      <td>
        {capitalize(team?.name)}
        <br />
        <small>{team?.members.length} members</small>
      </td>
      <td>{timetaken} days</td>
      <td>{mark}</td>
    </tr>,
    expanded && (
      <tr className="expandable" key="tr-expander">
        <td className="background-muted" colSpan={6}>
          <div ref={expanderBody} className="inner grid">
            {/* <div className="width-1-4 text-center">
              <img
                className="preserve-width border-circle"
                src={user.picture.large}
                alt="avatar"
              />
            </div>
            <div className="width-3-4">
              <h3>{capitalize(team?.name)}</h3>
              <p>
                Address:
                <br />
                <i>
                  {capitalize(user.location.street)}
                  <br />
                  {user.location.postcode} {capitalize(user.location.city)}
                  <br />
                  {user.nat}
                </i>
              </p>
              <p>
                E-mail: {user.email}
                <br />
                Phone: {user.phone}
              </p>
              <p>Date of birth: {formatDate(user.dob)}</p>
            </div> */}
          </div>
        </td>
      </tr>
    ),
  ];
}

export default TableRow;
