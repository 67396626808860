import { useState } from "react";
import { useHistory } from "react-router-dom";
import ZCoin from "components/ZCoin/ZCoin";
import WalletBalance from "pages/Wallet/WalletBalance";
import "./CourseCard.css";
import { useQueryClient, useMutation } from "react-query";
import walletService from "actions/services/wallet.service";
import ZPLoading from "pages/Watch/components/ZPLoading";
import Error from "components/Error/Error";

function CourseModalBody({ price, courseid }) {
  const history = useHistory();
  const [canAfford, setCanAfford] = useState(true);
  const queryClient = useQueryClient();

  const purchaseCourseMutation = useMutation(
    () => walletService.purchaseCourse(courseid, price),
    {
      enabled: courseid && price,
      onSuccess: (data) => {
        queryClient.invalidateQueries("walletBalance");
      },
    }
  );

  const handleBuyCourse = () => {
    // alert("BUY COURSE");
    purchaseCourseMutation.mutate();
  };

  const getWalletBalance = (balance) => {
    console.log("BALANCE FROM FUNC", balance);
    setCanAfford(balance > price);
  };

  const displayButton = () => {
    if (purchaseCourseMutation?.data?.success === true) {
      return (
        <button
          className={`custom-btn success-btn w-100`}
          onClick={() => history.push(`/course/my-new-course/${courseid}`)}
        >
          Access your new course!
        </button>
      );
    }
    if (canAfford) {
      return (
        <button
          className={`custom-btn success-btn w-100`}
          onClick={handleBuyCourse}
        >
          <span>Buy with </span>
          <ZCoin
            number={price}
            toolTipPlacement="bottom"
            handleZCoinClick={() => {}}
          />
        </button>
      );
    } else {
      return (
        <button
          className={`custom-btn danger-btn w-100`}
          onClick={() => history.push(`/wallet`)}
        >
          <ZCoin
            number={price}
            toolTipPlacement="bottom"
            handleClick={() => {}}
          />
          <span>needed. Earn more. </span>
        </button>
      );
    }
  };

  if (purchaseCourseMutation.isLoading) return <ZPLoading />;
  if (purchaseCourseMutation.isError) {
    return (
      <div className="course-modal">
        <Error
          message={
            typeof purchaseCourseMutation.error === "string"
              ? purchaseCourseMutation.error
              : JSON.stringify(purchaseCourseMutation.error)
          }
        />
      </div>
    );
  }
  if (purchaseCourseMutation?.data?.success === false) {
    return (
      <div className="course-modal text-center">
        <p>{purchaseCourseMutation?.data?.message}</p>
      </div>
    );
  }
  return (
    <div className="course-modal">
      {/* <span>
        <ZCoin number={30} />
        <span>needed to unlock this course</span>
      </span> */}
      <WalletBalance getWalletBalance={getWalletBalance} />

      <div className="w-100 text-center mt-4">
        {!canAfford && !purchaseCourseMutation?.data?.success && (
          <small className="badge danger-btn mb-3">Insufficient balance.</small>
        )}
        {/* {price <= walletBalance && (
          <small className="badge success-btn mb-3">
            You can unlock this course with Z-Coins
          </small>
        )} */}

        {displayButton()}
      </div>
    </div>
  );
}

export default CourseModalBody;
