function DownloadBtn({ assetLink, fileName, children }) {
  const handleDownload = () => {
    fetch(assetLink)
      .then(response => response.blob())
      .then(blob => {
        const assetLink = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = assetLink;
        a.download = fileName;
        a.click();
      })
      .catch(error => console.log(error));
  };

  return (
    <a 
      href={assetLink} 
      target="_blank" 
      rel="noreferrer"
      className='w-full inline-block text-lg text-center rounded-md bg-blue-700 hover:bg-blue-600 py-2 text-white' 
    >
      {children}
    </a>
  );
}

export default DownloadBtn;