import Split from "react-split";

import "./compiler.scss";

function TestingSplit() {
  return (
    <Split className="split">
      <div className="w-1/3 h-screen bg-white"></div>
      <div className="w-2/3 h-screen bg-purple-400">
        <Split className="split-col h-screen" direction="vertical">
          <div className="w-full h-1/2 bg-black"></div>
          <div className="w-full h-1/2 bg-white"></div>
        </Split>
      </div>
    </Split>
  );
}

export default TestingSplit;
