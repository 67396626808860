import { MarkdownContainer } from "pages/Competitions/Competitions.styled";

function MarkdownToHTML({ html }) {
  // console.log("QUESTION DESCRIPTION: ", html);
  return (
    <MarkdownContainer>
      <div
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
    </MarkdownContainer>
  );
}

export default MarkdownToHTML;
