import "./Onboarding.scss";

function OnboardingFooter({
  handleContinue,
  customClassName,
  customButtonName,
  customButtonClass,
}) {
  return (
    <div className={"onb-footer " + customClassName}>
      <button
        className={"btn-main m-2 p-2 " + customButtonClass}
        onClick={handleContinue}
      >
        {customButtonName ? customButtonName : "Continue"}
      </button>
    </div>
  );
}

export default OnboardingFooter;
