import dsEditorService from "actions/services/ds-editor.service";
import ScoreIndicator from "component/ScoreIndicator/ScoreIndicator";
import { UserContext } from "context/UserProvider";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import useVideoStore from "zustand-store/videoPlayerStore";
import NotebookFeedbackModal from "./notebook-feedback-modal";

function DSCourseEditor({
  lectureId,
  updateFrontendChallCompletion,
  refreshCourseData,
}) {
  const [loading, setLoading] = useState(false);
  const dsChallenge = useVideoStore((state) => state.dsChallenge);
  const { user } = useContext(UserContext);
  const [notebookUrl, setNotebookUrl] = useState(null);
  const [feedbackUrl, setFeedbackUrl] = useState(null);
  const [webviewLoading, setWebviewLoading] = useState(true);
  const [courseId, setCourseId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [submissions, setSubmissions] = useState(null);
  const [challengeId, setChallengeId] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const handleLoadStart = () => {
    setWebviewLoading(true); // Set loading to true when webview starts loading
  };

  const handleLoadEnd = () => {
    setWebviewLoading(false); // Set loading to false when webview finishes loading
  };

  const init = async () => {
    if (userId) {
      setLoading(true);
      const submissions = await dsEditorService.getAllDSSubmissions({
        lectureid: lectureId,
        student_id: userId,
      });
      setSubmissions(submissions?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [lectureId, userId]);

  useEffect(() => {
    if (user && dsChallenge) {
      const courseId = dsChallenge?.course;
      const userid = user?.userid ?? user?.data?.userid;
      const challId = dsChallenge?.datasciencechallengeid?._id;

      setCourseId(courseId);
      setUserId(userid);
      setChallengeId(challId);
      console.log(user, "userrrr", userid);
      dsEditorService
        .getNotebookUrl({
          challenge_id: challId,
          bootcamp_id: courseId,
          student_id: userid,
        })
        .then((x) => {
          setNotebookUrl(x?.data?.url);
          setFeedbackUrl(x?.data?.feedbackUrl)
        });

      // setNotebookUrl(
      //   // `https://www.notebook.zaio.io/notebooks/${courseId}/submitted/${userid}/${challId}/${challId}.ipynb`
      //   `https://35.179.128.67/notebooks/${courseId}/submitted/${userid}/${challId}/${challId}.ipynb`
      // );
    }
  }, [user, dsChallenge]);

  const fetchResult = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      student_id: userId,
      challenge_id: challengeId,
      bootcamp_id: courseId,
      lectureid: lectureId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setLoading(true);
    const API_URL = process.env.REACT_APP_BACKEND_URL;

    fetch(
      // "https://www.notebook.zaio.io/notebook-editor/autograde",
      API_URL + "/datascience/autograde-notebook",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setSubmissions(result?.data);

        if (
          result?.passed &&
          result?.data?.filter((subm) => subm?.passed)?.length <= 1
        ) {
          updateFrontendChallCompletion();
          refreshCourseData();
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="px-5">
      <div className="row">
        <div
          className="col-3 bg-white d-flex flex-column"
          style={{
            overflowX: "scroll",
            height: "100vh",
          }}
        >
          <div>
            <p className="m-0 h4 my-3 color-primary">
              {dsChallenge?.lecturename}
            </p>
            <div className="d-flex justify-content-between">
              {/* <button
                onClick={() => {
                  setChall(2);
                  setTestResult(null);
                }}
                className={`btn btn${
                  chall === 2 ? "" : "-outline"
                }-primary shadow-sm`}
              >
                challenge 2
              </button> */}
            </div>
          </div>

          <div>
            <div>
              <button
                disabled={loading || webviewLoading}
                className={`btn btn-primary shadow-sm mb-2 w-100`}
                onClick={fetchResult}
              >
                Submit notebook 
              </button>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-0 h5 my-4 color-primary font-normal">
                Submissions history
              </p>
              {loading && (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <img
                    className="ms-4"
                    width={36}
                    alt="loader"
                    height={36}
                    src={"/loader.svg"}
                  />{" "}
                </div>
              )}
            </div>
            <div>
              {submissions?.length > 0
                ? submissions?.map((subm, index) => (
                    <div className="shadow-sm border border-grey my-3 rounded px-2 d-flex align-items-center justify-content-between">
                      <div>
                        <p className="m-0 text-large font-bold">
                          Score: {subm?.marks}/{subm?.maxMarks}
                        </p>
                        <p className="m-0">
                          {subm?.passed ? "Passed" : "Failed"}
                          {index === 0 && (
                            <button
                              className="font-bold text-secondary ms-2"
                              onClick={() => {
                                setShowFeedbackModal(subm);
                              }}
                            >
                              more details
                            </button>
                          )}
                        </p>
                        <p className="m-0">
                          {moment(subm?.createdAt).fromNow()}
                        </p>
                      </div>
                      <div>
                        <ScoreIndicator
                          width={80}
                          height={80}
                          score={subm?.marks}
                          maxScore={subm?.maxMarks}
                        />
                      </div>
                    </div>
                  ))
                : submissions !== null && (
                    <p>No submissions found for "{dsChallenge?.lecturename}"</p>
                  )}
            </div>
          </div>
        </div>
        <div className="col-9">
          {webviewLoading && (
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                className="ms-4"
                width={120}
                alt="loader"
                height={120}
                src={"/loader.svg"}
              />{" "}
            </div>
          )}
          {notebookUrl && (
            <div
              style={{
                height: "100vh",
                overflow: "hidden",
                margin: "auto",
              }}
            >
              {notebookUrl}
              <iframe
                onLoad={handleLoadEnd}
                onLoadStart={handleLoadStart}
                id="jupyter-iframe"
                src={notebookUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  display: webviewLoading ? "none" : "block",
                }}
                title="Jupyter Notebook"
              />
            </div>
          )}
        </div>
      </div>

      <NotebookFeedbackModal
        feedbackUrl={feedbackUrl}
        show={showFeedbackModal}
        onHide={() => {
          setShowFeedbackModal(null);
        }}
      />
    </div>
  );
}

export default DSCourseEditor;
