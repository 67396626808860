import competitionsService from "actions/services/competitions.service";
import { CompetitionSubmissionFormContainer } from "pages/Competitions/Competitions.styled";
import ZPLoading from "pages/Watch/components/ZPLoading";
import { useState } from "react";
import { useMutation } from "react-query";

function SubmissionForm({ teamid, competitionid, submission }) {
  const [state, setState] = useState({
    demo: submission?.demo || "",
    github: submission?.github || "",
    link: submission?.link || "",
  });
  const [isSubmitted, setIsSubmitted] = useState(!!submission?.date);

  const submitMutation = useMutation(
    () =>
      competitionsService.submitSolution(
        teamid,
        competitionid,
        state.github,
        state.link,
        state.demo
      ),
    {
      onSuccess: (data) => {
        window.location.reload();
      },
    }
  );

  const handleFormSubmit = () => {
    submitMutation.mutate();
  };

  const displayButton = () => {
    const hasMark = submission?.mark;
    if (submission?.date)
      return (
        <p className="w-100 text-center p-2">
          <em>
            Solution submitted on <mark>{submission?.date}</mark>.<br />
            {hasMark ? (
              <span>
                Score: <mark>{submission?.mark}</mark>
              </span>
            ) : (
              <span>Solution being marked.</span>
            )}
          </em>
        </p>
      );

    return (
      <div className="w-100 btn btn-main m-2 p-2" onClick={handleFormSubmit}>
        Submit Solution
      </div>
    );
  };

  if (submitMutation.isLoading) return <ZPLoading />;
  if (submitMutation.isError) {
    return <Error message={submitMutation.error} />;
  }
  if (submitMutation?.data?.success === false) {
    return (
      <div className="course-modal text-center">
        <p>{submitMutation?.data?.message}</p>
      </div>
    );
  }
  // return null;
  return (
    <CompetitionSubmissionFormContainer>
      <h1>Submit Solution</h1>

      <div className="d-flex flex-column align-items-center w-100">
        <form onSubmit={handleFormSubmit}>
          <div className="w-100">
            <label
              className="apercuProText font_16 pb-2 pt-2"
              htmlFor="username"
            >
              Github Link to code
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="username"
              placeholder="github.com/username/repo"
              value={state.github}
              onChange={(e) => setState({ ...state, github: e.target.value })}
              disabled={isSubmitted}
              required
            />
          </div>
          <div className="w-100">
            <label
              className="apercuProText font_16 pb-2 pt-2"
              htmlFor="username"
            >
              Solution Demo (Loom/YouTube/Anything that allows organizers to
              view)
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="username"
              placeholder="youtube.com/watch?v=videoId"
              value={state.demo}
              onChange={(e) => setState({ ...state, demo: e.target.value })}
              disabled={isSubmitted}
              required
            />
          </div>

          <div className="w-100">
            <label
              className="apercuProText font_16 pb-2 pt-2"
              htmlFor="username"
            >
              Deployed link
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="username"
              placeholder="www.example.netlify.com"
              value={state.link}
              disabled={isSubmitted}
              onChange={(e) => setState({ ...state, link: e.target.value })}
              required
            />
          </div>

          {displayButton()}
        </form>
      </div>
    </CompetitionSubmissionFormContainer>
  );
}

export default SubmissionForm;
