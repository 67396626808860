import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACKEND_URL;

class LearningPathService {
  getLearningPathList() {
    // console.log(API_URL);
    return axios
      .get(API_URL + "/learningpath")
      .then((res) => {
        // console.log('cl',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej)
        return rej.response;
      });
  }
  getLearningPath(id) {
    // console.log(id)
    return axios
      .get(API_URL + "/learningpath/" + id, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response)
        return rej.response;
      });
  }
  getLearningPathUserProfile(id, email) {
    // console.log(id)
    return axios
      .get(API_URL + "/learningpath/UserProfile/" + id + "/" + email)
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response)
        return rej.response;
      });
  }
  enrollLearningPath(pathid) {
    return axios
      .post(
        API_URL + `/dashboard/mylearningpaths/${pathid}`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log('ENROLLED',res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log('ENROLLED ERROR',rej.response);
        return rej.response;
      });
  }

  getLearningPaths() {
    // console.log(authHeader());
    return axios
      .get(API_URL + "/dashboard/mylearningpaths", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('/dashboard/mylearningpaths',res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log('/dashboard/mylearningpaths',rej.response);
        return rej.response;
      });
  }

  getLearningPathsUserProfile(email) {
    // console.log(authHeader());
    return axios
      .get(API_URL + "/dashboard/mylearningpathsUserProfile/" + email)
      .then((res) => {
        // console.log('then',res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return rej.response;
      });
  }

  getContinueLearning() {
    // console.log("authHeader",authHeader());
    return axios
      .get(API_URL + "/dashboard/continuelearning", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return rej.response;
      });
  }

  autoEnroll(learningpathid) {
    console.log("autoenrolling", learningpathid, authHeader());
    return axios
      .get(API_URL + "/dashboard/mylearningpaths/" + learningpathid, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log('then', res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log('catch', rej.response);
        return rej.response;
      });
  }

  autoEnrollUserProfile(learningpathid, email) {
    // console.log("learningpathid", learningpathid, authHeader());
    return axios
      .get(API_URL + `/dashboard/mylearningpathsUserProfile/${learningpathid}/${email}`)
      .then((res) => {
        // console.log('then', res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log('catch', rej.response);
        return rej.response;
      });
  }
}

export default new LearningPathService();
