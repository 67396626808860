export const Constants = {
  ZCoinExplanation:
    "Z-Coin, a virtual currency that rewards you with courses for upskilling yourself and introducing more learners. Earn by completing lessons and inviting friends.",
  TeamStreaksExplanation:
    "Team Streak rewards you with gifts and team opportunities for showing teamwork. Earn by creating team, and ensuring all team members are active.",
  SoloStreaksExplanation:
    "Solo Streak measures your consistency that will show on your profile for job referrals. Stay consistent by watching atleast 1 video a day.",
  WaitingListBarText:
    "On request from users, we have started a community group. Join now!",
  redirectUrl: "https://t.me/zaiodevcommunity",
  onboardingVideo:
    "https://zaiocontent.s3.eu-west-2.amazonaws.com/onboarding-video-redone/Onboarding+video+redone+production-ready+(1).mp4",
  onboardingVideoTitle: "Welcome! 50% off on Fullstack",
  onboardingVideoDescription:
    "Learn to code from industry ready and NQF level 5 certified courses, with team based learning, tutor support and more. Its life changing! ",
  onboardingVideoDuration: "2",
  loggedout: {
    MenuItems: [
      // {
      // 	title: 'Browse Courses',
      // 	url: '/allcourses',
      // 	cName: 'nav-links'
      // },
      // {
      // 	title: 'Zaio For Business',
      // 	url: '/business',
      // 	cName: 'nav-links zfb'
      // },
      {
        title: "Login",
        url: "/login",
        cName: "nav-signin text-white",
      },
      {
        title: "Get Started",
        url: "/onboarding",
        cName: "get-started-btn btnDefault btnMain yellow",
      },
    ],
  },
  loggedin: {
    MenuItems: [
      // {
      // 	title: 'Dashboard',
      // 	url: '/dashboard',
      // 	cName: 'nav-links'
      // },
      // {
      // 	title: 'All courses',
      // 	url: '/allcourses',
      // 	cName: 'nav-links  nav-signin'
      // },
      {
        title: "Log out",
        url: "/logout",
        cName: "get-started-btn btnDefault btnMain yellow",
      },
      // {
      //   title: "Settings",
      //   url: "/settings",
      //   cName: "get-started-btn btnDefault btnMain yellow",
      // },
    ],
  },
};

export const Courses = [
  {
    lp: "IC",
    iscoursepro: false,
    coursediscount: 100,
    coursediscountexpiry: 7,
    courseprice: 0,
    coursetags: ["Introduction to HTML", "Introduction to CSS"],
    courseunit: [
      "602a195305614a00218fa392",
      "602a196005614a00218fa393",
      "602a196a05614a00218fa394",
      "605476ebfdbaf00021b428b3",
      "6054782ffdbaf00021b428ba",
      "605478fdfdbaf00021b428c1",
      "605479adfdbaf00021b428c6",
      "60547a8afdbaf00021b428cb",
      "60547d11fdbaf00021b428dc",
      "60547d61fdbaf00021b428df",
      "60547d9ffdbaf00021b428e2",
      "60547dbffdbaf00021b428e5",
      "60547dfcfdbaf00021b428e8",
      "60547e43fdbaf00021b428ed",
    ],
    _id: "602a18ae05614a00218fa391",
    courselevel: "beginner",
    coursename:
      "Introduction to HTML: Starting on the Netflix Landing Page Clone",
    courseteacher: "Akhil Boddu",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634758050/p0tmtdwaslkkh7hq64xx.png",
    courseduration: "2 hrs 30 mins",
  },
  {
    lp: "IC",
    iscoursepro: true,
    coursediscount: 50,
    coursediscountexpiry: 7,
    courseprice: 2000,
    coursetags: [
      "Intoduction of CSS",
      "Advanced CSS",
      "Clone the Netflix Page",
    ],
    courseunit: [
      "6053712685432a00210efd4b",
      "6053734f85432a00210efd4d",
      "605373ea85432a00210efd4f",
      "6053754285432a00210efd55",
      "605394c0b786f0002147bc95",
      "6053aba5f4d5810021c7a296",
      "6053acf7f4d5810021c7a29b",
      "6053af58f4d5810021c7a2a4",
      "6053ae81f4d5810021c7a2a1",
      "6053afc5f4d5810021c7a2a6",
      "6053b30df4d5810021c7a2af",
      "6053b3bef4d5810021c7a2b1",
      "6053b97cf4d5810021c7a2b4",
      "6053be3b312bf500217a1e39",
      "6053bb32f4d5810021c7a2b8",
      "6053c08a312bf500217a1e3d",
    ],
    _id: "602bd41ec38d5f0021a60332",
    courselevel: "beginner",
    coursename:
      "Introduction to CSS: Continuing on the Netflix Landing Page Clone",
    courseteacher: "Akhil Boddu",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634758209/hchreqqmi9qhebep7ju3.png",
    courseduration: "4 hrs",
  },
  {
    lp: "FE",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1500,
    coursetags: [
      "Variables",
      "Conditions",
      "Functions",
      "Loops",
      "Async JS",
      "Objects",
      "Classes",
      "DOM",
      "Instagram",
    ],
    courseunit: [
      "605486affdbaf00021b428f3",
      "60548876fdbaf00021b428fa",
      "605488e0fdbaf00021b428fd",
      "60548c73fdbaf00021b42908",
      "6055d472d21dfd00217a7ea4",
      "6055c64cd21dfd00217a7e98",
      "6055d47fd21dfd00217a7ea5",
      "6055d4ca03a2610021721afc",
      "6055d4e203a2610021721afe",
      "6055d4d903a2610021721afd",
      "6055d4ed03a2610021721aff",
      "6055d4f503a2610021721b00",
    ],
    _id: "605485e6fdbaf00021b428f2",
    courselevel: "beginner",
    coursename:
      "Introduction to JavaScript: Learn the fundamentals of programming",
    courseteacher: "Akhil Boddu",
    courseduration: "5 hrs",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634573178/b3hcsteuzbhlvh9yxuat.png",
  },
  {
    lp: "FE",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1200,
    coursetags: ["More HTML", "More CSS", "Problem Solving", "Build Youtube!"],
    courseunit: [
      "6051d2875686480021f5f0f4",
      "602bfde03d47dd0021379aba",
      "602bfde83d47dd0021379abb",
      "602bfdf93d47dd0021379abc",
      "602bfe033d47dd0021379abd",
      "602bfe0a3d47dd0021379abe",
      "602bfe123d47dd0021379abf",
      "602bfe193d47dd0021379ac0",
      "602bfe203d47dd0021379ac1",
      "602bfe283d47dd0021379ac2",
      "602bfe303d47dd0021379ac3",
      "602bfe383d47dd0021379ac4",
      "602bfe4b3d47dd0021379ac5",
    ],
    _id: "602bfce33d47dd0021379ab9",
    courselevel: "beginner",
    coursename: "Building YouTube using HTML & CSS",
    courseteacher: "Akhil Boddu",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634572029/gvzob3poig7968zuubwm.png",
    courseduration: "3 hrs 15 mins",
  },
  {
    lp: "FE",
    iscoursepro: false,
    coursediscount: 40,
    coursediscountexpiry: 0,
    courseprice: 2000,
    coursetags: [
      "HTML",
      " CSS",
      " JavaScript",
      " OOP",
      " Add notes",
      " Delete notes",
      " Keep UI",
      " Local Storage",
      " Deployment with Netlify.",
    ],
    courseunit: [
      "60db520ee66a7b00210ff772",
      "60db5278e66a7b00210ff775",
      "60db52f5e66a7b00210ff778",
      "60db54b7e66a7b00210ff781",
      "60db5742e66a7b00210ff78c",
      "60db5890e66a7b00210ff799",
      "60db596fe66a7b00210ff79d",
      "60db2f8112c93a0021dd2dfc",
    ],
    _id: "60587618c12b83ff0e7ff355",
    courselevel: "beginner",
    coursename:
      "Building Googles note taking app: Google Keep with HTML & CSS.",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634573553/gsrgczvvlbu40yo4zyvh.png",
    courseteacher: "Akhil Boddu",
    courseduration: "2 hrs",
  },
  {
    lp: "FE",
    iscoursepro: false,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1500,
    coursetags: [
      "HTML",
      " CSS",
      " JavaScript",
      " OOP",
      " Add notes",
      " Delete notes",
      " Keep UI",
      " Local Storage",
      " Deployment with Netlify.",
    ],
    courseunit: [
      "60db5bc3e66a7b00210ff7a0",
      "60db5c9ee66a7b00210ff7a5",
      "60db5d40e66a7b00210ff7a8",
      "60db5e29e66a7b00210ff7ab",
      "60db620ae66a7b00210ff7b4",
      "60db6391e66a7b00210ff7bf",
      "60db644be66a7b00210ff7c2",
      "60db64b1e66a7b00210ff7c5",
      "60db6536e66a7b00210ff7c8",
      "60db67b7e66a7b00210ff7cd",
      "60db689ee66a7b00210ff7d4",
      "60db6968e66a7b00210ff7db",
      "60db69cee66a7b00210ff7e0",
      "60db6a34e66a7b00210ff7e3",
      "60db6a8ae66a7b00210ff7e6",
      "60db6b27e66a7b00210ff7eb",
      "60db6c08e66a7b00210ff7f0",
      "60db6c5be66a7b00210ff7f4",
      "60db6c99e66a7b00210ff7f7",
    ],
    _id: "60db25a003b0e8174a324f6f",
    courselevel: "beginner",
    coursename:
      "Introduction to JavaScript DOM by making the Google Keep App functional",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634573994/lohlfwbegtddonz6xn1f.png",
    courseteacher: "Akhil Boddu",
    courseduration: "4 hrs",
  },
  {
    lp: "FE",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 30,
    courseprice: 1500,
    coursetags: [
      "Amazon clone",
      " Routing",
      " Reducers",
      " Database connection",
      " Context API",
      " Hooks",
      " more",
    ],
    courseunit: [],
    _id: "610a376ae2667400219f09ab",
    courselevel: "intermediate",
    coursename: "React Hooks & React Router Amazon Clone",
    courseteacher: "Akhil Boddu",
    courseduration: "4 hr",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634584314/c9tlfkko9qhpggtar01a.png",
  },
  {
    lp: "BE",
    iscoursepro: false,
    coursediscount: 50,
    coursediscountexpiry: 7,
    courseprice: 2000,
    coursetags: ["Python", " Basics", " File Manipulation", " Libraries"],
    courseunit: [
      "611564f52afb500021dd0513",
      "611568242afb500021dd051e",
      "611637ce32f36800215eb1da",
      "61163d8a32f36800215eb1e7",
      "61165d95e3b8b80021614725",
      "611660b0e3b8b8002161472e",
      "61166bd9e3b8b80021614745",
      "61167d52e3b8b8002161475d",
      "61168211e3b8b8002161476f",
      "611690d8e3b8b80021614785",
      "6116916de3b8b80021614788",
      "6116934be3b8b8002161478f",
      "61169565e3b8b80021614798",
      "61169b12e3b8b8002161479e",
      "60e428e6eba42a00218d7eb0",
    ],
    _id: "60e428afeba42a00218d7eaf",
    courselevel: "beginner",
    coursename: "Introduction to Python",
    courseteacher: "Akhil Boddu",
    courseduration: "2 hrs",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634574324/yzc89bhlbmjh1eibek1k.png",
  },
  {
    lp: "FE",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 800,
    coursetags: [
      "React Animations",
      " React Transitions",
      " Timings",
      " Demo Project",
    ],
    courseunit: [],
    _id: "610a4242e2667400219f09ae",
    courselevel: "intermediate",
    coursename: "Animating React Apps",
    courseteacher: "Akhil Boddu",
    courseduration: "1 hr",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634584814/hcecta7rzthtvparhitd.png",
  },
  {
    lp: "BE",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1300,
    coursetags: [""],
    courseunit: [
      "60e56ae26773d60021ff7479",
      "60e56b556773d60021ff747c",
      "60e56bbd6773d60021ff747f",
      "60e56ca96773d60021ff7482",
      "60e56d746773d60021ff7487",
      "60e5703e6773d60021ff7492",
      "60e5709d6773d60021ff7495",
      "60e571026773d60021ff7498",
      "60e573086773d60021ff74a3",
      "60e574f26773d60021ff74b0",
      "60e5759d6773d60021ff74b5",
    ],
    _id: "60e4c9a0d1a7b60021bf5d9b",
    courselevel: "beginner",
    coursename: "Introduction to NodeJS",
    courseteacher: "Asif Hassam",
    courseduration: "1 hr 30 mins",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634574458/xo1jyeoct5ikcilgjtkz.png",
  },
  {
    lp: "FS",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1000,
    coursetags: ["Behind the scenes", " Optimization techniques"],
    courseunit: [],
    _id: "610a3a7ce2667400219f09ac",
    courselevel: "professional",
    coursename: "Behind the Scenes of React & Optimization Techniques",
    courseteacher: "Akhil Boddu",
    courseduration: "1 hr",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634584455/ke6fy6bgfsar2z5uicix.png",
  },
  {
    lp: "BE",
    iscoursepro: false,
    coursediscount: 50,
    coursediscountexpiry: 7,
    courseprice: 2000,
    coursetags: ["Firebase Database", "CRUD", "Real time data"],
    courseunit: [
      "60dae22e12c93a0021dd2da8",
      "60dae44c12c93a0021dd2db1",
      "60dae64112c93a0021dd2db4",
      "60dae64812c93a0021dd2db5",
      "60db09e012c93a0021dd2de6",
      "60dae65112c93a0021dd2db6",
      "60dae65c12c93a0021dd2db7",
      "60dae66512c93a0021dd2db8",
      "60dae67112c93a0021dd2db9",
      "60dae67f12c93a0021dd2dba",
      "60dae68612c93a0021dd2dbb",
    ],
    _id: "605875fac12b83ff0e7ff354",
    courselevel: "beginner",
    coursename: "Making applications full-stack by storing data in firebase",
    courseteacher: "Akhil Boddu",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634573636/yhnzvyekvh1cwaiozvne.png",
    courseduration: "3 hrs",
  },
  {
    lp: "IC",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1500,
    coursetags: [
      "Object Oriented Programming (OOP)",
      " JavaScript",
      " Inheritance",
      " Polymorphism",
      " Encapsulation",
      " Netflix",
      " Google Keep",
    ],
    courseunit: [
      "60c24b1b63299a00216273a3",
      "60c24b6263299a00216273a5",
      "60c24bac63299a00216273a7",
      "60c24c3a63299a00216273ab",
      "60c24cd163299a00216273ae",
      "60c24d3d63299a00216273b1",
      "60c24d9063299a00216273b5",
      "60c24e0963299a00216273b8",
      "60c24e5963299a00216273bc",
      "60c24e9763299a00216273bf",
      "60c24f6b63299a00216273c2",
      "60c24fac63299a00216273c5",
      "60c2500163299a00216273c8",
      "60c251f463299a00216273d4",
    ],
    _id: "60c1ff1e53e3bd002132394e",
    courselevel: "intermediate",
    courseteacher: "Akhil Boddu",
    coursename: "Introducing to Object Oriented Programming in JS",
    courseduration: "2 hrs 30 mins",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634573978/tk7gsmdqvajkmny3agwn.png",
  },
  {
    lp: "BE",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1500,
    coursetags: [
      "Airbnb clone",
      " Redux",
      " Advanced Redux",
      " Managing where to place logic",
      " The problem with useEffect()",
      " HTTP states with redux",
      " more",
    ],
    courseunit: [],
    _id: "610a403be2667400219f09ad",
    courselevel: "intermediate",
    coursename: "Learning Basic & Advanced Redux by building the Airbnb Clone",
    courseteacher: "Akhil Boddu",
    courseduration: "5 hrs",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634584682/aynkun8wu5kqchfmsyqr.png",
  },
  {
    lp: "BE",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 4,
    courseprice: 1300,
    coursetags: ["Async NodeJS", "Web servers", "npm", "heroku", "deployment"],
    courseunit: [],
    _id: "610a4e33e2667400219f09b1",
    courselevel: "intermediate",
    coursename: "Asynchronous Node, Web Servers & Deployment ",
    courseteacher: "Asif Hassam",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634585161/olzg7wxlg7mspd4pk0v6.png",
    courseduration: "3 hrs",
  },
  {
    lp: "FS",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1200,
    coursetags: ["Demo Project", " React Tests", " Different kinds of Tests"],
    courseunit: [],
    _id: "610a4696e2667400219f09b0",
    courselevel: "intermediate",
    coursename: "Testing React Apps",
    courseteacher: "Akhil Boddu",
    courseduration: "1 hr",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634585017/k2bezzrzge9c0meckiyp.png",
  },
  {
    lp: "FS",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1000,
    coursetags: [
      "Demo Project",
      " What & Why Typescript?",
      " Doing React things the Typescript way",
    ],
    courseunit: [],
    _id: "610a4402e2667400219f09af",
    courselevel: "professional",
    coursename: "React + Typescript",
    courseteacher: "Akhil Boddu",
    courseduration: "2 hrs",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634584904/rxpeql7jqfxfjmcnz40t.png",
  },
  {
    lp: "FS",
    iscoursepro: true,
    coursediscount: 40,
    coursediscountexpiry: 7,
    courseprice: 1500,
    coursetags: [
      "ReactJS",
      " Google Keep Clone",
      " Props",
      " JSX",
      " Dynamic Rendering",
      " and more...",
    ],
    courseunit: [
      "60df08a8a307d500210223b9",
      "60df08f3a307d500210223bc",
      "60df2438a307d500210223c2",
      "60df28f0a307d500210223cf",
      "60e05f67e036ec00215c7ad5",
      "60e06106e036ec00215c7ada",
      "60e06139e036ec00215c7adb",
      "60e06224e036ec00215c7ae0",
      "60e06287e036ec00215c7ae3",
      "60e06318e036ec00215c7ae6",
      "60e06638e036ec00215c7afb",
      "60e06639e036ec00215c7afc",
      "60e06667e036ec00215c7afd",
      "60e066fee036ec00215c7b00",
      "60e06793e036ec00215c7b03",
      "60e0685de036ec00215c7b0b",
      "60e068d8e036ec00215c7b0e",
      "60e0699be036ec00215c7b17",
      "60e069dde036ec00215c7b1b",
      "60e06d4ae036ec00215c7b20",
      "60e06d9de036ec00215c7b23",
      "60e06e35e036ec00215c7b29",
      "60e06f39e036ec00215c7b31",
      "60e0707ae036ec00215c7b34",
      "60e07121e036ec00215c7b3a",
      "60e0717de036ec00215c7b3d",
      "60e0723ae036ec00215c7b40",
      "60e073b1e036ec00215c7b4a",
      "60e073dde036ec00215c7b4b",
      "60e0741fe036ec00215c7b4e",
      "60e074f4e036ec00215c7b56",
      "60e07a10e036ec00215c7b5c",
      "60e07bb7e036ec00215c7b6d",
      "60e406f3ed3a7f00216fc9ef",
    ],
    _id: "60df089fa307d500210223b8",
    courselevel: "beginner",
    coursename: "Introduction to ReactJS by making the Google Keep App",
    courseduration: "4 hr 15 min",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634574216/sg6sqbfrygrxkvykl4ha.png",
    courseteacher: "Akhil Boddu",
  },
  {
    lp: "IC",
    iscoursepro: false,
    coursediscount: 100,
    coursediscountexpiry: 0,
    courseprice: 0,
    coursetags: [
      "Introduction",
      "Tech world",
      "Software",
      "Hardware",
      "Networks",
      "Careers",
    ],
    courseunit: [
      "61472dcaddc40e002252e8e3",
      "614732b9ddc40e002252e8e7",
      "614733f3ddc40e002252e8ec",
      "614734a1ddc40e002252e8f2",
      "6147351fddc40e002252e8f5",
    ],
    _id: "6147205bddc40e002252e8e2",
    courselevel: "beginner",
    coursename: "Intro to the Tech world - Short course",
    courseteacher: "Asif Hassam",
    courseduration: "1 hr",
    coursethumbnailurl:
      "http://res.cloudinary.com/akhilboddu28/image/upload/v1634574677/yo36d5uv89wjrjtsccg0.png",
  },
  {
    lp: "BE",
    iscoursepro: false,
    coursediscount: 100,
    coursediscountexpiry: 0,
    courseprice: 0,
    coursetags: [
      "Git",
      " Github",
      " Netflix",
      " YouTube",
      " Branching",
      " Forking",
      "SSH",
    ],
    courseunit: [
      "61e67c99758def00221b8dea",
      "61e67cb0758def00221b8df7",
      "61e67cc2758def00221b8e04",
      "61e67cd3758def00221b8e13",
      "61e67cdd758def00221b8e20",
      "61e67ce5758def00221b8e2d",
      "61e67cf7758def00221b8e3a",
      "61e67d00758def00221b8e47",
      "61e67d08758def00221b8e54",
    ],
    _id: "61e56bcceb846b939f8db978",
    courselevel: "intermediate",
    coursename: "Project Simulation - Twitter Project",
    courseteacher: "Akhil Boddu",
    courseduration: "5 hr",
    coursethumbnailurl:
      "http://res.cloudinary.com/ak-124210/image/upload/v1642434078/yjnzwmihmhghxkwnb6n9.png",
  },
  {
    lp: "FS",
    iscoursepro: false,
    coursediscount: 100,
    coursediscountexpiry: 0,
    courseprice: 0,
    coursetags: [
      "Git",
      " Github",
      " Netflix",
      " YouTube",
      " Branching",
      " Forking",
      "SSH",
    ],
    courseunit: [
      "61472dcaddc40e002252e8e3",
      "614732b9ddc40e002252e8e7",
      "614733f3ddc40e002252e8ec",
      "614734a1ddc40e002252e8f2",
      "6147351fddc40e002252e8f5",
      "61dfd7c7208e0c0022150e8e",
      "61dfdd92208e0c0022150ebb",
      "61dfddbf208e0c0022150ec8",
      "61dfddeb208e0c0022150ed5",
      "61dfde10208e0c0022150ee2",
      "61dfde38208e0c0022150eef",
      "61dfde56208e0c0022150efc",
      "61dfde73208e0c0022150f09",
      "61dfdeae208e0c0022150f16",
      "61dfdeb9208e0c0022150f23",
      "61dfdedb208e0c0022150f30",
      "61dfdef0208e0c0022150f3d",
    ],
    _id: "61dfd765dd6338d4afd53b73",
    courselevel: "intermediate",
    coursename: "Introduction to Git",
    courseteacher: "Akhil Boddu",
    courseduration: "1 hr",
    coursethumbnailurl:
      "http://res.cloudinary.com/ak-124210/image/upload/v1642058524/re3iw0faxvszuk43nyqe.png",
  },
];

export const Faq = [
  {
    question: "Does Zaio have any free courses ?",
    answer:
      "Yes, we have a introduction to coding learning path where you will build the Tesla landing page and get introduced to the coding world",
    show: false,
  },
  {
    question:
      "What are the requirements for taking the Full Stack/Front end/Backend course ?",
    answer:
      "All you need is a good internet connection, a good laptop with at least an i3 processor (recommended i5) and a ‘not giving up’ attitude",
    show: false,
  },
  {
    question: "How long does the course take ?",
    answer:
      "This is a self-paced course with 200hrs of content, it takes an average student 6 months to complete everything.",
    show: false,
  },
  {
    question:
      "What happens if I get stuck and don’t understand a concept, or even can’t solve an error ?",
    answer:
      "At Zaio we have on-demand tutor support, this means the moment you are stuck or need any sort of help. You can access our tutors on-demand with a click of a button. When purchasing a premium learning path, you get 60 mins of free tutor support which any be used anytime. When you finish these 60 mins, you are charged R3/min for tutor support calls. All free Zaio introduction to coding learning path comes with 10 mins free tutor support.",
    show: false,
  },
  {
    question: "Will Zaio help me find a job ?",
    answer:
      "Zaio will have a whole module in your premium learning path on how to prepare for interviews and various platforms you can use and apply for job opportunities specifically to developer roles. We will also enrol you into a remote internship, should you be able to impress the company, they may offer you a remote full-time job.",
    show: false,
  },
  {
    question: "What Certification will I get ?",
    answer:
      "You will receive an NQF level 5 skills certificate (12 credits) that states what you are able to build web applications for business purposes. You will also receive a certificate of completion from Zaio.",
    show: false,
  },
  {
    question: "What are Zaio’s payment plans ?",
    answer:
      "You can pay a once off fee or you can pay in instalments over 2 months or 4 months. The pricing of the learning paths can be found on their individual pages.",
    show: false,
  },
  {
    question: "Will I get paid while learning ?",
    answer: "This is not a learner-ship, hence you will not get paid",
    show: false,
  },
  {
    question:
      "When do I get access to the course if I am paying in instalments ?",
    answer:
      "The moment you clear your first instalment, you will full access to the course.",
    show: false,
  },
  {
    question: "What If I stop paying my instalment amounts ?",
    answer:
      "Your accounted will be blocked to access any courses and your credit score will be affected.",
    show: false,
  },
  {
    question: "How long do I have access to the learning path for ?",
    answer:
      "You have life-time access to the learning path you have purchased.",
    show: false,
  },
];

export const WalletFaq = [
  {
    question: "Earn by watching lessons",
    answer:
      "You will earn 🟡 1 Z-Coin per video you watch. You could earn 🟡 1 Z-Coin more per video watched by your friends too. Invite a friend.",
    show: false,
  },
  {
    question: "Earn Z-Coin by inviting friends",
    answer:
      "For every friend that signs up with your code on the left, you will earn 🟡 50 Z-Coins and also 🟡 1 Z-Coin per video your friend watches for the rest of their life on Zaio.",
    show: false,
  },
  {
    question: "Unlock courses with Z-Coin",
    answer:
      "Some courses are locked on your dashboard. Instead of paying cash, you could unlock courses worth thousands of rands with 🟡 Z-Coin. This is your reward for actively improving your skills and helping our ecosystem by introducing more techies.",
    show: false,
  },
  // {
  //   question: "Earn Z-Coin by meeting your streak goals",
  //   answer:
  //     "This is a self-paced course with 200hrs of content, it takes an average student 6 months to complete everything.",
  //   show: false,
  // },
];

export const playgroundTypes = {
  SINGLE_FILES: "html-css-js-single",
  MULTI_FILES: "html-css-js-multi",
};

export const playgroundTypesUI = {
  [playgroundTypes.SINGLE_FILES]: "Single",
  [playgroundTypes.MULTI_FILES]: "Multi",
};

export const defaultFileStructure = {
  "index.html": {
    name: "index.html",
    language: "html",
    value: "<h1>Try type HTML here to see magic</h1>",
    path: "/",
  },
  "style.css": {
    name: "style.css",
    language: "css",
    value: "/* css code is here  */",
    path: "/",
  },
  "script.js": {
    name: "script.js",
    language: "javascript",
    value: "// JS code ",
    path: "/",
  },
};

export const ideConstants = {
  JSON_FOLDER_STUCTURE: "json_folder_structure",
}

export const countryCodeSigns = {
  AF: "R",
  KE: "KSh",
  NG: "₦",
  GH: "¢",
  US: "$",
};
