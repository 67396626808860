import courseService from "actions/services/course.service";
import lectureService from "actions/services/lecture.service";
import Error from "components/Error/Error";
import Loader from "components/Loader/Loader";
import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import { BsArrowUpRightSquareFill } from "react-icons/bs";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import useAssignmentStore from "zustand-store/assignmentStore";
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

function AssignmentList({
  courseid,
  lectureid,
  unitid,
  setCurrentVideo,
  currentVideo,
  setCurrentCourse,
  assignmentid,
}) {
  const adminAssignmentList = useAssignmentStore(
    (state) => state.adminAssignmentList
  );
  const setAdminAssignmentList = useAssignmentStore(
    (state) => state.setAdminAssignmentList
  );
  const setAdminSelectedAssignment = useAssignmentStore(
    (state) => state.setAdminSelectedAssignment
  );
  //queries
  const {
    isLoading,
    isError,
    error,
    data: assignmentListResponse,
  } = useQuery(
    "assignmentList",
    () => lectureService.getAdminAssignmentList(),
    {
      onSuccess: (response) => {
        console.log("assignmentListResponse h1h1h1", response)
        setAdminAssignmentList(response?.data);
      },
    }
  );
  const history = useHistory();
  const queryClient = useQueryClient();

  const selectVideo = (video, unitIndex, lecIndex) => {
    // console.log("Video selected", video, unitIndex, lecIndex);
    // ideCurrentVideo

    history.push(
      `/admin-ide/${video?.course}/${video?.courseunit}/${video?._id}`
    );
    setCurrentVideo(video);
    // queryClient.invalidateQueries("ideCurrentVideo");
  };
  const handleOpenWatch = (event) => {
    event.stopPropagation();
    window.open(
      `${FRONTEND_URL}/app/watch/${currentVideo?.course}/${currentVideo?.courseunit}/${currentVideo?._id}`,
      "_blank"
    );
  };

  if (isLoading)
    return (
      <div className="admin-sandbox-sidebar admin-videos-list">
        <Loader />
      </div>
    );

  if (isError)
    return (
      <div className="admin-sandbox-sidebar admin-videos-list">
        <Error message={JSON.stringify(error)} />
      </div>
    );

  const assignmentList = assignmentListResponse?.data || null;
  return (
    <div className="admin-sandbox-sidebar admin-videos-list">
      <div>
        <button
          className="p-2 bg-lime-400 rounded m-2"
          onClick={() => history.push("/admin-assignment")}
        >
          Create Assignment
        </button>
        {/* <button
          className="p-2 bg-purple-400 rounded m-2"
          onClick={() => history.push("/admin-cvcs")}
        >
          Create MCQ
        </button> */}
      </div>
      {assignmentList &&
        assignmentList?.map((assignment, index) => (
          <ListGroup
            key={index}
            style={{ borderRadius: 0, margin: 0 }}
            onClick={() => {
              setAdminSelectedAssignment(assignment);
              history.push(`/edit-assignment/${assignment?._id}`);
            }}
            defaultActiveKey={assignmentid}
          >
            <ListGroup.Item
              action
              eventKey={assignment?._id}
              active={assignment?._id === assignmentid}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <small>
                    {assignment?.assignmentname} ({assignment?._id})
                  </small>
                </div>
                <div>
                  <span
                    className="p-2 admin-ide-btn m-2"
                    onClick={handleOpenWatch}
                  >
                    <BsArrowUpRightSquareFill />
                  </span>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        ))}
    </div>
  );
}

export default AssignmentList;
