import { memo, useState } from "react";
import { LineReproduction } from "./VideoPlayerControls.styled";
import { useHistory, useLocation } from "react-router-dom";

const ProgressBar = ({
  showControlVolume,
  showQuality,
  showDataNext,
  showReproductionList,
  progress,
  duration,
  goToPosition,
  primaryColor,
  secondsToHms,
}) => {
  // console.log("PROGRESS RE-RENDERED");
  const location = useLocation();
  const history = useHistory();
  const queries = new URLSearchParams(location.search);
  const [isAdmin, setIsAdmin] = useState(queries.get("isAdmin"));
  return (
    showControlVolume !== true &&
    showQuality !== true &&
    !showDataNext &&
    !showReproductionList && (
      <LineReproduction
        progressVideo={(progress * 100) / duration}
        primaryColor={primaryColor}
        onClick={(e) => e.stopPropagation()}
      >
        <input
          type="range"
          value={progress}
          className="zp-progress-bar"
          max={duration}
          onChange={(e) => goToPosition(+e.target.value)}
          title=""
        />
        <span>
          {secondsToHms(duration - progress)} <br />
          {isAdmin && secondsToHms(progress)}
        </span>
      </LineReproduction>
    )
  );
};

export default ProgressBar;
