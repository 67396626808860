import React, { Component } from "react";
import "./privacypolicy.css";
import { Helmet } from "react-helmet";

//components
export default class privacypolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email ? this.props.email : "",
    };
  }
  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Learn how to code online for Free | Privacy Policy | Zaio
          </title>
          <meta
            name="description"
            content="Zaio is committed to developing training programmes that encourage lifelong learning in order to develop leaders who will shape Africa's and the world's future innovation."
          />
          <meta
            property="title"
            content="Learn how to code online for Free | About us | Zaio"
          />

          <meta
            name="og:description"
            content="Zaio is committed to developing training programmes that encourage lifelong learning in order to develop leaders who will shape Africa's and the world's future innovation."
          />
          <meta
            property="og:title"
            content="Learn how to code online for Free | About us | Zaio"
          />
          <meta property="og:url" content="https://www.zaio.io/" />
          <meta property="og:site_name" content="Zaio" />
          <meta property="og:type" content="article" />
        </Helmet>

        <div className="wave3"></div>

        <section className="our-values">
          <div className="container text-center">
            <h1 className="b2b-heading">Privacy Policy</h1>
            <p className="light-subheading">
              Zaio Technology (Pty) Ltd] (reg: 2017/490284/07) (“Zaio”)
            </p>
            <div className="body-text">
              <p>
                Zaio Technology (Pty) Ltd] (reg: 2017/490284/07) (“Zaio”)
                adheres to the highest standards of protecting your personal
                information when you use https://www.zaio.io/ or any of its
                related blogs, websites, applications or platforms
                (collectively, “the Website”) or any Zaio services (“Services”).
                As such, we have created this specific and detailed Privacy
                Policy for you to read and appreciate exactly how we safeguard
                your personal information and respect your privacy (“Policy”).
              </p>
              <p>
                <b>
                  YOUR CONTINUED USE OF THIS WEBSITE INDICATES THAT YOU HAVE
                  BOTH READ AND AGREE TO THE TERMS OF THIS PRIVACY POLICY. YOU
                  CANNOT USE THIS WEBSITE IF YOU DO NOT ACCEPT THIS POLICY. ALL
                  SECTIONS OF THIS POLICY ARE APPLICABLE TO USERS UNLESS THE
                  SECTION EXPRESSLY STATES OTHERWISE.
                </b>
              </p>
              <ol>
                <li>
                  <b>Introduction</b>
                  <ol>
                    <li>
                      This Privacy Policy is to be read as if specifically
                      incorporated into the Terms of Service.{" "}
                    </li>
                    <li>
                      Please note that Zaio is a private limited liability
                      company duly registered and operating in accordance with
                      the laws of the Republic of South Africa.
                    </li>
                    <li>
                      Users with citizenships and in locations from
                      jurisdictions other than of South Africa, please note that
                      Zaio complies with all South African data protection laws
                      when processing your personal data pursuant to your use of
                      the South African Website/the Services. Should foreign law
                      be applicable in any regard to your use of the Services
                      and/or the Website in any way, including how we may
                      process your personal data, please contact our data
                      representative at info@zaio.io to gladly engage you on its
                      application and your rights.
                    </li>
                    <li>
                      For more information regarding your personal information
                      lawfully stored or used by the Website or Zaio, please
                      contact info@zaio.io who will gladly assist.
                    </li>
                    <li>
                      This Policy was last updated on 05/06/2020, where you can
                      acquire previous versions hereof by contacting us.
                    </li>
                    <li>
                      Not all terms are necessarily defined in order, or may be
                      defined in our general Terms of Service.
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Privacy Policy</b>
                  <ol>
                    <li>
                      For the purposes of this section, Personal Information
                      will be understood in accordance with the definition
                      provided in the Protection of Personal Information Act 4
                      of 2013 ("the Act"). We also subscribe to the principles
                      for electronically collecting personal information
                      outlined in the Act, and the further legislation referred
                      to therein. We endeavour to ensure the quality, accuracy
                      and confidentiality of Personal Information in our
                      possession.
                    </li>
                    <li>
                      In adopting this Privacy Policy, we wish to balance our
                      legitimate business interests and your reasonable
                      expectation of privacy. Accordingly, we will take all
                      reasonable steps to prevent unauthorised access to, or
                      disclosure of your Personal Information. However, it is
                      impossible to guarantee that your Personal Information
                      shall be 100% secure.
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Our role</b>
                  <ol>
                    <li>
                      Zaio is the “data controller” and is responsible for your
                      personal data in instances where we decide the processing
                      operations concerning your personal data. Sometimes we
                      also operate as a “processor” of personal data on behalf
                      of a third-party data controller, where that data
                      controller’s privacy terms will apply, but we will draw
                      your attention to them, when applicable
                    </li>
                    <li>
                      We have appointed a data representative at Zaio who is
                      responsible for overseeing questions in relation to this
                      Privacy Policy. If you have any questions about this
                      Privacy Policy, including any requests to exercise your
                      legal rights, please contact the representative using the
                      details set out below.
                    </li>
                    <li>
                      You have the right to make a complaint at any time to the
                      South African information regulator’s office (Information
                      Regulator’s Office of South Africa). We would, however,
                      appreciate the chance to deal with your concerns before
                      you approach any such regulator, so please contact us in
                      the first instance.
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Types of information we process</b>
                  <ol>
                    <li>
                      Users will be prompted to register an account with us. In
                      so doing, users may be asked to provide the following
                      information (Personal Information):
                      <ol>
                        <li>
                          Identity Data: First Name, Surname, Language, Race,
                          Marital Status,
                        </li>
                        <li>
                          Biometric Data: Sex, Disability, Age, Physical or
                          mental health, National, ethnic or social origin,
                          Disability
                        </li>
                        <li>
                          Contact Data: Email, Physical Address, Phone Number
                        </li>
                        <li>
                          Financial Data: Bank Account Details,
                          Company/CC/Business Name, Company/CC/Business
                          Registration Number, Payment Gateway details
                        </li>
                        <li>Information relating to education</li>
                        <li>Employment history</li>
                        <li>
                          Technical Data: Technical data automatically derived
                          from your computer system and software, such as
                          cookies, geo-location and, web-beacons
                        </li>
                      </ol>
                    </li>
                    <li>
                      We may also process your personal information should you:
                      <ol>
                        <li>use our Website;</li>
                        <li>use of Services/Programmes;</li>
                        <li>contract with us;</li>
                        <li>Enter any of our competitions;</li>
                        <li>Engage with our social media accounts</li>
                        <li>consult with us;</li>
                        <li>complete forms;</li>
                        <li>sign-up for newsletters;</li>
                        <li>
                          interact with us via webinar or social platform group,
                          such as a Facebook group
                        </li>
                        <li>subscribe to our publications;</li>
                        <li>
                          provide any services to us as a service provider or
                          independent contractor on contract with us;
                        </li>
                        <li>request information to be sent to you;</li>
                        <li>
                          attend any Zaio event whether online or in person; or
                        </li>
                        <li>give us some feedback.</li>
                      </ol>
                    </li>
                    <li>
                      We will attempt to limit the types of Personal Information
                      we process to only that to which you consent to (for
                      example, in the context of online registration,
                      newsletters, message boards, surveys, polls, professional
                      announcements, SMS, MMS and other mobile services), but,
                      to the extent necessary, your agreement to these this
                      Privacy Policy constitutes your consent as contemplated in
                      section 69 of the Act.
                    </li>
                    <li>
                      We will not collect, use or disclose sensitive information
                      (such as information from or about minors, about racial or
                      ethnic origins or political or religious beliefs, where
                      relevant) except with your specific consent or in the
                      circumstances permitted by law.
                    </li>
                    <li>
                      We may also source your personal information from external
                      sources, where these are listed below:
                      <ol>
                        <li>
                          Technical Data from the following parties:
                          <ol>
                            <li>
                              analytics such as Google Analytics based in USA;
                            </li>
                            <li>
                              social networks such Facebook, Instagram, Twitter,
                              WhatsApp, LinkedIn all based in USA;{" "}
                            </li>
                            <li>
                              survey data such as Survey Monkey, Google Forms,
                              MailChimp based in USA;{" "}
                            </li>
                            <li>
                              marketing platforms such as MailChimp, SendGrid
                              based in USA; and
                            </li>
                            <li>
                              search information providers such as Google based
                              in USA;
                            </li>
                          </ol>
                        </li>
                        <li>
                          Financial Data from providers of technical and payment
                          services such as Peach Payments based in Cape Town,
                          South Africa;
                        </li>
                      </ol>
                    </li>
                    <li>
                      It is important that the personal data we hold about you
                      is accurate and current. Accordingly, you warrant that you
                      will keep it updated yourself by using the relevant tools
                      on the Website, or by contacting us to change it for you.
                    </li>
                    <li>
                      We also collect, use and share Aggregated Data such as
                      statistical or demographic data for any purpose.
                      Aggregated Data may be derived from your personal data but
                      is not considered personal data in law as this data does
                      not directly or indirectly reveal your identity. For
                      example, we may aggregate your usage data to calculate the
                      percentage of users accessing a specific Website feature.
                      However, if we combine or connect Aggregated Data with
                      your personal data so that it can directly or indirectly
                      identify you, we treat the combined data as personal data
                      which will be used in accordance with this Privacy Policy.
                    </li>
                    <li>
                      Where we need to collect personal data by law, or under
                      the terms of a contract we have with you and you fail to
                      provide that data when requested, we may not be able to
                      perform the contract we have or are trying to enter into
                      with you (for example, to provide you with services or
                      allow your access to the Website). In this case, we may
                      have to cancel Website-access or services you have with
                      us, but we will notify you if this is the case at the
                      time.
                    </li>
                  </ol>
                </li>
                <li>
                  The reasons for our processing of your personal information
                  <ol>
                    <li>
                      By agreeing to the terms contained in this Privacy Policy,
                      you actively and expressly consent to the use of your
                      Personal Information in relation to:
                      <ol>
                        <li>the provision and performance of the services;</li>
                        <li>informing you of changes made to our website;</li>
                        <li>
                          the provision of marketing related services to you by
                          us;
                        </li>
                        <li>
                          responding to any queries or requests you may have;
                        </li>
                        <li>
                          developing a more direct and substantial relationship
                          with users for the purposes described in this clause;
                        </li>
                        <li>developing an online user profile;</li>
                        <li>
                          understanding general user trends and patterns so that
                          we can develop and support existing and ongoing
                          marketing strategies
                        </li>
                        <li>
                          for security, administrative and legal purposes;
                        </li>
                        <li>
                          the creation and development of market data profiles
                          which may provide insight into market norms, practices
                          and trends to help us improve our offering to you.
                          Such information will be compiled and retained in
                          aggregated form, but shall not be used in any.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Data Safety and security
                      <ol>
                        <li>
                          Although absolute security cannot be guaranteed on the
                          internet, we have in place up-to-date, reasonable
                          technical and organisational security measures to
                          protect your Personal Information against accidental
                          or intentional manipulation, loss, misuse, destruction
                          or against unauthorised disclosure or access to the
                          information we process online.
                        </li>
                        <li>
                          While we cannot ensure or warrant the security of any
                          Personal Information you provide us, we will continue
                          to maintain and improve these security measures over
                          time in line with legal and technological
                          developments.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Transfer of personal information
                      <ol>
                        <li>
                          We store your Personal Information directly, or
                          alternatively, store your Personal Information on, and
                          transfer your Personal Information to, a central
                          database. If the location of the central database is
                          located in a country that does not have substantially
                          similar laws which provide for the protection of
                          Personal Information, we will take the necessary steps
                          to ensure that your Personal Information is adequately
                          protected in that jurisdiction, by doing one or more
                          of the following:
                          <ol>
                            <li>
                              We will only transfer your personal data to
                              third-parties with whom we have contracted, and
                              whom are subject to processing standards which are
                              equal to or better than ours; and/or
                            </li>
                            <li>
                              Where we use providers based in the US or EU, we
                              may transfer data to them if they are part of the
                              Privacy Shield and/or GDPR (respectively) which
                              requires them to provide similar protection to
                              personal data shared between Europe and the US.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      Change of Purpose and Data Retention
                      <ol>
                        <li>
                          Your information will not be stored for longer than is
                          necessary for the purposes described in this Privacy
                          Policy or as required by applicable legislation.
                        </li>
                        <li>
                          We will only use your personal data for the purposes
                          for which we collected it, unless we reasonably
                          consider that we need to use it for another reason and
                          that reason is compatible with the original purpose.
                          If you wish to get an explanation as to how the
                          processing for the new purpose is compatible with the
                          original purpose, please contact us.
                        </li>
                        <li>
                          If we need to use your personal data for an unrelated
                          purpose, we will notify you and we will explain the
                          legal basis which allows us to do so.
                        </li>
                        <li>
                          Please note that we may process your personal data
                          without your knowledge or consent, in compliance with
                          the above rules, where this is required or permitted
                          by law.
                        </li>
                        <li>
                          We will only retain your personal data for as long as
                          necessary to fulfil the purposes we collected it for,
                          including for the purposes of satisfying any legal,
                          accounting, or reporting requirements.
                        </li>
                        <li>
                          To determine the appropriate retention period for
                          personal data, we consider the amount, nature, and
                          sensitivity of the personal data, the potential risk
                          of harm from unauthorised use or disclosure of your
                          personal data, the purposes for which we process your
                          personal data, any other South African applicable law
                          requiring us to retain the data and whether we can
                          achieve those purposes through other means, and the
                          applicable legal requirements.
                        </li>
                        <li>
                          Details of retention periods for different aspects of
                          your personal data are available from us by contacting
                          us.
                        </li>
                        <li>
                          In some circumstances you can ask us to delete your
                          data; see below for further information.
                        </li>
                        <li>
                          In some circumstances we may anonymise your personal
                          data (so that it can no longer be associated with you)
                          for research or statistical purposes in which case we
                          may use this information indefinitely without further
                          notice to you.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Third Parties to whom we provide personal information
                      <ol>
                        <li>
                          The Personal Information we collect from users shall
                          only be accessed by our employees, representatives and
                          consultants on a need-to-know basis, and subject to
                          reasonable confidentiality obligations binding such
                          persons.
                        </li>
                        <li>
                          We shall have the right, but shall not be obliged, to
                          monitor or examine any information and materials
                          including any website link that you publish or submit
                          to us for publishing on the Site. You shall be solely
                          responsible for the contents of all material published
                          by yourself.
                        </li>
                        <li>
                          We constantly review our systems and data to ensure
                          the best possible service to our users. We do not
                          accept any users, or representatives of users, under
                          18 years of age or who otherwise does not have the
                          relevant capacity to be bound by this Privacy Policy.
                        </li>
                        <li>
                          We will not sell, share, or rent your Personal
                          Information to any third party or use your e-mail
                          address for unsolicited mail. Any emails sent by us
                          will only be in connection with the provision of our
                          services and/or the marketing thereof.
                        </li>
                        <li>
                          Only the following third parties will have access to
                          your personal information, for the following reasons:
                          <ol>
                            <li>
                              Internal Third Parties means other entities or
                              parties in the Zaio group acting as joint
                              controllers or processors and who are based in
                              South Africa and provide IT and system
                              administration services and undertake reporting.
                            </li>
                            <li>
                              External Third Parties means:
                              <ol>
                                <li>
                                  service providers acting as processors based
                                  in South Africa who provide IT and system
                                  administration services;
                                </li>
                                <li>
                                  South African or other national governments
                                  and/or their respective authorities pursuant
                                  to our adherence with anti-corruption and
                                  crime-fighting legislation;
                                </li>
                                <li>
                                  Firebase based in the United States of America
                                  for having them provide their secure data
                                  storage services. Google based in in the
                                  United States of America for providing search
                                  information and survey data through Google
                                  Forms to Zaio and its users. Survey Monkey
                                  based in United States of America for having
                                  them provide survey Services to Zaio and its
                                  users. Mail Chimp based in the United States
                                  of America for providing marketing services to
                                  Zaio and its users. SendGrid based in the
                                  United States of America for providing
                                  marketing services to Zaio and its users.
                                  Wecruit based in South Africa for providing
                                  sales and marketing services to Zaio and its
                                  users.{" "}
                                </li>
                                <li>
                                  professional advisers acting as processors or
                                  joint controllers including lawyers, bankers,
                                  auditors and insurers based in South Africa
                                  who provide consultancy, banking, legal,
                                  insurance and accounting services as required;
                                  and/or
                                </li>
                                <li>
                                  European Union, South African and other
                                  authorities acting as processors or joint
                                  controllers based in the United Kingdom or
                                  European Union who may require reporting of
                                  processing activities in certain
                                  circumstances.
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      Your legal rights
                      <ol>
                        <li>
                          Under certain circumstances, you have rights under
                          data protection laws in relation to your personal
                          data. Please contact us to find out more about, or
                          manifest, these rights:
                          <ol>
                            <li>request access to your personal data;</li>
                            <li>request correction of your personal data;</li>
                            <li>request erasure of your personal data;</li>
                            <li>
                              object to the processing of your personal data;
                            </li>
                            <li>
                              request a restriction of processing your personal
                              data;
                            </li>
                            <li>
                              request transfer of your personal data; and/or
                            </li>
                            <li>right to withdraw consent.</li>
                          </ol>
                        </li>
                        <li>
                          You will not have to pay a fee to access your personal
                          data (or to exercise any of the other rights).
                          However, we may charge a reasonable fee if your
                          request is clearly unfounded, repetitive or excessive.
                          Alternatively, we may refuse to comply with your
                          request in these circumstances.
                        </li>
                        <li>
                          We may need to request specific information from you
                          to help us confirm your identity and ensure your right
                          to access your personal data (or to exercise any of
                          your other rights). This is a security measure to
                          ensure that personal data is not disclosed to any
                          person who has no right to receive it. We may also
                          contact you to ask you for further information in
                          relation to your request to speed up our response.
                        </li>
                        <li>
                          We try to respond to all legitimate requests within
                          one month. Occasionally it may take us longer than a
                          month if your request is particularly complex or you
                          have made a number of requests. In this case, we will
                          notify you and keep you updated.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Log files</b>
                      <ol>
                        <li>
                          When you visit the website, even if you do not create
                          an account, we may collect information, such as your
                          IP address, the name of your ISP (Internet Service
                          Provider), your browser, the website from which you
                          visit us, the pages on our website that you visit and
                          in what sequence, the date and length of your visit,
                          and other information concerning your computer's
                          operating system, language settings, and broad
                          demographic information. This information is
                          aggregated and anonymous data and does not identify
                          you specifically. However, you acknowledge that this
                          data may be able to be used to identify you if it is
                          aggregated with other Personal Information that you
                          supply to us. This information is not shared with
                          third parties and is used only within the company on a
                          need-to-know basis. Any individually identifiable
                          information related to this data will never be used in
                          any way different to that stated above, without your
                          explicit permission.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Cookies</b>
                      <ol>
                        <li>
                          We use cookies. A cookie is a small piece of
                          information stored on your computer or smartphone by
                          the web browser. The two types of cookies used on the
                          Website are described below:
                        </li>
                        <li>
                          "Session cookies": These are used to maintain a
                          so-called 'session state' and only lasts for the
                          duration of your use of the Website. A session cookie
                          expires when you close your browser, or if you have
                          not visited the server for a certain period of time.
                          Session cookies are required for the Platform to
                          function optimally, but are not used in any way to
                          identify you personally.
                        </li>
                        <li>
                          "Permanent cookies": These cookies permanently store a
                          unique code on your computer or smart device hard
                          drive in order to identify you as an individual user.
                          No Personal Information is stored in permanent
                          cookies. You can view permanent cookies by looking in
                          the cookies directory of your browser installation.
                          These permanent cookies are not required for the
                          website to work, but may enhance your browsing
                          experience.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Links from the website</b>
                      <ol>
                        <li>
                          The services available through the Website, may
                          contain links to other third party websites, including
                          (without limitation) social media platforms, payment
                          gateways, appointment scheduling and/or live chat
                          platforms ("Third Party Websites"). If you select a
                          link to any Third Party Website, you may be subject to
                          such Third Party Website's terms and conditions and/or
                          other policies, which are not under our control, nor
                          are we responsible therefore.
                        </li>
                        <li>
                          Hyperlinks to Third Party Websites are provided "as
                          is", and we do not necessarily agree with, edit or
                          sponsor the content on Third Party Websites.
                        </li>
                        <li>
                          We do not monitor or review the content of any Third
                          Party Website. Opinions expressed or material
                          appearing on such websites are not necessarily shared
                          or endorsed by us and we should not be regarded as the
                          publisher of such opinions or material. Please be
                          aware that we are not responsible for the privacy
                          practices, or content, of other websites, either.
                        </li>
                        <li>
                          Users should evaluate the security and trustworthiness
                          of any Third Party Website before disclosing any
                          personal information to them. We do not accept any
                          responsibility for any loss or damage in whatever
                          manner, howsoever caused, resulting from your
                          disclosure to third parties of personal information.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>
                        Application Of The Electronic Communications And
                        Transactions Act 25 Of 2002 ("Ect Act")
                      </b>
                      <ol>
                        <li>
                          Data Messages (as defined in the ECT Act) will be
                          deemed to have been received by us if and when we
                          respond to the Data Messages.
                        </li>
                        <li>
                          Data Messages sent by us to a user will be deemed to
                          have been received by such user in terms of the
                          provisions specified in section 23(b) of the ECT Act.
                        </li>
                        <li>
                          Users acknowledge that electronic signatures,
                          encryption and/or authentication are not required for
                          valid electronic communications between us and users.
                        </li>
                        <li>
                          Information to be provided in terms of section 43(1)
                          of the ECT Act:
                          <ol>
                            <li>
                              Users warrant that Data Messages sent to us from
                              any electronic device, used by such user, from
                              time to time or owned by such user, were sent and
                              or authorised by such user, personally.
                            </li>
                            <li>
                              This Website is owned and operated by Zaio
                              Technology (Pty) Ltd.
                            </li>
                            <li>
                              Address for service of legal documents: Shelley
                              Road, 17, 7925, Cape Town.
                            </li>
                            <li>Contact Number: +27711010004.</li>
                            <li>Website - located at https://www.zaio.io/</li>
                            <li>Email address: info@zaio.io</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
