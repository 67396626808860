import axios from "axios";
import authHeader from "./auth-header";

// const API_URL = 'https://zaio-dev.herokuapp.com'
// const API_URL = process.env.REACT_APP_BACKEND_URL;
const API_URL = process.env.REACT_APP_BACKEND_URL;

const bootcamp = {
  enrollIntoBootcamp(data) {
    console.log("\n\n ENROLLING INTO BOOTCAMP", data);
    return axios
      .post(API_URL + `/bootcamp/enroll`, {
        ...data,
      })
      .then((res) => {
        //   console.log(res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log(rej.data);
        return rej;
      });
  },

  checkAlreadyEnrolled({ email, learningpath }) {
    console.log("\n\ncheckAlreadyEnrolled", email, learningpath);
    return axios
      .get(
        API_URL +
          `/bootcamp/check-already-enrolled?learningpath=${learningpath}&email=${email}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        return rej;
      });
  },

  reviseBootcampTasks(data) {
    return axios
      .post(API_URL + `/bootcamp/revise`, {
        ...data,
      })
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log(rej.data);
        return rej;
      });
  },

  fetchAllEnrolledBootcamps(data) {
    const email = data?.email;
    return axios
      .get(API_URL + `/bootcamp/all-enrolled-bootcamps?email=${email}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log(rej.data);
        return rej;
      });
  },

  getDashboardCardDetails() {
    return axios
      .get(API_URL + `/defer-student/dashboard-card-details`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log(rej.data);
        return rej;
      });
  },

  getDeferredStudentGoals({ userid, bootcampid }) {
    return axios
      .get(API_URL + `/defer-student/all-goals/${userid}/${bootcampid}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log(rej.data);
        return rej;
      });
  },

  getDeferredStudentBootcamps() {
    return axios
      .get(API_URL + `/defer-student/all-deferred-bootcamps`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((rej) => {
        console.log(rej.data);
        return rej;
      });
  },
};

export default bootcamp;
