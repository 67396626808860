import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
function CreateFileForm({ handleAddNewFile }) {
  const [newFileName, setNewFileName] = useState("");
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <Form onSubmit={handleAddNewFile}>
      <Form.Group controlId="afterLecture">
        <Form.Label>Add File Name</Form.Label>
        <Form.Control
          ref={inputRef}
          type="text"
          value={newFileName}
          onChange={(event) => setNewFileName(event.target.value)}
        />
      </Form.Group>
      <button
        className="bg-green-600 text-white rounded px-4 py-1 my-2"
        onClick={(e) => handleAddNewFile(e, newFileName)}
      >
        Create
      </button>
    </Form>
  );
}

export default CreateFileForm;
