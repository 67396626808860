import styled from "styled-components";

export const TeamLearningContainer = styled.div`
  padding: 28px 48px;
  color: white;

  .team-body {
    margin-top: 2rem;
    display: flex;
  }
  .team-body-left {
    width: 75%;

    .team-paragraph {
      display: inline-block;
      color: #909090;
      font: 16px Open Sans, sans-serif;
      cursor: "pointer";
      width: 80%;
    }
  }
  .team-body-right {
    width: 25%;
  }

  .mobile-show {
    display: none;
  }
  .mobile-hide {
    display: block;
  }

  @media only screen and (max-width: 900px) {
    .mobile-show {
      display: block;
    }
    .mobile-hide {
      display: none;
    }
    .team-body {
      display: flex;
      flex-direction: column;
    }
    .team-body-left {
      width: 100%;
      .team-body-left-title {
        display: none;
      }
      .team-paragraph {
        text-align: center;
        width: 100%;
        margin: 1rem;
      }
    }
    .team-body-right {
      display: none;
    }
  }
`;

export const VideoExplainerContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  max-width: 350px;
`;
