import Ide from "components/IDE/Ide";
import AdminSandboxList from "../AdminIde/AdminSandboxList";
// import AdminIdeNav from "../AdminIde/AdminIdeNav";
import AdminIdeNav from "../AdminIde/AdminIdeNav";

import "../AdminIde/admin_ide.scss";
import { useEffect, useState } from "react";
import AdminVideosList from "../AdminIde/AdminVideosList";
import { useParams } from "react-router-dom";

import CustomModal from "components/Modals/CustomModal";

import { useQuery } from "react-query";
import lectureService from "actions/services/lecture.service";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CodingQForm from "./CodingQForm";
// import MCQForm from "./MCQForm";

function CodingChallenge() {
  const { courseid, lectureid, unitid } = useParams();
  console.log("COURSE ID", courseid);
  console.log("unitid ID", unitid);
  console.log("lectureid ID", lectureid);

  const [showSandboxList, setShowSandboxList] = useState(false);
  const [showVideosList, setShowVideosList] = useState(true);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [projectFiles, setProjectFiles] = useState(null);
  const [preLoadedFiles, setPreLoadedFiles] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editingCompleted, setEditingCompleted] = useState(true);

  const [playgroundid, setPlaygroundId] = useState(false);
  const [show, setShow] = useState(false);

  const { isLoading, isError, error, data } = useQuery(
    "ideCurrentVideo",
    () => lectureService.getPublicLectureById(lectureid),
    {
      onSuccess: (data) => {
        setCurrentVideo(data);
      },
    }
  );

  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const loadModal = () => {
    setShow(true);
    console.log("LOAD MODAL", show);

    // setModal({
    //   ...modal,
    //   show: true,
    //   title: editingCompleted ? "Create" : "Edit",
    //   description: "",
    //   CustomComponent: SaveProgress,
    //   props: {
    //     currentVideo,
    //     projectFiles: JSON.parse(localStorage.getItem("projectFiles")),
    //     lectureid,
    //     setEditMode,
    //     editMode,
    //     playgroundid,
    //   },
    // });
  };

  const onCtrlS = () => {
    loadModal();
  };

  const onProjectFilesUpdate = (files) => {
    console.log("PROJECT Files", files);
    setProjectFiles(files);
  };

  // useEffect(() => {
  //   console.log("EDIT MODE UDPATED", editMode);
  //   if (!editMode) setEditingCompleted(true);
  //   else setEditingCompleted(false);
  // }, [editMode]);

  return (
    <div>
      <AdminIdeNav
        hideSandboxList={true}
        setShowSandboxList={setShowSandboxList}
        showSandboxList={showSandboxList}
        setShowVideosList={setShowVideosList}
        showVideosList={showVideosList}
        currentCourse={currentCourse}
        editMode={editMode}
        setEditMode={setEditMode}
        currentVideo={currentVideo}
      />
      {/* {showSandboxList && (
        <AdminSandboxList
          lectureid={lectureid}
          setPreLoadedFiles={setPreLoadedFiles}
          setEditMode={setEditMode}
          setPlaygroundId={setPlaygroundId}
          playgroundid={playgroundid}
        />
      )} */}
      <div className="admin-ide-container">
        <CodingQForm
          lectureId={lectureid}
          courseId={courseid}
          unitId={unitid}
        />
      </div>
      {showVideosList && (
        <AdminVideosList
          setCurrentCourse={setCurrentCourse}
          courseid={courseid}
          lectureid={lectureid}
          unitid={unitid}
          setCurrentVideo={setCurrentVideo}
          currentVideo={currentVideo}
        />
      )}
    </div>
  );
}

export default CodingChallenge;
