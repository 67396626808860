import { useMutation, useQueryClient } from "react-query";
import "./mcq.scss";
import "pages/Onboarding/Onboarding.scss";
import { useContext, useEffect, useState } from "react";
import useQuizStore from "zustand-store/quizStore";
import lectureService from "actions/services/lecture.service";
import { shallow } from "zustand/shallow";
import OnboardingHeader from "pages/Onboarding/OnboardingHeader";
import OnboardingFooter from "pages/Onboarding/OnboardingFooter";
import ChatBubble from "pages/DashBoard/ChatBubble";
import QuizQuestion from "./QuizQuestion";
import QuizOverview from "./QuizOverview";
import QuizResult from "./QuizResult";
import { idText } from "typescript";
import useAssignmentStore from "zustand-store/assignmentStore";
import { useHistory, useParams } from "react-router-dom";
import useVideoStore from "zustand-store/videoPlayerStore";
import CustomModal from "components/Modals/CustomModal";
import ReelModal from "components/QuizReelComponents/ReelModal/ReelModal";

import { SfxContext } from "context/SfxContext";
import HomeButton from "../../Utils/HomeButton";
import BackButton from "Utils/BackButton";

const AssignmentReleasedModal = () => {
  const history = useHistory();
  return (
    <ChatBubble
      title={"Check it out!"}
      showLive={false}
      onClick={() => history.push("/assignments")}
    />
  );
};

function MCQ({ lectureId, onNextClick, dataNext, assignment = false, refreshCourseData }) {
  const { courseid, unitid, lectureid } = useParams();
  const [quizStarted, setQuizStarted] = useState(false);
  const { clickSfx, hoverSfx, completedSfx, winSfx, errorSfx } =
    useContext(SfxContext);

  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
    title: "Bootcamp mode",
    description:
      "This is your personalized schedule, with reminders to help you stay upto date with your learning, depending on how much time you can commit",
  });

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const quizData = useQuizStore((state) => state.quizData);
  const currentQuestion = useQuizStore((state) => state.currentQuestion);
  const currentQuestionId = useAssignmentStore(
    (state) => state.currentQuestionId
  );
  const assignmentData = useAssignmentStore((state) => state.assignmentData);
  const question = useAssignmentStore((state) => state.question);
  const {
    quizCompleted,
    setQuizCompleted,
    // quizData,
    selectedOption,
    isSolutionCorrect,
    resetQuiz,
    handleSolutionSubmission,
    bgColor,
    resultMessage,
    buttonDisplay,
    setQuizData,
    setResultsData,
    restartQuiz,
    setQuizIndex,

    setSelectedOption,
    quizProgress,
    correctAnswerCount,
    resultDecimal,
    accAnswers,
  } = useQuizStore(
    (state) => ({
      quizCompleted: state.quizCompleted,
      setQuizCompleted: state.setQuizCompleted,
      // quizData: state.quizData,
      selectedOption: state.selectedOption,
      handleSolutionSubmission: state.handleSolutionSubmission,
      bgColor: state.bgColor,
      resultMessage: state.resultMessage,
      buttonDisplay: state.buttonDisplay,
      resetQuiz: state.resetQuiz,
      isSolutionCorrect: state.isSolutionCorrect,
      setQuizData: state.setQuizData,
      setResultsData: state.setResultsData,
      restartQuiz: state.restartQuiz,
      setQuizIndex: state.setQuizIndex,

      setSelectedOption: state.setSelectedOption,
      quizProgress: state.quizProgress,
      correctAnswerCount: state.correctAnswerCount,
      resultDecimal: state.resultDecimal,
      accAnswers: state.accAnswers,
    }),
    shallow
  );

  const setCurrentQuestion = useQuizStore((state) => state.setCurrentQuestion);
  const quizIndex = useQuizStore((state) => state.quizIndex);
  const questionSubmission = useMutation(
    () =>
      lectureService.submitCourseMCQ(
        quizData?._id,
        lectureId,
        resultDecimal,
        accAnswers
      ),
    {
      onSuccess: (response) => {
        console.log("QUESTION SUBMISSION SUCCESS", response, response?.data);
        if (response?.data?.assignmentreleased)
          setModal({
            ...modal,
            show: true,
            title: "New Assignment Released! 🎉",
            description:
              "Congratulations! You are making great progress. Go checkout your new assignment.",
            CustomComponent: AssignmentReleasedModal,
            props: {
              // onNextClick,
              // dataNext,
            },
          });

          refreshCourseData();
        // setModal({
        //   ...modal,
        //   show: true,
        //   title: "New Assignment released!",
        //   CustomComponent: () => {
        //     const history = useHistory();
        //     return (
        //       <ChatBubble
        //         title={"Check it out!"}
        //         showLive={false}
        //         onClick={() => history.push("assignments")}
        //       />
        //     );
        //   },
        //   props: {
        //     // user,
        //   },
        // });
      },
    }
  );
  const assignmentQuizSubmissionMutation = useMutation(
    () =>
      lectureService.submitAssignmentMCQ(
        currentQuestionId,
        assignmentData?.assignmentid?._id,
        currentQuestion?._id,
        {
          ...selectedOption,
          correct: isSolutionCorrect,
        }
      ),
    {
      onSuccess: (response) => {
        console.log("ASSIGNMENT MCQ SUBMISSION SUCCESS", response);
      },
    }
  );
  const videoMode = useVideoStore((state) => state.videoMode);

  useEffect(() => {
    console.log("QUIZ DATA", quizData);
    console.log("currentQuestion", currentQuestion);
    console.log("QUIZ QUESTION", question);
    // if (question?.status !== "completed") initializeQuiz();
    initializeQuiz();
  }, [quizData, lectureid, videoMode]);

  const initializeQuiz = () => {
    restartQuiz();
    setQuizStarted(false);
    setQuizCompleted(false);
    setCurrentQuestion(quizData?.questions[0]);
  };

  const displayColor = (option) => {
    // if (!quizData?.completed) {
    if (selectedOption?.answer === option.answer) {
      if (isSolutionCorrect === null) {
        // hoverSfx();
        return "selected";
      }
      if (isSolutionCorrect === false) {
        // clickSfx();
        return "danger";
      }
      if (isSolutionCorrect === true) {
        // winSfx();
        return "success";
      }
    } else {
      if (
        isSolutionCorrect !== null &&
        option.answer === currentQuestion?.correctAnswer[0].answer
      ) {
        return "success";
      }
      return "default";
    }
    // }
    //  else {
    //   const answer = quizData?.answers[quizNum]?.answer;
    //   console.log("QUIZ DATA", answer);
    //   if (option.answer === answer?.answer) {
    //     if (answer?.correct === true) return "bg-green-300";
    //     if (answer?.correct === false) return "bg-red-300";
    //   }
    //   return "bg-white-200";
    // }
  };

  const handleQuizOptionSelected = async () => {
    if (selectedOption === null) {
      alert("Please try answer a question to proceed.");
      return;
    }

    if (isSolutionCorrect === null) {
      const solution = currentQuestion?.correctAnswer[0].answer;
      setTimeout(() => {
        if (selectedOption.answer === solution) winSfx();
        else errorSfx();
      }, 250);
      handleSolutionSubmission(
        solution,
        currentQuestion?.correctAnswer[0].explanation
      );
      return;
    }
    return nextQuestion();
  };

  const nextQuestion = () => {
    clickSfx();
    console.log(
      "NEXT QUESTION",
      quizIndex,
      quizData.questions[quizIndex + 1],
      currentQuestion,
      quizData?.questions.length - 1
    );
    resetQuiz();

    if (assignment) assignmentQuizSubmissionMutation.mutate();
    if (quizIndex === quizData?.questions.length - 1) {
      setQuizCompleted(true);
      setQuizStarted(false);
      if (!assignment) questionSubmission.mutate();
      return;
    }

    setCurrentQuestion(quizData?.questions[quizIndex + 1]);
    setQuizIndex(quizIndex + 1);
  };

  const handleStartQuiz = () => {
    clickSfx();
    setQuizStarted(true);
  };
  const displayBody = () => {
    if (quizCompleted || question?.status === "completed")
      return (
        <QuizResult
          resultDecimal={question?.mark / quizData?.marks || resultDecimal}
          assignment={assignment}
        />
      );

    if (quizStarted)
      return (
    <>
        <div style={{
          "position": "absolute",
  "width": "100vw",
  "left": '0',
  "height": 'max-content',
  "padding": '3.2rem',
  "display": 'flex',
  "justifyItems": 'start',
  "flexWrap": 'wrap',
  "alignItems": 'center'
    }}>
    <BackButton color='white' size='24px'/>
    <HomeButton color='white' size='24px'/>
    </div>
        <QuizQuestion
          quizData={quizData}
          quizIndex={quizIndex}
          setSelectedOption={setSelectedOption}
          isSolutionCorrect={isSolutionCorrect}
          displayColor={displayColor}
        />
        </>
      );

    return (
      <>
      <div style={{
            "position": "absolute",
    "width": "100vw",
    "left": '0',
    "height": 'max-content',
    "padding": '3.2rem',
    "display": 'flex',
    "justifyItems": 'start',
    "flexWrap": 'wrap',
    "alignItems": 'center'
      }}>
      <BackButton color='white' size='24px'/>
      <HomeButton color='white' size='24px'/>
      </div>
      <div className="flex items-center w-full">
      <QuizOverview handleStartQuiz={handleStartQuiz} assignment={assignment} />
      </div>
      </>
    );
  };

  const displayFooter = () => {
    if (question?.status === "completed") {
      return null;
    }
    if (!quizStarted && !quizCompleted)
      return (
        <OnboardingFooter
          handleContinue={handleStartQuiz}
          customButtonName={"Start Quiz"}
        />
      );

    if (quizStarted)
      return (
        <OnboardingFooter
          handleContinue={handleQuizOptionSelected}
          customButtonName={buttonDisplay}
        />
      );

    if (!assignment)
      return (
        <OnboardingFooter
          handleContinue={() => {
            clickSfx();
            onNextClick(dataNext);
          }}
          customButtonName={"Next Lesson"}
        />
      );
  };

  if (currentQuestion === null) return <p>Loading...</p>;
  return (
    <div className="mcq-container">
      {modal.show && (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          props={modal.props}
          CustomComponent={modal.CustomComponent ?? false}
          // videoUrl={"https://youtu.be/Uv9URjIBtUQ"}
        />
      )}
      <div className="mcq-container-max">
        <div className="d-flex align-items-center justify-content-between">
          {/* <p className="m-0 p-0 text-white">
            {correctAnswerCount}/{quizData?.questions.length}
          </p> */}
          <OnboardingHeader
            percentageCompleted={
              question?.status === "completed" ? "100" : quizProgress * 100
            }
          />
        </div>
        {displayBody()}
        {displayFooter()}
      </div>
    </div>
  );
}

export default MCQ;
