const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

const setOnboardingObject = (
  getAJobTimeStampString = "00:14",
  getAJobTriggerString = "00:28",
  sideHustleTimeStampString = "00:30",
  sideHustleTriggerString = "00:46",
  codingAsASkillTimeStampString = "00:48",
  codingAsASkillTriggerString = "01:00",
  howComfortableTimeStampString = "01:02",
  howComfortableTriggerString = "01:13",
  courseOptionsTimeStampString = "01:45",
  courseOptionsTriggerString = "02:13",
  mobileCoursesTimeStampString = "01:15",
  mobileCoursesTriggerString = "01:43",
  dlCoursesTimeStampString = "02:15",
  dlCoursesTriggerString = "02:25"
) => {
  const onboardingObject = {
    goal: [
      {
        text: "Get a job in tech",
        nexttimestamp: getAJobTimeStampString,
        nexttrigger: getAJobTriggerString,
        nextstep: "havePC",
      },
      {
        text: "Start a side hustle",
        nexttimestamp: sideHustleTimeStampString,
        nexttrigger: sideHustleTriggerString,
        nextstep: "havePC",
      },
      {
        text: "Learn coding as a skill",
        nexttimestamp: codingAsASkillTimeStampString,
        nexttrigger: codingAsASkillTriggerString,
        nextstep: "havePC",
      },
    ],
    havePC: [
      {
        text: "I have a PC",
        nexttimestamp: howComfortableTimeStampString,
        nexttrigger: howComfortableTriggerString,
        nextstep: "isComfortable",
      },
      {
        text: "I don't have a PC",
        nexttimestamp: mobileCoursesTimeStampString,
        nexttrigger: mobileCoursesTriggerString,
        nextstep: "selectingMobileCourseOption",
      },
    ],
    isComfortable: [
      {
        text: "I struggle using my PC",
        nexttimestamp: dlCoursesTimeStampString,
        nexttrigger: dlCoursesTriggerString,
        nextstep: "digitalLiteracy",
      },
      {
        text: "I am pretty comfortable using my PC",
        nexttimestamp: courseOptionsTimeStampString,
        nexttrigger: courseOptionsTriggerString,
        nextstep: "selectingCourseOption",
      },
    ],
    selectingCourseOption: [
      {
        text: "What the code?",
        nexttimestamp: null,
        redirectToCourse: {
          url:
            FRONTEND_URL +
            "/app/quiz-reel?courseid=63691ddc67131c189ef2966b&lectureid=636a19b69ffa6e0022ff7501&unitid=6369f5823d2395002291c88f",
          courseid: "63691ddc67131c189ef2966b",
          lectureid: "6369f5e43d2395002291c8c7",
          courseInfo: {
            ELOs: [
              "Should continue to learn coding part-time",
              "Have the skills to learn how to code",
              "Can build a career out of coding & if I should",
            ],
            forYouIf: [
              "you don't know what coding is, how it's used in industry, how much you can earn from it",
              ",confused and lost about your career",
              "not sure if you have what it takes to start a coding career in tech.",
            ],
          },
        },
      },
      {
        text: "HTML CSS Virtual Card (1/2)",
        nexttimestamp: null,
        redirectToCourse: {
          url:
            FRONTEND_URL +
            // "/app/code?courseid=636974f23d2395002291c404&lectureid=6369762c3d2395002291c430&unitid=6369761a3d2395002291c41f",
            "/app/watch/636974f23d2395002291c404/6369761a3d2395002291c41f/6369762c3d2395002291c430",
          courseid: "636974f23d2395002291c404",
          lectureid: "6369762c3d2395002291c430",
          courseInfo: {
            ELOs: [
              "Know if I should continue to learn coding part-time",
              "Know if I have the skills to learn how to code",
              "Know if I can build a career out of coding & if I should",
            ],
            forYouIf: [
              "you don't know what coding is, how it's used in industry, how much you can earn from it",
              ",confused and lost about your career",
              "not sure if you have what it takes to start a coding career in tech.",
            ],
          },
        },
      },
    ],
    selectingMobileCourseOption: [
      {
        text: "What the code?",
        nexttimestamp: null,
        redirectToCourse: {
          url:
            FRONTEND_URL +
            "/app/quiz-reel?courseid=63691ddc67131c189ef2966b&lectureid=636a19b69ffa6e0022ff7501&unitid=6369f5823d2395002291c88f",
          courseid: "63691ddc67131c189ef2966b",
          lectureid: "636a19b69ffa6e0022ff7501",
          courseInfo: {
            ELOs: [
              "Know if I should continue to learn coding part-time",
              "Know if I have the skills to learn how to code",
              "Know if I can build a career out of coding & if I should",
            ],
            forYouIf: [
              "you don't know what coding is, how it's used in industry, how much you can earn from it",
              ",confused and lost about your career",
              "not sure if you have what it takes to start a coding career in tech.",
            ],
          },
        },
      },
      {
        text: "HTML CSS Virtual Card",
        nexttimestamp: null,
        redirectToCourse: {
          url:
            FRONTEND_URL +
            "/app/quiz-reel?courseid=6368c8f5a63aa900224bfedb&lectureid=6368e631701bc200226a5ef2&unitid=6368ccc6701bc200226a5e96",
          courseid: "6368c8f5a63aa900224bfedb",
          lectureid: "6368e631701bc200226a5ef2",
          courseInfo: {
            ELOs: [
              "Know if I should continue to learn coding part-time",
              "Know if I have the skills to learn how to code",
              "Know if I can build a career out of coding & if I should",
            ],
            forYouIf: [
              "you don't know what coding is, how it's used in industry, how much you can earn from it",
              ",confused and lost about your career",
              "not sure if you have what it takes to start a coding career in tech.",
            ],
          },
        },
      },
    ],
    digitalLiteracy: [
      {
        text: "View digital literacy content on PC",
        nexttimestamp: null,
        redirectToCourse: {
          url:
            FRONTEND_URL +
            // "/app/code?courseid=63691b5267131c189ef29666&lectureid=6369728e3d2395002291c219&unitid=6369721c3d2395002291c208",
            "/app/watch/63691b5267131c189ef29666/6369721c3d2395002291c208/6369728e3d2395002291c219",
          courseid: "63691b5267131c189ef29666",
          lectureid: "6369728e3d2395002291c219",
          courseInfo: {
            ELOs: [
              "Know if I should continue to learn coding part-time",
              "Know if I have the skills to learn how to code",
              "Know if I can build a career out of coding & if I should",
            ],
            forYouIf: [
              "you don't know what coding is, how it's used in industry, how much you can earn from it",
              ",confused and lost about your career",
              "not sure if you have what it takes to start a coding career in tech.",
            ],
          },
        },
      },
    ],
  };
  return onboardingObject;
};

export default setOnboardingObject;
