import SubmissionForm from "./SubmissionForm";

function Submission({ teamid, competitionid, submission }) {
  return (
    <div className="d-flex justify-content-center">
      <SubmissionForm
        teamid={teamid}
        competitionid={competitionid}
        submission={submission}
      />
    </div>
  );
}

export default Submission;
