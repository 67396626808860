import { useState } from "react";
import "./FAQ.css";
import { BiPlusCircle } from "react-icons/bi";
import { AiFillMinusCircle } from "react-icons/ai";

export default function FAQ(props) {
  let ques = [];
  for (let d of props.data) {
    ques.push({
      question: d.question,
      answer: d.answer,
      show: false,
    });
  }
  if (ques.length > 0) {
    ques[0].show = true;
  }
  const [questions, setQuestions] = useState(ques);

  const setShow = (index) => {
    let qs = [...questions];
    for (let i = 0; i < qs.length; i++) {
      if (i === index) {
        if (qs[i].show === true) {
          qs[i].show = false;
        } else {
          qs[i].show = true;
        }
      } else {
        qs[i].show = false;
      }
    }
    setQuestions(qs);
  };
  return (
    <section className="faq_main" style={{ clear: "both" }}>
      <div className="container">
        {/* <header> */}
        <h3 className="text-white mediumText">
          {props?.title ? props.title : "FAQ"}
        </h3>
        {props.description && <p>{props.description}</p>}
        {/* </header> */}

        <div className="accordion mt-5" id="accordionExample">
          {questions.map((faq, index) => (
            <div key={index} className="cardFaq pt-3 pb-3">
              <div
                className="card-head d-flex justify-content-between"
                id={`heading_${index}`}
                onClick={() => setShow(index)}
              >
                <h4
                  className={
                    faq.show
                      ? "collapsed question-heading mr-1"
                      : " mr-1 " + "mb-0 question-heading"
                  }
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {faq.question}
                </h4>
                {faq.show ? (
                  <AiFillMinusCircle size={30} color="#F9C80E" />
                ) : (
                  <BiPlusCircle size={30} color="#F9C80E" />
                )}
              </div>

              <div
                id="collapseOne"
                className={faq.show ? "show " : " " + "collapse"}
                aria-labelledby={`heading_${index}`}
                data-parent="#accordionExample"
              >
                <div className="card-body-mine">
                  <p className="faq-answer">{faq.answer}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
