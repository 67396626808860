import { useState, useContext, useEffect } from 'react';
import { UserContext } from 'context/UserProvider';
import { Link, useHistory } from 'react-router-dom';
import AuthService from 'actions/services/auth.service';
import { LoadingContext } from 'context/LoadingProvider';
import { Button, Modal } from 'react-bootstrap';

const ProfileEdit = ({ handleClose, show, state, setState,setImage,image }) => {
  const { user, setUser, userProfile, setUserProfile } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      ...state,
    }
    if(image) {
      payload = {
        ...payload,
        image
      }
    }
    const _data = new FormData();
    for(let key in payload){
      _data.append(key,payload[key])
    }

    AuthService.editUserProfile(_data)
      .then((response) => {
        console.log('userProfile.bio', response.data);
        if (response.success) {
          setUserProfile({ ...response, ...response.data });
        }
      })
      .catch((reject) => alert('somethingwentwrong'))
      .then(() => setLoading(false));

    e.target.reset();
    handleClose();
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    setFileToBase(file);
    console.log(file);
  }

  const setFileToBase = (file) => {
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onloadend = () => {
    //   setImage(reader.result);
    // }
    setImage(file);
  }

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="signup-form col-12 p-sm-2 p-md-4 border-4 rounded border-primary bg-white shdow"
          onSubmit={handleEdit}
        >
          <div className='form-group'>
            <label className="form-label" htmlFor="form4Example2">Image</label>
            <input onChange={handleImage} type="file" id="formupload" name="image" className="form-control" />
          </div>
          <div className="form-group">
            <label className="apercuProText font_16 pb-2 pt-2">Username</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="username"
              placeholder="username"
              value={state.username}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="apercuProText font_16 pb-2 pt-2">Location</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="location"
              placeholder="location"
              value={state.location}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label className="apercuProText font_16 pb-2 pt-2">Bio (Add commas then save to see magic happen)</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="bio"
              placeholder="Aspiring developer, Traveller, Love LOFI music"
              value={state.bio}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label className="apercuProText font_16 pb-2 pt-2">LinkedIn</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="linkedIn"
              placeholder="linkedIn"
              value={state.linkedIn}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label className="apercuProText font_16 pb-2 pt-2">Github</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="github"
              placeholder="github"
              value={state.github}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label className="apercuProText font_16 pb-2 pt-2">Website</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="website"
              placeholder="https://omahtassy.com"
              value={state.website}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-main w-100 btn-lg" disabled={state.loading}>
              {state.loading && <span className="spinner-border spinner-border-sm"></span>}
              <span>Submit</span>
            </button>
          </div>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default ProfileEdit;
