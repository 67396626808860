import { useState, useEffect, useContext, Suspense } from "react";
// import { atom, useAtom } from 'jotai'

import DashboardNavbar from "component/navbar/Navbar";
// import DashboardNavbar from '';
import { UserContext } from "context/UserProvider";
import classes from "../../../component/myProfile/MyProfile.module.css";

import TutorService from "actions/services/tutor-service";
import { Col, Row } from "react-bootstrap";
import Company from "./Company";
import { BsArrowLeftShort } from "react-icons/bs";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ReelModal from "components/QuizReelComponents/ReelModal/ReelModal";
import MockInterviewRequirements from "components/QuizReelComponents/ReelModal/MockInterviewRequirements";
import jobsService from "actions/services/jobs.service";
import { LoadingContext } from "context/LoadingProvider";
import JobEligibility from "./JobEligibility";
import { Mixpanel } from "Utils/Mixpanel";

const JobPage = () => {
  const { user, setUser } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [job, setJob] = useState(null);
  const [jobId, setJobId] = useState(params?.jobid);

  const [modal, setModal] = useState({
    show: false,
    title: "HELO HELO",
    description: "",
    CustomComponent: null,
    props: null,
  });

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      title: "",
      description: "",
      CustomComponent: null,
      props: null,
    });
  };

  const fetchJobData = async () => {
    // JobservigetJobById
    await jobsService.getJobById(jobId).then((res) => {
      setJob(res.data);
    });
  };

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/job/jobid",
      "Page name": "View Job Opportunity",
    });
    setLoading(true);
    console.log("location", location, jobId);
    if (location.state?.job) {
      console.log("from uselocation");
      setJob(location.state.job);
    } else {
      console.log("from fetch");
      fetchJobData();
    }

    const funct = async () => {
      try {
        let res3 = await TutorService.getUserAccountDetails();

        setMinutesRemaining(res3.data?.totalminutes);
        //console.log('res3======== Tutor deets', res3);
      } catch (error) {
        console.log(error);
      }
    };

    funct();
    setLoading(false);
  }, []);

  const handleApplyBtn = () => {
    setModal({
      ...modal,
      show: true,
      title: "Apply Now",
      description:
        "Book a mock interview with an instructor to better prepare for the job, and validate if you your skills are job ready. You should prepare before taking this mock interview. Tutor minutes will be deducted. All the best! ",
      // CustomComponent: null,
      // props: null
      CustomComponent: MockInterviewRequirements,
      props: {
        job,
        forModal: true,
      },
    });
  };
  return (
    job && (
      <div className={`${classes.container1}`}>
        {modal.show ? (
          <ReelModal
            show={modal.show}
            title={modal.title}
            description={modal.description}
            handleOnHide={handleOnHide}
            CustomComponent={modal.CustomComponent ?? false}
            props={modal.props ?? false}
          />
        ) : null}

        <DashboardNavbar
          minutesRemaining={minutesRemaining}
          companyName={"Rain"}
        />
        <div className={classes.title}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/jobs")}
          >
            <BsArrowLeftShort /> Back
          </span>
          <div className="d-flex justify-content-start align-items-center">
            <h1 className="">Job Opportunity</h1>
            {job.eligible ? (
              <span className={`eligible-icon p-2 m-3`}>ELIGIBLE</span>
            ) : (
              <span className={`eligible-icon not-eligible p-2 m-3`}>
                NOT ELIGIBLE
              </span>
            )}
          </div>
        </div>

        <div className={classes.subContainer}>
          {!job.eligible && (
            <div className="eligibility-note">
              <JobEligibility job={job} />
            </div>
          )}
          <Row>
            <Col lg={7} xl={8} className={classes.rightContainer}>
              <h3 className={classes.subHeading}>
                {job.company.companyname || "Company"} is hiring a{" "}
                <mark>{job.jobtitle}</mark>
              </h3>
              <p>{job.jobdescription}</p>

              <h3 className={classes.subHeading}>Job Requirements</h3>
              <ul className="job-requirements-list">
                {job.requirements.map((req, index) => (
                  <li key={index}>{req}</li>
                ))}
              </ul>

              <h3 className={classes.subHeading}>Tech Stack</h3>
              <div className="mt-3">
                <p>{job.techstacks.text}</p>
                {job.techstacks.stacks_list.map((techStack, index) => {
                  return (
                    <span className={`eligible-icon tech-tag`}>
                      {techStack}
                    </span>
                  );
                })}
              </div>

              <h3 className={classes.subHeading}>About company</h3>
              <div>
                <p>{job.company.companybreif}</p>
              </div>

              <h3 className={classes.subHeading}>Salary &amp; Compensation</h3>
              <div>
                <p>{job.salary}</p>
              </div>

              <div className={classes.blocks}></div>

              {/* {learningPathList && <EnrolledCourse learningPathList={learningPathList} />} */}

              {/* *************** */}
              {/* <h3 className={classes.subHeading} style={{ marginTop: 25 }}>
            Latest certificates
          </h3> */}
            </Col>
            <Col lg={5} xl={4} className={classes.leftContainer}>
              {/* <h3 className={classes.subHeading} style={{ marginTop: 25 }}>
            Team XP &amp; Streaks
          </h3> */}
              <div className={classes.cardContainer}>
                <Company handleApplyBtn={handleApplyBtn} job={job} />
              </div>

              {/* <h3 className={classes.subHeading}>Tech Stack </h3>
          <div className={classes.cardContainer}></div> */}
            </Col>
          </Row>
        </div>
      </div>
    )
  );
};

export default JobPage;
