import Stats from "components/QuizReelComponents/Header/Stats";
import { memo } from "react";
import { StandyByInfo } from "./InactiveVideoInfo.styled";

const VideoStandbyInfo = ({
  title,
  subTitle,
  unitTitle,
  showInfo,
  videoReady,
  playing,
}) => {
  // console.log("STANDYBY RE-RENDERED");
  return (
    <StandyByInfo
      show={showInfo === true && videoReady === true && playing === false}
    >
      {(title || subTitle) && (
        <div className="zpsection center">
          <h3 className="zph3">{subTitle}</h3>
          <h1 className="zph1 title">{title}</h1>
          <h2 className="zph2 sub-title">Unit: {unitTitle}</h2>
        </div>
      )}
      <div className="zpfooter">Paused</div>
    </StandyByInfo>
  );
};

export default memo(VideoStandbyInfo);
