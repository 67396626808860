import React, { useState } from "react";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import { useMutation } from "react-query";
import walletService from "actions/services/wallet.service";
import ZPLoading from "pages/Watch/components/ZPLoading";
import Error from "components/Error/Error";
import { inviteFriendEventTracking } from "Utils/WebEngage";

function InviteEarnBody(props) {
  const [state, setState] = useState({
    email1: "",
    email2: "",
    inviteSent1: "",
    inviteSent2: "",
  });

  const inviteEarnMutation = useMutation(
    (email) => walletService.inviteFriend(email),
    {
      onSuccess: (data) => {
        console.log("SUCCESSFULLY INVITED FREIND", data);
      },
    }
  );

  const onChangeState = (e, prop) => {
    setState({ ...state, [prop]: e.target.value });
  };

  const handleCreateTeam = () => {
    if (!state.email1) {
      alert("Please invite atleast one friend.");
      return;
    }

    inviteFriendEventTracking();
    if (state.email1) inviteEarnMutation.mutate(state.email1);
    if (state.email2) inviteEarnMutation.mutate(state.email2F);
  };

  if (inviteEarnMutation.isLoading) return <ZPLoading />;
  if (inviteEarnMutation.isError)
    return <Error message={inviteEarnMutation.error} />;
  if (inviteEarnMutation?.data?.success === false)
    return (
      <div>
        <p className="text-center">
          <FaInfoCircle style={{ fontSize: "3rem", color: "var(--yellow)" }} />
        </p>
        <p>{inviteEarnMutation?.data?.message}</p>
      </div>
    );
  if (inviteEarnMutation?.data?.success === true)
    return (
      <div>
        <FaCheckCircle style={{ fontSize: "3rem", color: "var(--green)" }} />
        <p>
          {inviteEarnMutation?.data?.data?.to} has been invited. Get 'em to
          check mail
        </p>
      </div>
    );

  return (
    <>
      <input
        type="email"
        className="form-control form-control-sm m-2"
        name="password"
        placeholder="friend@gmail.com"
        value={state.email1}
        onChange={(e) => onChangeState(e, "email1")}
      />
      <input
        type="email"
        className="form-control form-control-sm m-2"
        name="password"
        placeholder="optional - friend2@gmail.com"
        value={state.email2}
        onChange={(e) => onChangeState(e, "email2")}
      />

      {/* <small className="mt-4">
        <i>Once a member signs up with your link, you will earn Z-Coins.</i>
      </small> */}
      <br />
      <button className="btn btn-block btn-main" onClick={handleCreateTeam}>
        Invite and Earn
      </button>
    </>
  );
}

export default InviteEarnBody;
