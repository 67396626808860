import Navbar from "components/Navbar/Navbar";
// import Footer from "components/Footer/Footer";
import Footer from "components/Footer/footer/footer";
import { useContext } from "react";
import { UserContext } from "context/UserProvider";
import Banner from "components/Banner";

export default function Layout({ children }) {
  console.log("LAYOUT...");
  const { user, isProfileCompleted } = useContext(UserContext);
  return (
    <>
      {user && !isProfileCompleted && <Banner />}
      <Navbar loggedin={!!user} username={user ? user.username : ""}></Navbar>
      <div style={{ minHeight: "50vh" }}>{children}</div>
      <Footer />
    </>
  );
}
