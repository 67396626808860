import { create } from "zustand";
const DEFAULT_FILES = [
  {
    filename: "Main.java",
    sourcecode: `public class Main {
    public static void main(String[] args) {
        System.out.println("gadnuuuu");
    }
}
            `,
  },
];
const useEditorStore = create((set) => ({
  files: [],
  openFiles: [],
  sourceFiles: [],
  mode: "course", // "course" or "assignment"
  openFile: null,
  isCurrentFileOriginal: false,
  currentOriginalFile: null,
  currentIndex: 0,
  feedback: {
    loading: false,
    show: false,
    pending: false,
    message: "No feedback at the moment. Save your code to see feedback.",
  },

  textEditorValue: null,

  setFiles: (files) =>
    set(() => {
      // console.log("ZUZSTANDING THESE FILES FOR FILES and OPENFILES", files);
      return {
        files,
        openFiles: files,
      };
    }),
  setOpenFiles: (openFiles) => set(() => ({ openFiles })),
  setOpenOriginalFiles: (openFiles) => set(() => ({ openFiles })),
  setOpenFile: (openFile) => set(() => ({ openFile })),
  setSourceFiles: (sourceFiles) => set(() => ({ sourceFiles })),
  setEditorMode: (mode) => set(() => ({ mode })),
  setCurrentIndex: (currentIndex) =>
    set(() => ({
      currentIndex,
      currentOriginalFile: null,
      isCurrentFileOriginal: false,
    })),
  setFeedback: (feedback) => set(() => ({ feedback })),
  setIsCurrentFileOriginal: (isCurrentFileOriginal) =>
    set(() => {
      console.log("PASSING IT IN", isCurrentFileOriginal);
      if (isCurrentFileOriginal === false) {
        return {
          isCurrentFileOriginal,
          currentOriginalFile: null,
        };
      }
      return {
        isCurrentFileOriginal,
        currentIndex: null,
        // currentOriginalFile: null,
      };
    }),
  setCurrentOriginalFile: (currentOriginalFile) =>
    set(() => ({ currentOriginalFile })),
  setCurrentTextEditorValue: (textEditorValue) =>
    set(() => ({ textEditorValue })),
}));

export default useEditorStore;
