import { useState } from "react";
import { useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import { OverViewContainer } from "pages/Competitions/Competitions.styled";
import MarkdownToReact from "pages/Competitions/MarkdownToReact";
import Timelines from "./Timelines";
import Submission from "pages/CompetitionSubmission/Submission";
import Sponsor from "./Sponsor";

function Overview({ overview, timelines, teamid, competitionid, submission, company }) {
  const location = useLocation();
  const queries = new URLSearchParams(location.search);
  const [subTab, setSubTab] = useState(queries.get("subtab"));
  return (
    <OverViewContainer>
      <Tab.Container
        defaultActiveKey={subTab || "description"}
        className="h-100"
      >
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link eventKey="description">Description</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="timeline">Timeline</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="prizes">Prizes</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="submission">Submission Instructions</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="faq">FAQs</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="sponsoring-company">Sponsoring Company</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="description">
            {/* <MarkdownToReact url="https://zaiocontent.s3.eu-west-2.amazonaws.com/competitions/hospital-form-builder/spec/flow-diagram.md" /> */}
            <MarkdownToReact url={overview?.description} />
          </Tab.Pane>
          <Tab.Pane eventKey="timeline">
            <Timelines
              startdate={timelines?.startdate}
              enddate={timelines?.enddate}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="prizes">
            <MarkdownToReact url={overview?.prizes} />
          </Tab.Pane>
          <Tab.Pane eventKey="submission">
            <Submission
              competitionid={competitionid}
              teamid={teamid}
              submission={submission}
            />
            <MarkdownToReact url={overview?.submissioninstructions} />
          </Tab.Pane>
          <Tab.Pane eventKey="faq">
            <MarkdownToReact url={overview?.faqs} />
          </Tab.Pane>
          <Tab.Pane eventKey="sponsoring-company">
            <Sponsor company={company} />
          </Tab.Pane>

          
        </Tab.Content>
      </Tab.Container>
    </OverViewContainer>
  );
}

export default Overview;
