import { create } from "zustand";

const useAssignmentStore = create((set) => ({
  type: null, // "coding" or "mcq"
  title: "",
  assignmentData: null,
  currentQuestionId: null,
  question: null,

  adminAssignmentList: null,
  adminSelectedAssignment: null,
  adminSelectedAssignmentQuestion: null,
  adminCourseQuestion: null,
  //create-coursemcq , edit-coursemcq , edit-challenge, lecture, create-assignment-mcq, create-assignment-coding
  //create-assignment-mcq, create-assignment-coding, edit-assignment-mcq, edit-assignment-coding
  adminQuestionMode: "lecture",

  setAssignmentMode: (mode) => set(() => ({ type: mode })),
  setAssignmentData: (assignmentData) => set(() => ({ assignmentData })),
  setTitle: (title) => set(() => ({ title })),
  setCurrentQuestionId: (id) => set(() => ({ currentQuestionId: id })),
  setCurrentQuestion: (question) => set(() => ({ question })),

  setAdminAssignmentList: (adminAssignmentList) =>
    set(() => ({ adminAssignmentList })),
  setAdminSelectedAssignment: (adminSelectedAssignment) =>
    set(() => ({
      adminSelectedAssignment,
      // adminSelectedAssignmentQuestion: null,
      // adminCourseQuestion: null,
    })),
  setAdminSelectedAssignmentQuestion: (adminSelectedAssignmentQuestion) =>
    set(() => ({ adminSelectedAssignmentQuestion })),
  setAdminCourseQuestion: (adminCourseQuestion) =>
    set(() => {
      console.log("adminCourseQuestion", adminCourseQuestion);
      return { adminCourseQuestion, adminSelectedAssignmentQuestion: null };
    }),
  setAdminQuestionMode: (adminQuestionMode) =>
    set((state) => {
      console.log("adminQuestionMode", adminQuestionMode);
      let adminSelectedAssignmentQuestion;
      let adminCourseQuestion;
      // if (adminQuestionMode.includes("create")) {
      //   adminSelectedAssignmentQuestion = null;
      //   adminCourseQuestion = null;
      // }
      return {
        adminQuestionMode,
        adminSelectedAssignmentQuestion,
        adminCourseQuestion,
        // adminSelectedAssignment,
      };
    }),
}));

export default useAssignmentStore;
