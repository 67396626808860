import styled from "styled-components";

export const LineReproduction = styled.div`
  display: flex;
  margin-bottom: 0px;

  input {
    margin: auto;
  }

  span {
    font-size: 14px;
    margin-left: 5px;
  }

  .zp-progress-bar {
    cursor: pointer;
    width: 100%;
    /* margin-bottom: 15px; */
    appearance: none;
    height: 3px;
    transition: height 0.2s ease-out;
    border-radius: 5px;
    background: linear-gradient(
      93deg,
      ${(props) => props.primaryColor} ${(props) => props.progressVideo}%,
      #fff ${(props) => props.progressVideo}%
    );
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
      outline: none !important;
    }

    &::-moz-focus-outer {
      border: 0;
    }

    &::-ms-track {
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: ${(props) => props.primaryColor};
      cursor: pointer;

      outline: none !important;
      border-color: transparent;
      border: 0 !important;
      box-shadow: none !important;
      box-sizing: none;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      border: none;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: ${(props) => props.primaryColor};
      cursor: pointer;

      outline: none !important;
      border-color: transparent;
      border: 0 !important;
      box-shadow: none !important;
      box-sizing: none;
    }

    &:hover {
      height: 5px;
    }
  }
`;
export const ControlsContainer = styled.div`
  /* opacity: ${(props) => (props.show ? 1 : 0)}; */
  transform: ${(props) => (props.show ? "scale(1)" : "scale(1.2)")};
  z-index: 1;

  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.2s ease-out;

  padding: 10px;
  color: #fff;
  font-size: 1.5em;
  /* background: rgb(0, 0, 0); */
  /* Linear gradients */
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6 ) 0%,
    rgba(0, 0, 0, 0.15) 10%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.15) 90%,
    rgba(0, 0, 0, 0.5) 100%
  );

  .zp-header-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: auto;
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .zp-header__left-control {
    z-index: 2;
    div {
      display: flex;
      font-size: 20px;
      align-items: center;
      opacity: 1;
      transition: all 0.2s ease-out;
      overflow: hidden;

      span {
        margin-left: -100%;
        opacity: 0;
        transition: all ease-out 0.2s;
      }

      &:hover {
        opacity: 1;
        transform: translateX(-10px);

        span {
          margin-left: 0;
          opacity: 1;
        }
      }

      svg {
        font-size: 35px;
        margin-right: 5px;
      }
    }
  }

  .zp-header__middle-alert {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    /* border: white 1px solid; */
    border-radius: 20px;
    background: #8437f9;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
      opacity: 50%;
    }

    span {
      margin-left: 10px;
    }
  }

  .zp-header__right-control {
    z-index: 2;
    div {
      display: flex;
      font-size: 20px;
      align-items: center;
      opacity: ;
      transition: all 0.2s ease-out;
      overflow: hidden;

      svg {
        font-size: 35px;
        margin-left: 5px;
      }

      span {
        margin-right: -100px;
        opacity: 0;
        transition: all ease-out 0.2s;
      }

      &:hover {
        opacity: 1;
        transform: translateX(+10px);

        span {
          margin-right: 0;
          opacity: 1;
        }
      }
    }
  }

  .controlls {
    /* position: absolute; */
    /* width: 100vw; */
    margin: 0 0 20px 0;
    display: flex;
    justify-items: center;
    /* background: rgba(0, 0, 0, 0.3); */
    padding: 10px;
    /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.7) 20%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.7) 80%,
    rgba(0, 0, 0, 1) 100%
  ); */



    .end {
      margin-left: auto;
    }

    div {
      display: flex;
      justify-items: center;
    }

    .item-control {
      position: relative;
      margin: auto 15px;
    }

    .info-video {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      /* margin-top: -1px; */

      .info-first {
        font-weight: bold;
        opacity: 1;
        /* margin-right: 5px; */
      }

      .info-secund {
        font-weight: lighter;
        opacity: 0.5;
      }
    }

    svg {
      cursor: pointer;
      opacity: 1;
      font-size: 25px;
      transition: all 0.2s ease-out;

      &:hover {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
  .controls-with-challenge {
    z-index: 2;
  }
`;

export const VolumeControlsContainer = styled.div`
  position: relative;
  .volumn-controll {
    bottom: 70px;
    left: -30px;
    position: absolute;
    transform: rotate(-90deg);

    .box {
      background: #222222;
      padding: 15px 20px;
      border-radius: 5px;
      width: 80px;
      height: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    /* 
    .box-connector {
      width: 20px;
    } */

    .zp-volume-input {
      border: none;
      cursor: pointer;
      /* position: absolute; */
      bottom: 0;
      appearance: none;
      height: 5px;
      border-radius: 5px;
      background: #999;
      background: linear-gradient(
        93deg,
        ${(props) => props.primaryColor} ${(props) => props.percentVolume}%,
        #fff ${(props) => props.percentVolume}%
      );
      width: 70px;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: ${(props) => props.primaryColor};
        cursor: pointer;
      }

      &::-moz-range-thumb {
        -webkit-appearance: none;
        border: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: ${(props) => props.primaryColor};
        cursor: pointer;
      }
    }
  }
`;

const ItemControllBar = styled.div`
  bottom: 20px;
  right: -20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 1rem;

  .box-connector {
    height: 20px;
    width: 100%;
  }
`;

export const ItemPlaybackRate = styled(ItemControllBar)`
  cursor: pointer;
  font-weight: bold;
  max-width: 150px;

  & > div:first-child {
    background: #333;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    .title {
      font-size: 1.5rem;
      padding: 10px;
      margin: 0;
    }

    .item {
      background: #222;
      display: flex;
      font-size: 14px;
      padding: 10px;
      cursor: pointer;
      transition: all 0.2s ease-out;
      flex-direction: row;
      align-items: center;

      &:hover {
        background: #333;
      }
    }

    svg {
      font-size: 14px !important;
      margin-right: 5px;
    }

    .bold {
      font-weight: bold;
    }
  }
`;

export const IconPlayBackRate = styled.div`
  cursor: pointer;
  font-weight: bold;

  small {
    font-weight: lighter;
    font-weight: 10px;
  }

  span {
    opacity: 1;
    font-size: 25px;
    transition: all 0.2s ease-out;

    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }
`;

export const ItemNext = styled(ItemControllBar)`
  & > div:first-child {
    background: #333;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    .title {
      font-size: 1.5rem;
      padding: 10px;
      margin: 0;
    }

    .item {
      background: #222;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      padding: 10px;
      cursor: pointer;
      transition: all 0.2s ease-out;

      &:hover {
        background: #333;
      }
    }
    .bold {
      font-weight: bold;
    }
  }
`;

export const ItemListReproduction = styled(ItemControllBar)`
  max-width: 600px;
  overflow: hidden;

  & > div:first-child {
    background: #333;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;

    .bold {
      /* font-weight: bold; */
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      padding: 10px;

      span {
        margin-left: 10px;
      }
    }

    .list-list-reproduction {
      display: flex;
      flex-direction: column;
      height: 400px;
      overflow: auto;

      &::-webkit-scrollbar-track {
        background-color: #222;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #333;
      }

      .item-list-reproduction {
        background: #222;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        padding: 10px;
        cursor: pointer;
        transition: all 0.2s ease-out;
        align-items: center;

        &:hover {
          background: #333;
        }

        .percent {
          height: 3px;
          width: 100px;
          margin-left: auto;
        }

        .item-list-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .selected {
        background: #333;
        border: 1px solid white;
      }
    }
  }
`;
