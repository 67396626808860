import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import SoloStreakIcon from "assets/svg/solo-streak.svg";
import { ZCoinContainer } from "components/ZCoin/ZCoin.styled";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { Constants } from "Constants";

function SoloStreak({
  number = 0,
  handleClick = undefined,
  toolTipText = Constants.SoloStreaksExplanation,
  toolTipPlacement = "bottom",
  displayToolTip = true,
}) {
  const [showToolTip, setShowToolTip] = useState(false);
  const target = useRef(null);
  const history = useHistory();

  const handleOnClick = () => {
    if (handleClick) {
      handleClick();
      return;
    }
    history.push("/profile");
  };
  const renderTooltip = (props) => <Tooltip {...props}>{toolTipText}</Tooltip>;
  return (
    <>
      <ZCoinContainer
        ref={target}
        onClick={handleOnClick}
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
      >
        <div className="z-coin-amount">
          <img src={SoloStreakIcon} alt="LogoFooter" />
          <span>{number}</span>
        </div>
      </ZCoinContainer>
      <Overlay
        target={target.current}
        show={displayToolTip && showToolTip}
        placement={toolTipPlacement}
      >
        {renderTooltip}
      </Overlay>
    </>
  );
}

export default SoloStreak;
