import { useState, useEffect, useContext } from "react";

import DashboardNavbar from "component/navbar/Navbar";
// import DashboardNavbar from '';
import { UserContext } from "context/UserProvider";
import classes from "../../component/myProfile/MyProfile.module.css";
import TutorService from "actions/services/tutor-service";

import {
  ProgressBar,
  Button,
  Card,
  CardImg,
  Modal,
  Row,
  Col,
  Dropdown,
} from "react-bootstrap";
import WalletBalance from "./WalletBalance";
import FAQ from "components/FAQ/FAQ";
import { WalletFaq } from "./../../Constants";
import InviteLink from "./Invite/InviteLink";
import { Constants } from "./../../Constants";
import { Mixpanel } from "Utils/Mixpanel";
import ReactPlayer from "react-player";

const Wallet = () => {
  const { user, setUser } = useContext(UserContext);
  const [faq, setFaq] = useState(WalletFaq);

  const [minutesRemaining, setMinutesRemaining] = useState(0);

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/wallet",
      "Page name": "Wallet page",
    });
    const funct = async () => {
      try {
        let res3 = await TutorService.getUserAccountDetails();

        setMinutesRemaining(res3.data?.totalminutes);
        //console.log('res3======== Tutor deets', res3);
      } catch (error) {
        console.log(error);
      }
    };

    funct();
  }, []);

  return (
    <div className={`${classes.container}`}>
      <DashboardNavbar minutesRemaining={minutesRemaining} />
      <div className={classes.title}>
        <h1>My Wallet</h1>
      </div>

      <Row className={classes.subContainer}>
        <Col lg={5} xl={4} className={classes.leftContainer}>
          {/* <h3 className={classes.subHeading} style={{ marginTop: 25 }}>
            Team XP &amp; Streaks
          </h3> */}

          <WalletBalance toolTipText={Constants.ZCoinExplanation} />
          <InviteLink />

          {/* <h3 className={classes.subHeading}>Roadmap &amp; Goals</h3>
          <div className={classes.cardContainer}>
            <TeamRoadmap />
          </div> */}
        </Col>
        <Col lg={7} xl={8} className={classes.rightContainer}>
          {/* <h3 className={classes.subHeading}>FAQs</h3> */}
          <ReactPlayer
            width="100%"
            height="50%"
            controls
            url="https://youtu.be/2lhLyhSxgi4"
          />
          <FAQ
            data={faq}
            title={"The power of Z-Coin"}
            description={Constants.ZCoinExplanation}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <div className={classes.blocks}>
              <h3 className={classes.subHeading} style={{ marginTop: 25 }}>
                About Z-Coin
              </h3>
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Wallet;
