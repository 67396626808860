import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import lectureService from "actions/services/lecture.service";

class NewLecture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courseid: this.props.courseid,
      removelectureid: this.props.removelectureid,
      courseunitid: this.props.courseunitid,
      lecturedescription: this.props.lecturedescription || "",
      ismobilelecture: this.props.ismobilecourse ?? false,
      lecturename: "",
      lectureno: 0,
      // lectureurl: "https://zaiocontent.s3.eu-west-2.amazonaws.com/test1.mp4",
      lectureurl: "",
      duration: null,
      checkpoint: [],
      type: "lecture",
      temptimemin: 0,
      temptimesec: 0,
      temppause: false,
      tempurl:
        "https://codesandbox.io/embed/coding-on-zaio-m2e9pw?fontsize=14&hidenavigation=1&theme=dark",
      tempid: 0,
      editor: "codesandbox",
      quizoptions: [],
      pushtime: "",
      continue: "",
      tryagain: "",
      tryagainpush: "",
      quizOption: {
        correctanswer: false,
        nextstep: "",
        nexttimestamp: "",
        text: "",
        index: "",
      },
      editMode: false,
      quizpushes: [], //following backend structure
    };
    this.videoRef = React.createRef();
  }

  handleMetadata = () => {
    if (this.videoRef.current) {
      this.setState({ duration: Math.floor(this.videoRef.current.duration) });
    }
  };

  handleChange = (event) => {
    this.setState({ videoUrl: event.target.value, duration: null });
  };

  async componentDidMount() {
    if (this.props.lectureid) {
      const res = await lectureService.getLecture(this.props.lectureid);
      if (res.success) {
        if (res?.data?.quizpushes.length >= 0) {
          const qpObj = res.data.quizpushes[0];
          this.setState({
            ...res.data,
            quizoptions: qpObj?.quizoptions,
            pushtime: qpObj?.pushtime,
            continue: qpObj?.continue,
            tryagain: qpObj?.tryagain,
            tryagainpush: qpObj?.tryagainpush,
          });
        }
      } else {
        alert("please enroll in the course to make a change to lecture");
      }
    } else if (this.props.removelectureid) {
      const res = await lectureService.getLecture(this.props.removelectureid);
      if (res.success) {
        this.setState({ ...res.data });
      } else {
        alert("please enroll in the course to make a change to lecture");
      }
    }
  }
  setValue = (key, value, callback) => {
    this.setState(
      {
        [key]: value,
      },
      callback
    );
  };
  onSubmit = (e) => {
    e.preventDefault();

    const funct = async () => {
      let res = null;
      try {
        const quizPushObj = {
          continue: this.state.continue,
          pushtime: this.state.pushtime,
          quizoptions: this.state.quizoptions,
          tryagain: this.state.tryagain,
          tryagainpush: this.state.tryagainpush,
        };

        const qpArr = [{ ...quizPushObj }];
        // console.log("qpArr", qpArr);
        // this.setState({ quizpushes: qpArr });

        const objToPost = {
          ...this.state,
          quizpushes: qpArr,
        };
        console.log("THIS.STATE", objToPost);
        this.props.setLoading(true);
        if (this.props.lectureid) {
          res = await lectureService.putLecture(objToPost);
        } else if (this.props.removelectureid) {
          res = await lectureService.deleteLecture(this.props.removelectureid);
        } else {
          res = await lectureService.postLecture(objToPost);
        }
        if (res.success) {
          this.props.setUpdate(true);
          this.props.setNewLectureShow(false);
        } else {
          alert("Something went wrong");
        }
      } catch (error) {
        alert("Something went wrong");
      }
    };
    funct();
  };

  render() {
    return (
      <Form
        className="w-100 ml-0 ml-md-5 p-2 bg-white"
        style={{ height: "fit-content" }}
        onSubmit={(e) => this.onSubmit(e)}
      >
        <h1>{this.props.removelectureid}</h1>
        {!this.props.removelectureid ? (
          <>
            <p></p>
            <Form.Check
              className="mt-2"
              checked={this.state.ismobilelecture}
              type="checkbox"
              label="IS MOBILE Lecture"
              onClick={(e) => {
                this.setValue("ismobilelecture", !this.state.ismobilelecture);
                this.setValue("editor", "quizreels");
              }}
            />
            <Form.Group className="mb-3">
              <Form.Label>
                Lecture Name + {this.props.removelectureid}
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Lecture Name"
                value={this.state.lecturename}
                onChange={(e) => this.setValue("lecturename", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Lecture Description</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Lecture Name"
                value={this.state.lecturedescription}
                onChange={(e) =>
                  this.setValue("lecturedescription", e.target.value)
                }
              />
            </Form.Group>
            {this.state.lectureurl && (
              <video
                ref={this.videoRef}
                src={this.state.lectureurl}
                onLoadedMetadata={this.handleMetadata}
                style={{ display: "none" }}
              />
            )}
            <Form.Group className="mb-3">
              <Form.Label>Lecture Number</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Lecture Number"
                value={this.state.lectureno}
                onChange={(e) => this.setValue("lectureno", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Lecture Video URL</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Lecture Name"
                value={this.state.lectureurl}
                onChange={(e) => this.setValue("lectureurl", e.target.value)}
              />
            </Form.Group>

            {this.state.ismobilelecture ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Timestamps</Form.Label>
                  <Row>
                    <Col>Quiz Push time</Col>
                    <Col>
                      <Form.Control
                        placeholder="Time (m)"
                        value={this.state.pushtime}
                        onChange={(e) =>
                          this.setState({ pushtime: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>Continue at:</Col>
                    <Col>
                      <Form.Control
                        placeholder="Time (m)"
                        value={this.state.continue}
                        onChange={(e) =>
                          this.setState({ continue: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>Try again push at:</Col>
                    <Col>
                      <Form.Control
                        placeholder="Time (m)"
                        value={this.state.tryagainpush}
                        onChange={(e) =>
                          this.setState({ tryagainpush: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>Try again from:</Col>
                    <Col>
                      <Form.Control
                        placeholder="Time (m)"
                        value={this.state.tryagain}
                        onChange={(e) =>
                          this.setState({ tryagain: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Quiz Options</Form.Label>
                  <br />
                  <div className="mb-3">
                    <Row>
                      <Col lg className="mt-2">
                        <Form.Control
                          placeholder="Text"
                          value={this.state.quizOption.text}
                          onChange={(e) =>
                            this.setState({
                              quizOption: {
                                ...this.state.quizOption,
                                text: e.target.value,
                              },
                            })
                          }
                        />
                      </Col>
                      <Col sm className="mt-2">
                        <Form.Control
                          placeholder="Timestamp"
                          value={this.state.quizOption.nexttimestamp}
                          onChange={(e) => {
                            this.setState({
                              quizOption: {
                                ...this.state.quizOption,
                                nexttimestamp: e.target.value,
                              },
                            });
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          className="mt-2"
                          checked={this.state.quizOption.correctanswer}
                          type="checkbox"
                          label="Correct Answer"
                          onClick={(e) =>
                            this.setState({
                              quizOption: {
                                ...this.state.quizOption,
                                correctanswer:
                                  !this.state.quizOption.correctanswer,
                                nextstep: this.state.quizOption.correctanswer
                                  ? ""
                                  : "tryAgain",
                              },
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <div style={{ display: "flex" }}>
                    {this.state.editMode ? (
                      <Button
                        onClick={(e) =>
                          this.setState((prevState) => ({
                            quizoptions: prevState.quizoptions.map((qp) => {
                              console.log(
                                "YOOOOO",
                                qp,
                                prevState.quizOption,
                                prevState.quizoptions
                              );
                              if (qp.index === this.state.quizOption.index) {
                                return {
                                  ...this.state.quizOption,
                                  nextstep:
                                    this.state.quizOption.correctanswer === true
                                      ? ""
                                      : "tryAgain",
                                };
                              }
                              return qp;
                            }),
                            editMode: false,
                          }))
                        }
                      >
                        EDIT Quiz Push
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) =>
                          this.setState((prevstate) => ({
                            quizoptions: [
                              ...prevstate.quizoptions,
                              {
                                ...this.state.quizOption,
                                index: prevstate.quizoptions.length,
                                nextstep:
                                  this.state.quizOption.correctanswer === true
                                    ? ""
                                    : "tryAgain",
                              },
                            ],
                            quizOption: {
                              correctanswer: false,
                              nextstep:
                                this.state.quizOption.correctanswer === true
                                  ? ""
                                  : "tryAgain",
                              nexttimestamp: "",
                              text: "",
                              index: "",
                            },
                          }))
                        }
                      >
                        ADD Quiz Push
                      </Button>
                    )}
                    <Button
                      className="m-2"
                      onClick={(e) =>
                        this.setState((prevstate) => ({
                          quizOption: {
                            correctanswer: false,
                            nextstep: "tryAgain",
                            nexttimestamp: "",
                            text: "",
                            index: "",
                          },
                        }))
                      }
                    >
                      Clear
                    </Button>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  {this.state.quizoptions.map((item, index) => (
                    <Row
                      style={{
                        background: item.correctanswer ? "#9DBBAE" : "",
                        cursor: "pointer",
                      }}
                    >
                      {/* <Col>Option {index + 1}</Col> */}
                      <Col>
                        {item.text} - {item.nexttimestamp}{" "}
                      </Col>
                      <Col>
                        <div style={{ display: "flex" }}>
                          <Button
                            onClick={(e) =>
                              this.setState((prevstate) => ({
                                quizOption: {
                                  correctanswer: item.correctanswer,
                                  nextstep: item.correctanswer
                                    ? ""
                                    : "tryAgain",
                                  nexttimestamp: item.nexttimestamp,
                                  text: item.text,
                                  index: item.index,
                                },
                                editMode: true,
                              }))
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={(e) => {
                              this.setState({
                                quizoptions: this.state.quizoptions.filter(
                                  (x) => {
                                    return x.index != item.index;
                                    // if (item._id) {
                                    //   return x._id != item._id;
                                    // } else {

                                    // }
                                  }
                                ),
                              });
                            }}
                          >
                            Delete
                          </Button>{" "}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Editor</Form.Label>
                  <Form.Control
                    re
                    as="select"
                    size="md"
                    value={this.state.editor}
                    onChange={(e) => this.setValue("editor", e.target.value)}
                  >
                    <option value="codesandbox">CodeSandBox</option>
                    <option value="quizreels">Quiz Reels</option>
                    <option value="paiza">Paiza</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Control
                        placeholder="Time (m)"
                        value={this.state.temptimemin}
                        onChange={(e) =>
                          this.setState({ temptimemin: e.target.value })
                        }
                      />
                      minutes
                    </Col>
                    <Col>
                      <Form.Control
                        placeholder="Time (s)"
                        value={this.state.temptimesec}
                        onChange={(e) =>
                          this.setState({ temptimesec: e.target.value })
                        }
                      />
                      seconds
                    </Col>
                    <Col>
                      <Form.Check
                        className="mt-2"
                        checked={this.state.temppause}
                        type="checkbox"
                        label="Pause"
                        onClick={(e) =>
                          this.setState({ temppause: !this.state.temppause })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    placeholder="SandBox ID"
                    value={this.state.tempurl}
                    onChange={(e) => this.setState({ tempurl: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Button
                    onClick={(e) =>
                      this.setState((prevstate) => ({
                        checkpoint: [
                          ...prevstate.checkpoint,
                          {
                            checkpointtime:
                              +prevstate.temptimemin * 60 +
                              +prevstate.temptimesec,
                            checkpointdataurl: prevstate.tempurl,
                            checkpointpause: prevstate.temppause,
                            key: prevstate.tempid,
                          },
                        ],
                        tempid: prevstate.tempid + 1,
                        tempurl: "",
                        temptimemin: 0,
                        temptimesec: 0,
                        temppause: false,
                      }))
                    }
                  >
                    ADD
                  </Button>
                </Form.Group>
                <Form.Group className="mb-3">
                  {this.state.checkpoint.map((cp) => {
                    return (
                      <Row key={cp._id ? cp._id : cp.key}>
                        <Col>
                          <Form.Control
                            type="number"
                            placeholder={`${cp.checkpointtime} sec`}
                            readOnly
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder={`${cp.checkpointdataurl}`}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={
                              cp.checkpointpause == "true" ? true : false
                            }
                            readOnly
                          />
                        </Col>
                        <Col>
                          <Button
                            onClick={(e) =>
                              this.setState((prevstate) => {
                                if (cp._id) {
                                  return {
                                    checkpoint: prevstate.checkpoint.filter(
                                      (x) => x._id !== cp._id
                                    ),
                                  };
                                }
                                return {
                                  checkpoint: prevstate.checkpoint.filter(
                                    (x) => x.key !== cp.key
                                  ),
                                };
                              })
                            }
                          >
                            DELETE
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                </Form.Group>{" "}
              </>
            )}
          </>
        ) : (
          <h2>
            {" "}
            Do you want to delete the{" "}
            <span className="text-danger">{this.state.lecturename} </span>
            lecture ?
          </h2>
        )}

        <button
          type="submit"
          className="btn btn-main mt-3"
          onClick={(e) => this.onSubmit(e)}
        >
          Submit
        </button>
      </Form>
    );
  }
}

export default NewLecture;
