import Editor, { useMonaco } from "@monaco-editor/react";
import ReelModal from "components/QuizReelComponents/ReelModal/ReelModal";
import React, { useCallback, useEffect, useState } from "react";
import CreateFileForm from "./CreateFileForm";
import Explorer from "./Explorer";
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/solid";
import useEditorStore from "zustand-store/editorStore";
import { BsFillCircleFill } from "react-icons/bs";
import LogoNav from "assets/svg/Logonav.svg";
import ConsoleOutput from "components/IDE/ConsoleOutput";
import { Puff } from "react-loader-spinner";
import OpenAIChat from "./OpenAI/OpenAIChat";

const defaultFiles = [
  {
    filename: "Main.java",
    sourcecode: `public class Main {
              public static void main(String[] args) {
                  System.out.println("hello, world");
              }
          }
          `,
  },
  {
    filename: "Car.java",
    sourcecode: `public class Car {
                
            }
            `,
  },
  {
    filename: "Seat.java",
    sourcecode: `public class Seat {
             
            }
            `,
  },
];
function MultiFileEditor({ onFileSave = undefined }) {
  const monaco = useMonaco();

  const files = useEditorStore((state) => state.files);
  const setFiles = useEditorStore((state) => state.setFiles);
  const openFiles = useEditorStore((state) => state.openFiles);
  const setOpenFiles = useEditorStore((state) => state.setOpenFiles);
  const currentIndex = useEditorStore((state) => state.currentIndex);
  const setCurrentIndex = useEditorStore((state) => state.setCurrentIndex);
  const feedback = useEditorStore((state) => state.feedback);
  const setFeedback = useEditorStore((state) => state.setFeedback);
  const sourceFiles = useEditorStore((state) => state.sourceFiles);

  const isCurrentFileOriginal = useEditorStore(
    (state) => state.isCurrentFileOriginal
  );
  const setOpenFile = useEditorStore((state) => state.setOpenFile);
  const openFile = useEditorStore((state) => state.openFile);

  const currentOriginalFile = useEditorStore(
    (state) => state.currentOriginalFile
  );
  const setIsCurrentFileOriginal = useEditorStore(
    (state) => state.setIsCurrentFileOriginal
  );
  const setCurrentOriginalFile = useEditorStore(
    (state) => state.setCurrentOriginalFile
  );

  const [editingIndex, setEditingIndex] = useState(currentIndex);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [saveFile, setSaveFile] = useState(false);
  const [tab, setTab] = useState("feedbackTab");

  const [modal, setModal] = useState({
    show: false,
    title: "Challenge Completed! 🎉",
    description:
      "Congratulations! You have completed this challenge. You can now move on to the next lesson.",
    CustomComponent: null,
    props: null,
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const handleAddNewFile = (e, newFileName) => {
    e.preventDefault();
    
    // validations
    if(newFileName.includes(" ")){
      alert("Sorry, file name cannot contain spaces");
      return;
    }
    if(newFileName.split(".").length !== 2){
      alert("Please create a valid file name");
      return;
    }
    const newFile = {
      filename: newFileName,
      sourcecode: "",
    };

    let isFileCreated = false;
    for (let i = 0; i < files.length; i++) {
      if (files[i]?.filename === newFileName) {
        console.log("File already created");
        isFileCreated = true;
        break;
      } else {
        isFileCreated = false;
        console.log("File not created");
      }
    }

    if (!isFileCreated) {
      setFiles([...files, newFile]);
      setOpenFiles([...openFiles, newFile]);
      setOpenFile(newFile);
    } else {
      alert("Sorry, this file already exists");
    }

    handleOnHide();
  };

  const handleAddFile = () => {
    setModal({
      ...modal,
      show: true,
      title: "Set File Name",
      description: "",
      CustomComponent: CreateFileForm,
      props: {
        handleAddNewFile,
      },
    });
  };
  const handleDeleteFile = (event, fileToDelete) => {
    event.stopPropagation();
    const newFiles = files.filter(
      (file) => file?.filename !== fileToDelete?.filename
    );
    setFiles(newFiles);
    setOpenFile(files[0]);
  };

  const handleOnChange = (value, event, file, isCurrentFileOriginal) => {
    if ((!isCurrentFileOriginal || !currentOriginalFile?.filename) && file) {
      let editedCode;

      editedCode = {
        ...file,
        sourcecode: value,
        editing: true,
      };
      setOpenFile(editedCode);

      setFiles(
        files.map((file, index) =>
          file?.filename === openFile?.filename ? editedCode : file
        )
      );
    }
  };

  const handleOpenFile = (file, index, isOriginal = false) => {
    // if (isOriginal) {
    //   if (!openFiles.includes(sourceFiles[index])) {
    //     setOpenFiles([...openFiles, file]);
    //   }
    // }

    setOpenFile(file);

    if (!isCurrentFileOriginal || !currentOriginalFile?.filename) {
      console.log("\n\n\n\nOpening this file...", file);
      let isFileOpen = false;

      for (let i = 0; i < openFiles.length; i++) {
        if (openFiles[i]?.filename.trim() === file?.filename.trim()) {
          console.log("File already open");
          isFileOpen = true;
          break;
        } else {
          isFileOpen = false;
          console.log("File not open");
        }
      }

      console.log("\n\n\n\nisFileOpen...", isFileOpen);
      if (!isFileOpen) {
        setIsCurrentFileOriginal(false);
        setCurrentOriginalFile(null);
        setCurrentIndex(index);
        setOpenFiles([...openFiles, file]);
      }
    }
    // if (!openFilesWithoutEditingProp.includes(fileWithoutEditingProp)) {
    //   setOpenFiles([...openFiles, file]);
    // }

    // if (!openFiles.includes(sourceFiles[index])) {
    //   setOpenFiles([...openFiles, sourceFiles[index]]);
    // }
  };
  const handleCloseFile = (index) => {
    const newOpenFiles = openFiles.filter((file, i) => i !== index);
    setOpenFiles(newOpenFiles);
    if (currentIndex === index) {
      setCurrentIndex(openFiles.length - 1);
    }
  };

  const overrideSave = (e) => {
    if (
      e.keyCode === 83 &&
      (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
    ) {
      e.preventDefault();

      // if (assignment) saveCodingChallengeMutation.mutate();
      onCtrlS();
    } else if (
      e.keyCode === 78 &&
      (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
    ) {
      e.preventDefault();
      handleAddFile();
    }
  };

  useEffect(() => {
    if (!isCurrentFileOriginal) {
      console.log("!isCurrentFileOriginalhere", openFile);
      if (saveFile && openFile?.editing) {
        saveCode(openFile);
        if (onFileSave) onFileSave(openFile);
        setTimeout(() => {
          setSaveFile(false);
        }, 1400);
      }
    }
  }, [openFile, saveFile]);

  const onCtrlS = () => {
    console.log("BEING CALLED...", openFile);
    setSaveFile(true);
  };
  const saveCode = (file) => {
    console.log("Saving this file...", file, file?.sourcecode);
    if (!isCurrentFileOriginal) {
      let editedCode;
      editedCode = {
        ...file,
        editing: false,
      };
      setFiles(
        files.map((file, index) =>
          file?.filename === openFile?.filename ? editedCode : file
        )
      );
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", overrideSave, false);

    // 👇️ remove the event listener when component unmounts
    return () => {
      document?.removeEventListener("keydown", overrideSave);
    };
  }, []);

  const handleFeedbackBtn = () => {
    setFeedback({
      ...feedback,
      pending: false,
      show: !feedback?.show,
    });
  };

  const handleSupportButton = useCallback(() => {
    alert(
      "You will be redirected to whatsapp for support! See you in just a moment."
    );
    window.open(
      `https://wa.me/+27763793540/?text=Hi there. I need some assistance. I am ${
        user?.username || "a keen learner"
      }. My email is ${
        user.email || ", actually don't have one yet. I am new here...."
      }. This is the link to the video, I was watching: www.zaio.io/app/watch/${courseid}/${unitid}/${lectureid}
      
      My problem is.....`,
      "_blank"
    );
  }, []);

  const displayFeedbackMessage = () => {
    // const message =
    //   "There is an error in your code. In Java, the main method is case sensitive, it should start with a lowercase 'm'. Additionally, you are missing a semi-colon at the end of your System.out.println() statement. Here is the corrected code:\n" +
    //   "\n" +
    //   "```\n" +
    //   "public class Main {\n" +
    //   "    public static void main(String[] args)  {\n" +
    //   '        System.out.println("Hi");\n' +
    //   "    }\n" +
    //   "}\n" +
    //   "``` \n" +
    //   "\n" +
    //   'This code will output "Hi" to the console when run. Keep up the good work!';

    if (feedback?.message) {
      const message = feedback?.message;
      return (
        <div>
          <p
            className="font-light italic text-sm"
            dangerouslySetInnerHTML={{ __html: message.split("```")[0] }}
          ></p>
          <pre>
            <code>{message.split("```")[1]}</code>
          </pre>
          <p
            className="font-light italic text-sm"
            dangerouslySetInnerHTML={{ __html: message.split("```")[2] }}
          ></p>
        </div>
      );
    }
  };

  const showChat = () => {
    if (feedback?.isLoading)
      return (
        <Puff
          height="25"
          width="25"
          radius={1}
          color="white"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      );

    if (feedback?.show) return <XMarkIcon className="w-75 h-75" />;

    return <img src={LogoNav} />;
  };

  const getLanguageForEditor = (ext) => {
    switch (ext) {
      case 'py':
        return "python";
      case 'js':
        return "javascript";
      case 'java':
        return "java";
      default:
        return "java";
    }
  }

  const displayCodeEditor = () => {
    console.log("OPENFILE...", openFile);
    if (!isCurrentFileOriginal && openFile) {
      return (
        <Editor
          className="monaco-editor"
          height="100%"
          width="100%"
          theme="vs-dark"
          path={openFile?.filename}
          language={getLanguageForEditor(openFile?.filename?.split(".")[1])}
          value={openFile?.sourcecode}
          // onValidate={handleEditorValidation}
          // beforeMount={handleEditorWillMount}
          // onMount={handleEditorDidMount}
          onChange={(value, event) =>
            !currentOriginalFile?.filename &&
            !isCurrentFileOriginal &&
            handleOnChange(value, event, openFile, isCurrentFileOriginal)
          }
          options={{
            automaticLayout: true,
            fontLigatures: true,
            fontSize: 16,
            minimap: {
              enabled: false,
            },
            readOnly: false,
            renderLineHighlight: "line",
            renderLineHighlightOnlyWhenFocus: false,
            cursorSmoothCaretAnimation: true,
            cursorBlinking: "smooth",
          }}
        />
      );
    }

    if (isCurrentFileOriginal && currentOriginalFile?.filename) {
      return (
        <Editor
          className="monaco-editor" 
          height="100%"
          width="100%"
          // theme="vs-dark"
          path={"original-" + currentOriginalFile?.filename}
          language={getLanguageForEditor(currentOriginalFile?.filename?.split(".")[1])}
          value={currentOriginalFile?.sourcecode}
          onChange={(value, event) => handleOnChange(value, event, null)}
          options={{
            automaticLayout: true,
            fontLigatures: true,
            fontSize: 16,
            minimap: {
              enabled: false,
            },
            readOnly: true,
            renderLineHighlight: "line",
            renderLineHighlightOnlyWhenFocus: false,
            cursorSmoothCaretAnimation: true,
            cursorBlinking: "smooth",
          }}
        />
      );
    }

    return (
      <div className="bg-white w-full h-full flex justify-center">
        <div className="">
          <p className="text-dark mt-40">
            Select a project file or Create a new file.
          </p>
          <button
            className="bg-purple-400 rounded px-3 py-2 w-full text-center"
            onClick={handleAddFile}
          >
            Create File
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full flex relative">
      <p
        className="absolute bottom-0 left-0 py-1 bg-purple-400 z-10 rounded-full italic text-white font-light m-2 px-3 cursor-pointer text-sm hover:bg-sky-700"
        onClick={() =>
          window.open(
            "https://www.loom.com/share/9a5877177dcb4da993950ac6e036c297",
            "_blank"
          )
        }
      >
        How to use this?
      </p>
      <button
        className="absolute top-0 right-0 py-1 bg-green-600 hover:bg-green-400 hover:text-dark z-10 rounded-full text-white font-light m-2 px-3 hover:bg-sky-700"
        onClick={!feedback?.isLoading && onCtrlS}
      >
        {feedback?.isLoading ? (
          <Puff
            height="25"
            width="25"
            radius={1}
            color="white"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          "Save (Ctrl + S)"
        )}
      </button>
      <div className="absolute bottom-0 right-0 m-2 cursor-pointer h-full flex flex-col-reverse z-10 ">
        <div className="flex w-full justify-end mb-2">
          <div
            className="text-white bg-purple-400 rounded-full h-14 w-14 px-2 flex items-center mt-1 mr-2 justify-center hover:bg-sky-700"
            onClick={!feedback?.isLoading && handleFeedbackBtn}
          >
            {showChat()}
          </div>
          {feedback?.pending && (
            <span className="relative">
              <BsFillCircleFill className="ml-1 w-4 h-4 text-red-600 absolute top-0 right-0 mr-3 rounded-full" />
            </span>
          )}
        </div>
        {feedback?.show && (
          <>
            <div className="h-3/4 w-96 bg-gray-100 border-2 bg-slate rounded-md mr-2 mt-2 overflow-x-hidden">
              <div className="d-flex justify-around cursor-pointer bg-white text-white z-10 relative">
                <div
                  onClick={() => setTab("feedbackTab")}
                  className={`
                    w-full d-flex justify-center p-3  ${
                      tab === "feedbackTab"
                        ? "bg-purple-400 font-medium"
                        : "text-purple-400"
                    }
                  `}  
                >
                  Feedback
                </div>
                <div
                  onClick={() => setTab("Chat")}
                  className={`
                    w-full d-flex justify-center p-3  ${
                      tab === "feedbackTab"
                        ? "text-purple-400"
                        : " bg-purple-400 font-medium"
                    }
                  `}
                >
                  Chat
                </div>
              </div>
              {tab === "feedbackTab" ? (
                <>
                  <div className="flex flex-col justify-between items-center p-2 bg-white w-full">
                    <h1 className="text-lg m-0 font-bold">Feedback</h1>
                  </div>
                  <div className="bg-white p-2 rounded-md bg-slate m-2">
                    <p className="text-sm opacity-70 font-bold">
                      FEEDBACK FROM ZAIO ASSISTANT
                    </p>
                    {displayFeedbackMessage()}
                    {/* <p className="font-light italic text-sm">{feedback?.message}</p> */}
                    {/* <div>
                <button className="bg-green-400 text-sm px-2 mx-1 rounded-md text-right text-white">
                  Makes sense
                </button>
                <button
                  className="bg-yellow-400 text-sm px-2 mx-1 rounded-md text-right text-white"
                  onClick={handleSupportButton}
                >
                  Doesn't make sense
                </button>
              </div> */}
                  </div>
                </>
              ) : (
                <OpenAIChat />
              )}
            </div>
          </>
        )}
      </div>
      {/* <Chat /> */}
      {modal.show && (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          props={modal.props}
          CustomComponent={modal.CustomComponent ?? false}
          // videoUrl={"https://youtu.be/Uv9URjIBtUQ"}
        />
      )}
      <div className="flex-none w-40 h-full bg-gray-900">
        <Explorer
          currentIndex={currentIndex}
          handleOpenFile={handleOpenFile}
          files={files}
          handleDeleteFile={handleDeleteFile}
          handleAddFile={handleAddFile}
          onCtrlS={onCtrlS}
        />
      </div>
      <div className="grow h-screen">
        <div className="w-full bg-gray-900 text-white flex justify-between items-center">
          <div className="flex">
            {!isCurrentFileOriginal &&
              openFiles &&
              openFiles?.map((file, index) => (
                <button
                  key={index}
                  className={`flex items-center justify-between px-2 ${
                    file?.isOriginal && "bg-purple-400"
                  } ${openFile?.filename === file?.filename && "bg-gray-600"}`}
                  onClick={() => {
                    setOpenFile(file);
                  }}
                  onMouseOver={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                >
                  <span className="flex items-center">
                    <small
                      className={`mx-1 text-left ${
                        file?.isOriginal && "italic"
                      }`}
                    >
                      {file?.filename}
                    </small>
                    {file?.editing && (
                      <BsFillCircleFill className="ml-1 w-3 h-3 opacity-60" />
                    )}
                  </span>
                  {hoverIndex === index && (
                    <XCircleIcon
                      className="mx-1 text-right h-4 w-4 text-white"
                      onClick={() => handleCloseFile(index)}
                    />
                  )}
                </button>
              ))}

            {isCurrentFileOriginal && currentOriginalFile && (
              <button
                className={`flex items-center justify-between min-w-32 ${"bg-purple-400"}`}
              >
                <span className="flex items-center">
                  <small className={`mx-1 text-left italic`}>
                    Original File: {currentOriginalFile?.filename}
                  </small>
                </span>

                <XCircleIcon
                  className="mx-1 text-right h-4 w-4 text-white"
                  onClick={() => {
                    setIsCurrentFileOriginal(false);
                  }}
                />
              </button>
            )}

            <button className={`px-2 hover:bg-sky-700`} onClick={handleAddFile}>
              <small className="mx-2">+</small>
            </button>
          </div>
        </div>
        {displayCodeEditor()}
      </div>
    </div>
  );
}

export default MultiFileEditor;