import { useState, useEffect, useContext, useCallback } from "react";
import classes from "./MyProfile.module.css";

import { useHistory, useLocation } from "react-router-dom";

import {
  ProgressBar,
  Button,
  Card,
  CardImg,
  Modal,
  Row,
  Col,
  Dropdown,
} from "react-bootstrap";

import AuthService from "actions/services/auth.service";
import { UserContext } from "context/UserProvider";
import { LoadingContext } from "context/LoadingProvider";

import moment from "moment";
import Avatar from "react-avatar";
import { CopyToClipboard } from "react-copy-to-clipboard";

import userImg from "assets/img/myProfile/user.svg";
import badge from "assets/img/myProfile/badge.svg";
import certificatesImg from "assets/img/myProfile/certificates.svg";
import github from "assets/img/myProfile/github.svg";
import linkedin from "assets/img/myProfile/linkedin.svg";
import navigate from "assets/img/myProfile/navigate.svg";
import net from "assets/img/myProfile/net.svg";
import lectureService from "actions/services/lecture.service";

import { saveAs } from "file-saver";

import learningpathService from "actions/services/learningpath.service";
import tutorService from "actions/services/tutor-service";
import courseService from "actions/services/course.service";
import calculateCompletionDate from "pages/DashBoard/completionAlgo";


import DashboardNavbar from "../navbar/Navbar.jsx";
import ProfileEdit from "./ProfileEdit.jsx";
import EnrolledCourse from "./EnrolledCourse.jsx";
import SoloStreakScore from "./SoloStreakScore";
import { Mixpanel } from "Utils/Mixpanel";

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import toonavatar from 'cartoon-avatar';
import Poster from "../../pages/Competitions/CompetitionPage/Poster";
import { ComponentContainer, PosterContainer } from "../../pages/Competitions/Competitions.styled";

import WalletBalance from "../../pages/Wallet/WalletBalance";
import TeamStreak from "../../components/TeamStreak/TeamStreak";
import SoloStreak from "../../components/SoloStreak/SoloStreak";
import TeamStreakScore from "../../pages/TeamLearning/TeamStreakScore";
import { BsFillPencilFill } from "react-icons/bs";
import ActivityChart from "./ActivityChart";
import Questions from "./Questions";
import { CalendarPage } from "component/TasksCalendar/TasksCalendar";

import ProfileCommon from "./ProfileCommon";

const MyProfile = (props) => {
  const [learningPathList, setLearningPathList] = useState({});
  const [certificates, setCertificate] = useState({});
  const [certificateData, setcertificateData] = useState({
    certificateurl: "",
    coursename: "",
  });

  const [continueLearning, setContinueLearning] = useState(null);
  const [values, setValues] = useState([1]);
  const [days, setDaysLeft] = useState(0);
  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [percentage, setPercentage] = useState(55);
  const [viewCount, setViewCount] = useState(3);
  const history = useHistory();
  const location = useLocation();
  const [tab, setTab] = useState("Certificates");
  const [avatar, setAvatar] = useState("");

  const [certificateShow, setCertificateShow] = useState(false);
  const [show, setShow] = useState(false);
  const { userProfile, setUserProfile } = useContext(UserContext);
  const [state, setState] = useState({
    username: userProfile?.username,
    location: userProfile?.location,
    bio: userProfile?.bio,
    linkedIn: userProfile?.linkedIn,
    github: userProfile?.github,
    website: userProfile?.website,
  });
  const [image, setImage] = useState([]);
  const [profileLink, setProfileLink] = useState({
    link: ``,
    copied: false,
  });
  const [totalCourse, setTotalCourse] = useState([]);
  const [totalChallenge, setTotalChallenge] = useState();
  const [totalAssignment, setTotalAssignment] = useState();

  const { setLoading } = useContext(LoadingContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const calculateCompletion = (inputValue, continueData) => {
    console.log(" CALCULATE COMPLETION ALGO", inputValue, continueData);
    setValues(inputValue);
    let daysRemaining = calculateCompletionDate(inputValue, continueData);
    console.log(inputValue, daysRemaining);
    setDaysLeft(daysRemaining);
    setPercentage(continueData.courseEnrolledPercentageleftInDecimal * 100);
  };

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/profile",
      "Page name": "Profile page",
    });
    setLoading(true);
    AuthService.findUserProfile()
      .then((response) => {
        if (response.success) {
          console.log(response.data, 'kislay-data');
          setUserProfile({ ...response, ...response.data });
          setState({ ...state, ...response.data });
        }
      })
      .catch((reject) => alert("somethingwentwrong"))
      .then(() => setLoading(false));

    const loadAssignmentNumber = async () => {
      try {
        const _dataAssignment = await lectureService.getTotalAssignment();
        if (_dataAssignment.status === 200) {
          setTotalAssignment(_dataAssignment.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const loadChallengeNumber = async () => {
      try {
        const _dataChallenge = await lectureService.getTotalChallenge();
        if (_dataChallenge.status === 200) {
          setTotalChallenge(_dataChallenge.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const funct = async () => {
      loadAssignmentNumber();
      loadChallengeNumber();
      try {
        let res = await learningpathService.getLearningPaths();
        //console.log('res======== Learning path deets', res);
        if (res.data)
          setLearningPathList({
            ...res.data,
          });
        res.data.map((path) =>
          learningpathService.autoEnroll(path.learningpathid._id)
        );
        try {
          let res2 = await learningpathService.getContinueLearning();
          setContinueLearning({
            ...res2.data,
          });
          //console.log('res2======== Continue learning deets', res2);
          try {
            let res3 = await tutorService.getUserAccountDetails();

            setMinutesRemaining(res3.data?.totalminutes);
            //console.log('res3======== Tutor deets', res3);
          } catch (error) {
            console.log(error);
          }
          calculateCompletion(values, res2.data);
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
        history.push("/somethingwentwrong");
      }
    };

    funct();
  }, []);

  useEffect(() => {
    const avatarUrl = toonavatar.generate_avatar({ "gender": "male" })
    console.log(avatarUrl, 'tst');
    setAvatar(avatarUrl);
    const courseHandler = async () => {
      let res = null;
      try {
        console.log("courseHandler");
        res = await courseService.getCertificates();
        console.log("res=========Certificates", res);
        setCertificate(res.data?.certificates);
      } catch (error) {
        console.log(error);
        history.push("/somethingwentwrong");
      }
    };

    courseHandler();
  }, []);

  useEffect(() => {
    setProfileLink({
      link: `${window.location.origin}/app/zaio-profile/${userProfile?.email}`,
      copied: false,
    });
  }, [userProfile]);

  const handleSetView = () => {
    if (viewCount === 3) {
      setViewCount(certificates.length);
    } else {
      setViewCount(3);
    }
  };

  const certificateDataHandler = (coursename, certificateurl) => {
    // const [certificateData, setcertificateData] = useState({ certificateurl: '', coursename: '' });
    setcertificateData({
      certificateurl: certificateurl,
      coursename: coursename,
    });
  };

  const handleUpdateURL = useCallback((event) => {
    console.log(event, 'ksevent---')
    setTab(event)
    // history.push(`/competitions/${competitionId}?tab=${event}`);
  }, []);

  const displayButton = () => {
    return <div className="bg-[#8437f9] px-4 text-white">Learning in Progress...</div>;
  };


  return (
    <ProfileCommon
      avatar={avatar}
      userProfile={userProfile}
      handleUpdateURL={handleUpdateURL}
      certificates={certificates}
      setCertificateShow={setCertificateShow}
      certificateDataHandler={certificateDataHandler}
      certificateShow={certificateShow}
      certificateData={certificateData}
      handleSetView={handleSetView}
      viewCount={viewCount}
      learningPathList={learningPathList}
      setTotalCourse={setTotalCourse}
      tab={tab}
      totalAssignment={totalAssignment}
      totalChallenge={totalChallenge}
      totalCourse={totalCourse}
      profile={true}
      editable={true}
      profileData={state}
      setProfileData={setState}
    />

  );
};

export default MyProfile;
