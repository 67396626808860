import lectureService from "actions/services/lecture.service";
// import Accordion from "react-bootstrap/Accordion";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaTimesCircle } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Loader from "components/Loader/Loader";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function Results({ lectureId, encode, decode, testSubmission, syncSubmissions}) {
  const [submissions, setSubmissions] = useState(null);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userid = searchParams.get("userid");
  const challengeSubmissionResponse = useMutation(
    "challengeSubmissions",
    () =>
      userid
        ? lectureService.getChallengeSubmissionsPublic(lectureId, userid)
        : lectureService.getChallengeSubmissions(lectureId),
    {
      onSuccess: (res) => {
        console.log("CHALLENGE SUBMISSION RESPONSE/" + lectureId, res);
        if (res?.data)
          setSubmissions(
            res.data?.filter((sub) =>
              testSubmission ? sub?.testSubmission : !sub?.testSubmission
            )
          );
      },
    }
  );

  useEffect(() => {
    challengeSubmissionResponse.mutate();
    console.log(
      "CHALLENGE SUBMISSION RESPONSE/" + lectureId,
      challengeSubmissionResponse?.data
    );
  }, [lectureId, syncSubmissions]);

  // return (
  //   <div className="h-full w-full z-10">
  //     <p>Loading...</p>
  //   </div>
  // );
  if (challengeSubmissionResponse?.isLoading)
    return (
      <div className="h-full w-full z-10">
        <p>Loading...</p>
      </div>
    );
  if (challengeSubmissionResponse?.isError)
    return <div className="text-dark">{error}</div>;

  return (
    <Accordion allowMultipleExpanded className="m-1">
      {submissions?.length > 0 ? (
        submissions?.map((item, index) => (
          <AccordionItem eventKey={index} key={index}>
            <AccordionItemHeading>
              <AccordionItemButton
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="flex">
                  {item?.passed ? (
                    <BsCheckCircleFill
                      color="green"
                      size={24}
                      style={{ marginRight: "10px" }}
                    />
                  ) : (
                    <FaTimesCircle
                      color="red"
                      size={24}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  Submitted on{" "}
                  {moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="m-2">
              <div className="border-2 bg-slate rounded-sm">
                {item?.testcasesdata?.map((testcase, index) => (
                  // className="bg-grey p-2 m-1 rounded"
                  <div className="p-2">
                    <h5 className="flex">
                      <span>Test {index + 1}</span>
                      <span>
                        {testcase?.passed === "true" ? (
                          <BsCheckCircleFill
                            color="green"
                            size={24}
                            style={{ marginLeft: "10px" }}
                          />
                        ) : (
                          <FaTimesCircle
                            color="red"
                            size={24}
                            style={{ marginLeft: "10px" }}
                          />
                        )}
                      </span>
                    </h5>
                    {/* <p>{testcase?.}</p> */}
                    {testcase?.stdin && (
                      <>
                        <p className="mb-0 m-1">
                          <em>Input</em>
                        </p>
                        <pre className="bg-light rounded m-1 p-0">
                          {decode(testcase?.stdin)}
                        </pre>
                      </>
                    )}
                    <hr />

                    {testcase?.expected_output && (
                      <>
                        <p className="mb-0 m-1">
                          <em>Expected Output</em>
                        </p>
                        <pre className="bg-light rounded m-1 p-0">
                          {decode(testcase?.expected_output)}
                        </pre>
                      </>
                    )}
                    <hr />

                    {testcase?.stdout && (
                      <>
                        <p className="mb-0 m-1">
                          <em>Your Output</em>
                        </p>
                        <pre className="bg-light rounded m-1 p-0">
                          {decode(testcase?.stdout)}
                        </pre>
                      </>
                    )}
                    <hr />
                  </div>
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))
      ) : (
        <div className="text-dark">No submissions yet</div>
      )}
    </Accordion>
  );
}

export default Results;
