import {
  MdPendingActions,
  MdOutlineDoneOutline,
  MdOpenInNew,
  MdOutlineMouse,
} from "react-icons/md";

import "./Members.css";

function Members({
  pending,
  sentInvite,
  receivedInvite,
  fromDashboard,
  handleAcceptInvite,
  member,
}) {
  const handleOpenTeamTab = () =>
    window.open(window.location.origin + "/app/team", "_blank").focus();

  return (
    <div>
      <div
        onClick={fromDashboard ? handleOpenTeamTab : handleAcceptInvite}
        className={`invite-item ${pending ? "" : "accepted-invite"} ${
          receivedInvite ? "received-invite" : ""
        }`}
      >
        {pending ? (
          <MdPendingActions className="invite-icon" />
        ) : (
          <MdOutlineDoneOutline className="invite-icon" />
        )}

        {pending ? (
          <>
            {sentInvite && <span>Waiting for {sentInvite.to} to accept.</span>}
            {receivedInvite && (
              <span>
                Accept invite from {receivedInvite.from}.
              </span>
            )}
          </>
        ) : (
          <span>{member.username}</span>
        )}

        {receivedInvite && !fromDashboard ? (
          <MdOutlineMouse className="invite-icon" />
        ) : (
          <MdOpenInNew onClick={handleOpenTeamTab} className="invite-icon" />
        )}
      </div>
    </div>
  );
}

export default Members;
