import React from "react";

const Banner = () => {
  return (
    <div className="bg-yellow-500 w-full p-2 mb-2 text-center font-medium fixed top-0 z-10 left-0">
      <a
        href="/app/profile"
        target="_blank"
        style={{ textDecoration: "none",color:'white' }}
      >
        {/* Complete profile Bio &amp; Demographics to help with job hunting{" "} */}
        Complete your profile before starting any course to receive certificates
      </a>
    </div>
  );
};

export default Banner;
