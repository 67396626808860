import React from "react";
import { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link, useHistory } from "react-router-dom";
import "./Course.css";

import { Helmet } from "react-helmet";
import {
  AiOutlineDown,
  AiFillEdit,
  AiFillDelete,
  AiOutlinePlus,
} from "react-icons/ai";

import mixpanel from "mixpanel-browser";
import { ProgressBar } from "react-bootstrap";
import { BiWindowAlt } from "react-icons/bi";

import styles from "./coursecontent.module.scss";

mixpanel.init("4e2be35d7debc0ad57db236937d08d8d");

function CourseListAdmin({
  course,
  updateUnitList,
  updateLectureList,
  edit,
  setUpdate,
  unitss,
  setnewlecture,
  courseid,
  indexListChange,
  setLoading,
  borderRadius,
  lectureId,
  setNewUnit,
}) {
  const history = useHistory();
  const queries = new URLSearchParams(history.location.search);

  const [click, setClick] = useState(false);
  const [items, setItems] = useState(false);
  const [units, setUnits] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setUnits(course.courseunit);
  }, [course]);

  const handleClick = (isenrolled, courseid, lectureId, unitId) => {
    setClick(true);
    if (isenrolled) {
      if (course?.ismobilecourse) {
        history.push(
          `/quiz-reel?courseid=${courseid}&lectureid=${lectureId}&unitid=${unitId}`
        );
      } else {
        history.push(
          `/code?courseid=${courseid}&lectureid=${lectureId}&unitid=${unitId}`
        );
      }
    }
  };

  const Lecture = (props) => {
    const { lectures, unitId, unitname } = props;
    return (
      <DragDropContext DragDropContext onDragEnd={updateLectureList}>
        <Droppable droppableId={`${unitId}`}>
          {(provided) => {
            return (
              <>
                <ul
                  {...provided.droppableProps}
                  className="bg-grey"
                  ref={provided.innerRef}
                >
                  {lectures &&
                    lectures.map((lecture, unitIndex) => (
                      <Draggable
                        key={lecture._id}
                        draggableId={lecture._id}
                        index={unitIndex}
                        isDragDisabled={!edit}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            className="radius-5 m-0 p-0"
                            style={{ cursor: "pointer", background: "#123456" }}
                            {...provided.draggableProps}
                          >
                            <div
                              {...provided.dragHandleProps}
                              className={`d-flex justify-content-between ${styles.video_border} bg-grey-eb`}
                            >
                              <div className="d-flex justify-content-between w-100 m-0 p-0">
                                <div className="d-flex">
                                  <div>
                                    <div className="d-flex">
                                      <button
                                        className="btn p-0 m-0"
                                        type="button"
                                        onClick={() => {
                                          setnewlecture({
                                            removelectureid: lecture._id,
                                            unitname: unitname,
                                            show: true,
                                            setUpdate,
                                            setLoading,
                                          });
                                        }}
                                      >
                                        <AiFillDelete color="red" />
                                      </button>

                                      <button
                                        className="btn p-0 m-0"
                                        type="button"
                                        onClick={() => {
                                          setnewlecture({
                                            courseid: courseid,
                                            lectureid: lecture._id,
                                            courseunitid: unitId,
                                            unitname: unitname,
                                            show: true,
                                            setUpdate,
                                            setLoading,
                                          });
                                        }}
                                      >
                                        <AiFillEdit className="mx-1" color="" />
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    className={`${styles.sub_heading}`}
                                    data-bs-toggle="tooltip"
                                    title={lecture.lecturename}
                                    onClick={() => {
                                      handleClick(
                                        course.isenrolled,
                                        courseid,
                                        lecture._id,
                                        lecture._id
                                      );
                                    }}
                                  >
                                    <p>{lecture.lecturename}</p>
                                  </div>
                                </div>
                                <div
                                  className={`${styles.grey_text} d-flex ms-1`}
                                  onClick={() => {
                                    handleClick(
                                      course.isenrolled,
                                      courseid,
                                      lecture._id,
                                      lecture._id
                                    );
                                  }}
                                >
                                  <p>{`${lecture.duration} `}</p>
                                  <p className="ms-1">
                                    {" "}
                                    {lecture.duration === 1 ? " min" : " mins"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary m-1" // btn-circle
                    onClick={() => {
                      setnewlecture({
                        courseid: courseid,
                        courseunitid: unitId,
                        unitname: unitname,
                        show: true,
                        setUpdate,
                        setLoading,
                      });
                    }}
                  >
                    Add a lecture <AiOutlinePlus size={20} />
                  </button>
                </div>
              </>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  useEffect(() => {
    if (click) window.location.reload(true);
  }, [click]);

  return (
    <DragDropContext DragDropContext onDragEnd={updateUnitList}>
      <Droppable droppableId="units">
        {(provided) => {
          return (
            <ul
              {...provided.droppableProps}
              className="bg-grey"
              ref={provided.innerRef}
            >
              {course &&
                units.map((unit, unitIndex) => (
                  <Draggable
                    key={unit._id}
                    draggableId={unit._id}
                    index={unitIndex}
                    isDragDisabled={!edit}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        className="radius-5 m-0 p-0"
                        style={{ cursor: "pointer", background: "#123456" }}
                        {...provided.draggableProps}
                      >
                        <div className=" border radius-5 text-dark radius ">
                          <div
                            className="bg-white  p-2"
                            {...provided.dragHandleProps}
                          >
                            <div className="d-flex justify-content-between">
                              <h2 className="text-start">
                                <b style={{ fontSize: "16px" }}>
                                  {" "}
                                  {unit.unitname}
                                </b>
                              </h2>

                              <div className="d-flex">
                                <button
                                  className="btn p-0 m-0"
                                  type="button"
                                  onClick={() => {
                                    setNewUnit({
                                      courseid: courseid,
                                      unitname: unit.unitname,
                                      removeunitid: unit._id,
                                      coursename: unit.coursename,
                                      setUpdate,
                                      setLoading,
                                    });
                                  }}
                                >
                                  <AiFillDelete color="red" />
                                </button>

                                <button
                                  className="btn p-0 m-0"
                                  type="button"
                                  onClick={() => {
                                    setNewUnit({
                                      courseid: courseid,
                                      unitid: unit._id,
                                      coursename: unit.coursename,
                                      setUpdate,
                                      setLoading,
                                    });
                                  }}
                                >
                                  <AiFillEdit className="ms-1" color="" />
                                </button>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between pt-0">
                              <small
                                className={styles.grey_text}
                                style={{ fontSize: "12px" }}
                              >
                                {
                                  unit.lecture.filter((lecture) =>
                                    course.completedLectures.includes(
                                      lecture._id
                                    )
                                  ).length
                                }
                                /{unit.lecture.length} | {unit.unitduration}{" "}
                                mins
                              </small>
                              <button
                                className="btn p-0 m-0 btn-circle btn-sm"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#flush-collapseOne" + unit._id}
                                aria-expanded={"true"}
                                aria-controls={"flush-collapseOne" + unit._id}
                              >
                                <AiOutlineDown size={15} />
                              </button>
                            </div>
                          </div>
                          <div style={{backgroundColor:'#eee'}}>
                            <div
                              id={"flush-collapseOne" + unit._id}
                              className={`accordion `}
                              aria-labelledby={"flush-headingOne" + unit._id}
                            >
                              <div className="accordion-item collapsed m-0 p-0">
                                <div className="accordion-body p-0">
                                  <Lecture
                                    unitId={unit._id}
                                    unitname={unit.unitname}
                                    lectures={unit.lecture}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
            </ul>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}

export default CourseListAdmin;
