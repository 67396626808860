import { useContext, useEffect, useState } from "react";
import Split from "react-split";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import useVideoStore from "zustand-store/videoPlayerStore";
import MarkdownToHTML from "components/HTMLToReact/MarkdownToHTML";
import Results from "./Results";
import DownloadBtn from "components/Buttons/DownloadBtn";
import ZipFileInput from "components/Inputs/ZipFileInput";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { Puff } from "react-loader-spinner";
import lectureService from "actions/services/lecture.service";
import useAssignmentStore from "zustand-store/assignmentStore";
import SubmitCodeForm from "components/Compiler/SubmitCodeForm";
import ReelModal from "components/QuizReelComponents/ReelModal/ReelModal";
import { SfxContext } from "context/SfxContext";
import CompleteChallenge from "components/Compiler/CompleteChallenge";
import IdeFrontendUser from "components/IDE/IdeFrontendUser";
import { useLocation } from "react-router-dom";
import { init } from "react-facebook-pixel";
import useEditorStore from "zustand-store/editorStore";
import { UserContext } from "context/UserProvider";
import BackButton from "Utils/BackButton";
import HomeButton from "Utils/HomeButton";

const QuesDescriptionWindow = ({
  questionTab,
  assignment,
  challenge,
  setQuestionTab,
  submissions,
  challengeSubmissionResponse,
  fetchPrevSubmission,
}) => {
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isTutor = Boolean(searchParams.get("tutor"));

  return (
    <>
      <div className="flex flex-col items-start">
        <div className="flex">
          {user && <BackButton color="black" size="" />}
          {user && <HomeButton color="black" size="" />}
        </div>
        <p className="text-md font-bold uppercase px-2">
          {challenge?.challengename}
        </p>
        <p className="text-md font-bold px-2">
          Hello, {user?.username || user?.email}
        </p>
      </div>
      <Tab.Container
        defaultActiveKey={questionTab}
        activeKey={questionTab}
        className="h-100"
      >
        <Nav variant="pills">
          <Nav.Item onClick={() => setQuestionTab("description")}>
            <Nav.Link eventKey={"description"}>Description</Nav.Link>
          </Nav.Item>
          {!assignment && isTutor && (
            <Nav.Item onClick={() => setQuestionTab("testSubmissions")}>
              <Nav.Link eventKey={"testSubmissions"}>Test Submissions</Nav.Link>
            </Nav.Item>
          )}
          {!assignment && (
            <Nav.Item onClick={() => setQuestionTab("submissions")}>
              <Nav.Link eventKey={"submissions"}>Submissions</Nav.Link>
            </Nav.Item>
          )}
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey={"description"}>
            <div className="m-2">
              <MarkdownToHTML html={challenge?.questionfile} />
            </div>
          </Tab.Pane>
          {!assignment && (
            <Tab.Pane eventKey={"testSubmissions"}>
              <Results
                submissions={submissions?.filter((sub) => sub?.testSubmission)}
                challengeSubmissionResponse={challengeSubmissionResponse}
                fetchPrevSubmission={fetchPrevSubmission}
              />
            </Tab.Pane>
          )}
          {!assignment && (
            <Tab.Pane eventKey={"submissions"}>
              <Results
                submissions={submissions?.filter((sub) => !sub?.testSubmission)}
                challengeSubmissionResponse={challengeSubmissionResponse}
                fetchPrevSubmission={fetchPrevSubmission}
              />
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

function Frontend({
  // onCtrlS,
  // preLoadedProjectFiles,
  // onProjectFilesUpdate = undefined,
  lectureId,
  onNextClick = undefined,
  dataNext = null,
  assignment = false,
  refreshCourseData,
  updateFrontendChallCompletion,
}) {
  const { winSfx, hoverSfx } = useContext(SfxContext);
  const queryClient = useQueryClient();

  const [horizontalSizes, setHorizontalSizes] = useState([20, 80]);
  const [consoleTab, setConsoleTab] = useState("input");
  const [preLoadedProjectFiles, setPreLoadedProjectFiles] = useState(null);
  const [questionTab, setQuestionTab] = useState("description");
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [result, setResult] = useState(null);
  const [modal, setModal] = useState({
    show: false,
    title: "Challenge Completed! 🎉",
    description:
      "Congratulations! You have completed this challenge. You can now move on to the next lesson.",
    CustomComponent: null,
    props: null,
  });
  const [showAssignmentSubmission, setShowAssignmentSubmission] =
    useState(false);
  const [submissions, setSubmissions] = useState(null);
  const [syncSubmissions, setSyncSubmissions] = useState(null);
  const challenge = useVideoStore((state) => state.challenge);
  const assignmentData = useAssignmentStore((state) => state.assignmentData);
  const currentQuestionId = useAssignmentStore(
    (state) => state.currentQuestionId
  );

  const feedback = useEditorStore((state) => state.feedback);
  const setFeedback = useEditorStore((state) => state.setFeedback);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userid = searchParams.get("userid");

  const challengeSubmissionResponse = useMutation(
    "challengeSubmissions",
    () => lectureService.getFrontendChallengeSubmissions(lectureId, userid),
    {
      onSuccess: (res) => {
        console.log("CHALLENGE SUBMISSION RESPONSE/" + lectureId, res);
        if (res?.data) setSubmissions(res.data);
      },
    }
  );

  const handleFileSelect = (file) => {
    setUploadFile(file);
  };

  const handleFileDelete = () => {
    setUploadFile(null);
    setUploadPercent(0);
  };

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const handleSubmitAssignmentFrontend = async () => {
    setModal({
      ...modal,
      show: true,
      title: "You are about to submit your code! ",
      description:
        "This cannot be reveresed, and your results will be returned as soon as its marked by a tutor/instructor.",
      CustomComponent: SubmitCodeForm,
      props: {
        assignmentSubmitCodeMutation: assignmentSubmitFrontendMutation,
        setShowAssignmentSubmission,
        showAssignmentSubmission,
      },
    });
  };

  const handleSubmitSolution = () => {
    setResult(null);
    if (assignment) {
      handleSubmitAssignmentFrontend();
    } else {
      setQuestionTab("submissions");
      challengeSubmitFrontendMutation.mutate();
    }
  };

  const handleTestSolution = () => {
    setResult(null);
    if (assignment) {
      assignmentTestFrontendMutation.mutate();
    } else {
      challengeTestFrontendMutation.mutate();
    }
  };

  const onProjectFilesUpdate = (files) => {
    setPreLoadedProjectFiles(files);
  };

  const saveFrontendCodeFeedbackMutation = useMutation(
    () =>
      lectureService.saveFrontendCodeChatGPTFeedback(
        lectureId,
        preLoadedProjectFiles
      ),
    {
      onSuccess: (response) => {
        console.log("saveCodeFeedbackMutation...", response);
        if (response?.data?.zaioaireview) {
          // popSfx();
          hoverSfx();
          setFeedback({
            ...feedback,
            isLoading: false,
            pending: true,
            show: feedback?.show,
            message: response?.data?.zaioaireview,
          });
        } else {
          setFeedback({
            ...feedback,
            isLoading: false,
            pending: true,
            show: feedback?.show,
            message: response?.message,
          });
        }

        // if (response?.data?.challengeData?.files)
        //   setFiles(
        //     response?.data?.challengeData?.files.map((file, index) => ({
        //       filename: file?.filename,
        //       sourcecode: decode(file?.sourcecode),
        //     }))
        //   );

        // setModal({
        //   ...modal,
        //   show: true,
        //   title: "Your progress has been saved! ✅",
        //   description: "Keep up trying and stay consistent.",
        //   CustomComponent: null,
        //   props: {},
        // });

        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );

  const saveAssignmentFrontendCodeFeedbackMutation = useMutation(
    () =>
      lectureService.saveAssignmentFrontendCodeChatGPTFeedback(
        currentQuestionId,
        preLoadedProjectFiles
      ),
    {
      onSuccess: (response) => {
        console.log("saveCodeFeedbackMutation...", response);
        if (response?.data?.zaioaireview) {
          // popSfx();
          hoverSfx();
          setFeedback({
            ...feedback,
            isLoading: false,
            pending: true,
            show: feedback?.show,
            message: response?.data?.zaioaireview,
          });
        } else {
          setFeedback({
            ...feedback,
            isLoading: false,
            pending: true,
            show: feedback?.show,
            message: response?.message,
          });
        }

        // if (response?.data?.challengeData?.files)
        //   setFiles(
        //     response?.data?.challengeData?.files.map((file, index) => ({
        //       filename: file?.filename,
        //       sourcecode: decode(file?.sourcecode),
        //     }))
        //   );

        // setModal({
        //   ...modal,
        //   show: true,
        //   title: "Your progress has been saved! ✅",
        //   description: "Keep up trying and stay consistent.",
        //   CustomComponent: null,
        //   props: {},
        // });

        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );

  const challengeTestFrontendMutation = useMutation(
    () =>
      lectureService.testChallengeFrontendQuestion(
        lectureId,
        challenge._id,
        preLoadedProjectFiles
      ),
    {
      onSuccess: (data) => {
        if (data !== "error") {
          console.log(data);
          setUploadFile(null);
          setResult(data);
          setSyncSubmissions(Math.random());
        }
      },
      onError: (error) => {
        console.log(error);
        setResult(error);
      },
    }
  );

  const challengeSubmitFrontendMutation = useMutation(
    () =>
      lectureService.submitChallengeFrontendQuestion(
        lectureId,
        challenge._id,
        preLoadedProjectFiles
      ),
    {
      onSuccess: (data) => {
        if (data !== "error") {
          console.log(data);
          setUploadFile(null);
          setResult(data);

          if (parseInt(data?.data?.percentage) === 100) {
            updateFrontendChallCompletion();
            refreshCourseData();
            winSfx();
            setModal({
              ...modal,
              show: true,
              title: "Challenge Completed! 🎉",
              description:
                "Congratulations! You have completed this challenge. You can now move on to the next lesson.",
              CustomComponent: CompleteChallenge,
              props: {
                onNextClick,
                dataNext,
              },
            });
          } else {
            setResult(data);
          }
          setSyncSubmissions(Math.random());

          queryClient.invalidateQueries("challengeSubmissions");
          return queryClient.invalidateQueries("challengeSubmissions");
        }
      },
      onError: (error) => {
        console.log(error);
        setResult(error);
      },
    }
  );

  const assignmentTestFrontendMutation = useMutation(
    () =>
      lectureService.testAssignmentFrontendQuestion(
        assignmentData?.assignmentid?._id,
        currentQuestionId,
        preLoadedProjectFiles
      ),
    {
      onSuccess: (data) => {
        console.log(data);
        setUploadFile(null);
        setResult(data);
      },
      onError: (error) => {
        console.log(error);
        setResult(error);
      },
    }
  );

  const assignmentSubmitFrontendMutation = useMutation(
    () =>
      lectureService.submitAssignmentFrontendQuestion(
        assignmentData?.assignmentid?._id,
        currentQuestionId,
        preLoadedProjectFiles
      ),
    {
      onSuccess: (data) => {
        console.log(data);
        setUploadFile(null);
        setResult(data);
        handleOnHide();
        setShowAssignmentSubmission(false);
        refreshCourseData();
        fetchPrevSubmission();
      },
      onError: (error) => {
        console.log(error);
        setResult(error);
      },
    }
  );

  useEffect(() => {
    challengeSubmissionResponse.mutate();
  }, [syncSubmissions]);

  const fetchPrevSubmission = async (index = 0) => {
    if (submissions?.length > 0) {
      const prevFiles = {
        "index.html": {
          langauge: "html",
          name: "index.html",
          value: submissions?.[index]?.frontendsubmittedcode?.html,
        },
        "style.css": {
          langauge: "css",
          name: "style.css",
          value: submissions?.[index]?.frontendsubmittedcode?.css,
        },
        "script.js": {
          langauge: "js",
          name: "script.js",
          value: submissions?.[index]?.frontendsubmittedcode?.js,
        },
      };
      setPreLoadedProjectFiles(prevFiles);
    } else if (assignment && challenge?.frontendfilescode) {
      const prevFiles = {
        "index.html": {
          ...challenge?.frontendfilescode?.project_files_frontend?.["html"],
        },
        "style.css": {
          ...challenge?.frontendfilescode?.project_files_frontend?.["css"],
        },
        "script.js": {
          ...challenge?.frontendfilescode?.project_files_frontend?.["js"],
        },
      };
      setPreLoadedProjectFiles(prevFiles);
    } else if (challenge?.frontendplaygroundid) {
      const prevFiles = {
        "index.html": {
          ...challenge?.frontendplaygroundid?.project_files_frontend?.["html"],
        },
        "style.css": {
          ...challenge?.frontendplaygroundid?.project_files_frontend?.["css"],
        },
        "script.js": {
          ...challenge?.frontendplaygroundid?.project_files_frontend?.["js"],
        },
      };
      setPreLoadedProjectFiles(prevFiles);
    }
  };

  useEffect(() => {
    if (submissions === null) return;
    fetchPrevSubmission();
    return () => {
      setUploadFile(null);
      setUploadPercent(0);
      setResult(null);
    };
  }, [submissions, challenge]);

  return (
    <div
      className="w-screen h-screen"
      style={{ overflow: "hidden", height: "100vh", width: "100vw" }}
    >
      {modal.show && (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          props={modal.props}
          CustomComponent={modal.CustomComponent ?? false}
          // videoUrl={"https://youtu.be/Uv9URjIBtUQ"}
        />
      )}

      {preLoadedProjectFiles && (
        <Split
          className="split"
          sizes={horizontalSizes}
          minSize={100}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          onDragEnd={(s) => {
            console.log("onDragEnd", s);
            setHorizontalSizes(s);
          }}
        >
          <div className="h-screen bg-white p-2 overflow-y-scroll ">
            <QuesDescriptionWindow
              questionTab={questionTab}
              assignment={assignment}
              challenge={challenge}
              setQuestionTab={setQuestionTab}
              submissions={submissions}
              challengeSubmissionResponse={challengeSubmissionResponse}
              fetchPrevSubmission={fetchPrevSubmission}
            />
          </div>
          <IdeFrontendUser
            setSyncSubmissions={setSyncSubmissions}
            // onCtrlS={onCtrls}
            lectureId={lectureId}
            preLoadedProjectFiles={preLoadedProjectFiles}
            handleSubmitSolution={handleSubmitSolution}
            handleTestSolution={handleTestSolution}
            onProjectFilesUpdate={onProjectFilesUpdate}
            challengeTestFrontendMutation={challengeTestFrontendMutation}
            challengeSubmitFrontendMutation={challengeSubmitFrontendMutation}
            assignment={assignment}
            assignmentTestFrontendMutation={assignmentTestFrontendMutation}
            assignmentSubmitFrontendMutation={assignmentSubmitFrontendMutation}
            feedback={feedback}
            setFeedback={setFeedback}
            saveFrontendCodeFeedbackMutation={saveFrontendCodeFeedbackMutation}
            saveAssignmentFrontendCodeFeedbackMutation={
              saveAssignmentFrontendCodeFeedbackMutation
            }
          />
        </Split>
      )}
    </div>
  );
}

export default Frontend;
