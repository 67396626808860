import OnboardingHeader from "./OnboardingHeader";
import OnboardingQuestion from "./OnboardingQuestion";
import OnboardingFooter from "./OnboardingFooter";
import "./Onboarding.scss";
import ChatBubble from "pages/DashBoard/ChatBubble";
import textBasedOnboardingData from "./textBasedOnboardingData";
import { useContext, useEffect, useState } from "react";
import authService from "actions/services/auth.service";
import { UserContext } from "context/UserProvider";
import { SfxContext } from "context/SfxContext";
import BarLoader from "react-spinners/BarLoader";
import { Mixpanel } from "Utils/Mixpanel";

function Onboarding() {
  console.log("textBasedOnboardingData", textBasedOnboardingData);
  const [currentQuestion, setCurrentQuestion] = useState(
    textBasedOnboardingData.goal
  );
  const [currentStepOnboarding, setCurrentStepOnboarding] = useState("goal");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSetup, setShowSetup] = useState(false);
  const [redirectToCourse, setRedirectToCourse] = useState(null);
  const [setupTitle, setSetupTitle] = useState(
    "🎊 Setting things up for you..."
  );
  const [setupText, setSetupText] = useState(
    "Did you know you are in the top 5% of the African population taking an attempt to learn coding?"
  );

  const {
    user,
    setUser,
    userNotSignedUp,
    setUserNotSignedUp,
    setUserProgress,
  } = useContext(UserContext);
  const { clickSfx, hoverSfx } = useContext(SfxContext);

  const setOnboardingData = (text, hardCodedStep = "none") => {
    let objToReturn = {
      [hardCodedStep === "none" ? currentStepOnboarding : hardCodedStep]: text,
    };
    const notSignedUpObject = {
      ...userNotSignedUp,
      ...objToReturn,
    };
    console.log("notSignedUpObject ", notSignedUpObject, objToReturn);
    authService.settingDataWithoutSigningUp(objToReturn);
    setUserNotSignedUp(authService.getUserNotSignedUpData(null));
  };

  const handleOptionSelect = async (quizOption, index) => {
    if (!currentQuestion?.disabledOptionClick) {
      clickSfx();
      setSelectedIndex(index);
    }

    console.log("SELECTED QUIZ OPTION", quizOption, index);

    //setting up options data to show
    if (quizOption?.nextstep) {
      Mixpanel.track("Text Onboarding Step", {
        selectedOption: quizOption.text,
      });
      console.log(
        "nextoptions to show:",
        textBasedOnboardingData[quizOption.nextstep]
      );
      setSelectedOption(quizOption);

      if (quizOption?.redirectToCourse) {
        setRedirectToCourse(quizOption.redirectToCourse);
      }
    }
    if (currentQuestion?.lastStep) {
      Mixpanel.track("Text Onboarding Time commitment", {
        selectedOption: quizOption.text,
      });
      setSelectedOption(quizOption);
    }
  };

  const handleGoingBack = () => {
    if (currentQuestion.previousStep) {
      setCurrentQuestion(
        textBasedOnboardingData[currentQuestion?.previousStep]
      );
    }
  };

  const handleCompleteLastStep = async () => {
    if (currentQuestion?.lastStep) {
      Mixpanel.track("Text Onboarding Completed", {
        selectedOption: selectedOption?.text,
      });
      console.log("onboarding completed");
      setCurrentStepOnboarding("reelOnboardingComplete");
      setOnboardingData(selectedOption?.text);
      setOnboardingData("reelOnboardingComplete", "reelOnboardingComplete");
      setOnboardingData(redirectToCourse?.courseid, "courseid");
      setOnboardingData(redirectToCourse?.lectureid, "lastwatchedid");
      await authService.postOnboardingQuestions();
      // setShowSetup(true);
      setSelectedOption(null);
      return;
    }
  };

  const handleContinue = async () => {
    setSelectedIndex(null);
    console.log("selectedOption", selectedOption);
    clickSfx();
    if (currentQuestion?.lastStep) {
      Mixpanel.track("Text Onboarding Completed", {
        selectedOption: selectedOption?.text,
      });
      console.log("onboarding completed");
      setCurrentStepOnboarding("reelOnboardingComplete");
      setOnboardingData(selectedOption?.text);
      setOnboardingData("reelOnboardingComplete", "reelOnboardingComplete");
      setOnboardingData(redirectToCourse?.courseid, "courseid");
      setOnboardingData(redirectToCourse?.lectureid, "lastwatchedid");
      await authService.postOnboardingQuestions();
      // setShowSetup(true);
      setSelectedOption(null);
      return;
    }
    if (currentQuestion?.viewOnlyNextStep) {
      setCurrentStepOnboarding(currentQuestion?.viewOnlyNextStep);
      setCurrentQuestion(
        textBasedOnboardingData[currentQuestion?.viewOnlyNextStep]
      );
    }
    if (selectedOption?.nextstep) {
      setOnboardingData(selectedOption?.text);
      setCurrentStepOnboarding(selectedOption?.nextstep);
      setCurrentQuestion(textBasedOnboardingData[selectedOption.nextstep]);
    }
    setSelectedOption(null);
  };

  useEffect(() => {
    if (showSetup) {
      setTimeout(() => {
        setSetupTitle("🎉 Almost there...");
        setSetupText(
          "Redirecting you to your very own Netflix/Tik-Tok Coding experience!"
        );
        setTimeout(() => {
          // alert(redirectToCourse?.url);
          window.location.href = redirectToCourse?.url;
        }, 2000);
      }, 5000);
    }
  }, [showSetup]);
  return (
    <div className="onb-wrapper">
      <div className="onb-container">
        {showSetup ? (
          <div className="onb-loader">
            <BarLoader color="#8437f9" cssOverride={{ width: "100%" }} />
            <ChatBubble title={setupTitle} text={setupText} showLive={false} />
          </div>
        ) : (
          <>
            <OnboardingHeader
              handleGoingBack={
                currentQuestion.previousStep ? handleGoingBack : null
              }
              percentageCompleted={currentQuestion?.percentageCompleted}
            />
            <div className="onb-body">
              <ChatBubble
                title={currentQuestion.title}
                text={currentQuestion.text}
                showLive={false}
              />
              {currentQuestion?.component ? (
                <currentQuestion.component
                  setShowSetup={setShowSetup}
                  showSetup={showSetup}
                  handleCompleteLastStep={handleCompleteLastStep}
                />
              ) : (
                <OnboardingQuestion
                  options={currentQuestion.options}
                  handleOptionSelect={handleOptionSelect}
                  selectedIndex={selectedIndex}
                />
              )}
            </div>
            {!currentQuestion?.component && (
              <OnboardingFooter handleContinue={handleContinue} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Onboarding;
