import walletService from "actions/services/wallet.service";
import "../../Course/Course.css";
import UnlockWithZCoin from "components/UnlockWithZCoin/UnlockWithZCoin";
import { useHistory } from "react-router-dom";

function JoinCompetitionModalBody({ price, teamid, competitionid }) {
  const history = useHistory();
  const redirectFn = (data) => {
    if (data?.success) {
      history.push(`/competitions/${competitionid}?tab=overview`);
      window.location.reload();
    }
  };

  if (!teamid) {
    return <button className="btn btn-main m-3" onClick={() => history.push("/team")}>Create Team</button>;
  }
  return (
    <UnlockWithZCoin
      mutationFn={walletService.joinCompetition}
      mutationParams={[teamid, competitionid]}
      price={price}
      btnText="Join with"
      redirectFn={redirectFn}
    />
  );
}

export default JoinCompetitionModalBody;
