import { memo } from "react";
import { FaStepForward } from "react-icons/fa";
import { ItemNext } from "../VideoPlayerControls.styled";

const NextLesson = ({
  onNextClick,
  showDataNext,
  dataNext,
  setShowDataNext,
}) => {
  // console.log("Nextlesson RE-RENDERED", dataNext);
  return (
    onNextClick && (
      <div
        className="item-control"
        onMouseLeave={() => setShowDataNext(false)}
        onClick={() => onNextClick(dataNext)}
      >
        {showDataNext === true && dataNext?._id && (
          <ItemNext>
            <div>
              <div className="title">{dataNext?.heading}</div>
              <div className="item" onClick={() => onNextClick(dataNext)}>
                <div>
                  <span>
                    {dataNext?.lecturename}
                    <br />
                    <small style={{ opacity: "0.5" }}>
                      {dataNext?.duration}
                    </small>
                  </span>
                </div>
              </div>
            </div>
            <div className="box-connector" />
          </ItemNext>
        )}

        <FaStepForward
          onClick={onNextClick}
          onMouseEnter={() => setShowDataNext(true)}
        />
      </div>
    )
  );
};

export default memo(NextLesson);
