import { create } from "zustand";

const useVideoStore = create((set) => ({
  videoMode: "lecture", // "lecture" or "challenge" or "coursemcq" or "datascience"
  challenge: null,
  dsChallenge: null,
  quizmcq: null,
  lectureIndexParam: null,
  courseDetails: null,
  showControlsContainer: true,
  setShowControlsContainer: (mode) =>
    set((state) => {
      if (state.videoMode !== "lecture") {
        return { showControlsContainer: mode };
      }
      return { showControlsContainer: state.showControlsContainer };
    }),
  setVideoMode: (videoMode) =>
    set((state) => {
      return { videoMode };
    }),
  setChallenge: (challenge) =>
    set((state) => {
      console.log("CHALLENGE BEING ZUSTANDEd", challenge);
      return { challenge };
    }),
  setQuizMCQ: (quizmcq) =>
    set((state) => {
      return { quizmcq };
    }),
  setDSChallenge: (dsChallenge) =>
    set((state) => {
      console.log("setting dsChallenge details", dsChallenge);
      return { dsChallenge };
    }),
  //   setCourseDetails: ({ title }) => set(() => ({ courseDetails: { title } })),
  //   setLectureIndexParam: (lectureIndexParam) =>
  //     set(() => ({ lectureIndexParam })),
}));

export default useVideoStore;
