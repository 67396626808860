import { createContext, useState } from "react";
import useSound from "../hooks/useSound";

export const SfxContext = createContext({});

export function SfxContextProvider({ children }) {
  const options = {
    volume: 0.35,
    timeout: 200,
  };
  const hoverPath =
    "https://zaiocontent.s3.eu-west-2.amazonaws.com/sound-effects/tick.mp3";
  const clickedPath =
    "https://zaiocontent.s3.eu-west-2.amazonaws.com/sound-effects/click.wav";
  const winnerPath =
    "https://zaiocontent.s3.eu-west-2.amazonaws.com/sound-effects/winner.wav";
  const completedPath =
    "https://zaiocontent.s3.eu-west-2.amazonaws.com/sound-effects/completed.wav";
  const errorPath =
    "https://zaiocontent.s3.eu-west-2.amazonaws.com/failure-drum-sound-effect-2-7184.mp3";
  const popPath =
    "https://zaiocontent.s3.eu-west-2.amazonaws.com/pop2-84862.mp3";

  const hoverSfx = useSound(hoverPath, options);
  const clickSfx = useSound(clickedPath, options);
  const winSfx = useSound(winnerPath, { ...options, timeout: 1000 });
  const completedSfx = useSound(completedPath, { ...options, timeout: 3000 });
  const errorSfx = useSound(errorPath, { ...options, timeout: 1000 });
  const popSfx = useSound(popPath, { ...options });

  //unrelated to sfx, hacky but speed is crucial
  const [showSetup, setShowSetup] = useState(false);

  return (
    <SfxContext.Provider
      value={{
        hoverSfx,
        clickSfx,
        winSfx,
        completedSfx,
        errorSfx,
        popSfx,
        setShowSetup,
        showSetup,
      }}
    >
      {children}
    </SfxContext.Provider>
  );
}
