import { useContext, useState } from "react";
import SoloStreakIcon from "assets/svg/solo-streak.svg";

import { useHistory } from "react-router-dom";
import { useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { useQuery, useQueryClient } from "react-query";
import authService from "actions/services/auth.service";
import tblService from "actions/services/tbl.service";
import ZPLoading from "pages/Watch/components/ZPLoading";
import TeamStreak from "components/TeamStreak/TeamStreak";
import "../../pages/Wallet/WalletBalance.css";
import { Constants } from "Constants";
import { UserContext } from "context/UserProvider";
import SoloStreak from "components/SoloStreak/SoloStreak";
import { setSoloStreakAttribute } from "Utils/WebEngage";

function SoloStreakScore({
  toolTipText = Constants.SoloStreaksExplanation,
  toolTipPlacement = "right",
  displayToolTip = true,
  getTeamStreaks = undefined,
  simple = false,
  handleClick = undefined,
}) {
  const { user } = useContext(UserContext);
  const history = useHistory();

  const target = useRef(null);
  const [showToolTip, setShowToolTip] = useState(false);

  //queries
  const {
    isLoading,
    isError,
    error,
    data: soloStreaksData,
  } = useQuery("soloStreaksData", () => authService.getStreakData(), {
    enabled: !!(user && user?.success),
  });

  const renderTooltip = (props) => <Tooltip {...props}>{toolTipText}</Tooltip>;
  const displayBalance = () => {
    if (soloStreaksData?.success === false) {
      return (
        <>
          <br />
          <small>
            <em className="text-center">{soloStreaksData?.message}</em>
          </small>
        </>
      );
    } else {
      setSoloStreakAttribute(soloStreaksData?.data?.streakNumber)
      return (
        <h2 className="text-center">{soloStreaksData?.data?.streakNumber}</h2>
      );
    }
  };

  if (isLoading) return <ZPLoading />;
  if (isError) return <div className="text-white">{error}</div>;
  if (simple) {
    return (
      <SoloStreak
        number={soloStreaksData?.data?.streakNumber}
        toolTipPlacement={toolTipPlacement}
        handleClick={handleClick}
      />
    );
  }
  return (
    <>
      <div
        ref={target}
        className="wallet-ballance-container"
        // onClick={() => history.push("/wallet")}
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
      >
        <img src={SoloStreakIcon} alt="LogoFooter" className="zcoinicon" />
        <div>
          <small>My Streaks</small>
          {displayBalance()}
        </div>
      </div>
      <Overlay
        target={target.current}
        show={displayToolTip && showToolTip}
        placement={toolTipPlacement}
      >
        {renderTooltip}
      </Overlay>
    </>
  );
}

export default SoloStreakScore;
