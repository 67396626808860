import React from "react";
import {Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import LogoFooter from "assets/svg/logofooter.svg";
import Facebook from "assets/svg/fb.svg";
import Insta from "assets/svg/insta.svg";
import Twitter from "assets/svg/twitter.svg";
import Linkedin from "assets/svg/linkedin.svg";
import styles from "./footer.module.scss";
import Text from "../../../Utils/Text";



export default function Footer() {
  return (
    <Container fluid className={styles.container}>
      <Row>
        <Col className="p-0">
          <div className={`pad ${styles.footer} ${styles.right_icon}`}>
            <div className={styles.de_flex}>
              <div>
                <div>
                  <Link to="/">
                    <a>
                      <img src={LogoFooter} alt="LogoFooter" />
                    </a>
                  </Link>
                </div>

                <div className={styles.heading_width}>
                  <Text variant="textGrey">
                    Revolutionizing coding education.
                  </Text>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Text variant="textGreyDark">
                    {/* Questions? Call +27 71 101 0004 */}
                    Questions? Call +27 67 129 5424
                  </Text>
                </div>
              </div>

              <div className={`${styles.link_width}`}>
                <div className={styles.footer_marging}>
                  <Text variant="textWhite">Learn</Text>
                  <div>
                    <a style={{ textDecoration: "none" }} href="https://www.zaio.io/fullstack" target="_blank">
                      <Text variant="textGreyDark">
                        Fullstack Web development
                      </Text>
                    </a>
                    <a style={{ textDecoration: "none" }} href="https://www.zaio.io/frontend" target="_blank">
                      <Text variant="textGreyDark">Frontend Engineering</Text>
                    </a>

                    <a style={{ textDecoration: "none" }} href="https://www.zaio.io/backend" target="_blank">
                      <Text variant="textGreyDark">Backend Engineering</Text>
                    </a>

                    <a style={{ textDecoration: "none" }} href="https://www.zaio.io/introduction-to-coding" target="_blank">
                      <Text variant="textGreyDark">
                        [FREE] Introduction to Coding
                      </Text>
                    </a>
                  </div>
                </div>
                <div className={styles.footer_marging}>
                  <Text variant="textWhite">Plans</Text>
                  <div>
                    <a style={{ textDecoration: "none" }} href="https://www.zaio.io/learn" target="_blank">
                      <Text variant="textGreyDark">For individuals</Text>
                    </a>
                  </div>
                </div>
                <div className={styles.footer_marging}>
                  <Text variant="textWhite">About</Text>
                  <div>
                      <a style={{ textDecoration: "none" }} href="mailto:hello@zaio.io" target="_blank">
                        <Text variant="textGreyDark">Contact us</Text>
                      </a>
                      
                    <a style={{ textDecoration: "none" }} href="https://www.zaio.io/aboutus" target="_blank">
                      <Text variant="textGreyDark">About us</Text>
                    </a>
                    
                    <a style={{ textDecoration: "none" }} href="https://www.zaio.io/consultation" target="_blank">
                      <Text variant="textGreyDark">Book a consultation</Text>
                    </a>

                    <a style={{ textDecoration: "none" }} href="https://www.zaio.io/app/live-demo" target="_blank">
                      <Text variant="textGreyDark">Product demo</Text>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className={`align-items-center mt-5 ${styles.de_flex} `}>
              <div>
                <Text variant="textGreyDark">
                  Made with 🤍 in South Africa © 2022 Zaio Technology
                </Text>
              </div>
              <div className={styles.footer_marging}>
                {
                  //<span className={styles.icon_margin}>
                  //<Social />
                  //</span>
                }
                <span className={styles.icon_margin}>
                  <a
                    href="https://www.facebook.com/zaionite"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Facebook} alt="Facebook" />
                  </a>
                </span>
                <span className={styles.icon_margin}>
                  <a
                    href="https://www.linkedin.com/company/zaio-io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </span>
                <span className={styles.icon_margin}>
                  <a
                    href="https://www.instagram.com/zaio.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Insta} alt="Insta" />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.twitter.com/zaionite"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
