export const BlockAccount = () => {
  return (
    <div className="conatiner">
      <div className="mt-5 w-100 d-flex align-items-center justify-content-center">
        <p className="text-white">
          Your Zaio account is in arrears, please email{" "}
          <span className="text-danger">accounts@zaio.io</span> clear your
          balance and gain access
        </p>
      </div>
    </div>
  );
};
