import { LoadingContext } from "context/LoadingProvider";
import { UserContext } from "context/UserProvider";
import { useContext, useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { Mixpanel } from "Utils/Mixpanel";
import googleLoginIcon from "assets/img/dashboard/googleLoginIcon.svg";
import AuthService from "actions/services/auth.service";
import OnboardingFooter from "./OnboardingFooter";
import { SfxContext } from "context/SfxContext";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import ReactGA from "react-ga4";
import {
  phoneEventTracking,
  setSytemUserAttributes,
  signupEventTracking,
} from "Utils/WebEngage";

function SignupNew({ setShowSetup, showSetup, handleCompleteLastStep }) {
  console.log("SIGNUP", setShowSetup, showSetup);
  const { setLoading } = useContext(LoadingContext);
  const { user, setUser } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [onbDetails, setOnbordingDetails] = useState(null);
  const [phonenumber, setPhoneNumber] = useState("");
  const [state, setState] = useState({
    success: !!user,
    message: "",
  });
  const [phonenumberStep, setPhoneNumberStep] = useState(false);

  const { clickSfx, hoverSfx } = useContext(SfxContext);
  const history = useHistory();

  const fetchInitDetails = async () => {
    // console.log("sdadadada", user?.data);

    if (user?.data?.username && user?.data?.email) {
      if (
        user?.data?.onboardingProgress?.userDetailsStep === false &&
        !user?.data?.phonenumber
      ) {
        setPhoneNumberStep(true);
      } else {
        history.replace("/onboarding/payments");
      }
    }
  };

  useEffect(() => {
    ReactGA.initialize("G-BMVJEM2BJK");
    if (!user) return;
    fetchInitDetails();
  }, [user]);

  function ValidateEmail(inputText) {
    var mailformat = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  const responseSuccessGoogle = (response) => {
    setLoading(true);
    AuthService.googleLogin(response.tokenId)
      .then((response) => {
        //mixpanel
        if (response.success) {
          console.log("GOOGLE LOGIN", response);
          // alert("google login", response?.data.toString());
          Mixpanel.alias(response?.data?.email);
          Mixpanel.people.set({
            $email: response?.data?.email,
            $distinct_id: response?.data?.email,
            $name: username,
            $status: "free",
            $expirement: "retention",
          });
          Mixpanel.track("Account created", {
            source: "gmail",
          });
          // Mixpanel.track("Text onboarding Successful sign up", {
          //   method: "google",
          // });
          setState({
            ...state,
            success: true,
            message: response.message,
          });
          setUser({ ...response, ...response?.data, enrolled: false });
          signupEventTracking({
            email: response?.data?.email,
            username: response?.data?.username,
          });
          ReactGA.event("sign_up");
          setPhoneNumberStep(true);
          // handleCompleteLastStep();
        } else {
          const resMessage = response.message;
          setState({
            ...state,
            successful: false,
            message: resMessage,
          });
        }
      })
      .catch((reject) => {
        console.log(reject);
        alert("somethingwentwrong");
      })
      .then(() => {
        setLoading(false);
      });
  };
  const responseErrorGoogle = (response) => {
    console.log(response);
  };

  const onClickOption = (e) => {
    e.preventDefault();
    hoverSfx();
    setError("");
    //state.username == "" ||
    if (!ValidateEmail(email) || password === "") {
      setError("Please enter all fields above correctly to continue");
    } else if (state.terms === false || !termsChecked) {
      setError("Please agree to terms of use to continue");
    } else {
      console.log(e);
      handleRegister(e);
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setState({
      ...state,
      message: "",
      successful: false,
    });
    // console.log({ email, password, username, state });
    setLoading(true);
    // setUser({email,username});
    // window.location.replace('/app/onboarding/questions');
    // return;

    AuthService.register(email, password, username, "zaio.io", "")
      .then(async (response) => {
        // alert(email, password, username, "zaio.io", "");
        if (response.success) {
          setState({
            ...state,
            success: true,
            message: response?.message,
          });
          ReactGA.event("sign_up");
          setPhoneNumberStep(true);

          Mixpanel.alias(email); // set alias for user
          Mixpanel.track("Account created", {
            source: "zaio",
          });
          Mixpanel.people.set({
            $email: email,
            $distinct_id: email,
            $name: username,
            $status: "free",
            $expirement: "retention",
          });
          // Mixpanel.track("Text onboarding Successful sign up", {
          //   method: "normal",
          //   distinct_id: email,
          // });
          signupEventTracking({ username, email });
          console.log({ response });
          setUser({ ...response, ...response?.data, enrolled: false });
          //   handleCompleteLastStep();
          //   if (!response?.data?.username) {
          //     history.push("/completesignin");
          //   }

          // setShowSetup(true);
        } else {
          const resMessage = response?.message;
          console.log(state);
          // Mixpanel.track("Unsuccessful sign up");
          setState({
            ...state,
            successful: false,
            message: resMessage,
          });
        }
      })
      .catch((reject) => console.log("somethingwentwrong", reject))
      .then(() => {
        setLoading(false);
      });
    // setShowSetup(true);
  };

  const savePhoneNumber = () => {
    setError("");
    if (!phonenumber) return setError("Please enter correct phone number.");
    let formattedNumber;
    if (!phonenumber.startsWith("+")) formattedNumber = "+" + phonenumber;
    else formattedNumber = phonenumber;
    console.log({ formattedNumber });
    AuthService.updatetUserdata(formattedNumber)
      .then((res) => {
        console.log({ res });
        if (res?.success) {
          setSytemUserAttributes({ ...user, phoneNo: res?.data });
          phoneEventTracking({ phonenumber: res?.data });
          setUser({ ...user, phonenumber: res?.data });
          setTimeout(() => {
            history.replace("/onboarding/payments");
          }, 2000);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="onb-question p-3">
      {!phonenumberStep && (
        <>
          <GoogleLogin
            clientId="26179719531-0i4v58bc6afmg2b2pjvlg1bd443e55u0.apps.googleusercontent.com"
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="w-100 googleLoginButton mb-3"
              >
                <span className="googleLoginIcon">
                  <img src={googleLoginIcon} width={24} height={24} alt="" />
                </span>
                Complete with Google
              </button>
            )}
            buttonText="Login"
            onSuccess={responseSuccessGoogle}
            onFailure={responseErrorGoogle}
            cookiePolicy={"single_host_origin"}
          />
          <div className="flex flex-column justify-center items-center">
            {/* <hr className="dashed text-white" /> */}
            {/* <p className="p text-white">Or Email 👇</p> */}
            {/* <p className="p text-white">Email 👇</p>
        <hr className="dashed text-white" /> */}

            <div className="py-2 w-full">
              <label className="text-white" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Name"
                defaultValue={onbDetails?.signupDetails?.name}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="py-2 w-full">
              <label className="text-white" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="email@address.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="py-2 w-full">
              <label className="text-white" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="*****"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="text-white">
              <p className="mb-2">
                By signing up for Zaio Institute of Technology, you agree to
                Zaio’s
                <a
                  href="https://www.zaio.io/terms"
                  className="text-[#8437f9] text-decoration-none mx-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                &amp;
                <a
                  href="https://www.zaio.io/privacypolicy"
                  className="text-[#8437f9] text-decoration-none ms-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="marketingConsent"
                  checked={termsChecked}
                  onChange={() => setTermsChecked(!termsChecked)}
                />
                <label
                  className="form-check-label text-white"
                  htmlFor="marketingConsent"
                >
                  I agree to receive updates, exclusive offers, and marketing
                  material from Zaio.
                </label>
              </div>
            </div>
            {error && (
              <div className="">
                {error && <p className="error">{error}</p>}
                {state?.message && <p className="error">{state?.message}</p>}
              </div>
            )}
            <div
              className="w-100 flex justify-center"
              //   {"onb-footer " + "onb-footer-sticky"}>
            >
              <button
                className="btn-main w-full rounded-md my-4 p-2 onb-footer-complete-btn border-radius-2"
                onClick={onClickOption}
              >
                Signup
                {/* {customButtonName ? customButtonName : "Continue"} */}
              </button>
            </div>
          </div>
        </>
      )}

      {phonenumberStep && (
        <div className="py-2 w-full">
          <label className="text-white" htmlFor="password">
            Phone Number
          </label>
          <PhoneInput
            placeholder="+27 763 793 540"
            country={"za"}
            value={phonenumber}
            onChange={(phone) => setPhoneNumber(phone)}
            inputStyle={{ width: "100%" }}
            inputProps={{
              name: "phonenumber",
              required: true,
            }}
          />

          {error && (
            <div className="">
              {error && <p className="error">{error}</p>}
              {state?.message && <p className="error">{state?.message}</p>}
            </div>
          )}
          <div
            className="w-100 flex justify-center"
            //   {"onb-footer " + "onb-footer-sticky"}>
          >
            <button
              className="btn-main w-full rounded-md my-4 p-2 onb-footer-complete-btn border-radius-2"
              onClick={savePhoneNumber}
            >
              Save
              {/* {customButtonName ? customButtonName : "Continue"} */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignupNew;
