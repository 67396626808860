import lectureService from "actions/services/lecture.service";
import Compiler from "components/Compiler/Compiler";
import { useQuery } from "react-query";

import useAssignmentStore from "zustand-store/assignmentStore";
import useVideoStore from "zustand-store/videoPlayerStore";

function AssCompiler({ qId }) {
  return <Compiler assignment={true} />;
}

export default AssCompiler;
