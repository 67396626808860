import { useHistory, useLocation, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { ComponentContainer } from "../Competitions.styled";

import Nav from "react-bootstrap/Nav";

import Tab from "react-bootstrap/Tab";

import Overview from "./Overview/Overview";
import Poster from "./Poster";
import Spec from "./Spec/Spec";
import Resources from "./Resources/Resources";
import DashboardNavbar from "component/navbar/Navbar";
import Leaderboard from "./Leaderboard/Leaderboard";
import Hints from "./Hints/Hints";
// import Rules from "./Rules/Rules";
import CustomModal from "components/Modals/CustomModal";
import { useQuery, useQueryClient } from "react-query";
import competitionsService from "actions/services/competitions.service";
import ZPLoading from "pages/Watch/components/ZPLoading";
import Error from "components/Error/Error";
import JoinCompetitionModalBody from "./JoinCompetitionModalBody";

function CompetitionPage() {
  const history = useHistory();
  const location = useLocation();
  const { competitionId } = useParams();
  console.log(useParams());
  const queries = new URLSearchParams(location.search);
  const [tab, setTab] = useState(queries.get("tab"));

  const [enableLoad, setEnableLoad] = useState(true);
  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const team = useQueryClient().getQueryData("getTeam");
  const {
    isLoading,
    isError,
    data: competition,
  } = useQuery(
    "getCompetitionData",
    () =>
      competitionsService.getCompetitionDetails(
        competitionId,
        team?.teamData?._id
      ),
    {
      // onSuccess: (data) => {
      //   console.log("DATA", data);
      //   setEnableLoad(false);
      // },
      // enabled: enableLoad,
    }
  );

  const loadModal = () => {
    if (team?.teamData?._id) {
      setModal({
        ...modal,
        show: true,
        title: "Join Competition",
        CustomComponent: JoinCompetitionModalBody,
        props: {
          price: competition?.data.enterancefee,
          teamid: team?.teamData?._id,
          competitionid: competitionId,
        },
      });
      return;
    }

    setModal({
      ...modal,
      show: true,
      title: "Create Team First",
      description: "You need to create a team first to join this competition.",
      CustomComponent: JoinCompetitionModalBody,
      props: {
        price: competition?.data.enterancefee,
        teamid: team?.teamData?._id,
        competitionid: competitionId,
      },
    });
  };

  const handleUpdateURL = useCallback((event) => {
    history.push(`/competitions/${competitionId}?tab=${event}`);
  }, []);

  const displayButton = () => {
    // return <button>Starting on 15/12/2022</button>;
    if (!competitionData?.enrolled) {
      return <button onClick={loadModal}>Join Competition</button>;
    }

    if (!competitionData?.submission?.mark) {
      return (
        <p className="m-0 p-2">
          <em>Solution being marked</em>
        </p>
      );
    }

    if (!competitionData?.submission?.date) {
      return (
        <p className="m-0 p-2">
          <em>Submitted with score: {competitionData?.submission?.mark}</em>
        </p>
      );
    }
    return (
      <button
        onClick={() => {
          history.push(
            "/competitions/638b999b9d87182eef668853?tab=overview&subtab=submission"
          );
          window.location.reload();
        }}
      >
        Submit Solution
      </button>
    );
  };

  if (isLoading) return <ZPLoading />;
  if (isError) return <Error />;
  if (competition?.success === false)
    return <Error message={competition?.message} />;

  // return <h1>TESTING</h1>
  const competitionData = competition?.data;
  return (
    competitionData && (
      <div style={{ padding: "28px 48px" }}>
        {modal.show && (
          <CustomModal
            show={modal.show}
            title={modal.title}
            description={modal.description}
            handleOnHide={handleOnHide}
            props={modal.props}
            CustomComponent={modal.CustomComponent ?? false}
            // videoUrl={"https://youtu.be/Uv9URjIBtUQ"}
          />
        )}

        <DashboardNavbar />

        <div className="d-flex justify-content-center">
          <ComponentContainer>
            <Tab.Container defaultActiveKey={tab || "overview"}>
              <Poster
                competitionname={competitionData?.competitionname}
                competitionthumbnail={competitionData?.competitionthumbnail}
                company={competitionData?.company}
                tags={competitionData?.tags}
                duration={Math.floor(competitionData?.duration / 24)}
                prize={competitionData?.prize}
                bannerdescription={competitionData?.bannerdescription}
                zcoinprize={competitionData?.zcoinprize}
                banner={competitionData?.banner}
              />
              <Nav variant="pills" className="main-nav">
                <div className="nav-left">
                  <Nav.Item onClick={() => handleUpdateURL("overview")}>
                    <Nav.Link eventKey="overview">Overview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item onClick={() => handleUpdateURL("spec")}>
                    <Nav.Link eventKey="spec">Spec</Nav.Link>
                  </Nav.Item>
                  <Nav.Item onClick={() => handleUpdateURL("evaluation")}>
                    <Nav.Link eventKey="evaluation">Evaluation</Nav.Link>
                  </Nav.Item>
                  <Nav.Item onClick={() => handleUpdateURL("resources")}>
                    <Nav.Link eventKey="resources">Resources</Nav.Link>
                  </Nav.Item>
                  <Nav.Item onClick={() => handleUpdateURL("leaderboard")}>
                    <Nav.Link eventKey="leaderboard">Leaderboard</Nav.Link>
                  </Nav.Item>
                  <Nav.Item onClick={() => handleUpdateURL("hints")}>
                    <Nav.Link eventKey="hints">Hints</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="rules">Rules</Nav.Link>
                  </Nav.Item> */}
                </div>
                <div className="nav-right">{displayButton()}</div>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="overview">
                  <div className="tab-content-header">
                    <span>Overview</span>
                  </div>
                  <div className="tab-content-body">
                    <Overview
                      overview={competitionData?.overview}
                      timelines={competitionData?.timelines}
                      teamid={team?.teamData?._id}
                      competitionid={competitionId}
                      submission={competitionData?.submission}
                      company={competitionData?.company}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="spec">
                  <div className="tab-content-header">
                    <span>Technical Specifications</span>
                  </div>
                  <div className="tab-content-body">
                    <Spec specs={competitionData?.spec} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="evaluation">
                  <div className="tab-content-header">
                    <span>Evaluation Criteria</span>
                  </div>
                  <div className="tab-content-body">
                    <Spec specs={competitionData?.evaluation} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="resources">
                  <div className="tab-content-header">
                    <span>Resources</span>
                  </div>
                  <div className="tab-content-body">
                    <Resources
                      courses={competitionData?.resources?.courses}
                      otherresources={competitionData?.resources?.otherresouces}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="leaderboard">
                  {" "}
                  <div className="tab-content-header">
                    <span>Leaderboard</span>
                  </div>
                  <div className="tab-content-body">
                    <Leaderboard competitionId={competitionId} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="hints">
                  {" "}
                  <div className="tab-content-header">
                    <span>Hints</span>
                  </div>
                  <div className="tab-content-body">
                    <Hints
                      teamid={team?.teamData?._id}
                      competitionid={competitionId}
                      loadModal={loadModal}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="rules">
                  {" "}
                  <div className="tab-content-header">
                    <span>Rules</span>
                  </div>
                  {/* <div className="tab-content-body">
                    <Rules />
                  </div> */}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </ComponentContainer>
        </div>
      </div>
    )
  );
}

export default CompetitionPage;
