import "./Dashboard.scss";
import { RxTriangleLeft } from "react-icons/rx";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "context/UserProvider";
import Live from "components/Live/Live";

function ChatBubble({
  title = "🎉 Your Assignment is live",
  text = "Complete it and stay upto date with your tasks.",
  showLive = true,
  onClick = undefined,
  chatPersonImage = "https://zaiocontent.s3.eu-west-2.amazonaws.com/ab.png",
  hideImage = false,
  backgroundColor = "white",
}) {
  const { user } = useContext(UserContext);
  // const history = useHistory();
  return (
    <div className="dash-competition">
      {!hideImage && <img src={chatPersonImage} />}

      <div className="dash-message-parent">
        <RxTriangleLeft className="dash-comp-icon" />
        <div className="dash-message" style={{ backgroundColor }}>
          {title && (
            <p className="m-0 p-0">
              {title}
              {showLive && <Live />}
            </p>
          )}

          {text && <span>{text}</span>}
          {onClick && (
            <button className="btn btn-main m-2" onClick={onClick}>
              Do it now
              {/* {user && !user.ispro ? " - It's Free" : ""} */}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatBubble;
