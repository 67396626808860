import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import FAQ from "components/FAQ/FAQ";
import { IoIosArrowBack } from "react-icons/io";
import { AiFillCheckCircle, AiOutlineClose } from "react-icons/ai";
import paymentService from "actions/services/payment.service";
import styles from "./styles.module.css";
import { useContext } from "react";
import { LoadingContext } from "context/LoadingProvider";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "context/UserProvider";
import { Courses, Faq, countryCodeSigns } from "./../../Constants";
import { PaymentDetailsContext } from "context/PaymentDetailsProvider";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "Utils/Mixpanel";
import { BiExpand } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import { paymentPlanEventTracking } from "Utils/WebEngage";
mixpanel.init("4e2be35d7debc0ad57db236937d08d8d", { debug: true });

// Left sticky sidebar used for Mobile modal and desktop view
const ChooseAPlan = ({
  setValue,
  details,
  data,
  proceedPay,
  setPromo,
  setShowPlanModal,currencySign
}) => {
  return (
    <div className={`col-sm-12 col-md-12 col-lg-5`}>
      <div className="p-sm-3 p-md-2 p-lg-5 position-sticky bg-white top-0">
        <div className="d-flex justify-content-end d-md-none">
          <button
            onClick={() => {
              setShowPlanModal(false);
            }}
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className="mb-4">
          <h3 className="mediumBlackText">Choose a plan</h3>
        </div>

        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group>
            <Form.Control
              className={styles.form_select}
              as="select"
              size="lg"
              plaintext
              value={data?.plan}
              onChange={(e) => setValue("plan", e.target.value)}
            >
              {details &&
                details?.path?.paymentplans &&
                Object.keys(details?.path?.paymentplans).map((option) => {
                  const item = data?.paymentOptions[option];
                  return (
                    <option value={item.plan}>
                      {currencySign}{' '}{item.amount}
                      {""}
                      {item.plan === "upfront"
                        ? ` upfront`
                        : `/month for ${item.months} months`}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>
        </Form>

        <div className="p-1">
          <p className={styles.sep}>
            <p className="m-2">or </p>
          </p>
        </div>

        <div className="pt-3 d-flex align-items-center justify-content-between">
          <b className="normalText font_16 normalTextBold">
            x 1 {details?.name} Path
          </b>
          <div className={styles.imgbox + " d-flex align-items-center"}>
            <p className="m-0">{data.path.code}</p>
          </div>
        </div>

        <div className="pt-5">
          <p className={styles.sep}></p>
        </div>

        {data.plan === "upfront" || data.plan === "not-selected" ? (
          <div className="pt-3 d-flex justify-content-between">
            <span className="normalText font_14 normalTextColorGrey">
              Course price
            </span>
            <span className="ml-2 normalText font_14 normalTextColorBlack">
              {details &&
                details?.path?.paymentplans &&
                Object.keys(details?.path.paymentplans).map((option) => {
                  const item = data?.paymentOptions[option];
                  if (item.plan === data.plan) {
                    return currencySign + " "+ item.amount;
                  }
                })}
            </span>
          </div>
        ) : (
          <>
            <div className="pt-3 d-flex justify-content-between">
              <span className="normalText font_14 normalTextColorGrey">
                Initial installment
              </span>
              <span className="ml-2 normalText font_14 normalTextColorBlack">
                {/* R
            {data.plan === "two"
              ? data.two_single_month
              : data.four_single_month} */}
                {/* R {data?.paymentOptions[data?.plan]} */}
                {details &&
                  Object.keys(details?.path.paymentplans).map((option) => {
                    const item = data?.paymentOptions[option];
                    if (item.plan === data.plan) {
                      return currencySign + " " + item.amount;
                    }
                  })}
              </span>
            </div>
            <div className="pt-3 d-flex justify-content-between">
              <span className="normalText font_14 normalTextColorGrey">
                After initial installment
              </span>
              <span className="ml-2 normalText font_14 normalTextColorBlack">
                {details &&
                  Object.keys(details?.path.paymentplans).map((option) => {
                    const item = data?.paymentOptions[option];
                    if (item.plan === data.plan) {
                      return item.months + " ";
                    }
                  })}
                payments
              </span>
            </div>
          </>
        )}

        <div className="pt-3 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <span className="normalText font_14 normalTextColorGrey">
              Discount
            </span>
            <div className="bg-white p-1 ms-3 me-2">
              <span className="ms-2">
                {data.couponcode === "" ? "ZAIO" : data.couponcode}
                <AiOutlineClose className="ms-2" fontWeight={200} />
              </span>
            </div>
          </div>
          <span className="ml-2 normalText font_14 normalTextColorBlack">
            {details?.discount}%
          </span>
        </div>

        <div className="pt-2">
          <p className={styles.sep}></p>
        </div>

        {data.plan == "upfront" || data.plan === "not-selected" ? (
          <div className="pt-3 d-flex align-items-center justify-content-between">
            <b className=" normalText font_16 normalTextColorBlack normalTextBold">
              TOTAL
            </b>
            <b className="normalText font_16 normalTextColorBlack normalTextBold">
              {/* R{Math.ceil(data.price)} */}
              {details &&
                Object.keys(details?.path.paymentplans).map((option) => {
                  const item = data?.paymentOptions[option];
                  if (item.plan === data.plan) {
                    return  currencySign + " " + item.amount;
                  }
                })}
            </b>
          </div>
        ) : (
          <div className="pt-3 d-flex align-items-center justify-content-between">
            <b className="normalText font_16 normalTextColorBlack normalTextBold">
              Summary
            </b>
            <b className="normalText font_16 normalTextColorBlack normalTextBold">
              {details &&
                Object.keys(details?.path.paymentplans).map((option) => {
                  const item = data?.paymentOptions[option];
                  if (item.plan === data.plan) {
                    return item.months + ` installments / ${currencySign}` + item.amount;
                  }
                })}
            </b>
          </div>
        )}

        <div className="mt-5 align-items-center justify-content-center">
          <button
            className="btn btn-lg btn-main w-100"
            type="button"
            onClick={() => proceedPay()}
          >
            <p className="apercuProText font_16 m-0 my-2">Proceed to payment</p>
          </button>
          <p
            className="cursor normalText mt-3 font_14 text-center link-main text-underline"
            onClick={() => setPromo(true)}
          >
            Have a promo code?
          </p>
        </div>
      </div>
    </div>
  );
};

function CourseSummary(props) {
  let redUrl;
  if (process.env.REACT_APP_MODE === "sandbox") {
    redUrl = `${process.env.REACT_APP_BACKEND_URL}/payment`;
  } else {
    redUrl = "https://www.zaio.io/payment";
  }
  const history = useHistory();
  const queries = new URLSearchParams(history.location.search);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const { details, setCouponCode, setInstallments, setAddOn, setDetails } =
    useContext(PaymentDetailsContext);
  const { setLoading } = useContext(LoadingContext);
  const [promo, setPromo] = useState(false);
  const { user } = useContext(UserContext);
  const [faq] = useState(Faq);

  const [data, setData] = useState({
    name: details ? details?.name : "",
    courseid: details ? details?.id : "",
    discount: details ? details?.discount : "",
    learningpathid: details ? details?.id : "",
    amount: details ? details?.price : 0,
    couponcode: queries.get("coupon") ? queries.get("coupon") : "",
    freecourse: false,
    showInstallments: false,
    option: null,
    ptCourses: details ? Courses.filter((obj) => obj.lp === details.id) : [],
    showAddons: false,
    path: details ? details?.path : "",
    plan: details ? details?.plan : "upfront",
    paymentOptions: details?.path.paymentplans || [],
  });

  const [state, setState] = useState({
    success: !!user,
    completed: !!user
      ? user.hasOwnProperty("data")
        ? user.data.hasOwnProperty("completed")
          ? user.data.completed
          : false
        : !!user.completed
      : false,
    username: !!user
      ? user.hasOwnProperty("data")
        ? user.data.hasOwnProperty("username")
          ? true
          : false
        : !!user.username
      : false,
    redirect: "/login",
    src: "zaio.io",
  });

  const [currencySign] = useState(countryCodeSigns?.[details?.country] || countryCodeSigns.US);  

  const isDesktop = useMediaQuery({
    query: "(min-width: 728px)",
  });

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/course-summary",
      "Page name": "Viewing Summary after Pricing",
    });
    setLoading(true);

    if (details) {
      let price =
        details.path.learningpathprice -
        (details.discount * details.path.learningpathprice) / 100;
      mixpanel.track("Course summary", {
        "Course name": details?.path?.learningpathname,
      });
      setData({
        ...data,
        price,
        two_single_month: Math.ceil(price / 2 + (price * 4) / 100),
        four_single_month: Math.ceil(price / 4 + (price * 6) / 100),
        learningpathid: details.id,
        courseid: details.id,
        couponcode: details.couponcode,
        name: details.name,
        amount: details.price,
        iscourse: false,
        discount: details.discount,
        showAddons: false,
        ptCourses: Courses.filter((obj) => obj.lp === details.id),
        path: details.path,
      });

      setLoading(false);
    } else {
      // window.location.href = window.location.origin + "/updated-dashboard";
    }
  }, []);

  const amountToPayCalculation = () => {
    if (data.plan === "upfront") {
      return data.price;
    } else {
      if (data.plan === "two") {
        return data.two_single_month;
      } else {
        return data.four_single_month;
      }
    }
  };
  const proceedPay = () => {
    setDetails({
      ...data,
      amountToPay: amountToPayCalculation(),
    });
    mixpanel.track("Proceed to pay", {
      "Course name": data.path.learningpathname,
      "Payment method": "card",
      "Payment plan": data.plan,
      Revenue: data.amountToPay,
      "Discount code": data.couponcode,
      "Discount percentage": data.discount,
    });
    paymentPlanEventTracking(data);
    Object.keys(details?.path.paymentplans).map((option) => {
      const item = data?.paymentOptions[option];
      if (item.plan === data.plan) {
        window.location.href = item.paystackurl;
      }
    });
  };

  const setValue = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const closeModal = () => {
    setPromo(false);
  };

  const onCouponSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await paymentService.verifyCoupon({
      couponcode: data.couponcode,
      courseid: data.courseid,
    });
    setLoading(false);
    if (res.success) {
      setCouponCode(data.couponcode);
      let price =
        details.path.learningpathprice -
        (res.data.discount * details.path.learningpathprice) / 100;
      setDetails({
        ...data,
        discount: res.data.discount,
        couponcode: data.couponcode,
        price,
        two_single_month: Math.ceil(price / 2 + (price * 4) / 100),
        four_single_month: Math.ceil(price / 4 + (price * 6) / 100),
      });
      setData({
        ...data,
        discount: res.data.discount,
        price,
        two_single_month: Math.ceil(price / 2 + (price * 4) / 100),
        four_single_month: Math.ceil(price / 4 + (price * 6) / 100),
      });
      setPromo(false);
    } else {
      setData({
        ...data,
        couponcode: "",
        showInstallments: false,
        option: null,
        message: `'${data.couponcode}' ${res.message}`,
      });
    }
  };

  const handleSupportButton = useCallback(() => {
    alert(
      "You will be redirected to whatsapp to speak to a consultant! See you in just a moment."
    );
    window.open(
      `https://wa.me/+27632770232/?text=Hi there. I am interested in purchasing the course. I was on the summary page. I have some questions...`,
      "_blank"
    );
  }, []);

  useEffect(() => {
    if(!details) {
      history.push('/choose-path')
    }
  }, [])

  if (!state.success) {
    history.push(`/login`);
    return null;
  } else if (state.success && state.username) {
    return (
      <div className="container">
        {!showPlanModal && (
          <div
            className="bg-white position-fixed bottom-0 left-0 right-0 p-2 py-3 d-block d-md-none"
            style={{
              zIndex: 999999,
            }}
          >
            <button
              onClick={() => {
                setShowPlanModal(true);
              }}
              className="d-flex align-items-center justify-content-between btn w-100 btn-main py-3 shadow-sm"
            >
              <p className="m-0 text-bold h5">Check Payment Plans</p>
              <BiExpand />
            </button>
          </div>
        )}
        <div className="d-block d-md-none">
          <Modal show={showPlanModal}>
            <div className="p-3">
              <ChooseAPlan
                setValue={setValue}
                details={details}
                data={data}
                proceedPay={proceedPay}
                setPromo={setPromo}
                setShowPlanModal={setShowPlanModal}
                currencySign={currencySign}
              />
            </div>
          </Modal>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-12 col-md-7 mx-auto">
                <div className="shadow-sm rounded border p-4 border">
                  <p className="m-0 h5 text-white">This course includes,</p>
                  <div className="my-4">
                    {[
                      "Online course accredited at NQF Level 5.",
                      "Curriculum covers HTML, CSS, JavaScript, ReactJS, NodeJS, and MongoDB.",
                      "Gain skills to develop applications similar to Netflix, Youtube, and Amazon.",
                      "Full-time completion in approx 6 months (6 hours per day, Monday to Friday).",
                      "Part-time completion in approximately 10 to 12 months (3 hours per day, Monday to Friday).",
                      "Assistance in job placement provided upon passing the final assessment. Laptop and internet connection required to get started."
                    ]?.map((point) => (
                      <div className="d-flex align-items-center my-2">
                        <span className="w-2 mr-4 pr-1">
                        <AiFillCheckCircle color="#8437f9" className="" />
                        </span>
                        <p className="m-1 h6 text-white">{point}</p>
                      </div>
                    ))}
                  </div>
                  <button className="btn btn-lg btn-main w-100 shadow-sm" onClick={handleSupportButton}>
                    <p className="m-0 h6 my-1 text-white">Whatsapp a consultant</p>
                  </button>

                  {/* <button
                    className="btn btn-lg mt-3 w-100 shadow-sm"
                    style={{
                      borderColor: "#8437f9",
                    }}
                  >
                    <p className="m-0 h6 my-1 text-white">Whatsapp a consultant</p>
                  </button> */}
                  <div></div>
                </div>
              </div>
            </div>

            <FAQ data={faq} />
          </div>
          {isDesktop && (
            <ChooseAPlan
              setValue={setValue}
              details={details}
              data={data}
              proceedPay={proceedPay}
              setPromo={setPromo}
              setShowPlanModal={setShowPlanModal}
              currencySign={currencySign}
            />
          )}
        </div>
        <Modal
          show={promo}
          size="md"
          onHide={() => setPromo(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="p-5">
            <h2 className="mediumText text-primary mb-5">Have a promo code?</h2>
            <Form onSubmit={onCouponSubmit}>
              <Form.Group className="f_group">
                <Form.Control
                  className={styles.form_select}
                  type="text"
                  plaintext
                  placeholder="Enter your code here"
                  value={data.couponcode}
                  onChange={(e) => setValue("couponcode", e.target.value)}
                />
              </Form.Group>
            </Form>
            <div className="form-group">
              {data.message && (
                <div className="alert alert-danger" role="alert">
                  {data.message}
                </div>
              )}
            </div>
            <button
              className=" mt-3 btn w-100 btn-main"
              onClick={onCouponSubmit}
            >
              Apply code{" "}
            </button>
            <p
              className="cursor normalText mt-3 font_14 text-center link-main text-underline"
              onClick={() => {
                setValue("couponcode", "");
                setValue("message", "");
              }}
            >
              Remove
            </p>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (state.success && !state.username) {
    history.push(`/completesignin`);
    return null;
  }
}

export default CourseSummary;
