import AdminIdeNav from "../AdminIde/AdminIdeNav";

import "../AdminIde/admin_ide.scss";
import { useEffect, useState } from "react";
import AdminVideosList from "../AdminIde/AdminVideosList";
import { useParams } from "react-router-dom";

import { useQuery } from "react-query";
import lectureService from "actions/services/lecture.service";

import MCQForm from "./MCQForm";
import Assignment from "pages/Assignment/Assignment";
import AssignmentList from "./AssignmentList";
import AdminAssignmentNav from "./AdminAssignmentNav";
import AdminSandboxList from "../AdminIde/AdminSandboxList";
import CreateAssignmentForm from "./CreateAssignmentForm";
import AdminQuestionsList from "./AdminQuestionsList";

function AdminAssignment() {
  const { courseid, lectureid, unitid } = useParams();
  console.log("COURSE ID", courseid);
  console.log("unitid ID", unitid);
  console.log("lectureid ID", lectureid);

  const [showSandboxList, setShowSandboxList] = useState(false);
  const [showVideosList, setShowVideosList] = useState(true);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [projectFiles, setProjectFiles] = useState(null);
  const [preLoadedFiles, setPreLoadedFiles] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editingCompleted, setEditingCompleted] = useState(true);

  const [playgroundid, setPlaygroundId] = useState(false);
  const [show, setShow] = useState(false);
  const [questionType, setQuestionType] = useState("");

  const { isLoading, isError, error, data } = useQuery(
    "ideCurrentVideo",
    () => lectureService.getPublicLectureById(lectureid),
    {
      onSuccess: (data) => {
        setCurrentVideo(data);
      },
    }
  );

  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const loadModal = () => {
    setShow(true);
    console.log("LOAD MODAL", show);

    // setModal({
    //   ...modal,
    //   show: true,
    //   title: editingCompleted ? "Create" : "Edit",
    //   description: "",
    //   CustomComponent: SaveProgress,
    //   props: {
    //     currentVideo,
    //     projectFiles: JSON.parse(localStorage.getItem("projectFiles")),
    //     lectureid,
    //     setEditMode,
    //     editMode,
    //     playgroundid,
    //   },
    // });
  };

  const onCtrlS = () => {
    loadModal();
  };

  const onProjectFilesUpdate = (files) => {
    console.log("PROJECT Files", files);
    setProjectFiles(files);
  };

  // useEffect(() => {
  //   console.log("EDIT MODE UDPATED", editMode);
  //   if (!editMode) setEditingCompleted(true);
  //   else setEditingCompleted(false);
  // }, [editMode]);

  return (
    <div>
      <AdminAssignmentNav
        setShowSandboxList={setShowSandboxList}
        showSandboxList={showSandboxList}
        setShowVideosList={setShowVideosList}
        showVideosList={showVideosList}
        currentCourse={currentCourse}
        editMode={editMode}
        setEditMode={setEditMode}
        currentVideo={currentVideo}
        questionType={questionType}
        setQuestionType={setQuestionType}
      />
      {/* {showSandboxList && (
        <AdminSandboxList
          lectureid={lectureid}
          setPreLoadedFiles={setPreLoadedFiles}
          setEditMode={setEditMode}
          setPlaygroundId={setPlaygroundId}
          playgroundid={playgroundid}
        />
      )} */}
      {showSandboxList && (
        <div className="admin-sandbox-sidebar">
          <AdminQuestionsList />
          {/* <QuestionsList
            type="mcq"
            mcqs={assignment?.data?.assignmentid?.mcqquestions}
            codingQs={assignment?.data?.assignmentid?.codingquestions}
          /> */}
        </div>
      )}
      <div className="admin-ide-container">
        <CreateAssignmentForm
          lectureId={lectureid}
          courseId={courseid}
          unitId={unitid}
        />
      </div>
      {showVideosList && (
        <AssignmentList
          setCurrentCourse={setCurrentCourse}
          courseid={courseid}
          lectureid={lectureid}
          unitid={unitid}
          setCurrentVideo={setCurrentVideo}
          currentVideo={currentVideo}
        />
      )}
    </div>
  );
}

export default AdminAssignment;

// import React, { useState } from "react";
// import MCQForm from "./MCQForm";
// import AdminMCQ from "./AdminMCQ";

// function AdminAssignments() {
//   const [questionType, setQuestionType] = useState("");
//   const [title, setTitle] = useState("");
//   const [duration, setDuration] = useState("");

//   function handleQuestionTypeChange(event) {
//     setQuestionType(event.target.value);
//   }

//   return (
//     <div className="mcq-container">
//       {/* <div className="mcq-container-max mt-5"> */}
//       <div className="d-flex flex-column align-items-center rounded p-4">
//         <label htmlFor="questionType" className="text-white">
//           Question Type:
//         </label>
//         <select
//           id="questionType"
//           value={questionType}
//           onChange={handleQuestionTypeChange}
//         >
//           <option value="">Select a question type</option>
//           <option value="mcq">MCQ</option>
//           <option value="coding">Coding Challenge</option>
//         </select>

//         <label htmlFor="title" className="text-white">
//           Assignment
//         </label>
//         <input
//           type="text"
//           value={title}
//           onChange={(event) => setTitle(event.target.value)}
//         />

//         <label htmlFor="duration" className="text-white">
//           Duration
//         </label>
//         <input
//           type="number"
//           value={duration}
//           onChange={(event) => setDuration(event.target.value)}
//         />
//         {questionType === "mcq" && <MCQForm adminAssignment={true} />}
//         {/* {questionType === "coding" && <CodingForm />} */}
//       </div>
//       {/* </div> */}
//     </div>
//   );
// }

// export default AdminAssignments;
