import React from "react";
import { createContext, useState } from "react";

interface IPaymentDetails {
  addOns: {
    internship: boolean,
    mentorship: boolean
  } | null;
  installments: {
    total: number,
    interest: number,
    valid: boolean;
  } | null;
  details: {
    case: "course" | "learning-path";
    price: number;
    id: string;
    name: string;
    discount: number;
    couponcode: string;
  } | null;
  setAddOn: React.Dispatch<
    React.SetStateAction<{
      internship: boolean;
      mentorship: boolean;
    } | null>
  >;
  setDetails: React.Dispatch<
    React.SetStateAction<{
      case: "course" | "learning-path";
      price: number;
      id: string;
      name: string;
      discount: number;
      couponcode: string;
    } | null>
  >;
  setInstallments: React.Dispatch<
    React.SetStateAction<{
      total: number;
      valid: boolean;
      interest: number;
    } | null>
  >;
  setCouponCode: (couponcode: string) => void;
}

const defaultValue: IPaymentDetails = {
  details: null,
  installments: null,
  addOns: null,
  setAddOn: () => {},
  setDetails: () => {},
  setInstallments: () => {},
  setCouponCode: () => {},
};

export const PaymentDetailsContext = createContext(defaultValue);

export const PaymentDetailsProvider: React.FC = ({ children }: any) => {
  const [details, setDetails] = useState<IPaymentDetails["details"]>(null);
  const [addOns, setAddOn] = useState<IPaymentDetails["addOns"]>(null);
  const [installments, setInstallments] = useState<IPaymentDetails["installments"]>(null);
  const setCouponCode = (couponcode: string) => {
    if (details) {
      setDetails({ ...details, couponcode });
    }
  };
  return (
    <PaymentDetailsContext.Provider
      value={{ 
        details, 
        installments, 
        addOns,
        setDetails, 
        setCouponCode, 
        setInstallments,
        setAddOn
      }}
    >
      {children}
    </PaymentDetailsContext.Provider>
  );
};
