import authService from 'actions/services/auth.service';
import { LoadingContext } from 'context/LoadingProvider';
import { useContext } from 'react';
import {useState,useEffect} from 'react'
import Form from "react-bootstrap/Form";
import {useHistory} from 'react-router-dom';
import { Mixpanel } from 'Utils/Mixpanel';

function ForgotPassword(props) {
    const history = useHistory();
    const {setLoading} = useContext(LoadingContext)
    const [data, setData] = useState({
        email:"",
        password:"",
        confirmPassword:"",
        getPassword:false,
        uid:""
    })

    useEffect(() => {
        Mixpanel.track("Web Page view", {
            "Page url": "/forgot-password",
            "Page name": "Forgot Password Page",
          });
        const queries = new URLSearchParams(history.location.search);
        const uid = queries.get('uid');
        if(uid){
            setData({...data,getPassword:true,uid:uid})
        }
    }, [])

    if(data.getPassword){
        return (
            <div className="container">
                <Form className="form-group w-50 p-5 m-auto">
                  <label >Enter New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={data.password}
                    onChange={e=>setData({...data,password:e.target.value})}
                    placeholder="New Password"
                  />
                  <input
                    type="password"
                    className="form-control mt-2"
                    name="confirmPassword"
                    value={data.confirmPassword}
                    onChange={e=>setData({...data,confirmPassword:e.target.value})}
                    placeholder="Confirm Password"
                  />
                  <button 
                    className="btn btn-primary mt-2" 
                    onClick={
                        async (e)=>{
                                e.preventDefault();
                                setLoading(true);
                                if(data.password===data.confirmPassword){
                                    const res = await authService.setNewPassword(data.uid,data.password);
                                    
                                    if(res.success){
                                        alert('Password successfully changed')
                                        history.push("/")
                                        
                                    }
                                    else{
                                        console.log(res.message)
                                    }
                                }
                                else{
                                    alert('Passwords did not match'); 
                                }
                                setLoading(false);
                            }
                        }>Reset Password</button>
                </Form>
            </div>
            
        )
    }
    return (
        
        <div className="d-flex justify-content-center m-3">
        <div
          className=" col-12 col-md-6 col-lg-4 m-4 p-sm-2 p-md-4 border-4 rounded border-primary bg-white"
        >
         
            <Form className="form-group w-100 m-auto">
              <label className="apercuProText font_16 pb-2 pt-2">Enter Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={data.email}
                onChange={e=>setData({...data,email:e.target.value})}
                placeholder="email"
              />
              <button 
                className="btn btn-primary mt-2 w-100" 
                onClick={async e=>{
                    e.preventDefault();
                    setLoading(true);
                    await authService.forgotpassword(data.email);
                    setLoading(false);
                    alert('Password reset link sent')}}
                    >Send password reset link</button>
            </Form>
        </div>
        </div>
        
    )
}

export default ForgotPassword
