import lectureService from "actions/services/lecture.service";
import React, { useEffect, useState } from "react";
import { Form, Button, Card, ListGroup } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import TextEditor from "./TextEditor";
import TextEditorDrawer from "../CodingChallenge/TextEditorDrawer";
import useEditorStore from "zustand-store/editorStore";

function CreateAssignmentForm({
  courseId,
  unitId,
  lectureId,
  adminAssignment = false,
}) {
  console.log("lectureId", lectureId);
  const [questions, setQuestions] = useState([]);
  const [questionText, setQuestionText] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctOptionIndex, setCorrectOptionIndex] = useState(-1);
  const [assignmentName, setAssignmentName] = useState("");
  const [answerExplanation, setAnswerExplanation] = useState("");
  const [assignmentPreview, setAssignmentPreview] = useState("");
  const [duration, setDuration] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [isTextEditorOpen, setTextEditorOpen] = useState(false);

  const textEditorValue = useEditorStore((state) => state.textEditorValue);
  const setCurrentTextEditorValue = useEditorStore(
    (state) => state.setCurrentTextEditorValue
  );

  const queryClient = useQueryClient();

  const createAssignmentMutation = useMutation(
    () =>
      lectureService.createAssignment(
        textEditorValue,
        parseInt(duration),
        assignmentName
        // afterLecture
      ),
    {
      onSuccess: (data) => {
        console.log("created assignment...", data);
        alert(
          "Assignment created successfully...response " + JSON.stringify(data)
        );

        return queryClient.invalidateQueries(["assignmentList"]);
      },
    }
  );

  useEffect(() => {}, []);

  const handleCreateAssignment = (e) => {
    e.preventDefault();
    // const objToSend = {
    //   courseid: courseId,
    //   courseunitid: unitId,
    //   lecturename: lectureName,
    //   duration: duration,
    //   questions: questions,
    //   afterlecture: afterLecture,
    // };
    // console.log("POST MCQ", objToSend);
    createAssignmentMutation.mutate();
  };

  return (
    <div className="d-flex w-100 justify-content-center">
      <div className="w-50 m-3">
        <>
          {createAssignmentMutation?.isLoading ? (
            <p className="text-white text-center w-100">Loading...</p>
          ) : (
            <button
              className="btn-warning p-3 w-100 m-2"
              onClick={handleCreateAssignment}
            >
              Create Assignment
            </button>
          )}
        </>

        <Form>
          <Form.Group controlId="lectureName">
            {/* <Form.Label className="text-white">
              Assignment Preview MD file
            </Form.Label> */}
            {/* <Form.Control
              type="text"
              value={assignmentPreview}
              onChange={(event) => setAssignmentPreview(event.target.value)}
            /> */}

            <h1 className="text-lg text-white m-2 px-2">
              Assignment Details For Preview:{" "}
              <div
                className="cursor-pointer inline-block"
                onClick={() => setTextEditorOpen(true)}
              >
                <span className="bg-purple-400 text-sm px-2 rounded-lg mx-1">
                  Info
                </span>

                <button
                  className="bg-green-600 text-white text-sm rounded px-2"
                  onClick={() => setTextEditorOpen(true)}
                >
                  +
                </button>
              </div>
            </h1>
            <TextEditorDrawer
              isOpen={isTextEditorOpen}
              setIsOpen={setTextEditorOpen}
            />
            {/* <TextEditor /> */}
          </Form.Group>
          <Form.Group controlId="duration">
            <Form.Label className="text-white">Duration</Form.Label>
            <Form.Control
              type="number"
              value={duration}
              onChange={(event) => setDuration(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="assignmentName">
            <Form.Label className="text-white">Assignment Name</Form.Label>
            <Form.Control
              type="text"
              value={assignmentName}
              onChange={(event) => setAssignmentName(event.target.value)}
            />
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}

export default CreateAssignmentForm;
