import SpeedControl from "./SpeedControl";
import NextLesson from "./NextLesson";
import { FaExpand, FaCompress, FaHeadset } from "react-icons/fa";
import PlayList from "./PlayList";
import { memo } from "react";
import Stats from "components/QuizReelComponents/Header/Stats";

import WalletBalance from "pages/Wallet/WalletBalance";
import SoloStreakScore from "component/myProfile/SoloStreakScore";
import useVideoStore from "zustand-store/videoPlayerStore";

const BottomRightControls = ({
  //speed controls
  playbackRateEnable,
  showPlaybackRate,
  playbackRate,
  setShowPlaybackRate,
  playbackRateOptions,
  onChangePlayBackRate,

  //next lesson
  onNextClick,
  showDataNext,
  dataNext,
  setShowDataNext,

  //playlist
  showReproductionList,
  setShowReproductionList,
  sublistMode,
  goBackToMainList,
  listTitle,
  listReproduction,
  list,
  openSublist,
  listRedirect,
  unitList,
  currentUnitId,
  currentLectureId,
  completedLectures,

  fullScreen,
  enterFullScreen,
  exitFullScreen,

  handleSupportButton,
}) => {
  // console.log("BOTTOM RIGHT CONTROLS RENDERED");
  const videoMode = useVideoStore((state) => state.videoMode);
  return (
    <div className="end" onClick={(e) => e.stopPropagation()}>
      <div className="item-control">
        {/* <Stats /> */}
        <WalletBalance simple toolTipPlacement="top" />
        <SoloStreakScore simple toolTipPlacement="top" />
      </div>
      {/* <div className="item-control">
        <WalletBalance simple />
      </div> */}
      <div className="item-control">
        <FaHeadset style={{ color: "#aaf0d1" }} onClick={handleSupportButton} />
      </div>
      {videoMode === "lecture" && (
        <SpeedControl
          playbackRateEnable={playbackRateEnable}
          showPlaybackRate={showPlaybackRate}
          playbackRate={playbackRate}
          setShowPlaybackRate={setShowPlaybackRate}
          playbackRateOptions={playbackRateOptions}
          onChangePlayBackRate={onChangePlayBackRate}
        />
      )}

      <NextLesson
        onNextClick={onNextClick}
        showDataNext={showDataNext}
        dataNext={dataNext}
        setShowDataNext={setShowDataNext}
      />
      <PlayList
        showReproductionList={showReproductionList}
        setShowReproductionList={setShowReproductionList}
        sublistMode={sublistMode}
        goBackToMainList={goBackToMainList}
        listTitle={listTitle}
        listReproduction={listReproduction}
        list={list}
        openSublist={openSublist}
        listRedirect={listRedirect}
        unitList={unitList}
        currentUnitId={currentUnitId}
        currentLectureId={currentLectureId}
        completedLectures={completedLectures}
      />
      <div className="item-control">
        {fullScreen === false && <FaExpand onClick={enterFullScreen} />}
        {fullScreen === true && <FaCompress onClick={exitFullScreen} />}
      </div>
    </div>
  );
};

export default memo(BottomRightControls, (prevProps, nextProps) => {
  // console.log("BOTTOM RIGHT CONTROLS MEMO");
  if (
    prevProps.playbackRateEnable !== nextProps.playbackRateEnable ||
    prevProps.showPlaybackRate !== nextProps.showPlaybackRate ||
    prevProps.playbackRate !== nextProps.playbackRate ||
    prevProps.showDataNext !== nextProps.showDataNext ||
    prevProps.showReproductionList !== nextProps.showReproductionList ||
    prevProps.sublistMode !== nextProps.sublistMode ||
    prevProps.listTitle !== nextProps.listTitle ||
    prevProps.fullScreen !== nextProps.fullScreen
  ) {
    return false;
  }
  return true;
});
