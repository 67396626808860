import moment from "moment";
import { useHistory } from "react-router-dom";
const UserAssignmentsTable = ({ data }) => {
  const history = useHistory();

  const handleOpenAssignment = (id) => {
    history.push(`/assignment/${id}`);
  };
  return (
    <div className="flex flex-col ">
      <div className="">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 bg-white">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Title
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Course &amp; Topic
                  </th> */}
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Open Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Close Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Final Grade
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {data &&
                  data?.map((item, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleOpenAssignment(item?.assignmentid?._id)}
                    >
                      <td className="px-6 py-4 text-sm font-medium text-gray-800">
                        {item?.assignmentid?.assignmentname}
                      </td>
                      {/* <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        Java Core: Loops
                      </td> */}
                      <td className="px-6 py-4 text-sm text-gray-800 ">
                        <span
                          className={`px-2 py-1 rounded-full ${
                            item?.status === "inprogress"
                              ? "bg-purple-400"
                              : "bg-yellow-400"
                          } text-white text-sm font-semibold`}
                        >
                          {item?.status} on{" "}
                          {moment(item?.submissiondate).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm font-medium">
                        {moment(item?.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium">
                        {moment(item?.deadline).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium ">
                        {item?.finalgrade || "Not Graded"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAssignmentsTable;
