import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ZCoinIcon from "assets/svg/zcoin.svg";
import { ZCoinContainer } from "./ZCoin.styled";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { Constants } from "Constants";

function ZCoin({
  number = 0,
  handleClick = undefined,
  toolTipText = Constants.ZCoinExplanation,
  toolTipPlacement = "top",
  displayToolTip = true,
}) {
  const [showToolTip, setShowToolTip] = useState(false);
  const target = useRef(null);
  const history = useHistory();

  const handleOnClick = () => {
    if (handleClick) {
      handleClick();
      return;
    }
    history.push("/wallet");
  };
  const renderTooltip = (props) => <Tooltip {...props}>{toolTipText}</Tooltip>;
  return (
    <>
      <ZCoinContainer
        ref={target}
        onClick={handleOnClick}
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
      >
        <div className="z-coin-amount">
          <img className="z-coin-img" src={ZCoinIcon} alt="LogoFooter" />
          <span>{number}</span>
        </div>
      </ZCoinContainer>
      <Overlay
        target={target.current}
        show={displayToolTip && showToolTip}
        placement={toolTipPlacement}
      >
        {renderTooltip}
      </Overlay>
    </>
  );
}

export default ZCoin;
