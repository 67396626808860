import { BsArrowLeftShort } from "react-icons/bs";
import "./Onboarding.scss";
import ProgressBar from "@ramonak/react-progress-bar";

function OnboardingHeader({ handleGoingBack, percentageCompleted = "0" }) {
  return (
    <div className="onb-header">
      {handleGoingBack && (
        <BsArrowLeftShort className="onb-back-btn" onClick={handleGoingBack} />
      )}
      <ProgressBar
        completed={percentageCompleted}
        className="onb-progress-bar"
        isLabelVisible={false}
        bgColor={"#8437f9"}
      />
    </div>
  );
}

export default OnboardingHeader;
