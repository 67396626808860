import React, { useEffect, useRef, useState, useContext } from "react";
import VideoFooter from "../VideoFooter/VideoFooter";
import VideoSidebar from "../VideoSidebar/VideoSidebar";
// import useVideoPlayer from "../../hooks/useVideoPlayer";
import ReactPlayer from "react-player";
import Note from "../Note/Note";
// import QuizOptions from "../QuizOptions/QuizOptions";
// import { QuizReelContext } from "../../contexts/QuizReelContext";

import "./Video.css";
import ContentDrawer from "../ContentDrawer/ContentDrawer";
import { UserContext } from "context/UserProvider";
import {
  MdPlayArrow,
  MdOutlinePause,
  MdReplay,
  MdTouchApp,
} from "react-icons/md";

import { SfxContext } from "context/SfxContext";
import Confetti from "react-dom-confetti";
import Redirect from "../ReelModal/Redirect";
import ReelModal from "../ReelModal/ReelModal";

import authService from "actions/services/auth.service";
import lectureService from "actions/services/lecture.service";
import courseService from "actions/services/course.service";

import Stats from "../Header/Stats";
import setOnboardingObject from "./OnboardingObject";
import { Mixpanel } from "Utils/Mixpanel";

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

const config = {
  angle: "85",
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  zIndex: 2,
};

function Video({
  url,
  description,
  quizOptions,
  displayQuizTimestampString,
  tryAgainQPStringTime,
  jumpToTryAgainStringTime,
  jumptoContinueStringTime,
  hasQuiz,
  title,
  index,
  playlistLength,
  videos,
  courseData,
  getAJobTimeStampString,
  getAJobTriggerString,
  sideHustleTimeStampString,
  sideHustleTriggerString,
  codingAsASkillTimeStampString,
  codingAsASkillTriggerString,
  howComfortableTimeStampString,
  courseOptionsTimeStampString,
  mobileCoursesTimeStampString,
  dlCoursesTimeStampString,
  howComfortableTriggerString,
  courseOptionsTriggerString,
  mobileCoursesTriggerString,
  dlCoursesTriggerString,
  onboarding = false,
  _id,
  scrollToVideo,
  lectIdFromUrl,
  courseId,
  unitId,
  handleOpenContentDrawer,
}) {
  // console.log("videos", videos);
  const {
    user,
    setUser,
    userNotSignedUp,
    setUserNotSignedUp,
    setUserProgress,
  } = useContext(UserContext);
  const { hoverSfx, clickSfx, completedSfx } = useContext(SfxContext);
  const videoRef = useRef(null);
  const [playerState, setPlayerState] = useState({
    playing: false,
    volume: 1,
    muted: false,
    played: 0,
    loaded: 0,
    loadedSeconds: 0,
    playbackRate: 1.0,
    loop: false,
    isQuizDisplayed: false,
    videoElement: null,
    seeking: false,
    playedSeconds: 0,
  });
  const [duration, setDuration] = useState(0);
  const [displayQuiz, setDisplayQuiz] = useState(false);
  const [dynamicQuizOptions, setDynamicQuizOptions] = useState([]);

  const [displayQuizTimeInt, setDisplayQuizTimeInt] = useState(false);
  const [showPlayPauseIcon, setShowPlayPauseIcon] = useState(false);
  const [showReplayIcon, setShowReplayIcon] = useState(false);
  const [showScrollIcon, setShowScrollIcon] = useState(false);
  const [playlistCompleted, setPlaylistCompleted] = useState(false);
  const [showHoverPlayPauseIcon, setShowHoverPlayPauseIcon] = useState(true);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [note, setNote] = useState({
    active: false,
    title: "title",
    description: "description",
    timestamp: "",
  });
  const [contentDrawer, setContentDrawer] = useState({
    active: false,
  });

  // Onboarding steps: all keys in 'onboardingObject' below + reelOnboardingComplete
  const [currentStepOnboarding, setCurrentStepOnboarding] = useState("goal");
  const [completedLectures, setCompletedLectures] = useState(null);
  const [lastwatchedid, setLastWatchedId] = useState(null);
  const [currentLectureId, setCurrentLectureId] = useState();
  const [completedLecturesCount, setCompletedLecturesCount] = useState(0);
  const [footerPosition, setFooterPosition] = useState(null);
  const [countDown, setCountDown] = useState(15);

  const [modal, setModal] = useState({
    show: false,
    title: "HELO HELO",
    description: "",
    CustomComponent: null,
    props: null,
  });

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      title: "",
      description: "",
      CustomComponent: null,
      props: null,
    });
  };

  // object to help with population of new quizOptions array
  const tryAgainObject = {
    tryAgain: [
      {
        nexttimestamp: jumpToTryAgainStringTime,
        nexttrigger: displayQuizTimestampString,
        text: "Try again",
      },
      {
        nexttimestamp: jumptoContinueStringTime,
        nexttrigger: null,
        text: "Continue",
      },
    ],
  };

  // object to handle onboarding
  const onboardingObject = setOnboardingObject(
    getAJobTimeStampString,
    getAJobTriggerString,
    sideHustleTimeStampString,
    sideHustleTriggerString,
    codingAsASkillTimeStampString,
    codingAsASkillTriggerString,
    howComfortableTimeStampString,
    howComfortableTriggerString,
    courseOptionsTimeStampString,
    courseOptionsTriggerString,
    mobileCoursesTimeStampString,
    mobileCoursesTriggerString,
    dlCoursesTimeStampString,
    dlCoursesTriggerString
  );

  useEffect(() => {
    // if (lectIdFromUrl === _id) {
    //   handlePlay();
    // }
    if (onboarding) {
      initialOnboardingData();
    } else {
      console.log("lastwatchedid", lastwatchedid);
      setLastWatchedId(lectIdFromUrl);
      setCurrentLectureId(lectIdFromUrl);
      if (!user || !user?.success) {
        console.log("about to scroll...", lastwatchedid);
        lastwatchedid && scrollToVideo(lastwatchedid);
      } else {
        console.log("about to user no success..., ", lectIdFromUrl);
        lectIdFromUrl && scrollToVideo(lectIdFromUrl);
      }
      getAndSetDataForNotSignedUpUsers();
      initialQuizData();
    }
    if (user && user.success) {
      courseService.getCourseCount(courseData._id).then((response) => {
        setCompletedLecturesCount(
          response.data ? response.data.completedlecturecount : 0
        );
      });
    }
  }, [lectIdFromUrl]);

  const initialQuizData = () => {
    setDisplayQuizTimeInt(convertTimeStringToInt(displayQuizTimestampString));
    setDynamicQuizOptions(quizOptions);
    setDisplayQuiz(false);
    setIsOptionSelected(false);
  };
  const initialOnboardingData = () => {
    setDisplayQuizTimeInt(convertTimeStringToInt(displayQuizTimestampString));
    setDynamicQuizOptions(onboardingObject.goal);
    setDisplayQuiz(false);
    setIsOptionSelected(false);
  };

  const getAndSetDataForNotSignedUpUsers = () => {
    const _completedLectures =
      authService.getOnboardingData("completedlectures");
    const _lastwatchedid = authService.getOnboardingData("lastwatchedid");
    if (_completedLectures) setCompletedLectures(completedLectures);
    if (_lastwatchedid) setLastWatchedId(_lastwatchedid);
  };

  const handlePlayPause = (e) => {
    // clickSfx();
    console.log("PLAY PAUSE", videoRef.current.props.id.split("-")[0]);

    setCurrentLectureId(videoRef.current.props.id.split("-")[0]);
    setPlayerState({ ...playerState, playing: !playerState.playing });
  };
  const handleSkipTo = (e) => {
    const manualNumberInDecimal = parseFloat(e.target.value) / 100;
    // console.log("manualNumberInDecimal", manualNumberInDecimal);
    videoRef.current.seekTo(manualNumberInDecimal, "fraction");
    setPlayerState((prevPlayerState) => ({
      ...prevPlayerState,
      played: manualNumberInDecimal,
    }));
  };

  const handleProgress = (state) => {
    // console.log("onProgress", state);

    // console.log(
    //   hasQuiz,
    //   parseInt(playerState.playedSeconds),
    //   displayQuizTimestampInt
    // );
    // console.log("playerState.playedSeconds", playerState.playedSeconds);

    if (onboarding && countDown > 0 && playing) {
      setCountDown(countDown - 1);
    }

    if (hasQuiz && !isOptionSelected) {
      if (
        parseInt(playerState.playedSeconds) === parseInt(displayQuizTimeInt)
      ) {
        // console.log("QUIZ PUSH");
        setDisplayQuiz(true);
        handlePause();
      } else {
        setPlayerState((prevPlayerState) => ({ ...prevPlayerState, ...state }));
      }
    } else {
      setPlayerState((prevPlayerState) => ({ ...prevPlayerState, ...state }));
    }
  };

  const handlePlay = (e) => {
    // console.log("onPlay", videoRef.current.props.id.split("-")[0]);
    setUser({ ...user, isQRPlaying: true });
    setCurrentLectureId(videoRef.current.props.id.split("-")[0]);
    if (!displayQuiz) {
      clickSfx();
    }

    setPlayerState({ ...playerState, playing: true });
    setShowReplayIcon(false);
  };

  const handlePause = (e) => {
    console.log("onPause", videoRef.current.props.id.split("-")[0]);
    setUser({ ...user, isQRPlaying: false });
    if (!displayQuiz) {
      clickSfx();
    }

    setPlayerState({ ...playerState, playing: false });
    setShowReplayIcon(false);
  };

  const handleMuteUnmute = () => {
    clickSfx();
    setPlayerState({ ...playerState, muted: !playerState.muted });
  };

  const handleTakeNote = () => {
    clickSfx();
    handlePause();

    if (user && user?.success) {
      const timestamp = new Date(playerState.playedSeconds * 1000)
        .toISOString()
        .slice(14, 19);
      setNote({
        ...note,
        active: true,
        title,
        description,
        timestamp,
      });
      setUser({ ...user, isContentDrawerOpen: true });
    } else {
      setModal({
        ...modal,
        show: true,
        title: "Need some info before you take a note!",
        description:
          "Please take a moment to signup, its FREE & quick, and this will help us store your notes.",
        CustomComponent: null,
        props: null,
      });
    }
  };
  const handleCloseNote = () => {
    clickSfx();
    setNote({ ...note, active: false });
    setUser({ ...user, isContentDrawerOpen: false });
  };

  const convertTimeStringToInt = (stringTime) => {
    const myArray = stringTime.split(":");
    const intTime = Number(myArray[0]) * 60 + Number(myArray[1]);
    return intTime;
  };

  const handleQuizOptionSelect = async (quizOption) => {
    clickSfx();

    setCountDown(false);
    console.log("SELECTED QUIZ OPTION", quizOption);
    if (quizOption.nexttimestamp === null) {
      console.log("nexttimestamp is null");
      if (onboarding) {
        console.log("reelOnboardingComplete...");
        Mixpanel.track("Onboarding Completed");
        setOnboardingData(quizOption.text);
        setCurrentStepOnboarding("reelOnboardingComplete");
        setOnboardingData(quizOption.text, "reelOnboardingComplete");
        setOnboardingData(quizOption.redirectToCourse.courseid, "courseid");
        setOnboardingData(
          quizOption.redirectToCourse.lectureid,
          "lastwatchedid"
        );
        await authService.postOnboardingQuestions();
      }
      setModal({
        ...modal,
        show: true,
        title: quizOption.text,
        description: "You will be redirected automatically.",
        // CustomComponent: null,
        // props: null
        CustomComponent: Redirect,
        props: {
          redirectUrl: quizOption.redirectToCourse.url,
          courseInfo: quizOption.redirectToCourse.courseInfo,
          text: quizOption.text,
        },
      });
      // alert(
      //   quizOption.text +
      //     " Trigger popup and redirect: " +
      //     quizOption.redirectUrl
      // );
      return;
    }

    // jumping to next time stamp in video
    const jumpToNextTimeStamp = convertTimeStringToInt(
      quizOption.nexttimestamp
    );
    console.log(
      "nexttimestamp int",
      jumpToNextTimeStamp,
      "loaded-seconds",
      duration
    );
    const jumpToNextTimeStampFraction = jumpToNextTimeStamp / duration;
    videoRef.current.seekTo(jumpToNextTimeStampFraction, "fraction");
    console.log(
      "nexttimestamp int",
      jumpToNextTimeStamp,
      "fraction",
      jumpToNextTimeStampFraction
    );
    setPlayerState((prevPlayerState) => ({
      ...prevPlayerState,
      played: jumpToNextTimeStampFraction,
      playedSeconds: jumpToNextTimeStamp,
      playing: true,
    }));
    setIsOptionSelected(true);
    setDisplayQuiz(false);

    //setting up options data to show
    if (quizOption.nextstep) {
      if (onboarding) {
        console.log(
          "nextoptions to show:",
          onboardingObject[quizOption.nextstep]
        );
        setDynamicQuizOptions(onboardingObject[quizOption.nextstep]);
      } else {
        setDynamicQuizOptions(tryAgainObject[quizOption.nextstep]);
      }

      setIsOptionSelected(false);
    }

    // setting up for next trigger timestamp for when to show options

    if (quizOption.nexttrigger) {
      const nextTimeStampInt = convertTimeStringToInt(quizOption.nexttrigger);
      setDisplayQuizTimeInt(nextTimeStampInt);
    } else {
      if (quizOption.nextstep === "tryAgain") {
        const nextTimeStampInt = convertTimeStringToInt(tryAgainQPStringTime);
        setDisplayQuizTimeInt(nextTimeStampInt);
      }
    }

    // if user selects try again
    if (quizOption.text === tryAgainObject.tryAgain[0].text) {
      initialQuizData();
      setIsOptionSelected(false);
    }

    if (onboarding) {
      setOnboardingData(quizOption.text);
      setCurrentStepOnboarding(quizOption.nextstep);
    }
  };
  const setOnboardingData = (text, hardCodedStep = "none") => {
    let objToReturn = {
      [hardCodedStep === "none" ? currentStepOnboarding : hardCodedStep]: text,
    };
    const notSignedUpObject = {
      ...userNotSignedUp,
      ...objToReturn,
    };
    console.log("notSignedUpObject " + title, notSignedUpObject, objToReturn);
    authService.settingDataWithoutSigningUp(objToReturn);
    setUserNotSignedUp(authService.getUserNotSignedUpData(null));
  };

  const handleNotSignedUpVideoWatch = () => {
    const _completedLectures =
      authService.getOnboardingData("completedlectures");
    if (_completedLectures) {
      if (!_completedLectures.includes(_id)) {
        setOnboardingData([..._completedLectures, _id], "completedlectures");
        setCompletedLectures(_completedLectures);
      }
      setOnboardingData(_id, "lastwatchedid");
      setLastWatchedId(_id);
    } else {
      setOnboardingData([_id], "completedlectures");
      setCompletedLectures(_completedLectures);
      setOnboardingData(_id, "lastwatchedid");
      setLastWatchedId(_id);
    }
    setShowScrollIcon(true);
    setTimeout(() => {
      setShowScrollIcon(false);
    }, 6200);
  };
  const handlePostVideoComplete = async () => {
    try {
      await lectureService
        .postLastWatched(currentLectureId, courseData._id)
        .then((res) => {
          console.log("POSTLASTWATCHDE", res);
          if (res.data.streakdata !== undefined) {
            setUserProgress((prevProgress) => ({
              ...prevProgress,
              streaks: res.data?.streakdata.data.streakNumber,
            }));
            if (res.data.streakupdate) {
              Mixpanel.track("Won Streak");
              setModal({
                ...modal,
                show: true,
                title: "You just won a Streak! 🔥",
                description:
                  "Congratulations! Streaks show your consistency, which is important for learning any skill. You will earn rewards, and this will soon be displayed in your profile. One video a day!",
                CustomComponent: Stats,
                props: {
                  textPrimary: true,
                  streaksOnly: true,
                },
              });
            }
          }
          setShowScrollIcon(true);
        });
      lectureService.postStudentProgress(currentLectureId).then((res) => {
        console.log("postStudentProcess", res, {
          ...setUserProgress,
          completedlecturecount: res.data.completedlecturecount,
        });
        setUserProgress((prevProgress) => ({
          ...prevProgress,
          completedlecturecount: res.data.completedlecturecount,
        }));

        courseService.generateCertificate(courseData._id).then((res) => {
          // console.log("res2", res);
          authService.setCompletedPercentage();
        });

        setTimeout(() => {
          setShowScrollIcon(false);
        }, 6200);
      });
      // console.log("RES AFTeR POST VIDEO", res);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnded = () => {
    onboarding ? initialOnboardingData() : initialQuizData();

    Mixpanel.track("Video completed", {
      type: "Tik-tok video",
      title,
    });

    if (!user || !user?.success) {
      handleNotSignedUpVideoWatch();
    } else {
      handlePostVideoComplete();
    }

    setIsOptionSelected(false);
    setShowReplayIcon(true);
    if (index === playlistLength - 1) {
      // completedSfx();
      setPlaylistCompleted(true);
    } else {
      setShowReplayIcon(true);
    }
  };
  const handleStarted = () => {
    setShowReplayIcon(false);
  };
  const { playing, volume, muted, loop, played, playbackRate } = playerState;
  // console.log(id);
  return (
    <div className="video" id={_id}>
      {modal.show || onboarding ? (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          CustomComponent={modal.CustomComponent ?? false}
          props={modal.props ?? false}
        />
      ) : null}

      {/* {playlistCompleted && (
        <Confetti
          active={playlistCompleted}
          config={config}
          style={{ zIndex: "2" }}
        />
      )} */}

      <div
        className="video_click"
        id={_id + "-video-click"}
        onClick={(e) => {
          setShowPlayPauseIcon(true);
          handlePlayPause(e);
          setTimeout(() => {
            setShowPlayPauseIcon(false);
          }, 500);
        }}
      >
        {note.active && <Note note={note} handleCloseNote={handleCloseNote} />}
        {/* {contentDrawer.active && (
          <ContentDrawer
            contentDrawer={contentDrawer}
            handleCloseContentDrawer={handleCloseContentDrawer}
            videos={videos}
            courseData={courseData}
            scrollToVideo={scrollToVideo}
          />
        )} */}

        <ReactPlayer
          id={_id + "-video-click"}
          ref={videoRef}
          className="video__player"
          url={url}
          playing={playing && !(user && user.isContentDrawerOpen)}
          width={"100%"}
          height={"100%"}
          playsinline={true}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          // onReady={() => console.log("onReady")}
          onStart={handleStarted}
          onPlay={handlePlay}
          onPause={handlePause}
          // onBuffer={() => setBuffer(true)}
          // onPlaybackRateChange={handleOnPlaybackRateChange}
          // onSeek={(e) => console.log("onSeek", e)}
          onEnded={handleEnded}
          // onError={(e) => console.log("onError", e)}
          onProgress={(e) => handleProgress(e, displayQuizTimeInt)}
          onDuration={(e) => setDuration(e)}
        />
        {!playing && !modal.show
          ? showPlayPauseIcon && (
              <MdOutlinePause className="qr-icons play-icon" />
            )
          : showPlayPauseIcon &&
            !modal.show && <MdPlayArrow className="qr-icons play-icon" />}

        {!playing && !modal.show && playerState.playedSeconds == 0 ? (
          <MdPlayArrow className="qr-icons play-icon" />
        ) : null}

        {!modal.show && showReplayIcon && (
          <MdReplay className="qr-icons play-icon" />
        )}
        {/* {playing ? (
          <MdOutlinePause className="qr-icons play-icon transparent" />
        ) : (
          <MdPlayArrow className="qr-icons play-icon transparent" />
        )} */}
      </div>
      {!modal.show && showScrollIcon && (
        <MdTouchApp className="qr-icons scroll-icon" />
      )}

      {!onboarding && (
        <input
          className="videofooter"
          type="range"
          style={{ backgroundSize: `${played * 100}%` }}
          // top: footerPosition && footerPosition.top
          min="0"
          max="100"
          step="any"
          value={played * 100}
          onChange={(e) => {
            handleSkipTo(e);
          }}
        />
      )}

      {countDown && onboarding && (
        <div className={`videoContainer videoContainer_skip`}>
          <div className="videoFooter">
            <button
              className="signup-cta bg-dark text-white p-2"
              onClick={() => Mixpanel.track("Tried to Skip Onboarding")}
            >
              Skip {countDown > 0 ? `in ${countDown}` : ""}
            </button>
          </div>
        </div>
      )}
      {!playing ? (
        <>
          <VideoFooter
            title={title}
            description={description}
            displayQuiz={displayQuiz}
            quizOptions={hasQuiz ? dynamicQuizOptions : []}
            tryAgainQPStringTime={tryAgainQPStringTime}
            jumpToTryAgainStringTime={jumpToTryAgainStringTime}
            jumptoContinueStringTime={jumptoContinueStringTime}
            handleQuizOptionSelect={hasQuiz ? handleQuizOptionSelect : null}
            isOptionSelected={isOptionSelected}
            hasQuiz={hasQuiz ?? false}
            onboarding={onboarding}
            countDown={countDown}
          />
        </>
      ) : null}

      <VideoSidebar
        muted={muted}
        handleMuteUnmute={handleMuteUnmute}
        playing={playing}
        handleTakeNote={handleTakeNote}
        handleOpenContentDrawer={handleOpenContentDrawer}
        videos={videos}
        completedLecturesCount={completedLecturesCount}
        setModal={setModal}
        modal={modal}
        played={played}
        onboarding={onboarding}
      />
    </div>
  );
}

export default Video;

// {pauseVideo === parseInt(playerState.progress) ? (
//   <>
//     <VideoFooter
//       channel={channel}
//       description={description}
//       song={song}
//     />
//     {/**/}
//     <VideoSidebar quizOptions={quizOptions} videoRef={videoRef} />
//   </>
// ) : null}

// {!playerState.isPlaying ? (
//   <>
//     <VideoFooter channel={channel} description={description} />
//     <VideoSidebar quizOptions={quizOptions} videoRef={videoRef} />
//   </>
// ) : null}
