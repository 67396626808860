import { Line, Circle } from "rc-progress";
import { useLocation, useHistory } from "react-router-dom";

import { useEffect, useState, useContext } from "react";
import "./Header.css";
import courseService from "actions/services/course.service";
import streakService from "actions/services/streak.service";
import tblService from "actions/services/tbl.service";
import { UserContext } from "context/UserProvider";
import authService from "actions/services/auth.service";
import { useQueryClient, useQuery, useMutation } from "react-query";
import ZPLoading from "pages/Watch/components/ZPLoading";

import SoloStreakScore from "component/myProfile/SoloStreakScore";
import WalletBalance from "pages/Wallet/WalletBalance";

function Stats({
  showLine,
  playlistLength = 0,
  textPrimary,
  setModal,
  modal,
  streaksOnly,
  teamStreaks,
  customStyles = null,
}) {
  const location = useLocation();
  const history = useHistory();
  const queries = new URLSearchParams(location.search);
  const [onboarding, setOnboarding] = useState(queries.get("onboarding"));
  const { user, setUser, userNotSignedUp, userProgress, setUserProgress } =
    useContext(UserContext);

  const queryClient = useQueryClient();
  const team = queryClient.getQueryData("getTeam");

  const {
    isLoading: isTeamStreaksLoading,
    isError: isTeamStreaksError,
    error: teamStreaksError,
    data: teamStreaksData,
  } = useQuery(
    "teamStreaksData",
    () => authService.getTeamStreakData(team?.teamData?._id),
    {
      enabled: !!team?.teamData?._id,
    }
  );
  const {
    isLoading: isSoloStreaksLoading,
    isError: isSoloStreaksError,
    error: soloStreaksError,
    data: soloStreaksData,
  } = useQuery("soloStreaksData", () => authService.getStreakData(), {
    enabled: !!(user && user?.success),
  });

  const getCompletedCoursesCount = () => {
    let count;
    if (user && user.success) {
      count = userProgress && userProgress.completedlecturecount;
    } else {
      count = userNotSignedUp.completedlectures.length;
    }
    return count;
  };

  const calculatePercentage = () => {
    let numerator;
    if (onboarding) {
      return 0;
    } else {
      numerator = getCompletedCoursesCount();
    }

    return (numerator / playlistLength) * 100;
  };

  if (isTeamStreaksLoading || isSoloStreaksLoading) {
    <ZPLoading />;
  }
  if (isTeamStreaksError || isSoloStreaksError) {
    console.log("teamStreaksError", teamStreaksError);
    console.log("soloStreaksError", soloStreaksError);
  }
  return (
    <div
      className={`header-items ${!showLine ? "centre-items" : ""}`}
      style={customStyles}
    >
      {showLine && (
        <div className="course-progress">
          <Line
            strokeWidth={10}
            trailWidth={10}
            percent={calculatePercentage()}
            strokeColor="#8556f8"
          />
        </div>
      )}

      <WalletBalance
        simple
        toolTipPlacement="left"
        handleClick={() =>
          setModal({
            ...modal,
            show: true,
            title: "Z-Coin",
            description:
              "Signup to start earning Z-Coin, a virtual currency to help you buy courses worth thousands.",
            CustomComponent: null,
            props: null,
          })
        }
      />

      <SoloStreakScore
        simple
        toolTipPlacement="left"
        handleClick={() =>
          setModal({
            ...modal,
            show: true,
            title: "Streaks",
            description:
              "Signup to start earning streaks, a rewards system to help with job placements and earning gifts.",
            CustomComponent: null,
            props: null,
          })
        }
      />

      {/* {!streaksOnly && !(user && user.success) ? (
        <div
          className="circle"
          onClick={() => {
            try {
              setModal({
                ...modal,
                show: true,
                title: "XP",
                description:
                  "Still in development, your XP will be populated in the DB. So keep watching the videos to keep earning XP. This will be used to indicate your experience level which will help with placing you in internships or jobs.",
                CustomComponent: null,
                props: null,
              });
            } catch (e) {}
          }}
        >
          <Circle
            strokeWidth={4}
            trailWidth={4}
            percent={50}
            strokeColor="#8556f8"
          />
          <div className="score">XP</div>
        </div>
      ) : null} */}
    </div>
  );
}

export default Stats;

// old streak:
{
  /* <div
        className="circle"
        onClick={() => {
          setModal({
            ...modal,
            show: true,
            title: "Streaks",
            description:
              "Each day that you learn on Zaio, you earn a streak. Streaks indicate consistency which is one of the skills that shows success of a learner. You will earn rewards & this will be shown on your profile when referred for jobs/internships. It will also open up rewards for you such as free mentorship, consulting, discounted courses, goodies and more.",
            CustomComponent: null,
            props: null,
          });
        }}
      >
        <Circle
          strokeWidth={4}
          trailWidth={4}
          percent={100}
          strokeColor="#8556f8"
        />
        <div className={`score ${textPrimary ? "text-primary" : ""}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <span role="img" aria-label="emoji">
              {teamStreaksData?.data && teamStreaks
                ? teamStreaksData?.data?.streakNumber
                : null}{" "}
              {!teamStreaks && soloStreaksData?.data?.streakNumber} 🔥
            </span>
            {teamStreaks && <span style={{ fontSize: "8px" }}>Team</span>}
          </div>
        </div>
      </div> */
}
