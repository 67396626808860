import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ZCoin from "components/ZCoin/ZCoin";
import WalletBalance from "pages/Wallet/WalletBalance";

import "../../pages/Course/Course.css";
import { useQueryClient, useMutation } from "react-query";
import walletService from "actions/services/wallet.service";
import ZPLoading from "pages/Watch/components/ZPLoading";
import Error from "components/Error/Error";

function UnlockWithZCoin({
  mutationParams,
  mutationFn,
  price,
  btnText = "Unlock with",
  successText = "Successfully Unlocked! Click here to continue",
  redirectFn = undefined,
}) {
  const history = useHistory();

  const [canAfford, setCanAfford] = useState(true);
  const [mutationRes, setMutationRes] = useState(null);
  const queryClient = useQueryClient();

  const purchaseMutation = useMutation(() => mutationFn(...mutationParams), {
    enabled: price,
    onSuccess: (data) => {
      setMutationRes(data);
      queryClient.invalidateQueries("walletBalance");
    },
  });

  const handleBuyWithZCoin = () => {
    purchaseMutation.mutate();
  };

  const handleSuccess = () => {
    if (redirectFn) {
      redirectFn(mutationRes);
      return;
    }
  };

  const getWalletBalance = (balance) => {
    setCanAfford(balance > price);
  };

  const displayButton = () => {
    if (purchaseMutation?.data?.success === true) {
      return (
        <button
          className={`custom-btn success-btn w-100`}
          onClick={handleSuccess}
        >
          {successText}
        </button>
      );
    }
    if (canAfford) {
      return (
        <button
          className={`custom-btn success-btn w-100`}
          onClick={handleBuyWithZCoin}
        >
          <span>{btnText}</span>
          <ZCoin
            number={price}
            toolTipPlacement="bottom"
            handleZCoinClick={() => {}}
          />
        </button>
      );
    } else {
      return (
        <button
          className={`custom-btn danger-btn w-100`}
          onClick={() => history.push(`/wallet`)}
        >
          <ZCoin
            number={price}
            toolTipPlacement="bottom"
            handleClick={() => {}}
          />
          <span>needed. Earn more. </span>
        </button>
      );
    }
  };

  if (purchaseMutation.isLoading) return <ZPLoading />;
  if (purchaseMutation.isError) {
    return (
      <div className="course-modal">
        <Error
          message={
            typeof purchaseMutation.error === "string"
              ? purchaseMutation.error
              : JSON.stringify(purchaseMutation.error)
          }
        />
      </div>
    );
  }
  if (purchaseMutation?.data?.success === false) {
    return (
      <div className="course-modal text-center">
        <p>{purchaseMutation?.data?.message}</p>
      </div>
    );
  }
  return (
    <div className="course-modal">
      <WalletBalance getWalletBalance={getWalletBalance} />

      <div className="w-100 text-center mt-4">
        {!canAfford && !purchaseMutation?.data?.success && (
          <small className="badge danger-btn mb-3">Insufficient balance.</small>
        )}

        {displayButton()}
      </div>
    </div>
  );
}

export default UnlockWithZCoin;
