import React, { Component } from "react";
import LogoNav from "assets/svg/Logonav.svg";
import { Constants } from "Constants";
import "./navbar.css";
import logo from "assets/img/zaio-logos/zaio-logo.png";
import { Link } from "react-router-dom";

class Navbar extends Component {
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <nav className="Nav">
        <div className="NavbarItems">
          <Link to="/" className="navbar-logo">
            <img src={LogoNav} alt="React Logo" />
          </Link>
          <div className="menu-icon" onClick={this.handleClick}>
            <i
              className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
          <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
            {/* {this.props.username && <li>
            Welcome! {this.props.username}
          </li>} */}
            {Constants?.[
              !this.props.loggedin ? "loggedout" : "loggedin"
            ].MenuItems.map((item, index) => {
              return (
                
                  <Link className={item.cName} to={item.url}>
                    {item.title}
                  </Link>
                
              );
            })}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;