import { create } from "zustand";

const QUIZ_QUESTION_COMPLETE = {
  selectedOption: null,
  isSolutionCorrect: null, // can be null, true or false
  bgColor: "",
  buttonDisplay: "Submit",
  resultMessage: null,
};
const OVERALL_QUIZ_PROPS = {
  quizCompleted: false,
  quizProgress: 0,
  quizNum: 0,
  resultsData: null,
  quizIndex: 0,
  currentQuestion: null,
  correctAnswerCount: 0,
  resultDecimal: 0,
  accAnswers: [],
};
const useQuizStore = create((set) => ({
  ...QUIZ_QUESTION_COMPLETE,
  ...OVERALL_QUIZ_PROPS,
  quizData: null,
  setQuizData: (quizData) => set(() => ({ quizData })),
  setQuizCompleted: (status) => set((state) => ({ quizCompleted: status })),
  setSelectedOption: (option) => set({ selectedOption: option }),
  handleSolutionSubmission: (solution, explanation, quizCompleted = false) =>
    set((state) => {
      // if(quizCompleted) {

      // }
      const accAnswers = [...state.accAnswers, state.selectedOption];
      const isSolutionCorrect = state.selectedOption.answer === solution;
      const quizNum = state.quizNum + 1;
      const quizProgress = quizNum / state.quizData.questions.length;
      const bgColor = isSolutionCorrect ? "bg-green-300" : "bg-red-300";
      const buttonDisplay = isSolutionCorrect ? "Next" : "Got it!";
      const resultMessage = isSolutionCorrect
        ? { title: "Correct Answer", subTitle: explanation }
        : { title: "Wrong Answer", subTitle: explanation };
      const correctAnswerCount = isSolutionCorrect
        ? state.correctAnswerCount + 1
        : state.correctAnswerCount;
      const resultDecimal =
        correctAnswerCount / state.quizData.questions.length;
      return {
        isSolutionCorrect,
        quizNum,
        bgColor,
        resultMessage,
        buttonDisplay,
        quizProgress,
        correctAnswerCount,
        resultDecimal,
        accAnswers,
      };
    }),
  setResultsData: (resultsData) => set(() => ({ resultsData })),
  setQuizIndex: (quizIndex) => set(() => ({ quizIndex })),
  setCurrentQuestion: (currentQuestion) => set(() => ({ currentQuestion })),
  resetQuiz: () => set((state) => ({ ...QUIZ_QUESTION_COMPLETE })),
  restartQuiz: () =>
    set((state) => ({
      ...QUIZ_QUESTION_COMPLETE,
      ...OVERALL_QUIZ_PROPS,
    })),
}));

export default useQuizStore;
