import styled from "styled-components";

export const CodeDrawerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: ${(props) => (props.show ? "block" : "none")};
  /* z-index: ${(props) => (props.show ? 2 : 0)}; */
  position: absolute;
  top: 0;
  right: 0;
  background: yellow;
  color: #fff;
  font-size: 1.5em;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.7) 20%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.7) 80%,
    rgba(0, 0, 0, 1) 100%
  );

  .back-to-video {
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 9999;
    padding: 10px 20px;
    background: #000;
    border-radius: 5px;

    div {
      display: flex;
      font-size: 20px;
      align-items: center;
      opacity: 0.3;
      transition: all 0.2s ease-out;
      overflow: hidden;

      span {
        display: none;
        margin-right: -50px;
        opacity: 0;
        transition: all ease-out 0.2s;
      }

      &:hover {
        opacity: 1;
        transform: translateX(+10px);

        span {
          margin-right: 0;
          opacity: 1;
          display: block;
        }
      }

      svg {
        font-size: 35px;
        margin-left: 5px;
      }
    }
  }
`;