import { useRef, useState } from "react";
import "./ide.scss";
// import "./main";
import Editor from "@monaco-editor/react";
import { emmetHTML, emmetCSS } from "emmet-monaco-es";

import "luna-object-viewer/luna-object-viewer.css";
import "luna-data-grid/luna-data-grid.css";
import "luna-dom-viewer/luna-dom-viewer.css";
import "luna-console/luna-console.css";
import IframeCode from "./PreviewTerminal";

import {
  BsLayoutSplit,
  BsLayoutThreeColumns,
  BsArrowsFullscreen,
} from "react-icons/bs";

import FileExplorer from "pages/AdminPortal/AdminIde/RenderFolderFiles";
import { ideConstants, playgroundTypes, playgroundTypesUI } from "Constants";
import {
  formatJSON,
  getDefaultFilesBasedOnType,
  loadIcon,
  parseValue,
} from "./ideUtils";
import MultiFilesIframeCode from "./PreviewTerminalMultiFiles";

function Ide({
  onCtrlS,
  setShowMulFileJSONEditor,
  setProjectFiles,
  projectFiles,
  currentFile,
  setCurrentFile,
  currentFileRef,
  isUserSide,
}) {
  const monacoRef = useRef(null);
  const editorRef = useRef(null);

  //maximized, minimize, closed, default
  const [previewMode, setPreviewMode] = useState("default");

  const handleSelectProjectFile = (file) => {
    if (currentFile === file) return;

    setCurrentFile(
      projectFiles?.type === playgroundTypes.MULTI_FILES ? file?.key : file
    );
    console.log(currentFileRef.current, "HELLO");
  };

  const overrideSave = () => {
    document.addEventListener(
      "keydown",
      function (e) {
        if (
          e.keyCode === 83 &&
          (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
        ) {
          e.preventDefault();
          onCtrlS();
        }
      },
      false
    );
  };
  const handleEditorValidation = (markers) => {
    markers.forEach((marker) => console.log("onValidate:", marker.message));
  };

  const handleEditorWillMount = (monaco) => {
    monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
  };
  const handleEditorDidMount = (editor, monaco) => {
    emmetHTML(monaco);
    emmetCSS(monaco);

    monacoRef.current = monaco;
    editorRef.current = editor; // save editor instance
  };

  const handleOnChange = (value) => {
    setProjectFiles((prev) => ({
      ...prev,
      files: {
        ...prev?.files,
        [currentFileRef.current]: {
          ...prev?.files[currentFileRef.current],
          value,
        },
      },
    }));
  };

  overrideSave();

  return (
    <div className="ide-container">
      <div
        className={`code-editor-container code-editor-container-${previewMode}`}
      >
        <div className={`ide-sidebar ide-sidebar-${previewMode}`}>
          <p className="text-white">EXPLORER</p>
          {!isUserSide && (
            <div className="text-white">
              <p className="h5 m-0 text-white">Project Type:</p>
              <div className="text-dark">
                <select
                  onChange={(e) => {
                    const selectedType = e?.target?.value;
                    const files = getDefaultFilesBasedOnType(selectedType);
                    setProjectFiles((prev) => ({
                      ...prev,
                      files,
                      type: selectedType,
                    }));
                    setCurrentFile(files["index.html"].name);
                  }}
                  className="p-1 px-2 mb-4 w-100"
                >
                  {Object.values(playgroundTypes)?.map((type) => (
                    <option selected={projectFiles?.type === type} value={type}>
                      {playgroundTypesUI?.[type]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {projectFiles?.type === playgroundTypes.MULTI_FILES && (
            <>
              {!isUserSide && (
                <button
                  className="mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm"
                  onClick={() => {
                    setShowMulFileJSONEditor(true);
                    localStorage.setItem(
                      ideConstants.JSON_FOLDER_STUCTURE,
                      formatJSON(projectFiles?.files)
                    );
                  }}
                >
                  Edit JSON
                </button>
              )}
              <div>
                {
                  <FileExplorer
                    currentFile={currentFile}
                    handleSelectProjectFile={handleSelectProjectFile}
                    projectFiles={parseValue(projectFiles?.files)}
                  />
                }
              </div>
            </>
          )}

          {projectFiles?.type === playgroundTypes.SINGLE_FILES &&
            Object.keys(projectFiles.files).map((projectFile, index) => (
              <button
                key={index}
                onClick={() => handleSelectProjectFile(projectFile)}
                className={`project-file-btn ${
                  projectFile === currentFile && "selected"
                }`}
              >
                {loadIcon(projectFile)}
                <code>{projectFile}</code>
              </button>
            ))}
        </div>

        <div className={`code-editor code-editor-${previewMode}`}>
          <div className="code-editor__header">
            <div></div>
          </div>

          <div className="monaco-editor-container">
            <div className="monaco-editor-header">
              <code>{currentFile}</code>
            </div>
            {/* {console.log("projectFiles", projectFiles)}
            {console.log("currentFile", currentFile)}
            {console.log("projectFiles[currentFile]", projectFiles[currentFile])} */}

            <Editor
              className="monaco-editor"
              height="100%"
              width="100%"
              theme="vs-dark"
              path={projectFiles?.files[currentFile]?.name}
              language={projectFiles?.files[currentFile]?.language}
              defaultLanguage={projectFiles?.files[currentFile]?.language}
              value={projectFiles?.files[currentFile]?.value}
              onValidate={handleEditorValidation}
              beforeMount={handleEditorWillMount}
              onMount={handleEditorDidMount}
              onChange={handleOnChange}
              options={{
                automaticLayout: true,
                fontLigatures: true,
                fontSize: 16,
                minimap: {
                  enabled: false,
                },
                renderLineHighlight: "line",
                renderLineHighlightOnlyWhenFocus: false,
                cursorSmoothCaretAnimation: true,
                cursorBlinking: "smooth",
              }}
            />
          </div>
        </div>
      </div>
      <div className={`browser-window browser-window-${previewMode}`}>
        <div className="preview-container-header">
          <div className="monaco-editor-header">
            <code className="text-primary">{"Preview"}</code>
          </div>
          <div className="tools">
            <BsLayoutSplit
              className="preview-container-header-round"
              onClick={() => setPreviewMode("split")}
            />
            <BsLayoutThreeColumns
              className="preview-container-header-round"
              onClick={() => setPreviewMode("default")}
            />
            <BsArrowsFullscreen
              className="preview-container-header-round"
              onClick={() => setPreviewMode("maximized")}
            />
          </div>
        </div>
        <iframe
          srcDoc={
            projectFiles?.type === playgroundTypes.SINGLE_FILES
              ? IframeCode(
                  projectFiles?.files["index.html"]?.value,
                  projectFiles?.files["style.css"]?.value,
                  projectFiles?.files["script.js"]?.value
                )
              : MultiFilesIframeCode(projectFiles?.files)
          }
        />
      </div>
    </div>
  );
}

export default Ide;
