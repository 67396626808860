import styled from "styled-components";

export const StandyByInfo = styled.div`
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 50px;
  transition: all 0.5s ease-out;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .zpsection {
    margin: auto 0;
    padding-top: 100px;
    padding-left: 100px;

    .zph3 {
      color: #fff;
      font-size: 1.1em;
      margin-bottom: 5px;
    }

    .zph1 {
      font-weight: bold;
      font-size: 3.5em;
      color: #ffffff;
      margin: 10px 0;
    }

    .zph2 {
      color: #ffffff;
      font-size: 20px;
      margin-top: -5px;
      font-weight: bold;
    }
  }

  .zpfooter {
    margin-top: auto;
    margin-bottom: 50px;
    margin-right: 50px;
    margin-left: auto;
    text-transform: uppercase;
    color: #ffffff;
  }
`;