import React, { useEffect, useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import classes from "./TutorSupport.module.css";

import { ProgressBar, Dropdown } from "react-bootstrap";
import DashboardNavbar from "../navbar/Navbar.jsx";

import tutor1 from "assets/img/dashboard/tutor1.svg";
import tutor2 from "assets/img/dashboard/tutor2.svg";
import tutor3 from "assets/img/dashboard/tutor3.svg";
import tutor4 from "assets/img/dashboard/tutor4.svg";
import dropdownImage from "assets/img/dashboard/dropdownImage.svg";

import learningpathService from "actions/services/learningpath.service";

import TutorSupportTable from "./TutorSupportTable.jsx";

import TutorService from "actions/services/tutor-service";
import { UserContext } from "context/UserProvider";
import { Mixpanel } from "Utils/Mixpanel";
import ReactPlayer from "react-player";
import TutorSupportProfile from "./TutorSupportProfile";

const About = () => {
  const [tableData, setTableData] = useState([]);
  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [tableLenght, setTableLength] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tutorDetails, setTutorDetails] = useState([])
  const { user, setUser } = useContext(UserContext);

  const loadData = async () => {
    setIsLoading(true);
    const res = await TutorService.getUserSupportSession();
    const tutorResponse = await TutorService.getTutorForUser(user?.data?.userid);
    if(tutorResponse?.success){
      setTutorDetails([tutorResponse.data])
    }
    let resMinutes = await TutorService.getUserAccountDetails();
    if (res.data.length > 0) {
      setTableLength(true);
    }
    setTableData(res.data);
    setIsLoading(false);
    setMinutesRemaining(resMinutes.data.totalminutes);
  };

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/tutor-support",
      "Page name": "Tutor Support page",
    });
    loadData();
  }, []);

  return (
    <>
      <div className={classes.container1}>
        <DashboardNavbar minutesRemaining={minutesRemaining} />
        <div className={classes.title}>
          <h1>Tutor support</h1>
        </div>
        {/* <div className={classes.cardBlock}>
          <div className={classes.cards}>
            <div className={classes.cardBody} style={{ paddingTop: 17 }}>
              <div className={classes.cardTop}>
                <div style={{ width: '48%' }}>
                  <h4 className={classes.cardHeading}>How does tutor support work?</h4>
                </div>
                <div className={classes.helpWrapper} style={{ position: 'relative', width: '48%' }}>
                  <p className='howcanhelp'>How can we help?</p>
                </div>
              </div>

              <div className={classes.cardText}>
                <span className={classes.left} style={{ color: '#A9A9A9', width: '60%' }}>
                  Find out how does tutor support work here
                </span>
                <span className={classes.right}>
                  <a
                    href={`https://wa.me/+27763793540/?text=Hi there, My name is ${user.username} and I wanted to connect from my dashboard to get some advice. My email is ${user.email}`}
                    target="_blank"
                    className="text-white"
                  >
                    Find a tutor
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className={classes.cards}>
            <div className={classes.cardBody} style={{ paddingTop: 17 }}>
              <div className={classes.cardTop}>
                <div style={{ width: '48%' }}>
                  <h4 className={classes.cardHeading}>Tutor minutes remaining</h4>
                </div>
                <div className={classes.wrapper} style={{ position: 'relative', width: '48%' }}>
                  <div className={classes.paidCardProgressBar}>
                    <p>
                      <span style={{ fontWeight: '600', fontSize: 36 }}>{minutesRemaining}</span>{' '}
                      minutes left
                    </p>
                  </div>
                </div>
              </div>

              <div className={classes.cardText}>
                <span className={classes.left} style={{ color: '#A9A9A9' }}>
                  Amount of time spent
                </span>
                <span
                  className={classes.right}
                  onClick={() =>
                    alert(
                      'To top up minutes, it costs R3.5/min. You can transfer the amount to the Zaio account with your name as the reference. Connect with a tutor (minutes will not be tracked for this)'
                    )
                  }
                >
                  Top up
                </span>
              </div>
            </div>
          </div>

          <div className={classes.cards}>
            <div className={classes.cardBody} style={{ paddingTop: 17 }}>
              <div className={classes.cardTop}>
                <div style={{ width: '48%' }}>
                  <h4 className={classes.cardHeading}>
                    {' '}
                    Get support, advice and tips from the best.
                  </h4>
                </div>
                <div className={classes.wrapper} style={{ position: 'relative', width: '48%' }}>
                  <img
                    src={tutor1}
                    alt=""
                    className={`${classes.icon} ${classes.tutor1}`}
                    // style={{ position: 'absolute', right: 93, top: 0 }}
                  />
                  <img
                    src={tutor2}
                    alt=""
                    className={`${classes.icon} ${classes.tutor2}`}
                    // style={{ position: 'absolute', right: 62, top: 0 }}
                  />
                  <img
                    src={tutor3}
                    alt=""
                    className={`${classes.icon} ${classes.tutor3}`}
                    // style={{ position: 'absolute', right: 31, top: 0 }}
                  />
                  <img
                    src={tutor4}
                    alt=""
                    className={`${classes.icon} ${classes.tutor4}`}
                    // style={{ position: 'absolute', right: 0, top: 0 }}
                  />
                </div>
              </div>

              <div className={classes.cardText}>
             //<span className={classes.left} style={{ color: '#A9A9A9', width: '60%' }}>
               //   You can choose a tutor from out top selected professionals
               // </span> 
                <span className={classes.right}>
                  <a
                    href={`https://wa.me/+27763793540/?text=Hi there, My name is ${user.username} and I wanted to connect from my dashboard to get some advice. My email is ${user.email}`}
                    target="_blank"
                    className="text-white"
                  >
                    Start a chat
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div style={{ margin: '4rem 0 1.5rem', display: 'flex', justifyContent: 'space-between' }}>
          <h1 className={classes.tableHeading}>Statistics of calls</h1>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-button-dark-example1"
              variant="secondary"
              className={classes.tutorDropdown}
              style={{ boxShadow: 'none', border: 0, backgroundColor: '#0d1e3a' }}
            >
              <img src={dropdownImage} alt="dropdown" style={{ marginRight: 5 }} />
              <span style={{}}>Filter</span>
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              <Dropdown.Item href="#/action-1" active>
                Today
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">This week</Dropdown.Item>
              <Dropdown.Item href="#/action-3">This month</Dropdown.Item>
              <Dropdown.Item href="#/action-4">This year</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
        <div className="d-flex flex-1 w-full justify-evenly">
        <div className="my-4">
          <TutorSupportTable
            tableData={tableData}
            tableLenght={tableLenght}
            isLoading={isLoading}
            user={user}
          />
        </div>
        { (tutorDetails.length && tutorDetails[0]?.company_username) ? (<div className="my-4">
          <TutorSupportProfile 
            tutorDetails={tutorDetails}
          />
        </div>) : <></>}
        </div>
        <div
          className="row"
          style={{
            height: 600,
            objectFit: "fill",
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/2JplW9UK5Wg?si=8QyA5DG8I44K9b9o"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </>
  );
};
export default About;
