import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const API_URL_BACKENDQ = process.env.REACT_APP_BACKENDQ_URL;

class LectureService {
  postStudentProgress(lectureid) {
    return axios
      .post(
        API_URL + "/studentprogress",
        {
          lectureid,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        // console.log('postStudentprogress',res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return rej.response;
      });
  }

  getTotalChallenge() {
    return axios
      .get(API_URL + "/studentprogress/gettotalassignment", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('postStudentprogress',res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return rej.response;
      });
  }

  getTotalAssignment() {
    return axios
      .get(API_URL + "/assignment/profile/totalassignments", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('postStudentprogress',res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return rej.response;
      });
  }

  postLastWatched(lectureid, courseid) {
    console.log("POST LAST WATCHED API", lectureid, courseid);
    return axios
      .post(
        API_URL + "/course/" + courseid + "/resume",
        {
          lectureid,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log("then", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return rej.response;
      });
  }

  getLastWatched(courseid) {
    return axios
      .get(API_URL + "/course/" + courseid + "/resume", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }
  postLecture(data) {
    console.log("DATA", data);
    return axios
      .post(API_URL + "/lecture", data, {
        headers: authHeader(),
      })
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }
  deleteLecture(id) {
    return axios
      .delete(API_URL + "/lecture/" + id, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response)
        return "error";
      });
  }

  getLectureById(id) {
    return axios
      .get(API_URL + "/lecture/" + id, {
        headers: authHeader(),
      })
      .then((res) => {
        return res.data.data;
      });
  }
  getPublicLectureById(id) {
    return axios
      .get(API_URL + "/lecture/getlecture/" + id, {
        headers: authHeader(),
      })
      .then((res) => {
        return res.data.data;
      });
  }

  fetchChallengeBetweenVideo(challengeid) {
    console.log("studentprogress/challengeprogress/:challengeid", challengeid);

    return axios
      .get(API_URL + "/studentprogress/challengeprogress/" + challengeid, {
        headers: authHeader(),
      })
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response)
        return "error";
      });
  }

  fetchChallengeBetweenVideoPublic(challengeid, userid) {
    console.log(
      "studentprogress/challengeprogress_public/:challengeid/:userid",
      challengeid
    );

    return axios
      .get(
        API_URL +
          "/studentprogress/challengeprogress_public/" +
          challengeid +
          "/" +
          userid,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response)
        return "error";
      });
  }

  getLecture(id) {
    // console.log(id)
    return axios
      .get(API_URL + "/lecture/" + id, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response)
        return "error";
      });
  }
  putLecture(data) {
    console.log("PUT LECTURE", data);
    return axios
      .put(API_URL + "/lecture/" + data._id, data, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  //ADMIN ENDPOINTS:
  saveCodePush(data) {
    //   {

    //     "html":"This is html test",
    //     "css":"this is css test",
    //     "js":"this is js test",
    //     "pause":true,
    //     "lectureid":"605373a685432a00210efd4e",
    //     "timestamp":53,
    //     "edit":false

    // }
    console.log(API_URL + "/lecture/addplayground/", data);
    return axios
      .post(API_URL + "/lecture/addplayground/", data, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then addplayground", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch addplayground", rej.response);
        return "error";
      });
  }

  getCodePushes(lectureid) {
    console.log("/playground/" + lectureid);
    return axios
      .get(API_URL + "/playground/" + lectureid, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then playgrounds", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  getFrontendFilesCode(lectureid) {
    console.log("/playground/" + lectureid);
    return axios
      .get(API_URL + "/playground/frontend/" + lectureid, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then playgrounds", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  getFrontendFilesCodeAssignment(id) {
    return axios
      .get(API_URL + "/playground/frontend-assignment/" + id, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then playgrounds", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  deletePlayground(lectureid, playgroundid) {
    console.log("/playground/deleteplayground" + lectureid, playgroundid);
    return axios
      .post(
        API_URL + "/lecture/deleteplayground/",
        {
          lectureid,
          playgroundid,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log("then playgrounds", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  submitChallengeBetweenVideo(lectureid, files) {
    console.log("/studentprogress/makechallengesubmission", lectureid, files);
    return axios
      .post(
        API_URL + "/studentprogress/makechallengesubmission",
        {
          lectureid,
          files,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  submitChallengeBackendQuestion(lectureId, backendChallengeId, file) {
    console.log(
      "/lecture/challengebackendsubmission",
      lectureId,
      backendChallengeId,
      file
    );

    const formData = new FormData();
    formData.append("file", file);
    formData.append("lectureId", lectureId);
    formData.append("backendChallengeId", backendChallengeId);

    return axios
      .post(
        API_URL_BACKENDQ + "/lecture/challengebackendsubmission",
        formData,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  submitChallengeFrontendQuestion(lectureId, frontendChallengeId, files) {
    console.log(
      "/lecture/challengefrontendsubmission",
      lectureId,
      frontendChallengeId,
      files
    );

    const payload = {
      lectureId,
      frontendChallengeId,
      html: files["index.html"].value,
      css: files["style.css"].value,
      js: files["script.js"].value,
    };

    return axios
      .post(
        API_URL_BACKENDQ + "/lecture/challengefrontendsubmission",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  runTestCasesWithoutSubmission(
    assignmentid,
    assignmentcodingquestionid,
    files
  ) {
    console.log(
      "/assignment/testassignment",
      assignmentid,
      assignmentcodingquestionid,
      files
    );
    return axios
      .post(
        API_URL + "/assignment/testassignment",
        {
          assignmentid,
          assignmentcodingquestionid,
          files,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  testAssignmentBackendQuestion(
    assignmentid,
    assignmentbackendquestionid,
    file
  ) {
    console.log(
      "/assignment/testassignmentbackend",
      assignmentid,
      assignmentbackendquestionid,
      file
    );
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assignmentid", assignmentid);
    formData.append("assignmentbackendquestionid", assignmentbackendquestionid);
    return axios
      .post(API_URL_BACKENDQ + "/assignment/testassignmentbackend", formData, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  testAssignmentFrontendQuestion(
    assignmentid,
    assignmentfrontendquestionid,
    files
  ) {
    console.log(
      "/assignment/testassignmentfrontend",
      assignmentid,
      assignmentfrontendquestionid,
      files
    );
    const payload = {
      assignmentid,
      assignmentfrontendquestionid,
      html: files["index.html"].value,
      css: files["style.css"].value,
      js: files["script.js"].value,
    };
    return axios
      .post(API_URL_BACKENDQ + "/assignment/testassignmentfrontend", payload, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  testChallengeBackendQuestion(lectureId, backendChallengeId, file) {
    console.log(
      "/lecture/testchallengebackend",
      lectureId,
      backendChallengeId,
      file
    );
    const formData = new FormData();
    formData.append("file", file);
    formData.append("lectureId", lectureId);
    formData.append("backendChallengeId", backendChallengeId);
    return axios
      .post(API_URL_BACKENDQ + "/lecture/testchallengebackend", formData, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  testChallengeFrontendQuestion(lectureId, frontendChallengeId, files) {
    console.log(
      "/lecture/testchallengefrontend",
      lectureId,
      frontendChallengeId,
      files
    );
    const payload = {
      lectureId,
      frontendChallengeId,
      html: files["index.html"].value,
      css: files["style.css"].value,
      js: files["script.js"].value,
    };
    return axios
      .post(API_URL_BACKENDQ + "/lecture/testchallengefrontend", payload, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  saveCodeChatGPTFeedback(challengeid, filedata) {
    const objToSend = {
      challengeid,
      filedata,
    };
    console.log("/studentprogress/savechallengeprogress", objToSend);
    return axios
      .post(
        API_URL + "/studentprogress/savechallengeprogress",
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  saveFrontendCodeChatGPTFeedback(lectureId, filedata) {
    const objToSend = {
      lectureId,
      html: filedata["index.html"].value,
      css: filedata["style.css"].value,
      js: filedata["script.js"].value,
    };
    console.log("/studentprogress/savefrontendchallengeprogress", objToSend);
    return axios
      .post(
        API_URL + "/studentprogress/savefrontendchallengeprogress",
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  saveAssignmentFrontendCodeChatGPTFeedback(
    assignmentfrontendquestionid,
    filedata
  ) {
    const objToSend = {
      questionid: assignmentfrontendquestionid,
      html: filedata["index.html"].value,
      css: filedata["style.css"].value,
      js: filedata["script.js"].value,
    };
    console.log("/assignment/frontend/save", objToSend);
    return axios
      .post(
        API_URL + "/assignment/frontend/save",
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  submitAssignmentMCQ(assignmentmcqid, assignmentid, questionid, answer) {
    console.log("/assignment/assignmentmcqsubmission", {
      assignmentmcqid,
      assignmentid,
      questionid,
      answer,
    });
    return axios
      .post(
        API_URL + "/assignment/assignmentmcqsubmission",
        {
          assignmentmcqid,
          assignmentid,
          questionid,
          answer,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  submitAssignmentCodeQuestion(assignmentid, submission) {
    console.log("/assignment/assignmentcodingsubmission", {
      assignmentid,
      submission,
    });
    return axios
      .post(
        API_URL + "/assignment/assignmentcodingsubmission",
        {
          assignmentid,
          submission,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  submitAssignmentBackendQuestion(
    assignmentid,
    assignmentbackendquestionid,
    submission
  ) {
    console.log("/assignment/assignmentbackendsubmission", {
      assignmentid,
      assignmentbackendquestionid,
      submission,
    });
    const formData = new FormData();
    formData.append("file", submission);
    formData.append("assignmentid", assignmentid);
    formData.append("assignmentbackendquestionid", assignmentbackendquestionid);
    return axios
      .post(
        API_URL_BACKENDQ + "/assignment/assignmentbackendsubmission",
        formData,
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  submitAssignmentFrontendQuestion(
    assignmentid,
    assignmentfrontendquestionid,
    files
  ) {
    console.log("/assignment/assignmentfrontendsubmission", {
      assignmentid,
      assignmentfrontendquestionid,
      files,
    });
    const payload = {
      assignmentid,
      assignmentfrontendquestionid,
      html: files["index.html"].value,
      css: files["style.css"].value,
      js: files["script.js"].value,
    };
    return axios
      .post(
        API_URL_BACKENDQ + "/assignment/assignmentfrontendsubmission",
        payload,
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  getChallengeSubmissions(lectureid) {
    return axios
      .get(API_URL + "/studentprogress/getchallengesubmissions/" + lectureid, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  getChallengeSubmissionsPublic(lectureid, userid) {
    return axios
      .get(
        API_URL +
          "/studentprogress/getchallengesubmissions_public/" +
          lectureid +
          "/" +
          userid,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  getBackendChallengeSubmissions(lectureid, userid) {
    return axios
      .get(
        API_URL +
          "/studentprogress/" +
          `${
            !userid
              ? `getchallengesubmissions/${lectureid}?backend=true`
              : `getchallengesubmissions_public/${lectureid}/${userid}?backend=true`
          }`,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  getFrontendChallengeSubmissions(lectureid, userid) {
    return axios
      .get(
        API_URL +
          "/studentprogress/" +
          `${
            !userid
              ? `getchallengesubmissions/${lectureid}?frontend=true`
              : `getchallengesubmissions_public/${lectureid}/${userid}?frontend=true`
          }`,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  createAssignment(assignmentpreview, duration, assignmentname) {
    console.log("/assignment/", assignmentpreview, duration, assignmentname);
    return axios
      .post(
        API_URL + "/assignment",
        {
          assignmentpreview,
          duration,
          assignmentname,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log("then", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  getAssignment() {
    return axios
      .get(API_URL + "/assignment", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  getUserAssignments() {
    return axios
      .get(API_URL + "/assignment", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  getAdminAssignmentList() {
    return axios
      .get(API_URL + "/assignment/allassignments", {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  refreshAssignmentProgress(assignmentId) {
    return axios
      .post(
        API_URL + "/assignment/refreshassignmentprogress",
        {
          assignmentId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        return "Error occured while refreshing questions! Try again.";
      });
  }

  getAssignmentById(assignmentId) {
    console.log("/assignment/" + assignmentId);
    return axios
      .get(API_URL + "/assignment/" + assignmentId, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return "error";
      });
  }

  getAssQuestionById(questionid, type) {
    console.log("/assignment/getquestiondataforuser", questionid, type);
    return axios
      .post(
        API_URL + "/assignment/getquestiondataforuser",
        {
          questionid,
          type,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log("then", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  getAssProgressByQId(assignmentid) {
    console.log("/assignment/assignmentprogress/" + assignmentid);
    return axios
      .get(API_URL + "/assignment/assignmentprogress/" + assignmentid, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  saveAssignmentCodeChallengeProgress(questionid, filedata) {
    const objToSend = {
      questionid,
      filedata,
    };
    console.log("/assignment/save", objToSend);
    return axios
      .post(
        API_URL + "/assignment/save",
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  // ASSIGNMENTS
  compileCode(language, stdin, files, lectureId) {
    const objToSend = {
      language,
      stdin,
      files,
      lectureid: lectureId,
    };
    console.log("/studentprogress/runcode", objToSend);
    return axios
      .post(
        API_URL + "/studentprogress/runcode",
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  createAssignmentMCQSeriesAsAdmin(
    assignmentid,
    questions,
    marks,
    questionname
  ) {
    const objToSend = {
      type: "mcq",
      assignmentid,
      questions,
      marks,
      questionname,
    };
    console.log("/assignment/addassignmentquestion", objToSend);
    return axios
      .post(
        API_URL + "/assignment/addassignmentquestion",
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  // deleteAssignmentMCQSeries(assignmentcodingid, assignmentid) {
  //   const objToSend = {
  //     assignmentid,
  //   };
  //   console.log(
  //     "/assignment/deleteassignmentmcqquestion/" + assignmentcodingid,
  //     objToSend
  //   );
  //   return axios
  //     .post(
  //       API_URL +
  //         "/assignment/deleteassignmentmcqquestion/" +
  //         assignmentcodingid,
  //       {
  //         ...objToSend,
  //       },
  //       { headers: authHeader() }
  //     )
  //     .then((res) => {
  //       console.log("then submit", res);
  //       return res.data;
  //     })
  //     .catch((rej) => {
  //       console.log("catch", rej.response);
  //       return "error";
  //     });
  // }

  createAssignmentChallengeAsAdmin(
    questionfile,
    assignmentid,
    testcases,
    marks,
    questionname,
    language,
    sourcefiles
  ) {
    const objToSend = {
      questionfile,
      type: "coding",
      assignmentid,
      testcases,
      marks,
      questionname,
      language,
      sourcefiles,
    };
    console.log("/assignment/addassignmentquestion", objToSend);
    return axios
      .post(
        API_URL + "/assignment/addassignmentquestion",
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  createBackendChallengeAsAdmin(
    questionfile,
    assignmentid,
    testcases,
    marks,
    questionname,
    language,
    startercode,
    timeout
  ) {
    const formData = new FormData();
    formData.append("questionfile", questionfile);
    formData.append("type", "backend");
    formData.append("assignmentid", assignmentid);
    formData.append("testcases", JSON.stringify(testcases));
    formData.append("marks", marks);
    formData.append("questionname", questionname);
    formData.append("language", language);
    formData.append("startercode", startercode);
    formData.append("timeout", timeout);
    // const objToSend = {
    //   questionfile,
    //   type: "backend",
    //   assignmentid,
    //   testcases,
    //   marks,
    //   questionname,
    //   language,
    //   startercode,
    // };
    // console.log("/assignment/addassignmentquestion", objToSend);
    return axios
      .post(API_URL + "/assignment/addassignmentquestion", formData, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  updateAssignmentBackendQ(
    assignmentcodingid,
    assignmentid,
    testcases,
    questionfile,
    marks,
    questionname,
    language,
    startercode,
    timeout = 30
  ) {
    const formData = new FormData();
    formData.append("questionfile", questionfile);
    formData.append("type", "backend");
    formData.append("assignmentid", assignmentid);
    formData.append("testcases", JSON.stringify(testcases));
    formData.append("marks", marks);
    formData.append("questionname", questionname);
    formData.append("language", language);
    formData.append("startercode", startercode);
    formData.append("timeout", timeout);

    return axios
      .post(
        API_URL +
          "/assignment/updateassignmentbackendquestion/" +
          assignmentcodingid,
        formData,
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  deleteAssignmentBackendQ(assignmentcodingid, assignmentid) {
    const objToSend = {
      assignmentid,
    };
    return axios
      .post(
        API_URL +
          "/assignment/deleteassignmentbackendquestion/" +
          assignmentcodingid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  createFrontendChallengeAsAdmin(
    questionfile,
    assignmentid,
    // testcases,
    marks,
    questionname,
    language,
    startercode,
    frontendFilesCode
    // timeout
  ) {
    const formData = new FormData();
    formData.append("questionfile", questionfile);
    formData.append("type", "frontend");
    formData.append("assignmentid", assignmentid);
    // formData.append("testcases", JSON.stringify(testcases));
    formData.append("marks", marks);
    formData.append("questionname", questionname);
    formData.append("language", language);
    formData.append("startercode", startercode);
    formData.append("html", frontendFilesCode["index.html"].value);
    formData.append("css", frontendFilesCode["style.css"].value);
    formData.append("js", frontendFilesCode["script.js"].value);
    // formData.append("timeout", timeout);
    // const objToSend = {
    //   questionfile,
    //   type: "backend",
    //   assignmentid,
    //   testcases,
    //   marks,
    //   questionname,
    //   language,
    //   startercode,
    // };
    // console.log("/assignment/addassignmentquestion", objToSend);
    return axios
      .post(API_URL + "/assignment/addassignmentquestion", formData, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  createDataScienceChallengeAsAdmin(
    // questionfile,
    assignmentid,
    // testcases,
    marks,
    questionname,
    language,
    startercode,
    courseId
    // frontendFilesCode
    // timeout
  ) {
    const formData = new FormData();
    // formData.append("questionfile", questionfile);
    formData.append("type", "datascience");
    formData.append("assignmentid", assignmentid);
    // formData.append("testcases", JSON.stringify(testcases));
    formData.append("marks", marks);
    formData.append("questionname", questionname);
    formData.append("language", language);
    formData.append("startercode", startercode);
    formData.append("courseId", courseId);
    // formData.append("timeout", timeout);
    // const objToSend = {
    //   questionfile,
    //   type: "backend",
    //   assignmentid,
    //   testcases,
    //   marks,
    //   questionname,
    //   language,
    //   startercode,
    // };
    // console.log("/assignment/addassignmentquestion", objToSend);
    return axios
      .post(API_URL + "/assignment/addassignmentquestion", formData, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  updateAssignmentDataScienceQ(
    assignmentcodingid,
    assignmentid,
    // testcases,
    // questionfile,
    marks,
    questionname,
    language,
    startercode,
    // frontendFilesCode
    // timeout = 30,
    courseId
  ) {
    const formData = new FormData();
    // formData.append("questionfile", questionfile);
    formData.append("type", "datascience");
    formData.append("assignmentid", assignmentid);
    // formData.append("testcases", JSON.stringify(testcases));
    formData.append("marks", marks);
    formData.append("questionname", questionname);
    formData.append("language", language);
    formData.append("startercode", startercode);
    // formData.append("timeout", timeout);
    formData.append("courseId", courseId);

    return axios
      .post(
        API_URL +
          "/assignment/updateassignmentdsquestion/" +
          assignmentcodingid,
        formData,
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  deleteAssignmentDataScienceQ(assignmentcodingid, assignmentid) {
    const objToSend = {
      assignmentid,
    };
    return axios
      .post(
        API_URL +
          "/assignment/deleteassignmentdsquestion/" +
          assignmentcodingid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  updateAssignmentFrontendQ(
    assignmentcodingid,
    assignmentid,
    // testcases,
    questionfile,
    marks,
    questionname,
    language,
    startercode,
    frontendFilesCode
    // timeout = 30
  ) {
    const formData = new FormData();
    formData.append("questionfile", questionfile);
    formData.append("type", "frontend");
    formData.append("assignmentid", assignmentid);
    // formData.append("testcases", JSON.stringify(testcases));
    formData.append("marks", marks);
    formData.append("questionname", questionname);
    formData.append("language", language);
    formData.append("startercode", startercode);
    formData.append("html", frontendFilesCode["index.html"].value);
    formData.append("css", frontendFilesCode["style.css"].value);
    formData.append("js", frontendFilesCode["script.js"].value);
    // formData.append("timeout", timeout);

    return axios
      .post(
        API_URL +
          "/assignment/updateassignmentfrontendquestion/" +
          assignmentcodingid,
        formData,
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  deleteAssignmentFrontendQ(assignmentcodingid, assignmentid) {
    const objToSend = {
      assignmentid,
    };
    return axios
      .post(
        API_URL +
          "/assignment/deleteassignmentfrontendquestion/" +
          assignmentcodingid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  addAssignmentMCQ(assignmentmcqid, assignmentid, questionid, question) {
    const objToSend = {
      assignmentid,
      questionid,
      question,
    };
    console.log(
      "/assignment/addassignmentmcqquestion/64121b695ee6054acf81c148",
      objToSend
    );
    return axios
      .post(
        API_URL + "/assignment/addassignmentmcqquestion/" + assignmentmcqid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  updateAssignmentMCQ(
    assignmentmcqid,
    assignmentid,
    questionid,
    question,
    marks
  ) {
    const objToSend = {
      assignmentid,
      questionid,
      question,
      marks,
    };
    console.log(
      "/assignment/updateassignmentmcqquestion/:assignmentmcqid",
      objToSend
    );
    return axios
      .post(
        API_URL + "/assignment/updateassignmentmcqquestion/" + assignmentmcqid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  deleteAssignmentMCQ(assignmentmcqid, assignmentid, questionid) {
    const objToSend = {
      assignmentid,
      questionid,
    };
    console.log(
      "/assignment/deleteassignmentmcqquestion/:assignmentmcqid",
      objToSend
    );
    return axios
      .post(
        API_URL + "/assignment/deleteassignmentmcqquestion/" + assignmentmcqid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  updateAssignmentCodingQ(
    assignmentcodingid,
    assignmentid,
    testcases,
    questionfile,
    marks,
    questionname,
    language,
    sourcefiles
  ) {
    const objToSend = {
      assignmentid,
      testcases,
      questionfile,
      marks,
      questionname,
      language,
      sourcefiles,
    };
    console.log("/assignment/updateassignmentcodingquestion/", objToSend);
    return axios
      .post(
        API_URL +
          "/assignment/updateassignmentcodingquestion/" +
          assignmentcodingid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  deleteAssignmentCodingQ(assignmentcodingid, assignmentid) {
    const objToSend = {
      assignmentid,
    };
    console.log(
      "/assignment/deleteassignmentcodingquestion/" + assignmentcodingid,
      objToSend
    );
    return axios
      .post(
        API_URL +
          "/assignment/deleteassignmentcodingquestion/" +
          assignmentcodingid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  // CHALLENGES
  adminUploadChallenge(
    courseid,
    courseunitid,
    lecturename,
    duration,
    testcases,
    afterlecture,
    questionfile,
    sourcefiles,
    language,
    releaseassignment
  ) {
    const objToSend = {
      type: "challenge",
      courseid,
      courseunitid,
      lecturename,
      lectureno: 0,
      duration: parseInt(duration),
      testcases,
      afterlecture,
      questionfile,
      sourcefiles,
      language,
      releaseassignment,
    };
    console.log("  /lecture/", objToSend);
    return axios
      .post(
        API_URL + "/lecture",
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  adminEditCourseChallenge(
    challengeid,
    duration,
    testcases,
    questionfile,
    afterlecture,
    sourcefiles
  ) {
    const objToSend = {
      duration: parseInt(duration),
      testcases,
      afterlecture,
      questionfile,
      sourcefiles,
    };
    console.log("  /lecture/challenge", objToSend);
    return axios
      .put(
        API_URL + "/lecture/challenge/" + challengeid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  // BACKEND CHALLENGES
  adminUploadBackendChallenge(
    courseid,
    courseunitid,
    lecturename,
    duration,
    testcases,
    afterlecture,
    questionfile,
    startercode,
    language,
    releaseassignment,
    timeout
  ) {
    const formdata = new FormData();
    formdata.append("type", "backendchallenge");
    formdata.append("courseid", courseid);
    formdata.append("courseunitid", courseunitid);
    formdata.append("lecturename", lecturename);
    formdata.append("lectureno", 0);
    formdata.append("duration", parseInt(duration));
    formdata.append("testcases", JSON.stringify(testcases));
    formdata.append("afterlecture", afterlecture);
    formdata.append("questionfile", questionfile);
    formdata.append("startercode", startercode);
    formdata.append("language", language);
    formdata.append("releaseassignment", releaseassignment);
    formdata.append("timeout", timeout);

    return axios
      .post(API_URL + "/lecture", formdata, { headers: authHeader() })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  adminEditCourseBackendChallenge(
    backendchallengeid,
    duration,
    testcases,
    questionfile,
    afterlecture,
    startercode,
    timeout
  ) {
    const formdata = new FormData();
    formdata.append("duration", parseInt(duration));
    formdata.append("testcases", JSON.stringify(testcases));
    formdata.append("afterlecture", afterlecture);
    formdata.append("questionfile", questionfile);
    formdata.append("startercode", startercode);
    formdata.append("timeout", timeout);

    return axios
      .put(
        API_URL + "/lecture/backendchallenge/" + backendchallengeid,
        formdata,
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        console.log(rej);
        return "error";
      });
  }

  adminUploadDataScienceChallenge(
    courseid,
    courseunitid,
    lecturename,
    duration,
    // testcases,
    afterlecture,
    startercode,
    language,
    releaseassignment,
    marks,
    // timeout,
    courseId
  ) {
    const formdata = new FormData();
    formdata.append("type", "datascience");
    formdata.append("courseid", courseid);
    formdata.append("courseunitid", courseunitid);
    formdata.append("lecturename", lecturename);
    formdata.append("lectureno", 0);
    formdata.append("duration", parseInt(duration));
    // formdata.append("testcases", JSON.stringify(testcases));
    formdata.append("afterlecture", afterlecture);
    // formdata.append("questionfile", questionfile);
    formdata.append("startercode", startercode);
    formdata.append("language", language);
    formdata.append("releaseassignment", releaseassignment);
    formdata.append("marks", marks);
    formdata.append("courseId", courseId);

    return axios
      .post(API_URL + "/lecture", formdata, { headers: authHeader() })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  adminEditCourseDataScienceChallenge(
    datasciencechallengeid,
    duration,
    // testcases,
    afterlecture,
    startercode,
    // timeout,
    marks,
    courseId
  ) {
    const formdata = new FormData();
    formdata.append("duration", parseInt(duration));
    // formdata.append("testcases", JSON.stringify(testcases));
    formdata.append("afterlecture", afterlecture);
    // formdata.append("questionfile", questionfile);
    formdata.append("startercode", startercode);
    formdata.append("marks", marks);
    formdata.append("courseId", courseId);

    // formdata.append("html", frontendFilesCode['index.html'].value);
    // formdata.append("css", frontendFilesCode['style.css'].value);
    // formdata.append("js", frontendFilesCode['script.js'].value);
    // formdata.append("timeout", timeout);
    // console.log({duration,questionfile,afterlecture,startercode});

    return axios
      .put(
        API_URL + "/lecture/datasciencechallenge/" + datasciencechallengeid,
        formdata,
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        console.log(rej);
        return "error";
      });
  }

  //frontend challenge
  adminUploadFrontendChallenge(
    courseid,
    courseunitid,
    lecturename,
    duration,
    // testcases,
    afterlecture,
    questionfile,
    startercode,
    language,
    releaseassignment,
    frontendFilesCode
    // timeout
  ) {
    const formdata = new FormData();
    formdata.append("type", "frontendchallenge");
    formdata.append("courseid", courseid);
    formdata.append("courseunitid", courseunitid);
    formdata.append("lecturename", lecturename);
    formdata.append("lectureno", 0);
    formdata.append("duration", parseInt(duration));
    // formdata.append("testcases", JSON.stringify(testcases));
    formdata.append("afterlecture", afterlecture);
    formdata.append("questionfile", questionfile);
    formdata.append("startercode", startercode);
    formdata.append("language", language);
    formdata.append("releaseassignment", releaseassignment);
    formdata.append("html", frontendFilesCode["index.html"].value);
    formdata.append("css", frontendFilesCode["style.css"].value);
    formdata.append("js", frontendFilesCode["script.js"].value);
    // formdata.append("timeout", timeout);

    return axios
      .post(API_URL + "/lecture", formdata, { headers: authHeader() })
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }

  adminEditCourseFrontendChallenge(
    frontendchallengeid,
    duration,
    // testcases,
    questionfile,
    afterlecture,
    startercode,
    frontendFilesCode
    // timeout
  ) {
    const formdata = new FormData();
    formdata.append("duration", parseInt(duration));
    // formdata.append("testcases", JSON.stringify(testcases));
    formdata.append("afterlecture", afterlecture);
    formdata.append("questionfile", questionfile);
    formdata.append("startercode", startercode);
    formdata.append("html", frontendFilesCode["index.html"].value);
    formdata.append("css", frontendFilesCode["style.css"].value);
    formdata.append("js", frontendFilesCode["script.js"].value);
    // formdata.append("timeout", timeout);
    console.log({ duration, questionfile, afterlecture, startercode });
    return axios
      .put(
        API_URL + "/lecture/frontendchallenge/" + frontendchallengeid,
        formdata,
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        console.log(rej);
        return "error";
      });
  }
  // MCQS
  adminUploadMCQ(
    courseid,
    courseunitid,
    lecturename,
    duration,
    questions,
    afterlecture,
    releaseassignment
  ) {
    const objToSend = {
      type: "coursemcq",
      courseid,
      courseunitid,
      lecturename,
      lectureno: 0,
      duration: parseInt(duration),
      questions,
      afterlecture,
      releaseassignment,
    };
    console.log("  /lecture/", objToSend);
    return axios
      .post(
        API_URL + "/lecture",
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  submitCourseMCQ(coursemcqid, lectureid, result, answers) {
    console.log(
      "/studentprogress/makecoursemcqsubmission",
      coursemcqid,
      lectureid,
      result,
      answers
    );
    return axios
      .post(
        API_URL + "/studentprogress/makecoursemcqsubmission",
        {
          coursemcqid,
          lectureid,
          result,
          answers,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  createCourseMCQ(questionid, question) {
    const objToSend = {
      question,
    };
    console.log("/lecture/coursemcq/" + questionid, objToSend);
    return axios
      .post(
        API_URL + "/lecture/coursemcq/" + questionid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  updateCourseMCQ(mcqseriesid, questionid, question) {
    const objToSend = {
      questionid,
      question,
    };
    console.log("/lecture/coursemcq/" + mcqseriesid, objToSend);
    return axios
      .put(
        API_URL + "/lecture/coursemcq/" + mcqseriesid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
  deleteCourseMCQ(mcqseriesid, questionid) {
    const objToSend = {
      questionid,
    };
    console.log("/lecture/deletecoursemcq/" + mcqseriesid, objToSend);
    return axios
      .post(
        API_URL + "/lecture/deletecoursemcq/" + mcqseriesid,
        {
          ...objToSend,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        console.log("then submit", res);
        return res.data;
      })
      .catch((rej) => {
        console.log("catch", rej.response);
        return "error";
      });
  }
}

export default new LectureService();
