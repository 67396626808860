import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { FiCheck, FiX } from "react-icons/fi";
import InactiveVideoInfo from "./components/InactiveVideoInfo/InactiveVideoInfo";
import { Container } from "./ZaioVideoPlayer.styled";
import ZPLoading from "./components/ZPLoading";
import VideoPreload from "./components/VideoPreLoad/VideoPreload";
import CodeDrawer from "./components/CodeDrawer/CodeDrawer";
import VideoPlayerControls from "./components/VideoPlayerControls";
import useVideoStore from "zustand-store/videoPlayerStore";
import Compiler from "components/Compiler/Compiler";
import MCQ from "components/MCQ";
import Backend from "components/Backend/Backend";
import Frontend from "components/FrontendChallenge/Frontend";
import { videoEventsTracking } from "Utils/WebEngage";
import DSCourseEditor from "pages/ds-course-editor/ds-course-editor";

export default function Watch({
  title = false,
  subTitle = false,
  titleMedia = false,
  extraInfoMedia = false,
  unitTitle = false,

  backAction = undefined,
  currentLectureId = null,
  currentCourseId = null,
  currentUnitId = null,

  fullPlayer = true,
  backButton = undefined,

  src,
  autoPlay = false,

  alertMessage = { display: false, message: "" }, //display: booleans, message: string

  onVideoPause = undefined,
  onCanPlay = undefined,
  onTimeUpdate = undefined,
  onEnded = undefined,
  onErrorVideo = undefined,
  onNextClick = undefined,
  onCrossClick = undefined,
  startPosition = 0,

  dataNext = {},
  unitList = [],
  completedLectures = [],
  listRedirect = undefined,
  courseTitle = "Course Title",
  qualities = [],
  onChangeQuality = [],
  playbackRateEnable = true,
  overlayEnabled = false,
  autoControllCloseEnabled = false,

  // Styles
  primaryColor = "#8437f9",
  secundaryColor = "#f9c80c",
  fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",

  playbackRateOptions = ["0.25", "0.5", "0.75", "Normal", "1.25", "1.5", "2"],
  playbackRateStart = 1,

  //codepush
  codePushSrc = "https://codesandbox.io/embed/coding-on-zaio-m2e9pw?fontsize=14&hidenavigation=1&theme=dark",
  projectFiles = null,

  handleSupportButton = undefined,

  setProjectFiles,
  refreshCourseData,
  updateFrontendChallCompletion,
}) {
  const videoComponent = useRef(null);
  const timerRef = useRef(null);
  const timerBuffer = useRef(null);
  const playerElement = useRef(null);
  const listReproduction = useRef(null);

  const [videoReady, setVideoReady] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [end, setEnd] = useState(false);
  const [controlBackEnd, setControlBackEnd] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [volume, setVolume] = useState(100);
  const [muted, setMuted] = useState(false);
  const [error, setError] = useState(false);
  const [waitingBuffer, setWaitingBuffer] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(playbackRateStart);
  const [started, setStarted] = useState(false);

  const [showControlVolume, setShowControlVolume] = useState(false);
  const [showQuality, setShowQuality] = useState(false);
  const [showDataNext, setShowDataNext] = useState(false);
  const [showPlaybackRate, setShowPlaybackRate] = useState(false);
  const [showReproductionList, setShowReproductionList] = useState(false);

  const [codeMode, setCodeMode] = useState(false);
  const [sublistMode, setSublistMode] = useState(false);
  const [list, setList] = useState(unitList);
  const [listTitle, setListTitle] = useState(courseTitle);
  const [displayAlert, setDisplayAlert] = useState(false);

  const codeModeRef = useRef(false);

  const videoMode = useVideoStore((state) => state.videoMode);
  const challenge = useVideoStore((state) => state.challenge);
  const setVideoMode = useVideoStore((state) => state.setVideoMode);
  const setShowControlsContainer = useVideoStore(
    (state) => state.setShowControlsContainer
  );

  const memoizedProgress = useMemo(() => progress, [progress]);

  const secondsToHms = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);
    let seconds = s.toString();

    if (s < 10) {
      seconds = `0${s}`;
    }

    if (h) {
      return `${h}:${m}:${seconds}`;
    }

    return `${m}:${seconds}`;
  };

  const timeUpdate = (e) => {
    setShowInfo(false);
    setEnd(false);
    if (playing) {
      setPlaying(true);
    }

    if (waitingBuffer) {
      setWaitingBuffer(false);
    }

    if (timerBuffer.current) {
      clearTimeout(timerBuffer.current);
    }

    timerBuffer.current = setTimeout(() => setWaitingBuffer(true), 1000);

    let choseBuffer = 0;

    const target = e.target;

    const lenghtBuffer = target.buffered.length;
    let start = 0;
    let endBuffer = 0;
    const atualTime = target.currentTime;

    for (let i = 1; i <= lenghtBuffer; i++) {
      const startCheck = target.buffered.start(i - 1);
      const endCheck = target.buffered.end(i - 1);

      if (endCheck > atualTime && atualTime > startCheck) {
        choseBuffer = i;

        if (endCheck > endBuffer) {
          endBuffer = endCheck;
        }

        if (startCheck < start) {
          start = startCheck;
        }
      }
    }

    if (memoizedProgress > target.currentTime) {
      e.target.currentTime = memoizedProgress;
      setProgress(memoizedProgress);
    } else setProgress(target.currentTime);
    console.log({ memoizedProgress, t: target.currentTime });

    if (onTimeUpdate) {
      onTimeUpdate(e, pauseVideo, toggleMode, duration);
    }

    // setActualBuffer({
    //   index: choseBuffer,
    //   start,
    //   endBuffer,
    // });
    // setProgress(target.currentTime);
  };

  const goToPosition = (position) => {
    if (videoComponent.current) {
      videoComponent.current.currentTime = position;
      setProgress(position);
    }
  };

  const playVideo = () => {
    if (videoComponent.current) {
      setPlaying(true);
      // setProgress(custom + 1.25);
      if (videoComponent.current.paused) {
        videoComponent.current.play();
        return;
      }

      // if (onVideoPlay) {
      //   onVideoPlay(videoComponent);
      // }
    }
  };
  const pauseVideo = () => {
    if (videoComponent.current) {
      setPlaying(false);

      if (!videoComponent.current.paused) {
        videoComponent.current.pause();
        return;
      }

      // if (onVideoPause) {
      //   onVideoPause(videoComponent);
      // }
    }
  };
  const play = useCallback(() => {
    if (videoComponent.current) {
      setPlaying(!playing);

      if (videoComponent.current.paused) {
        videoComponent.current.play();
        return;
      }

      videoEventsTracking("Video Paused", {
        duration: videoComponent.current.duration,
        courseTitle,
        titleMedia,
      });
      videoComponent.current.pause();
    }
  }, [playing]);

  const onEndedFunction = () => {
    if (videoComponent.current) {
      videoEventsTracking("Video Completed", {
        duration: videoComponent.current.duration,
        courseTitle,
        titleMedia,
      });
      if (
        +startPosition === +videoComponent.current.duration &&
        !controlBackEnd
      ) {
        setControlBackEnd(true);
        videoComponent.current.currentTime =
          videoComponent.current.duration - 30;
        if (autoPlay) {
          setPlaying(true);
          videoComponent.current.play();
        } else {
          setPlaying(false);
        }
      } else {
        setEnd(true);
        setPlaying(false);

        if (onEnded) {
          onEnded();
        }
      }
    }
  };

  const nextSeconds = (seconds) => {
    if (videoComponent.current) {
      const current = videoComponent.current.currentTime;
      const total = videoComponent.current.duration;

      if (current + seconds >= total - 2) {
        videoComponent.current.currentTime =
          videoComponent.current.duration - 1;
        setProgress(videoComponent.current.duration - 1);
        return;
      }

      videoComponent.current.currentTime += seconds;
      setProgress(videoComponent.current.currentTime + seconds);
    }
  };

  const previousSeconds = (seconds) => {
    if (videoComponent.current) {
      const current = videoComponent.current.currentTime;

      if (current - seconds <= 0) {
        videoComponent.current.currentTime = 0;
        setProgress(0);
        return;
      }

      videoComponent.current.currentTime -= seconds;
      setProgress(videoComponent.current.currentTime - seconds);
    }
  };

  const startVideo = useCallback(() => {
    setVideoReady(true);
    if (videoComponent.current) {
      videoEventsTracking("Video Started", {
        duration: videoComponent.current.duration,
        courseTitle,
        titleMedia,
      });
      try {
        setDuration(videoComponent.current.duration);
        setVideoReady(true);

        if (!started) {
          setStarted(true);
          setPlaying(false);

          if (autoPlay) {
            videoComponent.current.play();
            setPlaying(!videoComponent.current.paused);
          }
        }

        if (onCanPlay) {
          onCanPlay();
        }
      } catch (err) {
        setPlaying(false);
      }
    }
  }, []);

  const erroVideo = () => {
    if (onErrorVideo) {
      onErrorVideo();
    }
    setError("play error");
  };

  const setMutedAction = useCallback(
    (value) => {
      if (videoComponent.current) {
        setMuted(value);
        setShowControlVolume(false);
        videoComponent.current.muted = value;
      }
    },
    [muted, showControlVolume, volume]
  );

  const setVolumeAction = useCallback(
    (value) => {
      if (videoComponent.current) {
        setVolume(value);
        videoComponent.current.volume = value / 100;
      }
    },
    [volume]
  );

  const exitFullScreen = useCallback(() => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }

      setFullScreen(false);
    }
  }, [fullScreen]);

  const enterFullScreen = useCallback(() => {
    if (playerElement.current) {
      setShowInfo(true);
      if (playerElement.current.requestFullscreen) {
        playerElement.current.requestFullscreen();
        setFullScreen(true);
      }
    }
  }, [fullScreen, showInfo]);

  const chooseFullScreen = () => {
    if (playerElement.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        return;
      }

      setShowInfo(true);

      if (playerElement.current.requestFullscreen) {
        playerElement.current.requestFullscreen();
      }
      setFullScreen(true);
    }
  };

  const setStateFullScreen = () => {
    if (!document.fullscreenElement) {
      setFullScreen(false);
      return;
    }

    setFullScreen(true);
  };

  const controllScreenTimeOut = () => {
    if (!autoControllCloseEnabled) {
      setShowInfo(true);
      return;
    }

    setShowControls(false);
    if (!playing) {
      setShowInfo(true);
    }
  };

  const hoverScreen = () => {
    setShowControls(true);
    setShowInfo(false);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(controllScreenTimeOut, 500);
  };

  const getKeyBoardInteration = (e) => {
    //spacebar
    if (e.keyCode === 32 && videoComponent.current && !codeModeRef.current) {
      // console.log("from", codeModeRef);
      if (videoComponent.current.paused) {
        videoComponent.current.play();
        setPlaying(true);
        hoverScreen();
      } else {
        videoComponent.current.pause();
        setPlaying(false);
        hoverScreen();
      }
    }

    //arrow right
    if (e.keyCode === 39 && videoComponent.current && !codeModeRef.current) {
      nextSeconds(5);
      hoverScreen();
    }

    //arrow left
    if (e.keyCode === 37 && videoComponent.current && !codeModeRef.current) {
      previousSeconds(5);
      hoverScreen();
    }
  };

  const scrollToSelected = () => {
    const element = listReproduction.current;
    if (element) {
      const selected = element.getElementsByClassName("selected")[0];
      const position = selected?.offsetTop;
      const height = selected?.offsetHeight;
      if (!position || !height) return;
      element.scrollTop = position - height * 2;
    }
  };

  const onChangePlayBackRate = useCallback((value) => {
    if (videoComponent.current) {
      const speed = value === "Normal" ? 1 : +value;
      videoComponent.current.playbackRate = speed;
      setPlaybackRate(speed);
    }
  }, []);

  const toggleMode = (from) => {
    //from = code-switch or back-to-video
    if (from === "code-switch") {
      if (fullScreen) {
        exitFullScreen();
        return;
      }
      if (playing) pauseVideo();
    } else {
      setProgress(Math.ceil(memoizedProgress) + 1.25);
      playVideo();
    }
    codeModeRef.current = !codeMode;
    setCodeMode(!codeMode);
  };
  const memoizedToggleMode = useCallback(toggleMode, [codeMode]);

  const openSublist = (item) => {
    console.log("ITEM", item);
    setListTitle(item?.unitname);
    setList(item?.lecture);
    setSublistMode(true);
  };

  const goBackToMainList = useCallback(() => {
    setList(unitList);
    setListTitle(courseTitle);
    setSublistMode(false);
  }, [sublistMode]);

  useEffect(() => {
    if (showReproductionList) {
      scrollToSelected();
    }
  }, [showReproductionList]);

  useEffect(() => {
    if (src && videoComponent.current) {
      videoComponent.current.currentTime = startPosition;
      setProgress(0);
      setDuration(0);
      setVideoReady(false);
      setError(false);
      setShowReproductionList(false);
      setShowDataNext(false);
      // setActualBuffer({
      //   index: 0,
      //   start: 0,
      //   end: 0,
      //   endBuffer: 0,
      // });
      setPlaying(autoPlay);
      document.addEventListener("keydown", getKeyBoardInteration, false);
      playerElement.current &&
        playerElement.current.addEventListener(
          "fullscreenchange",
          setStateFullScreen,
          false
        );
    } else {
      // alert("No url given");
      setVideoReady(true);
      // setVideoMode("challenge");
    }

    // 👇️ remove the event listener when component unmounts
    return () => {
      document?.removeEventListener("keydown", getKeyBoardInteration);
      playerElement?.current?.removeEventListener(
        "fullscreenchange",
        setStateFullScreen
      );
    };
  }, [src]);

  // When changes happen in fullscreen document, teh state of fullscreen is changed
  useEffect(() => {
    setStateFullScreen();
  }, [document.fullscreenElement]);

  // dealing with the alerts
  useEffect(() => {
    if (alertMessage.display) {
      hoverScreen();
    }
  }, [alertMessage.display]);

  const memoizedSetShowControlVolume = useCallback(setShowControlVolume, [
    showControlVolume,
  ]);
  const memoizedPlaybackRateOptions = useMemo(
    () => playbackRateOptions,
    [playbackRateOptions]
  );
  const memoizedSetShowPlaybackRate = useCallback(setShowPlaybackRate, [
    showPlaybackRate,
  ]);
  const memoizedsetShowReproductionList = useCallback(setShowReproductionList, [
    showReproductionList,
  ]);

  const memoizedList = useMemo(() => list, [list]);

  // console.log("VIDEO PLAYER RENDERED")
  return (
    <div>
      {videoMode !== "frontendchallenge" && (
        <CodeDrawer
          codeMode={codeMode}
          fullPlayer={fullPlayer}
          codePushSrc={codePushSrc}
          projectFiles={projectFiles}
          toggleMode={memoizedToggleMode}
          setProjectFiles={setProjectFiles}
        />
      )}
      <Container
        onMouseMove={hoverScreen}
        ref={playerElement}
        onDoubleClick={chooseFullScreen}
        onClick={play}
        fullPlayer={fullPlayer}
        hideVideo={!!error}
        fontFamily={fontFamily}
        show={!codeMode}
      >
        {(videoReady === false ||
          (waitingBuffer === true && playing === true)) &&
          !error &&
          !end && <ZPLoading primaryColor={primaryColor} />}

        {!!overlayEnabled && (
          <InactiveVideoInfo
            title={title}
            subTitle={subTitle}
            unitTitle={unitTitle}
            showInfo={showInfo}
            videoReady={videoReady}
            playing={playing}
          />
        )}

        <VideoPreload
          videoReady={videoReady}
          error={error}
          title={title}
          subTitle={subTitle}
          onCrossClick={onCrossClick}
        />

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        {videoMode === "lecture" && (
          <video
            ref={videoComponent}
            src={src}
            controls={false}
            onCanPlay={() => startVideo()}
            onTimeUpdate={timeUpdate}
            onError={erroVideo}
            onEnded={onEndedFunction}
          />
        )}

        {videoMode === "challenge" && (
          <div className="video-player-compiler">
            <Compiler
              lectureId={currentLectureId}
              onNextClick={onNextClick}
              dataNext={dataNext}
              refreshCourseData={refreshCourseData}
            />
          </div>
        )}

        {videoMode === "backendchallenge" && (
          <div className="video-player-compiler">
            <Backend
              lectureId={currentLectureId}
              onNextClick={onNextClick}
              dataNext={dataNext}
              refreshCourseData={refreshCourseData}
            />
          </div>
        )}

        {videoMode === "frontendchallenge" && (
          <div className="video-player-compiler">
            <Frontend
              lectureId={currentLectureId}
              onNextClick={onNextClick}
              dataNext={dataNext}
              refreshCourseData={refreshCourseData}
              updateFrontendChallCompletion={updateFrontendChallCompletion}
            />
          </div>
        )}

        {videoMode === "coursemcq" && (
          <div className="video-player-compiler">
            <MCQ
              lectureId={currentLectureId}
              onNextClick={onNextClick}
              dataNext={dataNext}
              refreshCourseData={refreshCourseData}
            />
          </div>
        )}
        {videoMode === "datascience" && (
          <div className="video-player-compiler w-100">
            <DSCourseEditor
              lectureId={currentLectureId}
              onNextClick={onNextClick}
              dataNext={dataNext}
              refreshCourseData={refreshCourseData}
              updateFrontendChallCompletion={updateFrontendChallCompletion}
            />
          </div>
        )}

        <VideoPlayerControls
          showControls={showControls}
          videoReady={videoReady}
          error={error}
          primaryColor={primaryColor}
          progress={progress}
          duration={duration}
          // HeaderControls:
          backButton={backButton}
          fullScreen={fullScreen}
          alertMessage={alertMessage}
          toggleMode={memoizedToggleMode}
          exitFullScreen={exitFullScreen}
          backAction={backAction}
          // ProgressBar:
          showControlVolume={showControlVolume}
          // showQuality={showQuality}
          showDataNext={showDataNext}
          showReproductionList={showReproductionList}
          goToPosition={goToPosition}
          secondsToHms={secondsToHms}
          // BottomLeftControls:
          playing={playing}
          play={play}
          muted={muted}
          volume={volume}
          setShowControlVolume={memoizedSetShowControlVolume}
          setVolumeAction={setVolumeAction}
          setMutedAction={setMutedAction}
          titleMedia={titleMedia}
          extraInfoMedia={extraInfoMedia}
          // BottomRightControls:
          enterFullScreen={enterFullScreen}
          //speed:
          playbackRateEnable={playbackRateEnable}
          showPlaybackRate={showPlaybackRate}
          playbackRate={playbackRate}
          setShowPlaybackRate={memoizedSetShowPlaybackRate}
          playbackRateOptions={memoizedPlaybackRateOptions}
          onChangePlayBackRate={onChangePlayBackRate}
          // nextlesson
          onNextClick={onNextClick}
          dataNext={dataNext}
          setShowDataNext={setShowDataNext}
          //playlist
          setShowReproductionList={memoizedsetShowReproductionList}
          sublistMode={sublistMode}
          goBackToMainList={goBackToMainList}
          listTitle={listTitle}
          listReproduction={listReproduction}
          list={memoizedList}
          openSublist={openSublist}
          listRedirect={listRedirect}
          unitList={unitList}
          currentUnitId={currentUnitId}
          currentLectureId={currentLectureId}
          completedLectures={completedLectures}
          handleSupportButton={handleSupportButton}
        />
      </Container>
    </div>
  );
}
