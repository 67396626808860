import React, { useState, useEffect } from 'react'
import adminService from "actions/services/admin.service";
import getDemo from "actions/services/admin.service"
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import axios from "axios";
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';

import TutorService from 'actions/services/tutor-service';


function UserTable({ allData }) {
    const [loadTable, setLoadTable] = useState([]);
    const [filterTable, setFilterTable] = useState([]);
    const [show, setShow] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [tutorName, setTutorName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [minutesToppedUp, setMinutesToppedUp] = useState(0);

    const userSelected = (value) => {
        setTutorName(value)
    }

    const loadData = async () => {
        // setIsLoading(true);
        // const res = await getDemo.userTutorAccount();
        const getTotalMinutes = allData.data.map((item) => item.totalminutes - 10)
            .reduce((acc, comp) => acc + comp, 0);
        setMinutesToppedUp(getTotalMinutes);
        setLoadTable(allData.data);
        setFilterTable(allData.data);
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [allData])

    // useEffect(() => {
    //     setAllTutors(loadTable.map(item => item?.userid?.username));
    // }, [loadTable])

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleHistoryClose = () => setShowHistory(false);
    const handleHistoryShow = () => {
        setShowHistory(true);
    };


    useEffect(() => {
        if (tutorName) {
            const findd = loadTable.filter((_it) => _it?.userid?.username && (_it.userid.username).toLowerCase().includes(tutorName.toLowerCase()));
            console.log(findd, 'finddfindd')
            setFilterTable(findd)
        } else {
            loadData()
        }
    }, [tutorName])

    const handlePayment = (payment) => {
        handleShow();
        setPaymentData(payment);
    }

    const handleHistory = async (id) => {
        handleHistoryShow();

        const res = await TutorService.userSupportSessionForAdmin(id);
        console.log("res.data", res.data);
        setHistoryData(res.data);
    }

    console.log({ filterTable });

    return (
        <>
            {isLoading ? <h3 style={{ color: 'white' }}>Loading.....</h3> :
                <>
                    <p style={{ color: 'white' }}>
                        Stats for minutesToppedUp {minutesToppedUp} minutes
                    </p>
                    <div className="form-group">
                        <input onChange={(e) => setTutorName(e.target.value)} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Enter Tutor Name" />
                    </div>
                    <div className='container mt-5 bg-white' style={{ height: '500px', overflow: 'auto' }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">email</th>
                                    <th scope="col">phonenumber</th>
                                    <th scope="col">username</th>
                                    <th scope="col">tutorMinutes</th>
                                    <th scope="col">updatedAt</th>
                                    <th scope="col">Actions</th>
                                    <th scope="col">History</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterTable.length > 0 &&
                                    filterTable.map((item) => (
                                        <tr>
                                            <th style={{ fontSize: '12px' }} scope="row">{item?.userid?.email}</th>
                                            <th style={{ fontSize: '12px' }} scope="row">{item?.userid?.phonenumber}</th>
                                            <th style={{ fontSize: '12px' }} scope="row">{item?.userid?.username}</th>
                                            <th style={{ fontSize: '12px' }} scope="row">{`${item?.totalminutes} mins`}</th>
                                            <th style={{ fontSize: '12px' }} scope="row">{moment(item?.userid?.updatedAt).format("DD-MM-YYYY")}</th>
                                            <th><button className='btn btn-primary' onClick={() => { handlePayment(item.payment_history) }}>Payments</button></th>
                                            <th><button className='btn btn-primary' onClick={() => { handleHistory(item.userid._id) }}>History</button></th>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>

                        <Modal centered show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Payments</Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                {paymentData.length > 0 ? <>
                                    {
                                        paymentData.map((item, index) =>
                                            <>
                                                {index !== 0 && <hr />}
                                                <p><strong>Amount:</strong> {item?.amount}</p>
                                                <p><strong>Minutes loaded:</strong> {item?.minutesloaded}</p>
                                                <p><strong>Payment date:</strong> {
                                                    new Date(item?.payment_date)
                                                        .toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })
                                                }</p>
                                            </>
                                        )
                                    }

                                </> : ''}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal centered show={showHistory} onHide={handleHistoryClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Data History </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                {historyData.length > 0 ? <>
                                    {
                                        historyData.map((item, index) =>
                                            <>
                                                {index !== 0 && <hr />}
                                                <p><strong>Tutor name:</strong> {item?.tutor_name}</p>
                                                <p><strong>Start time:</strong> {item?.start_time}</p>
                                                <p><strong>End time:</strong> {item?.end_time}</p>
                                                <p><strong>Issues resolved:</strong> {item?.issues_resolved}</p>
                                                {/* <p><strong>Payment date:</strong> {
                                                    new Date(item?.payment_date)
                                                        .toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })
                                                }</p> */}
                                            </>
                                        )
                                    }

                                </> : 'Not Present'}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleHistoryClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            }
        </>
    )
}

export default UserTable