import Editor from "@monaco-editor/react";
import React, { useState } from "react";
import MultiFileEditor from "./MultiFileEditor/MultiFileEditor";

export default function EditorDrawer({ children, isOpen, setIsOpen }) {
 
  const [projectFiles, setProjectFiles] = useState({
    source: {
      name: "Main.java",
      language: "java",
      value: `public class Main {
        public static void main(String[] args) {
        System.out.println("hello, world");
    }
}
`,
    },
    input: {
      name: "input",
      language: "plaintext",
      value: "",
    },
    output: {
      name: "output",
      language: "plaintext",
      value: "",
    },
  });
  const [currentFile, setCurrentFile] = useState("source");

  const handleOnChange = (value, event, key = "source") => {
    // console.log(value, event, key);
    setProjectFiles((projectFiles) => {
      const updatedFiles = {
        ...projectFiles,
        [key]: {
          ...projectFiles[key],
          value,
        },
      };
      //   if (onProjectFilesUpdate) {
      //     onProjectFilesUpdate(updatedFiles);
      //   }

      return updatedFiles;
    });
  };
  return (
    <main
      className={
        "w-screen fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? "w-full transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all delay-500 opacity-0 translate-x-full  ")
      }
    >
      <section
        className={
          " w-5/6 right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative w-full pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
          <MultiFileEditor />
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></section>
    </main>
  );
}
