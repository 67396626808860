import { PaymentDetailsProvider } from "context/PaymentDetailsProvider";
import { LoadingProvider } from "context/LoadingProvider";
import { BrowserRouter } from "react-router-dom";
// test
import App from "./App";
import { UserProvider } from "context/UserProvider";
// import { UserContextProvider } from "context/UserProvider";
import { createRoot } from "react-dom/client";
import { SfxContextProvider } from "context/SfxContext";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./index.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
// const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter basename="/app">
      <LoadingProvider>
        <PaymentDetailsProvider>
          <SfxContextProvider>
            <UserProvider>
              <App />
              <ReactQueryDevtools initialIsOpen />
            </UserProvider>
          </SfxContextProvider>
        </PaymentDetailsProvider>
      </LoadingProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
