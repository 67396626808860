import lectureService from "actions/services/lecture.service";

import React, { useEffect, useState } from "react";
import { Form, Button, Card, ListGroup } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAssignmentStore from "zustand-store/assignmentStore";
import EditorDrawer from "./EditorDrawer";
import useEditorStore from "zustand-store/editorStore";
import TextEditorDrawer from "./TextEditorDrawer";

function CodingQForm({ courseId, unitId, lectureId, assignmentid = null }) {
  console.log("lectureId", lectureId);
  const [testCases, setTestCases] = useState([]);
  const [stdInput, setStdInput] = useState("");
  const [expectedOutput, setExpectedOutput] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);

  const [correctOptionIndex, setCorrectOptionIndex] = useState(-1);
  const [afterLecture, setAfterLecture] = useState(lectureId);

  const [lectureName, setLectureName] = useState("");
  const [duration, setDuration] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [language, setLanguage] = useState("sql");
  const [questionName, setQuestionName] = useState("");
  const [isAdminChallenge, setIsAdminChallenge] = useState(!!assignmentid);
  const [marks, setMarks] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTextEditorOpen, setTextEditorOpen] = useState(false);
  const [assignmentDropdown, setAssignmentDropdown] = useState("0");

  const queryClient = useQueryClient();
  const adminCourseQuestion = useAssignmentStore(
    (state) => state.adminCourseQuestion
  );
  const adminSelectedAssignmentQuestion = useAssignmentStore(
    (state) => state.adminSelectedAssignmentQuestion
  );
  const adminQuestionMode = useAssignmentStore(
    (state) => state.adminQuestionMode
  );
  const files = useEditorStore((state) => state.files);
  const setFiles = useEditorStore((state) => state.setFiles);
  const questionFile = useEditorStore((state) => state.textEditorValue);
  const setQuestionFile = useEditorStore(
    (state) => state.setCurrentTextEditorValue
  );

  const submitChallengeMutation = useMutation(
    () =>
      lectureService.adminUploadChallenge(
        courseId,
        unitId,
        lectureName,
        duration,
        testCases,
        afterLecture,
        questionFile,
        files.map((file) => ({
          filename: file?.filename,
          sourcecode: encode(file?.sourcecode),
        })),
        language,
        assignmentDropdown
      ),
    {
      onSuccess: (data) => {
        console.log("submitChallengeMutation...", data);
        alert("Created Challenge...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  );
  const updateChallengeMutation = useMutation(
    (tcs) =>
      lectureService.adminEditCourseChallenge(
        adminCourseQuestion?.challengeid?._id,
        duration,
        tcs,
        questionFile,
        afterLecture,
        files.map((file) => ({
          filename: file?.filename,
          sourcecode: encode(file?.sourcecode),
        }))
      ),
    {
      onSuccess: (data) => {
        console.log("submitChallengeMutation...", data);
        alert("Updated Challenge...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  );
  const updateLectureInfoMutation = useMutation(
    () =>
      lectureService.putLecture({
        lecturename: lectureName,
        duration: duration,
        afterlecture: afterLecture,
        _id: adminCourseQuestion?._id,
        releaseassignment: assignmentDropdown,
      }),
    {
      onSuccess: (data) => {
        console.log("update lecture information...", data);
        alert("UPDATE LECTURE INFO...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  );

  const createAssignmentQMutation = useMutation(
    () =>
      lectureService.createAssignmentChallengeAsAdmin(
        questionFile,
        assignmentid,
        testCases,
        marks,
        questionName,
        language,
        files.map((file) => ({
          filename: file?.filename,
          sourcecode: encode(file?.sourcecode),
        }))
      ),
    {
      onSuccess: (data) => {
        console.log("created assignment challenge...", data);
        alert("CREATED ASSIGNMENT QUESTION...response" + JSON.stringify(data));
        return queryClient.invalidateQueries(["assignmentList"]);
      },
    }
  );
  const updateAssignmentQMutation = useMutation(
    (tcs) =>
      lectureService.updateAssignmentCodingQ(
        adminSelectedAssignmentQuestion?._id,
        assignmentid,
        tcs,
        questionFile,
        marks,
        questionName,
        language,
        files.map((file) => ({
          filename: file?.filename,
          sourcecode: encode(file?.sourcecode),
        }))
      ),
    {
      onSuccess: (data) => {
        console.log("created assignment challenge...", data);
        alert("ADDED QUESTION ID...response" + JSON.stringify(data));
        return queryClient.invalidateQueries(["assignmentList"]);
      },
    }
  );
  const deleteAssignmentQMutation = useMutation(
    (tcs) =>
      lectureService.deleteAssignmentCodingQ(
        adminSelectedAssignmentQuestion?._id,
        assignmentid
      ),
    {
      onSuccess: (data) => {
        console.log("deleted assignment challenge...", data);
        alert("DELETED ASSIGNMENT QUESTION...response" + JSON.stringify(data));
        return queryClient.invalidateQueries(["assignmentList"]);
      },
    }
  );

  const {
    isLoading: isAssignmentListLoading,
    isError,
    error,
    data: assignmentListResponse,
  } = useQuery(
    "assignmentList",
    () => lectureService.getAdminAssignmentList(),
    {
      onSuccess: (response) => {
        // setAdminAssignmentList(response?.data);
      },
    }
  );

  useEffect(() => {
    if (adminCourseQuestion && adminQuestionMode === "edit-course-challenge") {
      console.log("ADMIN QUESTION...", adminCourseQuestion);
      const testcases = adminCourseQuestion?.challengeid?.testcases;

      setTestCases(testcases);
      setLectureName(adminCourseQuestion?.lecturename);
      setQuestionFile(adminCourseQuestion?.challengeid?.questionfile);
      setLanguage(adminCourseQuestion?.challengeid?.language);
      setDuration(adminCourseQuestion?.duration);
      console.log(
        "ADMIN QUESTION FILES...",
        adminCourseQuestion?.challengeid?.sourcefiles
      );
      setFiles(
        adminCourseQuestion?.challengeid?.sourcefiles?.map((file) => ({
          filename: file?.filename,
          sourcecode: decode(file?.sourcecode),
        }))
      );
      setAssignmentDropdown(adminCourseQuestion?.releaseassignment || "0");

      // const duration = localStorage.getItem("duration");
      // if (duration) setDuration(duration);
      const marks = adminCourseQuestion?.coursemcq?.marks;
      if (marks) setMarks(marks);
    } else if (
      adminSelectedAssignmentQuestion &&
      adminQuestionMode === "edit-assignment-coding"
    ) {
      console.log(
        "ADMIN ASSIGNMENT QUESTION...",
        adminSelectedAssignmentQuestion
      );
      const testcases = adminSelectedAssignmentQuestion?.testcases;

      setTestCases(testcases);
      setQuestionFile(adminSelectedAssignmentQuestion?.questionfile);
      setMarks(adminSelectedAssignmentQuestion?.marks);
      setQuestionName(adminSelectedAssignmentQuestion?.questionname);
      setLanguage(adminSelectedAssignmentQuestion?.language);
      setFiles(
        adminSelectedAssignmentQuestion?.sourcefiles?.map((file) => ({
          filename: file?.filename,
          sourcecode: decode(file?.sourcecode),
        }))
      );
    } else {
      console.log("NO ADMIN QUESTION");
      resetForm();
    }
    // const testCases = JSON.parse(localStorage.getItem("testCases"));
    // if (testCases) setTestCases(testCases);
    // const lectureName = localStorage.getItem("lectureName");
    // if (lectureName) setLectureName(lectureName);
    // const duration = localStorage.getItem("duration");
    // if (duration) setDuration(duration);
  }, [adminCourseQuestion, adminSelectedAssignmentQuestion]);

  function encode(str) {
    return btoa(unescape(encodeURIComponent(str || "")));
  }

  function decode(bytes) {
    var escaped = escape(atob(bytes || ""));
    try {
      return decodeURIComponent(escaped);
    } catch {
      return unescape(escaped);
    }
  }

  const handleAddTestCase = (e) => {
    e.preventDefault();
    const newTestCase = {
      stdin: encode(stdInput),
      expected_output: encode(expectedOutput),
    };
    console.log("NEW TESTCASE", newTestCase);

    setTestCases([...testCases, newTestCase]);
    resetForm();

    if (adminQuestionMode === "edit-course-challenge") {
      updateChallengeMutation.mutate([...testCases, newTestCase]);
    } else if (adminQuestionMode === "edit-assignment-coding") {
      updateAssignmentQMutation.mutate([...testCases, newTestCase]);
    }
  };

  const resetForm = () => {
    // setQuestionText("");
    setStdInput("");
    setExpectedOutput("");
    // setLanguage("sql");
    setCorrectOptionIndex(-1);
    setEditIndex(-1);
  };

  const handleUpdatedTestCase = (e) => {
    e.preventDefault();
    const editedTestCase = {
      stdin: encode(stdInput),
      expected_output: encode(expectedOutput),
    };
    const updatedTestCases = testCases.map((testCase, index) =>
      editIndex === index ? editedTestCase : testCase
    );

    setTestCases(updatedTestCases);
    if (adminQuestionMode === "edit-course-challenge") {
      updateChallengeMutation.mutate(updatedTestCases);
    } else if (adminQuestionMode === "edit-assignment-coding") {
      updateAssignmentQMutation.mutate(updatedTestCases);
    }

    resetForm();
  };

  const handleEditTestCase = (index) => {
    setEditIndex(index);

    const testCase = testCases[index];
    console.log("EDITING", testCase, editIndex);
    setStdInput(decode(testCase.stdin));
    setExpectedOutput(decode(testCase.expected_output));
    // setLanguage("java");
  };
  const handleDeleteTestCase = (index) => {
    const newTestCases = testCases.filter((_, i) => i !== index);

    setTestCases(newTestCases);
    if (adminQuestionMode === "edit-course-challenge") {
      updateChallengeMutation.mutate(newTestCases);
    } else if (adminQuestionMode === "edit-assignment-coding") {
      updateAssignmentQMutation.mutate(newTestCases);
    }
  };
  const handlePostChallenge = (e) => {
    e.preventDefault();
    if (adminQuestionMode === "edit-course-challenge") {
      updateChallengeMutation.mutate();
      return;
    }
    if (adminQuestionMode.includes("assignment")) {
      createAssignmentQMutation.mutate();
    } else {
      submitChallengeMutation.mutate();
    }
  };

  const handleUpdatChallengeToDB = (testcases) => {
    updateChallengeMutation.mutate(testcases);
    updateLectureInfoMutation.mutate();
  };

  const loadButton = () => {
    if (adminQuestionMode.includes("create")) {
      return (
        <>
          {submitChallengeMutation?.isLoading ? (
            <p className="text-white text-center w-100">Loading...</p>
          ) : (
            <button
              className="btn-warning p-3 w-100 m-2"
              onClick={handlePostChallenge}
            >
              Submit Challenge to DB
            </button>
          )}
        </>
      );
    }

    if (adminQuestionMode === "edit-course-challenge") {
      return (
        <>
          {submitChallengeMutation?.isLoading ? (
            <p className="text-white text-center w-100">Loading...</p>
          ) : (
            <button
              className="btn-warning p-3 w-100 m-2"
              onClick={() => handleUpdatChallengeToDB(testCases)}
            >
              Update Challenge to DB
            </button>
          )}
        </>
      );
    }

    if (adminQuestionMode === "edit-assignment-coding") {
      return (
        <>
          {updateAssignmentQMutation?.isLoading ? (
            <p className="text-white text-center w-100">Loading...</p>
          ) : (
            <button
              className="btn-warning p-3 w-100 m-2"
              onClick={() => updateAssignmentQMutation.mutate(testCases)}
            >
              Update Assignment Data
            </button>
          )}
        </>
      );
    }

    // {isAdminChallenge && (
    //   <>
    //     {submitChallengeMutation?.isLoading ? (
    //       <p className="text-white text-center w-100">Loading...</p>
    //     ) : (
    //       <button
    //         className="btn-warning p-3 w-100 m-2"
    //         onClick={handlePostChallenge}
    //       >
    //         Create Assignment Coding Challenge
    //       </button>
    //     )}
    //   </>
    // )}
  };

  return (
    <div>
      {loadButton()}
      {adminQuestionMode === "edit-assignment-coding" && (
        <button
          className="w-100 bg-red-400 p-2 px-3 m-2 rounded"
          onClick={() => {
            alert("Refresh page to stop.");
            deleteAssignmentQMutation.mutate();
          }}
        >
          Delete Assignment Question
        </button>
      )}
      <div className="d-flex w-100">
        <div className="w-50 m-3">
          <h1 className="text-lg text-white m-2 px-2">
            Source code files:{" "}
            <div
              className="cursor-pointer inline-block"
              onClick={() => setIsDrawerOpen(true)}
            >
              {files &&
                files?.map((file) => (
                  <span className="bg-purple-400 text-sm px-2 rounded-lg mx-1">
                    {file?.filename}
                  </span>
                ))}
              <button
                className="bg-green-600 text-white text-sm rounded px-2"
                onClick={() => setIsDrawerOpen(true)}
              >
                +
              </button>
            </div>
          </h1>
          <h1 className="text-lg text-white m-2 px-2">
            Question Details:{" "}
            <div
              className="cursor-pointer inline-block"
              onClick={() => setTextEditorOpen(true)}
            >
              <span className="bg-purple-400 text-sm px-2 rounded-lg mx-1">
                Info
              </span>

              <button
                className="bg-green-600 text-white text-sm rounded px-2"
                onClick={() => setTextEditorOpen(true)}
              >
                +
              </button>
            </div>
          </h1>
          {!adminQuestionMode.includes("assignment") && (
            <Form.Group controlId="questionType">
              <Form.Label className="text-white">
                Select Assignment to release
              </Form.Label>

              {isAssignmentListLoading ? (
                <p>Loading...</p>
              ) : (
                <Form.Select
                  id=""
                  value={assignmentDropdown}
                  onChange={(event) =>
                    setAssignmentDropdown(event.target.value)
                  }
                >
                  <option value="0">No assignment to release</option>
                  {assignmentListResponse?.data?.map((ass, index) => (
                    <option value={ass?._id}>
                      {ass?.assignmentname} ({ass?._id})
                    </option>
                  ))}
                </Form.Select>
              )}
            </Form.Group>
          )}

          {adminQuestionMode.includes("assignment") && (
            <Form.Group controlId="questionName">
              <Form.Label className="text-white">Challenge Name</Form.Label>
              <Form.Control
                type="url"
                value={questionName}
                onChange={(event) => setQuestionName(event.target.value)}
              />
            </Form.Group>
          )}
          {/* <Form.Group controlId="questionFile">
            <Form.Label className="text-white">Question file</Form.Label>
            <Form.Control
              type="url"
              value={questionFile}
              onChange={(event) => setQuestionFile(event.target.value)}
            />
          </Form.Group> */}
          {!isAdminChallenge && (
            <>
              <Form.Group controlId="lectureName">
                <Form.Label className="text-white">
                  Challenge Name{" "}
                  {adminQuestionMode === "edit-course-challenge" &&
                    "(Challenge name won't update)"}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={lectureName}
                  onChange={(event) => setLectureName(event.target.value)}
                  disabled={adminQuestionMode === "edit-course-challenge"}
                />
              </Form.Group>
              <Form.Group controlId="duration">
                <Form.Label className="text-white">Duration</Form.Label>
                <Form.Control
                  type="number"
                  value={duration}
                  onChange={(event) => setDuration(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="afterLecture">
                <Form.Label className="text-white">After Lecture</Form.Label>
                <Form.Control
                  type="text"
                  value={afterLecture}
                  onChange={(event) => setAfterLecture(event.target.value)}
                />
              </Form.Group>
            </>
          )}
          {isAdminChallenge && (
            <Form.Group controlId="duration">
              <Form.Label className="text-white">Marks</Form.Label>
              <Form.Control
                type="number"
                value={marks}
                onChange={(event) => setMarks(event.target.value)}
              />
            </Form.Group>
          )}
          <Form.Group controlId="questionType">
            <Form.Label className="text-white">Select Language</Form.Label>
            <Form.Select
              id=""
              value={language}
              onChange={(event) => setLanguage(event.target.value)}
            >
              <option value="java">Java</option>
              <option value="js">JavaScript</option>
              <option value="sql">SQL</option>
              <option value="py">Python</option>
            </Form.Select>
          </Form.Group>

          <Form>
            <Form.Group controlId="stdInput">
              <Form.Label className="text-white">Std Input</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={stdInput}
                onChange={(event) => setStdInput(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="expectedOutput">
              <Form.Label className="text-white">Expected Output</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={expectedOutput}
                onChange={(event) => setExpectedOutput(event.target.value)}
              />
            </Form.Group>

            {editIndex >= 0 ? (
              <button
                className="btn success-btn p-3 w-100 m-2"
                onClick={handleUpdatedTestCase}
              >
                Update Test Case
              </button>
            ) : (
              <button
                className="btn-primary p-3 w-100 m-2"
                onClick={handleAddTestCase}
              >
                Add Test Case
              </button>
            )}
            {editIndex >= 0 && (
              <button
                className="btn-primary  p-3 w-100 m-2"
                onClick={resetForm}
              >
                Reset Form
              </button>
            )}
          </Form>
        </div>
        <div className="w-50 m-3">
          {testCases.map((testCase, index) => (
            <Card key={index} className="mt-3">
              <Card.Body>
                <Card.Title className="d-flex align-items-center justify-content-between">
                  Test Case {index + 1}
                  <div className="d-flex">
                    <button
                      className="btn-primary btn-sm m-2"
                      onClick={() => handleEditTestCase(index)}
                    >
                      Edit Test Case
                    </button>
                    <button
                      className="btn-danger btn-sm m-2"
                      onClick={() => handleDeleteTestCase(index)}
                    >
                      Delete Test Case
                    </button>
                  </div>
                </Card.Title>
                {/* <Card.Text>LanguageCode: {testCase.language_id}</Card.Text> */}
                <ListGroup>
                  <p className="m-0 p-0 mb-2">std Input: {testCase.stdin}</p>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={decode(testCase.stdin)}
                    disabled
                  />
                </ListGroup>
                <ListGroup>
                  <p className="m-0 p-0 mt-4 mb-2">
                    Expected Output: {testCase.expected_output}
                  </p>

                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={decode(testCase.expected_output)}
                    disabled
                  />
                </ListGroup>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>

      <EditorDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
      <TextEditorDrawer
        isOpen={isTextEditorOpen}
        setIsOpen={setTextEditorOpen}
      />
    </div>
  );
}

export default CodingQForm;
