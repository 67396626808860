import axios from "axios";

// Old Key
// const API_KEY = "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";

// new Key
const API_KEY = "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";

export const askQuestion = async (question=null,answer=[]) => {
  try {
    const { data } = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-3.5-turbo",
        messages: [
          { role: "system", content: "You are Coding Assistant for students. You provide code snippet, explaination, summary of the questions asked" },
          { role: "user", content: question ? question : "Hello!" },
          ...answer 
        ]
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`
        }
      }
    );
    return data.choices[0].message;
  } catch (error) {
    throw error;
  }
};
