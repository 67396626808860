import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FaPlayCircle, FaLock, FaCheckCircle } from "react-icons/fa";
import { ProgressBar } from "react-bootstrap";
import CustomModal from "components/Modals/CustomModal";
import ZCoin from "components/ZCoin/ZCoin";

import "./CourseCard.css";
import CourseModalBody from "./CourseModalBody";
import { moduleEventTracking } from "Utils/WebEngage";

const DEFAULT_COURSE = undefined;
function Course({
  elementRef = undefined,
  fromTeamPath = false,
  course = undefined,
  cardHeight = 350,
  lockedCourse = false,
}) {
  console.log("COURSE", course?.completedPercentage);
  const history = useHistory();
  const [showHoverCourse, setShowHoverCourse] = useState(false);

  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const handleCourseClick = () => {
    if (fromTeamPath) return;
    if (lockedCourse) {
      loadModal();
    } else {
      moduleEventTracking('Module Selected', course);
      history.push(`/course/${course?.coursename}/${course?._id}`);
    }
  };
  const loadModal = () => {
    setModal({
      ...modal,
      show: true,
      title: "Unlock course",
      // description:"Your current balance is 22ZCoin. ",
      CustomComponent: CourseModalBody,
      props: {
        price: course?.price,
        courseid: course?._id,
      },
    });
  };

  const renderMiddleIcon = () => {
    if (course?.completedPercentage === 100) {
      return (
        <FaCheckCircle className={`yellow-course-icon green-course-icon`} />
      );
    }
    if (fromTeamPath) {
      return;
    }

    if (!lockedCourse) {
      return (
        showHoverCourse && (
          <span className="">
            <FaPlayCircle className=" yellow-course-icon pulse" />
          </span>
        )
      );
    } else {
      return (
        <FaLock
          className={`yellow-course-icon ${showHoverCourse ? "pulse" : ""}`}
        />
      );
    }
  };
  const renderOverlay = () => {
    if (lockedCourse) {
      return <div className="locked-overlay"></div>;
    }
    if (course?.completedPercentage === 100) {
      return <div className="locked-overlay"></div>;
    }
    return;
  };
  const renderTopRightNote = () => {
    if (course?.completedPercentage === 100) {
      return (
        <div
          className={`course-note course-percentage-progress-show completed`}
        >
          <small>Completed</small>
        </div>
      );
    }
    if (lockedCourse) {
      return (
        <div
          className={`course-note course-percentage-progress-show purple-bg`}
        >
          <ZCoin
            number={course?.price}
            toolTipText={`You need ${course?.price} Z-Coins to unlock this course.`}
            handleClick={loadModal}
          />
        </div>
      );
    }
    return (
      <div
        className={`course-note ${
          showHoverCourse ? "course-percentage-progress-show" : ""
        }`}
      >
        {course?.completedPercentage ? (
          <small>{course?.completedPercentage}% Progress</small>
        ) : (
          <small>View Course</small>
        )}
      </div>
    );
  };

  return (
    <>
      {modal.show && (
        <CustomModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          props={modal.props}
          CustomComponent={modal.CustomComponent ?? false}
          // videoUrl={"https://youtu.be/Uv9URjIBtUQ"}
        />
      )}
      <div
        className="course-container text-white"
        style={{
          height: cardHeight - 50,
        }}
        ref={elementRef}
        onClick={handleCourseClick}
        onMouseEnter={() => setShowHoverCourse(true)}
        onMouseLeave={() => setShowHoverCourse(false)}
      >
        {renderOverlay()}
        {renderTopRightNote()}
        <div className="course-thumbnail">
          <img
            src={course?.coursethumbnailurl}
            // src="https://zaiocontent.s3.eu-west-2.amazonaws.com/images+of+courses/bootstrap.png"
            style={{ width: "100%", marginTop: "0" }}
          />
          {renderMiddleIcon()}
        </div>

        <div className="course-details-info">
          <span>{course?.coursename}</span>

          <div className="d-flex justify-content-between align-items-center">
            <small className="course-small-info">
              {course?.courseunit?.length
                ? course?.courseunit?.length + " Units"
                : null}
            </small>
            <span className="">
              <small className="badge bg-success text-white ">
                Watch on
                {course?.ismobilecourse && " Mobile"}
                {!course?.ismobilecourse && " PC"}
              </small>
            </span>
          </div>
        </div>

        {!lockedCourse && !!course?.completedPercentage && (
          <ProgressBar
            now={course?.completedPercentage}
            className={"courseProgressBar"}
            style={{
              width: "100%",
            }}
          />
        )}
      </div>
    </>
  );
}

export default Course;
