// this component can be further optimized to have a seperate data layer

import React, { useContext, useEffect, useRef, useState } from "react";
import "./compiler.scss";
// import "./main";
import Editor, { useMonaco } from "@monaco-editor/react";
import { emmetHTML, emmetCSS, expandAbbreviation } from "emmet-monaco-es";
// import { compileAndRun } from "./console";
// import ConsoleOutput from "./ConsoleOutput";

import "luna-object-viewer/luna-object-viewer.css";
import "luna-data-grid/luna-data-grid.css";
import "luna-dom-viewer/luna-dom-viewer.css";
import "luna-console/luna-console.css";
// import IframeCode from "./PreviewTerminal";

import { DiHtml5, DiCss3, DiJsBadge } from "react-icons/di";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MarkdownToReact from "pages/Competitions/MarkdownToReact";
import { MdPlayCircleFilled } from "react-icons/md";
import { Puff } from "react-loader-spinner";
import { useMutation, useQuery, useQueryClient } from "react-query";
import lectureService from "actions/services/lecture.service";
import useVideoStore from "zustand-store/videoPlayerStore";
import Results from "./Results";
import ReelModal from "components/QuizReelComponents/ReelModal/ReelModal";
import CustomModal from "components/Modals/CustomModal";
import CompleteChallenge from "./CompleteChallenge";
import useAssignmentStore from "zustand-store/assignmentStore";
import MultiFileEditor from "pages/AdminPortal/CodingChallenge/MultiFileEditor/MultiFileEditor";
import useEditorStore from "zustand-store/editorStore";
import Split from "react-split";

import "./compiler.scss";
import SubmitCodeForm from "./SubmitCodeForm";
import { MarkdownContainer } from "pages/Competitions/Competitions.styled";
import MarkdownToHTML from "components/HTMLToReact/MarkdownToHTML";
import { useParams, useLocation } from "react-router-dom";
import { SfxContext } from "context/SfxContext";

function Compiler({
  onCtrlS,
  preLoadedProjectFiles,
  onProjectFilesUpdate = undefined,
  lectureId,
  onNextClick = undefined,
  dataNext = null,
  assignment = false,
  refreshCourseData,
}) {
  const { winSfx, hoverSfx } = useContext(SfxContext);
  const { courseid, unitid, lectureid } = useParams();
  const challenge = useVideoStore((state) => state.challenge);
  const monacoRef = useRef(null);
  const editorRef = useRef(null);

  const [isEditorReady, setIsEditorReady] = useState(false);
  const [showConsole, setShowConsole] = useState(false);
  //maximized, minimize, closed, default
  const [previewMode, setPreviewMode] = useState("default");
  const [compileLoading, setCompileLoading] = useState(false);
  const [consoleTab, setConsoleTab] = useState("input");
  const [questionTab, setQuestionTab] = useState("description");
  const [savedCodingChallengeFiles, setSavedCodingChallengeFiles] =
    useState(null);

  const assignmentData = useAssignmentStore((state) => state.assignmentData);
  const currentQuestionId = useAssignmentStore(
    (state) => state.currentQuestionId
  );
  const currentQuestion = useAssignmentStore((state) => state.question);
  const setEditorMode = useEditorStore((state) => state.setEditorMode);
  const setFiles = useEditorStore((state) => state.setFiles);
  const setOpenFile = useEditorStore((state) => state.setOpenFile);
  const files = useEditorStore((state) => state.files);
  const setOpenFiles = useEditorStore((state) => state.setOpenFiles);
  const openFiles = useEditorStore((state) => state.openFiles);
  const currentIndex = useAssignmentStore((state) => state.currentIndex);
  const setCurrentQuestion = useAssignmentStore(
    (state) => state.setCurrentQuestion
  );
  const feedback = useEditorStore((state) => state.feedback);
  const setFeedback = useEditorStore((state) => state.setFeedback);
  const setSourceFiles = useEditorStore((state) => state.setSourceFiles);
  const sourceFiles = useEditorStore((state) => state.sourceFiles);
  const editorMode = useEditorStore((state) => state.mode);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [projectFiles, setProjectFiles] = useState({
    source: {
      name: "source",
      language: "java",
      value: `public class Main {
  public static void main(String[] args) {
    System.out.println("hello, world");
  }
}
      `,
    },
    input: {
      name: "input",
      language: "plaintext",
      value: "",
    },
    output: {
      name: "output",
      language: "plaintext",
      value: "",
    },
  });

  const [horizontalSizes, setHorizontalSizes] = useState([30, 70]);
  const [verticalSizes, setVerticalSizes] = useState([70, 30]);
  const [syncSubmissions, setSyncSubmissions] = useState(null)

  const [showAssignmentSubmission, setShowAssignmentSubmission] =
    useState(false);

  const queryClient = useQueryClient();

  const userid = searchParams.get("userid");
  const isTutor = Boolean(searchParams.get("tutor"));

  // const {
  //   isLoading,
  //   isError,
  //   error,
  //   isSuccess,
  //   data: courseCodingQuestionProgress,
  // } = useQuery(
  //   "courseCodingQuestionProgress",
  //   () => lectureService.fetchChallengeBetweenVideo(challenge?._id),
  //   {
  //     // enabled: !!challenge?._id,
  //     onSuccess: (response) => {
  //       console.log("\n\n\ncourseCodingQuestionProgress", response);
  //       const savedFiles = response?.data?.files;
  //       if (savedFiles) {
  //         console.log("savedFiles", savedFiles);
  //         setSavedCodingChallengeFiles(savedFiles);
  //         // setFiles(
  //         //   response?.data?.files.map((file, index) => ({
  //         //     filename: file?.filename,
  //         //     sourcecode: decode(file?.sourcecode),
  //         //   }))
  //         // );
  //       } else {
  //         setFiles(
  //           challenge?.sourcefiles.map((file, index) => ({
  //             filename: file?.filename,
  //             sourcecode: decode(file?.sourcecode),
  //           }))
  //         );
  //       }
  //     },
  //   }
  // );

  const fetchCourseChallengeProgress = useMutation(
    "courseCodingQuestionProgress",
    () =>
      !userid
        ? lectureService.fetchChallengeBetweenVideo(challenge?._id)
        : lectureService.fetchChallengeBetweenVideoPublic(
            challenge?._id,
            userid
          ),
    {
      enabled: !assignment,
      onSuccess: (response) => {
        console.log("\n\n\ncourseCodingQuestionProgress", response);
        const savedFiles = response?.data?.files;
        if (savedFiles) {
          // console.log("savedFiles", savedFiles);
          const filesFetched = response?.data?.files.map((file, index) => ({
            filename: file?.filename,
            sourcecode: decode(file?.sourcecode),
          }));
          setFiles(filesFetched);
          setOpenFile(filesFetched[0]);
        } else {
          const filesFetched = challenge?.sourcefiles.map((file, index) => ({
            filename: file?.filename,
            sourcecode: decode(file?.sourcecode),
          }));
          setFiles(filesFetched);
          setOpenFile(filesFetched[0]);
        }
      },
    }
  );

  const submitChallengeMutation = useMutation(
    () =>
      lectureService.submitChallengeBetweenVideo(
        lectureId,
        files.map((file) => ({
          filename: file.filename,
          sourcecode: encode(file?.sourcecode),
        }))
      ),
    {
      onSuccess: (response) => {
        console.log("\n\nsubmitted challenge mutation...", response);
        if (response?.data?.passed) {
          winSfx();
          setModal({
            ...modal,
            show: true,
            title: "Challenge Completed! 🎉",
            description:
              "Congratulations! You have completed this challenge. You can now move on to the next lesson.",
            CustomComponent: CompleteChallenge,
            props: {
              onNextClick,
              dataNext,
            },
          });
        }
        setSyncSubmissions(Math.random())
        queryClient.invalidateQueries("challengeSubmissions");
        return queryClient.invalidateQueries("challengeSubmissions");
      },
    }
  );
  const saveCodeFeedbackMutation = useMutation(
    (filedata) =>
      lectureService.saveCodeChatGPTFeedback(challenge?._id, filedata),
    {
      onSuccess: (response) => {
        console.log("saveCodeFeedbackMutation...", response);
        if (response?.data?.zaioaireview) {
          // popSfx();
          hoverSfx();
          setFeedback({
            ...feedback,
            isLoading: false,
            pending: true,
            show: feedback?.show,
            message: response?.data?.zaioaireview,
          });
        }

        // if (response?.data?.challengeData?.files)
        //   setFiles(
        //     response?.data?.challengeData?.files.map((file, index) => ({
        //       filename: file?.filename,
        //       sourcecode: decode(file?.sourcecode),
        //     }))
        //   );

        // setModal({
        //   ...modal,
        //   show: true,
        //   title: "Your progress has been saved! ✅",
        //   description: "Keep up trying and stay consistent.",
        //   CustomComponent: null,
        //   props: {},
        // });

        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const saveAssignmentCodeFeedbackMutation = useMutation(
    (filedata) =>
      lectureService.saveAssignmentCodeChallengeProgress(
        currentQuestionId,
        filedata
      ),
    {
      onSuccess: (response) => {
        console.log("saveAssignmentCodingChallengeMutation...", response);
        if (!response?.success) {
          // popSfx();
          hoverSfx();
          setFeedback({
            ...feedback,
            pending: true,
            show: feedback?.show,
            message: response?.message,
          });
        } else {
          // popSfx();
          hoverSfx();
          setFeedback({
            ...feedback,
            pending: true,
            show: feedback?.show,
            message: response?.data?.zaioaireview,
          });
        }

        // setModal({
        //   ...modal,
        //   show: true,
        //   title: "Your progress has been saved! ✅",
        //   description: "Keep up trying and stay consistent.",
        //   CustomComponent: null,
        //   props: {},
        // });

        // return queryClient.invalidateQueries(["challengeSubmissions"]);
      },
    }
  );
  const runTestCaseWithoutSubmission = useMutation(
    () =>
      lectureService.runTestCasesWithoutSubmission(
        assignmentData?.assignmentid?._id,
        currentQuestionId,
        files.map((file) => ({
          filename: file.filename,
          sourcecode: encode(file?.sourcecode),
        }))
      ),
    {
      onSuccess: (data) => {
        console.log("Running test cases...", data);
        // saveCodingChallengeMutation.mutate();
      },
    }
  );
  const assignmentSubmitCodeMutation = useMutation(
    () =>
      lectureService.submitAssignmentCodeQuestion(
        assignmentData?.assignmentid?._id,
        {
          assignmentcodingquestionid: currentQuestionId,
          files: files.map((file) => ({
            filename: file.filename,
            sourcecode: encode(file?.sourcecode),
          })),
        }
      ),
    {
      onSuccess: (response) => {
        // console.log("ASsignment submission Data", response);
        handleOnHide();
        setShowAssignmentSubmission(false);
      },
    }
  );
  const compileCodeMutation = useMutation(
    () =>
      lectureService.compileCode(
        files?.[0].filename?.split(".")[1] || "java",
        encode(projectFiles["input"]?.value),
        files.map((file) => ({
          filename: file.filename,
          sourcecode: encode(file?.sourcecode),
        })),
        lectureId
      ),
    {
      onSuccess: (response) => {
        console.log("compiled code", response);
        handleResult(response?.data[0]);
        setCompileLoading(false);
        setSyncSubmissions(Math.random())
      },
    }
  );

  const [modal, setModal] = useState({
    show: false,
    title: "Challenge Completed! 🎉",
    description:
      "Congratulations! You have completed this challenge. You can now move on to the next lesson.",
    CustomComponent: null,
    props: null,
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const overrideSave = () => {
    document.addEventListener(
      "keydown",
      function (e) {
        if (
          e.keyCode === 83 &&
          (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
        ) {
          e.preventDefault();
          console.log("Ctrl S..");
          // if (assignment) saveCodingChallengeMutation.mutate();
          onCtrlS();
        }
      },
      false
    );
  };

  const handleOnChange = (value, event, key = "source") => {
    // console.log(value, event, key);
    setProjectFiles((projectFiles) => {
      const updatedFiles = {
        ...projectFiles,
        [key]: {
          ...projectFiles[key],
          value,
        },
      };
      if (onProjectFilesUpdate) {
        onProjectFilesUpdate(updatedFiles);
      }

      return updatedFiles;
    });
  };

  function encode(str) {
    return btoa(unescape(encodeURIComponent(str || "")));
  }

  function decode(bytes) {
    var escaped = "";

    try {
      escaped = escape(atob(bytes || ""));
    } catch (e) {
      console.log("ERROR", e);
      return "";
    }

    try {
      return decodeURIComponent(escaped);
    } catch (e) {
      console.log("ERROR", e);
      return unescape(escaped);
    }
  }

  const handleResult = (data) => {
    console.log("handleSuccess", data);
    console.log(data?.status?.description);

    const status = data?.status;
    const stdout = decode(data?.stdout);
    const compile_output = decode(data?.compile_output);
    const time = data?.time === null ? "-" : data?.time + "s";
    const memory = data?.memory === null ? "-" : data?.memory + "KB";

    console.log(`${status?.description}, ${time}, ${memory}`);

    const output = [compile_output, stdout].join("\n").trim();

    setConsoleTab("output");
    setProjectFiles((projectFiles) => {
      const updatedFiles = {
        ...projectFiles,
        ["output"]: {
          ...projectFiles["output"],
          value: output,
        },
      };
      return updatedFiles;
    });

    // if (output !== "") {
    //   var dot = document.getElementById("stdout-dot");
    //   if (!dot.parentElement.classList.contains("lm_active")) {
    //     dot.hidden = false;
    //   }
    // }
  };
  const handleCodeCompile = async () => {
    setCompileLoading(true);
    console.log("Compiling code...", files, files?.[0].filename?.split(".")[1]);
    compileCodeMutation.mutate();
  };

  const handleSubmitAssignmentCode = async () => {
    setModal({
      ...modal,
      show: true,
      title: "You are about to submit your code! ",
      description:
        "This cannot be reveresed, and your results will be returned as soon as its marked by a tutor/instructor.",
      CustomComponent: SubmitCodeForm,
      props: {
        assignmentSubmitCodeMutation,
        setShowAssignmentSubmission,
        showAssignmentSubmission,
      },
    });
  };
  // this is for the submit button (without assignment)
  const handleSubmitCode = async () => {
    setConsoleTab("results");
    setQuestionTab("submissions");
    submitChallengeMutation.mutate();
  };

  // this is for the Run test cases button (as assignment)
  const handleRunTestCases = async () => {
    setConsoleTab("results");
    runTestCaseWithoutSubmission.mutate();
  };

  const showSolutionMarkedModal = () => {
    setModal({
      ...modal,
      show: true,
      title: "This question has been marked by a tutor/instructor",
      description: "You can view the results below.",
      CustomComponent: function () {
        return (
          <>
            <button
              onClick={() => setModal({ ...modal, show: false })}
              className="p-3 m-2 btn-block btn-warning"
            >
              Close
            </button>
          </>
        );
      },
      props: {},
    });
  };
  const displaySubmitButton = () => {
    if (
      submitChallengeMutation?.isLoading === true ||
      runTestCaseWithoutSubmission?.isLoading === true
    ) {
      return (
        <button className="success-btn">
          <Puff
            height="25"
            width="25"
            radius={1}
            color="white"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </button>
      );
    }

    // if (submitChallengeMutation?.data?.success === true) {
    //   return <button className="success-btn">Success!</button>;
    // }

    if (assignment) {
      return (
        <>
          <button onClick={handleRunTestCases} className="success-btn">
            Run Test Cases
          </button>
          {currentQuestion?.status === "marked" && (
            <button onClick={showSolutionMarkedModal} className="btn-warning">
              Solution Marked
            </button>
          )}
          {handleDisplaySubmitAssignmentButton()}
        </>
      );
    }
    return (
      <button onClick={handleSubmitCode} className="success-btn">
        Submit
      </button>
    );
  };
  const handleDisplaySubmitAssignmentButton = () => {
    if (currentQuestion?.status === "inprogress") {
      if (assignmentSubmitCodeMutation?.isSuccess === true) {
        return (
          <button
            className="bg-transparent"
            onClick={() =>
              alert(
                "Feedback will be provided soon as our instructors review your submission. Goodluck!"
              )
            }
          >
            <em>Pending Feedback</em>
          </button>
        );
      } else {
        return (
          <button onClick={handleSubmitAssignmentCode} className="btn-warning">
            Submit Code
          </button>
        );
      }
    }
  };

  const loadRunningTestCases = () => {
    if (runTestCaseWithoutSubmission?.data?.success === true) {
      const submissionData = runTestCaseWithoutSubmission?.data;

      // console.log("SUBMISSION DATA", submissionData);
      // if (submissionData?.data?.lectureid) {
      const title = submissionData?.message + " ✅\n";

      let result = "";
      submissionData?.data?.forEach((testcase, index) => {
        if (testcase?.passed)
          result += "🟢 Test Case " + (index + 1) + " Passed\n";
        else result += "🔴 Test Case " + (index + 1) + " Failed\n";

        result += "Inputs \n";
        result += testcase?.stdin
          ? decode(testcase?.stdin) + "\n"
          : "No inputs " + "\n";
        result += "\n";

        result += "Expected Outputs \n";
        result += testcase?.expected_output
          ? decode(testcase?.expected_output) + "\n"
          : "No Expected Output " + "\n";
        result += "\n";

        result += "Your Output \n";
        result += testcase?.stdout
          ? decode(testcase?.stdout) + "\n"
          : "No Output " + "\n";
        result += "\n";
      });
      return title + "\n" + result;
      // }

      return submissionData?.message + " ✅";
    }

    if (runTestCaseWithoutSubmission?.isLoading) return `Running test cases...`;

    return "Submit your code to run test cases";
  };

  const loadSubmissionResult = () => {
    console.log("SUBMISSION MUTATION", submitChallengeMutation?.data);
    if (submitChallengeMutation?.data?.success === true) {
      const submissionData =
        submitChallengeMutation?.data?.data?.challengeSubmissionObj;

      console.log("SUBMISSION DATA", submissionData);
      if (submissionData?.lectureid) {
        const title = submitChallengeMutation?.data?.message + " ✅\n";

        let result = "";
        submissionData?.testcasesdata?.forEach((testcase, index) => {
          if (testcase?.passed)
            result += "🟢 Test Case " + (index + 1) + " Passed\n";
          else result += "🔴 Test Case " + (index + 1) + " Failed\n";

          result += "Inputs \n";
          result += testcase?.stdin
            ? decode(testcase?.stdin) + "\n"
            : "No inputs " + "\n";
          result += "\n";

          result += "Expected Outputs \n";
          result += testcase?.expected_output
            ? decode(testcase?.expected_output) + "\n"
            : "No Expected Output " + "\n";
          result += "\n";

          result += "Your Output \n";
          result += testcase?.stdout
            ? decode(testcase?.stdout) + "\n"
            : "No Output " + "\n";
          result += "\n";
        });
        return title + "\n" + result;
      }

      return submitChallengeMutation?.data?.message + " ✅";
    }

    if (submitChallengeMutation?.isLoading) return `Running test cases...`;

    return "Submit your code to run test cases";
  };

  useEffect(() => {
    setQuestionTab("description");
    saveCodeFeedbackMutation.mutate({});
    // setSavedCodingChallengeFiles(null);
    if (!savedCodingChallengeFiles && !assignment) {
      fetchCourseChallengeProgress.mutate();
    }

    console.log(
      "COURSE CHALLENGE IN...",
      challenge,
      currentQuestion,
      currentQuestion?.sourcecode,
      decode(currentQuestion?.sourcecode)
    );

    // if (savedCodingChallengeFiles && savedCodingChallengeFiles.length > 0) {
    //   setFiles(
    //     savedCodingChallengeFiles.map((file) => ({
    //       filename: file?.filename,
    //       sourcecode: decode(file?.sourcecode),
    //     }))
    //   );
    // }

    if (challenge?.sourcefiles) {
      setSourceFiles(
        challenge?.sourcefiles.map((file, index) => ({
          filename: file?.filename,
          sourcecode: decode(file?.sourcecode),
        }))
      );
    }

    setProjectFiles((projectFiles) => {
      const updatedFiles = {
        ...projectFiles,
      };
      return updatedFiles;
    });
  }, [lectureid, challenge, assignmentData]);

  const saveCode = (file) => {
    setFeedback({
      ...feedback,
      isLoading: true,
    });
    // console.log("saving file...");
    // if (files.length === 0) {
    //   return;
    // }
    // const file = files[currentIndex];
    // console.log("FILE", file)
    console.log("Calling mutation...", file);
    if (editorMode === "assignment") {
      // alert("ASAVING.. assignment");
      saveAssignmentCodeFeedbackMutation.mutate({
        filename: file?.filename,
        sourcecode: encode(file?.sourcecode),
      });
    } else {
      console.log("ASAVING.. challenge", {
        filename: file?.filename,
        sourcecode: file?.sourcecode,
      });

      saveCodeFeedbackMutation.mutate({
        filename: file?.filename,
        sourcecode: encode(file?.sourcecode),
      });
    }

    // const editedCode = {
    //   ...file,
    //   sourcecode: file?.sourcecode,
    //   editing: false,
    // };
    // setOpenFiles(
    //   openFiles.map((file, index) =>
    //     index === currentIndex ? editedCode : file
    //   )
    // );
  };

  return (
    <div
      className="w-screen h-screen"
      style={{ overflow: "hidden", height: "100vh", width: "100vw" }}
    >
      {modal.show && (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          props={modal.props}
          CustomComponent={modal.CustomComponent ?? false}
          // videoUrl={"https://youtu.be/Uv9URjIBtUQ"}
        />
      )}
      {/* <div className="compiler-nav"></div> */}

      <Split
        className="split"
        sizes={horizontalSizes}
        // onDrag={(e) => console.log("onDrag", e)}
        // onDragStart={(e) => console.log("onDragStart", e)}
        onDragEnd={(s) => {
          console.log("onDragEnd", s);
          setHorizontalSizes(s);
          // localStorage.setItem("horizontalSizes", JSON.stringify(s));
        }}
      >
        <div
          className="w-1/3 h-screen bg-white p-2 overflow-y-scroll "
          // style={{ height: "100vh", overflow: "hidden", overflowY: "scroll"  }}
        >
          <p className="text-md font-bold uppercase">
            {challenge?.challengename}
          </p>
          <Tab.Container
            defaultActiveKey={questionTab}
            activeKey={questionTab}
            className="h-100"
          >
            <Nav variant="pills">
              <Nav.Item onClick={() => setQuestionTab("description")}>
                <Nav.Link eventKey={"description"}>Description</Nav.Link>
              </Nav.Item>
              {isTutor && (
                <Nav.Item onClick={() => setQuestionTab("compilations")}>
                  <Nav.Link eventKey={"compilations"}>Compilations</Nav.Link>
                </Nav.Item>
              )}
              {!assignment && (
                <Nav.Item onClick={() => setQuestionTab("submissions")}>
                  <Nav.Link eventKey={"submissions"}>Submissions</Nav.Link>
                </Nav.Item>
              )}
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey={"description"}>
                <div className="m-2">
                  {/* {challenge?.questionfile &&
                    challenge?.questionfile.includes(".md") && (
                      <MarkdownToReact url={challenge?.questionfile} />
                    )} */}
                  <MarkdownToHTML html={challenge?.questionfile} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={"compilations"}>
                <Results
                syncSubmissions={syncSubmissions}
                  testSubmission={true}
                  lectureId={lectureid}
                  encode={encode}
                  decode={decode}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={"submissions"}>
                <Results
                syncSubmissions={syncSubmissions}
                  lectureId={lectureid}
                  encode={encode}
                  decode={decode}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <div className="w-2/3 h-screen bg-purple-400">
          <Split
            className="split-col h-screen"
            direction="vertical"
            sizes={verticalSizes}
            onDragEnd={(s) => {
              console.log("onDragEnd", s);
              setVerticalSizes(s);
              // localStorage.setItem("verticalSizes", JSON.stringify(s));
            }}
            // sizes={["70%", "30%"]}
          >
            <div className="w-full h-1/2 bg-black">
              {/* {isLoading ? (
                <p className="text-white">Loading...</p>
              ) : (
                isSuccess &&
                courseCodingQuestionProgress && (
                  <MultiFileEditor onFileSave={saveCode} />
                )
              )} */}
              <MultiFileEditor onFileSave={saveCode} />
              {/* <Editor
            className="monaco-editor"
            height="100%"
            width="100%"
            theme="vs-dark"
            path={projectFiles[currentFile]?.name}
            language={projectFiles[currentFile]?.language}
            defaultLanguage={projectFiles[currentFile]?.language}
            value={projectFiles[currentFile]?.value}
            onValidate={handleEditorValidation}
            beforeMount={handleEditorWillMount}
            onMount={handleEditorDidMount}
            onChange={(value, event) => handleOnChange(value, event, "source")}
            options={{
              automaticLayout: true,
              fontLigatures: true,
              fontSize: 16,
              minimap: {
                enabled: false,
              },
              renderLineHighlight: "line",
              renderLineHighlightOnlyWhenFocus: false,
              cursorSmoothCaretAnimation: true,
              cursorBlinking: "smooth",
            }}
          /> */}
            </div>
            <div
              className="w-full h-1/2"
              style={{ backgroundColor: "#1e1e1e", zIndex: 1 }}
            >
              <Tab.Container
                defaultActiveKey={consoleTab}
                activeKey={consoleTab}
                className="h-full w-full"
              >
                <Nav variant="pills w-full">
                  <div className="w-100 flex justify-between items-center">
                    <div className="flex">
                      <Nav.Item onClick={() => setConsoleTab("input")}>
                        <Nav.Link eventKey={"input"}>Input</Nav.Link>
                      </Nav.Item>
                      <Nav.Item onClick={() => setConsoleTab("output")}>
                        <Nav.Link eventKey={"output"}>Output</Nav.Link>
                      </Nav.Item>
                      <Nav.Item onClick={() => setConsoleTab("results")}>
                        <Nav.Link eventKey={"results"}>Results</Nav.Link>
                      </Nav.Item>
                    </div>
                    <div className="nav-right z-10">
                      <button onClick={handleCodeCompile}>
                        {compileLoading ? (
                          <Puff
                            height="25"
                            width="25"
                            radius={1}
                            color="white"
                            ariaLabel="puff-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        ) : (
                          "Compile"
                        )}
                      </button>
                      {displaySubmitButton()}
                    </div>
                  </div>
                </Nav>

                <Tab.Content style={{ zIndex: 9999 }}>
                  <Tab.Pane eventKey={"input"}>
                    <Editor
                      className="monaco-editor"
                      height="30vh"
                      width="100%"
                      theme="vs-dark"
                      path={projectFiles["input"]?.name}
                      language={projectFiles["input"]?.language}
                      defaultLanguage={projectFiles["input"]?.language}
                      value={projectFiles["input"]?.value}
                      onChange={(value, event) =>
                        handleOnChange(value, event, "input")
                      }
                      options={{
                        automaticLayout: true,
                        fontSize: 16,
                        scrollBeyondLastLine: false,
                        language: "plaintext",
                        minimap: {
                          enabled: false,
                        },
                      }}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={"output"}>
                    <Editor
                      className="monaco-editor"
                      height="30vh"
                      width="100%"
                      theme="vs-dark"
                      path={projectFiles["output"]?.name}
                      language={projectFiles["output"]?.language}
                      defaultLanguage={projectFiles["output"]?.language}
                      value={projectFiles["output"]?.value}
                      options={{
                        automaticLayout: true,
                        fontSize: 16,
                        scrollBeyondLastLine: false,
                        readOnly: true,
                        language: "plaintext",
                        minimap: {
                          enabled: false,
                        },
                      }}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={"results"}>
                    <Editor
                      className="monaco-editor"
                      height="30vh"
                      width="100%"
                      theme="vs-dark"
                      path={"results"}
                      language={"plaintext"}
                      value={
                        assignment
                          ? loadRunningTestCases()
                          : loadSubmissionResult()
                      }
                      options={{
                        automaticLayout: true,
                        fontSize: 16,
                        scrollBeyondLastLine: false,
                        readOnly: true,
                        language: "plaintext",
                        minimap: {
                          enabled: false,
                        },
                      }}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Split>
        </div>
      </Split>
    </div>
  );
}

export default Compiler;
