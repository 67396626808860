import { useState, useEffect, useContext } from "react";

import DashboardNavbar from "component/navbar/Navbar";
// import DashboardNavbar from '';
import { UserContext } from "context/UserProvider";
import classes from "../DashBoard/Dashboardnew.module.css";
import TutorService from "actions/services/tutor-service";
import Job from "./Job";
import PaginateJobs from "./PaginateJobs";
import JobsService from "actions/services/jobs.service";
import { LoadingContext } from "context/LoadingProvider";
import { Mixpanel } from "Utils/Mixpanel";

const Jobs = () => {
  const { user, setUser } = useContext(UserContext);

  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [jobs, setJobs] = useState(null);
  const { setLoading } = useContext(LoadingContext)

  useEffect(() => {
    
    Mixpanel.track("Web Page view", {
      "Page url": "/jobs-page",
      "Page name": "List of Jobs Page",
    });
    setLoading(true)
    const funct = async () => {
      try {
        let res3 = await TutorService.getUserAccountDetails();
        setMinutesRemaining(res3.data?.totalminutes);
        //console.log('res3======== Tutor deets', res3);

        await JobsService.getJobsByUser().then((res) => {
          setJobs(res.data);
          // setJobs([1,2,3,4]);
          console.log("RESDATA", res.data);
        });
      } catch (error) {
        console.log(error);
      }
    };

    funct();
    setLoading(false)
  }, []);

  return (
    <div className={`${classes.container1}`}>
      <DashboardNavbar minutesRemaining={minutesRemaining} />
      <div className={classes.title}>
        <h1>Jobs</h1>
      </div>
      <div className={classes.subContainer}>
        {jobs && <PaginateJobs itemsPerPage={5} jobs={jobs} />}
      </div>
    </div>
  );
};

export default Jobs;
