import { useContext, useEffect, useState } from "react";
import { Constants } from "Constants";
import { BiTime } from "react-icons/bi";

import { FaPlayCircle } from "react-icons/fa";
import ReactPlayer from "react-player";
import learningpathService from "actions/services/learningpath.service";

import { UserContext } from "context/UserProvider";
import { useQuery } from "react-query";

import ZPLoading from "pages/Watch/components/ZPLoading";
import Error from "components/Error/Error";
import isBrowsingFromPhone from "Utils/DetectDeviceBrowser";

import "./ContinueLearning.css";
import { useHistory } from "react-router-dom";
import { ControlsContainer } from "pages/Watch/components/VideoPlayerControls/VideoPlayerControls.styled";
import { StandyByInfo } from "pages/Watch/components/InactiveVideoInfo/InactiveVideoInfo.styled";
import { resumeCourseEventTracking } from "../../../Utils/WebEngage";

function ContinueLearningCard({ isOnboardingComplete, continueLearning }) {
  const { user } = useContext(UserContext);
  const [hover, setHover] = useState(false);
  const history = useHistory();

  // const [isOnboardingComplete, setIsOnboardingComplete] = useState(true);

  //queries

  // useEffect(() => {
  //   if (
  //     user?.data?.onboardingquestions &&
  //     user?.data?.onboardingquestions.length !== 0
  //   ) {
  //     console.log(
  //       "USER cl",
  //       user.data.onboardingquestions[0].reelOnboardingComplete == ""
  //     );
  //     setIsOnboardingComplete(
  //       user.data.onboardingquestions[0].reelOnboardingComplete == ""
  //         ? false
  //         : true
  //     );
  //   }
  // }, []);

  const handleRedirect = () => {
    const resumeVideo = continueLearning?.data?.resumevideo;
    if (isOnboardingComplete) {
      if (resumeVideo) {
        if (resumeVideo.ismobilelecture) {
          history.push(
            `/quiz-reel?courseid=${resumeVideo.course}&lectureid=${resumeVideo._id}&unitid=${resumeVideo.courseunit}`
          );
        } else {
          resumeCourseEventTracking(continueLearning)
          window.open(
            `/watch/${resumeVideo.course}/${resumeVideo.courseunit}/${resumeVideo._id}`,
            "_self"
          );
          // history.push(
          //   `/watch/${resumeVideo.course}/${resumeVideo.courseunit}/${resumeVideo._id}`
          // );
          // if (!isBrowsingFromPhone()) {
          //   // history.push(
          //   //   `/code?courseid=${continueLearning?.resumevideo?.course}&lectureid=${continueLearning?.resumevideo?._id}&unitid=${continueLearning?.resumevideo?.courseunit}`
          //   // );
          //   history.push(
          //     `/watch/${resumeVideo.course}/${resumeVideo.courseunit}/${resumeVideo._id}`
          //   );
          // } else {
          //   alert(
          //     "Please log into your desktop to access this course. You can explore mobile courses on your dashboard."
          //   );
          //   history.push(`/updated-dashboard`);
          // }
        }
      } else {
        alert(
          "Course is not set, please wait or contact hello@zaio.io or telegram group for notifying us."
        );
      }
    } else {
      history.push(`/quiz-reel?onboarding=true`);
    }
  };

  // if (isLoading) return <ZPLoading />;
  // if (isError) return <Error message={error} />;
  if (continueLearning?.success === false) {
    return (
      <div className="dash-container cl-container d-flex justify-content-center align-items-center text-white">
        <h1>
          <mark>Start Learning</mark>
        </h1>{" "}
        choose a course from below.
      </div>
    );
  }
  const resumeVideo = continueLearning?.data?.resumevideo;
  return (
    <div
      className={`dash-container cl-container ${
        resumeVideo?.ismobilelecture || !isOnboardingComplete
          ? "mobile-player-cl-container"
          : ""
      }`}
      onClick={() => handleRedirect()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        height: 750
      }}
    >
      {/* <p className={classes.cardHeading}>Continue learning</p> */}

      <div className={`course-note course-percentage-progress-show`}>
        {/* <small>
          {Math.floor(
            continueLearning?.data?.courseEnrolledPercentageleftInDecimal * 100
          )}
          % to completion
        </small> */}
        <small>
          Continue Learning (
          {Math.floor(
            continueLearning?.data?.courseEnrolledPercentageleftInDecimal * 100
          )}
          % to go)
        </small>
      </div>

      {hover && (
        <ControlsContainer show={hover}>
          <div className="cl-video-title">
            <h3 className="text-white">
              {!isOnboardingComplete
                ? Constants.onboardingVideoTitle
                : continueLearning?.data?.resumevideo?.lecturename}
            </h3>
          </div>
        </ControlsContainer>
      )}
      <FaPlayCircle className="yellow-course-icon pulse" />

      {/* <h1 className="cl-video-title yellow-course-icon ">TESTIBG U!@#</h1> d */}

      <div
        className={`continue-learning-player  ${
          resumeVideo?.ismobilelecture || !isOnboardingComplete
            ? "mobile-player-cl"
            : ""
        }`}
      >
        <ReactPlayer
          id={"continueLearning"}
          url={
            isOnboardingComplete
              ? (continueLearning &&
                  continueLearning?.data?.resumevideo?.lectureurl) ||
                ""
              : Constants.onboardingVideo
          }
          playing={true}
          // width={"100%"}
          // height={"100%"}
          playsinline={true}
          loop={true}
          volume={0}
          muted={true}
          onProgress={(playerState) => {
            // if(playerState.playedSeconds > 20) {
            //   set
            // }
          }}
        />
      </div>

      {/* <img
                  alt="main_img"
                  src={
                    continueLearning && continueLearning.resumecourse
                      ? continueLearning.resumecourse.coursethumbnailurl
                      : "https://d27jswm5an3efw.cloudfront.net/app/uploads/2019/07/insert-image-html.jpg"
                  }
                  className={classes.img}
                /> */}

      {/* <div className={classes.a}>
            {<AiFillPlayCircle className={classes.playIcon} />}
          </div> */}

      {/* <div className="p-2 d-flex justify-content-between">
        <span
          // className={classes.left}
          style={{
            fontSize: "16px",
            wordWrap: "break-word",
            color: "white",
          }}
        >
          {isOnboardingComplete
            ? continueLearning?.data?.resumevideo?.lecturename
            : Constants.onboardingVideoTitle}
        </span>

        <span
          style={{
            color: "#A9A9A9",
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          {<BiTime style={{ fontSize: "19px", marginRight: "3px" }} />}{" "}
          {isOnboardingComplete
            ? continueLearning?.data?.resumevideo?.duration
            : Constants.onboardingVideoDuration}
          min
        </span>
      </div> */}
    </div>
  );
}

export default ContinueLearningCard;
