import { useState } from "react";
import MarkdownToReact from "pages/Competitions/MarkdownToReact";
import LockedHint from "./LockedHint";

function Hint({ item, competitionid, teamid }) {
  const [isPurchased, setIsPurchased] = useState(!!item?.purchased);
  if (isPurchased) return <MarkdownToReact url={item.hint} />;

  return (
    <LockedHint
      title={item?.title}
      description={item?.description}
      price={item?.price}
      teamid={teamid}
      competitionid={competitionid}
      hintid={item?._id}
      setIsPurchased={setIsPurchased}
    />
  );
}

export default Hint;
