import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import AuthService from "actions/services/auth.service";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "context/UserProvider";
import { LoadingContext } from "context/LoadingProvider";
import GoogleLogin from "react-google-login";

import googleLoginIcon from "assets/img/dashboard/googleLoginIcon.svg";
import { Mixpanel } from "Utils/Mixpanel";

const Login = () => {
  const history = useHistory();
  const queries = new URLSearchParams(history.location.search);
  const { user, setUser } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);
  const [state, setState] = useState({
    username: "",
    password: "",
    success: !!user,
    message: "",
    redirect: queries.get("redirect") || "/updated-dashboard",
    src: "zaio.io",
  });
  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/login",
      "Page name": "Login",
    });
    const queries = new URLSearchParams(history.location.search);
    if (queries.has("username")) {
      setState({
        ...state,
        email: queries.get("email"),
        username: queries.get("username"),
        phonenumber: queries.get("phone"),
        src: queries.get("src"),
      });
    }
  }, []);

  const onChangeUsername = (e) => {
    setState({
      ...state,
      username: e.target.value,
    });
  };

  const onChangePassword = (e) => {
    setState({
      ...state,
      password: e.target.value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setState({
      ...state,
      message: "",
      successful: false,
    });

    setLoading(true);

    AuthService.login(state.username, state.password, state.src)
      .then((response) => {
        console.log("signin");
        //mixpanel

        // alert("RESPONSE:" + JSON.stringify(response) + " ");

        if (response.success) {
          Mixpanel.identify(state.username);
          Mixpanel.identify(state.username);
          Mixpanel.people.set({
            $email: state.username,
            $distinct_id: state.username,
          });
          Mixpanel.track("Login", { method: "normal" });

          console.log("signin", response);
          if (response?.data?.onboardingProgress?.userDetailsStep === false) {
            setUser({ ...user, ...response.data });
            history.push("/onboarding");
          } else if (
            response?.data?.onboardingProgress?.paymentDetailsStep === false
          ) {
            setUser({ ...user, ...response.data });
            history.push("/onboarding/payments");
          } else if (
            response?.data?.onboardingProgress?.questionsStep === false
          ) {
            setUser({ ...user, ...response.data });
            history.push("/onboarding/questions");
          } else if (response.data.enrolled) {
            setUser({ ...user, ...response.data });
            history.push("/updated-dashboard");
          } else if (!response.data.username) {
            history.push("/completesignin");
          }
          if (response.data.isadmin) {
            history.push("/admin/paid-users");
          }
          setState({
            ...state,
            success: true,
            message: response.message,
          });
          setUser({ ...response, ...response.data });
        } else {
          const resMessage = response.message;
          setState({
            ...state,
            successful: false,
            message: resMessage,
          });
        }
      })
      .catch((reject) => alert("somethingwentwrong"))
      .then(() => setLoading(false));
  };

  const responseSuccessGoogle = (response) => {
    setLoading(true);
    AuthService.googleLogin(response.tokenId)
      .then((response) => {
        //mixpanel

        if (response.success) {
          Mixpanel.identify(state.username);
          Mixpanel.people.set({
            $email: state.username,
            $distinct_id: state.username,
          });
          Mixpanel.track("Login", { method: "google" });

          if (!response.alreadyRegister) {
            console.log("alreadyRegister2", response.alreadyRegister);
            // setUser({ ...user, ...response.data });
            history.push("/completesignin");
          }
          if (response?.data?.onboardingProgress?.userDetailsStep === false) {
            setUser({ ...user, ...response.data });
            history.push("/onboarding");
          } else if (
            response?.data?.onboardingProgress?.paymentDetailsStep === false
          ) {
            setUser({ ...user, ...response.data });
            history.push("/onboarding/payments");
          } else if (
            response?.data?.onboardingProgress?.questionsStep === false
          ) {
            setUser({ ...user, ...response.data });
            history.push("/onboarding/questions");
          } else if (response.data.enrolled) {
            setUser({ ...user, ...response.data });
            history.push("/updated-dashboard");
          } else if (response.data.isadmin) {
            history.push("/admin/paid-users");
          }
          setState({
            ...state,
            success: true,
            message: response.message,
          });
          setUser({ ...response, ...response.data });
        } else {
          const resMessage = response.message;
          setState({
            ...state,
            successful: false,
            message: resMessage,
          });
        }
      })
      .catch((reject) => alert("somethingwentwrong"))
      .then(() => setLoading(false));
    // console.log(response);
  };

  const responseErrorGoogle = (response) => {
    console.log(response);
  };

  if (state.success && user?.success) {
    // alert("REDIRECTING NOW...")

    history.push(
      user?.data?.accBlocked ? "/account-blocked" : `${state.redirect}`
    );
    return null;
  } else {
    return (
      <div className="d-flex justify-content-center m-3">
        <form
          className="signup-form col-12 col-md-6 col-lg-4 m-4 p-sm-2 p-md-4 border-4 rounded border-primary bg-white"
          onSubmit={handleLogin}
        >
          <div className="d-flex justify-content-center">
            <h3 className="mt-2 mb-3 mediumBlackText">Login</h3>
          </div>

          <GoogleLogin
            clientId="26179719531-0i4v58bc6afmg2b2pjvlg1bd443e55u0.apps.googleusercontent.com"
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="w-100 btn-lg googleLoginButton"
              >
                <span className="googleLoginIcon">
                  <img src={googleLoginIcon} alt="" />
                </span>
                Sign in with Google
              </button>
            )}
            buttonText="Login"
            onSuccess={responseSuccessGoogle}
            onFailure={responseErrorGoogle}
            cookiePolicy={"single_host_origin"}
          />
          <div className="login-heading-container">
            <hr className="dashed" />
            <p className="p">Login with Email</p>
            <hr className="dashed" />
          </div>
          {state.message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {state.message}
              </div>
            </div>
          )}
          <div className="form-group">
            <label
              className="apercuProText font_16 pb-2 pt-2"
              htmlFor="username"
            >
              Email
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="username"
              placeholder="email@address.com"
              value={state.username}
              onChange={onChangeUsername}
              required
            />
          </div>

          <div className="form-group">
            <label
              className="apercuProText font_16 pb-2 pt-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              className="form-control form-control-lg"
              name="password"
              placeholder="******"
              value={state.password}
              onChange={onChangePassword}
              required
            />
          </div>

          <div className="form-group">
            <button
              type="submit"
              className="btn btn-main w-100 btn-lg"
              disabled={state.loading}
            >
              {state.loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Login</span>
            </button>
          </div>
          <div className="d-flex justify-between">
            <div className="forgot-password d-flex flex-col items-start mt-1">
              <span className="text-sm text-gray-500">New to ZAIO? </span>
              <Link className="text-xs text-purple-400" to={`/onboarding`}>
                Get Started
              </Link>
            </div>
            <div className="forgot-password d-flex flex-col items-end mt-1">
              <span className="text-sm text-gray-500"> Forgot password? </span>
              <Link className="text-xs  text-purple-400" to={`/forgotpassword`}>
                Forgot Password
              </Link>
            </div>
          </div>
          {/* <div>
            <button
              className='btn btn-primary btn-block'
              disabled={state.loading}
              style={{ color: "#17325b", backgroundColor: "white" }}
              onClick={openSnapplify}

            >
              {state.loading && (
                <span className='spinner-border spinner-border-sm'></span>
              )}
              <span>SIGN IN WITH SNAPPLIFY</span>
            </button>
          </div> */}
        </form>
      </div>
    );
  }
};

export default Login;
