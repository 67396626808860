import axios from "axios";
import authHeader from "./auth-header";

// const API_URL = 'https://zaio-dev.herokuapp.com'
const API_URL = process.env.REACT_APP_BACKEND_URL;
// const API_URL = 'http://localhost:5000'

const getDemo = {
  postBusinessDemoForm(data) {
    return axios
      .post(API_URL + "/businessdemo", {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        phonenumber: data.phonenumber,
        companyname: data.companyname,
        jobtitle: data.jobtitle,
        demoreason: data.demoreason,
      })
      .then((res) => {
        //   console.log(res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log(rej.data);
        return rej;
      });
  },

  getTotalMinutes() {
    return axios
      .get(API_URL + "/supportsession")
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        return rej;
      });
  },

  spportSession(data) {
    return axios
      .post(API_URL + "/supportsession/addsession", data)
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        return rej;
      });
  },


  userTutorAccount() {
    return axios
      .get(API_URL + "/usertutoraccount")
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        return rej;
      });
  },

  topupUserAccount(data) {
    return axios
      .post(API_URL + "/usertutoraccount/topupuseraccount", data)
      .then((res) => {
        return res.data;
      })
      .catch((rej) => {
        return rej;
      });
  },

  postSyllabusForm(data) {
    return axios
      .post(API_URL + "/syllabus", {
        fullname: data.fullname,
        email: data.email,
        contactnumber: data.contactnumber,
        occupation: data.occupation,
        firstcourse: data.firstcourse,
        heardfrom: data.heardfrom,
      })
      .then((res) => {
        //   console.log(res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log(rej.data);
        return rej;
      });
  },
  postAddToWaitingList(data) {
    return axios
      .post(API_URL + "/waitinglist", {
        username: data.username,
        email: data.email,
      })
      .then((res) => {
        // console.log('then',res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return rej.response;
      });
  },
  getWaitingList() {
    return axios
      .get(API_URL + "/waitinglist")
      .then((res) => {
        // console.log('then',res);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response)
        return rej.response;
      });
  },
  async getUsers() {
    return await axios.get(API_URL + "/users", {
      headers: authHeader(),
    });
  },
  async getPaidUsers() {
    return await axios.get(API_URL + "/general/allusersinfo", {
      headers: authHeader(),
    });
  },
  async deleteUser(email) {
    console.log(email);
    return await axios.delete(
      API_URL + "/dashboard/deleteuser",
      { data: { email } },
      {
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
      },

    ).then((res) => {
      // console.log('then',res);
      return res.data;
    })
      .catch((rej) => {
        // console.log('catch',rej.response)
        return rej.response;
      });;
  },
};

export default getDemo;
