import ChatBubble from "pages/DashBoard/ChatBubble";
import React from "react";

function QuizOverview({ handleStartQuiz, assignment = false }) {
  return (
    <div>
      <ChatBubble
        title={assignment ? "Time to practise some concepts." : "Let's practise what we went through!"}
        text={
          assignment ? "This section will be auto-marked" : "Take a minute to do this quiz, and then we'll move on to the next lesson."
        }
        showLive={false}
        // hideImage={true}
        // backgroundColor={isSolutionCorrect ? "#D1F2EB" : "#FADBD8"}
      />
    </div>
  );
}

export default QuizOverview;
