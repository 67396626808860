import React, { useEffect, useRef, useState, useContext } from "react";
import DashboardNavbar from "component/navbar/Navbar";

import { UserContext } from "context/UserProvider";
import { Mixpanel } from "Utils/Mixpanel";
import UserAssignmentsTable from "./UserAssignmentsTable";
import lectureService from "actions/services/lecture.service";
import { useQuery } from "react-query";
import { MdLocationDisabled } from "react-icons/md";
import Loader from "components/Loader/Loader";

const UserAssignment = () => {
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/assignments",
      "Page name": "Assignments List page",
    });
  }, []);

  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useQuery("userAssignments", () => lectureService.getUserAssignments(), {
    onSuccess: (data) => {
      console.log("CHECKINGIT OUT", data);
    },
  });

  const loadTable = () => {
    if (isLoading) {
      return <Loader loadDirect={true} />;
    }

    if (isError) {
      return <p>Error occured: {JSON.stringify(isError)}</p>;
    }

    return <UserAssignmentsTable data={response?.data} />;
  };

  return (
    <>
      <div className="dashboard-container">
        <DashboardNavbar />
        <div className="title-container">
          <div className="title">
            <h1>Assignments</h1>
            <p className="italic text-sm px-4 rounded-full bg-purple-400">Your assignments will be released as you complete challenges.</p>
          </div>
        </div>
        {loadTable()}
      </div>
    </>
  );
};
export default UserAssignment;
