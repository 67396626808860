import React, { useState, useContext, useEffect } from "react";
import "./VideoFooter.css";
import TextTruncate from "react-text-truncate"; // recommend
import QuizOptions from "../QuizOptions/QuizOptions";
import { MdQuiz } from "react-icons/md";
import { UserContext } from "context/UserProvider";
// import useVideoPlayer from "../../hooks/useVideoPlayer";
import ReelModal from "../ReelModal/ReelModal";
import { useHistory } from "react-router-dom";
import { Mixpanel } from "Utils/Mixpanel";

function VideoFooter({
  title,
  description,
  displayQuiz,
  tryAgainQPStringTime,
  jumpToTryAgainStringTime,
  jumptoContinueStringTime,
  handleQuizOptionSelect,
  quizOptions,
  hasQuiz,
  countDown,
}) {
  // console.log("videofooter", quizOptions)
  const history = useHistory();
  const [max, setMax] = useState(false);

  const { user, setUser } = useContext(UserContext);
  const [modal, setModal] = useState({
    show: false,
    title: "HELO HELO",
    description:
      "Try sign up, it will take a min, and you will be get a personalized experience - ITS FREE.",
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      title: "",
      description:
        "Try sign up, it will take a min, and you will be get a personalized experience - ITS FREE.",
    });
  };

  const handleSignUp = () => {
    Mixpanel.track("Button Click", {
      "Button name": "Sign up",
      "Button context": "Reel Caption footer",
    });

    setModal({
      ...modal,
      show: true,
      title: "Want to access signup?",
    });
  };

  const handleSkip = () => {
    Mixpanel.track("Skipped Onboarding");
    history.push("/getstarted");
  };

  return (
    <>
      {modal.show && (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
        />
      )}
      <div
        className={`videoContainer ${
          max || displayQuiz ? "maxVideoContainer" : ""
        }`}
      >
        <div className="videoFooter">
          <div className="quizOptions">
            {displayQuiz && hasQuiz ? (
              <QuizOptions
                quizOptions={quizOptions}
                handleQuizOptionSelect={handleQuizOptionSelect}
                tryAgainQPStringTime={tryAgainQPStringTime}
                jumpToTryAgainStringTime={jumpToTryAgainStringTime}
                jumptoContinueStringTime={jumptoContinueStringTime}
              />
            ) : null}
            {/* {isOptionSelected ? <QuizOptions quizOptions={quizOptions} handleQuizOptionSelect={handleQuizOptionSelect} />} */}
          </div>
          <div className="post-pic">
            {hasQuiz ? (
              <MdQuiz className="icons" />
            ) : (
              <img
                alt="pic of course"
                src="https://res.cloudinary.com/ak-124210/image/upload/v1642434078/yjnzwmihmhghxkwnb6n9.png"
              />
            )}

            <span className="profile-name">{title}</span>
            {!displayQuiz && (!user || !user?.success) ? (
              <>
                {!countDown && (
                  <button className="signup-cta" onClick={handleSkip}>
                    Skip
                  </button>
                )}

                <button className="signup-cta" onClick={handleSignUp}>
                  Signup
                </button>
              </>
            ) : null}
          </div>
          <div className="video-caption">
            {!max ? (
              <TextTruncate
                line={1}
                element="span"
                truncateText="…"
                text={description}
                textTruncateChild={
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setMax(true)}
                  >
                    <strong>view more</strong>
                  </span>
                }
              />
            ) : (
              <span style={{ cursor: "pointer" }} onClick={() => setMax(false)}>
                {description} <br />
                <strong style={{ marginTop: "1rem" }}>view less</strong>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoFooter;
