import moment from "moment";

function encode(str) {
  return btoa(unescape(encodeURIComponent(str || "")));
}

function decode(bytes) {
  var escaped = "";

  try {
    escaped = escape(atob(bytes || ""));
  } catch (e) {
    console.log("ERROR", e);
    return "";
  }

  try {
    return decodeURIComponent(escaped);
  } catch (e) {
    console.log("ERROR", e);
    return unescape(escaped);
  }
}

const DEFAULT_ACTIVITY_STORE = {
  [moment(Date.now()).format("DD-MM-YYYY")]: {
    video: {
      // id: {
      //   started: "",
      //   ended: "",
      //   timeTaken: null,
      // },
    },
    challenge: {
      // id: {
      //   started: "",
      //   ended: "",
      //   timeTaken: null,
      // },
    },
    quiz: {
      // id: {
      //   started: "",
      //   ended: "",
      //   timeTaken: null,
      // },
    },
    //   summary: {
    // date: {
    //   videos: 0,
    //   challenges: 0,
    //   quizzes: 0,
    // },
    //   },
  },
};

const populateUpdatedObject = (status, storedData, type, lectureId) => {
  const currentDateTime = Date.now();
  const currentDate = moment(currentDateTime).format("DD-MM-YYYY");
  console.log(
    "\n\n\n\nINFO\n\n\n",
    status,
    storedData,
    type,
    lectureId,
    storedData[currentDate][type][lectureId]
  );
  let updatedObject;
  switch (status) {
    case "start": {
      updatedObject = {
        ...storedData,
        [currentDate]: {
          ...storedData[currentDate],
          [type]: {
            ...storedData[type],
            [lectureId]: {
              start: currentDateTime,
              end: null,
              timeTaken: null,
            },
          },
        },
      };
      console.log(
        "\n\n\n\nSTART\n\n\nINSIDE POPULATE UDPATED OBJ FOR ACTIVITY STORING\n\n\n",
        lectureId,
        updatedObject
      );
      return updatedObject;
    }
    case "end": {
      const startTime = moment(storedData?.type?.lectureId?.start);
      const currentMoment = moment(currentDateTime);
      updatedObject = {
        ...storedData,
        [currentDate]: {
          ...storedData[currentDate],
          [type]: {
            ...storedData[type],
            [lectureId]: {
              ...storedData[currentDate][type][lectureId],
              end: currentDateTime,
              timeTaken: currentMoment.diff(startTime, "minutes"),
            },
          },
        },
      };
      console.log(
        "\n\n\n\nEND\n\n\nINSIDE POPULATE UDPATED OBJ FOR ACTIVITY STORING\n\n\n",
        lectureId,
        updatedObject
      );
      return updatedObject;
    }
    default: {
      console.log("Event not tracked, need to have start or end status");
      return null;
    }
  }
};
export const trackLectureActivity = (status, type, lectureId) => {
  // This includes watching videos, doing quizzes and coding challenges
  //   Type is either video, mcq, challenge

  //   var a = moment("2016-06-06T21:03:55"); //now
  //   var b = moment("2016-05-06T20:03:55");

  //   const c = moment(Date.now());
  //   const d = moment(1679755457441);

  //   console.log(a.diff(b, "minutes")); // 44700
  //   console.log(c.diff(d, "minutes")); // 44700

  //   console.log(
  //     "\n\n\nLS:",
  //     status,
  //     type,
  //     lectureId,
  //     moment(c).format("MMM Do YY"),
  //     moment(d).format("MMM Do YY")
  //   );

  //   return;
  const lectureActivityString = localStorage.getItem("lectureActivity");
  if (!lectureActivityString) {
    console.log("STORE NOT FOUND, setting it up...");
    localStorage.setItem(
      "lectureActivity",
      encode(JSON.stringify(DEFAULT_ACTIVITY_STORE))
    );
    return;
  }
  const storedData = JSON.parse(decode(lectureActivityString));
  console.log("STORED DATA", storedData);

  const updatedObject = populateUpdatedObject(
    status,
    storedData,
    type,
    lectureId
  );

  console.log("\n\n\n\n\nUPDATED OBJ\n", updatedObject);
  localStorage.setItem(
    "lectureActivity",
    encode(JSON.stringify(updatedObject))
  );

  //   const objectToStore = {
  //     [type]: data,
  //   };

  //   const objectToStore = {
  //     [key]: data,
  //   };
  //   const storedData = JSON.parse(decode(localStorage.getItem("activity")));
  //   if (storedData) {
  //     const updatedData = {
  //       ...storedData,
  //       [id]: {
  //         ...storedData[id],
  //         ...objectToStore,
  //       },
  //     };
  //     localStorage.setItem("activity", encode(JSON.stringify(updatedData)));
  //   }
};
