import Live from "components/Live/Live";
import ZCoin from "components/ZCoin/ZCoin";
import WalletBalance from "pages/Wallet/WalletBalance";
import { PosterContainer } from "../Competitions.styled";

function Poster({
  competitionname = "",
  company = "",
  tags = undefined,
  duration = "",
  prize = "",
  bannerdescription = "",
  banner = "",
  zcoinprize = 0,
}) {
  return (
    <PosterContainer>
      <div className="overlay"></div>
      <div className="poster-info">
        <div className="poster-header">
          <div>
            {tags &&
              tags.map((tag, index) => (
                <span key={index} className="badge bg-success text-white m-1">
                  {tag}
                </span>
              ))}
          </div>
          <div>
            <Live />
          </div>
        </div>
        <div className="poster-body">
          <div className="left-info">
            <h3>{competitionname}</h3>
            <p className="p-0 m-0">{bannerdescription}</p>
          </div>
          <div className="right-info">
            <div className="p-0 m-0 d-flex align-items-center justify-content-center">
              {prize}
              <ZCoin
                number={zcoinprize}
                toolTipPlacement="top"
                toolTipText="300 Z-coins will be earned per member for winning the competition. Goodluck"
              />
            </div>

            <small>Winning Prizes for all members</small>
          </div>
        </div>
        <div
          className="poster-footer"
          onClick={() => window.open(company?.website, "_blank")}
        >
          <img className="poster-company-img" src={company?.logo} />
          <span>{company?.name}</span>
          <span className="dot">.</span>
          <span>{duration} days</span>
        </div>
      </div>
      <img
        // className="poster-img"
        src={banner}
        // src="https://marketplace.canva.com/EAENvp21inc/1/0/1600w/canva-simple-work-linkedin-banner-qt_TMRJF4m0.jpg"
      />
    </PosterContainer>
  );
}

export default Poster;
