import react, { useState, useEffect, useContext } from 'react';
import classes from './HallOfFame.module.css';

import { Button, Card, CardImg, Modal } from 'react-bootstrap';

import { AiFillPlayCircle } from 'react-icons/ai';

import hallOfFameUser1 from 'assets/img/dashboard/hallOfFameUser1.svg';
import hallOfFameUser2 from 'assets/img/dashboard/hallOfFameUser2.svg';
import hallOfFameUser3 from 'assets/img/dashboard/hallOfFameUser3.svg';
import hallOfFameUser4 from 'assets/img/dashboard/hallOfFameUser4.svg';
import hallOfFameUser5 from 'assets/img/dashboard/hallOfFameUser5.svg';
import hallOfFameUser6 from 'assets/img/dashboard/hallOfFameUser6.svg';
import hallOfFame1 from 'assets/img/dashboard/hallOfFame1.svg';
import hallOfFame2 from 'assets/img/dashboard/hallOfFame2.svg';
import hallOfFame3 from 'assets/img/dashboard/hallOfFame3.svg';

import playIcon from 'assets/img/dashboard/playIcon.svg';

import TutorService from 'actions/services/tutor-service';
import testimonial from 'actions/services/testimonial.service';
import ReactPlayer from 'react-player';

import DashboardNavbar from '../navbar/Navbar.jsx';
import { UserContext } from 'context/UserProvider';

// const getTestimonialData = [
//   {
//     name: 'Kislay profil',
//     occupation: 'UCT student',
//     videourl: 'https://www.youtube.com/watch?v=y8d2o_sbH-Y',
//     // text: 'I love Zaio, Zaio is the best. ',
//     text: 'I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaiois the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.I love Zaio, Zaio is the best.',
//     twitter: 'twitter link',
//     thumbnail: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     profileImage: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     linkedin: 'linkedin link',
//     instagram: 'instagram link',
//   },
//   {
//     name: 'Kislay profil',
//     occupation: 'UCT student',
//     videourl: 'https://www.youtube.com/watch?v=y8d2o_sbH-Y',
//     text: 'I love Zaio, Zaio is the best. ',
//     twitter: 'twitter link',
//     thumbnail: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     profileImage: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     linkedin: 'linkedin link',
//     instagram: 'instagram link',
//   },
//   {
//     name: 'Kislay profil',
//     occupation: 'UCT student',
//     videourl: 'https://www.youtube.com/watch?v=y8d2o_sbH-Y',
//     text: 'I love Zaio, Zaio is the best. ',
//     twitter: 'twitter link',
//     thumbnail: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     profileImage: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     linkedin: 'linkedin link',
//     instagram: 'instagram link',
//   },
//   {
//     name: 'Kislay profil',
//     occupation: 'UCT student',
//     videourl: 'https://www.youtube.com/watch?v=y8d2o_sbH-Y',
//     text: 'I love Zaio, Zaio is the best. ',
//     twitter: 'twitter link',
//     thumbnail: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     profileImage: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     linkedin: 'linkedin link',
//     instagram: 'instagram link',
//   },
//   {
//     name: 'Kislay profil',
//     occupation: 'UCT student',
//     videourl: 'https://www.youtube.com/watch?v=y8d2o_sbH-Y',
//     text: 'I love Zaio, Zaio is the best. ',
//     twitter: 'twitter link',
//     thumbnail: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     profileImage: 'https://biz-file.com/c/1911/528568-700x412.jpg',
//     linkedin: 'linkedin link',
//     instagram: 'instagram link',
//   },

// ];

const HallOfFame = () => {
  const { user, setUser } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [isPublic, setIsPublic] = useState(window.location.pathname === '/app/zaio-rockstars');
  const [text, setText] = useState('');
  const dummyImgUrl =
    'https://thumbs.dreamstime.com/b/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg';
  const dummyVideoUrl = 'https://i.ytimg.com/vi/kryum4bLnq8/maxresdefault.jpg';

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [getTestimonialData, setGetTestimonailData] = useState([]);
  const [minutesRemaining, setMinutesRemaining] = useState(0);

  const loadData = async () => {
    const res = await testimonial.getTestimonial();
    let resp;
    if (user) {
      resp = await TutorService.getUserAccountDetails();
      setMinutesRemaining(resp.data.totalminutes);
    }

    setGetTestimonailData(res.data.reverse());
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={`${classes.container} ${user && classes.loggedContainer}`}>
      {/* {user && <DashboardNavbar/>} */}
      {!isPublic && <DashboardNavbar minutesRemaining={minutesRemaining} />}
      <div className={classes.title}>
        <h1>Hall of fame</h1>
        {/* <Button variant="warning" className={classes.btn}>
          Submit your testimonials
        </Button> */}
      </div>
      <div className={classes.subContainer}>
        {getTestimonialData.map((item) => (
          <div
            key={item.id}
            className={classes.cardContainer}
            onClick={() => {
              setVideoUrl(item.videourl);
              setText(item.text);
              handleShow();
            }}
          >
            <div className={classes.image}>
              {/* <Button
                variant="warning"
                onClick={() => {
                  handleShow();
                  setVideoUrl(item.videourl);
                  setText(item.text);
                }}
                className={classes.playIcon}
              >
                <img src={playIcon} alt="" style={{ transform: 'translateX(2px)' }} />
              </Button> */}
              <img src={item.video_thumbnail ? item.video_thumbnail : dummyVideoUrl} alt="" />
            </div>
            <div className={classes.card}>
              <p className={classes.cardText}>
                {item.text.length > 150 ? `${item.text.slice(0, 150)}...` : item.text}
              </p>
              <div className={classes.cardBottom}>
                <img
                  src={item.user_thumbnail ? item.user_thumbnail : dummyImgUrl}
                  alt=""
                  className={classes.userImg}
                />
                <p className={classes.cardText} style={{ margin: 0, lineHeight: 1.6 }}>
                  <span style={{ color: '#fff' }}>{item.name}</span>
                  <br />
                  <span style={{ color: '#8D959C' }}>{item.occupation}</span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal centered show={show} onHide={handleClose} className={classes.modalBody}>
        <div style={{ backgroundColor: '#fff' }}>
          {/* <Modal.Header closeButton className={classes.modalHeader}> */}
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          {/* </Modal.Header> */}

          <ReactPlayer url={videoUrl} width={'100%'} controls />
          <div style={{ display: 'flex', padding: 3 }}>
            <p style={{ fontSize: '12px', margin: '0px 10px 0px' }}>{text}</p>
            <Button variant="secondary" onClick={handleClose} className={classes.closeBtn}>
              Close
            </Button>
          </div>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
        </div>
      </Modal>
    </div>
  );
};

export default HallOfFame;

{
  /* <div className={classes.subContainer}> */
}
{
  /* {getTestimonialData.map((item) => (
          <div key={item.id} className={classes.cardContainer}>
            <div className={item.videourl ? `${classes.image}` : `${classes.noimage}`}>
              
              <ReactPlayer url={item.videourl} width={320} height={300} style={{ transform: 'translateX(2px)' }} controls />
            </div>
            <div className={classes.card}>
              <p className={classes.cardText}>
                {(item.text).length > 100 ? `${item.text.slice(0, 100)}...` : item.text}
              </p>
              <div className={classes.cardBottom}>
                <img src={hallOfFameUser5} alt="" className={classes.userImg} />
                <p className={classes.cardText} style={{ margin: 0, lineHeight: 1.6 }}>
                  <span style={{ color: '#fff' }}>{item.name}</span>
                  <br />
                  <span style={{ color: '#8D959C' }}>{item.occupation}</span>
                </p>
              </div>
            </div>
          </div>
        ))} */
}
//   {cardContent.map((item) => (
//     <div
//       key={item.id}
//       className={classes.cardContainer}
//       onClick={() => {
//         handleShow();
//         setVideoUrl(item.videoUrl);
//         setText(item.text);
//       }}
//     >
//       <div className={item.image ? `${classes.image}` : `${classes.noimage}`}>
//         <Button
//           variant="warning"
//           onClick={() => {
//             handleShow();
//             setVideoUrl(item.videoUrl);
//             setText(item.text);
//           }}
//           className={classes.playIcon}
//         >
//           <img src={playIcon} alt="" style={{ transform: 'translateX(2px)' }} />
//         </Button>
//         <img src={item.image} alt="" />
//       </div>
//       <div className={classes.card}>
//         <p className={classes.cardText}>
//           {item.text.length > 100 ? `${item.text.slice(0, 100)}...` : item.text}
//         </p>
//         <div className={classes.cardBottom}>
//           <img src={item.imageUser} alt="" className={classes.userImg} />
//           <p className={classes.cardText} style={{ margin: 0, lineHeight: 1.6 }}>
//             <span style={{ color: '#fff' }}>Corina Irén</span>
//             <br />
//             <span style={{ color: '#8D959C' }}>Data Scientist</span>
//           </p>
//         </div>
//       </div>
//     </div>
//   ))}
// </div>