const IframeCode = (html, css, js) => `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/luna-object-viewer/luna-object-viewer.css" />
  <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/luna-data-grid/luna-data-grid.css" />
  <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/luna-dom-viewer/luna-dom-viewer.css" />
  <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/luna-console/luna-console.css" />
  <script src="//cdn.jsdelivr.net/npm/luna-object-viewer/luna-object-viewer.js"></script>
  <script src="//cdn.jsdelivr.net/npm/luna-data-grid/luna-data-grid.js"></script>
  <script src="//cdn.jsdelivr.net/npm/luna-dom-viewer/luna-dom-viewer.js"></script>
  <script src="//cdn.jsdelivr.net/npm/luna-console/luna-console.js"></script>
</head>

<style>
  /* CSS CODE GOES HERE */
  ${css}
  
</style>

<style>
  /* Console styles */
  /* These styles need to over write any styles that the user could have added that - thats why the ids are abit long and confusing */
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .material-symbols-outlined {
    cursor: pointer;
    margin-right: 10px;
    font-variation-settings:
      'FILL' 0,
      'wght' 400,
      'GRAD' 0,
      'opsz' 48
  }

  #akhil1234maragmailka1234xyzabcparent {
    /* position: relative; */
  }

  #akhil1234maragmailka1234xyzabcpreview {
    height: 97vh;
    width: 100vw;
    overflow: hidden;
    overflow-y: scroll;
  }

  #akhil1234maragmailka1234xyzabccontainer {
    position: fixed;
    bottom: 0;
    left: 0;
    overflow: hidden;
    height: 20vh;
    width: 100vw;
    display: none;
    margin: 0;
    padding: 0;

  }

  #akhil1234maragmailka1234xyzabccontainerwithoutconsole {
    position: fixed;
    bottom: 0;
    left: 0;
    overflow: hidden;
    height: 3vh;
    width: 100vw;
    cursor: pointer;
    margin: 0;
    padding: 0;
    /* display: none; */
  }

  #akhil1234maragmailka1234xyzabccontainer__header {
    height: 3vh;
    background-color: #f1f3f4;
    margin: 0;
    padding: 0;
    border: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #akhil1234maragmailka1234xyzabccontainer__body {
    height: 37vh;
    /* overflow: hidden; */
    overflow-y: scroll;
    padding-bottom: 5rem;
  }

  #akhil1234maragmailka1234xyzabccontainer__header-btn {
    padding: 7.5px 10px;
    /* background-color: lightgray; */
    border: none;

    cursor: pointer;
    font-size: 16px;
    color: #5f6367;
    border-bottom: 2px solid #8437f9;
    border-top: 1px solid lightgray;
  }
</style>

<div id="akhil1234maragmailka1234xyzabcparent">
  <div id="akhil1234maragmailka1234xyzabcpreview">
    <!-- HTML CODE GOES HERE -->
    ${html}
  </div>
  <div id="akhil1234maragmailka1234xyzabccontainer">
    <div id="akhil1234maragmailka1234xyzabccontainer__header">
      <button id="akhil1234maragmailka1234xyzabccontainer__header-btn">Console</button>
      <span id="akhil1234maragmailka1234xyzabccontainer__header-minimize-console" class="material-symbols-outlined">
        expand_more
      </span>
    </div>
    <div id="akhil1234maragmailka1234xyzabccontainer__body"></div>
  </div>
  <div id="akhil1234maragmailka1234xyzabccontainerwithoutconsole">
    <div id="akhil1234maragmailka1234xyzabccontainer__header">
      <button id="akhil1234maragmailka1234xyzabccontainer__header-btn">Console</button>
      <span id="akhil1234maragmailka1234xyzabccontainerwithoutconsole__header-minimize-console"
        class="material-symbols-outlined">
        expand_less
      </span>
    </div>
  </div>
</div>

<script>
  // setup stuff
  const previewContainer = document.getElementById('akhil1234maragmailka1234xyzabcpreview')
  const collapsedConsoleContainer = document.getElementById('akhil1234maragmailka1234xyzabccontainerwithoutconsole')
  const consoleContainer = document.getElementById('akhil1234maragmailka1234xyzabccontainer')
  const consoleContainerBody = document.getElementById('akhil1234maragmailka1234xyzabccontainer__body')
  const minimizeBtn = document.getElementById('akhil1234maragmailka1234xyzabccontainer__header-minimize-console')
  // container.style.display = "none"
  const console = new LunaConsole(consoleContainerBody);
  window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
    console.error(errorMsg);
}

  const displayConsole = () => {
    consoleContainer.style.display = "block"
    collapsedConsoleContainer.style.display = "none"
    consoleContainer.style.height = "40vh"
    previewContainer.style.height = "60vh"
  }
  const hideConsole = () => {
    consoleContainer.style.display = "none"
    collapsedConsoleContainer.style.display = "block"
    consoleContainer.style.height = "3vh"
    previewContainer.style.height = "97vh"
  }

  collapsedConsoleContainer.addEventListener('click', () => {
    displayConsole();
    
  })
  minimizeBtn.addEventListener('click', () => {
    hideConsole()
  })
  console.log("Zaio Console")

  if(true) {
    displayConsole()
  } else {
    hideConsole()
  }
</script>
<script>
  try {
    // JS CODE GOES HERE
    ${js}
  } catch (error) {
    console.log("Error in your code", error?.message)
    console.error(error)
  }
</script>

</html>
`;

export default IframeCode;
