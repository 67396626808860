import { Loading } from "../ZaioVideoPlayer.styled";

export default function ({ primaryColor="#8437f9" }) {
  return (
    <Loading color={primaryColor}>
      <div>
        <div />
        <div />
        <div />
      </div>
    </Loading>
  );
}
