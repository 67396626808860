import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./notebook-feedback-modal.css";
import moment from "moment";

export default function NotebookFeedbackModal({
  show,
  onHide,
  title,
  feedbackUrl
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (show) setLoading(true);
  }, [show]);

  const handleIframeLoad = () => {
    setLoading(false);
  };


  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      dialogClassName="notebook-feedback-modal"
      contentClassName="w-100 h-100"
      aria-labelledby="sliding-dialog-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="sliding-dialog-title">
          Last submission feedback ({moment(show?.createdAt).fromNow()})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {loading && (
          <div className="d-flex justify-content-center align-items-center h-100">
            <img
              className="ms-4"
              width={86}
              alt="loader"
              height={86}
              src={"/loader.svg"}
            />{" "}
          </div>
        )}
        <iframe
          src={feedbackUrl}
          title={title}
          className="w-100 h-100 border-0"
          onLoad={handleIframeLoad}
          style={{ display: loading ? "none" : "block", border: "none" }}
        />
      </Modal.Body>
    </Modal>
  );
}
