import { StylesConfig } from "Utils/Bootcamp";
import { AiOutlineVideoCamera } from "react-icons/ai";
import { BsFileEarmarkCode } from "react-icons/bs";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdAssignmentReturned, MdOutlineAssignment } from "react-icons/md";
import { RxCircle } from "react-icons/rx";

const FONT_SIZE = 20;
const Event = (props) => {
  const { event, isModal, color } = props;
  const status = event?.status ? event?.status : "default";
  const classes = StylesConfig[status]?.styles || "";

  return (
    <button
      onClick={() => {
        if (event.type === "assignment") {
          window.open(`/app/assignments`, "_blank");
        } else {
          window.open(
            `/app/watch/${event?.courseId}/${event?.courseunit}/${event?.lectureId}`,
            "_blank"
          );
        }
      }}
      key={event.lecturename}
      className={`flex justify-start mb-1 items-center w-100 p-1 ${
        isModal ? "px-2 my-2" : ""
      }`}
    >
      {["completed", "late", "advanced"]?.includes(event?.status) ? (
        <IoMdCheckmarkCircleOutline color={color} />
      ) : (
        <RxCircle color={color} />
      )}
      <div
        className={`pl-2 text-left font-medium text-sm text-dark-400 hover:text-sky-400 ${
          !isModal ? "w-5/6 truncate" : ""
        }`}
      >
        {event.lecturename}
        {isModal && (
          <p className="m-0 font-semibold text-sm text-slate-400">
            ({event.duration} Mins)
          </p>
        )}
      </div>

      {isModal && (
        <div className="ms-auto">
          {event?.type === "coursemcq" && (
            <MdOutlineAssignment size={FONT_SIZE} color={color} />
          )}
          {event?.type === "challenge" && (
            <BsFileEarmarkCode size={FONT_SIZE} color={color} />
          )}
          {event?.type === "lecture" && (
            <AiOutlineVideoCamera size={FONT_SIZE} color={color} />
          )}
          {event?.type === "assignment" && (
            <MdAssignmentReturned size={FONT_SIZE} color={color} />
          )}
        </div>
      )}
    </button>
  );
};

export default Event;
