// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useContext } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import ReactGA from "react-ga";

import "./App.css";
import "./styles/index.scss";

import Loader from "components/Loader/Loader";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import WaitingListBar from "components/WaitingListBar/WaitingListBar";
import ForgotPassword from "containers/ForgotPassword";

import { SignupProcess } from "pages/Auth/SignupProcess/SignupProcess";
import { Logout } from "pages/Auth/Logout/Logout";

import Course from "pages/Course/Course";
import PrivacyPolicy from "pages/Policies/privacypolicy/privacypolicy";
import Terms from "pages/Policies/terms/terms";
import RefundPolicy from "pages/Policies/refundpolicy/refundpolicy";
import { AdminPortal } from "pages/AdminPortal/AdminPortal";
import Dashboard from "pages/DashBoard/Dashboard";
import Login from "pages/Auth/Login/Login";

import Layout from "Utils/Layout";
import ShouldBeLoggedIn from "Utils/ShouldBeLoggedIn";
import { LoadingContext } from "context/LoadingProvider";
import authService from "actions/services/auth.service";
import { UserContext } from "context/UserProvider";

import Sidebar from "component/Sidebar/Sidebar";

import CompleteSignin from "pages/Auth/SignupProcess/CompleteSignin";
import CoursePreferenceQuiz from "pages/Course/CoursePreferenceQuiz";
import CourseSummary from "pages/Course/CourseSummary";
import Dashboardnew from "pages/DashBoard/Dashboardnew";
import MyProfile from "component/myProfile/MyProfile.jsx";
import ZaioProfile from "component/myProfile/ZaioProfile";
import TutorSupport from "component/tutorSupport/TutorSupport.jsx";
import HallOfFame from "component/hallOfFame/HallOfFame";
import Settings from "component/settings/Settings.jsx";
import ReactPixel from "react-facebook-pixel";
import VideoEntry from "components/QuizReelComponents/VideoEntry/VideoEntry";
import Footer from "components/QuizReelComponents/BottomNav/Footer";
import Notes from "pages/Notes/Notes";
import TeamLearning from "pages/TeamLearning/TeamLearning";

import Jobs from "pages/Jobs/Jobs";
import JobPage from "pages/Jobs/JobsPage/JobPage";
import Payments from "pages/Payments/Payments";
// import Watch from "pages/Watch/Watch";
import Watch from "pages/Watch/Watch";
import Wallet from "pages/Wallet/Wallet";

import tblService from "actions/services/tbl.service";
import { useQuery } from "react-query";
import mixpanel from "mixpanel-browser";
import Competitions from "pages/Competitions/Competitions";
import CompetitionPage from "pages/Competitions/CompetitionPage/CompetitionPage";
import Submission from "pages/CompetitionSubmission/Submission";
import RNWebView from "pages/RnWebView/RNWebView";
import Onboarding from "pages/Onboarding";
import Ide from "components/IDE/Ide";
import AdminIde from "pages/AdminPortal/AdminIde/AdminIde";
import { CalendarPage } from "component/TasksCalendar/TasksCalendar";
import { TasksProvider } from "context/TasksProvider";
import Compiler from "components/Compiler/Compiler";
import CompilerLayout from "components/Compiler/CompilerLayout";
import AdminAssignments from "pages/AdminPortal/AdminAssignments/AdminAssignments";

import Assignment from "pages/Assignment/Assignment";
import CodingChallenge from "pages/AdminPortal/CodingChallenge/CodingChallenge";
import EditAssignment from "pages/AdminPortal/AdminAssignments/EditAssignment";
import AdminCVCs from "pages/AdminPortal/AdminAssignments/AdminCVCs";
import UserAssignment from "pages/Assignments/UserAssignment";
import TestingSplit from "components/Compiler/TestingSplit";
import Certificate from "pages/Certificate/Certificate";
import Recommend from "pages/Recommend/Recommend";
import NextCourse from "pages/Certificate/NextCourse";
import SignupNewFlow from "pages/Onboarding/SignupNewFlow";
import OnboardingPayment from "pages/Onboarding/OnboardingPayment";
import OnboardingInfo from "pages/Onboarding/OnboardingInfo";
import { BlockAccount } from "pages/BlockAccount/BlockAccount";
import ShouldBeBlocked from "Utils/ShouldBeBlocked";
import { DeferredStudentGoals } from "pages/DashBoard/StudentDeferredProgram/StudentGoals";

mixpanel.init("4e2be35d7debc0ad57db236937d08d8d", { debug: true });
ReactPixel.init("854803775130939");
ReactGA.initialize("UA-204183816-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [updateUser, setUpdateUser] = useState(false);
  const [location, setLocation] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);
  const [sessionData, setSessionData] = useState({});
  const [accBlocked, setAccBlocked] = useState(false);
  const history = useHistory();

  //queries
  const {
    isLoading: isTeamLoading,
    isError: isTeamError,
    error: teamError,
    data: team,
  } = useQuery("getTeam", () => tblService.getTeam(), {
    enabled: !!(user && user.success),
  });
  // const {
  //   isLoading: isUserDataLoading,
  //   isError: isUserDataError,
  //   error: userDataError,
  //   data: userData,
  // } = useQuery("userData", () => authService.updateUserData(), {
  //   enabled: !!user,
  //   onSuccess: (data) => {
  //     console.log("CHECKINGIT OUT", data)
  //   }
  // });

  useEffect(() => {
    setLoading(true);
    const funct = async () => {
      if (user) {
        try {
          const res = await authService.updateUserData();
          if (res.success) {
            console.log("HIHIHDIHIHI", { ...res, ...res.data });
            setUser({ ...res, ...res.data });
          } else {
            history.push("/logout");
          }
        } catch (err) {
          history.push("/logout");
          console.log(err);
        }
      } else {
        if (
          window.location.pathname.includes("login") ||
          window.location.pathname.includes("onboarding") ||
          window.location.pathname.includes("getstarted") ||
          window.location.pathname.includes("forgotpassword") ||
          window.location.pathname.includes("pricing") ||
          window.location.pathname.includes("live-demo") ||
          window.location.pathname.includes("zaio-rockstars") ||
          window.location.pathname.includes("zaio-profile") ||
          window.location.pathname.includes("quiz-reel") ||
          window.location.pathname.includes("privacypolicy") ||
          window.location.pathname.includes("react-native-web-view") ||
          window.location.pathname.includes("ide") ||
          window.location.pathname.includes("compiler") ||
          window.location.pathname.includes("admin-ide") ||
          window.location.pathname.includes("admin-assignments") ||
          window.location.pathname.includes("admin-mcq") ||
          window.location.pathname.includes("assignment")

          // ||
          // window.location.pathname.includes("watch")
        ) {
          setUser(null);
        } else {
          setUser(null);
          console.log("// history.push");
          history.push("/getstarted");
        }
      }

      setLoading(false);
    };

    funct();
    setUpdateUser(false);
  }, [updateUser]);

  useEffect(() => {
    console.log(user?.data?.accBlocked, "USERRRR");
    if (user) {
      if (user?.data?.accBlocked) {
        //blocked the app
        setAccBlocked(true);
        history.push("/account-blocked");
      }
    } else {
      setAccBlocked(false);
    }
  }, [user]);

  return (
    <div className="App">
      {/* {user && !user.isQRPlaying ? <Footer /> : null} */}
      <Footer />
      <Loader />
      {/* {user ? null : <WaitingListBar />} */}

      {/* <TrackRoute /> */}
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/account-blocked">
            <ShouldBeLoggedIn loggedin={!!user}>
              <Layout>
                <BlockAccount />
              </Layout>
            </ShouldBeLoggedIn>
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>

          <Route exact path="/login">
            <Layout>
              <Login loggedin={!!user}></Login>
            </Layout>
          </Route>
          {/* <Route exact path="/code">
            <Redirect to="/updated-dashboard" />
          </Route> */}

          <Route path="/admin">
            <ShouldBeLoggedIn>
              <AdminPortal userData={user}></AdminPortal>
            </ShouldBeLoggedIn>
          </Route>
          <Route path="/admin-ide/:courseid/:unitid/:lectureid">
            <ShouldBeLoggedIn>
              <AdminIde />
            </ShouldBeLoggedIn>
          </Route>
          <Route path="/admin-assignment/:courseid?/:unitid?/:lectureid?">
            <AdminAssignments />
          </Route>
          {/* <Route path="/admin-create-assignment">
            <AdminAssignments />
          </Route> */}

          <Route path="/edit-assignment/:assignmentid">
            <EditAssignment />
          </Route>

          <Route path="/admin-cvcs/:courseid?/:unitid?/:lectureid?">
            <AdminCVCs />
          </Route>
          {/* <Route path="/admin-mcq">
            <AdminMCQ />
          </Route> */}

          <Route path="/admin-code-challenge/:courseid?/:unitid?/:lectureid?">
            <CodingChallenge />
          </Route>
          {/* <Route path="/admin-code-challenge">
            <CodingChallenge />
          </Route> */}
          <Route path="/assignment/:assignmentid/:qType?/:qId?" exact>
            <Assignment />
          </Route>

          <Route path="/tt" exact>
            <TestingSplit />
          </Route>

          <Route exact path="/react-native-web-view">
            <RNWebView />
          </Route>

          <Route exact path="/onboarding/payments">
            {/* <Onboarding /> */}
            <OnboardingPayment />
          </Route>
          <Route exact path="/onboarding/questions">
            {/* <Onboarding /> */}
            <OnboardingInfo />
          </Route>
          <Route exact path="/onboarding">
            {/* <Onboarding /> */}
            <SignupNewFlow />
          </Route>

          <Route exact path="/course/:coursename/:courseid">
            <Layout>
              <div
                style={{
                  minHeight: "200px",
                }}
              >
                <Course loggedin={!!user}></Course>
              </div>
            </Layout>
          </Route>

          <Route exact path="/privacypolicy">
            <Layout>
              <PrivacyPolicy></PrivacyPolicy>
            </Layout>
          </Route>

          <Route exact path="/terms">
            <Layout>
              <Terms></Terms>
            </Layout>
          </Route>

          <Route exact path="/refundpolicy">
            <Layout>
              <RefundPolicy></RefundPolicy>
            </Layout>
          </Route>

          <Route exact path="/quiz-reel">
            {/* <Layout> */}
            {/* <Sidebar> */}
            <VideoEntry />
            {/* </Sidebar> */}
            {/* </Layout> */}
          </Route>
          {/* <Route exact path="/watch/:courseid/:lectureid">
            <Watch />
          </Route> */}
          <Route exact path="/watch/:courseid/:unitid/:lectureid">
            <Watch />
          </Route>
          <Route exact path="/certificate/:courseid">
            <Certificate />
          </Route>
          <Route exact path="/next-course/:courseid">
            <NextCourse />
          </Route>
          <Route exact path="/recommend/:courseid">
            <Recommend />
          </Route>
          <Route exact path="/ide">
            <Ide />
          </Route>
          <Route exact path="/compiler">
            <Compiler />
          </Route>

          <Route exact path="/wallet">
            <ShouldBeLoggedIn>
              <Sidebar>
                <Wallet />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/competitions">
            <ShouldBeLoggedIn>
              <Sidebar>
                <Competitions />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>
          <Route exact path="/competitions/:competitionId">
            <ShouldBeLoggedIn>
              <Sidebar>
                <CompetitionPage />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>
          <Route exact path="/competition-submission/:id">
            <ShouldBeLoggedIn>
              <Sidebar>
                <Submission />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/completesignin">
            <ShouldBeLoggedIn loggedin={!!user}>
              <Layout>
                <CompleteSignin setUpdateUser={setUpdateUser} userData={user} />
              </Layout>
            </ShouldBeLoggedIn>
          </Route>

          <Route path="/dashboard" exact>
            <ShouldBeLoggedIn loggedin={!!user}>
              <ShouldBeBlocked accBlocked={accBlocked}>
                <Layout>
                  <Dashboard setUpdateUser={setUpdateUser} userData={user} />
                </Layout>
              </ShouldBeBlocked>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/profile">
            <ShouldBeLoggedIn>
              <Sidebar>
                <TasksProvider>
                  <MyProfile setUpdateUser={setUpdateUser} userData={user} />
                </TasksProvider>
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route path="/zaio-profile/:email" exact>
            <TasksProvider>
              <ZaioProfile
                setUpdateUser={setUpdateUser}
                userData={user}
                fromUserDashboard
                isPublic
              />
            </TasksProvider>
          </Route>

          <Route exact path="/tutor-support">
            <ShouldBeLoggedIn>
              <Sidebar>
                <TutorSupport setUpdateUser={setUpdateUser} userData={user} />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/hall-of-fame">
            <ShouldBeLoggedIn>
              <Sidebar>
                <HallOfFame setUpdateUser={setUpdateUser} userData={user} />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/jobs">
            <ShouldBeLoggedIn>
              <Sidebar>
                <Jobs />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>
          <Route exact path="/jobs/job-details/:jobid">
            <ShouldBeLoggedIn>
              <Sidebar>
                <JobPage />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/settings">
            <ShouldBeLoggedIn>
              <Sidebar>
                <Settings setUpdateUser={setUpdateUser} userData={user} />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route path="/zaio-rockstars" exact>
            <Layout>
              <HallOfFame
                setUpdateUser={setUpdateUser}
                userData={user}
                fromUserDashboard
                isPublic
              />
            </Layout>
          </Route>
          <Route path="/choose-path" exact>
            {/* <ShouldBeLoggedIn loggedin={!!user}> */}
            <Layout>
              <Dashboard
                setUpdateUser={setUpdateUser}
                userData={user}
                fromUserDashboard
              />
            </Layout>
            {/* </ShouldBeLoggedIn> */}
          </Route>

          <Route path="/pricing" exact>
            {/* <ShouldBeLoggedIn loggedin={!!user}> */}
            <Layout>
              <Dashboard
                setUpdateUser={setUpdateUser}
                userData={user}
                fromUserDashboard
                isPublic
              />
            </Layout>
            {/* </ShouldBeLoggedIn> */}
          </Route>

          <Route exact path="/updated-dashboard">
            <ShouldBeLoggedIn>
              <ShouldBeBlocked accBlocked={accBlocked}>
                <Sidebar>
                  <Dashboardnew />
                </Sidebar>
              </ShouldBeBlocked>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/assignments">
            <ShouldBeLoggedIn>
              <Sidebar>
                <UserAssignment />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/notes">
            <ShouldBeLoggedIn>
              <Sidebar>
                <Notes />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/team">
            <ShouldBeLoggedIn>
              <Sidebar>
                <TeamLearning />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route path="/coursepreferencequiz">
            <ShouldBeLoggedIn loggedin={!!user}>
              <Layout>
                <CoursePreferenceQuiz
                  setUpdateUser={setUpdateUser}
                  userData={user}
                ></CoursePreferenceQuiz>
              </Layout>
            </ShouldBeLoggedIn>
          </Route>

          <Route path="/coursesummary" exact>
            <ShouldBeLoggedIn loggedin={!!user}>
              <Layout>
                <CourseSummary setUpdateUser={setUpdateUser} userData={user} />
              </Layout>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/payments">
            <ShouldBeLoggedIn>
              <Layout>
                <Payments />
              </Layout>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/bootcamp-mode">
            <ShouldBeLoggedIn>
              <Sidebar>
                <TasksProvider>
                  <CalendarPage />
                </TasksProvider>
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/deferred-program/goals">
            <ShouldBeLoggedIn>
              <Sidebar>
                  <DeferredStudentGoals />
              </Sidebar>
            </ShouldBeLoggedIn>
          </Route>

          <Route exact path="/welcome">
            <Layout>
              <SignupProcess loggedin={!!user}></SignupProcess>
            </Layout>
          </Route>

          <Route exact path="/getstarted">
            <Layout>
              <SignupProcess loggedin={!!user}></SignupProcess>
            </Layout>
          </Route>

          <Route exact path="/somethingwentwrong">
            <Layout>SOMETHING WENT WRONG!</Layout>
          </Route>

          <Route path="/forgotpassword">
            <Layout>
              <ForgotPassword />
            </Layout>
          </Route>

          <Route>
            <Layout>
              <div
                style={{
                  minHeight: "300px",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "2rem",
                  paddingTop: "5%",
                  color: "white",
                }}
              >
                404 NOT FOUND
              </div>
            </Layout>
          </Route>
        </Switch>
      </ScrollToTop>
    </div>
  );
}

export default App;
