import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACKEND_URL;

class DSEditorService {
  getAllDSSubmissions({ student_id, lectureid }) {
    return axios
      .get(
        API_URL +
          `/datascience/all-submissions?student_id=${student_id}&lectureid=${lectureid}`,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        // console.log('then',res.data);
        return res.data;
      })
      .catch((rej) => {
        // console.log('catch',rej.response);
        return rej.response;
      });
  }

  getNotebookUrl(params = { challenge_id, bootcamp_id, student_id, }){
    const query = new URLSearchParams(params).toString();
    return axios.get(`${API_URL}/datascience/notebooks?${query}`, {headers: authHeader()})
    .then(res => res.data)
    .catch(err => err.response)

  }


}

export default new DSEditorService();
