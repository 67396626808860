function OnboardingOption({
  option,
  handleOptionSelect,
  index,
  selectedIndex,
  fromQuiz = false,
}) {
  if (fromQuiz) {
    return (
      <div
        className={`onb-option ${
          selectedIndex === index ? "onb-option-selected" : ""
        }`}
        onClick={() => handleOptionSelect(option, index)}
      >
        <p className="m-0 p-0">{option}</p>
        {/* {option && <span>{option}</span>} */}
      </div>
    );
  }
  return (
    <div
      className={`onb-option ${
        selectedIndex === index ? "onb-option-selected" : ""
      }`}
      onClick={() => handleOptionSelect(option, index)}
    >
      <p className="m-0 p-0">{option?.text}</p>
      {option?.textDescription && <span>{option?.textDescription}</span>}
    </div>
  );
}

export default OnboardingOption;
