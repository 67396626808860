import { SfxContext } from "context/SfxContext";
import ChatBubble from "pages/DashBoard/ChatBubble";
import { useContext } from "react";
import useQuizStore from "zustand-store/quizStore";

function QuizQuestion({
  quizData,
  quizIndex,
  setSelectedOption,
  isSolutionCorrect,
  displayColor,
}) {
  const currentQuestion = useQuizStore((state) => state.currentQuestion);
  const { clickSfx, hoverSfx, completedSfx, winSfx } = useContext(SfxContext);
  // const quizIndex = useQuizStore((state) => state.quizIndex);
  return (
    <div>
      <ChatBubble
        title={currentQuestion?.questionText}
        text={""}
        showLive={false}
      />

      <div className="onb-body">
        <div className="onb-question">
          {quizData?.questions[quizIndex].answerOptions &&
            quizData?.questions[quizIndex].answerOptions.map(
              (option, index) => (
                <div
                  key={index}
                  className={`onb-option onb-option-${displayColor(option)}`}
                  onClick={() => {
                    isSolutionCorrect === null && setSelectedOption(option);
                    hoverSfx();
                  }}
                >
                  {/* <p className="m-0 p-0">{option?.answer}</p> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: option?.answer,
                    }}
                  ></div>
                </div>
              )
            )}
        </div>
      </div>
      <div>
        {isSolutionCorrect !== null && (
          <ChatBubble
            title={
              isSolutionCorrect
                ? "✅ Correct! Here is some feedback:"
                : "⛔ Incorrect! Here is some feedback:"
            }
            text={currentQuestion.correctAnswer[0].explanation}
            showLive={false}
            hideImage={true}
            backgroundColor={isSolutionCorrect ? "#D1F2EB" : "#FADBD8"}
          />
        )}
      </div>
    </div>
  );
}

export default QuizQuestion;
