import { UserContext } from "context/UserProvider";
import React, { useContext, useState, useEffect } from "react";
import { FiHome, FiPenTool } from "react-icons/fi";
import { MdMovieFilter, MdOutlineGroupAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import ReelModal from "../ReelModal/ReelModal";
import { SfxContext } from "context/SfxContext";

import "./Footer.css";
import InviteFriends from "../ReelModal/InviteFriends";
import Avatar, { genConfig } from "react-nice-avatar";
import isBrowsingFromPhone from "Utils/DetectDeviceBrowser";
import learningpathService from "actions/services/learningpath.service";

let config1 = genConfig();

function Footer() {
  const { user, setUser } = useContext(UserContext);
  const { hoverSfx, clickSfx, winSfx } = useContext(SfxContext);
  const history = useHistory();
  const [continueLearning, setContinueLearning] = useState(null);
  const [selectedOption, setSelectedOption] = useState(false);

  const [modal, setModal] = useState({
    show: false,
    title: "HELO HELO",
    description:
      "Try sign up, it will take a min, and you will be get a personalized experience - ITS FREE.",
    CustomComponent: null,
  });

  useEffect(() => {
    fetchContinueLearningData();
  }, []);

  const fetchContinueLearningData = async () => {
    try {
      let res = await learningpathService.getContinueLearning();
      // console.log("RES", res);
      setContinueLearning({
        ...res.data,
      });
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      title: "",
      description:
        "Try sign up, it will take a min, and you will be get a personalized experience - ITS FREE.",
      CustomComponent: null,
    });
  };

  const handleRedirect = () => {
    clickSfx();

    const resumeVideo = continueLearning?.resumevideo;
    // history.push("/quiz-reel?onboarding=true");
    console.log("REDIRECT", continueLearning);
    if (user && user.success) {
      console.log("REDIRECTSUCCESS", continueLearning);
      if (continueLearning && continueLearning.resumevideo?.ismobilelecture) {
        history.push(
          `/quiz-reel?courseid=${resumevideo.course}&lectureid=${resumevideo._id}&unitid=${resumevideo.courseunit}`
        );
      } else {
        history.push(
          `/watch/${resumeVideo.course}/${resumeVideo.courseunit}/${resumeVideo._id}`
        );
        // if (!isBrowsingFromPhone()) {
        //   history.push(
        //     `/code?courseid=${continueLearning.resumevideo.course}&lectureid=${continueLearning.resumevideo._id}&unitid=${continueLearning.resumevideo.courseunit}`
        //   );
        // } else {
        //   window.location.href = "/"
        //   alert(
        //     "Please log into your desktop to continue with this desktop course. You can start a mobile courses on your dashboard."
        //   );
        //   // history.push(`/updated-dashboard`);
        // }
      }
    } else {
      history.push(`/quiz-reel?onboarding=true`);
    }
  };

  const showFooter = () => {
    if (window.location.pathname.includes("onboarding")) {
      return false;
    }
    return true;
  };

  return (
    <div>
      {modal.show && (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          CustomComponent={modal.CustomComponent ?? false}
        />
      )}
      <div
        id="qr-footer"
        className={`${showFooter() ? "qr-footer" : "qr-footer-hide"}`}
        // style={{ display: "none" }}
      >
        <div className="navbar-icons">
          <FiHome
            className="qr-icons"
            style={{
              // fill: selectedOption === "home" ? "white" : "",
              color: selectedOption === "home" ? "#8437f9" : "",
            }}
            onClick={(e) => {
              setSelectedOption("home");
              clickSfx();
              user && user?.success
                ? history.push("/updated-dashboard")
                : setModal({
                    ...modal,
                    show: true,
                    title: "Want to access dashboard?",
                    CustomComponent: null,
                  });
            }}
          />
          <FiPenTool
            className="qr-icons"
            style={{
              // fill: selectedOption === "notes" ? "white" : "",
              color: selectedOption === "notes" ? "#8437f9" : "",
            }}
            id="qr-home"
            onClick={(e) => {
              setSelectedOption("notes");
              clickSfx();
              user && user?.success
                ? setModal({
                    ...modal,
                    show: true,
                    title: "Notes is coming soon!",
                    description:
                      "Soon you will be able to take and track your learnings all along your journey.",
                    CustomComponent: null,
                  })
                : setModal({
                    ...modal,
                    show: true,
                    title: "Want to access Notes?",
                    CustomComponent: null,
                  });
            }}
          />
          <MdMovieFilter
            className="qr-icons"
            style={{
              // fill: selectedOption === "notes" ? "white" : "",
              color: selectedOption === "continue-learning" ? "#8437f9" : "",
            }}
            onClick={() => {
              setSelectedOption("continue-learning");
              handleRedirect();
            }}
          />
          <MdOutlineGroupAdd
            className="qr-icons"
            style={{
              // fill: selectedOption === "notes" ? "white" : "",
              color: selectedOption === "team" ? "#8437f9" : "",
            }}
            onClick={() => {
              setSelectedOption("team");
              clickSfx();
              user && user?.success
                ? history.push("/team")
                : setModal({
                    ...modal,
                    show: true,
                    title: "Want to do Team Based Learning?",
                    description:
                      "It's free, and your chances of succeeding in your learning journey much higher. Try now - IT's FREE",
                    CustomComponent: null,
                  });
            }}
          />
          <div
            // className="qr-icons post-pic"
            onClick={() => {
              setSelectedOption("profile");
              clickSfx();
              user && user?.success
                ? history.push("/profile")
                : setModal({
                    ...modal,
                    show: true,
                    title: "Want to access Profile?",
                    CustomComponent: null,
                  });
            }}
          >
            {/* <img
              alt="user profile pic"
              src="https://zaiocontent.s3.eu-west-2.amazonaws.com/akhil.png"
            /> */}
            <Avatar
              style={{
                width: "2.2rem",
                height: "2.2rem",
                color: selectedOption === "profile" ? "#8437f9" : "",
              }}
              {...config1}
              className="qr-icons post-pic"
              // className={`${classes.icon} ${classes.tutor1}`}
              // style={{ position: "absolute", left: 0, top: 0 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
