import { playgroundTypes } from "Constants";
import lectureService from "actions/services/lecture.service";

import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
export const defaultProjectFiles = {
  "index.html": {
    name: "index.html",
    language: "html",
    value: "<h1>Try type HTML here to see magic</h1>",
  },
  "style.css": {
    name: "style.css",
    language: "css",
    value: "/* css code is here  */",
  },
  "script.js": {
    name: "script.js",
    language: "javascript",
    value: "// JS code ",
  },
};
function SaveProgress({
  currentVideo,
  editMode,
  setEditMode,
  projectFiles,
  lectureid,
  modalMode,
  playgroundid,
  setShow,
  setProjectFiles,
  props,
  setSelectedSandbox,
  selectedSandbox,
  fromAddNewButton,
  setFromAddNewBtn,
}) {
  // console.log("CURRENT VIDEO", props, currentVideo, selectedSandbox?.timestamp);
  const [seconds, setSeconds] = useState(selectedSandbox?.timestamp);
  const [localEditMode, setLocalEditMode] = useState(editMode);
  const [pauseChecked, setPauseChecked] = useState(selectedSandbox?.pause);
  const inputRef = useRef();
  const queryClient = useQueryClient();

  const saveCodePushMutation = useMutation(
    () =>
      lectureService.saveCodePush({
        project_files: fromAddNewButton
          ? defaultProjectFiles
          : projectFiles?.files,
        playground_type: fromAddNewButton
          ? playgroundTypes.SINGLE_FILES
          : projectFiles?.type,
        pause: pauseChecked,
        playgroundid,
        lectureid,
        timestamp: seconds,
        edit: editMode,
      }),
    {
      onSuccess: (data) => {
        if (data?.success) {
          setProjectFiles({
            type: data?.data?.playground_type,
            files: JSON.parse(data?.data?.project_files),
          });
          setShow(false);
          setFromAddNewBtn(false);
          if (!editMode) {
            //fetch updated list of Sandboxes
            queryClient.invalidateQueries("sandboxes");
          }
        } else {
          alert("Error occured: " + data?.message);
        }
      },
      onError: (err) => {
        console.log(err, projectFiles);
      },
    }
  );

  const overrideSave = (e) => {
    if (
      e.key === "p" &&
      (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
    ) {
      e.preventDefault();
      handlingDClick();
    }
  };
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  useEffect(() => {
    setLocalEditMode(localEditMode);
  }, [localEditMode, editMode]);
  useEffect(() => {
    document.addEventListener("keydown", overrideSave, false);

    return () => {
      document?.removeEventListener("keydown", overrideSave);
    };
  }, [pauseChecked, modalMode]);

  const handlingDClick = () => {
    setPauseChecked(!pauseChecked);
  };

  const onSaveAsNewSandbox = (e) => {
    e.preventDefault();
    console.log(projectFiles, editMode, fromAddNewButton);
    if (!seconds) return;
    saveCodePushMutation.mutate();
  };

  return (
    <div className="d-flex flex-column m-4">
      <h1>{editMode ? "Edit Sandbox" : "Create Sandbox"}</h1>
      <span className="badge success-btn">{currentVideo?.lecturename}</span>
      <form onSubmit={onSaveAsNewSandbox}>
        <input
          ref={inputRef}
          type="number"
          placeholder="Enter time in seconds"
          className="form-control shadow-sm my-2"
          value={seconds}
          onChange={(e) => setSeconds(e.target.value)}
        />
      </form>
      <div className="d-flex no-gutters align-items-center">
        <input
          type="checkbox"
          checked={pauseChecked}
          id="zaio-terms"
          onChange={(e) => setPauseChecked(e.target.checked)}
        />
        <label
          htmlFor="zaio-terms"
          className="ms-2 normalTextColorGrey normalText font_12"
        >
          Pause (Ctrl/Cmd + P to toggle){" "}
        </label>
      </div>
      <button
        className="btn shadow-sm btn-main mt-3"
        onClick={onSaveAsNewSandbox}
      >
        {editMode ? "Edit sandbox" : "Save as New Sandbox"}
      </button>
    </div>
  );
}

export default SaveProgress;
