import React, { useState } from "react";
import "./Header.css";
import { Line, Circle } from "rc-progress";
import Stats from "./Stats";
import ReelModal from "../ReelModal/ReelModal";
import TeamScore from "pages/TeamLearning/TeamScore";
import SoloStreakScore from "component/myProfile/SoloStreakScore";

function Header({ playlistLength }) {
  const [modal, setModal] = useState({
    show: false,
    title: "HELO HELO",
    description: "",
    CustomComponent: null,
    props: null,
  });

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      title: "",
      description: "",
      CustomComponent: null,
      props: null,
    });
  };
  return (
    <header>
      {modal.show ? (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          CustomComponent={modal.CustomComponent ?? false}
          props={modal.props ?? false}
        />
      ) : null}
      {/* <SoloStreakScore
        simple
        handleClick={() =>
          setModal({
            ...modal,
            show: true,
            title: "Streaks",
            description:
              "Each day that you learn on Zaio, you earn a streak. Streaks indicate consistency which is one of the skills that shows success of a learner. You will earn rewards & this will be shown on your profile when referred for jobs/internships. It will also open up rewards for you such as free mentorship, consulting, discounted courses, goodies and more.",
            CustomComponent: null,
            props: null,
          })
        }
      /> */}
      <Stats
        showLine
        playlistLength={playlistLength}
        setModal={setModal}
        modal={modal}
      />
    </header>
  );
}

export default Header;
