import Live from "components/Live/Live";
import { useHistory } from "react-router-dom";
import { CompetitionCardContainer } from "../Competitions.styled";

function CompetitionCard({
  _id,
  competitionname,
  competitionthumbnail,
  prize,
  duration,
  company,
  tags,
}) {
  const history = useHistory();
  return (
    <CompetitionCardContainer
      onClick={() => history.push("/competitions/" + _id)}
    >
      <div className="card-header">
        <div className="overlay"></div>
        <img src={competitionthumbnail} />
        <div className="card-live">
          <Live />
        </div>
        <div
          className="card-poster-info"
          // onClick={() => window.open(company?.website, "_blank")}
        >
          <img src={company?.logo} />
          <span>{company?.name}</span>
        </div>
      </div>
      <div className="card-info">
        <h6>{competitionname}</h6>
        {/* <span>Sharpen your skills, win prizes and help local farmer.</span> */}
        <div className="card-info-skills">
          {tags.map((tag, index) => (
            <span key={index} className="badge bg-success text-white">
              {tag}
            </span>
          ))}

          {/* <span className="badge bg-success text-white">JS</span>
          <span className="badge bg-success text-white">API</span>
          <span className="badge bg-success text-white">Existing code</span> */}
        </div>
      </div>
      <div className="card-footer">
        <span>{prize}</span>
        <span>{duration} days</span>
      </div>
    </CompetitionCardContainer>
  );
}

export default CompetitionCard;
