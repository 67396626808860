import { useEffect, useState } from 'react'

import TextEditorDrawer from '../CodingChallenge/TextEditorDrawer'

import useAssignmentStore from 'zustand-store/assignmentStore'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Form } from 'react-bootstrap'
import useEditorStore from 'zustand-store/editorStore'
import lectureService from 'actions/services/lecture.service'


function DsQForm({ courseId, unitId, lectureId, assignmentid = null }) {
  const [lectureName, setLectureName] = useState('')
  const [starterCode, setStarterCode] = useState(null)
  const [isTextEditorOpen, setTextEditorOpen] = useState(false)
  const [ideOpen, setIdeOpen] = useState(false)
  const [questionName, setQuestionName] = useState('')
  const [isAdminChallenge, setIsAdminChallenge] = useState(!!assignmentid)
  const [language, setLanguage] = useState('python-ds')
  const [marks, setMarks] = useState(0)
  const [testCases, setTestCases] = useState([])
  const [duration, setDuration] = useState('')
  const [afterLecture, setAfterLecture] = useState(lectureId)
  const [assignmentDropdown, setAssignmentDropdown] = useState('0')
  const [timeout, setTimeout] = useState(30)

  const queryClient = useQueryClient()
  const adminCourseQuestion = useAssignmentStore(
    (state) => state.adminCourseQuestion
  )
  const adminQuestionMode = useAssignmentStore(
    (state) => state.adminQuestionMode
  )
  const adminSelectedAssignmentQuestion = useAssignmentStore(
    (state) => state.adminSelectedAssignmentQuestion
  )
  const questionFile = useEditorStore((state) => state.textEditorValue)
  const setQuestionFile = useEditorStore(
    (state) => state.setCurrentTextEditorValue
  )


  /* if edit mode then initialize variables */
  useEffect(() => {
    if (
      adminSelectedAssignmentQuestion &&
      adminQuestionMode === 'edit-assignment-datascience'
    ) {
      setQuestionName(adminSelectedAssignmentQuestion?.questionname)
      setLanguage(adminSelectedAssignmentQuestion?.language)
      setMarks(adminCourseQuestion?.datasciencechallengeid?.marks)
      // getFrontendFilesCodeAssignmentMutation.mutate()
      // setTestCases(adminSelectedAssignmentQuestion?.testcases)
      // setTimeout(adminSelectedAssignmentQuestion?.timeout || 30)
    } else if (adminCourseQuestion && adminQuestionMode === "edit-course-ds") {
      console.log("ADMIN QUESTION...", adminCourseQuestion);
      // setTestCases(adminCourseQuestion?.backendchallengeid?.testcases)
      setLectureName(adminCourseQuestion?.lecturename)
      setDuration(adminCourseQuestion?.duration)
      setMarks(adminCourseQuestion?.datasciencechallengeid?.marks)
      setLanguage(adminCourseQuestion?.datasciencechallengeid?.language)
      // getFrontendFilesCodeMutation.mutate()
      console.log('NO HERE')
    }
     else {
      console.log('NO ADMIN QUESTION')
    }

    return () => {
      resetFormComplete()
    }
  }, [adminQuestionMode, adminSelectedAssignmentQuestion, adminCourseQuestion])

  /* mutations start */
  console.log({adminCourseQuestion})
  const updateChallengeMutation = useMutation(
    (tcs) =>
      lectureService.adminEditCourseDataScienceChallenge(
        adminCourseQuestion?.datasciencechallengeid,
        courseId,
        duration,
        // tcs,
        afterLecture,
        starterCode,
        marks
        // timeout
      ),
    {
      onSuccess: (data) => {
        console.log("submitChallengeMutation...", data);
        alert("Updated Challenge...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  )

  const updateLectureInfoMutation = useMutation(
    () =>
      lectureService.putLecture({
        lecturename: lectureName,
        duration: duration,
        afterlecture: afterLecture,
        _id: adminCourseQuestion?._id,
        releaseassignment: assignmentDropdown,
        courseId
      }),
    {
      onSuccess: (data) => {
        console.log("update lecture information...", data);
        alert("UPDATE LECTURE INFO...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  );

  const submitChallengeMutation = useMutation(
    () => 
      lectureService.adminUploadDataScienceChallenge(
        courseId,
        unitId,
        lectureName,
        duration,
        afterLecture,
        starterCode,
        language,
        assignmentDropdown,
        marks,courseId
      ),
    {
      onSuccess: (data) => {
        console.log("submitChallengeMutation...", data);
        alert("Created Challenge...response: " + JSON.stringify(data));
        queryClient.invalidateQueries("videoCourseList");
      },
    }
  )

  const updateAssignmentQMutation = useMutation(
    (tcs) =>
      lectureService.updateAssignmentDataScienceQ(
        adminSelectedAssignmentQuestion?._id,
        courseId,
        assignmentid,
        // tcs,
        // questionFile,
        marks,
        questionName,
        language,
        starterCode,
        // frontendFilesCode
        // timeout
      ),
    {
      onSuccess: (data) => {
        console.log('created assignment challenge...', data)
        alert('ADDED QUESTION ID...response' + JSON.stringify(data))
        return queryClient.invalidateQueries(['assignmentList'])
      },
    }
  )

  const deleteAssignmentQMutation = useMutation(
    (tcs) =>
      lectureService.deleteAssignmentDataScienceQ(
        adminSelectedAssignmentQuestion?._id,
        assignmentid
      ),
    {
      onSuccess: (data) => {
        console.log('deleted assignment challenge...', data)
        alert('DELETED ASSIGNMENT QUESTION...response' + JSON.stringify(data))
        return queryClient.invalidateQueries(['assignmentList'])
      },
    }
  )

  const createAssignmentQMutation = useMutation(
    () =>
      lectureService.createDataScienceChallengeAsAdmin(
        // questionFile,
        assignmentid,
        // testCases,
        marks,
        questionName,
        language,
        starterCode,
        courseId
        // frontendFilesCode
        // timeout
      ),
    {
      onSuccess: (data) => {
        console.log('created assignment challenge...', data)
        alert('CREATED ASSIGNMENT QUESTION...response' + JSON.stringify(data))
        return queryClient.invalidateQueries(['assignmentList'])
      },
    }
  )
  /* mutations end */

  const {
    isLoading: isAssignmentListLoading,
    isError,
    error,
    data: assignmentListResponse,
  } = useQuery(
    'assignmentList',
    () => lectureService.getAdminAssignmentList(),
    {
      onSuccess: (response) => {
        // setAdminAssignmentList(response?.data);
      },
    }
  )

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setStarterCode(file)
  }

  const handlePostChallenge = async (e) => {
    e.preventDefault()
    if (adminQuestionMode === 'edit-course-challenge') {
      updateChallengeMutation.mutate()
      return
    }
    if (adminQuestionMode.includes('assignment')) {
      createAssignmentQMutation.mutate()
    } else {
      submitChallengeMutation.mutate()
    }
  }

  const handleUpdatChallengeToDB = (testcases) => {
    updateChallengeMutation.mutate(testcases);
    updateLectureInfoMutation.mutate();
  };

  const loadButton = () => {
    if (adminQuestionMode.includes('create')) {
      return (
        <>
          {submitChallengeMutation?.isLoading ? (
            <p className='text-white text-center w-100'>Loading...</p>
          ) : (
            <button
              className='btn-warning p-3 w-100 m-2'
              onClick={handlePostChallenge}
            >
              Submit Challenge to DB
            </button>
          )}
        </>
      )
    }

    if (adminQuestionMode === 'edit-course-ds') {
      return (
        <>
          {submitChallengeMutation?.isLoading ? (
            <p className='text-white text-center w-100'>Loading...</p>
          ) : (
            <button
              className='btn-warning p-3 w-100 m-2'
              onClick={() => handleUpdatChallengeToDB(testCases)}
            >
              Update Challenge to DB
            </button>
          )}
        </>
      )
    }

    if (adminQuestionMode === 'edit-assignment-datascience') {
      return (
        <>
          {updateAssignmentQMutation?.isLoading ? (
            <p className='text-white text-center w-100'>Loading...</p>
          ) : (
            <button
              className='btn-warning p-3 w-100 m-2'
              onClick={() => updateAssignmentQMutation.mutate(testCases)}
            >
              Update Assignment Data
            </button>
          )}
        </>
      )
    }
  }

  function encode(str) {
    return btoa(unescape(encodeURIComponent(str || '')))
  }

  function decode(bytes) {
    var escaped = escape(atob(bytes || ''))
    try {
      return decodeURIComponent(escaped)
    } catch {
      return unescape(escaped)
    }
  }


  const resetFormComplete = () => {
    setTestCases([])
    setQuestionName('')
    setMarks('')
    setLanguage('html-css-js')
    setStarterCode(null)
    setQuestionFile('')
  }


  return (
    <>
    <div>
      {loadButton()}
      {adminQuestionMode === 'edit-assignment-datascience' && (
        <button
          className='w-100 bg-red-400 p-2 px-3 m-2 rounded'
          onClick={() => {
            alert('Refresh page to stop.')
            deleteAssignmentQMutation.mutate()
          }}
        >
          Delete Assignment Question
        </button>
      )}
      <div className='d-flex w-100'>
        <div className='w-50 m-3'>
          <h1 className='text-lg text-white m-2 px-2'>
            TestFile code:{' '}
            <div
              className='cursor-pointer inline-block'
              // onClick={() => setIsDrawerOpen(true)}
            >
              {starterCode && (
                <span className='bg-purple-400 text-sm px-2 rounded-lg mx-1'>
                  {starterCode?.name}
                </span>
              )}
              <div className="inline-block">
               <label
                 htmlFor='zipFileInput'
                 // className='text-3xl text-gray-700 border border-gray-500 rounded-lg px-4 py-8 cursor-pointer'
                 className={`flex justify-center items-center  text-white rounded cursor-pointer "text-4xl py-2 pb-3 px-4 hover:bg-green-600 bg-green-700"`}
               >
                 +
               </label>
               <input
                 className='w-[0.1px] h-[0.1px] opacity-0 overflow-hidden absolute -z-10'
                 type='file'
                 id='zipFileInput'
                 onChange={handleFileSelect}
               />
              </div>
            </div>
          </h1>

          {!adminQuestionMode.includes('assignment') && (
            <Form.Group controlId='questionType'>
              <p className='text-white'>Challenge id: {adminCourseQuestion?.datasciencechallengeid._id} (use it when calling the api)</p>
              <Form.Label className='text-white'>
                Select Assignment to release
              </Form.Label>

              {isAssignmentListLoading ? (
                <p>Loading...</p>
              ) : (
                <Form.Select
                  id=''
                  value={assignmentDropdown}
                  onChange={(event) =>
                    setAssignmentDropdown(event.target.value)
                  }
                >
                  <option value='0'>No assignment to release</option>
                  {assignmentListResponse?.data?.map((ass, index) => (
                    <option key={ass?.id} value={ass?._id}>
                      {ass?.assignmentname} ({ass?._id})
                    </option>
                  ))}
                </Form.Select>
              )}
            </Form.Group>
          )}

          {adminQuestionMode.includes('assignment') && (
            <Form.Group controlId='questionName'>
              <Form.Label className='text-white'>Challenge Name</Form.Label>
              <Form.Control
                type='url'
                value={questionName}
                onChange={(event) => setQuestionName(event.target.value)}
              />
            </Form.Group>
          )}

          {!isAdminChallenge && (
            <>
              <Form.Group controlId='lectureName'>
                <Form.Label className='text-white'>
                  DSChallenge Name{' '}
                  {adminQuestionMode === 'edit-course-challenge' &&
                    "(Challenge name won't update)"}
                </Form.Label>
                <Form.Control
                  type='text'
                  value={lectureName}
                  onChange={(event) => setLectureName(event.target.value)}
                  disabled={adminQuestionMode === 'edit-course-challenge'}
                />
              </Form.Group>
              <Form.Group controlId='duration'>
                <Form.Label className='text-white'>Duration</Form.Label>
                <Form.Control
                  type='number'
                  value={duration}
                  onChange={(event) => setDuration(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId='afterLecture'>
                <Form.Label className='text-white'>After Lecture</Form.Label>
                <Form.Control
                  type='text'
                  value={afterLecture}
                  onChange={(event) => setAfterLecture(event.target.value)}
                />
              </Form.Group>
            </>
          )}

            <Form.Group controlId='duration'>
              <Form.Label className='text-white'>Marks</Form.Label>
              <Form.Control
                type='number'
                value={marks}
                onChange={(event) => setMarks(event.target.value)}
              />
            </Form.Group>
          <Form.Group controlId='questionType'>
            <Form.Label className='text-white'>Select Language</Form.Label>
            <Form.Select
              id=''
              value={language}
              onChange={(event) => setLanguage(event.target.value)}
            >
              <option value='python-ds'>python-ds</option>
            </Form.Select>
          </Form.Group>

          {/* <Form.Group controlId='timeout'>
            <Form.Label className='text-white'>Timeout duration</Form.Label>
            <Form.Control
              type='number'
              value={timeout}
              onChange={(event) => setTimeout(event.target.value)}
            />
          </Form.Group> */}
        </div>
      </div>

      {/* <EditorDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} /> */}
      <TextEditorDrawer
        isOpen={isTextEditorOpen}
        setIsOpen={setTextEditorOpen}
      />
    </div>
    
    </>
  )
}

export default DsQForm
