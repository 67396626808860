import { codeSwitchEventTracking } from "Utils/WebEngage";
import { memo } from "react";
import { FaArrowLeft, FaInfoCircle, FaCode } from "react-icons/fa";

const HeaderControls = ({
  backButton,
  fullScreen,
  alertMessage,
  toggleMode,
  exitFullScreen,
  backAction,
}) => {
  // console.log("HEADER CONTROLS RENDERED")
  return (
    backButton && (
      <div className="zp-header-controls" onClick={(e) => e.stopPropagation()}>
        <div className="zp-header__left-control">
          <div onClick={backAction} style={{ cursor: "pointer" }}>
            <FaArrowLeft />
            <span>Go Back</span>
          </div>
        </div>
        {alertMessage.display && (
          <div
            className="zp-header__middle-alert"
            onClick={() =>
              alertMessage.isCodePush ? toggleMode("code-switch") : alert("You are legendary. Keep learning!")
            }
          >
            {alertMessage.isCodePush ? <FaCode /> : <FaInfoCircle />}
            <span>
              {!fullScreen
                ? alertMessage.message
                : "Please exit fullscreen and code switch"}
            </span>
          </div>
        )}
        {!fullScreen ? (
          <div className="zp-header__right-control">
            <div
              id="code-switch"
              onClick={() => {
                toggleMode("code-switch");
                codeSwitchEventTracking()
              }}
              style={{ cursor: "pointer" }}
            >
              <span>Code Switch</span>
              <FaCode />
            </div>
          </div>
        ) : (
          <div className="zp-header__right-control">
            <div onClick={exitFullScreen} style={{ cursor: "pointer" }}>
              <span>Access Code Switch without fullscreen</span>
              <FaInfoCircle />
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default memo(HeaderControls);
