import { useState } from "react";
import TeamStreakIcon from "assets/svg/team-streak.svg";

import { useHistory } from "react-router-dom";
import { useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { useQuery, useQueryClient } from "react-query";
import authService from "actions/services/auth.service";
import tblService from "actions/services/tbl.service";
import ZPLoading from "pages/Watch/components/ZPLoading";
import TeamStreak from "components/TeamStreak/TeamStreak";
import "../Wallet/WalletBalance.css";
import { Constants } from "Constants";
import { setTeamStreakAttribute } from "Utils/WebEngage";

function TeamStreakScore({
  toolTipText = Constants.TeamStreaksExplanation,
  toolTipPlacement = "right",
  displayToolTip = true,
  getTeamStreaks = undefined,
  simple = false,
}) {
  const history = useHistory();

  const target = useRef(null);
  const [showToolTip, setShowToolTip] = useState(false);

  //queries
  const queryClient = useQueryClient();
  const team = queryClient.getQueryData("getTeam");

  const {
    isLoading,
    isError,
    error,
    data: teamStreaksData,
  } = useQuery(
    "teamStreaksData",
    () => authService.getTeamStreakData(team?.teamData?._id),
    {
      enabled: !!team?.teamData?._id,
    }
  );
  // console.log("walletBalance", teamStreaksData);

  const renderTooltip = (props) => <Tooltip {...props}>{toolTipText}</Tooltip>;
  const displayBalance = () => {
    if (teamStreaksData?.success === false) {
      return (
        <>
          <br />
          <small>
            <em className="text-center">{teamStreaksData?.message}</em>
          </small>
        </>
      );
    } else {
      setTeamStreakAttribute(teamStreaksData?.data?.streakNumber || 0)
      return (
        <h2 className="text-center">{teamStreaksData?.data?.streakNumber}</h2>
      );
    }
  };

  if (isLoading) return <ZPLoading />;
  if (isError) return <div className="text-white">{error}</div>;
  if (simple) {
    return (
      <TeamStreak
        number={teamStreaksData?.data?.streakNumber}
        toolTipPlacement={toolTipPlacement}
      />
    );
  }
  return (
    <>
      <div
        ref={target}
        className="wallet-ballance-container"
        // onClick={() => history.push("/wallet")}
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
      >
        <img src={TeamStreakIcon} alt="LogoFooter" className="zcoinicon" />
        <div>
          <small>Team Streaks</small>
          {displayBalance()}
        </div>
      </div>
      <Overlay
        target={target.current}
        show={displayToolTip && showToolTip}
        placement={toolTipPlacement}
      >
        {renderTooltip}
      </Overlay>
    </>
  );
}

export default TeamStreakScore;
