import React from 'react';
import { FaHome } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';

const HomeButton = ({ color = '', size = '' }) => {
    const history = useHistory(); 
    return (
        <div onClick={()=> history.push('/updated-dashboard')} className='p-2 cursor-pointer'>
            <FaHome style={{color: `${color}`, fontSize: `${size}`}}/>
        </div>
    )
}

export default HomeButton