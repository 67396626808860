import React from "react";
import "./Job.css";
import { useHistory } from "react-router-dom";
// import { atom, useAtom } from 'jotai'

export default function Job({ index, job }) {
  const history = useHistory();
  const handleJobClick = () => {
    history.push("/jobs/job-details/" + job._id, { job });
  };
  

  return (
    <div className="job-card" onClick={handleJobClick}>
      {job && (
        <div className="job-row">
          <img className="job-company-image" src={job.company.companylogo} />
          {/* <img className="job-company-image" src={`https://eagle.co.ug/wp-content/uploads/2016/02/MTN-LOGO.jpg`} /> */}
          <div className="job-summary">
            <h3>
              {job.jobtitle}{" "}
              {job.eligible ? (
                <span className={`eligible-icon`}>ELIGIBLE</span>
              ) : (
                <span className={`eligible-icon not-eligible`}>
                  NOT ELIGIBLE
                </span>
              )}
              {/* Frontend Developer
              <span className={`eligible-icon`}>ELIGIBLE</span> */}
            </h3>
            <p>{job.company.companyname}</p>
            {/* <p>MTN</p> */}

            <div>
              <span className={`eligible-icon info-tag`}>
                🌏 {job.location}
                {/* 🌏 Remote */}
              </span>
              <span className={`eligible-icon info-tag`}>💰 {job.salary}</span>
              {/* <span className={`eligible-icon info-tag`}>💰 R200,000 p/a</span> */}
            </div>
          </div>

          <div className="job-summary">
            {job.tags.map((tag) => (
              <span className={`eligible-icon tech-tag`}>{tag}</span>
            ))}

            {/* <span className={`eligible-icon tech-tag`}>JavaScript</span>
            <span className={`eligible-icon tech-tag`}>ReactJS</span>
            <span className={`eligible-icon tech-tag`}>Redux</span> */}
          </div>
        </div>
      )}
    </div>
  );
}
