import { memo } from "react";
import BottomLeftControls from "./BottomLeftControls";
import BottomRightControls from "./BottomRightControls";
import HeaderControls from "./HeaderControls";
import ProgressBar from "./ProgressBar";

import { ControlsContainer } from "./VideoPlayerControls.styled";
import useVideoStore from "zustand-store/videoPlayerStore";

const VideoPlayerControls = ({
  showControls,
  videoReady,
  error,
  primaryColor,
  progress,
  duration,

  // HeaderControls:
  backButton,
  fullScreen,
  alertMessage,
  toggleMode,
  exitFullScreen,
  backAction,

  // ProgressBar:
  showControlVolume,
  // showQuality,
  showDataNext,
  showReproductionList,
  // progress,
  // duration,
  goToPosition,
  secondsToHms,

  // BottomLeftControls:
  playing,
  play,
  muted,
  volume,
  setShowControlVolume,
  setVolumeAction,
  setMutedAction,
  titleMedia,
  extraInfoMedia,

  //bottom right controls
  // speed controls
  playbackRateEnable,
  showPlaybackRate,
  playbackRate,
  setShowPlaybackRate,
  playbackRateOptions,
  onChangePlayBackRate,

  //next lesson
  onNextClick,
  dataNext,
  setShowDataNext,

  //playlist
  setShowReproductionList,
  sublistMode,
  goBackToMainList,
  listTitle,
  listReproduction,
  list,
  openSublist,
  listRedirect,
  unitList,
  currentUnitId,
  currentLectureId,
  completedLectures,

  enterFullScreen,

  handleSupportButton,
}) => {
  // console.log("VIDEO PLAYER CONTROLS RENDERED");
  const videoMode = useVideoStore((state) => state.videoMode);
  const showControlsContainer = useVideoStore(
    (state) => state.showControlsContainer
  );
  const setShowControlsContainer = useVideoStore(
    (state) => state.setShowControlsContainer
  );
  return (
    <>
      {showControlsContainer && (
        <ControlsContainer
          show={showControls === true && videoReady === true && error === false}
          // show={false}
          primaryColor={primaryColor}
        >
          {videoMode === "lecture" && (
            <>
              <HeaderControls
                backButton={backButton}
                fullScreen={fullScreen}
                alertMessage={alertMessage}
                toggleMode={toggleMode}
                exitFullScreen={exitFullScreen}
                backAction={backAction}
              />

              {/* showQuality={showQuality} */}
              <ProgressBar
                showControlVolume={showControlVolume}
                showDataNext={showDataNext}
                showReproductionList={showReproductionList}
                progress={progress}
                duration={duration}
                primaryColor={primaryColor}
                goToPosition={goToPosition}
                secondsToHms={secondsToHms}
              />
            </>
          )}

          {videoReady === true && (
            <div
              className="controlls controls-with-challenge"
              onMouseLeave={() => setShowControlsContainer(false)}
            >
              <BottomLeftControls
                playing={playing}
                play={play}
                muted={muted}
                primaryColor={primaryColor}
                volume={volume}
                showControlVolume={showControlVolume}
                setShowControlVolume={setShowControlVolume}
                setVolumeAction={setVolumeAction}
                setMutedAction={setMutedAction}
                titleMedia={titleMedia}
                extraInfoMedia={extraInfoMedia}
              />

              <BottomRightControls
                playbackRateEnable={playbackRateEnable}
                showPlaybackRate={showPlaybackRate}
                playbackRate={playbackRate}
                setShowPlaybackRate={setShowPlaybackRate}
                playbackRateOptions={playbackRateOptions}
                onChangePlayBackRate={onChangePlayBackRate}
                // nextlesson:
                onNextClick={onNextClick}
                showDataNext={showDataNext}
                dataNext={dataNext}
                setShowDataNext={setShowDataNext}
                //playlist
                showReproductionList={showReproductionList}
                setShowReproductionList={setShowReproductionList}
                sublistMode={sublistMode}
                goBackToMainList={goBackToMainList}
                listTitle={listTitle}
                listReproduction={listReproduction}
                list={list}
                openSublist={openSublist}
                listRedirect={listRedirect}
                unitList={unitList}
                currentUnitId={currentUnitId}
                currentLectureId={currentLectureId}
                completedLectures={completedLectures}
                //fullscreen
                fullScreen={fullScreen}
                enterFullScreen={enterFullScreen}
                exitFullScreen={exitFullScreen}
                handleSupportButton={handleSupportButton}
              />
            </div>
          )}
        </ControlsContainer>
      )}
    </>
  );
};

export default VideoPlayerControls;

// export default memo(VideoPlayerControls, (prevProps, nextProps) => {
//   if (
//     prevProps.showControls !== nextProps.showControls ||
//     prevProps.videoReady !== nextProps.videoReady ||
//     prevProps.error !== nextProps.error ||
//     prevProps.playing !== nextProps.playing ||
//     prevProps.showControlVolume !== nextProps.showControlVolume ||
//     prevProps.volume !== nextProps.volume
//   ) {
//     return false;
//   }
//   return true;
// });
