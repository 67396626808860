import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useEditorStore from "zustand-store/editorStore";

// const modules = {
//   toolbar: [
//     [{ header: [1, 2, false] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ],
//     ["link", "image"],
//     ["clean"],
//   ],
// };

// const TOOLBAR_OPTIONS = [
//   [{ header: [1, 2, 3, false] }],
//   ["bold", "italic", "underline", "strike", "blockquote", "link"],
//   [{ list: "ordered" }, { list: "bullet" }],
//   [{ indent: "-1" }, { indent: "+1" }],
//   ["emoji"],
//   ["clean"],
// ];
var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

function TextEditor() {
  //   const [value, setValue] = useState("");
  const textEditorValue = useEditorStore((state) => state.textEditorValue);
  const setCurrentTextEditorValue = useEditorStore(
    (state) => state.setCurrentTextEditorValue
  );

  // useEffect(() => {
  //   console.log("UBIUBYBBB", value);
  // }, [value]);

  return (
    <div className="w-full h-full">
      <ReactQuill
        theme="snow"
        className="w-full h-full"
        value={textEditorValue}
        onChange={setCurrentTextEditorValue}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            ["image", "code-block"],
          ],
        }}
      />
    </div>
  );
}

export default TextEditor;
