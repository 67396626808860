import { useState, useEffect, useContext } from "react";

import DashboardNavbar from "component/navbar/Navbar";
// import DashboardNavbar from '';
import { UserContext } from "context/UserProvider";
import { Col, Row } from "react-bootstrap";
import TeamScore from "./TeamScore";
import Accordion from "component/Accordion/Accordion";

import TutorService from "actions/services/tutor-service";
import learningpathService from "actions/services/learningpath.service";
import TeamPath from "./TeamPath";
import TeamRoadmap from "./TeamRoadmap";
import { affirmations } from "Utils/Affirmations";
import ReelModal from "components/QuizReelComponents/ReelModal/ReelModal";
import InviteFriends from "components/QuizReelComponents/ReelModal/InviteFriends";
import tblService from "actions/services/tbl.service";
import AcceptInvite from "components/QuizReelComponents/ReelModal/AcceptInvite";
import streakService from "actions/services/streak.service";
import authService from "actions/services/auth.service";
import { LoadingContext } from "context/LoadingProvider";
import { Mixpanel } from "Utils/Mixpanel";
import CompetitionsList from "../Competitions/Components/CompetitionsList";
import { TeamLearningContainer } from "./TeamLearning.styled";
import ContinueLearningCard from "pages/DashBoard/ContinueLearning/ContinueLearningCard";
import VideoExplainer from "./VideoExplainer";

const TeamLearning = () => {
  const { user, setUser } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);

  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [filterBy, setFilterBy] = useState("All");
  const [filters, setFilters] = useState([
    "All",
    // "fullstack",
    // "introductiontocodingold",
    // "introductiontocoding",
    "Full Stack Web Developer",
    // "Introduction to Coding",
    "Computer Literacy",
    "Coding Literacy",
  ]);

  const [learningPathList, setLearningPathList] = useState({});
  const [hasTeam, setHasTeam] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    title: "HELO HELO",
    description:
      "Try sign up, it will take a min, and you will be get a personalized experience - ITS FREE.",
    CustomComponent: null,
  });
  const [sentInvites, setSentInvites] = useState([]);
  const [receivedInvites, setReceivedInvites] = useState([]);
  const [team, setTeam] = useState([]);
  const [teamLPs, setTeamLPs] = useState([]);
  const [teamStreak, setTeamStreak] = useState(null);

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      title: "",
      description:
        "Try sign up, it will take a min, and you will be get a personalized experience - ITS FREE.",
      CustomComponent: null,
      props: {},
    });
  };

  const handleInviteMembers = () => {
    setModal({
      ...modal,
      show: true,
      title: "Invite Team Members",
      description:
        "Create a team, and build practical experience by working on real projects with your team.",
      CustomComponent: InviteFriends,
    });
  };

  const getTutorMins = async () => {
    try {
      setLoading(true);
      let res3 = await TutorService.getUserAccountDetails();

      setMinutesRemaining(res3.data?.totalminutes);
      //console.log('res3======== Tutor deets', res3);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getLearningPaths = async () => {
    try {
      setLoading(true);
      let res = await learningpathService.getLearningPaths();
      // console.log("res======== Learning path deets", res);
      if (res.data)
        setLearningPathList({
          ...res.data,
        });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getInvites = async () => {
    setLoading(true);
    await tblService.getInvites().then((response) => {
      setReceivedInvites(response.myReceivedInvites);
      setSentInvites(response.mySentInvites);
      setLoading(false);
    });
  };
  const getTeamData = async () => {
    setLoading(true);
    await tblService.getTeam().then(async (response) => {
      setTeamStreak(0);
      // console.log("RES TEAMDATA", response);
      setTeam({ ...response.teamData, members: response.members });

      await authService
        .getTeamStreakData(response.teamData._id)
        .then((response) => {
          // console.log("response.teamData.id", response);
          if (response.data) {
            setTeamStreak(response.data.streakNumber);
            // setTeamStreak(43);
          } else {
            setTeamStreak(0);
          }
        });

      if (response.teamData) {
        // console.log("response.teamData", response.teamData._id);
        setHasTeam(true);
        await tblService
          .getTeamLearningPaths(response.teamData._id)
          .then((response) => {
            // console.log("TEAM LEARNING PATHS", response.teamLearningPathData);
            setTeamLPs(response.teamLearningPathData);
            setLoading(false);
          });
      } else {
        setHasTeam(false);
      }
    });
  };
  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/team-learning",
      "Page name": "Team Learning Page",
    });
    getTeamData();
    getTutorMins();
    getLearningPaths();
    getInvites();
  }, []);

  const handleFilterSelect = (e) => {
    setFilterBy(e.target.innerHTML);
  };

  const handleAcceptInvite = (invite) => {
    setModal({
      ...modal,
      show: true,
      title: `Invite from "Code Buddy"`,
      description: `Accept invite from ${invite.from} and get started with Team Based Learning.`,
      CustomComponent: AcceptInvite,
      props: {
        acceptInvite: tblService.acceptInvite,
        invite,
      },
    });
  };

  return (
    <TeamLearningContainer>
      <DashboardNavbar minutesRemaining={minutesRemaining} />

      {modal.show && (
        <ReelModal
          show={modal.show}
          title={modal.title}
          description={modal.description}
          handleOnHide={handleOnHide}
          CustomComponent={modal.CustomComponent ?? false}
          props={modal.props ?? false}
        />
      )}

      <div className="team-body">
        <div className="mobile-show">
          <h1>Build Practical Experience</h1>

          <TeamScore
            hasTeam={hasTeam}
            handleInviteMembers={handleInviteMembers}
            sentInvites={sentInvites}
            receivedInvites={receivedInvites}
            handleAcceptInvite={handleAcceptInvite}
            team={team}
            teamStreak={teamStreak}
            setTeamStreak={setTeamStreak}
          />
        </div>
        <div className="team-body-left">
          <h1 className="mobile-hide">Build Practical Experience</h1>

          <span className="team-paragraph mobile-hide">
            Grow your Web Development skills by competing in our exciting
            competitions. All projects contribute to your profile. So get
            cracking!
          </span>

          <CompetitionsList handleInviteMembers={handleInviteMembers} />
        </div>
        <div className="team-body-right">
          <TeamScore
            hasTeam={hasTeam}
            handleInviteMembers={handleInviteMembers}
            sentInvites={sentInvites}
            receivedInvites={receivedInvites}
            handleAcceptInvite={handleAcceptInvite}
            team={team}
            teamStreak={teamStreak}
            setTeamStreak={setTeamStreak}
          />
        </div>
      </div>

      {/* <h3 className={classes.subHeading}>Team Progress</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {filters.map((filter, index) => {
                return (
                  <span
                    key={index}
                    onClick={handleFilterSelect}
                    className={`${classes.proIcon} ${
                      filterBy === filter ? classes.selectedFilterBtn : ""
                    }`}
                  >
                    {filter}
                  </span>
                );
              })}
            </div>
          </div> */}

      {/* {hasTeam ? (
            <>
              <div className={classes.blocks}>
                <TeamPath
                  learningPathList={teamLPs}
                  filterBy={filterBy}
                  team={team}
                />
              </div>
            </>
          ) : (
            <button
              className=" mt-4 btn btn-block btn-main"
              onClick={handleInviteMembers}
            >
              Create Team &amp; access team progress - IT's FREE
            </button>
          )} */}

      {/* <div className={classes.blocks}>
            <h3 className={classes.subHeading} style={{ marginTop: 25 }}>
              Team courses/projects
            </h3>
            {hasTeam ? (
              <p>
                Please contact tutor support to access these projects (only
                accessible after entire team is done with React &amp; node.
              </p>
            ) : (
              <button
                className=" mt-4 btn btn-block btn-main"
                onClick={handleInviteMembers}
              >
                Create Team &amp; access team projects - IT's FREE
              </button>
            )}
          </div> */}
    </TeamLearningContainer>
  );
};

export default TeamLearning;
