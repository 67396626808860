import competitionsService from "actions/services/competitions.service";
import Error from "components/Error/Error";
import ZPLoading from "pages/Watch/components/ZPLoading";
import { useQuery } from "react-query";
import Table from "./Table";

function Leaderboard({ competitionId }) {
  const {
    isLoading,
    error,
    data: leaderboard,
  } = useQuery("leaderboard", () =>
    competitionsService.getLeaderboard(competitionId)
  );

  if (isLoading) return <ZPLoading />;
  if (error) return <Error message={error} />;

  if (leaderboard?.data?.length === 0)
    return (
      <p className="w-100 text-center p-5">
        <em>No solutions submitted yet.</em>
      </p>
    );
  return <Table leaderboard={leaderboard?.data} />;
}

export default Leaderboard;
