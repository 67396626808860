import React, { useContext } from "react";
import Styles from "./Loader.module.css";
import { Grid } from "react-loader-spinner";
import { LoadingContext } from "context/LoadingProvider";
import { Loading } from "./Loader.styled";

function Loader({ loadDirect }) {
  const { isloading } = useContext(LoadingContext);
  return (
    <div>
      {isloading || loadDirect ? (
        // <div className={Styles.loader}>
        //   <Grid
        //     color="#16335b"
        //     height={80}
        //     width={80}
        //     visible={isloading || loadDirect}
        //   />
        // </div>
      
          <Loading color={"#8437f9"}>
            <div>
              <div />
              <div />
              <div />
            </div>
          </Loading>     
      
      ) : null}
    </div>
  );
}

export default Loader;
