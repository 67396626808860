import authService from "actions/services/auth.service";
import { UserContext } from "context/UserProvider";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// route=wallet
// user={"success":true,"message":"Logged in","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dnZWRVc2VyIjp7Il9pZCI6IjYzNmQ2NjEyYTc1ZDM2MDAyMjJmMTgyMyIsImVtYWlsIjoiYXNpZmhhc3NhbTE0QGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJhJDEwJHRRSktPeGZGTXZiemJTVGpMMmQ4NmVWYmdkd2NCVnNLclVtd0ZrTGV2UW85TFRJVTRWUkpXIn0sImlhdCI6MTY3MTI5Nzg2NCwiZXhwIjoxNjcxNjQzNDY0fQ.jEtExYfDfNaCcOrSPt4SLeEY_h6ww8SCtiGPNjVvHB0","data":{"username":"Asif hassam","email":"asifhassam14@gmail.com","completed":true,"isadmin":false,"enrolled":true,"ispro":true,"onboardingquestions":[{"digitalLiteracy":"","havePC":"I don't have a PC","isComfortable":"","reelOnboardingComplete":"What the code?"}]}}
// https://www.zaio.io/app/react-native-web-view?route=wallet&user={%22success%22:true,%22message%22:%22Logged%20in%22,%22token%22:%22eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dnZWRVc2VyIjp7Il9pZCI6IjYzNmQ2NjEyYTc1ZDM2MDAyMjJmMTgyMyIsImVtYWlsIjoiYXNpZmhhc3NhbTE0QGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJhJDEwJHRRSktPeGZGTXZiemJTVGpMMmQ4NmVWYmdkd2NCVnNLclVtd0ZrTGV2UW85TFRJVTRWUkpXIn0sImlhdCI6MTY3MTI5Nzg2NCwiZXhwIjoxNjcxNjQzNDY0fQ.jEtExYfDfNaCcOrSPt4SLeEY_h6ww8SCtiGPNjVvHB0%22,%22data%22:{%22username%22:%22Asif%20hassam%22,%22email%22:%22asifhassam14@gmail.com%22,%22completed%22:true,%22isadmin%22:false,%22enrolled%22:true,%22ispro%22:true,%22onboardingquestions%22:[{%22digitalLiteracy%22:%22%22,%22havePC%22:%22I%20don%27t%20have%20a%20PC%22,%22isComfortable%22:%22%22,%22reelOnboardingComplete%22:%22What%20the%20code?%22}]}}

export default function RNWebView() {
  const location = useLocation();
  const history = useHistory();
  const queries = new URLSearchParams(location.search);
  const [userString, setUserString] = useState(queries.get("user"));
  const [route, setRoute] = useState(queries.get("route"));
  const { user, setUser } = useContext(UserContext);

  const populateLogin = async (userData) => {
    try {
      const res = await authService.webViewGetLogin(userData?.token);
      console.log("res", res);
      if (res.success) {
        console.log("REDIRECTing to ", { ...res, ...res.data });
        setUser({ ...res, ...res.data });
        history.push("/" + route);
      }
    } catch (err) {
      // history.push("/logout");
      console.log("ERROR", err);
      console.log(err);
    }
  };

  useEffect(() => {
    localStorage.setItem("user", userString);
    console.log("user", JSON.parse(userString));
    console.log("route", route);

    const userData = JSON.parse(userString);

    setTimeout(() => {
      populateLogin(userData);
    }, 300);
  }, []);

  //   window.location.href = "http://localhost:3000/app/" + "/" + route;
  //   history.push("/" + route);
  return <div className="text-white text-center">Loading...</div>;
}
