import { CodeDrawerContainer } from "./CodeDrawer.styled";
import { FaVideo } from "react-icons/fa";
import { memo, useState } from "react";
import Ide from "components/IDE/Ide";
import useStateRef from "react-usestateref";

const CodeDrawer = ({
  codeMode,
  fullPlayer,
  fullScreen,
  codePushSrc,
  toggleMode,
  projectFiles,
  setProjectFiles,
}) => {
  // console.log("CODE DRAWER RENDERED");
  //current selected file
  const [currentFile, setCurrentFile, currentFileRef] =
    useStateRef("index.html");
  return (
    <CodeDrawerContainer show={codeMode} fullPlayer={fullPlayer}>
      {!fullScreen && (
        <div className="back-to-video">
          <div
            id="back-to-video"
            onClick={() => toggleMode("back-to-video")}
            style={{ cursor: "pointer" }}
          >
            <span>Back to video</span>
            <FaVideo />
          </div>
        </div>
      )}
      <Ide
        isUserSide
        setCurrentFile={setCurrentFile}
        currentFile={currentFile}
        projectFiles={projectFiles}
        setProjectFiles={setProjectFiles}
        currentFileRef={currentFileRef}
      />

      {/* <iframe
        src={codePushSrc}
        style={{
          height: "100%",
          width: "100%",
        }}
        title="React"
        allow="accelerometer; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; xr-spatial-tracking"
        sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
      ></iframe> */}
    </CodeDrawerContainer>
  );
};

export default memo(CodeDrawer);
