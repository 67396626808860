import AdminIdeNav from "../AdminIde/AdminIdeNav";

import "../AdminIde/admin_ide.scss";
import { useEffect, useState } from "react";
import AdminVideosList from "../AdminIde/AdminVideosList";
import { useParams } from "react-router-dom";

import { useQuery } from "react-query";
import lectureService from "actions/services/lecture.service";

import MCQForm from "./MCQForm";
import Assignment from "pages/Assignment/Assignment";
import AssignmentList from "./AssignmentList";
import AdminAssignmentNav from "./AdminAssignmentNav";
import AdminSandboxList from "../AdminIde/AdminSandboxList";
import CreateAssignmentForm from "./CreateAssignmentForm";
import CodingQForm from "../CodingChallenge/CodingQForm";
import QuestionsList from "pages/Assignment/QuestionsList";
import useAssignmentStore from "zustand-store/assignmentStore";
import AdminQuestionsList from "./AdminQuestionsList";
import BackendQForm from "../BackendChallenge/BackendQForm";
import FrontendQForm from "../FrontendChallenge/FrontendQForm";
import DsQForm from "../DSChallenge/DsqForm";

function EditAssignment() {
  const { assignmentid } = useParams();
  console.log("assignmentid", assignmentid);

  const [showSandboxList, setShowSandboxList] = useState(false);
  const [showVideosList, setShowVideosList] = useState(true);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [projectFiles, setProjectFiles] = useState(null);
  const [preLoadedFiles, setPreLoadedFiles] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editingCompleted, setEditingCompleted] = useState(true);

  const [playgroundid, setPlaygroundId] = useState(false);
  const [show, setShow] = useState(false);
  const [questionType, setQuestionType] = useState("");

  const setAssignmentData = useAssignmentStore(
    (state) => state.setAssignmentData
  );
  const adminSelectedAssignment = useAssignmentStore(
    (state) => state.adminSelectedAssignment
  );
  const adminQuestionMode = useAssignmentStore(
    (state) => state.adminQuestionMode
  );
  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
  });
  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const {
    // isLoading,
    // isError,
    // error,
    data: assignment,
  } = useQuery("adminAssignment", () => lectureService.getAssignmentById(), {
    onSuccess: (response) => {
      // console.log("Assignment");
      // setCurrentVideo(data);
      setAssignmentData(response?.data);
    },
  });

  const loadModal = () => {
    setShow(true);
    console.log("LOAD MODAL", show);

    // setModal({
    //   ...modal,
    //   show: true,
    //   title: editingCompleted ? "Create" : "Edit",
    //   description: "",
    //   CustomComponent: SaveProgress,
    //   props: {
    //     currentVideo,
    //     projectFiles: JSON.parse(localStorage.getItem("projectFiles")),
    //     lectureid,
    //     setEditMode,
    //     editMode,
    //     playgroundid,
    //   },
    // });
  };

  const onCtrlS = () => {
    loadModal();
  };

  const onProjectFilesUpdate = (files) => {
    console.log("PROJECT Files", files);
    setProjectFiles(files);
  };

  useEffect(() => {
    console.log("adminSelectedAssignment", adminSelectedAssignment);
  }, []);

  return (
    <div>
      <AdminAssignmentNav
        setShowSandboxList={setShowSandboxList}
        showSandboxList={showSandboxList}
        setShowVideosList={setShowVideosList}
        showVideosList={showVideosList}
        currentCourse={currentCourse}
        editMode={editMode}
        setEditMode={setEditMode}
        currentVideo={currentVideo}
        questionType={questionType}
        setQuestionType={setQuestionType}
      />
      {showSandboxList && (
        <div className="admin-sandbox-sidebar">
          {/* <QuestionsList
            adminAssignmentMode={true}
            setQuestionType={setQuestionType}
            mcqs={adminSelectedAssignment?.mcqquestions}
            codingQs={adminSelectedAssignment?.codingquestions}
          /> */}
          <AdminQuestionsList
            adminAssignmentMode={true}
            setQuestionType={setQuestionType}
            mcqs={adminSelectedAssignment?.mcqquestions}
            codingQs={adminSelectedAssignment?.codingquestions}
            backendQs={adminSelectedAssignment?.backendquestions}
            frontendQs={adminSelectedAssignment?.frontendquestions}
            datascienceQs={adminSelectedAssignment?.datasciencequestions}
          />
        </div>
      )}
      <div className="admin-ide-container">
        {/* <CreateAssignmentForm
          lectureId={lectureid}
          courseId={courseid}
          unitId={unitid}
        /> */}

        {adminQuestionMode.includes("mcq") && (
          <MCQForm assignmentid={assignmentid} />
        )}

        {adminQuestionMode.includes("coding") && (
          <CodingQForm assignmentid={assignmentid} />
        )}

        {adminQuestionMode.includes("backend") && (
          <BackendQForm assignmentid={assignmentid} />
        )}
        
        {adminQuestionMode.includes("frontend") && (
          <FrontendQForm assignmentid={assignmentid} />
        )}

        {adminQuestionMode.includes("datascience") && (
          <DsQForm assignmentid={assignmentid} />
        )}
      </div>
      {showVideosList && (
        <AssignmentList
          setCurrentCourse={setCurrentCourse}
          //   courseid={courseid}
          //   lectureid={lectureid}
          //   unitid={unitid}
          setCurrentVideo={setCurrentVideo}
          currentVideo={currentVideo}
          assignmentid={assignmentid}
        />
      )}
    </div>
  );
}

export default EditAssignment;
