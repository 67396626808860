import React, { useState } from 'react'
import testimonial from 'actions/services/testimonial.service'

function Testimonials() {

    const [formData, setFormData] = useState({
        name: '',
        occupation: '',
        videourl: '',
        video_thumbnail:'',
        user_thumbnail:'',
        text: '',
        twitter: '',
        linkedin: '',
        instagram: ''
    })

    const { name, occupation, videourl, text, twitter, linkedin, instagram,video_thumbnail,user_thumbnail } = formData;


    const handleChange = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const body = {
            "name": name,
            "occupation": occupation,
            "videourl": videourl,
            "user_thumbnail":user_thumbnail,
            "video_thumbnail":video_thumbnail,
            "text": text,
            "twitter": twitter,
            "linkedin": linkedin,
            "instagram": instagram
        }
        const res = await testimonial.postTestimonial(body);
        console.log(res);
        if (res) {
            alert(res.message);
            setFormData({
                name: '',
                occupation: '',
                videourl: '',
                user_thumbnail:'',
                video_thumbnail:'',
                text: '',
                twitter: '',
                linkedin: '',
                instagram: ''
            })
        }
    }

    return (
        <div className='container mt-5'>
            <h3 style={{ color: "white" }}>Testimonail Account</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input type="text" value={name} onChange={handleChange} name="name" className="form-control" aria-describedby="emailHelp" placeholder="Enter Name" />
                </div>
                <div className="form-group">
                    <input type="text" value={occupation} onChange={handleChange} name="occupation" className="form-control" placeholder="Enter Occupation" />
                </div>
                <div className="form-group">
                    <input type="text" value={videourl} onChange={handleChange} name="videourl" className="form-control" aria-describedby="emailHelp" placeholder="Enter Video Url" />
                </div>
                <div className="form-group">
                    <input type="text" value={user_thumbnail} onChange={handleChange} name="user_thumbnail" className="form-control" aria-describedby="emailHelp" placeholder="Enter Profile Image Url" />
                </div>
                <div className="form-group">
                    <input type="text" value={video_thumbnail} onChange={handleChange} name="video_thumbnail" className="form-control" aria-describedby="emailHelp" placeholder="Enter thumbnail Url" />
                </div>
                <div className="form-group">
                    <input type="text" value={text} onChange={handleChange} name="text" className="form-control" placeholder="Enter Text" />
                </div>
                <div className="form-group">
                    <input type="text" value={twitter} onChange={handleChange} name="twitter" className="form-control" placeholder="Enter twitter url" />
                </div>
                <div className="form-group">
                    <input type="text" value={linkedin} onChange={handleChange} name="linkedin" className="form-control" placeholder="Enter linkedin url" />
                </div>
                <div className="form-group">
                    <input type="text" value={instagram} onChange={handleChange} name="instagram" className="form-control" placeholder="Enter instagram url" />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    )
}

export default Testimonials