import { defaultFileStructure, playgroundTypes } from "Constants";
import { DiCss3, DiHtml5, DiJsBadge } from "react-icons/di";

export const getDefaultFilesBasedOnType = (type) => {
  return defaultFileStructure;
};

export const getDefaultFolderStructureBasedOnType = (type) => {
  if (type === playgroundTypes.SINGLE_FILES) {
    return [];
  } else if (type === playgroundTypes.MULTI_FILES) {
    return [];
  }
};

export function formatJSON(val = {}) {
  try {
    return JSON.stringify(val, null, 2);
  } catch {
    return val;
  }
}

export const parseValue = (val) => {
  try {
    return JSON.parse(val);
  } catch {
    return val;
  }
};

export const loadIcon = (projectFile) => {
  if (projectFile.includes("html")) {
    return <DiHtml5 color="#e85e28" className="file-icon" />;
  }
  if (projectFile.includes("css")) {
    return <DiCss3 color="#254ede" className="file-icon" />;
  }
  if (projectFile.includes("js")) {
    return <DiJsBadge color="#f0da3c" className="file-icon" />;
  }
};

export const formatProjectFiles = (codePush) => {
  console.log(codePush,typeof codePush?.playgroundid?.project_files, 'typeof codePush?.playgroundid?.project_files',typeof codePush?.playgroundid?.project_files === "string" )
  if (typeof codePush?.playgroundid?.project_files === "string") {
    return {
      files: JSON.parse(codePush?.playgroundid?.project_files),
      type: codePush?.playgroundid?.playground_type,
    };
  } else if (typeof codePush?.playgroundid?.project_files === "object") {
    return {
      files: {
        "index.html": {
          name: "index.html",
          language: "html",
          value: codePush?.playgroundid?.project_files?.html?.value,
        },
        "style.css": {
          name: "style.css",
          language: "css",
          value: codePush?.playgroundid?.project_files?.css?.value,
        },
        "index.js": {
          name: "index.js",
          language: "js",
          value: codePush?.playgroundid?.project_files?.js?.value,
        },
      },
      type: codePush?.playgroundid?.playground_type,
    };
  } else return null;
};
