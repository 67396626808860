import MarkdownToHTML from "components/HTMLToReact/MarkdownToHTML";
import MarkdownToReact from "pages/Competitions/MarkdownToReact";

function AssignmentOverview({ title, mdfile, setShowQuestions }) {
  console.log("MD FILE", mdfile);
  return (
    <div className="flex flex-col items-center mt-24">
      <div className="w-3/4 bg-white h-1/2 rounded-md">
        <div className="w-full border-b-2 border-gray-200 p-2 px-3 flex justify-between items-center">
          <h1 className="text-lg font-bold">
            {title || "Assignment Overview"}
          </h1>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => setShowQuestions(true)}
          >
            Open Questions
          </button>
        </div>
        <div className="m-2">
          <MarkdownToHTML html={mdfile} />
        </div>
      </div>
    </div>
  );
}

export default AssignmentOverview;
